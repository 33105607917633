<template>
  <div>
    <div v-if="reportCreated">
      <el-dialog custom-class="report-border black-card" :visible.sync="reportVisible" :close-on-click-modal="false" width="1268px" top="3vh">
        <div id="imageDom" ref="imageDom">
          <el-card class="black-card" style="border: 0" shadow="none" body-style="padding: 25px 40px 25px 40px">
            <el-row style="margin-top: -10px">
              <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 16px;color: #e5e5e5;font-weight: bolder">技术服务数据报告</span>
            </el-row>
            <el-row style="margin-top: 45px;display: flex">
              <div style="width: 6px;height: 6px;background-color: #0ecb81;float: left"></div>
              <span style="float: left;color: #e5e5e5;margin-top: -6px;margin-left: 10px;font-size: 13px;font-weight: bolder">概述</span>
            </el-row>
            <el-row style="margin-top: 15px;display: flex">
              <span style="float: left;text-align: left;color: #e5e5e5;margin-top: -6px;font-size: 13px;">账号起始时间为 {{ new Date(settlementObj.profitRateRecordTime[0]) }}，初始资金为 <span style="color: #0ecb81">{{ getOriginalBalance() }}</span> {{ baseCoin }}。于 {{ new Date(settlementObj.profitRateRecordTime[settlementObj.profitRateRecordTime.length - 1]) }} 进行统计，当前资金为 <span style="color: #0ecb81">{{ settlementObj.settlementValue.toFixed(2) }}</span> {{ baseCoin }}，总计运行 <span style="color: rgb(51, 177, 253)">{{ runningTime }}</span> 天，累计收益率 <span style="color: #0ecb81">{{ (((settlementObj.settlementValue - getOriginalBalance()) / getOriginalBalance()) * 100).toFixed(2) }}%</span>，累计收益额 <span style="color: #0ecb81">{{ (settlementObj.settlementValue - getOriginalBalance()).toFixed(2) }}</span> {{ baseCoin }}。</span>
            </el-row>
            <div v-if="displayList.indexOf('CUSTOMER_REMARK') > -1">
              <el-row style="margin-top: 45px;display: flex">
                <div style="width: 6px;height: 6px;background-color: #0ecb81;float: left"></div>
                <span style="float: left;color: #e5e5e5;margin-top: -6px;margin-left: 10px;font-size: 13px;font-weight: bolder">备注</span>
              </el-row>
              <el-row style="margin-top: 15px;display: flex">
                <span style="float: left;text-align: left;color: #e5e5e5;margin-top: -6px;font-size: 13px">{{ customerRemark }}</span>
              </el-row>
            </div>
            <el-row style="margin-top: 50px;display: flex">
              <div style="width: 6px;height: 6px;background-color: #0ecb81;float: left"></div>
              <span style="float: left;color: #e5e5e5;margin-top: -6px;margin-left: 10px;font-size: 13px;font-weight: bolder">净值走势</span>
            </el-row>
            <el-row style="margin-top: 0;display: flex">
              <general-settlement-profit-panel :id="'settlement_profit_package_' + instance.instanceId" ref="settlement_profit" style="height: 168px;width: 100%"></general-settlement-profit-panel>
            </el-row>
            <el-row style="margin-top: 40px;display: flex">
              <div style="width: 6px;height: 6px;background-color: #0ecb81;float: left"></div>
              <span style="float: left;color: #e5e5e5;margin-top: -6px;margin-left: 10px;font-size: 13px;font-weight: bolder">账号周期信息</span>
            </el-row>
            <el-row style="margin-top: 15px;display: flex">
              <el-col :span="14" style="padding-right: 20px">
                <el-row>
                  <el-card style="border-radius: 8px 8px 0 0;background-color: rgb(229,158,26);height: 30px;border: 0" shadow="none" body-style="padding: 0">
                    <span style="color: white;font-weight: bolder;margin-top: 4px;display: inline-block">资金数据</span>
                  </el-card>
                </el-row>
                <el-row>
                  <el-card style="border-radius: 0 0 8px 8px;background-color: rgb(0, 0, 0, 0);height: 100px;border: 2px solid rgba(49,58,70,0.8);border-top: 0" shadow="none" body-style="padding: 0">
                    <el-row style="margin-top: 20px">
                      <el-col :span="6">
                        <el-card style="width: 100%;border: 0;border-right: 1px solid rgba(49,58,70,0.8);border-radius: 0;background-color: rgba(0, 0, 0, 0);height: 60px" shadow="none" body-style="padding: 5px">
                          <el-row>
                            <span style="color: white;font-size: 12px">初始资金（{{ baseCoin }}）</span>
                          </el-row>
                          <el-row style="margin-top: 5px">
                            <span style="color: white;font-size: 18px">{{ getOriginalBalance() }}</span>
                          </el-row>
                        </el-card>
                      </el-col>
                      <el-col :span="6">
                        <el-card style="width: 100%;border: 0;border-right: 1px solid rgba(49,58,70,0.8);border-radius: 0;background-color: rgba(0, 0, 0, 0);height: 60px" shadow="none" body-style="padding: 5px">
                          <el-row>
                            <span style="color: white;font-size: 12px">收益率</span>
                          </el-row>
                          <el-row style="margin-top: 5px">
                            <span style="color: white;font-size: 18px">{{ (((settlementObj.settlementValue - getOriginalBalance()) / getOriginalBalance()) * 100).toFixed(2) }}%</span>
                          </el-row>
                        </el-card>
                      </el-col>
                      <el-col :span="6">
                        <el-card style="width: 100%;border: 0;border-right: 1px solid rgba(49,58,70,0.8);border-radius: 0;background-color: rgba(0, 0, 0, 0);height: 60px" shadow="none" body-style="padding: 5px">
                          <el-row>
                            <span style="color: white;font-size: 12px">当前资金（{{ baseCoin }}）</span>
                          </el-row>
                          <el-row style="margin-top: 5px">
                            <span style="color: white;font-size: 18px">{{ settlementObj.settlementValue.toFixed(2) }}</span>
                          </el-row>
                        </el-card>
                      </el-col>
                      <el-col :span="6">
                        <el-card style="width: 100%;border: 0;border-radius: 0;background-color: rgba(0, 0, 0, 0);height: 60px" shadow="none" body-style="padding: 5px">
                          <el-row>
                            <span style="color: white;font-size: 12px">收益额（{{ baseCoin }}）</span>
                          </el-row>
                          <el-row style="margin-top: 5px">
                            <span style="color: white;font-size: 18px">{{ (settlementObj.settlementValue - getOriginalBalance()).toFixed(2) }}</span>
                          </el-row>
                        </el-card>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-row>
              </el-col>
              <el-col :span="10">
                <el-row>
                  <el-card style="border-radius: 8px 8px 0 0;background-color: rgb(51,177,253);height: 30px;border: 0" shadow="none" body-style="padding: 0">
                    <span style="color: white;font-weight: bolder;margin-top: 4px;display: inline-block">交易数据</span>
                  </el-card>
                </el-row>
                <el-row>
                  <el-card style="border-radius: 0 0 8px 8px;background-color: rgb(0, 0, 0, 0);height: 100px;border: 2px solid rgba(49,58,70,0.8);border-top: 0" shadow="none" body-style="padding: 0">
                    <el-row style="margin-top: 20px">
                      <el-col :span="8">
                        <el-card style="width: 100%;border: 0;border-right: 1px solid rgba(49,58,70,0.8);border-radius: 0;background-color: rgba(0, 0, 0, 0);height: 60px" shadow="none" body-style="padding: 5px">
                          <el-row>
                            <span style="color: white;font-size: 12px">交易次数</span>
                          </el-row>
                          <el-row style="margin-top: 5px">
                            <span style="color: white;font-size: 18px">{{ report.tradeCount }}</span>
                          </el-row>
                        </el-card>
                      </el-col>
                      <el-col :span="8">
                        <el-card style="width: 100%;border: 0;border-right: 1px solid rgba(49,58,70,0.8);border-radius: 0;background-color: rgba(0, 0, 0, 0);height: 60px" shadow="none" body-style="padding: 5px">
                          <el-row>
                            <span style="color: white;font-size: 12px">交易量（USD）</span>
                          </el-row>
                          <el-row style="margin-top: 5px">
                            <span style="color: white;font-size: 18px">{{ Number(report.totalVol).toFixed(2) }}</span>
                          </el-row>
                        </el-card>
                      </el-col>
                      <el-col :span="8">
                        <el-card style="width: 100%;border: 0;border-radius: 0;background-color: rgba(0, 0, 0, 0);height: 60px" shadow="none" body-style="padding: 5px">
                          <el-row>
                            <span style="color: white;font-size: 12px">本金最大回撤</span>
                          </el-row>
                          <el-row style="margin-top: 5px">
                            <span style="color: white;font-size: 18px">{{ Number(settlementObj.maxLossRate * 100).toFixed(2) }}%</span>
                          </el-row>
                        </el-card>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-row>
              </el-col>
            </el-row>
            <el-row style="margin-top: 40px;display: flex">
              <el-col v-if="displayList.indexOf('SETTLE_MODULE') > -1 || displayList.indexOf('WALLET_MODULE') > -1" :span="14">
                <div v-if="displayList.indexOf('SETTLE_MODULE') > -1">
                  <el-row>
                    <div style="width: 6px;height: 6px;background-color: #0ecb81;float: left"></div>
                    <span style="float: left;color: #e5e5e5;margin-top: -6px;margin-left: 10px;font-size: 13px;font-weight: bolder">收益结算</span>
                  </el-row>
                  <el-row style="margin-top: 30px">
                    <el-col :span="13">
                      <settlement-profit-statics ref="profitPie"></settlement-profit-statics>
                    </el-col>
                    <el-col :span="11" style="padding-right: 30px">
                      <el-row>
                        <span style="color: white;display: inline-block;float: left">{{ partyA }} 收益占比</span>
                        <span style="color: white;display: inline-block;float: right">{{ Number.prototype.accSub(100, ftRate * 100) }}%</span>
                      </el-row>
                      <el-row style="margin-top: 10px">
                        <span style="color: white;display: inline-block;float: left">{{ partyA }} 收益额（{{ baseCoin }}）</span>
                        <span style="color: white;display: inline-block;float: right">{{ userRealProfit.toFixed(2) }}</span>
                      </el-row>
                      <el-row style="margin-top: 30px">
                        <span style="color: white;display: inline-block;float: left">{{ partyB }} 收益占比</span>
                        <span style="color: white;display: inline-block;float: right">{{ ftRate * 100 }}%</span>
                      </el-row>
                      <el-row style="margin-top: 10px">
                        <span style="color: white;display: inline-block;float: left">{{ partyB }} 收益额（{{ baseCoin }}）</span>
                        <span style="color: white;display: inline-block;float: right">{{ ftRealProfit.toFixed(2) }}</span>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
                <div v-if="displayList.indexOf('WALLET_MODULE') > -1">
                  <el-row style="margin-top: 20px;display: flex">
                    <div style="width: 6px;height: 6px;background-color: #0ecb81;float: left"></div>
                    <span style="float: left;color: #e5e5e5;margin-top: -6px;margin-left: 10px;font-size: 13px;font-weight: bolder">收款方式</span>
                  </el-row>
                  <el-row style="margin-top: 30px;display: flex">
                    <el-col :span="4">
                      <span style="float: left;color: #e5e5e5;margin-top: -6px;margin-left: 10px;font-size: 13px;font-weight: bolder">收款链</span>
                    </el-col>
                    <el-col :span="18">
                      <span style="float: left;color: #e5e5e5;margin-top: -6px;margin-left: 10px;font-size: 13px;font-weight: bolder">{{ addressProtocol }}</span>
                    </el-col>
                  </el-row>
                  <el-row style="margin-top: 20px;display: flex">
                    <el-col :span="4">
                      <span style="float: left;color: #e5e5e5;margin-top: -6px;margin-left: 10px;font-size: 13px;font-weight: bolder">收款地址</span>
                    </el-col>
                    <el-col :span="18">
                      <span style="float: left;color: #e5e5e5;margin-top: -6px;margin-left: 10px;font-size: 13px;font-weight: bolder">{{ walletAddress }}</span>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <el-col v-if="displayList.indexOf('POSITION_MODULE') > -1" :span="10">
                <el-row>
                  <div style="width: 6px;height: 6px;background-color: #0ecb81;float: left"></div>
                  <span style="float: left;color: #e5e5e5;margin-top: -6px;margin-left: 10px;font-size: 13px;font-weight: bolder">最新持仓快照</span>
                </el-row>
                <el-row style="margin-top: 30px">
                  <el-col :span="7">
                    <el-card style="background-color: rgba(0, 0, 0, 0); border: 1px solid rgba(49,58,70,0.8);border-radius: 0;border-right: 0;height: 30px" shadow="none" body-style="padding: 6px">
                      <span style="color: white;font-size: 12px;text-align: left;display: inline-block;float: left">Symbol</span>
                    </el-card>
                  </el-col>
                  <el-col :span="5">
                    <el-card style="background-color: rgba(0, 0, 0, 0); border: 1px solid rgba(49,58,70,0.8);border-radius: 0;border-right: 0;height: 30px" shadow="none" body-style="padding: 6px">
                      <span style="color: white;font-size: 12px;text-align: right;display: inline-block;float: right">Exchange</span>
                    </el-card>
                  </el-col>
                  <el-col :span="6">
                    <el-card style="background-color: rgba(0, 0, 0, 0); border: 1px solid rgba(49,58,70,0.8);border-radius: 0;border-right: 0;height: 30px" shadow="none" body-style="padding: 6px">
                      <span style="color: white;font-size: 12px;text-align: right;display: inline-block;float: right">Type</span>
                    </el-card>
                  </el-col>
                  <el-col :span="6">
                    <el-card style="background-color: rgba(0, 0, 0, 0); border: 1px solid rgba(49,58,70,0.8);border-radius: 0;height: 30px" shadow="none" body-style="padding: 6px">
                      <span style="color: white;font-size: 12px;text-align: right;display: inline-block;float: right">Position</span>
                    </el-card>
                  </el-col>
                  <!--
                  <el-col :span="6">
                    <el-card style="background-color: rgba(0, 0, 0, 0); border: 1px solid rgba(49,58,70,0.8);border-radius: 0;height: 30px" shadow="none" body-style="padding: 6px">
                      <span style="color: white;font-size: 12px;text-align: right;display: inline-block;float: right">PNL</span>
                    </el-card>
                  </el-col>
                  -->
                </el-row>
                <div v-if="settlementObj && settlementObj.positionInfo && Object.keys(settlementObj.positionInfo).length > 0">
                  <el-row style="margin-top: 0" v-for="p in Object.keys(settlementObj.positionInfo)" :key="p + settlementObj.positionInfo[p].platform">
                    <el-col :span="7">
                      <el-card style="background-color: rgba(0, 0, 0, 0); border: 1px solid rgba(49,58,70,0.8);border-radius: 0;border-right: 0;height: 30px;border-top: 0" shadow="none" body-style="padding: 6px">
                        <span style="color: white;font-size: 12px;text-align: left;display: inline-block;float: left">{{ p }}</span>
                      </el-card>
                    </el-col>
                    <el-col :span="5">
                      <el-card style="background-color: rgba(0, 0, 0, 0); border: 1px solid rgba(49,58,70,0.8);border-radius: 0;border-right: 0;height: 30px;border-top: 0" shadow="none" body-style="padding: 6px">
                        <span style="color: white;font-size: 12px;text-align: right;display: inline-block;float: right">{{ settlementObj.positionInfo[p].platform }}</span>
                      </el-card>
                    </el-col>
                    <el-col :span="6">
                      <el-card style="background-color: rgba(0, 0, 0, 0); border: 1px solid rgba(49,58,70,0.8);border-radius: 0;height: 30px;border-right: 0;border-top: 0" shadow="none" body-style="padding: 6px">
                        <span style="color: white;font-size: 12px;text-align: right;display: inline-block;float: right">{{ settlementObj.positionInfo[p].symbolType.replaceAll('USD_', '') }}</span>
                      </el-card>
                    </el-col>
                    <el-col :span="6">
                      <el-card style="background-color: rgba(0, 0, 0, 0); border: 1px solid rgba(49,58,70,0.8);border-radius: 0;height: 30px;border-top: 0" shadow="none" body-style="padding: 6px">
                        <span :style="'' + (settlementObj.positionInfo[p].position > 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ';font-size: 12px;text-align: right;display: inline-block;float: right'">{{ settlementObj.positionInfo[p].position }}</span>
                      </el-card>
                    </el-col>
                    <!--
                    <el-col :span="6">
                      <el-card style="background-color: rgba(0, 0, 0, 0); border: 1px solid rgba(49,58,70,0.8);border-radius: 0;height: 30px;border-top: 0" shadow="none" body-style="padding: 6px">
                        <span :style="'' + (settlementObj.positionInfo[p].pnl > 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ';font-size: 12px;text-align: right;display: inline-block;float: right'">{{ settlementObj.positionInfo[p].pnl.toFixed(3) }}</span>
                      </el-card>
                    </el-col>
                    -->
                  </el-row>
                </div>
                <div v-if="!(settlementObj && settlementObj.positionInfo && Object.keys(settlementObj.positionInfo).length > 0)" style="height: 230px;overflow-y: auto">
                  <el-card class="black-card" style="border: 1px solid rgba(49,58,70,0.8);border-top: 0;padding-top: 68px;height: 220px" shadow="none">
                    <i class="el-icon-s-order" style="color: darkgray"></i>
                    <span style="display: inline-block;margin-left: 5px;color: darkgray;font-weight: bolder">EMPTY POSITION...</span>
                  </el-card>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </div>
        <el-row style="margin-top: 20px;display: flex">
          <button class="dark-mode-btn" style="width: 268px;height: 35px;font-size: 12px;border-radius: 3px;margin: auto" @click="clickGeneratePicture">生成分享图片</button>
        </el-row>
      </el-dialog>
      <el-image-viewer
          style="border: 0;background-color: rgba(0, 0, 0, 0)"
          :z-index="9999"
          v-if="showPicDialogVisible"
          :on-close="() => {showPicDialogVisible = false}"
          :url-list="[picUrl]"
      />
    </div>
    <div v-if="!reportCreated">
      <el-dialog custom-class="report-border black-card" :visible.sync="reportVisible" :close-on-click-modal="false" width="718px" top="5vh">
        <el-card class="black-card" style="border: 0" shadow="none" body-style="padding: 25px 40px 25px 40px">
          <el-row style="margin-top: -30px">
            <span class="dark-mode-font" style="display: inline-block;font-size: 16px;color: #e5e5e5;font-weight: bolder">报告生成</span>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-col :span="9">
              <span style="float: left;color: #e5e5e5;margin-top: 2px;margin-left: 0;font-size: 13px;font-weight: bolder">结算方</span>
            </el-col>
            <el-col :span="15">
              <input class="trialpha-input" style="background-color: #1c2128;height: 30px;color: white;margin-top: -12px" v-model="partyB" />
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-col :span="9">
              <span style="float: left;color: #e5e5e5;margin-top: 2px;margin-left: 0;font-size: 13px;font-weight: bolder">结算客户</span>
            </el-col>
            <el-col :span="15">
              <input class="trialpha-input" style="background-color: #1c2128;height: 30px;color: white;margin-top: -12px" v-model="partyA" />
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-col :span="9">
              <span style="float: left;color: #e5e5e5;margin-top: 2px;margin-left: 0;font-size: 13px;font-weight: bolder">客户资金类型</span>
            </el-col>
            <el-col :span="15">
              <el-radio-group v-model="baseCoin" style="margin-top: 5px;float: left">
                <el-radio label="BTC">BTC</el-radio>
                <el-radio label="ETH">ETH</el-radio>
                <el-radio label="USDT">USDT</el-radio>
                <el-radio label="USD">USD</el-radio>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-col :span="9">
              <span style="float: left;color: #e5e5e5;margin-top: 2px;margin-left: 0;font-size: 13px;font-weight: bolder">抽佣比例 (0 ~ 1)</span>
            </el-col>
            <el-col :span="15">
              <input class="trialpha-input" style="background-color: #1c2128;height: 30px;color: white;margin-top: -12px" v-model="ftRate" />
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-col :span="9">
              <span style="float: left;color: #e5e5e5;margin-top: 2px;margin-left: 0;font-size: 13px;font-weight: bolder">钱包地址</span>
            </el-col>
            <el-col :span="15">
              <input class="trialpha-input" style="background-color: #1c2128;height: 30px;color: white;margin-top: -12px" v-model="walletAddress" placeholder="Address" />
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-col :span="9">
              <span style="float: left;color: #e5e5e5;margin-top: 2px;margin-left: 0;font-size: 13px;font-weight: bolder">收款协议</span>
            </el-col>
            <el-col :span="15">
              <el-radio-group v-model="addressProtocol" style="margin-top: 5px;float: left">
                <el-radio label="TRC">TRC</el-radio>
                <el-radio label="ERC">ERC</el-radio>
                <el-radio label="BSC">BSC</el-radio>
                <el-radio label="OMNI">OMNI</el-radio>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-col :span="9">
              <span style="float: left;color: #e5e5e5;margin-top: 2px;margin-left: 0;font-size: 13px;font-weight: bolder">结算区间</span>
            </el-col>
            <el-col :span="15">
              <el-date-picker
                  style="background-color: rgba(0, 0, 0, 0);border: 0;color: white;float: left;margin-left: -10px"
                  v-model="timeRange"
                  type="datetimerange"
                  range-separator="~"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-col :span="9">
              <span style="float: left;color: #e5e5e5;margin-top: 2px;margin-left: 0;font-size: 13px;font-weight: bolder">板块展示</span>
            </el-col>
            <el-col :span="15">
              <el-checkbox-group v-model="displayList" style="text-align: left">
                <el-checkbox label="SETTLE_MODULE">结算模块</el-checkbox>
                <el-checkbox label="POSITION_MODULE">仓位模块</el-checkbox>
                <el-checkbox label="WALLET_MODULE">收款方式</el-checkbox>
                <el-checkbox label="CUSTOMER_ORIGIN_BALANCE">自定义初始金额</el-checkbox>
                <el-checkbox label="CUSTOMER_REMARK">添加自定义备注</el-checkbox>
              </el-checkbox-group>
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px" v-if="displayList.indexOf('CUSTOMER_ORIGIN_BALANCE') > -1">
            <el-col :span="9">
              <span style="float: left;color: #e5e5e5;margin-top: 2px;margin-left: 0;font-size: 13px;font-weight: bolder">初始金额</span>
            </el-col>
            <el-col :span="15">
              <input class="trialpha-input" style="background-color: #1c2128;height: 30px;color: white;margin-top: -12px" v-model="customerOriginBalance" />
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px" v-if="displayList.indexOf('CUSTOMER_REMARK') > -1">
            <el-col :span="9">
              <span style="float: left;color: #e5e5e5;margin-top: 2px;margin-left: 0;font-size: 13px;font-weight: bolder">自定义描述</span>
            </el-col>
            <el-col :span="15">
              <textarea class="trialpha-input" style="background-color: #1c2128;height: 88px;color: white;margin-top: -3px" v-model="customerRemark" />
            </el-col>
          </el-row>
        </el-card>
        <el-row style="margin-top: 10px;display: flex">
          <el-button class="dark-mode-btn" style="width: 268px;height: 35px;font-size: 12px;border-radius: 3px;margin: auto" @click="createReport" :loading="creating">创建</el-button>
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>

<script>

import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import GeneralSettlementProfitPanel from "@/pages/controlPanel/accountMgnt/SettlementProfitPanel";
import SettlementProfitStatics from "@/pages/controlPanel/accountMgnt/SettlementProfitStatics";
import html2canvas from "html2canvas";
import SettlementService from "@/api/crm/SettlementService";
import {Message} from "element-ui";

export default {
  name: "SettlementPage",
  components: {SettlementProfitStatics, GeneralSettlementProfitPanel, ElImageViewer},
  data: function () {
    return {
      displayList: ['SETTLE_MODULE', 'POSITION_MODULE', 'WALLET_MODULE'],
      instance: {},
      reportVisible: false,
      showPicDialogVisible: false,
      reportCreated: false,
      picUrl: '',
      ftRate: 0.3,
      walletAddress: '0x489A3C697663BbD2959Ef61813F8959F4546FfEf',
      addressProtocol: 'BSC',
      baseCoin: 'USDT',
      timeRange: [new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30 * 3) ,new Date()],
      creating: false,
      report: {},
      settlementObj: {},
      runningTime: 0,
      partyA: '',
      partyB: 'Oneport',
      customerOriginBalance: 0,
      customerRemark: '',
      userRealProfit: 0,
      ftRealProfit: 0
    }
  },
  methods: {
    render: function (instance) {
      this.creating = false;
      this.reportCreated = false;
      this.reportVisible = true;
      this.instance = instance;
      this.partyA = instance.name;
      this.timeRange = [new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30 * 3) ,new Date()];
    },
    createReport() {
      let that = this;
      if (!this.partyA || !this.partyB || !this.baseCoin || !this.ftRate || this.ftRate <= 0 || !this.walletAddress || !this.addressProtocol || !this.timeRange || this.timeRange.length < 2) {
        Message.error('必填字段尚未填写');
        return;
      }
      that.creating = true;
      SettlementService.create(this.instance.instanceId, Number.prototype.accSub(1, this.ftRate), this.ftRate, this.walletAddress, 'ETH', this.timeRange[0].getTime() / 1000, this.timeRange[1].getTime() / 1000, function (res) {
        that.creating = false;
        that.reportCreated = true;
        that.report = res;
        that.settlementObj = res.coinsSettlement[that.instance.tradeAccountList[0].baseCoin];
        that.runningTime = ((that.settlementObj.profitRateRecordTime[that.settlementObj.profitRateRecordTime.length - 1] - that.settlementObj.profitRateRecordTime[0]) / (1000 * 60 * 60 * 24)).toFixed(2);
        that.$nextTick(() => {
          let y = [];
          for (let profitRateValuesKey in that.settlementObj.profitRateValues) {
            y.push(that.settlementObj.profitRateValues[profitRateValuesKey] * 100);
          }
          that.$refs['settlement_profit'].render(that.settlementObj.profitRateRecordTime, y);
          let totalProfit = (that.settlementObj.settlementValue - that.getOriginalBalance());
          that.ftRealProfit = totalProfit * that.ftRate;
          that.userRealProfit = totalProfit * Number.prototype.accSub(1, that.ftRate);
          that.$refs['profitPie'].render(that.partyA, that.partyB, that.ftRealProfit, that.userRealProfit);
        });
      }, function (e) {
        console.error(e);
        that.creating = false;
      });
    },
    getOriginalBalance() {
      if (this.displayList.indexOf('CUSTOMER_ORIGIN_BALANCE') > -1) {
        return Number(Number(this.customerOriginBalance).toFixed(2));
      } else {
        return Number(this.settlementObj.originBalance.toFixed(2));
      }
    },
    clickGeneratePicture() {
      // this.$PDFSave(this.$refs.imageDom, "我的文件");
      html2canvas(this.$refs.imageDom).then(canvas => {
        // 转成图片，生成图片地址
        let imgUrl = canvas.toDataURL("image/png");
        this.picUrl = (imgUrl);
        this.showPicDialogVisible = true;
      });
    }
  }
}
</script>

<style>

.report-border {
  border-radius: 6px;
}

.pic-dialog {
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
}

</style>