<script type="text/javascript">

import ApeContract from "@/web3/contract/ape/ApeContract";

function getApeStakeInfo(type, address, success, error) {
  let contract = ApeContract.getApeContractAddress(this);
  if (type === 'APE') {
    contract.methods.getApeCoinStake(address).call().then((res) => {
      if (res.length > 0) {
        success([Number(res[2]) / Math.pow(10, 18), Number(res[3]) / Math.pow(10, 18), Number(res[4]) / Math.pow(10, 18)]);
      } else {
        success([0, 0, 0]);
      }
    }, (err) => {
      error(err);
    });
  } else if (type === 'BAYC') {
    contract.methods.getBakcStakes(address).call().then((res) => {
      if (res.length > 0) {
        success([Number(res[0][2]) / Math.pow(10, 18), Number(res[0][3]) / Math.pow(10, 18), Number(res[0][4]) / Math.pow(10, 18), res[0][1]]);
      } else {
        success([0, 0, 0, 0]);
      }
    }, (err) => {
      error(err);
    });
  } else if (type === 'MAYC') {
    contract.methods.getMaycStakes(address).call().then((res) => {
      console.log(res);
      if (res.length > 0) {
        success([Number(res[0][2]) / Math.pow(10, 18), Number(res[0][3]) / Math.pow(10, 18), Number(res[0][4]) / Math.pow(10, 18), res[0][1]]);
      } else {
        success([0, 0, 0, 0]);
      }
    }, (err) => {
      error(err);
    });
  }
}

function claimApeProfit(nftId, web3, type, success, error) {
  let contract = ApeContract.getApeContractAddress(this);
  if (type === 'APE') {
    contract.methods.claimSelfApeCoin().send({from: web3.currentProvider.selectedAddress}).on("receipt", function(receipt) {
      success(receipt);
    }).on("error", function(err) {
      error(err);
    });
  } else if (type === 'MAYC') {
    contract.methods.claimSelfMAYC([nftId]).call().then((res) => {
      success(res);
    }, (err) => {
      error(err);
    });
  }
}

export default {
  getApeStakeInfo,
  claimApeProfit
}


</script>