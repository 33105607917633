<template>
  <el-card :symbol="symbol" class="black-card" style="height: 140px;border: 0;background-color: rgba(0, 0, 0, 0)" shadow="none" body-style="padding: 20px 20px 0 20px">
    <el-row style="display: flex;z-index: 1">
      <el-col :span="18">
        <el-row>
          <span style="display: inline-block;float: left;color: #FFFFFF;font-size: 18px;font-family: 'Microsoft YaHei',serif;;z-index: 0">{{ symbol }}
            <span :style="'display: inline-block;margin-left: 10px;margin-top: 2px;float: right;font-size: 16px;font-family: \'Microsoft YaHei\',serif;' + (symbolObj.rate > 0 ? 'color: #0ecb81' : 'color: #f6465d')">{{ symbolObj.rate > 0 ? '+' : '' }}{{ symbolObj.rate }}%</span>
          </span>
        </el-row>
        <el-row>
          <span style="display: inline-block;float: left;font-size: 28px;color: white;z-index: 0">{{ symbolObj.price }}</span>
        </el-row>
      </el-col>
      <el-col :span="6">
        <img :alt="symbol" :src="symbolIcon[symbol]" style="position: absolute;width: 460px;height: 460px;margin-left: -350px;margin-top: -180px;float: right;opacity: 0.025;z-index: -1">
        <img :alt="symbol" :src="symbolIcon[symbol]" style="width: 30px;height: 30px;margin-top: 8px;float: right">
      </el-col>
    </el-row>
    <el-row style="height: 50px;margin-left: -30px;width: calc(100% + 60px);z-index: 1">
      <div :id="symbol + '_simple_info_chart'" :ref="symbol + '_simple_info_chart'" style="height: 50px;width: 100%;z-index: 1"></div>
    </el-row>
  </el-card>
</template>

<script>
import DataApiService from "@/api/trade/DataApiService";

export default {
  name: "CoinSimpleInfo",
  props: {
    symbol: String
  },
  data: function () {
    return {
      symbolObj: {
        price: 0,
        rate: 0,
        xList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
        priceList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      symbolIcon: {
        BTCUSDT: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/coins/BTC.png',
        ETHUSDT: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/coins/ETH.png',
        BNBUSDT: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/coins/BNB.png',
        SOLUSDT: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/coins/SOL.png',
        APEUSDT: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/coins/APE.png'
      },
      coinStyle: {
        BTC: {
          showSymbol: false,
          name: 'BTC',
          type: 'line',
          data: [],
          smooth: true,
          itemStyle: {
            normal: {
              areaStyle: {
                type: 'default'
              },
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [{
                    offset: 0,
                    color: 'rgba(247,147,26, 0.3)'
                  },
                    {
                      offset: 0.5,
                      color: 'rgba(247,147,26, 0.1)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(247,147,26, 0.05)'
                    }
                  ]
              ),
              lineStyle: {        // 系列级个性化折线样式
                width: 2,
                type: 'solid',
                color: "rgb(247,147,26)"
              },
              opacity: 0
            }
          },
        },
        ETH: {
          showSymbol: false,
          name: 'ETH',
          type: 'line',
          data: [],
          smooth: true,
          itemStyle: {
            normal: {
              areaStyle: {
                type: 'default'
              },
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [{
                    offset: 0,
                    color: 'rgba(104, 126, 227, 0.3)'
                  },
                    {
                      offset: 0.5,
                      color: 'rgba(104, 126, 227, 0.1)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(104, 126, 227, 0.05)'
                    }
                  ]
              ),
              lineStyle: {        // 系列级个性化折线样式
                width: 2,
                type: 'solid',
                color: "rgb(104, 126, 227)"
              },
              opacity: 0
            }
          },
        },
        BNB: {
          showSymbol: false,
          name: 'USD',
          type: 'line',
          data: [],
          smooth: true,
          itemStyle: {
            normal: {
              areaStyle: {
                type: 'default'
              },
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [{
                    offset: 0,
                    color: 'rgba(240, 185, 10, 0.3)'
                  },
                    {
                      offset: 0.5,
                      color: 'rgba(240, 185, 10, 0.1)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(240, 185, 10, 0.05)'
                    }
                  ]
              ),
              lineStyle: {        // 系列级个性化折线样式
                width: 2,
                type: 'solid',
                color: "rgb(240, 185, 10)"
              },
              opacity: 0
            }
          },
        },
        SOL: {
          showSymbol: false,
          name: 'USD',
          type: 'line',
          data: [],
          smooth: true,
          itemStyle: {
            normal: {
              areaStyle: {
                type: 'default'
              },
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [{
                    offset: 0,
                    color: 'rgba(57, 189, 199, 0.3)'
                  },
                    {
                      offset: 0.5,
                      color: 'rgba(57, 189, 199, 0.1)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(57, 189, 199, 0.05)'
                    }
                  ]
              ),
              lineStyle: {        // 系列级个性化折线样式
                width: 2,
                type: 'solid',
                color: "rgb(57, 189, 199)"
              },
              opacity: 0
            }
          },
        },
        APE: {
          showSymbol: false,
          name: 'APE',
          type: 'line',
          data: [],
          smooth: true,
          itemStyle: {
            normal: {
              areaStyle: {
                type: 'default'
              },
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [{
                    offset: 0,
                    color: 'rgba(57, 135, 199, 0.3)'
                  },
                    {
                      offset: 0.5,
                      color: 'rgba(57, 135, 199, 0.1)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(57, 135, 199, 0.05)'
                    }
                  ]
              ),
              lineStyle: {        // 系列级个性化折线样式
                width: 2,
                type: 'solid',
                color: "rgb(57, 135, 199)"
              },
              opacity: 0
            }
          },
        }
      },
      queryTimer: {}
    }
  },
  created() {
    this.render();
    this.queryTimer = setInterval(this.render, 15000);
  },
  beforeDestroy() {
    clearInterval(this.queryTimer);
  },
  methods: {
    getOption() {
      let series = [{
        showSymbol: false,
        name: this.symbol + '_trend',
        type: 'line',
        data: this.symbolObj.priceList,
        smooth: true,
        itemStyle: this.coinStyle[this.symbol.replace('USDT', '')].itemStyle,
      }];
      let min = Math.min(...this.symbolObj.priceList);
      let max = Math.max(...this.symbolObj.priceList);
      return {
        calculable: true,
        xAxis: [{
          type: 'category',
          boundaryGap: true,
          show: false,
          data: this.symbolObj.xList,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          axisLabel: {
            show: false,
            textStyle: {
              fontSize: 8, //更改坐标轴文字大小
              color: 'white'
            }
          },
        }],
        grid: {
          x: 0,
          y: 1,
          x2: 0,
          y2: 1
        },
        yAxis: [{
          type: 'value',
          show: true,
          min: min,
          max: max,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          axisLabel: {
            show: false,
            textStyle: {
              color: 'white', //更改坐标轴文字颜色
              fontSize: 8 //更改坐标轴文字大小
            }
          },
        }],
        series: series
      };
    },
    render() {
      let that = this;
      let price = [];
      DataApiService.getKLine('BINANCE', this.symbol, 24, function (data) {
        that.symbolObj.price = data[23].close;
        that.symbolObj.rate = (((data[23].close - data[0].open) / data[0].open) * 100).toFixed(2);
        data.forEach((obj) => {
          price.push(obj.close);
        });
        that.symbolObj.priceList = price;
        if (!that.tradeVolChart && that.$refs[that.symbol + '_simple_info_chart']) {
          that.tradeVolChart = that.$echarts.init(that.$refs[that.symbol + '_simple_info_chart']);
          that.tradeVolChart.clear();
          that.tradeVolChart.setOption(that.getOption());
          window.addEventListener('resize', () => (that.tradeVolChart.resize()));
        }
      });
    }
  }
}
</script>

<style scoped>

</style>