<template>
  <div class="trialpha-full-sub-page" style="padding: 40px 80px">
    <el-skeleton animated :loading="rendering">
      <template slot="template">
        <el-card class="black-card" style="width: 100%;height: 80px;margin-top: 8px;background-color: rgba(0, 0, 0, 0);border: 0" shadow="none" body-style="padding: 0">
          <el-row style="display: flex;margin-top: 4px">
            <trialpha-sk style="width: 60%;margin-top: 15px;float: left;margin-left:10px"></trialpha-sk>
          </el-row>
          <el-row style="display: flex;margin-top: 12px">
            <trialpha-sk style="width: 80%;margin-top: 15px;float: left;margin-left:10px"></trialpha-sk>
          </el-row>
        </el-card>
        <el-card class="black-card" style="border-radius: 0;height: calc(100vh - 176px);border: 0;background-color: rgba(0, 0, 0, 0);overflow-x: auto;display: flex" shadow="none" body-style="padding: 8px 0 8px 0">
          <div :style="'width: ' + (315 * 10) + 'px;display:flex'">
            <el-card class="black-card" style="border-radius: 0;height: calc(100vh - 200px);width: 313px;margin-left: 0;margin-right: 8px;float: left" v-for="(item) in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" :key="item" shadow="none">
              <el-row>
                <trialpha-sk style="margin-left: 15px;margin-top: 15px;width: 25%"></trialpha-sk>
              </el-row>
              <el-row style="margin-top: 40px;">
                <el-card style=";width: calc(100% + 40px);height: calc(100vh - 302px);margin-left: -20px;border-radius: 0;background-color: rgba(0, 0, 0, 0);border: 0;overflow-y: auto" body-style="padding: 5px" shadow="none">
                  <trialpha-sk style="margin-left: 10px;width: 60%"></trialpha-sk>
                  <trialpha-sk style="margin-left: 10px;margin-top: 15px;width: 80%"></trialpha-sk>
                  <trialpha-sk style="margin-left: 10px;margin-top: 15px;width: 70%"></trialpha-sk>
                  <trialpha-sk style="margin-left: 10px;margin-top: 15px;width: 80%"></trialpha-sk>
                  <trialpha-sk style="margin-left: 10px;margin-top: 15px;width: 80%"></trialpha-sk>
                </el-card>
              </el-row>
            </el-card>
          </div>
        </el-card>
      </template>
      <template>
        <el-card class="black-card" style="width: 100%;height: 80px;margin-top: 8px;background-color: rgba(0, 0, 0, 0);border: 0" shadow="none" body-style="padding: 0">
          <el-row style="display: flex;margin-top: 4px">
            <el-button-group>
              <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 68px" @click="typeFilter = 'ALL'" :disabled="typeFilter === 'ALL'">全部</el-button>
              <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 68px" @click="typeFilter = 'NONE'" :disabled="typeFilter === 'NONE'">未分配</el-button>
              <el-button class="dark-mode-btn ellipsis-line" v-for="type in projectView.data.typeList" :key="type.projectTypeKey" size="small" style="width: 68px" @click="typeFilter = type.projectTypeName" :disabled="typeFilter === type.projectTypeName">{{ type.projectTypeName }}</el-button>
              <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 198px" @click="createNewIssue">Create a new case</el-button>
              <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 128px" @click="loadProjectView">刷新</el-button>
            </el-button-group>
            <el-input class="trialpha-dark-input-small trialpha-dark-input-para" placeholder="关键字搜索" style="margin-top: -1px;height: 20px;width: 268px;margin-left: 25px" v-model="searchKey" size="small">
              <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">搜索:</span></template>
            </el-input>
          </el-row>
          <el-row style="display: flex;margin-top: 12px">
            <el-button-group>
              <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 68px" @click="userFilter = 'ALL'" :disabled="userFilter === 'ALL'">全部</el-button>
              <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 68px" @click="userFilter = 'NONE'" :disabled="userFilter === 'NONE'">未指派</el-button>
              <el-button class="dark-mode-btn ellipsis-line" v-for="user in projectView.data.userList" :key="user.userName" size="small" style="width: 68px" @click="userFilter = user.userName" :disabled="userFilter === user.userName">{{ user.userName }}</el-button>
            </el-button-group>
          </el-row>
        </el-card>
        <el-card class="black-card" style="border-radius: 0;height: calc(100vh - 176px);border: 0;background-color: rgba(0, 0, 0, 0);overflow-x: auto;display: flex" shadow="none" body-style="padding: 8px 0 8px 0">
          <div :style="'width: ' + (315 * projectView.data.statusList.filter((s) => statusTypeKeyIssueListMap[s.key]).length) + 'px;display:flex'">
            <el-card class="black-card" style="border-radius: 0;height: calc(100vh - 200px);width: 313px;margin-left: 0;margin-right: 8px;float: left" v-for="status in projectView.data.statusList.filter((s) => statusTypeKeyIssueListMap[s.key])" :key="status.key" shadow="none">
              <el-row>
                <span class="dark-mode-font" style="display: inline-block;text-align: left;float: left;color: #FFFFFF">{{ status.label }} ({{statusTypeKeyIssueListMap[status.key] ? statusTypeKeyIssueListMap[status.key].length : 0}})</span>
              </el-row>
              <el-row style="margin-top: 40px;">
                <el-card style=";width: calc(100% + 40px);height: calc(100vh - 302px);margin-left: -20px;border-radius: 0;background-color: rgba(0, 0, 0, 0);border: 0;overflow-y: auto" body-style="padding: 5px" shadow="none">
                  <el-card class="black-card" style="margin-top: 8px;width: 100%;height: 140px;" body-style="padding: 20px 20px 20px 5px" shadow="none" v-for="issue in statusTypeKeyIssueListMap[status.key].filter((s) => filter(s))" :key="issue.issueKey">
                    <el-tooltip class="item" effect="dark" :content="issue.issueTitle" placement="top">
                      <el-row>
                        <el-col :span="1">
                          <el-card shadow="none" :style="'height: 158px;margin-top: -20px;margin-left: -5px;width: 6px;border-radius: 3px 0 0 3px;border: 0;' + ('background-color:' + issuePriorityColor[issue.issuePriority]) + ''"></el-card>
                        </el-col>
                        <el-col :span="21">
                          <el-row>
                            <span class="ellipsis-line" style="margin-top: -12px;margin-left: -8px;color: rgb(87,147,216);display: inline-block;float: left;text-align: left;font-size: 13px;font-weight: bold">{{ issue.issueTitle }}</span>
                          </el-row>
                          <el-row style="margin-top: 25px;display: flex">
                            <el-card style="background-color: rgba(0, 0, 0, 0);width: 50px;height: 28px;border-radius: 0;border: 1px solid gray" body-style="padding: 0">
                              <span style="color: gray;font-size: 12px">#{{ issue.issueNo }}</span>
                            </el-card>
                            <el-card v-if="typeKeyTypeMap[issue.projectTypeKey]" :style="'' + ('background-color: ' + (typeKeyTypeMap[issue.projectTypeKey] ? typeKeyTypeMap[issue.projectTypeKey].projectTypeBackground : 'black')) + ';margin-left:10px;width: 50px;height: 28px;border-radius: 3px;border: 0'" body-style="padding: 0">
                              <span style="color: #FFFFFF;font-size: 12px">{{ issue.issueType }}</span>
                            </el-card>
                            <el-card style="background-color: rgba(0, 0, 0, 0);margin-left: -5px;text-align: left;margin-top: -2px;width: 100px;height: 28px;border-radius: 3px;border: 0" body-style="padding: 0" shadow="none">
                              <span class="ellipsis-line" style="color: lightgray;font-size: 12px;display: inline-block;float: left"><i class="el-icon-link" style="transform: rotate(45deg)" /> {{ issue.issueAssignee }}</span>
                            </el-card>
                          </el-row>
                          <el-row style="margin-top: 6px">
                            <el-card style="background-color: rgba(0, 0, 0, 0);margin-left: 0;text-align: left;margin-top: -2px;width: 100%;height: 28px;border-radius: 3px;border: 0" body-style="padding: 0" shadow="none">
                              <span class="ellipsis-line" style="margin-left: -7px;color: gray;font-size: 12px;display: inline-block;float: left"><i class="el-icon-time" /> {{ issue.issueCreated }}</span>
                            </el-card>
                          </el-row>
                        </el-col>
                        <el-col :span="2">
                          <el-button class="dark-mode-btn" @click="openDetails(issue)" style="height: 158px;margin-top: -21px;border-radius: 0;border-top: 0;padding: 5px;float: right;margin-right: -22px;line-height: 108px">
                            <i class="el-icon-s-tools" style="margin-bottom: 60px"></i>
                          </el-button>
                        </el-col>
                      </el-row>
                    </el-tooltip>
                  </el-card>
                </el-card>
              </el-row>
            </el-card>
          </div>
        </el-card>
      </template>
    </el-skeleton>
    <issue-details ref="issue-details" @update="loadProjectView"></issue-details>
    <create-issue ref="create-issue" @update="loadProjectView"></create-issue>
  </div>
</template>

<script>

import TracupService from "@/api/tracup/TracupService";
import IssueDetails from "@/pages/controlPanel/adminPanel/IssueDetail";
import CreateIssue from "@/pages/controlPanel/adminPanel/CreateIssue";
import {Message} from "element-ui";
import TrialphaSk from "@/components/TrialphaSk";

export default {
  name: "TraCup",
  components: {TrialphaSk, CreateIssue, IssueDetails},
  data: function () {
    return {
      searchKey: '',
      typeFilter: 'ALL',
      userFilter: 'ALL',
      rendering: true,
      projectView: {
        data: {
          info: {},
          priorityList: [],
          roleList: [],
          statusList: [],
          typeList: [],
          userList: []
        }
      },
      statusTypeKeyIssueListMap: {},
      typeKeyTypeMap: {},
      statusKeyStatusMap: {},
      issuePriorityColor: {
        '3': '#55ccf4',
        '2': '#febc11',
        '1': '#ec465a'
      },
      userMap: {}
    }
  },
  created() {
    this.loadProjectView();
  },
  methods: {
    filter(s) {
      if (this.typeFilter === 'ALL') {
        return this.filterUser(s);
      } else if (this.typeFilter === 'NONE') {
        if (s.issueType === null) {
          return this.filterUser(s);
        }
      } else {
        if (this.typeFilter === s.issueType) {
          return this.filterUser(s);
        }
      }
    },
    filterUser(s) {
      if (this.userFilter === 'ALL') {
        return this.filterTitle(s);
      } else if (this.userFilter === 'NONE') {
        if (s.issueAssignee === '未指派') {
          return this.filterTitle(s);
        }
      } else {
        if (this.userFilter === s.issueAssignee) {
          return this.filterTitle(s);
        }
      }
    },
    filterTitle(s) {
      if (this.searchKey === 'ALL') {
        return true;
      } else {
        if (s.issueTitle.toLowerCase().indexOf(this.searchKey.toLowerCase()) > -1) {
          return true;
        }
      }
    },
    init() {
      this.projectView = {
        data: {
          info: {},
          priorityList: [],
              roleList: [],
              statusList: [],
              typeList: [],
              userList: []
        }
      };
      this.statusTypeKeyIssueListMap = {};
      this.typeKeyTypeMap = {};
      this.statusKeyStatusMap = {};
      this.issuePriorityColor = {
        '3': '#55ccf4',
            '2': '#febc11',
            '1': '#ec465a'
      };
      this.userMap = {};
    },
    loadProjectView() {
      let that = this;
      that.init();
      that.rendering = true;
      let statusTypeKeyIssueListMapTmp = {};
      setTimeout(() => {
        if (that.rendering === true) {
          Message.info('数据仍然还在加载，请稍后...');
        }
      }, 1068);
      TracupService.queryProjectAllIssue(function (issue) {
        for (const issueKey in issue.data.list) {
          let obj = issue.data.list[issueKey];
          if (statusTypeKeyIssueListMapTmp[obj.issueStatus]) {
            statusTypeKeyIssueListMapTmp[obj.issueStatus].push(obj);
          } else {
            statusTypeKeyIssueListMapTmp[obj.issueStatus] = [obj];
          }
        }
        that.statusTypeKeyIssueListMap = statusTypeKeyIssueListMapTmp;
        TracupService.queryProjectView(function (data) {
          let typeKeyTypeMapTmp = {};
          let statusKeyStatusMapTmp = {};
          let userMapTmp = {};
          for (const dataKey in data.data.typeList) {
            let obj = data.data.typeList[dataKey];
            typeKeyTypeMapTmp[obj.projectTypeKey] = obj;
          }
          for (const dataKey in data.data.statusList) {
            let obj = data.data.statusList[dataKey];
            statusKeyStatusMapTmp[obj.key] = obj;
          }
          for (const dataKey in data.data.userList) {
            let obj = data.data.userList[dataKey];
            userMapTmp[obj.userName] = obj;
          }
          that.typeKeyTypeMap = typeKeyTypeMapTmp;
          that.statusKeyStatusMap = statusKeyStatusMapTmp;
          that.userMap = userMapTmp;
          that.projectView = data;
          that.rendering = false;
        });
      });
    },
    openDetails(issue) {
      this.$refs['issue-details'].render(issue, this.issuePriorityColor, this.typeKeyTypeMap, this.statusKeyStatusMap, this.userMap);
    },
    createNewIssue() {
      this.$refs['create-issue'].render(this.issuePriorityColor, this.typeKeyTypeMap, this.statusKeyStatusMap, this.userMap);
    }
  },
}
</script>

<style scoped>

.trialpha-dark-input-small .el-input__inner {
  color: white;
  font-size: 12px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-radius: 0 3px 3px 0;
  outline: none;
  padding: 0;
}

.trialpha-dark-input-small .el-input-group__prepend {
  background-color: #373e47;
  border: #444c56 1px solid;
  border-right: 0;
  border-radius: 3px 0 0 3px;
  padding: 0 10px 0 10px;
}

.trialpha-dark-input-small .el-input__inner:hover {
  outline: none;
  border: 1px solid #444c56;
}

.trialpha-dark-input-small .el-input__inner:focus {
  outline: none;
  border: 1px solid #444c56;
}

.trialpha-dark-input-small .el-input__inner:disabled {
  background-color: #373e47;
  border: #444c56 1px solid;
  outline: none;
}

.ellipsis-line {
  padding: 8px;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 2px;
  height: 10px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  background-color: #22272e;
  border: 1px solid #3E454E;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 8px 8px;
  padding: 2px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 0 0 8px 8px;
  background: #3e454e;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #3e454e;
}

</style>