<script type="text/javascript">
import AbmNet from "@/api/net/AbmNet";
import ServiceMapper from "@/const/ServiceMapper"

function create(instanceId, userCommissionRate, forthtechCommissionRate, walletAddress, walletProtocol, timeStartInSeconds, timeEndInSeconds, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.settlementCreate, {
    instanceId: instanceId,
    userCommissionRate: userCommissionRate,
    forthtechCommissionRate: forthtechCommissionRate,
    walletAddress: walletAddress,
    walletProtocol: walletProtocol,
    timeStartInSeconds: timeStartInSeconds,
    timeEndInSeconds: timeEndInSeconds
  }, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

export default {
  create
}
</script>