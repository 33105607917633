<template>
  <div class="panel-scoped">
  
    <el-row>
      <el-col :span="24">
        <FtGroupButtonWidget :buttons="[{
            id: 'append',
            text: '我创建的',
            textI18n: '',
        }]" mode="single" textMode="true" v-on:switch-change="onAction" 
        style="background: #3E454E;" />
      </el-col>
    </el-row>

    <div style="margin-top: 20px;">

      <FtOperationListWidget 
        :columns="columns" :data="createdByRequester" 
        :scrollHeight="scrollHeight"
        :buttons="[{
              id: 'updateWallet',
              text: '修改',
              textI18n: '',
              width: '110px',
              padding: '0 10px 0 10px'
          }]" v-on:switch-change="onAction"
          oWidth="110px" style="border-bottom: 1px solid #3E454E;"/>

    </div>

  </div>
</template>

<script>
// import {Message} from "element-ui";
// import ConfirmProvider from '@/components/ConfirmProvider'

// import CrmChannelManagement from "@/api/crm/CrmChannelManagement";
import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";
import FtOperationListWidget from "@/components/widgets/FtOperationListWidget";

import { useWalletOutsideStore } from '@/store/wallet'

export default {
  components: {
    FtGroupButtonWidget,
    FtOperationListWidget
  },
  props: {},
  data() {
    return {
      // 列表信息
      columns: [
        {textI18n: '', text: '钱包名', align: 'left', width: '60%', name: 'humanReadableName'},
        {textI18n: '', text: '审核通过人数', align: 'left', width: '20%', name: 'requireApprovalNumber'},
        {textI18n: '', text: '总人数', align: 'left', width: '20%', name: 'approvers', render: (name, rowScope) => {
          return rowScope[name] ? rowScope[name].length : 0
        },},
      ],

      // 滚动面板高度
      scrollHeight: 0,
    }
  },
  computed: {
    // 我创建的
    createdByRequester: () => {
      let walletOutsideStore = useWalletOutsideStore()
      let index = 1
      return walletOutsideStore.createdByRequester.map(item => {
        item.index = index ++
        return item
      })
    }
  },
  mounted() {

    // var windowHeight = window.innerHeight
    this.scrollHeight = 275

  },
  methods: {
    // 各种切换处理
    onAction(item) {
      console.log('我创建的地址', item.id)
      if (item.id == 'updateWallet') {
        this.$emit('switch-change', item)
      }
    },
  }
}
</script>

<style scoped>
.panel-scoped{
  background: #22272E;
  padding: 20px;
  border: 1px solid #3E454E;
  border-radius: 8px;
}

.panel-scoped .ft-checkbox{
  width: 20px; height: 20px; border-radius: 4px; border: 1px solid #3E454E; margin-top: 6px;
  cursor: pointer;
}

.panel-scoped .ft-checkbox > div{
  width: 12px; height: 12px; background: transparent; border-radius: 2px; margin: 3px;
}

.panel-scoped .ft-checkbox.active > div{
  background: #fff;
}
</style>