<template>
  <div :id=id ref="tradeVolChart" :color="color" :width="width" style="height: 100%;width: 100%"></div>
</template>

<script>

export default {
  name: 'tradeVolChart',
  components: {},
  props: {
    id: String,
    color: String,
    width: String
  },
  data: function () {
    return {
      init: false,
      timeList: [],
      price: [],
      data: []
    }
  },
  created: function () {
  },
  methods: {
    getProfitOptionOnlyProfit(xTimeList, profitList) {
      let series = [{
        showSymbol: true,
        name: this.$i('controlPanelHome.system7Vol'),
        type: 'line',
        data: profitList,
        smooth: true,
        lineStyle: {
          color: this.color,
          width: Number(this.width),   //这里是坐标轴的宽度,可以去掉
        }
      }];
      return {
        tooltip: {
          trigger: 'axis'
        },
        color:[this.color],
        legend: {
          orient: 'vertical',
          data: [this.$i('controlPanelHome.system7Vol')],
          textStyle:{
            color:'white',
            fontSize:10
          }
        },
        calculable: true,
        xAxis: [{
          type: 'category',
          boundaryGap: true,
          show: true,
          data: xTimeList,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: 8, //更改坐标轴文字大小
              color: 'white'
            }
          },
        }],
        grid: {
          x: 50,
          y: 35,
          x2: 18,
          y2: 25
        },
        yAxis: [{
          type: 'value',
          show: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: 'white', //更改坐标轴文字颜色
              fontSize: 8 //更改坐标轴文字大小
            }
          },
        }],
        series: series
      };
    },
    timestampToDate(timestamp) {
      let date = new Date(Number(timestamp));
      let Y = date.getFullYear() + '-';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let D = date.getDate() + ' ';
      let h = date.getHours() + ':';
      let m = date.getMinutes() + ':';
      let s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    render: function (data) {
      this.data = data;
      let that = this;
      let xTimeList = [];
      let volList = [];
      that.data.forEach((obj) => {
        xTimeList.push((obj.dateString));
        volList.push(obj.totalVal.toFixed(2));
      });
      if (that.$refs.tradeVolChart) {
        that.tradeVolChart = that.$echarts.init(that.$refs.tradeVolChart);
        that.tradeVolChart.clear();
        that.tradeVolChart.setOption(that.getProfitOptionOnlyProfit(xTimeList, volList));
        window.addEventListener('resize', () => (that.tradeVolChart.resize()));
      }
    }
  }
}
</script>