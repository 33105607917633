<template>
  <div>
    <div>
      <!-- 挂单数据 Header -->
      <el-card class="black-card" style="border-radius: 3px 3px 0 0;border-bottom: 0;background-color: #1c2128"
               body-style="padding: 10px" shadow="none">
        <el-row>
          <el-col :span="8">
            <span class="dark-mode-font" style="color: white">Active orders</span>
          </el-col>
          <el-col :span="16">
            <input class="dark-mode-input" v-model="filterActiveName" placeholder="Filter..."
                   style="color: white;height: 25px;outline: none;font-size: 12px;background-color: rgba(0, 0, 0, 0);border: 0;width: 98px;float: right;border-bottom: 1px solid #444c56;padding-left: 18px;padding-bottom: 1px"/>
            <i class="el-icon-search" style="float: right;color: #444c56;margin-top: 5px;margin-right: -15px"></i>
          </el-col>
        </el-row>
      </el-card>
      <!-- 挂单数据表头 -->
      <el-card class="black-card" style="border-radius: 0;height: 28px;background-color: #1c2128" shadow="none"
               body-style="padding: 4px 10px 3px 10px">
        <el-row>
          <el-col :span="7">
            <span class="dark-mode-font"
                  style="display: inline-block;float: left;text-align: left;font-size: 12px;color:darkgray ">Symbol</span>
          </el-col>
          <el-col :span="6">
            <span class="dark-mode-font"
                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">Size</span>
          </el-col>
          <el-col :span="6">
            <span class="dark-mode-font"
                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">Price</span>
          </el-col>
          <el-col :span="5">
            <span class="dark-mode-font"
                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">Operation</span>
          </el-col>
        </el-row>
      </el-card>
      <!-- 挂单数据展示 -->
      <el-card class="black-card"
               style="border-radius: 0;border-top: 0;border-bottom: 0;max-height: 224px;overflow-y: auto" shadow="none"
               body-style="padding: 0">
        <el-skeleton animated :loading="loading.loadingActiveOrders" :throttle="200">
          <template slot="template">
            <trialpha-sk :style="'margin-top: 20px;width: 60%;float: left;margin-left: 10px'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left: 10px'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left;margin-left: 10px'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 90%;float: left;margin-left: 10px'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left: 10px'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left;margin-left: 10px'"></trialpha-sk>
            <trialpha-sk
                style="margin-top: 20px;margin-bottom: 20px;width: 90%;float: left;margin-left: 10px"></trialpha-sk>
          </template>
          <template>
            <div v-if="activeOrders.length > 0">
              <el-card class="black-card-hover" shadow="none" style="border: 0;border-radius: 0" body-style="padding:0"
                       v-for="(p, index) in activeOrders.filter((f) => doActiveFilter(f))" :key="index">
                <el-row style="height: 25px;border-bottom: 1px solid #2e3033;padding: 3px 10px 3px 10px">
                  <el-col :span="7">
                    <span class="dark-mode-font"
                          style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{
                        p['symbol']
                      }}</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font"
                          :style="'display: inline-block;float: right;text-align: right;font-size: 12px;' + ((p['side'].toUpperCase() === 'BUY' || p['side'].toUpperCase() === 'LONG') > 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{
                        p['filledSize']
                      }} / {{ p['realAmount'] }}</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font"
                          style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{
                        p['realCostPrice']
                      }}</span>
                  </el-col>
                  <el-col :span="5">
                    <el-button class="dark-mode-btn"
                               style="border-radius: 0;border-top: 0;border-bottom: 0;border-right: 0;height: 26px;line-height: 26px;float: right;margin-top: -5px;font-size: 12px;padding: 0;width: 68px;margin-right: -10px"
                               @click="cancelOrder(p['symbol'], p['orderID'])">Cancel
                    </el-button>
                  </el-col>
                </el-row>
              </el-card>
            </div>
            <div v-if="activeOrders.length === 0">
              <el-row
                  style="height: 25px;border: 0;background-color: rgba(0, 0, 0, 0);padding: 3px 10px 3px 10px;text-align: center;margin-top: 20px;margin-bottom: 25px">
                <span class="dark-mode-font" style=";font-size: 12px;color:gray;text-align: center;"><i
                    class="el-icon-s-order"/> EMPTY ORDERS...</span>
              </el-row>
            </div>
          </template>
        </el-skeleton>
      </el-card>
    </div>

    <div v-if="symbolType !== 'SPOT'">
      <!-- 持仓数据 Header -->
      <el-card class="black-card" style="border-radius: 0;border-bottom: 0;background-color: #1c2128"
               body-style="padding: 10px" shadow="none">
        <el-row>
          <el-col :span="6">
            <span class="dark-mode-font" style="color: white">Positions</span>
          </el-col>
          <el-col :span="8">
            <el-button-group style="float: right;padding: 0;width: 100%">
              <el-button class="dark-mode-btn ellipsis-line" size="small"
                         style="width: 50%;height: 25px;line-height: 25px;padding: 0;text-align: center"
                         @click="positionInfoType = 'Details'" :disabled="positionInfoType === 'Details'">Details
              </el-button>
              <el-button class="dark-mode-btn ellipsis-line" size="small"
                         style="width: 50%;height: 25px;line-height: 25px;padding: 0;text-align: center"
                         @click="positionInfoType = 'FundingFee'" :disabled="positionInfoType === 'FundingFee'">F. Fee
              </el-button>
            </el-button-group>
          </el-col>
          <el-col :span="10">
            <input class="dark-mode-input" v-model="filterName" placeholder="Filter..."
                   style="color: white;height: 25px;outline: none;font-size: 12px;background-color: rgba(0, 0, 0, 0);border: 0;width: 98px;float: right;border-bottom: 1px solid #444c56;padding-left: 18px;padding-bottom: 1px"/>
            <i class="el-icon-search" style="float: right;color: #444c56;margin-top: 5px;margin-right: -15px"></i>
          </el-col>
        </el-row>
      </el-card>
      <!-- 持仓数据表头 -->
      <el-card class="black-card" v-if="positionInfoType === 'Details'"
               style="border-radius: 0;height: 28px;background-color: #1c2128" shadow="none"
               body-style="padding: 4px 10px 3px 10px">
        <el-row>
          <el-col :span="6">
            <span class="dark-mode-font"
                  style="display: inline-block;float: left;text-align: left;font-size: 12px;color:darkgray ">Symbol</span>
          </el-col>
          <el-col :span="1">
            <span class="dark-mode-font"
                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">L.</span>
          </el-col>
          <el-col :span="6">
            <span class="dark-mode-font"
                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray;cursor: pointer"
                  @click="sortBy('position')">Size<i class="el-icon-d-caret"></i></span>
          </el-col>
          <el-col :span="6">
            <span class="dark-mode-font"
                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray">Price</span>
          </el-col>
          <el-col :span="5">
            <span class="dark-mode-font"
                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray;cursor: pointer"
                  @click="sortBy('pnl')">PNL<i class="el-icon-d-caret"></i></span>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="black-card" v-if="positionInfoType === 'FundingFee'"
               style="border-radius: 0;height: 28px;background-color: #1c2128" shadow="none"
               body-style="padding: 4px 10px 3px 10px">
        <el-row>
          <el-col :span="6">
            <span class="dark-mode-font"
                  style="display: inline-block;float: left;text-align: left;font-size: 12px;color:darkgray ">Symbol</span>
          </el-col>
          <el-col :span="1">
            <span class="dark-mode-font"
                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">L.</span>
          </el-col>
          <el-col :span="6">
            <span class="dark-mode-font"
                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray;cursor: pointer"
                  @click="sortBy('usd')">USD Value<i class="el-icon-d-caret"></i></span>
          </el-col>
          <el-col :span="6">
            <span class="dark-mode-font"
                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray;cursor: pointer"
                  @click="sortBy('funding', 7)">7Days<i class="el-icon-d-caret"></i></span>
          </el-col>
          <el-col :span="5">
            <span class="dark-mode-font"
                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray;cursor: pointer"
                  @click="sortBy('funding', 30)">30Days<i class="el-icon-d-caret"></i></span>
          </el-col>
        </el-row>
      </el-card>
      <!-- 持仓数据展示 -->
      <el-card class="black-card"
               style="border-radius: 0;border-top: 0;border-bottom: 0;max-height: 199px;overflow-y: auto" shadow="none"
               body-style="padding: 0">
        <el-skeleton animated :loading="loading.loadingPosition" :throttle="200">
          <template slot="template">
            <trialpha-sk :style="'margin-top: 20px;width: 60%;float: left;margin-left: 10px'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left: 10px'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left;margin-left: 10px'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 90%;float: left;margin-left: 10px'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left: 10px'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left;margin-left: 10px'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 90%;float: left;margin-left: 10px'"></trialpha-sk>
          </template>
          <template>
            <div v-if="position.length > 0">
              <el-card class="black-card-hover" shadow="none" style="border: 0;border-radius: 0" body-style="padding:0"
                       v-for="(p, index) in (position.filter((f) => doPositionFilter(f.symbol)))" :key="index">
                <el-row style="height: 25px;border-bottom: 1px solid #2e3033;padding: 3px 10px 3px 10px">
                  <el-col :span="6">
                    <span class="dark-mode-font"
                          style="width: 90%;display: inline-block;float: left;text-align: left;font-size: 12px;color:white;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{
                        p['symbol']
                      }}</span>
                  </el-col>
                  <el-col :span="1">
                    <span class="dark-mode-font"
                          style="display: inline-block;float: right;text-align: left;font-size: 12px;color:white ">{{
                        p['leverage']
                      }}X</span>
                  </el-col>
                  <div v-if="positionInfoType === 'Details'">
                    <el-col :span="6">
                      <span class="dark-mode-font"
                            :style="'display: inline-block;float: right;text-align: right;font-size: 12px;' + (Number(p['position']) > 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{
                          p['position']
                        }}</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="dark-mode-font"
                            style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{
                          p['entryPrice']
                        }}</span>
                    </el-col>
                    <el-col :span="5">
                      <span class="dark-mode-font"
                            :style="'display: inline-block;float: right;text-align: right;font-size: 12px;' + (Number(p['pnl']) > 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{
                          p['pnl']
                        }}</span>
                    </el-col>
                  </div>
                  <div v-if="positionInfoType === 'FundingFee'">
                    <el-col :span="6">
                      <span class="dark-mode-font"
                            :style="'display: inline-block;float: right;text-align: right;font-size: 12px;' + (Number(p['usd']) > 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">${{
                          p['usd']
                        }}</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="dark-mode-font"
                            :style="'display: inline-block;float: right;text-align: right;font-size: 12px;' + (getFundingFee(p['symbol'], 7) > 0 ? 'color: #f6465d;' : 'color: #0ecb81;') + ''">{{
                          getFundingFee(p['symbol'], 7)
                        }}%</span>
                    </el-col>
                    <el-col :span="5">
                      <span class="dark-mode-font"
                            :style="'display: inline-block;float: right;text-align: right;font-size: 12px;' + (getFundingFee(p['symbol'], 30) > 0 ? 'color: #f6465d;' : 'color: #0ecb81;') + ''">{{
                          getFundingFee(p['symbol'], 30)
                        }}%</span>
                    </el-col>
                  </div>
                </el-row>
              </el-card>
            </div>
            <div v-if="position.length === 0">
              <el-row
                  style="height: 25px;border: 0;background-color: rgba(0, 0, 0, 0);padding: 3px 10px 3px 10px;text-align: center;margin-top: 20px;margin-bottom: 25px">
                <span class="dark-mode-font" style=";font-size: 12px;color:gray;text-align: center;"><i
                    class="el-icon-s-order"/> EMPTY POSITION...</span>
              </el-row>
            </div>
          </template>
        </el-skeleton>
      </el-card>
      <!-- 仓位概述 -->
      <el-card class="black-card" style="border-radius: 0;height: 68px;overflow-y: auto;border-bottom: 0" shadow="none"
               body-style="padding: 10px">
        <el-row>
          <span class="dark-mode-font"
                style="color: darkgray;float: left;font-size: 12px">仓位美元价值: {{ getTotalPositionUsdV() }}</span>
          <span class="dark-mode-font" style="color: darkgray;float: right;font-size: 12px">总美元价值PNL: <span
              :style="'' + (Number(getTotalPnlUsdV()) > 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{
              getTotalPnlUsdV()
            }}</span></span>
        </el-row>
        <el-row style="margin-top: 8px">
          <span class="dark-mode-font" style="color: darkgray;float: left;font-size: 12px">总杠杆: {{
              (Number(getTotalPositionUsdV()) / totalAssetUsd).toFixed(2)
            }}X</span>
        </el-row>
      </el-card>
    </div>

    <div>
      <el-collapse class="trialpha-collapse-position-panel" v-model="historyPanel" style="border: 0">
        <el-collapse-item class="trialpha-collapse-position-panel" style="border: 0;padding: 0">
          <template slot="title">
            <el-row style="width: 100%;display: flex;padding-left: 10px">
              <span class="dark-mode-font ellipsis-line" :style="'display: inline-block;float: left;margin-left: 0;color: #FFFFFF'">History</span>
            </el-row>
          </template>
          <!-- 历史数据表头 -->
          <el-card class="black-card" style="border-radius: 0;border-top: 0;border-left: 0;border-right: 0;height: 28px;background-color: #1c2128" shadow="none"
                   body-style="padding: 4px 10px 3px 10px">
            <el-row>
              <el-col :span="6">
                <span class="dark-mode-font"
                      style="display: inline-block;float: left;text-align: left;font-size: 12px;color:darkgray ">Symbol</span>
              </el-col>
              <el-col :span="4">
                <span class="dark-mode-font"
                      style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">Size</span>
              </el-col>
              <el-col :span="5">
                <span class="dark-mode-font"
                      style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray;cursor: pointer">Price</span>
              </el-col>
              <el-col :span="3">
                <span class="dark-mode-font"
                      style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray">Type</span>
              </el-col>
              <el-col :span="6">
                <span class="dark-mode-font"
                      style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray;cursor: pointer">Time</span>
              </el-col>
            </el-row>
          </el-card>
          <!-- 历史数据展示 -->
          <el-card class="black-card"
                   style="border-radius: 0;border-left: 0;border-right: 0;border-top: 0;border-bottom: 0;max-height: 199px;overflow-y: auto"
                   shadow="none" body-style="padding: 0">
            <el-skeleton animated :loading="loading.loadingHistoryOrders" :throttle="200">
              <template slot="template">
                <trialpha-sk :style="'margin-top: 10px;width: 60%;float: left;margin-left: 10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left: 10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left;margin-left: 10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 90%;float: left;margin-left: 10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left: 10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left;margin-left: 10px'"></trialpha-sk>
                <trialpha-sk style="margin-top: 20px;margin-bottom: 10px;width: 40%;float: left;margin-left: 10px"></trialpha-sk>
              </template>
              <template>
                <div v-if="historyOrders.length > 0">
                  <el-card class="black-card-hover" shadow="none" style="border: 0;border-radius: 0"
                           body-style="padding:0"
                           v-for="(p, index) in (historyOrders.filter((f) => doHistoryPositionFilter(f.symbol)))"
                           :key="index">
                    <el-row style="height: 25px;border-bottom: 1px solid #2e3033;padding: 3px 10px 3px 10px">
                      <el-col :span="6">
                        <span class="dark-mode-font"
                              style="width: 90%;display: inline-block;float: left;text-align: left;font-size: 12px;color:white;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{
                            p['symbol']
                          }}</span>
                      </el-col>
                      <el-col :span="4">
                        <span class="dark-mode-font"
                              :style="'display: inline-block;float: right;text-align: right;font-size: 12px;' + ((p['side'].toUpperCase() === 'BUY') ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{
                            p['realAmount']
                          }}</span>
                      </el-col>
                      <el-col :span="5">
                        <span class="dark-mode-font"
                              style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{
                            p['realCostPrice']
                          }}</span>
                      </el-col>
                      <el-col :span="3">
                        <span class="dark-mode-font"
                              style="display: inline-block;float: right;text-align: left;font-size: 12px;color:white ">{{
                            p['type']
                          }}</span>
                      </el-col>
                      <el-col :span="6">
                        <span class="dark-mode-font"
                              style="width: 90%;display: inline-block;float: right;text-align: right;font-size: 12px;color:white;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{
                            p['time']
                          }}</span>
                      </el-col>
                    </el-row>
                  </el-card>
                </div>
                <div v-if="historyOrders.length === 0">
                  <el-row
                      style="height: 25px;border: 0;background-color: rgba(0, 0, 0, 0);padding: 3px 10px 3px 10px;text-align: center;margin-top: 20px;margin-bottom: 25px">
                    <span class="dark-mode-font" style=";font-size: 12px;color:gray;text-align: center;"><i
                        class="el-icon-s-order"/> EMPTY HISTORY...</span>
                  </el-row>
                </div>
              </template>
            </el-skeleton>
          </el-card>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div>
      <!-- 保证金模块 Header -->
      <el-card class="black-card" style="border-radius: 0;border-bottom: 0;background-color: #1c2128"
               body-style="padding: 10px" shadow="none">
        <el-row>
          <el-col :span="6">
            <span class="dark-mode-font" style="color: white">Margin</span>
          </el-col>
          <el-col :span="8">
            <el-button-group style="float: right;padding: 0;width: 100%">
              <el-button class="dark-mode-btn ellipsis-line" size="small"
                         style="width: 50%;height: 25px;line-height: 25px;padding: 0;text-align: center"
                         @click="balanceInfoType = 'ALL'" :disabled="balanceInfoType === 'ALL'">ALL
              </el-button>
              <el-button class="dark-mode-btn ellipsis-line" size="small"
                         style="width: 50%;height: 25px;line-height: 25px;padding: 0;text-align: center"
                         @click="balanceInfoType = 'NOT0'" :disabled="balanceInfoType === 'NOT0'">Not 0
              </el-button>
            </el-button-group>
          </el-col>
          <el-col :span="10">
            <input class="dark-mode-input" v-model="filterMarginName" placeholder="Filter..."
                   style="color: white;height: 25px;outline: none;font-size: 12px;background-color: rgba(0, 0, 0, 0);border: 0;width: 98px;float: right;border-bottom: 1px solid #444c56;padding-left: 18px;padding-bottom: 1px"/>
            <i class="el-icon-search" style="float: right;color: #444c56;margin-top: 5px;margin-right: -15px"></i>
          </el-col>
        </el-row>
      </el-card>
      <!-- 保证金模块表头 -->
      <el-card class="black-card" style="border-radius: 0;height: 28px;background-color: #1c2128" shadow="none"
               body-style="padding: 4px 10px 3px 10px">
        <el-row>
          <el-col :span="8">
            <span class="dark-mode-font"
                  style="display: inline-block;float: left;text-align: left;font-size: 12px;color:darkgray ">Asset</span>
          </el-col>
          <el-col :span="8">
            <span class="dark-mode-font"
                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray;cursor: pointer"
                  @click="sortBalanceBy('marginBalance')">Margin<i class="el-icon-d-caret"></i></span>
          </el-col>
          <el-col :span="8">
            <span class="dark-mode-font"
                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray;cursor: pointer"
                  @click="sortBalanceBy('usd')">USD Value<i class="el-icon-d-caret"></i></span>
          </el-col>
        </el-row>
      </el-card>
      <!-- 保证金模块余额展示 -->
      <el-card class="black-card" style="border-radius: 0 3px;border-top: 0;max-height: 100px;overflow-y: auto"
               shadow="none" body-style="padding: 0">
        <el-skeleton animated :loading="loading.loadingAssets" :throttle="200">
          <template slot="template">
            <trialpha-sk :style="'margin-top: 20px;width: 60%;float: left;margin-left: 10px'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left: 10px'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left;margin-left: 10px'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 90%;float: left;margin-left: 10px'"></trialpha-sk>
          </template>
          <template>
            <div v-if="balanceArray.filter((f) => doMarginBalanceFilter(f.asset, f)).length > 0 ">
              <el-card class="black-card-hover" shadow="none" style="border: 0;border-radius: 0" body-style="padding:0"
                       v-for="(p, index) in balanceArray.filter((f) => doMarginBalanceFilter(f.asset, f))" :key="index">
                <el-row style="height: 25px;border-bottom: 1px solid #2e3033;padding: 3px 10px 3px 10px">
                  <el-col :span="8">
                    <span class="dark-mode-font"
                          style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{
                        p['asset']
                      }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="dark-mode-font"
                          style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{
                        p['marginBalance']
                      }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="dark-mode-font"
                          style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">${{
                        p['usd']
                      }}</span>
                  </el-col>
                </el-row>
              </el-card>
            </div>
            <div v-if="balanceArray.filter((f) => doMarginBalanceFilter(f.asset, f)).length === 0">
              <el-row
                  style="height: 25px;border: 0;background-color: rgba(0, 0, 0, 0);padding: 3px 10px 3px 10px;text-align: center;margin-top: 20px;margin-bottom: 25px">
                <span class="dark-mode-font" style=";font-size: 12px;color:gray;text-align: center;"><i
                    class="el-icon-s-finance"/> EMPTY MARGIN...</span>
              </el-row>
            </div>
          </template>
        </el-skeleton>
      </el-card>
    </div>
  </div>
</template>

<script>
import GeneralApiService from "@/api/trade/GeneralApiService";
import generalApiService from "@/api/trade/GeneralApiService";
import TrialphaSk from "@/components/TrialphaSk";
import SymbolInfoService from "@/utils/SymbolInfoService";
import {Message} from "element-ui";
import SymbolFundingFeeService from "@/utils/SymbolFundingFeeService";

export default {
  name: "GeneralPositionPanel",
  components: {TrialphaSk},
  data: function () {
    return {
      balanceInfoType: 'NOT0',
      positionInfoType: 'Details',
      filterActiveName: '',
      filterName: '',
      historyFilterName: '',
      filterMarginName: '',
      allPrice: {},
      position: [],
      activeOrders: [],
      historyOrders: [],
      totalPositionUsd: 0,
      totalPNL: 0,
      totalLeverage: 0,
      totalAssetUsd: 0,
      symbolType: generalApiService.SYMBOL_TYPE.USD_CONTRACT,
      loading: {
        loadingPosition: false,
        loadingAssets: false,
        loadingActiveOrders: false,
        loadingHistoryOrders: false
      },
      balanceArray: [],
      apiKey: '',
      platform: '',
      sortRule: {
        position: 'Default',
        pnl: 'Default',
        funding: 'Default',
        usd: 'Default',
        marginBalance: 'Default',
        changeSort: function (sort) {
          if (sort === 'Default') {
            return 'HighToLow';
          } else if (sort === 'HighToLow') {
            return 'LowToHigh';
          } else if (sort === 'LowToHigh') {
            return 'HighToLow';
          }
        }
      },
      historyPanel: []
    }
  },
  methods: {
    render(platform, symbolType, apiKey) {
      let that = this;
      this.apiKey = apiKey;
      this.platform = platform;
      that.loading.loadingPosition = true;
      that.loading.loadingAssets = true;
      that.loading.loadingActiveOrders = true;
      that.loading.loadingHistoryOrders = true;
      that.symbolType = symbolType;
      let positionInner = [];
      let positionUsdInner = 0;
      let totalPnlInner = 0;
      if (that.symbolType !== GeneralApiService.SYMBOL_TYPE.SPOT) {
        GeneralApiService.getAllPositions(platform, symbolType, apiKey, function (res) {
          for (const dataKey in res) {
            let symbolModel = SymbolInfoService.getSymbolModel(platform, symbolType, res[dataKey].symbol);
            res[dataKey].entryPrice = Number(res[dataKey].entryPrice.toFixed(symbolModel.pricePrecision));
            res[dataKey].pnl = Number(res[dataKey].pnl.toFixed(3));
            res[dataKey].usd = Number(((res[dataKey].position) * SymbolInfoService.getSymbolPrice(that.platform, that.symbolType, res[dataKey].symbol) * (symbolModel.contractValue === 0 ? 1 : symbolModel.contractValue)).toFixed(2));
            if ((platform === GeneralApiService.PLATFORM.BINANCE && symbolType === GeneralApiService.SYMBOL_TYPE.COIN_CONTRACT) || (platform === GeneralApiService.PLATFORM.OKX && res[dataKey].symbol.split('-').length > 1)) {
              res[dataKey].usd = Number(((res[dataKey].position) * symbolModel.contractValue).toFixed(2));
            }
            positionInner.push(res[dataKey]);
            positionUsdInner += res[dataKey].usd;
            let pnl = res[dataKey].pnl;
            if ((platform === GeneralApiService.PLATFORM.BINANCE && symbolType === GeneralApiService.SYMBOL_TYPE.COIN_CONTRACT) || (platform === GeneralApiService.PLATFORM.OKX && res[dataKey].symbol.split('-').length > 1 && (res[dataKey].symbol.indexOf('-USD-') > -1))) {
              pnl = Number((res[dataKey].pnl * SymbolInfoService.getSymbolPrice(that.platform, that.symbolType, res[dataKey].symbol)).toFixed(3));
            }
            totalPnlInner += pnl;
          }
          that.position = positionInner;
          that.totalPNL = totalPnlInner;
          that.totalPositionUsd = positionUsdInner;
          that.loading.loadingPosition = false;
        }, function (err) {
          console.error(err);
        });
      }
      let assetTotalUsdValue = 0;
      GeneralApiService.getAssetsBalance(platform, symbolType, apiKey, function (res) {
        let priceArray = [];
        for (const resKey in res) {
          let usdValue = 0;
          if (resKey === 'USD' || resKey === 'USDT' || resKey === 'USDC' || resKey === 'BUSD') {
            usdValue = res[resKey][0];
          } else {
            let price = SymbolInfoService.getSymbolPrice(that.platform, that.symbolType, SymbolInfoService.getSymbolName(that.platform, that.symbolType, resKey));
            if (price) {
              usdValue = res[resKey][0] * price;
            }
          }
          assetTotalUsdValue += usdValue;
          priceArray.push({
            asset: resKey,
            marginBalance: res[resKey][0],
            usd: usdValue.toFixed(2)
          });
        }
        that.balanceArray = priceArray;
        that.totalAssetUsd = assetTotalUsdValue;
        that.loading.loadingAssets = false;
        that.$forceUpdate();
      }, function (err) {
        console.error(err);
      });
      GeneralApiService.getAllActiveOrders(platform, symbolType, apiKey, function (res) {
        that.activeOrders = res;
        that.loading.loadingActiveOrders = false;
        that.$forceUpdate();
      }, function (err) {
        console.error(err);
      });
      GeneralApiService.getOrderHistory(platform, symbolType, apiKey, function (res) {
        try {
          let ordersTmp = [];
          for (const resKey in res) {
            let symbolModel = SymbolInfoService.getSymbolModel(platform, symbolType, res[resKey].symbol);
            if (symbolModel) {
              res[resKey].realCostPrice = Number(res[resKey].realCostPrice.toFixed(symbolModel.pricePrecision));
            }
            ordersTmp.push(res[resKey]);
          }
          that.historyOrders = ordersTmp;
          that.loading.loadingHistoryOrders = false;
          that.$forceUpdate();
        } catch (e) {
          console.log(e);
        }
      }, function (err) {
        console.error(err);
      });
    },
    doPositionFilter(symbol) {
      if (!this.filterName || this.filterName === '') {
        return symbol;
      } else if (symbol.toLowerCase().indexOf(this.filterName.toLowerCase()) > -1) {
        return symbol;
      }
    },
    doHistoryPositionFilter(symbol) {
      if (!this.historyFilterName || this.historyFilterName === '') {
        return symbol;
      } else if (symbol.toLowerCase().indexOf(this.historyFilterName.toLowerCase()) > -1) {
        return symbol;
      }
    },
    doMarginBalanceFilter(symbol, obj) {
      if (this.balanceInfoType === 'ALL' || obj.marginBalance > 0 || obj.marginBalance < 0) {
        if (!this.filterMarginName || this.filterMarginName === '') {
          return symbol;
        } else if (symbol.toLowerCase().indexOf(this.filterMarginName.toLowerCase()) > -1) {
          return symbol;
        }
      }
    },
    doActiveFilter(obj) {
      let symbol = obj.symbol;
      if (!this.filterActiveName || this.filterActiveName === '') {
        return symbol;
      } else if (symbol.toLowerCase().indexOf(this.filterActiveName.toLowerCase()) > -1) {
        return symbol;
      }
    },
    cancelOrder(symbol, orderId) {
      let that = this;
      GeneralApiService.cancelOrder(this.platform, this.symbolType, this.apiKey, symbol, orderId, function () {
        Message.success('Canceled success');
        that.loading.loadingActiveOrders = true;
        GeneralApiService.getAllActiveOrders(that.platform, that.symbolType, that.apiKey, function (res) {
          that.activeOrders = res;
          that.loading.loadingActiveOrders = false;
          that.$forceUpdate();
        }, function (err) {
          console.error(err);
        });
      });
    },
    getFundingFee(symbol, days) {
      return Number(SymbolFundingFeeService.getFundingFee(this.platform, this.symbolType, symbol, days).toFixed(2));
    },
    getTotalPositionUsdV() {
      let positionUsdInner = 0;
      let res = this.position.filter(s => this.doPositionFilter(s.symbol));
      for (const dataKey in res) {
        let symbolModel = SymbolInfoService.getSymbolModel(this.platform, this.symbolType, res[dataKey].symbol);
        let usdPosition = Math.abs(res[dataKey].position) * SymbolInfoService.getSymbolPrice(this.platform, this.symbolType, res[dataKey].symbol) * (symbolModel.contractValue === 0 ? 1 : symbolModel.contractValue);
        if ((this.platform === GeneralApiService.PLATFORM.BINANCE && this.symbolType === GeneralApiService.SYMBOL_TYPE.COIN_CONTRACT) || (this.platform === GeneralApiService.PLATFORM.OKX && res[dataKey].symbol.split('-').length > 1)) {
          usdPosition = Number(((res[dataKey].position) * symbolModel.contractValue).toFixed(2));
        }
        positionUsdInner += usdPosition
      }
      return positionUsdInner.toFixed(2);
    },
    getTotalPnlUsdV() {
      let positionUsdInner = 0;
      let res = this.position.filter(s => this.doPositionFilter(s.symbol));
      for (const dataKey in res) {
        let pnl = res[dataKey].pnl;
        if ((this.platform === GeneralApiService.PLATFORM.BINANCE && this.symbolType === GeneralApiService.SYMBOL_TYPE.COIN_CONTRACT) || (this.platform === GeneralApiService.PLATFORM.OKX && res[dataKey].symbol.split('-').length > 1 && (res[dataKey].symbol.indexOf('-USD-') > -1))) {
          pnl = Number((res[dataKey].pnl * SymbolInfoService.getSymbolPrice(this.platform, this.symbolType, res[dataKey].symbol)).toFixed(3));
        }
        positionUsdInner += pnl;
      }
      return positionUsdInner.toFixed(2);
    },
    sortBy(sortKey, extraPara) {
      this.sortRule[sortKey] = this.sortRule.changeSort(this.sortRule[sortKey]);
      this.position.sort((a, b) => {
        if ((sortKey === 'funding' ? this.getFundingFee(a['symbol'], extraPara) : a[sortKey]) < (sortKey === 'funding' ? this.getFundingFee(b['symbol'], extraPara) : b[sortKey])) {
          if (this.sortRule[sortKey] === 'LowToHigh') {
            return -1;
          } else {
            return 1;
          }
        } else if ((sortKey === 'funding' ? this.getFundingFee(a['symbol'], extraPara) : a[sortKey]) > (sortKey === 'funding' ? this.getFundingFee(b['symbol'], extraPara) : b[sortKey])) {
          if (this.sortRule[sortKey] === 'LowToHigh') {
            return 1;
          } else {
            return -1;
          }
        }
        return 0;
      });
    },
    sortBalanceBy(sortKey, extraPara) {
      this.sortRule[sortKey] = this.sortRule.changeSort(this.sortRule[sortKey]);
      this.balanceArray.sort((a, b) => {
        if ((sortKey === 'funding' ? this.getFundingFee(a['symbol'], extraPara) : a[sortKey]) < (sortKey === 'funding' ? this.getFundingFee(b['symbol'], extraPara) : b[sortKey])) {
          if (this.sortRule[sortKey] === 'LowToHigh') {
            return -1;
          } else {
            return 1;
          }
        } else if ((sortKey === 'funding' ? this.getFundingFee(a['symbol'], extraPara) : a[sortKey]) > (sortKey === 'funding' ? this.getFundingFee(b['symbol'], extraPara) : b[sortKey])) {
          if (this.sortRule[sortKey] === 'LowToHigh') {
            return 1;
          } else {
            return -1;
          }
        }
        return 0;
      });
    }
  }
}
</script>

<style scoped>


.el-collapse {
  color: #FFFFFF;
  border-top: 1px solid #444c56;
  border-bottom: 1px solid #444c56;
}

.trialpha-collapse-position-panel .el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: white;
}

.trialpha-collapse-position-panel .el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  padding: 0;
  color: white;
}

</style>