import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import bVue, {BootstrapVueIcons} from 'bootstrap-vue'
import MuseUI from 'muse-ui'
import Helpers from 'muse-ui/lib/Helpers';
import VueRippler from 'vue-rippler';
import NProgress from 'muse-ui-progress';
import 'muse-ui/dist/muse-ui.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'muse-ui-progress/dist/muse-ui-progress.css';
import ElementUI from '@/utils/ElementUiC';
import 'element-ui/lib/theme-chalk/index.css';
import ToggleButton from 'vue-js-toggle-button';
import layer from "vue-layer";
import "vue-layer/lib/vue-layer.css";
import I18NService from '@/i18n/I18NService';
import ThemeService from "@/theme/ThemeService";
import "@/assets/black.css";
import '@/assets/tradeMgntStyle.css';
import '@/assets/tradeMgntMedia.css';
import '@/assets/trialpha-common.css'
import '@/assets/loading.css';
import Skeleton from 'vue-skeleton-antd';
import echarts from 'echarts'
import 'element-ui/lib/theme-chalk/display.css';
import UserPermissionService from "@/api/user/UserPermissionService";
import Decimal from "decimal.js";
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css';
import excel from 'vue-excel-export';
import {createPinia,PiniaVuePlugin} from 'pinia';
import vueToPdf from 'vue-to-pdf';

Vue.use(vueToPdf);
Vue.use(PiniaVuePlugin)
const pinia = createPinia()
// use
Vue.use(excel);
Vue.use(mavonEditor)
Vue.prototype.$echarts = echarts;
Vue.use(Skeleton);
Vue.prototype.$layer = layer(Vue);
Vue.use(ToggleButton);
Vue.use(ElementUI);
Vue.use(bVue);
Vue.use(MuseUI);
Vue.use(NProgress);
Vue.use(Helpers);
Vue.use(VueRippler);
Vue.use(I18NService);
Vue.use(ThemeService);
Vue.use(BootstrapVueIcons);
Vue.config.productionTip = false;
Vue.directive('auth',{
  // 只调用一次，指令第一次绑定到元素时调用，用这个钩子函数可以定义一个在绑定时执行一次的初始化动作。
  bind(el, binding){
    let permission = UserPermissionService.getFeaturePermission(binding.arg);
    if (permission === UserPermissionService.permission_type.HIDE) {
      el.hidden = true;
    } else if (permission === UserPermissionService.permission_type.DISABLED) {
      el.disabled = true;
      el.style.cursor = 'not-allowed';
    } else if (permission === UserPermissionService.permission_type.ALLOWED) {
      el.hidden = false;
      el.disabled = false;
    } else {
      el.hidden = true;
    }
  },
});


String.prototype.formatUnicorn = String.prototype.formatUnicorn || function () {
  "use strict";
  let str = this.toString();
  if (arguments.length) {
    let t = typeof arguments[0];
    let key;
    let args = ("string" === t || "number" === t) ?
        Array.prototype.slice.call(arguments)
        : arguments[0];

    for (key in args) {
      str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
    }
  }
  return str;
};

function accAdd(arg1, arg2) {
  return Decimal.add(arg1, arg2);
}

function accSub(arg1, arg2) {
  return Decimal.sub(arg1, arg2);
}

function accMul(num1, num2) {
  return Decimal.mul(num1, num2);
}

function accDiv(num1, num2) {
  return Decimal.div(num1, num2);
}

Number.prototype.accAdd = function (arg1, arg2) {
  return accAdd(arg1, arg2);
}

Number.prototype.accSub = function (arg1, arg2) {
  return accSub(arg1, arg2);
}

Number.prototype.accMul = function (arg1, arg2) {
  return accMul(arg1, arg2);
}

Number.prototype.accDiv = function (arg1, arg2) {
  return accDiv(arg1, arg2);
}

Number.prototype.toFormatNum = function toThousands(num) {
  const str = num.toString();
  const reg = str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g
      : /(\d)(?=(?:\d{3})+$)/g;
  return str.replace(reg,"$1,");
}

new Vue({
  render: h => h(App),
  router,
  pinia
}).$mount('#app');
