<template>
  <div class="trialpha-stander-sub-page">
    <el-card id="news-container" class="black-card" style="width: 100%;height: calc(100vh - 145px);margin-top: 55px" body-style="padding: 0">
      <el-row>
        <el-card class="black-card" body-style="padding:5px" style="border-radius: 3px 3px 0 0;height: 35px;background-color: #32383e;border: 0">
          <span class="dark-mode-font" style="color: #FFFFFF;overflow-y: auto;display: inline-block;float: left;margin-left: 10px;font-size: 14px;margin-top: 1px">Market news</span>
          <span class="dark-mode-font" style=";overflow-y: auto;display: inline-block;float: right;margin-right: 10px;font-size: 13px;margin-top: 2px">{{ timeInfo }}</span>
        </el-card>
      </el-row>
      <el-row>
        <el-card class="black-card" style="width: 100%;height: calc(100vh - 185px);overflow-y: auto;border: 0;border-radius: 0 0 3px 3px" body-style="padding: 0">
          <newest style="width: 100%;height: 100%"></newest>
        </el-card>
      </el-row>
    </el-card>
  </div>
</template>

<script>

import TimeService from "@/utils/TimeService";
import Newest from "@/pages/controlPanel/accountMgnt/Newest";

export default {
  name: "MobileNews",
  components: {Newest},
  data: function () {
    return {
      timeInterval: {},
      timeInfo: 'Mon 00:00:00'
    }
  },
  created() {
    let that = this;
    this.timeInterval = setInterval(() => {
      that.timeInfo = TimeService.getSimpleTimeInfo();
    }, 1000);
  },
  destroyed: function () {
    clearInterval(this.timeInterval);
  },
  methods: {
  }
}
</script>

<style scoped>


.mobile-user-mgnt-select .el-input__inner {
  color: white;
  height: 35px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-radius: 3px;
  font-size: 12px;
}

</style>