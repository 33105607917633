<script type="text/javascript">

import AbmNet from "@/api/net/AbmNet";
import ServiceMapper from "@/const/ServiceMapper";

let ALL_CHAINS = {
  BTC: 'BTC',
  ETH: 'ETH',
  OKC: 'OKC',
  BSC: 'BSC',
  TRON: 'TRON',
  POLYGON: 'POLYGON',
  AVAXC: 'AVAXC',
  OPTIMISM: 'OPTIMISM',
  ARBITRUM: 'ARBITRUM'
}

function getTokenList(chain, address, success, error) {
  AbmNet.getRequest(ServiceMapper.CHAIN_UI_SERVICE.getTokenList + '?chainShortName=' + chain + '&address=' + address, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getAddressSummary(chain, address, success, error) {
  AbmNet.getRequest(ServiceMapper.CHAIN_UI_SERVICE.getAddressSummary + '?chainShortName=' + chain + '&address=' + address, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getErc721List(address, success, error) {
  AbmNet.getRequest(ServiceMapper.CHAIN_UI_SERVICE.getERC721List + '?address=' + address, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getErc721Info(address, tokenId, success, error) {
  AbmNet.getRequest(ServiceMapper.CHAIN_UI_SERVICE.getERC721Info + '?address=' + address + '&tokenId=' + tokenId, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function talkGPT(openAiMessageList, success, error) {
  AbmNet.postRequest(ServiceMapper.CHAIN_UI_SERVICE.talkGPT, openAiMessageList, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

export default {
  ALL_CHAINS,
  getAddressSummary,
  getTokenList,
  getErc721List,
  getErc721Info,
  talkGPT
};
</script>