<template>
  <div v-if="walletInfo.networkName === chainRequired">
    <el-card class="trade-black-card">
      <el-row>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: left">GMX: GLP Stake</span>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: right">{{ chainRequired }}</span>
      </el-row>
      <el-row style="margin-top: -10px">
        <el-divider class="data-divider"></el-divider>
      </el-row>
      <el-row style="margin-top: -10px">
        <span class="dark-mode-font" style="color: white;display: inline-block;float: left">GLP Price</span>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: right;font-family: 'Microsoft YaHei',serif">${{ glpPrice.toFixed(4) }}</span>
      </el-row>
      <el-row>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: left">GLP Stake Amount</span>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: right;font-family: 'Microsoft YaHei',serif">{{ glpAmount.toFixed(2) }} (${{(glpAmount * glpPrice).toFixed(2)}})</span>
      </el-row>
      <el-row>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: left">Reward (ETH)</span>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: right;font-family: 'Microsoft YaHei',serif">{{ ethReward.toFixed(4) }} (${{(ethReward * getPrice('AVAXUSDT')).toFixed(2)}})</span>
      </el-row>
      <el-row>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: left">Reward (esGMX)</span>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: right;font-family: 'Microsoft YaHei',serif">{{ esGmxReward.toFixed(4) }} (${{(esGmxReward * getPrice('GMXUSDT')).toFixed(2)}})</span>
      </el-row>
      <el-row>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: left">APR</span>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: right;font-family: 'Microsoft YaHei',serif">{{ ethApr.toFixed(2) }}%</span>
      </el-row>
      <el-row>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: left">Current Profit Rate</span>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: right;font-family: 'Microsoft YaHei',serif">{{ (((ethReward * getPrice('AVAXUSDT') + esGmxReward * getPrice('GMXUSDT')) / (glpAmount * glpPrice)) * 100).toFixed(3) }}%</span>
      </el-row>
    </el-card>
  </div>
</template>

<script>

import Web3Service from "@/web3/Web3Service";
import GmxService from "@/api/web3/GmxService";
import SymbolInfoService from "@/utils/SymbolInfoService";
import GeneralApiService from "@/api/trade/GeneralApiService";
import Web3 from "web3";

export default {
  name: "gmxAvax",
  data: function () {
    return {
      chainRequired: 'Avalanche',
      walletInfo: {},
      glpPrice: 0,
      glpAmount: 0,
      ethReward: 0,
      esGmxReward: 0,
      ethApr: 0
    }
  },
  methods: {
    init() {
      let web3Avax = new Web3('https://avalanche-mainnet.infura.io/v3/a570c72d3b0f452e82a99280f2dbeb76');
      web3Avax.eth.getBalance('0x1BF0573AD88305e6d1615a3CCAf65181CDFA8A20').then((res) => {
        console.log(res);
      });

      let that = this;
      that.initWalletInfo();
      Web3Service.getWeb3(that).currentProvider.on('networkChanged', function(){
        that.initWalletInfo();
      });
      Web3Service.getWeb3(that).currentProvider.on('accountsChanged', () => {
        that.initWalletInfo();
      });
    },
    initWalletInfo() {
      let that = this;
      Web3Service.initWalletInfo(this, function (res) {
        that.walletInfo = res;
        that.initGmxInfo();
      })
    },
    initGmxInfo() {
      let that = this;
      let web3 = Web3Service.getWeb3(this);
      let currentAddr = web3.currentProvider.selectedAddress;
      GmxService.getStakeInfoReward(false, currentAddr, function (res) {
        that.gmxPrice = res.gmxPrice;
        that.glpPrice = res.glpPrice;
        that.glpAmount = res.glpStake;
        that.ethReward = res.ethReward;
        that.ethApr = res.ethApr;
        that.esGmxReward = res.esGmxReward;
      });
    },
    getPrice(symbol) {
      return SymbolInfoService.getSymbolPrice(GeneralApiService.PLATFORM.BINANCE, GeneralApiService.SYMBOL_TYPE.SPOT, symbol);
    }
  }
}
</script>

<style scoped>

.el-divider.data-divider {
  border-top: 1px solid #444c56;
}

.data-divider > .el-divider__text {
  background-color: #22272e;
}

</style>