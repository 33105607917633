<template>
    <div class="create-customer-dialog" :style="[show ? 'display: block;' : 'display: none;']">

        <div class="create-customer-dialog-content">

            <div class="create-customer-dialog-content-inner">
                <div @click="eventSwitchChange({id: 'close'})" style="position: absolute; top: -56px; right: -46px; width: 32px; height: 32px; background: #3E454E; color: white; cursor: pointer; border-radius: 16px; font-size: 19px; padding: 3px;">
                    <i class="el-icon-close"></i>
                </div>
            
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; margin-bottom: 20px;">
                    <el-col :span="24" style="text-align: left;">
                        <FtGroupButtonWidget :buttons.sync="detailSwitchButtons" v-on:switch-change="eventSwitchChange" />
                    </el-col>
                </el-row>
                        
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="6" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                            <template v-if="currentPanel == 'personal'">
                                客户昵称
                            </template>
                            <template v-else>
                                渠道名称
                            </template>
                        </label>
                    </el-col>
                    <el-col :span="18" style="text-align: left;">
                        <input v-model="form.name" type="text" value="" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                    </el-col>
                </el-row>
                        
                <el-row v-if="currentPanel == 'personal'" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="6" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">渠道归属</label>
                    </el-col>
                    <el-col :span="18" style="text-align: left;">
                        <select v-model="form.channel" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;">
                            <option v-for="(channel, cindex) in channels" v-bind:key="cindex" :value="channel.id">
                                {{channel.name}}
                            </option>
                        </select>
                    </el-col>
                </el-row>
                        
                <el-row v-if="currentPanel == 'channel'" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="6" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">关联客户</label>
                    </el-col>
                    <el-col :span="18" style="text-align: left;">
                        <input v-model="form.relation" type="text" value="" placeholder="客户昵称，以逗号隔开。若无客户，则不输入" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                    </el-col>
                </el-row>
                
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; margin-top: 20px;">
                    <el-col :span="24">
                        <!-- 个人、渠道 新建 -->
                        <FtGroupButtonWidget :buttons="[{
                            id: 'append',
                            icon: 'el-icon-plus',
                            text: '新建',
                            textI18n: '',
                        }]" mode="single" v-on:switch-change="eventSwitchChange" style="float: none; display: inline-block; margin: auto; background: #3E454E;" />
                    </el-col>
                </el-row>
            </div>

        </div>

    </div>
</template>

<script>
import {Message} from "element-ui";
import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";

import CrmChannelManagement from "@/api/crm/CrmChannelManagement";
import UserService from "@/api/user/UserService";
import UserPermissionService from "@/api/user/UserPermissionService";

export default {
    components: {
        FtGroupButtonWidget,
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'personal'
        }
    },
    data() {
        return {
            userName: '',
            // 新建面板
            detailSwitchButtons: [
                {
                    id: 'personal',
                    text: '个人',
                    textI18n: '',
                    active: true
                },
                {
                    id: 'channel',
                    text: '渠道',
                    textI18n: '',
                    active: false
                }
            ],
            currentPanel: 'personal',

            channels: [],

            form: {
                name: '',
                relation: '',
                channel: 0
            }
            
        }
    },
    computed: {
        
    },
    mounted() {
        this.userName = UserService.getUserName()
        this.currentPanel = this.mode
        this.detailSwitchButtons = this.detailSwitchButtons.map(item => {
            if (item.id == this.mode) {
                item.active = true
            } else {
                item.active = false
            }
            return item
        })
        this.channelList()
    },
    methods: {

        // 加载渠道列表
        channelList() {
            return new Promise((resolve, reject) => {
                CrmChannelManagement.queryChannel(0, 999999, '', '',
                    (data) => {
                        let items = UserPermissionService.isAllowed('crmFullPermission') ? data.content : data.content.filter(item => {
                            return item.belong == this.userName
                        })
                        this.channels = [{id: 1, name: 'personal', belong: 'personal', contact: 'personal'}].concat(items)
                        resolve(items)
                    },
                    () => {
                        // Message.error(this.$i("operationFail"));
                        reject()
                    }
                );
            })
        },

        // 各种切换处理
        eventSwitchChange(item) {
            if (item.id == 'close') {
            console.log('switch change event', item.id)
                this.$emit('update:show', false)
            } else if (item.id == 'personal') {
                this.currentPanel = item.id
            } else if (item.id == 'channel') {
                this.currentPanel = item.id
            } else if (item.id == 'append') {
                // 新建
                if (this.currentPanel == 'personal') {
                    // 个人
                    if (this.form.name == '') {
                        Message.error('请输入客户昵称');
                        return 
                    }
                    if (this.form.channel == 0) {
                        Message.error('请选择渠道归属');
                        return 
                    }
                    if (CrmChannelManagement.countStringLength(this.form.name) > 36) {
                        Message.warning('客户昵称超长，最多36字符，汉字算两个字符');
                        return
                    }
                    let params = {
                        name: this.form.name,
                        channelId: this.form.channel,
                        accountTag: '',
                        coinName: "USDT",
                        followUser: UserService.getUserName(),
                        maxDrawdown: 20,
                        platform: "0",
                        progress: "接触中",
                        settlementTime: Date.now(),
                        startFundsAmount: 0.0,
                        strategyRemark: "",
                        fundUtilizationRate: 0.0,
                        user: UserService.getUserId(),
                    }
                    CrmChannelManagement.registerCustomer(params,
                        (res) => {
                            console.log(res)
                            this.$emit('update:show', false)
                            this.$emit('success', 'personal')
                        },
                        () => {
                            // Message.error(this.$i("operationFail"));
                        }
                    );
                } else if (this.currentPanel == 'channel') {
                    // 渠道
                    if (this.form.name == '') {
                        Message.error('请输入渠道名称');
                        return 
                    }
                    // if (this.form.relation == '') {
                    //     Message.error('请输入关联客户');
                    //     return 
                    // }
                    if (this.form.relation == '') {
                        this.form.relation = ''
                    }
                    if (CrmChannelManagement.countStringLength(this.form.name) > 16) {
                        Message.warning('渠道名称超长，最多16字符，汉字算两个字符');
                        return
                    }
                    // if (CrmChannelManagement.countStringLength(this.form.relation) > 64) {
                    //     Message.warning('关联客户超长，最多64字符，汉字算两个字符');
                    //     return
                    // }
                    CrmChannelManagement.addNewChannel(this.form.name, this.form.relation,
                        (res) => {
                            console.log(res)
                            this.$emit('update:show', false)
                            this.$emit('success', 'channel')
                        },
                        () => {
                            // Message.error(this.$i("operationFail"));
                        }
                    );
                }
            }
        },
        
    }
}
</script>

<style scoped>
.create-customer-dialog{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
}
.create-customer-dialog-content{
    background: #22272E;
    width: 500px;
    height: 280px;
    padding: 30px;
    border: 1px solid #3E454E;
    border-radius: 8px;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -250px;
    margin-top: -140px;
}
.create-customer-dialog-content-inner{
    position: relative;
}
</style>