<template>
    <div class="create-customer-dialog" :style="[show ? 'display: block;' : 'display: none;']">

        <div class="create-customer-dialog-content">

            <div class="create-customer-dialog-content-inner">
                <div @click="onAction({id: 'close'})" style="position: absolute; top: -46px; right: -46px; width: 32px; height: 32px; background: #3E454E; color: white; cursor: pointer; border-radius: 16px; font-size: 19px; padding: 3px;">
                    <i class="el-icon-close"></i>
                </div>
            
                <el-row class="hidden-sm-and-down" style="width: 100%;position: static; margin-bottom: 20px;">
                    <el-col :span="24" style="text-align: left;">
                        <FtGroupButtonWidget :buttons="[
                            {
                                id: 'detail',
                                text: '转账审批详情',
                                textI18n: '',
                                active: true
                            }
                        ]" />
                    </el-col>
                </el-row>
                        
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="12" style="text-align: center;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px;">
                            用户名
                        </label>
                    </el-col>
                    <el-col :span="12" style="text-align: center;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px;">
                            状态
                        </label>
                    </el-col>
                </el-row>

                <el-row v-for="item in userMappings" :key="item.user" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="12" style="text-align: center;">
                        {{item.user}}
                    </el-col>
                    <el-col :span="12" style="text-align: center;">
                        {{item.status}}
                    </el-col>
                </el-row>
                
                <!-- 步骤控制 -->
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; margin-top: 20px;">
                    <el-col :span="24">
                        <FtGroupButtonWidget :buttons="[{
                            id: 'close',
                            icon: '',
                            text: '确定',
                            textI18n: '',
                        }]" mode="single" v-on:switch-change="onAction" 
                        style="float: none; display: inline-block; margin: auto; background: #3E454E;" />
                    </el-col>
                </el-row>
            </div>

        </div>

    </div>
</template>

<script>
// import {Message} from "element-ui";
// import moment from "moment"
import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";

// import WalletManagement from "@/api/wallet/WalletManagement";
// import UserService from "@/api/user/UserService";


export default {
    components: {
        FtGroupButtonWidget,
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'cex'
        }
    },
    data() {
        return {
            userName: '',
            // 表单数据
            userMappings: [
                // {user: 'lining', status: '已通过'},
            ]
        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {

        setData(item) {
            console.log('转账详情, 设置参数', item)
            // this.form.id = item.id
            // this.currentPanel = item.walletType.toLowerCase()
            // if (item.approvers) {
            //     this.approverUsernameList = item.approvers.map(a => {
            //         return {
            //             value: a
            //         }
            //     })
            // }
            // this.form.requireApprovalNumber = item.requireApprovalNumber
            this.userMappings = item.allManagers.map(user => {
                var status = ''
                if (item.approvers && item.approvers.indexOf(user) > -1) {
                    status = '已通过'
                }
                if (item.decliners && item.decliners.indexOf(user) > -1) {
                    status = '已拒绝'
                }
                return {
                    user: user,
                    status: status ? status : '未审核'
                }
            })
            // this.form.createTimeDate = moment(item.createTime * 1000).format('YYYY/MM/DD HH:mm:ss')
        },

        // 各种切换处理
        onAction(item) {
            if (item.id == 'close') {
                console.log('switch change event', item.id)
                this.$emit('update:show', false)
            }
        },

        paddingZero(v) {
            return v > 9 ? v : ('0' + v)
        }
        
    }
}
</script>

<style scoped>
.create-customer-dialog{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    color: white;
}
.create-customer-dialog-content{
    background: #22272E;
    width: 420px;
    /* height: 360px; */
    padding: 30px;
    border: 1px solid #3E454E;
    border-radius: 8px;
    position: fixed;
    left: 50%;
    top: 30%;
    margin-left: -210px;
    margin-top: -140px;
}
.create-customer-dialog-content-inner{
    position: relative;
}
</style>
