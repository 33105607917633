<script type="text/javascript">


import ServiceMapper from "@/const/ServiceMapper";
import AbmNet from "@/api/net/AbmNet";

function create(req, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.instanceCreate, req, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function updateName(req, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.instanceUpdateName, req, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function query(success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.instanceQuery + "?page=0&size=10000", function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function bindCustomer(instanceId, customerName, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.instanceBindCustomer
      + "?customerName=" + customerName + "&instanceId=" + instanceId, {}, function (data) {
    success(data);
  }, function (err) {
    error(err)
  })
}

function queryBalance(coin, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.instanceQueryAllBalance + '?coin=' + coin, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function getAudit(page, size, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.instanceGetAudit + "?page=" + page + "&size=" + size, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function profitRate(instanceId, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.instanceProfitRate + "?instanceId=" + instanceId, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function reset(instanceId, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.instanceReset + '?instanceId=' + instanceId, {
    instanceId: instanceId
  }, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function forceRefresh(instanceId, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.instanceForceRefresh + '?instanceId=' + instanceId, {
    instanceId: instanceId
  }, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function link(instanceId, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.instanceLink + '?instanceIdAndName=' + instanceId, {
    instanceId: instanceId
  }, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function unlink(instanceId, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.instanceUnLink + '?instanceId=' + instanceId, {
    instanceId: instanceId
  }, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function deleteAccount(instanceId, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.instanceDelete + '?instanceId=' + instanceId + '&force=true', {
    instanceId: instanceId
  }, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

export default {
  query,
  create,
  profitRate,
  reset,
  forceRefresh,
  queryBalance,
  updateName,
  link,
  unlink,
  deleteAccount,
  getAudit,
  bindCustomer
}


</script>