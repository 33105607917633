<script type="text/javascript">


import ServiceMapper from "@/const/ServiceMapper";
import AbmNet from "@/api/net/AbmNet";

function create(request,success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.tradeAccountCreate, request, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function query(success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.tradeAccountQuery,{}, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function updateInfo(req, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.tradeAccountUpdateInfo, req, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}




export default {
  create,
  query,
  updateInfo
};
</script>

