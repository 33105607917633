<template>
  <div class="trialpha-stander-sub-page" style="padding-bottom: 100px">
    <el-row style="margin-top: 68px">
      <el-card class="black-card" style="width: 80%;margin: auto auto;">
        <AbmTokenMgnt/>
      </el-card>
    </el-row>
    <el-row>
      <el-card class="black-card" style="width: 80%;height: 298px;margin: 28px auto auto;">
        <div style="margin: auto;width: 368px;height: 298px">
          <el-row>
            <el-col :span="6">
              <span class="dark-mode-font" style="margin-top: 6px;display: inline-block">Alias</span>
            </el-col>
            <el-col :span="18">
              <el-input v-model="createObj.alias"></el-input>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="6">
              <span class="dark-mode-font" style="margin-top: 6px;display: inline-block">Email</span>
            </el-col>
            <el-col :span="18">
              <el-input v-model="createObj.email"></el-input>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="6">
              <span class="dark-mode-font" style="margin-top: 6px;display: inline-block">Role</span>
            </el-col>
            <el-col :span="18">
              <el-select class="select-basic-coin" v-model="createObj.userRole" placeholder="请选择" size="mini" style="width: 100%;float: right;margin-right: 0">
                <el-option
                    v-for="item in ROLE_MAP"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="6">
              <span class="dark-mode-font" style="margin-top: 6px;display: inline-block">Username</span>
            </el-col>
            <el-col :span="18">
              <el-input v-model="createObj.username"></el-input>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="6">
              <span class="dark-mode-font" style="margin-top: 6px;display: inline-block">Password</span>
            </el-col>
            <el-col :span="18">
              <el-input type="password" v-model="createObj.password"></el-input>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="18" :offset="6">
              <el-button class="dark-mode-btn" style="width: 100%" @click="createUser" :loading="creating" v-auth:dbWriteApi>Create User</el-button>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-row>
    <el-row>
      <el-card class="black-card" style="width: 80%;height: 898px;margin: 25px auto auto;">
        <h3 class="dark-mode-font" style="color: #FFFFFF;font-size: 18px">Web permission config</h3>
        <div style="height: 818px;width: 100%;overflow-y: auto">
          <el-collapse class="trialpha-collapse" v-model="activeNames" style="border: 0;margin-top: 25px">
            <el-collapse-item class="trialpha-collapse" style="border: 0" :name="role.role" v-for="role in roleArray" :key="role.id">
              <template slot="title">
                <el-row style="width: 100%">
                  <el-col :span="24" style="padding: 0">
                    <span class="dark-mode-font" style="">{{ role.role }}</span>
                  </el-col>
                </el-row>
              </template>
              <el-row style="height: 498px;overflow-y: auto">
                <el-card class="black-card" body-style="padding: 0" style="width: 100%;border-radius: 5px 5px 0 0 ;border-bottom: 0;height: 30px;line-height: 30px;margin-top: 15px;background-color: #454c56">
                  <el-row>
                    <el-col :span="8"><span class="dark-mode-font ellipsis-line-strategy" style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 8px;color: #FFFFFF">PermissionName</span></el-col>
                    <el-col :span="8"><span class="dark-mode-font ellipsis-line-strategy" style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 8px;color: #FFFFFF">Description</span></el-col>
                  </el-row>
                </el-card>
                <el-card class="black-card" body-style="padding: 0" style="width: 100%;height: 398px;overflow-y: auto;border-radius: 0">
                  <el-card class="black-card-hover" body-style="padding: 0" v-for="(item) in (role.featureConfigLists)" :key="item.id"
                           style="border-radius: 0;border-bottom: 1px solid #444c56;border-top: 0;height: 38px;line-height: 38px;border-left: 0;border-right: 0">
                    <el-row>
                      <el-col :span="8"><span class="dark-mode-font ellipsis-line-strategy" style="color: white;text-align: left;font-size: 12px;display: inline-block;float: left;margin-left: 8px">{{ item.featureName }}</span></el-col>
                      <el-col :span="8"><span class="dark-mode-font ellipsis-line-strategy" style="color: white;text-align: left;font-size: 12px;display: inline-block;float: left;margin-left: 8px">{{ webPermissionConfigMap[item.featureName] }}</span></el-col>
                      <el-col :span="8"><span class="dark-mode-font ellipsis-line-strategy" style="color: white;text-align: left;font-size: 12px;display: inline-block;float: left;margin-left: 8px">
                      <el-radio-group v-model="item.permission" @change="changePermission(role.role, item)">
                        <el-radio label="ENABLED">Enable</el-radio>
                        <el-radio label="DISABLED">Disable</el-radio>
                        <el-radio label="HIDE">Hide</el-radio>
                      </el-radio-group>
                      </span></el-col>
                    </el-row>
                  </el-card>
                </el-card>
                <el-card class="black-card" body-style="padding: 0" style="width: 100%;border-radius: 0 0 5px 5px ;border-top: 0;height: 45px;line-height: 45px;">
                  <el-row>
                    <el-col :span="6" v-if="role.role === 'SUPER_ADMIN'" style="padding-left: 2px">
                      <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="newFeatureName" placeholder="New feature name" size="small"></el-input>
                    </el-col>
                    <el-col :span="6" v-if="role.role === 'SUPER_ADMIN'" style="padding-left: 2px">
                      <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="newDesc" placeholder="Description" size="small"></el-input>
                    </el-col>
                    <el-col :span="6" v-if="role.role !== 'SUPER_ADMIN'" style="padding-left: 2px">
                      <el-select class="trialpha-dark-input trialpha-dark-input-para" style="width: 100%" v-model="newFeatureName" placeholder="New feature name" size="small">
                        <el-option
                            v-for="item in webPermissionConfigArray.filter((p) => {if(role.featureConfigLists.filter((f) => f.featureName === p).length === 0) { return p; }})"
                            :key="item"
                            :label="webPermissionConfigMap[item]"
                            :value="item">
                        </el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="6" v-if="role.role !== 'SUPER_ADMIN'" style="padding-left: 2px">
                      <el-input class="trialpha-dark-input trialpha-dark-input-para" :value="webPermissionConfigMap[newFeatureName]" placeholder="Description" size="small" disabled hidden></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-radio-group v-model="newPermission">
                        <el-radio label="ENABLED">Enable</el-radio>
                        <el-radio label="DISABLED">Disable</el-radio>
                        <el-radio label="HIDE">Hide</el-radio>
                      </el-radio-group>
                    </el-col>
                    <el-col :span="6">
                      <el-button class="dark-mode-btn" size="small" @click="changePermission(role.role, {featureName: '', permission: '', desc: ''})">ADD / UPDATE</el-button>
                    </el-col>
                  </el-row>
                </el-card>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-card>
    </el-row>
    <el-row style="margin-top: 2.5%;margin-bottom: 68px">
      <div style="width: 80%;margin-left: 10%">
        <b-row>
          <nav class="navbar navbar-dark bg-dark">
          <span class="navbar-brand mb-0 h1">{{
              $i("permission.userList")
            }}</span>
          </nav>
        </b-row>

        <b-row>
          <b-table
              id="userListTable"
              ref="userListTable"
              :fields="fields"
              :current-page="userListCurrentPage"
              :items="userListProvider"
              :per-page="userListPerPage"
              dark
              small
          >
            <template #table-busy>
              <div class="text-center text-info">
                <b-spinner class="align-middle"></b-spinner>
                <strong>{{ $i("loading") }}</strong>
              </div>
            </template>
            <template v-slot:cell(id)="data">
            <span
                :title="data.item.id"
                class="d-inline-block text-truncate"
                data-placement="top"
                data-toggle="tooltip"
                style="max-width: 150px"
            >
              {{ data.item.id }}
            </span>
            </template>
            <template v-slot:cell(action)="data">
              <b-row>
                <b-col>
                  <b-form-select
                      v-model="data.item.role.roleSelected"
                      :options="ROLE_MAP"
                      class="sm"
                      size="sm"
                  ></b-form-select>
                </b-col>
                <b-col>
                  <b-button
                      class="mr-1 text-center"
                      icon="exclamation-circle-fill"
                      size="sm"
                      @click="setToUser(data)"
                  >
                    {{ $i("confirm") }}
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-table>
        </b-row>
        <b-row align-content="center" align-h="center">
          <b-col cols="auto">
            <b-pagination
                v-model="userListCurrentPage"
                :per-page="userListPerPage"
                :total-rows="userListTotalRows"
                align="center"
                aria-controls="userListTable"
            ></b-pagination>
          </b-col>

        </b-row>

        <b-row>
          <nav class="navbar navbar-dark bg-dark">
          <span class="navbar-brand mb-0 h1">{{
              $i("permission.roleInstanceList")
            }}</span>
          </nav>
        </b-row>

        <b-row>
          <b-table
              ref="rolePermissionTable"
              :fields="rolePermissionFields"
              :items="rolePermissionListProvider"
              dark
              small
          >
            <template #table-busy>
              <div class="text-center text-info">
                <b-spinner class="align-middle"></b-spinner>
                <strong>{{ $i("loading") }}</strong>
              </div>
            </template>
            <template v-slot:cell(role)="data">
            <span class="d-inline-block text-left" style="max-width: 100px">
              {{ $i("permission." + data.item.role) }} ({{ data.item.role }})
            </span>
            </template>

            <template #cell(ReadFlag)="data">
            <span>
              <input
                  v-model="data.item.instancePermission.readFlag"
                  type="checkbox"
              />
            </span>
            </template>

            <template #cell(WriteFlag)="data">
            <span>
              <input
                  v-model="data.item.instancePermission.writeFlag"
                  type="checkbox"
              />
            </span>
            </template>

            <template #cell(DeleteFlag)="data">
            <span>
              <input
                  v-model="data.item.instancePermission.deleteFlag"
                  type="checkbox"
              />
            </span>
            </template>

            <template #cell(Action)="data">
            <span>
              <b-button @click="saveInstancePermission(data.item)"
              >Save</b-button
              >
            </span>
            </template>
          </b-table>
        </b-row>
      </div>
    </el-row>
  </div>
</template>

<script>
import {Message} from "element-ui";
import AdminUserManagement from "@/api/user/AdminUserManagement";
import UserService from "@/api/user/UserService";
import UserPermissionService from "@/api/user/UserPermissionService";
import AbmTokenMgnt from "@/web3/abmToken/AbmTokenMgnt";

export default {
  name: "UserPermissionManagement",
  components: {AbmTokenMgnt},
  data: function () {
    return {
      newDesc: '',
      newFeatureName: '',
      newPermission: 'DISABLED',
      activeNames: ['1'],
      creating: false,
      createObj: {
        alias: '',
        email: '',
        username: '',
        password: '',
        userRole: ''
      },
      userListCurrentPage: 1,
      userListPerPage: 10,
      userListTotalRows: 0,
      fields: [
        {key: "id", label: "ID"},
        {key: "username", label: this.$i("login.username")},
        {key: "email", label: this.$i("login.email")},
        {key: "alias", label: this.$i("userInfo.alias")},
        {key: "status", label: this.$i("userInfo.status")},
        {key: "role.role", label: this.$i("userInfo.role")},
        {key: "action", label: this.$i("action")},
      ],
      rolePermissionFields: [
        {
          key: "role",
          label: this.$i("userInfo.role"),
          // thClass: "text-left",
          // tdClass: "text-left",
        },
        {key: "description", label: "Description"},
        {
          key: "ReadFlag",
          label: this.$i("permission.readFlag"),
        },
        {
          key: "WriteFlag",
          label: this.$i("permission.writeFlag"),
        },
        {
          key: "DeleteFlag",
          label: this.$i("permission.deleteFlag"),
        },
        {
          key: "Action",
          label: this.$i("action"),
        },
      ],
      ROLE_MAP: [
        {value: "SUPER_ADMIN", text: this.$i("permission.SUPER_ADMIN")},
        {value: "USER", text: this.$i("permission.USER")},
        {value: "ADMIN", text: this.$i("permission.ADMIN")},
        {value: "INSTANCE_OPERATOR", text: this.$i("permission.INSTANCE_OPERATOR"),},
        {value: "CUSTOMER_ROLE_001", text: this.$i("permission.CUSTOMER_ROLE_001"),},
        {value: "CUSTOMER_ROLE_002", text: this.$i("permission.CUSTOMER_ROLE_002"),},
        {value: "CUSTOMER_ROLE_003", text: this.$i("permission.CUSTOMER_ROLE_003"),},
        {value: "PLATFORM_ADMIN", text: this.$i("permission.CONTROL_CENTER")},
        {value: "PLATFORM_USER", text: this.$i("permission.PLATFORM_USER")},
        {value: "DEMO_USER", text: this.$i("permission.DEMO_USER")},
        {value: "RECEPTIONIST", text: this.$i("permission.RECEPTIONIST")},
      ],
      roleArray: [],
      webPermissionConfigMap: {},
      webPermissionConfigArray: []
    };
  },
  methods: {
    changePermission(role, item) {
      let obj = {
        role: role,
        webPermissionName: item.featureName === '' ? this.newFeatureName : item.featureName,
        permission: item.permission === '' ? this.newPermission : item.permission,
        desc: item.desc === '' ? this.newDesc : item.desc
      }
      if (obj.webPermissionName === '' || obj.role === '' || obj.permission === '') {
        Message.error('信息不能为空');
      } else {
        let that = this;
        UserPermissionService.updateWebPermissionConfig(obj, function () {
          Message.success(obj.webPermissionName + ' set to ' + obj.permission + ' for role ' + obj.role + ' success');
          AdminUserManagement.getRolePermissionList((data) => {
            that.roleArray = data;
            data.forEach((p) => {
              if (p.role === 'SUPER_ADMIN') {
                let webPermissionConfigMapTmp = {};
                let webPermissionConfigArrayTmp = [];
                p.featureConfigLists.forEach((f) => {
                  webPermissionConfigMapTmp[f.featureName] = f.desc;
                  webPermissionConfigArrayTmp.push(f.featureName);
                });
                that.webPermissionConfigMap = webPermissionConfigMapTmp;
                that.webPermissionConfigArray = webPermissionConfigArrayTmp;
              }
            });
              }, () => {
                Message.error(that.$i("OperationFail"));
              }
          );
        })
      }
    },
    userListProvider(ctx, callback) {
      AdminUserManagement.getUserList(ctx.currentPage, ctx.perPage,
          (data) => {
            let items = data.elements;
            for (const item of items) {
              item.role.roleSelected = item.role.role;
            }
            this.userListTotalRows = data.totalElements;
            callback(items)
          },
          () => {
            Message.error(this.$i("operationFail"));
          }
      );
    },
    rolePermissionListProvider(ctx, callback) {
      let that = this;
      AdminUserManagement.getRolePermissionList(
          (data) => {
            that.roleArray = data;
            data.forEach((p) => {
              if (p.role === 'SUPER_ADMIN') {
                let webPermissionConfigMapTmp = {};
                let webPermissionConfigArrayTmp = [];
                p.featureConfigLists.forEach((f) => {
                  webPermissionConfigMapTmp[f.featureName] = f.desc;
                  webPermissionConfigArrayTmp.push(f.featureName);
                });
                that.webPermissionConfigMap = webPermissionConfigMapTmp;
                that.webPermissionConfigArray = webPermissionConfigArrayTmp;
              }
            });
            callback(data);
          },
          () => {
            Message.error(this.$i("OperationFail"));
          }
      );
    },
    saveInstancePermission(arg) {
      let ins = {
        role: arg.role,
        read: arg.instancePermission.readFlag,
        write: arg.instancePermission.writeFlag,
        delete: arg.instancePermission.deleteFlag,
      };
      let d = {instancePermissions: [ins]};
      AdminUserManagement.updateRolePermission(
          d,
          () => {
            Message.info(this.$i("operationSuccess"));
            this.$refs.rolePermissionTable.refresh();
          },
          () => {
            Message.error(this.$i("operationFail"));
          }
      );
    },
    setToUser(data) {
      AdminUserManagement.updateUserRole(
          data.item.role.roleSelected,
          data.item.id,
          () => {
            Message.info(this.$i("operationSuccess"));
            this.$refs.userListTable.refresh();
          },
          () => {
            Message.error(this.$i("operationFail"));
          }
      );
    },
    createUser() {
      let that = this;
      that.creating = true;
      UserService.createUser(this.createObj, function () {
        that.creating = false;
        Message.success('User create success: ' + that.createObj.username);
        that.$refs.userListTable.refresh();
      }, function () {
        that.creating = false;
      });
    }
  },
};
</script>

<style lang="css" scoped>


.el-collapse {
  color: #FFFFFF;
  border-top: 1px solid #444c56;
  border-bottom: 1px solid #444c56;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: white;
}

.el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

</style>