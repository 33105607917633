<template>
    <div class="create-customer-dialog" :style="[show ? 'display: block;' : 'display: none;']">

        <div class="create-customer-dialog-content">

            <div class="create-customer-dialog-content-inner">
                <div @click="onAction({id: 'close'})" style="position: absolute; top: -56px; right: -46px; width: 32px; height: 32px; background: #3E454E; color: white; cursor: pointer; border-radius: 16px; font-size: 19px; padding: 3px;">
                    <i class="el-icon-close"></i>
                </div>

                <!-- 第二步 -->
                <template v-if="step == 2">
            
                    <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; margin-bottom: 20px;">
                        <el-col :span="24" style="text-align: left;">
                            <FtGroupButtonWidget v-if="currentPanel == 'cex'" :buttons="[
                                {
                                    id: 'cex',
                                    text: '交易所钱包',
                                    textI18n: '',
                                    active: true
                                }
                            ]" />
                            <FtGroupButtonWidget v-if="currentPanel == 'dex'" :buttons="[
                                {
                                    id: 'dex',
                                    text: '链上钱包',
                                    textI18n: '',
                                    active: true
                                }
                            ]" />
                        </el-col>
                    </el-row>
                            
                    <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                        <el-col :span="7" style="text-align: right;">
                            <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                                创建者
                            </label>
                        </el-col>
                        <el-col :span="14" style="text-align: left;">
                            <input v-model="form.userName" type="text" readonly style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                        </el-col>
                    </el-row>

                    <el-row v-for="(item, index) in approverUsernameList" :key="index" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                        <el-col :span="7" style="text-align: right;">
                            <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">审批人</label>
                        </el-col>
                        <el-col :span="14" style="text-align: left;">
                            <!-- <input v-model="item.value" type="text" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/> -->
                            <select v-model="item.value" disabled style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px">
                                <option v-for="(item, index) in accountList" :key="index" :value="item.value">{{item.text}}</option>
                            </select>
                        </el-col>
                        <el-col :span="2" style="text-align: left;">
                            <!-- <i class="el-icon-remove-outline" style="color: red; margin-left: 10px; cursor: pointer;" 
                                @click="onAction({id: 'delApproverUser', value: index})"></i> -->
                        </el-col>
                    </el-row>

                    <!-- <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                        <el-col :span="24">
                            <FtGroupButtonWidget :buttons="[{
                                id: 'addApproverUser',
                                icon: '',
                                text: '添加',
                                textI18n: '',
                            }]" mode="single" v-on:switch-change="onAction" style="float: none; display: inline-block; margin: auto; background: #3E454E;" />
                        </el-col>
                    </el-row> -->

                </template>

                <!-- 第三步 -->
                <template v-if="step == 3">
            
                    <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; margin-bottom: 20px;">
                        <el-col :span="24" style="text-align: left;">
                            <FtGroupButtonWidget v-if="currentPanel == 'cex'" :buttons="[
                                {
                                    id: 'cex',
                                    text: '审核通过设置',
                                    textI18n: '',
                                    active: true
                                }
                            ]" />
                        </el-col>
                    </el-row>
                            
                    <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                        <el-col :span="7" style="text-align: right;">
                            <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                                创建者
                            </label>
                        </el-col>
                        <el-col :span="14" style="text-align: left;">
                            <input v-model="form.userName" type="text" readonly style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                        </el-col>
                    </el-row>
                            
                    <el-row v-if="currentPanel == 'cex'" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                        <el-col :span="7" style="text-align: right;">
                            <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                                审核通过人数
                            </label>
                        </el-col>
                        <el-col :span="3" style="text-align: left;">
                            <input v-model="form.requireApprovalNumber" type="text" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                        </el-col>
                        <el-col :span="7" style="text-align: left; color: #fff;">
                            of {{approverUsernameList.length}}
                        </el-col>
                    </el-row>

                </template>
                
                <!-- 步骤控制 -->
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; margin-top: 20px;">
                    <el-col :span="24">
                        <FtGroupButtonWidget :buttons="[{
                            id: 'close',
                            icon: '',
                            text: '确定',
                            textI18n: '',
                        }]" mode="single" v-on:switch-change="onAction" 
                        style="float: none; display: inline-block; margin: auto; background: #3E454E;" />
                    </el-col>
                </el-row>
            </div>

        </div>

    </div>
</template>

<script>
import {Message} from "element-ui";
import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";

// import WalletManagement from "@/api/wallet/WalletManagement";
// import UserService from "@/api/user/UserService";

import { useWalletStore, useWalletOutsideStore } from '@/store/wallet'

export default {
    components: {
        FtGroupButtonWidget,
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'cex'
        }
    },
    data() {
        return {
            userName: '',
            // 新建面板
            detailSwitchButtons: [
                {
                    id: 'cex',
                    text: '交易所钱包',
                    textI18n: '',
                    active: true
                },
                {
                    id: 'dex',
                    text: '链上钱包',
                    textI18n: '',
                    active: false
                }
            ],
            currentPanel: 'cex',

            // 第几个步骤
            step: 2,

            // // 审批人员名单
            // usernameList: [
            //     {value: '', text: ''},
            //     {value: '', text: ''},
            //     {value: '', text: ''},
            // ],

            // 审批人员名单(已选择)
            approverUsernameList: [
                {value: ''},
                {value: ''},
                {value: ''},
            ],

            // 表单数据
            form: {
                /**
                 * 地址录入
                 */
                id: '',
                name: '1',
                apiKey: '1',
                secretKey: '1',
                exchangeKey: '1', // -> secondaryVerification
                privateKey: '1',
                chainName: '1',
                userName: 'lining',
                /**
                 * 审核成员
                 */
                approverUsernameList: [],
                /**
                 * 审核通过设置
                 */
                requireApprovalNumber: 1
            },
            
        }
    },
    computed: {
        // 账号列表
        accountList: () => {
            let walletOutsideStore = useWalletOutsideStore()
            return walletOutsideStore.accountList
        }
    },
    mounted() {
        // this.userName = UserService.getUserName()
        this.currentPanel = this.mode
        this.detailSwitchButtons = this.detailSwitchButtons.map(item => {
            if (item.id == this.mode) {
                item.active = true
            } else {
                item.active = false
            }
            return item
        })

        let walletStore = useWalletStore()
        walletStore.getUserList()
    },
    methods: {

        setData(item) {
            console.log('更新钱包, 设置参数', item)
            this.form.id = item.id
            this.currentPanel = item.walletType.toLowerCase()
            this.form.userName = item.createdBy
            if (item.approvers) {
                this.approverUsernameList = item.approvers.map(a => {
                    return {
                        value: a
                    }
                })
            }
            this.form.requireApprovalNumber = item.requireApprovalNumber
        },

        // 各种切换处理
        onAction(item) {
            if (item.id == 'close') {
            console.log('switch change event', item.id)
                this.$emit('update:show', false)
            } else if (item.id == 'cex') {
                this.currentPanel = item.id
            } else if (item.id == 'dex') {
                this.currentPanel = item.id
            } else if (item.id == 'prevstep') {
                if (this.currentPanel == 'cex') {
                    switch (this.step) {
                        case 1:
                            break
                        case 2:
                            this.step = 1
                            break
                        case 3:
                            this.step = 2
                            break
                    }
                } else if (this.currentPanel == 'dex') {
                    switch (this.step) {
                        case 1:
                            break
                        case 2:
                            this.step = 1
                            break
                        case 3:
                            this.step = 2
                            break
                    }
                }
            } else if (item.id == 'nextstep') {
                if (this.currentPanel == 'cex') {
                    switch (this.step) {
                        case 1:
                            if (this.form.name == '') {
                                Message.error('请输入姓名');
                                return 
                            }
                            if (this.form.apiKey == '') {
                                Message.error('请输入API KEY');
                                return 
                            }
                            if (this.form.secretKey == '') {
                                Message.error('请输入SECRET KEY');
                                return 
                            }
                            if (this.form.exchangeKey == '') {
                                Message.error('请输入交易所二步验证KEY');
                                return 
                            }
                            this.step = 2
                            break
                        case 2:
                            if (this.approverUsernameList.length) {
                                let r = false
                                this.approverUsernameList.forEach(m => {
                                    if (!m.value.trim()) {
                                        r = true
                                    }
                                })
                                if (r) {
                                    Message.error('请输入审核人员');
                                    return 
                                }
                            }
                            this.step = 3
                            break
                        case 3:
                            break
                    }
                } else if (this.currentPanel == 'dex') {
                    switch (this.step) {
                        case 1:
                            if (this.form.name == '') {
                                Message.error('请输入姓名');
                                return 
                            }
                            if (this.form.privateKey == '') {
                                Message.error('请输入私钥');
                                return 
                            }
                            this.step = 2
                            break
                        case 2:
                            if (this.approverUsernameList.length) {
                                let r = false
                                this.approverUsernameList.forEach(m => {
                                    if (!m.value.trim()) {
                                        r = true
                                    }
                                })
                                if (r) {
                                    Message.error('请输入审核人员');
                                    return 
                                }
                            }
                            this.step = 3
                            break
                        case 3:
                            break
                    }
                }
            } else if (item.id == 'addApproverUser') {
                if (this.approverUsernameList.length >= 20) {
                    Message.error('最多20人审核');
                    return
                }
                this.approverUsernameList.push({value: ''})
            } else if (item.id == 'delApproverUser') {
                this.approverUsernameList.splice(item.value, 1)
            } else if (item.id == 'append') {
                // // 更新
                // let params = {
                //     id: this.form.id,
                //     approverUsernameList: this.approverUsernameList.map(m => {
                //         return m.value
                //     }).concat(UserService.getUserName()),
                //     requireApprovalNumber: this.form.requireApprovalNumber,
                // }
                // WalletManagement.updateWalletApprovers(params,
                //     (res) => {
                //         console.log(res)
                //         Message.success('更新成功');
                //         let walletStore = useWalletStore()
                //         walletStore.listCommonUsedWallet()
                //         walletStore.listWallet()
                //         walletStore.listWalletApprovedByRequester()
                //         this.$emit('update:show', false)
                //         this.$emit('success', 'updateWallet')
                //     },
                //     () => {
                //         // Message.error(this.$i("operationFail"));
                //     }
                // );
            }
        },
        
    }
}
</script>

<style scoped>
.create-customer-dialog{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
}
.create-customer-dialog-content{
    background: #22272E;
    width: 500px;
    /* height: 360px; */
    padding: 30px;
    border: 1px solid #3E454E;
    border-radius: 8px;
    position: fixed;
    left: 50%;
    top: 30%;
    margin-left: -250px;
    margin-top: -140px;
}
.create-customer-dialog-content-inner{
    position: relative;
}
</style>