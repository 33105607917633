<script type="text/javascript">

import ServiceMapper from "@/const/ServiceMapper";
import AbmNet from "@/api/net/AbmNet";

function strategyLibCreate(name, platforms, coins, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.strategyLibCreate , {
    name: name,
    platforms: platforms,
    coins: coins,
    description: 'Empty description...',
    strategyConfig: '[]'
  }, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function strategyLibUpdate(libId, name, platforms, coins, description, strategyConfig, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.strategyLibUpdate , {
    id: libId,
    name: name,
    platforms: platforms,
    coins: coins,
    description: description,
    strategyConfig: strategyConfig
  }, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function strategyLibQuery(libId, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.strategyLibQuery + (libId ? '?libId=' + libId : ''), function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function addComment(libId, comment, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.strategyLibCommentAdd.replaceAll('{strategyLibId}', libId) , {
    comment: comment
  }, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

export default {
  strategyLibCreate,
  strategyLibUpdate,
  strategyLibQuery,
  addComment
}

</script>