<template>
  <div style="width: 100%;height: 100%;overflow-y: auto;padding: 5px">
    <el-row style="margin-top: 20px">
      <el-card class="black-card" style="height: 258px">
        <el-row style="margin-top: -5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">账号</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy" style="display: inline-block; float: left;color: white;font-size: 12px">{{ accountInfo.email }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">Currency</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">{{ accountInfo.currency }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">余额（{{ accountInfo.currency }}）</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">{{ accountInfo.equity }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">Delta</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">{{ accountInfo.delta_total }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">可用保证金</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">{{ accountInfo.available_funds }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">开仓所需保证金</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">{{ accountInfo.projected_initial_margin }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">总杠杆</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">{{ (50 * (accountInfo.initial_margin / accountInfo.margin_balance)).toFixed(2) }}X</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">IM(SM):{{ Number(accountInfo.initial_margin * 100 / accountInfo.margin_balance).toFixed(2) }}%</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">MM(SM):{{ Number(accountInfo.maintenance_margin * 100 / accountInfo.margin_balance).toFixed(2) }}%</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">IM(PM):{{ Number(accountInfo.initial_margin * 100 / accountInfo.equity).toFixed(2) }}%</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">MM(PM):{{ Number(accountInfo.maintenance_margin * 100 / accountInfo.equity).toFixed(2) }}%</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">未实现盈亏</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">{{ accountInfo.total_pl }} {{ accountInfo.currency }}</span>
          </el-col>
        </el-row>
      </el-card>
    </el-row>
    <el-row style="margin-top: 20px">
      <general-order-panel v-if="active" ref="order-panel-deribit" v-auth:quickPlaceOrder></general-order-panel>
    </el-row>
    <el-row style="margin-top: 15px">
      <general-position-panel ref="position-panel-db" style="margin-top: -1px"/>
    </el-row>
    <el-row style="margin-top: 15px">
      <el-row>
        <span class="dark-mode-font" style="font-size: 12px;display: inline-block;float: left;color: #FFFFFF;margin-left: 5px">Options</span>
      </el-row>
      <el-card class="black-card" body-style="padding: 0" style="width: 100%;border-radius: 5px 5px 0 0;border-bottom: 0;height: 25px;line-height: 25px;margin-top: 5px;background-color: #454c56">
        <el-row>
          <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy small-font" style="font-size: 12px;display: inline-block;color: #FFFFFF">Symbol</span></el-col>
          <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy small-font" style="font-size: 12px;display: inline-block;color: #FFFFFF">Size</span></el-col>
          <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy small-font" style="font-size: 12px;display: inline-block;color: white">Entry price</span></el-col>
          <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy small-font" style="font-size: 12px;display: inline-block;color: white">Pnl</span></el-col>
          <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy small-font" style="font-size: 12px;display: inline-block;color: white">Delta</span></el-col>
        </el-row>
      </el-card>
      <el-card class="black-card" body-style="padding: 0" style="width: 100%;height: 197px;overflow-y: auto;border-radius: 0">
        <el-skeleton animated :loading="renderingPosition" :throttle="200">
          <template slot="template">
            <trialpha-sk :style="'margin-top: 25px;width: 60%;float: left'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left'"></trialpha-sk>
          </template>
          <template>
            <el-card class="black-card" body-style="padding: 0" v-for="(item, index) in Object.keys(optionMap)" :key="index" style="border-radius: 0;border-bottom: 1px solid #444c56;border-top: 0;border-left: 0;border-right: 0">
              <el-card class="black-card-hover" body-style="padding: 0" style="border-radius: 0;border-bottom: 1px solid #444c56;border-top: 0;height: 28px;line-height: 28px;border-left: 0;border-right: 0">
                <el-row>
                  <el-col :span="5">
                    <el-tooltip class="item" effect="dark" :content="optionMap[item].instrument_name" placement="top">
                      <span class="dark-mode-font ellipsis-line-strategy" style="text-align: left;font-size: 12px;display: inline-block;float: left;margin-left: 8px">{{ item }}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy small-font" :style="'font-size: 12px;display: inline-block;' + (optionMap[item].size > 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ optionMap[item].size }}</span></el-col>
                  <el-col :span="5" :offset="5"><span class="dark-mode-font ellipsis-line-strategy small-font" :style="'font-size: 12px;display: inline-block;' + (optionMap[item].pnl > 0 ? 'color: #41d78a;' : 'color: #e36565;') + ''">{{ optionMap[item].pnl.toFixed(4) }}</span></el-col>
                  <el-col :span="4">
                    <el-tooltip class="item" effect="dark" :content="'Delta:' + (optionMap[item].delta).toFixed(3) + ', Gamma:' + (optionMap[item].gamma.toFixed(5)) + ', Vega:' + optionMap[item].vega.toFixed(2) + ', Theta:' + optionMap[item].theta.toFixed(2)" placement="top">
                      <span class="dark-mode-font ellipsis-line-strategy small-font">{{ optionMap[item].delta.toFixed(3) }}</span>
                    </el-tooltip>
                  </el-col>
                </el-row>
              </el-card>
              <el-card class="black-card-hover" body-style="padding: 0" v-for="(itemInner) in optionMap[item].data" :key="itemInner.instrument_name" style="border-radius: 0;border-bottom: 1px solid #444c56;border-top: 0;height: 28px;line-height: 28px;border-left: 0;border-right: 0">
                <el-row>
                  <el-col :span="5">
                    <el-tooltip class="item" effect="dark" :content="itemInner.instrument_name" placement="top">
                      <span class="dark-mode-font ellipsis-line-strategy small-font" style="color: white;font-size: 12px;display: inline-block;">{{ itemInner.instrument_name }}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy small-font" :style="'font-size: 12px;display: inline-block;' + (itemInner.size > 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ itemInner.size }}</span></el-col>
                  <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy small-font" style="font-size: 12px;display: inline-block;color: #FFFFFF">{{ itemInner.average_price }}</span></el-col>
                  <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy small-font" :style="'font-size: 12px;display: inline-block;' + (itemInner.total_profit_loss > 0 ? 'color: #85ddb0;' : 'color: #f1bbbb;') + ''">{{ itemInner.total_profit_loss.toFixed(4) }}</span></el-col>
                  <el-col :span="4">
                    <el-tooltip class="item" effect="dark" :content="'Delta:' + (itemInner.delta / itemInner.size).toFixed(3) + '/' +  itemInner.delta.toFixed(3) + ', Gamma:' + (itemInner.gamma) + ', Vega:' + itemInner.vega.toFixed(2) + ', Theta:' + itemInner.theta.toFixed(2)" placement="top">
                      <span class="dark-mode-font ellipsis-line-strategy small-font">{{ (itemInner.delta / itemInner.size).toFixed(3) }}/{{ itemInner.delta.toFixed(3) }}</span>
                    </el-tooltip>
                  </el-col>
                </el-row>
              </el-card>
            </el-card>
          </template>
        </el-skeleton>
      </el-card>
      <el-card class="black-card" body-style="padding: 0" style="width: 100%;border-radius: 0;border-top: 0;height: 25px;line-height: 25px;margin-top: 0;border-bottom: 0">
        <el-row>
          <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy" style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 8px;color: #FFFFFF">Total</span></el-col>
          <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy small-font" :style="'font-size: 12px;display: inline-block;' + (totalOptionSize > 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ totalOptionSize }}</span></el-col>
          <el-col :span="5" :offset="5"><span class="dark-mode-font ellipsis-line-strategy small-font" :style="'font-size: 12px;display: inline-block;' + (totalOptionPnl > 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ totalOptionPnl.toFixed(4) }}</span></el-col>
          <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy small-font" style="font-size: 12px;display: inline-block;color: #FFFFFF;">{{ totalOptionDelta.toFixed(3) }}</span></el-col>
        </el-row>
      </el-card>
      <el-card class="black-card" body-style="padding: 0" style="width: 100%;border-radius: 0 0 5px 5px;height: 25px;line-height: 25px;margin-top: 0">
        <el-row>
          <el-col :span="10"><span class="dark-mode-font ellipsis-line-strategy small-font" style="font-size: 12px;text-align: right;display: inline-block;color: #FFFFFF"> Gamma: {{ totalGamma.toFixed(5) }}</span></el-col>
          <el-col :span="7"><span class="dark-mode-font ellipsis-line-strategy small-font" style="font-size: 12px;text-align: right;display: inline-block;color: #FFFFFF"> Vega: {{ totalVega.toFixed(2) }}</span></el-col>
          <el-col :span="7"><span class="dark-mode-font ellipsis-line-strategy small-font" style="font-size: 12px;text-align: right;display: inline-block;color: #FFFFFF"> Theta: {{ totalTheta.toFixed(2) }}</span></el-col>
        </el-row>
      </el-card>
    </el-row>
    <el-row style="margin-top: 25px">
      <el-row>
        <span class="dark-mode-font" style="font-size: 12px;display: inline-block;float: left;color: #FFFFFF;margin-left: 5px">Futures</span>
      </el-row>
      <el-card class="black-card" body-style="padding: 0" style="width: 100%;border-radius: 5px 5px 0 0;border-bottom: 0;height: 25px;line-height: 25px;margin-top: 5px;background-color: #454c56">
        <el-row>
          <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy small-font">Symbol</span></el-col>
          <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy small-font">Size</span></el-col>
          <el-col :span="4">
            <el-tooltip class="item" effect="dark" content="Entry price" placement="top">
              <span class="dark-mode-font ellipsis-line-strategy small-font">Initial coins</span>
            </el-tooltip>
          </el-col>
          <el-col :span="4">
            <el-tooltip class="item" effect="dark" content="Entry price" placement="top">
              <span class="dark-mode-font ellipsis-line-strategy small-font">Entry price</span>
            </el-tooltip>
          </el-col>
          <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy small-font">Pnl</span></el-col>
          <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy small-font">Delta</span></el-col>
        </el-row>
      </el-card>
      <el-card class="black-card" body-style="padding: 0" style="width: 100%;height: 197px;overflow-y: auto;border-radius: 0">
        <el-skeleton animated :loading="renderingPosition" :throttle="200">
          <template slot="template">
            <trialpha-sk :style="'margin-top: 25px;width: 60%;float: left'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left'"></trialpha-sk>
          </template>
          <template>
            <el-card class="black-card-hover" body-style="padding: 0" v-for="(item, index) in positionFuture" :key="index" style="border-radius: 0;border-bottom: 1px solid #444c56;border-top: 0;height: 28px;line-height: 28px;border-left: 0;border-right: 0">
              <el-row v-if="item.size !== 0">
                <el-col :span="4">
                  <el-tooltip class="item" effect="dark" :content="item.instrument_name" placement="top">
                    <span class="dark-mode-font ellipsis-line-strategy small-font">{{ item.instrument_name }}</span>
                  </el-tooltip>
                </el-col>
                <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy small-font" :style="'' + (item.size > 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ item.size }}</span></el-col>
                <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy small-font" :style="'' + (item.size > 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ (item.size / item.average_price).toFixed(4) }}</span></el-col>
                <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy small-font" >{{ item.average_price }}</span></el-col>
                <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy small-font" :style="'' + (item.total_profit_loss > 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ item.total_profit_loss.toFixed(4) }}</span></el-col>
                <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy small-font">{{ item.delta.toFixed(3) }}</span></el-col>
              </el-row>
            </el-card>
          </template>
        </el-skeleton>
      </el-card>
      <el-card class="black-card" body-style="padding: 0" style="width: 100%;border-radius: 0 0 3px 3px;border-top: 0;height: 25px;line-height: 25px;margin-top: 0;">
        <el-row>
          <el-col :span="4">
            <el-tooltip class="item" effect="dark" :content="'杠杆: ' + totalLeverage.toFixed(2)" placement="top">
              <span class="dark-mode-font ellipsis-line-strategy small-font">杠杆: {{ totalLeverage.toFixed(2) }}X</span>
            </el-tooltip>
          </el-col>
          <el-col :span="6">
            <el-tooltip class="item" effect="dark" :content="'总仓位: ' + totalFuturePosition" placement="top">
              <span class="dark-mode-font ellipsis-line-strategy small-font">总仓位: {{ totalFuturePosition }}</span>
            </el-tooltip>
          </el-col>
          <el-col :span="6">
            <el-tooltip class="item" effect="dark" :content="'币敞口: ' + totalFutureInitialCoin" placement="top">
              <span class="dark-mode-font ellipsis-line-strategy small-font">币敞口: {{ totalFutureInitialCoin }}</span>
            </el-tooltip>
          </el-col>
          <el-col :span="4">
            <el-tooltip class="item" effect="dark" :content="'Pnl: ' + totalFuturePnl.toFixed(4)" placement="top">
              <span class="dark-mode-font ellipsis-line-strategy small-font" :style="'' + (totalFuturePnl > 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ totalFuturePnl.toFixed(4) }}</span>
            </el-tooltip>
          </el-col>
          <el-col :span="4">
            <el-tooltip class="item" effect="dark" :content="'Delta: ' + totalFutureDelta.toFixed(3)" placement="top">
              <span class="dark-mode-font ellipsis-line-strategy small-font">{{ totalFutureDelta.toFixed(3) }}</span>
            </el-tooltip>
          </el-col>
        </el-row>
      </el-card>
    </el-row>
    <el-button class="dark-mode-btn" style="margin-top: 25px;width: 100%" :loading="renderingPosition" @click="renderData">Reload</el-button>
  </div>
</template>

<script>
import TradeApiService from "@/api/trade/TradeApiService";
import TimeService from "@/utils/TimeService";
import TrialphaSk from "@/components/TrialphaSk";
import GeneralOrderPanel from "@/components/account/GeneraalOrderPanel";
import GeneralPositionPanel from "@/components/account/GeneralPositionPanel";
import GeneralApiService from "@/api/trade/GeneralApiService";

export default {
  name: "DeribitAccountInfo",
  components: {GeneralPositionPanel, GeneralOrderPanel, TrialphaSk},
  data: function () {
    return {
      instance: {},
      tradeAccountIns: {},
      positionOption: {},
      positionFuture: {},
      allSpot: {},
      moreThanZeroSpot: {},
      showAllPosition: false,
      renderingSpot: true,
      renderingPosition: true,
      renderingHistory: true,
      historyOrderToEnd: false,
      loadingOrder: false,
      allPrice: {},
      totalVega: 0,
      totalTheta: 0,
      totalGamma: 0,
      totalRho: 0,
      totalOptionDelta: 0,
      totalFutureDelta: 0,
      totalOptionPnl: 0,
      totalFuturePnl: 0,
      totalOptionSize: 0,
      totalFutureSize: 0,
      totalFuturePosition: 0,
      totalFutureInitialCoin: 0,
      history: [],
      startTime: 0,
      endTime: 0,
      historyAmount:{},
      historyCostUsd:{},
      analysis:[],
      accountInfo: {
        //净值
        equity: 0,
        currency: 'ETH',
        //开仓所需保证金
        initial_margin: 0,
        maintenance_margin: 0,
        //保证金余额
        margin_balance: 0,
        //可用保证金
        available_funds: 0,
        //开仓所需保证金
        projected_initial_margin: 0,
        //总未实现盈亏
        total_pl: 0,
        //总Vega
        options_vega: 0,
        options_gamma: 0,
        options_theta: 0,
        delta_total: 0,
        optionD: 0,
        futureD: 0
      },
      optionMap: {},
      totalLeverage: 0,
      active: false
    }
  },
  watch: {
    accountInfo: function (val) {
      this.totalLeverage = (this.totalFuturePosition / (val.equity * this.allPrice[val.currency + '-PERPETUAL']));
    },
    totalFuturePosition: function (val) {
      this.totalLeverage = (val / (this.accountInfo.equity * this.allPrice[this.accountInfo.currency + '-PERPETUAL']));
    },
    allPrice: function (val) {
      this.totalLeverage = (this.totalFuturePosition / (this.accountInfo.equity * val[this.accountInfo.currency + '-PERPETUAL']));
    }
  },
  methods: {
    getTimeString: function (time) {
      return TimeService.timestampToDate(time);
    },
    changeAllSymbol: function () {
      this.showAllPosition = !this.showAllPosition;
    },
    render: function (ins, tradeAccountIns) {
      this.active = true;
      this.instance = ins;
      this.tradeAccountIns = tradeAccountIns;
      this.renderData();
    },
    uninstall: function () {
      this.active = false;
    },
    renderData: function () {
      let that = this;
      this.startTime = 0;
      this.history = [];
      this.analysis = [];
      this.positionOption = [];
      this.positionFuture = [];
      this.accountInfo = {
        equity: 0,
        currency: 'ETH',
        initial_margin: 0,
        maintenance_margin: 0,
        margin_balance: 0,
        available_funds: 0,
        projected_initial_margin: 0,
        total_pl: 0,
        options_vega: 0,
        options_gamma: 0,
        options_theta: 0,
        delta_total: 0,
        optionD: 0,
        futureD: 0
      }
      this.historyOrderToEnd = false;
      this.loadingOrder = false;
      that.renderingSpot = true;
      that.renderingPosition = true;
      that.renderingHistory = true;
      that.$nextTick(function () {
        that.$refs['order-panel-deribit'].render(that.tradeAccountIns, that.renderData);
      });
      this.$refs['position-panel-db'].render(GeneralApiService.PLATFORM.DERIBIT, GeneralApiService.SYMBOL_TYPE.ALL, this.tradeAccountIns.apiKey);
      TradeApiService.getAllPrice('DERIBIT', this.tradeAccountIns.baseCoin, function (data) {
        that.allPrice = data;
      });
      TradeApiService.getAccountInfo(this.tradeAccountIns.platform, this.tradeAccountIns.apiKey, this.tradeAccountIns.baseCoin, function (data) {
        that.accountInfo = data;
      }, function () {

      });
      TradeApiService.getAllPosition(this.tradeAccountIns.platform, this.tradeAccountIns.apiKey, function (data) {
        that.initPosition(data);
      }, function () {
        that.renderingPosition = false;
      });
    },
    initPosition: function (data) {
      let tmpF = [];
      let tmpO = [];
      let pnlOT = 0;
      let pnlFT = 0;
      let totalFSize = 0;
      let totalFuturePosition = 0;
      let totalFutureInitialCoin = 0;
      let totalOSize = 0;
      let totalVega = 0;
      let totalTheta = 0;
      let totalGamma = 0;
      let deltaO = 0;
      let deltaF = 0;
      let that = this;
      let optionMap = {};
      for (let dataKey in data) {
        if (data[dataKey].kind === 'future') {
          if (data[dataKey].size !== 0) {
            tmpF.push(data[dataKey]);
            pnlFT += data[dataKey].total_profit_loss;
            totalFSize += data[dataKey].size;
            deltaF += data[dataKey].delta;
            totalFuturePosition += Math.abs(data[dataKey].size);
            totalFutureInitialCoin += data[dataKey].size / data[dataKey].average_price;
          }
        } else {
          if (data[dataKey].size !== 0) {
            tmpO.push(data[dataKey]);
            let dateO = data[dataKey].instrument_name.split('-')[1];
            if (optionMap[dateO] === undefined) {
              optionMap[dateO] = {
                data: [],
                pnl: 0,
                vega: 0,
                theta: 0,
                delta: 0,
                gamma: 0,
                size: 0
              };
            }
            optionMap[dateO].data.push(data[dataKey]);
            optionMap[dateO].pnl += data[dataKey].total_profit_loss;
            optionMap[dateO].vega += data[dataKey].vega;
            optionMap[dateO].theta += data[dataKey].theta;
            optionMap[dateO].delta += data[dataKey].delta;
            optionMap[dateO].gamma += data[dataKey].gamma;
            optionMap[dateO].size += data[dataKey].size;
            pnlOT += data[dataKey].total_profit_loss;
            totalOSize += data[dataKey].size;
            totalVega += data[dataKey].vega;
            totalTheta += data[dataKey].theta;
            deltaO += data[dataKey].delta;
            totalGamma += data[dataKey].gamma;
          }
        }
      }
      that.totalFutureInitialCoin = totalFutureInitialCoin.toFixed(4);
      that.totalFuturePosition = Number(totalFuturePosition.toFixed(0));
      that.positionFuture = tmpF;
      that.positionOption = tmpO;
      that.totalFuturePnl = pnlFT;
      that.totalOptionPnl = pnlOT;
      that.totalVega = totalVega;
      that.totalTheta = totalTheta;
      that.totalGamma = totalGamma;
      that.totalFutureSize = totalFSize;
      that.totalOptionSize = totalOSize;
      that.totalOptionDelta = deltaO;
      that.totalFutureDelta = deltaF;
      that.optionMap = optionMap;
      that.renderingPosition = false;
    }
  }
}
</script>

<style scoped>

.ellipsis-line-strategy {
  padding: 0;
  width: 98%;
  float: right;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.small-font {
  width: 90%;
  font-size: 12px;
  -webkit-transform: scale(1);
  text-align: right;
  display: inline-block;
  float: right;
  margin-right: 5px;
  color: #FFFFFF
}

@media only screen and  (max-width: 768px) {
  .small-font {
    width: 120%;
    font-size: 12px;
    -webkit-transform: scale(0.75);
    text-align: right;
    display: inline-block;
    float: right;
    margin-right: -5px;
    color: #FFFFFF
  }
}

</style>