<template>
  <div class="panel-scoped">

    <div style="display: flex;">
      <div style="flex: 1;">

        <div style="display: flex;">
          <div>
            <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">选择转出地址</label>
          </div>
          <div style="flex: 1; padding-right: 20px;">
            <select v-model="form.sourceWallet" @change="changeWalletAction" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px">
                <option v-for="(item, index) in sourceWalletList" :key="index" :value="item.value">{{item.origin && item.origin.humanReadableName ? item.origin.humanReadableName + ' : ' : ''}}{{item.text}}</option>
            </select>
          </div>

          <div>
            <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">
              <template v-if="form.sourceWalletType == 'CEX'">选择转出代币</template>
              <template v-else>选择目标链</template>
            </label>
          </div>
          <div style="flex: 1; padding-right: 20px;">
            <select v-if="form.sourceWalletType == 'CEX'" v-model="form.cexCoinType" @change="changeCoinTypeAction" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px">
                <option v-for="(item, index) in cexCoinTypeList" :key="index" :value="item.value">
                  {{item.text}}
                  {{walletMoneyInfo[item.value]}}
                </option>
            </select>
            <select v-else v-model="form.dexTargetChain" @change="changeChainAction" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px">
                <option v-for="(item, index) in dexChainList" :key="index" :value="item.value">{{item.text}}</option>
            </select>
          </div>
          <div>
            <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">
              <template v-if="form.sourceWalletType == 'CEX'">选择目标链</template>
              <template v-else>选择转出代币</template>
            </label>
          </div>
          <div style="flex: 1;">
            <select v-if="form.sourceWalletType == 'CEX'" v-model="form.cexTargetChain" @change="changeChainAction" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px">
                <option v-for="(item, index) in cexChainList" :key="index" :value="item.value">{{item.text}}</option>
            </select>
            <select v-else v-model="form.dexCoinType" @change="changeCoinTypeAction" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px">
                <option v-for="(item, index) in dexCoinTypeList" :key="index" :value="item.value">
                  {{item.text}}
                  {{walletMoneyInfo[item.value]}}
                </option>
            </select>
          </div>
        </div>

        <FtInputBoxWidget v-model="form.remark" placeholder="请输入备注信息" style="margin-left: 0px; display: none;" />
        
      </div>
      <div style="display: none;">
        <FtGroupButtonWidget :buttons="[{
            id: 'createWallet',
            text: '添加转出地址',
            textI18n: '',
        }]" mode="single" v-on:switch-change="onAction" 
        style="margin-left: 16px; background: #3E454E; margin-top: 38px;" />
      </div>
    </div>

  </div>
</template>

<script>
// import {Message} from "element-ui";
// import ConfirmProvider from '@/components/ConfirmProvider'

import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";
import FtInputBoxWidget from "@/components/widgets/FtInputBoxWidget";

import { useWalletStore, useWalletOutsideStore } from '@/store/wallet'
import WalletManagement from "@/api/wallet/WalletManagement";
import TrialphaLoading from "@/components/TrialphaLoading";

export default {
  components: {
    FtGroupButtonWidget,
    FtInputBoxWidget,
  },
  props: {},
  data() {
    return {
      // 表单数据
      form: {
        sourcePlatform: '',
        sourceWalletId: '',
        sourceWallet: '',
        sourceWalletType: '',
        targetChain: '',
        dexTargetChain: '',
        cexTargetChain: '',
        coinType: '',
        dexCoinType: '',
        cexCoinType: '',
        remark: '',
        money: 0
      },
      // 余额信息
      walletMoneyInfo: {},
      // 目标链列表
      dexChainList: [],
      // 代币列表
      dexCoinTypeList: [],
      // 目标链列表
      cexChainList: [],
      // 代币列表
      cexCoinTypeList: [],
    }
  },
  computed: {
      // 转出钱包地址
      sourceWalletList: () => {
        let walletOutsideStore = useWalletOutsideStore()
        return [{text: '请选择', value: ''}].concat(walletOutsideStore.sourceWalletList)
      },
      // 目标链列表
      dexTargetChainList: () => {
        let walletOutsideStore = useWalletOutsideStore()
        return walletOutsideStore.dexTargetChainList
      },
      cexTargetChainList: () => {
        let walletOutsideStore = useWalletOutsideStore()
        return walletOutsideStore.cexTargetChainList
      },
      // 代币列表
      coinTypeList: () => {
        let walletOutsideStore = useWalletOutsideStore()
        return walletOutsideStore.dexCoinTypeList
      },
  },
  mounted() {

  },
  methods: {
    onAction(item) {
      console.log('转出钱包地址', item.id)
      if (item.id == 'createWallet') {
        this.$emit('switch-change', item)
      }
      else if (item.id == 'changeTargetChain') {
        let walletStore = useWalletStore()
        walletStore.setFilterTargetChain(this.form.targetChain)
        this.getWalletMoney(true)
      }
    },
    getFormData() {
      let walletId = ''
      let walletType = ''
      this.sourceWalletList.forEach(w => {
        if (w.value == this.form.sourceWallet) {
          walletType = w.walletType
          if (w.origin) {
            walletId = w.origin.id
          }
        }
      })
      this.form.sourceWalletId = walletId
      this.form.sourceWalletType = walletType
      this.form.money = this.walletMoneyInfo[this.form.targetChain]
      return this.form
    },
    resetFormData() {
      // 表单数据
      this.form = {
        sourceWallet: '',
        sourceWalletType: '',
        targetChain: '',
        coinType: '',
        remark: '',
        money: 0
      }
      // 余额信息
      this.walletMoneyInfo = {}
      let walletStore = useWalletStore()
      walletStore.setFilterTargetChain('')
    },
    filterTargetChain(list, type, platform) {
      var result = []
      if (type == 'CEX') {
        result = list
        // result = list.filter(c => {
        //   // OPTIMISM和ARB 是 DEX 类型的钱包用的，所以CEX要过滤掉
        //   return ['OPTIMISM', 'ARB'].indexOf(c.value) == -1
        // })
        if (platform == 'OKX') {
          return list.filter(c => {
            return c.value != 'BSC'
          })
        }
        return result
      }
      if (type == 'DEX') {
        // 
      }
      return list
    },
    setSourceWallet(wallet) {
      if (wallet.platform) {
        // CEX
        console.log('选择最新的CEX钱包')
      }
      else if (wallet.privateKey) {
        // DEX
        console.log('选择最新的DEX钱包')
      }
      let sourceWalletList = this.sourceWalletList
      let newCreateTime = 0
      let newWallet = null
      sourceWalletList.forEach(w => {
        if (w.origin && w.origin.createTime > newCreateTime) {
          newCreateTime = w.createTime
          newWallet = w
        }
      })
      if (newWallet) {
        this.form.sourceWallet = newWallet.value
      }
      this.changeWalletAction()
    },
    // 选择钱包后的处理
    changeWalletAction() {
      var that = this
      that.form.targetChain = ''
      that.form.cexTargetChain = ''
      that.form.dexTargetChain = ''
      that.form.coinType = ''
      that.form.cexCoinType = ''
      that.form.dexCoinType = ''
      that.onAction({id: 'changeTargetChain'})
      let wallet = null
      that.sourceWalletList.forEach(w => {
        if (w.value == that.form.sourceWallet) {
          if (w.origin) {
            wallet = w.origin
          }
        }
      })
      console.log('选中钱包', wallet)
      if (wallet) {
        // that.form.sourceWalletId = wallet.origin.id
        that.form.sourceWalletType = wallet.walletType
        that.form.sourcePlatform = wallet.platformName

        if (wallet.walletType == 'CEX') {
          var coinMap = that.cexTargetChainList[wallet.platformName]
          var coinTypeList = []
          for (var n in coinMap) {
            coinTypeList.push({value: n, text: n})
          }
          console.log('目标链信息CEX', coinMap, coinTypeList)
          that.cexCoinTypeList = [{text: '请选择', value: ''}].concat(coinTypeList)
          that.cexChainList = []
        } else {
          var chainMap = that.dexTargetChainList
          var chainList = []
          for (var c in chainMap) {
            chainList.push({value: c, text: c})
          }
          console.log('目标链信息DEX', chainMap, chainList)
          that.dexChainList = [{text: '请选择', value: ''}].concat(chainList)
          that.dexCoinTypeList = []
        }
        // if (wallet.platformName == 'OKX') {
        //   // 当选择okx交易所时，选择目标链要把bsc移除掉。
        //   that.form.targetChain = ''
        // }
        if (that.form.sourceWalletType == 'CEX') {
          that.getWalletMoney(true)
        }
      } else {
        that.form.sourceWalletType = ''
        that.form.sourcePlatform = ''
        // that.form.targetChain = ''
        // that.form.coinType = ''
        that.dexChainList = []
        that.dexCoinTypeList = []
        that.cexChainList = []
        that.cexCoinTypeList = []
      }
    },
    changeChainAction() {
      var that = this
      if (that.form.sourceWalletType == 'CEX') {
        // 第三极
        that.form.targetChain = that.form.cexTargetChain
        that.onAction({id: 'changeTargetChain'})
      } else {
        var coinList = that.dexTargetChainList[that.form.dexTargetChain]
        that.dexCoinTypeList = [{text: '请选择', value: ''}].concat(coinList.map(m => {
          return {value: m, text: m}
        }))
        console.log('代币信息DEX', that.dexCoinTypeList)
        that.form.targetChain = that.form.dexTargetChain
        that.onAction({id: 'changeTargetChain'})
        // 查询代币余额
        that.getWalletMoney(true)
        that.form.coinType = ''
        that.form.dexCoinType = ''
      }
    },
    changeCoinTypeAction() {
      var that = this
      if (that.form.sourceWalletType == 'CEX') {
        var coinMap = that.cexTargetChainList[that.form.sourcePlatform]
        var chainList = coinMap[that.form.cexCoinType]
        that.cexChainList = [{text: '请选择', value: ''}].concat(chainList.map(m => {
          return {value: m, text: m}
        }).filter(m => {
          if (that.form.sourcePlatform == 'OKX') {
            return m.value != 'BSC'
          }
          return true
        }))
        console.log('目标链信息CEX', that.cexChainList)
        that.form.coinType = that.form.cexCoinType
        that.form.targetChain = ''
        that.form.cexTargetChain = ''
        that.onAction({id: 'changeTargetChain'})
      } else {
        // 第三极
        that.form.coinType = that.form.dexCoinType
      }
      
    },
    // 选择钱包后，获取该钱包各币种余额
    getWalletMoney(showLoading) {
      let that = this
      let form = this.getFormData()
      return new Promise(resolve => {
        if (form.sourceWalletType == 'DEX') {
        console.log('钱包余额查询DEX', form.sourceWalletType)
          let paramsCex = {
              walletAddress : that.form.sourceWallet,
              dexChain : that.form.targetChain,
              tokens : that.coinTypeList[that.form.targetChain]
          }
          if (paramsCex.walletAddress && paramsCex.dexChain) {
            let loading = showLoading ? TrialphaLoading.loading(that) : {close: () => {}}
            WalletManagement.walletQueryDexBalanceOf(paramsCex).then((res) => {
              console.log('钱包余额DEX', res)
              that.walletMoneyInfo = res
              loading.close()
              resolve()
            }, () => {
              that.walletMoneyInfo = {}
              loading.close()
              resolve()
            })
          } else {
            resolve()
          }
        } else if (form.sourceWalletType == 'CEX') {
        console.log('钱包余额查询CEX', form.sourceWalletType)
          let paramsDex = {
              apiKey : that.form.sourceWallet
          }
          if (paramsDex.apiKey) {
            let loading = showLoading ? TrialphaLoading.loading(that) : {close: () => {}}
            WalletManagement.walletQueryCexBalanceOf(paramsDex).then((res) => {
              console.log('钱包余额CEX', res)
              that.walletMoneyInfo = res
              loading.close()
              resolve()
            }, () => {
              that.walletMoneyInfo = {}
              loading.close()
              resolve()
            })
          } else {
            resolve()
          }
        } else {
          resolve()
        }
      })
    }
  }
}
</script>

<style scoped>
.panel-scoped{
  background: #22272E;
  padding: 20px;
  border: 1px solid #3E454E;
  border-radius: 8px;
}
</style>