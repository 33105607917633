<script type="text/javascript">

function orElse(getV, defaultV) {
  try {
    let v = getV();
    if (v === null || v === undefined) {
      return defaultV;
    }
    return v;
  } catch (e) {
    return defaultV;
  }
}

export default {
  orElse
}
</script>