<template>
  <div>
    <el-drawer
        size="100%"
        append-to-body
        :with-header="true"
        :close-on-click-modal=true
        :close-on-press-escape=true
        custom-class="black-card"
        :destroy-on-close="true"
        :show-close=true
        :visible.sync="drawer"
        direction="rtl">
      <div style="height: 96%;padding: 0 10% 128px 10%">
        <span class="dark-mode-font" style="display: inline-block;width: 100%;text-align: center;color: #FFFFFF;font-size: 20px">Create new case</span>
        <el-row style="margin-top: 20px">
          <el-col :span="24">
            <el-input class="trialpha-dark-input trialpha-dark-input-para" placeholder="此处填写问题的主题" v-model="issueObj.issueTitle">
              <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">主题:</span></template>
            </el-input>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="10">
            <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 13px">状态</span>
          </el-col>
          <el-col :span="10">
            <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 13px">优先级</span>
          </el-col>
          <el-col :span="4">
            <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 13px">类型</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="10">
            <el-select class="select-symbol" style="width: 168px" :value="'新建'" placeholder="请选择"
                       size="small">
              <el-option
                  v-for="key in ['新建']"
                  :key="key"
                  :label="key"
                  :value="key">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="10">
            <el-select class="select-symbol" style="width: 168px" v-model="issueObj.issuePriority" placeholder="请选择"
                       size="small">
              <el-option
                  v-for="key in priority"
                  :key="key"
                  :label="priorityMap[key]"
                  :value="key">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select class="select-symbol" style="width: 168px" v-model="issueObj.issueType" placeholder="请选择"
                       size="small">
              <el-option
                  v-for="key in Object.keys(typeKeyTypeMap)"
                  :key="key"
                  :label="typeKeyTypeMap[key].projectTypeName"
                  :value="typeKeyTypeMap[key].projectTypeName">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row style="margin-top: 0">
          <el-col :span="10">
            <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 13px">指派</span>
          </el-col>
          <el-col :span="9">
            <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 13px">关注者</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="10">
            <el-select class="select-symbol" style="width: 168px" v-model="issueObj.issueAssignee" placeholder="请选择"
                       size="small">
              <el-option
                  v-for="key in Object.keys(userMap)"
                  :key="key"
                  :label="key"
                  :value="userMap[key].userEmail ? userMap[key].userEmail : userMap[key].userKey">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="13">
            <el-select class="select-symbol" style="width: 100%" v-model="issueObj.issueFollower" multiple placeholder="请选择"
                       size="small">
              <el-option
                  v-for="key in Object.keys(userEmailMap)"
                  :key="key"
                  :label="userEmailMap[key].userName"
                  :value="key">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="10">
            <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 13px">描述</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px;margin-bottom: 20px;">
          <mavon-editor ref=md @imgAdd="addImage" @imgDel="imgDel" v-model="issueObj.issueDescription" :ishljs="true" :html="false" default-open="preview" :xssOptions="false" style="height: 518px"></mavon-editor>
        </el-row>
        <el-row style="margin-top: 5px;margin-bottom: 20px;padding-bottom: 30px">
          <el-button class="dark-mode-btn" size="small" style="margin-top: 10px;width: 100%" @click="save" :disabled="!canAdd">提交</el-button>
        </el-row>
      </div>
    </el-drawer>
  </div>
</template>

<script>

import TracupService from "@/api/tracup/TracupService";
import {Message} from "element-ui";
import {AwsTracupUpdate} from "@/utils/UploadFile";

export default {
  name: "CreateIssue",
  data: function () {
    return {
      canAdd: true,
      drawer: false,
      priority: ["1", "2", "3"],
      priorityMap: {
        "1": '高',
        "2": '中',
        "3": '低'
      },
      issueObj: {
        issueTitle: '',
        issueType: '',
        issueDescription: '',
        issuePriority: "2",
        issueAssignee: '',
        issueFollower: []
      },
      issuePriorityColor: {},
      typeKeyTypeMap: {},
      statusKeyStatusMap: {},
      userMap: {},
      userEmailMap: {},
      content: '',
      issueDetails: {},
      issueFollow: [],
      history: [],
      msg: ''
    }
  },
  methods: {
    init() {
      this.canAdd = true;
      this.issueObj = {
        issueTitle: '',
        issueType: '',
        issueDescription: '',
        issuePriority: "2",
        issueAssignee: '',
        issueFollower: []
      };
      this.issuePriorityColor = {};
      this.typeKeyTypeMap = {};
      this.statusKeyStatusMap = {};
      this.userMap = {};
      this.userEmailMap = {};
      this.issueDetails = {};
      this.issueFollow = [];
      this.history = [];
    },
    addImage: function (pos, $file) {
      AwsTracupUpdate($file).then(res => {
        this.$refs.md.$img2Url(pos, res.Location);
      });
    },
    imgDel: function () {

    },
    save: function () {
      for (const argumentsKey in this.issueObj) {
        if (!this.issueObj[argumentsKey]) {
          Message.error(argumentsKey + ' 尚未填写');
          return;
        }
        if (argumentsKey === 'issueFollower') {
          this.issueObj.issueFollower = this.issueObj.issueFollower.join(';');
        }
      }
      let that = this;
      that.canAdd = false;
      TracupService.createIssue(this.issueObj, function (data) {
        if (data.code === 0 && data.message === '') {
          Message.success('创建成功');
          that.$emit('update',true);
          that.drawer = false;
        } else {
          Message.error(data.message);
          that.canAdd = true;
        }
      });
    },
    update: function (k, v) {
      let that = this;
      let req = {
        iNo: that.issueObj.issueNo
      };
      req[k] = v;
      TracupService.updateIssue(req, function () {
        Message.success('Update ' + k + ' to ' + v + ' success');
        that.$emit('update',true);
        that.loadDetails();
      });
    },
    render: function (issuePriorityColor, typeKeyTypeMap, statusKeyStatusMap, userMap) {
      this.init();
      this.drawer = true;
      this.content = '';
      this.issuePriorityColor = issuePriorityColor;
      this.typeKeyTypeMap = typeKeyTypeMap;
      this.statusKeyStatusMap = statusKeyStatusMap;
      this.userMap = userMap;
      for (const userMapKey in userMap) {
        let obj = userMap[userMapKey];
        this.userEmailMap[obj.userEmail] = obj;
      }
    },
    isJsonString(str) {
      try {
        if (typeof JSON.parse(str) == "object") {
          return true;
        }
      } catch (e) {
        return false;
      }
    }
  }
}
</script>

<style>

.msg-area textarea {
  border: #444c56 1px solid;
  font-size: 14px!important;
  color: #c5b6b6 !important;
  font-family: cursive!important;
  background-color: #22272e!important;
}

</style>