<template>
  <div :id="id">
    <div style="width: 100%;height: 158px;z-index: 1" :ref="'settlement_profit_' + id + ''"></div>
  </div>
</template>

<script>

import TimeService from "@/utils/TimeService";

export default {
  name: 'GeneralSettlementProfitPanel',
  components: {},
  props: {
    id: String
  },
  data: function () {
    return {
      loadingData: false,
      color: {},
      init: false,
      timeList: [],
      price: [],
    }
  },
  methods: {
    getProfitOptionOnlyProfit(xTimeList, profitList) {
      return {
        tooltip: {
          trigger: 'axis'
        },
        calculable: true,
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          show: true,
          data: xTimeList,
          axisLabel: {
            color: 'lightgray'
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#2a3237'
            }
          },
          axisLine:{
            lineStyle:{
              color:'rgba(49,58,70,0.8)',
              width: 4,   //这里是坐标轴的宽度,可以去掉
            }
          }
        }],
        grid: {
          x: 60,
          y: 20,
          x2: 15,
          y2: 20
        },
        yAxis: [{
          type: 'value',
          axisLabel: {
            formatter: '{value} %',
            color: 'lightgray'
          },
          show: true,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#2a3237'
            }
          },
          axisLine:{
            lineStyle:{
              color:'rgba(49,58,70,0.8)',
              width: 4,   //这里是坐标轴的宽度,可以去掉
            }
          }
        }],
        series: profitList
      };
    },
    timestampToDate(timestamp) {
      let date = new Date(Number(timestamp));
      let Y = date.getFullYear() + '-';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let D = date.getDate() + ' ';
      let h = date.getHours() + ':';
      let m = date.getMinutes() + ':';
      let s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    render: function (x, y) {
      let that = this;
      that.loadingData = false;
      let profit = [{
        showSymbol: false,
        name: 'PROFIT',
        type: 'line',
        data: y,
        smooth: true,
        itemStyle: {
          normal: {
            areaStyle: {
              type: 'default'
            },
            color: new this.$echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [{
                  offset: 0,
                  color: 'rgb(30,172,238,0.3)'
                },
                  {
                    offset: 0.5,
                    color: 'rgba(30,172,238, 0.1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(30,172,238, 0.05)'
                  }
                ]
            ),
            lineStyle: {        // 系列级个性化折线样式
              width: 3,
              type: 'solid',
              color: "rgba(18,250,161,0.78)"
            },
            opacity: 0
          }
        }
      }];
      if (that.$refs['settlement_profit_' + that.id]) {
        that.chart = that.$echarts.init(that.$refs['settlement_profit_' + that.id]);
        that.chart.clear();
        let timeList = [];
        for (let xKey in x) {
          timeList.push(TimeService.timestampToDateWithoutTime(x[xKey]));
        }
        that.chart.setOption(that.getProfitOptionOnlyProfit(timeList, profit));
        window.addEventListener('resize', () => (that.chart.resize()));
      }


      /*
      that.loadingData = true;
      InstanceService.profitRate(this.instance.instanceId, function (data) {
      }, function () {
        that.loadingData = false;
      });
       */
    },
    renderDefault: function () {
      let that = this;
      if (that.$refs['profit_' + that.id]) {
        that.chart = that.$echarts.init(that.$refs['profit_' + that.id]);
        that.chart.clear();
        that.coinStyle['USD'].data = [0, 0];
        let profit = [that.coinStyle['USD']];
        that.chart.setOption(that.getProfitOptionOnlyProfit([0, 1], profit));
        window.addEventListener('resize', () => (that.chart.resize()));
      }
    }
  }
}
</script>

<style>

@media only screen and (min-width: 992px) {
  .hidden-more-than-992 {
    display: none !important
  }
}

@media only screen and (max-width: 992px) {
  .hidden-less-than-992 {
    display: none !important
  }
}

@media only screen and (min-width: 1180px) {
  .hidden-more-than-1180 {
    display: none !important
  }
}

@media only screen and (max-width: 1180px) {
  .hidden-less-than-1180 {
    display: none !important
  }
}

@media only screen and (max-width: 1398px) {
  .hidden-less-than-1398 {
    display: none !important
  }
}

</style>