<template>
  <div class="panel-scoped">
  
    <el-row>
      <el-col :span="24">
        <FtGroupButtonWidget :buttons="[{
            id: 'append',
            text: '转账记录',
            textI18n: '',
        }]" mode="single" textMode="true"
        style="background: #3E454E;" />
      </el-col>
    </el-row>

    <div :class="[(scrollHeight > 0 ? 'scroll-mode' : '')]" :style="(scrollHeight > 0 ? {height: scrollHeight + 'px'} : {})">
      <div style="display: flex; margin-top: 20px;" v-for="item in finishedTransfers" :key="item._index">
        <div class="ellipsis-line-tr" style="flex: 1; border: 1px solid #3E454E; border-radius: 8px; height: 30px; line-height: 26px; color: #fff;font-size: 12px;text-align: left;padding-left: 10px">
          <div style="display: flex;">
            <div style="padding-right: 15px;">
              {{item.createTimeDate}}
            </div>
            <div style="flex: 1;padding-right: 15px">
            {{item.fromWalletHumanReadableName ? item.fromWalletHumanReadableName : item.fromWalletAddress}}
            向
            {{item.toWalletHumanReadableName ? item.toWalletHumanReadableName : item.toWalletAddress}}
            转账
            {{item.tokenAmount}} {{item.tokenType}}
              <span style="display: inline-block;float: right">
                <!-- {{['FAILED', 'ERRORED', 'OVERTIME_CANCELLED', 'CEX_STATUS_LOST_TRACK'].indexOf() > -1 ? '失败' : '成功'}} -->
                <!-- {{item.statusHumanReadableName}} -->
                {{item.statusHumanReadableName.indexOf('成功') > -1 ? '转账成功' : '转账失败'}}
              </span>
            </div>
          </div>
        </div>
        <div style="padding-left: 20px;">
          <FtGroupButtonWidget :buttons="[{
              id: 'transferDetail',
              text: '查看详情',
              textI18n: '',
          }]" mode="single" v-on:switch-change="onAction({id: 'transferDetail', value: item})" 
          style="background: #3E454E;" />
        </div>
      </div>

    </div>

    <FtPagingWidget
        :pageIndex="pageIndex"
        :pageSize="pageSize"
        :pageTotal="pageTotal"
        v-on:index-change="onPagingIndexChange"/>

  </div>
</template>

<script>
// import {Message} from "element-ui";
// import ConfirmProvider from '@/components/ConfirmProvider'

// import CrmChannelManagement from "@/api/crm/CrmChannelManagement";
import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";
// import FtOperationListWidget from "@/components/widgets/FtOperationListWidget";
import FtPagingWidget from "@/components/widgets/FtPagingWidget";

import { useWalletStore, useWalletOutsideStore } from '@/store/wallet'

export default {
  components: {
    FtGroupButtonWidget,
    // FtOperationListWidget
    FtPagingWidget
  },
  props: {},
  data() {
    return {
      // 列表信息
      // columns: [
      //   {textI18n: '', text: '编号', align: 'left', width: '5%', name: 'id'},
      //   {textI18n: '', text: '钱包名', align: 'left', width: '55%', name: 'name'},
      //   {textI18n: '', text: '审核通过人数', align: 'left', width: '20%', name: 'name'},
      //   {textI18n: '', text: '总人数', align: 'left', width: '20%', name: 'name'},
      // ]

      // 滚动面板高度
      scrollHeight: 0,
    }
  },
  computed: {
    // 转账记录
    finishedTransfers: () => {
      let walletOutsideStore = useWalletOutsideStore()
      return walletOutsideStore.finishedTransfersShow
    },
    // 分页信息
    pageIndex: () => {
      let walletOutsideStore = useWalletOutsideStore()
      return walletOutsideStore.finishedTransfersIndex
    },
    pageSize: () => {
      let walletOutsideStore = useWalletOutsideStore()
      return walletOutsideStore.finishedTransfersSize
    },
    pageTotal: () => {
      let walletOutsideStore = useWalletOutsideStore()
      return walletOutsideStore.finishedTransfersTotal
    }
  },
  mounted() {

    // var windowHeight = window.innerHeight
    this.scrollHeight = 760

  },
  methods: {

    // 各种切换处理
    onAction(item) {
      console.log('转账详情数据', item.id, item.value)
      this.$emit('switch-change', item)
    },

    onPagingIndexChange(index) {
      console.log('onPagingIndexChange', index)
      let walletStore = useWalletStore()
      walletStore.listMyFinishedTransfersTargetPage(index)
    },
  }
}
</script>

<style scoped>
.panel-scoped{
  background: #22272E;
  padding: 20px;
  border: 1px solid #3E454E;
  border-radius: 8px;
}

.panel-scoped .ft-checkbox{
  width: 20px; height: 20px; border-radius: 4px; border: 1px solid #3E454E; margin-top: 6px;
  cursor: pointer;
}

.panel-scoped .ft-checkbox > div{
  width: 12px; height: 12px; background: transparent; border-radius: 2px; margin: 3px;
}

.panel-scoped .ft-checkbox.active > div{
  background: #fff;
}

.ellipsis-line-tr {
  padding: 0;
  text-align: center;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.scroll-mode{
  overflow-y: auto;
}
</style>
