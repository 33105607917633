<template>
  <div style="padding-bottom: 30px">
    <el-card class="black-card" style="margin-top: 25px;" shadow="none">
      <span class="dark-mode-font" style="color: white">{{ tradeAccountIns.apiKey }}</span>
      <el-divider></el-divider>
      <el-row>
        <span class="dark-mode-font" style="color: white">Supported NFT</span>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col :span="12">
          <el-checkbox :value="tradeAccountIns.info && tradeAccountIns.info.ercNft" style="color: white" @change="clickProtocols('ercNft')">ERC-NFT</el-checkbox>
        </el-col>
      </el-row>
      <el-row>
        <span class="dark-mode-font" style="color: white">Supported DeFi Protocols</span>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col :span="12">
          <el-checkbox :value="tradeAccountIns.info && tradeAccountIns.info.gmxArb" style="color: white" @change="clickProtocols('gmxArb')">GMX-Arb</el-checkbox>
        </el-col>
        <el-col :span="12">
          <el-checkbox :value="tradeAccountIns.info && tradeAccountIns.info.gmxAvax" style="color: white" @change="clickProtocols('gmxAvax')">GMX-Avax</el-checkbox>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-checkbox :value="tradeAccountIns.info && tradeAccountIns.info.uniErc" style="color: white" @change="clickProtocols('uniErc')">UNI-Erc</el-checkbox>
        </el-col>
        <el-col :span="12">
          <el-checkbox :value="tradeAccountIns.info && tradeAccountIns.info.apeCoin" style="color: white" @change="clickProtocols('apeCoin')">APE-COIN</el-checkbox>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-checkbox :value="tradeAccountIns.info && tradeAccountIns.info.apeBayc" style="color: white" @change="clickProtocols('apeBayc')">APE-BAYC</el-checkbox>
        </el-col>
        <el-col :span="12">
          <el-checkbox :value="tradeAccountIns.info && tradeAccountIns.info.apeMayc" style="color: white" @change="clickProtocols('apeMayc')">APE-MAYC</el-checkbox>
        </el-col>
      </el-row>
      <el-row>
        <el-button class="dark-mode-btn" size="small" style="float: right" @click="updateInfo">Update Info</el-button>
      </el-row>
    </el-card>
    <div v-if="nftList.length > 0">
      <el-card class="black-card" style="border-radius: 0;background-color: #1c2128;border-bottom: 0;margin-top: 15px;" body-style="padding: 10px" shadow="none">
        <el-row>
          <el-col :span="12">
            <span class="dark-mode-font" style="color: white;float: left">NFT Gallery</span>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="black-card" style="border-radius: 0;background-color: #1c2128;margin-bottom: 15px;padding-bottom: 5px;overflow-y: auto;max-height: 386px" body-style="padding: 10px" shadow="none" v-if="nftList.length > 0">
        <el-row style="margin-top: 10px">
          <div v-for="(nft, index) in nftList" :key="index" style="width: 138px; height: 186px;float: left;padding-right: 10px;margin: auto">
            <nft-card :nft-obj="nft" style="width: 100%;height: 100%;"/>
          </div>
        </el-row>
      </el-card>
      <el-card class="black-card" style="border-radius: 0;background-color: rgba(0, 0, 0, 0);margin-bottom: 15px;padding-bottom: 5px" body-style="padding: 10px" shadow="none" v-if="nftList.length === 0">
        <el-row style="margin-top: 0">
          <el-row
              style="height: 35px;border: 0;background-color: rgba(0, 0, 0, 0);padding: 3px 10px 3px 10px;text-align: center;margin-top: 0;margin-bottom: 0">
                <span class="dark-mode-font"
                      style="margin-top: 20px;display: inline-block;font-size: 12px;color:gray;text-align: center;"><i
                    class="el-icon-s-order"/> Empty </span>
          </el-row>
        </el-row>
      </el-card>
    </div>
    <el-card class="black-card" style="border-radius: 0;border-bottom: 0;background-color: #1c2128;margin-top: 20px" body-style="padding: 10px" shadow="none">
      <el-row>
        <el-col :span="12">
          <span class="dark-mode-font" style="color: white;float: left">Total wallet USD value</span>
        </el-col>
        <el-col :span="12">
          <span class="dark-mode-font" style="color: white;float: right"><span class="dark-mode-font" style="color: #0ecb81;">${{ totalUsd.toFixed(2) }}</span></span>
        </el-col>
      </el-row>
    </el-card>
    <el-row style="margin-top: 0;padding-bottom: 15px" v-if="walletInfo.length > 0">
      <div v-for="(chain, index) in walletInfo" :key="index">
          <el-card class="black-card" style="border-radius: 0;border-bottom: 0;background-color: #1c2128"
                   body-style="padding: 10px" shadow="none">
            <el-row>
              <el-col :span="8">
                  <span class="dark-mode-font" style="color: white;display: inline-block;float: left">{{
                      chain.chain
                    }}</span>
              </el-col>
              <el-col :span="16">
                  <span class="dark-mode-font"
                        style="display: inline-block;float: right;font-size: 12px;margin-top: 3px;color: white"><span style="color: #0ecb81;">${{
                      chain.totalUsd.toFixed(2)
                    }}</span></span>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="black-card" style="border-radius: 0;height: 28px;background-color: #1c2128" shadow="none"
                   body-style="padding: 4px 10px 3px 10px">
            <el-row>
              <el-col :span="12">
                  <span class="dark-mode-font"
                        style="display: inline-block;float: left;text-align: left;font-size: 12px;color:gray ">Asset</span>
              </el-col>
              <el-col :span="6">
                  <span class="dark-mode-font"
                        style="display: inline-block;float: right;text-align: right;font-size: 12px;color:gray ">Amount</span>
              </el-col>
              <el-col :span="6">
                  <span class="dark-mode-font"
                        style="display: inline-block;float: right;text-align: right;font-size: 12px;color:gray ">Value</span>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="black-card-hover" shadow="none" style=";border: 0;border-radius: 0" body-style="padding:0"
                   v-for="(p, index) in chain.erc20TokenList" :key="index">
            <el-row
                style="height: 25px;border-bottom: 1px solid #444c56;border-left: 1px solid #444c56;border-right: 1px solid #444c56;padding: 3px 10px 3px 10px">
              <el-col :span="12">
                  <span class="dark-mode-font"
                        style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{
                      p.symbol
                    }}</span>
              </el-col>
              <el-col :span="6">
                  <span class="dark-mode-font"
                        style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{
                      p.balance.toFixed(4)
                    }}</span>
              </el-col>
              <el-col :span="6">
                  <span class="dark-mode-font"
                        style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">${{
                      p.usdValue.toFixed(2)
                    }}</span>
              </el-col>
            </el-row>
          </el-card>
          <div v-if="chain.erc20TokenList.length === 0">
            <el-row
                style="height: 65px;border-top: 0;border-bottom: #444c56 1px solid;border-left: #444c56 1px solid;border-right: #444c56 1px solid;background-color: rgba(0, 0, 0, 0);padding: 3px 10px 3px 10px;text-align: center;margin-top: 0;margin-bottom: 0">
                <span class="dark-mode-font"
                      style="margin-top: 20px;display: inline-block;font-size: 12px;color:gray;text-align: center;"><i
                    class="el-icon-s-order"/> EMPTY...</span>
            </el-row>
          </div>
      </div>
    </el-row>
    <el-row style="margin-top: 0" v-if="walletInfo.length === 0">
      <el-row
          style="height: 65px;border-bottom: #444c56 1px solid;border-top: #444c56 1px solid;border-left: #444c56 1px solid;border-right: #444c56 1px solid;background-color: rgba(0, 0, 0, 0);padding: 3px 10px 3px 10px;text-align: center;margin-top: 0;margin-bottom: 0">
                <span class="dark-mode-font"
                      style="margin-top: 20px;display: inline-block;font-size: 12px;color:gray;text-align: center;"><i
                    class="el-icon-s-order"/> Loading...</span>
      </el-row>
    </el-row>
  </div>
</template>

<script>

import SymbolInfoService from "@/utils/SymbolInfoService";
import GeneralApiService from "@/api/trade/GeneralApiService";
import ChainApiService from "@/web3/chain-api/ChainApiService";
import TradeAccountService from "@/api/trade/TradeAccountService";
import {Message} from "element-ui";
import NftCard from "@/components/account/NftCard";

export default {
  name: "EvmBasedWalletAccountInfo",
  components: {NftCard},
  data: function () {
    return {
      loading: false,
      instance: {},
      tradeAccountIns: {},
      destroyed: false,
      totalUsd: 0,
      walletUsdMap: {},
      selectWallet: '',
      supportChains: [
        {
          name: ChainApiService.ALL_CHAINS.ETH,
          symbol: 'ETH'
        },
        {
          name: ChainApiService.ALL_CHAINS.BSC,
          symbol: 'BNB'
        },
        {
          name: ChainApiService.ALL_CHAINS.POLYGON,
          symbol: 'MATIC'
        },
        {
          name: ChainApiService.ALL_CHAINS.AVAXC,
          symbol: 'AVAX'
        },
        {
          name: ChainApiService.ALL_CHAINS.ARBITRUM,
          symbol: 'ETH'
        },
        {
          name: ChainApiService.ALL_CHAINS.OPTIMISM,
          symbol: 'ETH'
        }
      ],
      walletInfo: [],
      nftList: []
    }
  },
  destroyed() {
    this.destroyed = true;
  },
  methods: {
    updateInfo() {
      TradeAccountService.updateInfo({
        id: this.tradeAccountIns.id,
        info: JSON.stringify(this.tradeAccountIns.info)
      }, function () {
        Message.success('Update protocol info success.');
      });
    },
    clickProtocols(protocol) {
      if (this.tradeAccountIns.info === '') {
        this.tradeAccountIns.info = {};
        this.tradeAccountIns.info[protocol] = true;
      } else {
        if (this.tradeAccountIns.info[protocol]) {
          this.tradeAccountIns.info[protocol] = false;
        } else {
          this.tradeAccountIns.info[protocol] = true;
        }
      }
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    resetData() {
      this.destroyed = false;
      this.walletUsdMap = {};
      this.totalUsd = 0;
      this.selectWallet = '';
      this.walletInfo = [];
    },
    async render(ins, tradeAccountIns) {
      if (!this.loading) {
        this.loading = true;
        let that = this;
        this.instance = ins;
        this.nftList = [];
        this.tradeAccountIns = tradeAccountIns;
        try {
          this.tradeAccountIns.info = JSON.parse(tradeAccountIns.info);
        } catch (e) {
          console.log();
        }
        let wallet = this.tradeAccountIns.apiKey;
        that.selectWallet = this.tradeAccountIns.apiKey;
        that.resetData();
        for (const supportChainsKey in that.supportChains) {
          if (that.destroyed) {
            break;
          }
          let chain = that.supportChains[supportChainsKey];
          ChainApiService.getAddressSummary(chain.name, wallet, function (nativeBalanceRes) {
            if (nativeBalanceRes && nativeBalanceRes[0]) {
              let nativeBalance = Number(nativeBalanceRes[0]['balance']);
              let totalU = 0;
              let usdV = ((SymbolInfoService.getSymbolPrice(GeneralApiService.PLATFORM.OKX, GeneralApiService.SYMBOL_TYPE.SPOT, chain.symbol + '-USDT') || 0) * nativeBalance);
              let balanceList = [{
                symbol: chain.symbol,
                balance: nativeBalance,
                usdValue: usdV,
                logo: '',
                ta: ''
              }];
              totalU += usdV;
              ChainApiService.getTokenList(chain.name, wallet, function (erc20BalanceList) {
                if (erc20BalanceList) {
                  erc20BalanceList.forEach((ERC20) => {
                    let obj = {
                      symbol: ERC20.token,
                      balance: Number(ERC20.holdingAmount),
                      usdValue: Number(ERC20.valueUsd),
                      ta: ERC20.tokenContractAddress
                    };
                    if (obj.usdValue > 0) {
                      totalU += obj.usdValue;
                      balanceList.push(obj);
                    } else if (that.isSpecialWL(chain.name, ERC20.token)) {
                      totalU += that.getSpecialWLCoinUsdValue(chain.name, ERC20.token, ERC20.holdingAmount);
                      obj.usdValue = that.getSpecialWLCoinUsdValue(chain.name, ERC20.token, ERC20.holdingAmount);
                      balanceList.push(obj);
                    }
                  });
                  that.walletInfo.push({
                    chain: chain.name,
                    totalUsd: totalU,
                    erc20TokenList: balanceList
                  });
                  that.totalUsd += totalU;
                  if (!that.walletUsdMap[wallet]) {
                    that.walletUsdMap[wallet] = 0;
                  }
                  that.walletUsdMap[wallet] = that.walletUsdMap[wallet] + totalU;
                  that.$nextTick(() => {
                    that.$forceUpdate();
                  });
                  that.loading = false;
                }
              }, function (err) {
                console.log(err);
              });
            }
          }, function (err) {
            console.log(err);
          });
          await that.sleep(168);
        }
        ChainApiService.getErc721List(wallet, function (nfts) {
          that.nftList = nfts;
        }, function (err) {
          console.log(err);
        });
      }
    },
    uninstall() {
      this.loading = false;
      this.resetData();
    },
    isSpecialWL(chain, tokenName) {
      if (chain === "OKC" && tokenName === "stOKT") {
        return true;
      }
      return false;
      },
    getSpecialWLCoinUsdValue(chain, tokenName, tokenBalance) {
      if (chain === "OKC" && tokenName === "stOKT") {
        let price = SymbolInfoService.getSymbolPrice(GeneralApiService.PLATFORM.OKX, GeneralApiService.SYMBOL_TYPE.ALL, 'OKT-USDT');
        return Number(tokenBalance) * price;
      }
      return 0;
    }
  }
}
</script>

<style scoped>

.el-divider.data-divider {
  border-top: 1px solid #444c56;
}

.data-divider > .el-divider__text {
  background-color: #22272e;
}

</style>