<template>
  <el-card class="trade-black-card" style="overflow-y: auto;height: 100%;border-radius: 0;border: 0;margin-top: 5px" body-style="padding: 0" shadow="none">
    <div>
      <el-card class="trade-black-card" body-style="padding: 0" shadow="none" style="border: 0">
        <el-row style="border-bottom: 1px solid rgba(128,128,128,0.18);padding-left: 15px;padding-right: 15px">
          <el-col :span="5">
            <span class="position-header" style="float: left">Symbol</span>
          </el-col>
          <el-col :span="5">
            <span class="position-header">Size</span>
          </el-col>
          <el-col :span="6">
            <span class="position-header">Entry Price</span>
          </el-col>
          <el-col :span="8">
            <span class="position-header">Operation</span>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="position-item" body-style="padding: 0" shadow="none" v-for="(item, index) in activeOrders" :key="index">
        <el-row>
          <el-col :span="5">
            <span class="position-header" style="color: white;float: left">{{ item.symbol }}</span>
          </el-col>
          <el-col :span="5">
            <span class="position-header" :style="'' +(item.side === 'buy' ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ Number.prototype.accSub(item.realAmount, item.dealData.R) }}/{{ item.realAmount }}</span>
          </el-col>
          <el-col :span="6">
            <span class="position-header" style="color: white">{{ item.realCostPrice }}</span>
          </el-col>
          <el-col :span="8">
            <el-button class="close-position-btn" v-auth:tradePanelWrite style="font-size: 12px;padding: 0 15px 0 15px;float: right;margin-top: 5px">CANCEL</el-button>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <!--
    <div class="hide-more-971">
      <el-card class="position-card-item" body-style="padding: 0" shadow="none" v-for="symbol in Object.keys(positions)" :key="symbol">
        <el-row style="margin-top: 5px">
          <el-col :span="11" style="margin-top: 8px">
            <span class="position-header ellipsis-line" style="color: white;float: left;font-size: 13px;">{{ symbol }}</span>
          </el-col>
          <el-col :span="13">
            <el-button class="close-position-btn" style="font-size: 12px;padding: 0 15px 0 15px;float: right;margin-top: 5px">MARKET CLOSE</el-button>
            <input class="trade-black-card" type="text" style="margin-top: 5px;background-color: #1f2b3a;width: 60px;outline: none;border: 0;color: white;margin-right: 8px;font-size: 12px;height: 24px;line-height: 24px;padding-left: 5px;border-radius: 3px;float: right" placeholder="Amount">
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-row>
            <el-col :span="12">
              <span class="position-header" style="float: left">PNL</span>
            </el-col>
            <el-col :span="12">
              <span class="position-header" style="float: right">Size</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <span class="position-header" :style="'' + (Number(positions[symbol].recentPnl) > 0 ? 'color: #0ecb81' : 'color: #ef5350') + ';float: left;font-size: 15px'">{{ positions[symbol].recentPnl }}</span>
            </el-col>
            <el-col :span="12">
              <span class="position-header" :style="'' + (Number(positions[symbol].size) > 0 ? 'color: #0ecb81' : 'color: #ef5350') + ';float: right;font-size: 15px'">{{ positions[symbol].size }}</span>
            </el-col>
          </el-row>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="7">
            <span class="position-header" style="color: grey;float: left;font-size: 12px">Entry Price</span>
          </el-col>
          <el-col :span="7">
            <span class="position-header" style="color: grey;float: right;font-size: 12px">Liquidation Price</span>
          </el-col>
          <el-col :span="10">
            <span class="position-header" style="color: grey;float: right;font-size: 12px">Collateral Used</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 0">
          <el-col :span="7">
            <span class="position-header" style="color: white;float: left;font-size: 12px">{{ positions[symbol].recentBreakEvenPrice }}</span>
          </el-col>
          <el-col :span="7">
            <span class="position-header" style="color: white;float: right">{{ positions[symbol].estimatedLiquidationPrice }}</span>
          </el-col>
          <el-col :span="10">
            <span class="position-header" style="color: white;float: right;font-size: 12px">{{ positions[symbol].collateralUsed }}</span>
          </el-col>
        </el-row>
      </el-card>
    </div>
    -->
    <el-row v-if="activeOrders.length <= 0" style="margin-top: 100px">
      <i class="el-icon-s-order" style="font-size: 25px;color: rgba(128,128,128,0.48)"></i>
    </el-row>
    <el-row v-if="activeOrders.length <= 0" style="margin-top: -5px">
      <span style="font-size: 12px;color: rgba(128,128,128,0.48)">Empty</span>
    </el-row>
  </el-card>
</template>

<script>

import TradeApiService from "@/api/trade/TradeApiService";

export default {
  name: "BinanceActiveOrder",
  props: {
    symbol: String
  },
  data: function () {
    return {
      platform: 'BINANCE',
      activeOrders: [],
      tradeAccountIns: {},
      totalSize: 0,
      totalPnl: 0,
      renderingPosition: false,
      positionTimer: {}
    }
  },
  created() {
  },
  beforeDestroy() {
    clearInterval(this.positionTimer);
  },
  methods: {
    timestampToDate(timestamp) {
      let date = new Date(Number(timestamp));
      let h = date.getHours() + ':';
      let m = date.getMinutes() + ':';
      let s = date.getSeconds();
      return h + m + s;
    },
    render: function (tradeAccount) {
      let that = this;
      this.tradeAccountIns = tradeAccount;
      TradeApiService.getAllActiveOrders(this.platform, this.tradeAccountIns.apiKey, function (data) {
        that.activeOrders = data;
      });
      that.positionTimer = setInterval(() => {
        TradeApiService.getAllActiveOrders(this.platform, this.tradeAccountIns.apiKey, function (data) {
          that.activeOrders = data;
        });
      }, 6868);
    },
  }

}
</script>

<style scoped>

</style>