import DatafeedApiService from '@/api/trade/DatafeedApiService'
import historyProvider from './historyProvider'

// 周期转换表
const ResolutionList = ["1", "5", "15", "60", "240", "1D"]
const IntradayList = ["1", "5", "15", "60", "240"]

function getSupportedResolution(exchange, resolution) {
	if (exchange == 'ftx') {
		let map = {
			'1': 'M1',
			'5': 'M5',
			'15': 'M15',
			'60': 'H1',
			'240': 'H4',
			'1D': 'D1',
		}
		return map[resolution]
	} else if (exchange == 'okex') {
		let map = {
			'1': '1m',
			'5': '5m',
			'15': '15m',
			'60': '1H',
			'240': '4H',
			'1D': '1D',
		}
		return map[resolution]
	} else if (exchange == 'binance_future') {
		let map = {
			'1': '1m',
			'5': '5m',
			'15': '15m',
			'60': '1h',
			'240': '4h',
			'1D': '1d',
		}
		return map[resolution]
	} else if (exchange == 'binance_spot') {
		let map = {
			'1': '1m',
			'5': '5m',
			'15': '15m',
			'60': '1h',
			'240': '4h',
			'1D': '1d',
		}
		return map[resolution]
	} else if (exchange == 'binance') {
		let map = {
			'1': '1m',
			'5': '5m',
			'15': '15m',
			'60': '1h',
			'240': '4h',
			'1D': '1d',
		}
		return map[resolution]
	} else if (exchange == 'deribit') {
		let map = {
			'1': '1m',
			'5': '5m',
			'15': '15m',
			'60': '1h',
			'240': '4h',
			'1D': '1d',
		}
		return map[resolution]
	} else if (exchange == 'bybit_future') {
		let map = {
			'1': '1m',
			'5': '5m',
			'15': '15m',
			'60': '1h',
			'240': '4h',
			'1D': '1d',
		}
		return map[resolution]
	} else if (exchange == 'bybit_delivery') {
		let map = {
			'1': '1m',
			'5': '5m',
			'15': '15m',
			'60': '1h',
			'240': '4h',
			'1D': '1d',
		}
		return map[resolution]
	} else if (exchange == 'bybit') {
		let map = {
			'1': '1m',
			'5': '5m',
			'15': '15m',
			'60': '1h',
			'240': '4h',
			'1D': '1d',
		}
		return map[resolution]
	}
	return resolution
}

function convertSupportedResolutionSecond(exchange, resolution) {
	if (exchange == 'ftx') {
		let map = {
			'1': 60,
			'5': 60 * 5,
			'15': 60 * 15,
			'60': 60 * 60,
			'240': 60 * 240,
			'1D': 60 * 60 * 24,
		}
		return map[resolution]
	} else if (exchange == 'okex') {
		let map = {
			'1': 60,
			'5': 60 * 5,
			'15': 60 * 15,
			'60': 60 * 60,
			'240': 60 * 240,
			'1D': 60 * 60 * 24,
		}
		return map[resolution]
	} else if (exchange == 'binance_future') {
		let map = {
			'1': 60,
			'5': 60 * 5,
			'15': 60 * 15,
			'60': 60 * 60,
			'240': 60 * 240,
			'1D': 60 * 60 * 24,
		}
		return map[resolution]
	} else if (exchange == 'binance_spot') {
		let map = {
			'1': 60,
			'5': 60 * 5,
			'15': 60 * 15,
			'60': 60 * 60,
			'240': 60 * 240,
			'1D': 60 * 60 * 24,
		}
		return map[resolution]
	} else if (exchange == 'binance') {
		let map = {
			'1': 60,
			'5': 60 * 5,
			'15': 60 * 15,
			'60': 60 * 60,
			'240': 60 * 240,
			'1D': 60 * 60 * 24,
		}
		return map[resolution]
	} else if (exchange == 'deribit') {
		let map = {
			'1': 60,
			'5': 60 * 5,
			'15': 60 * 15,
			'60': 60 * 60,
			'240': 60 * 240,
			'1D': 60 * 60 * 24,
		}
		return map[resolution]
	} else if (exchange == 'bybit_future') {
		let map = {
			'1': 60,
			'5': 60 * 5,
			'15': 60 * 15,
			'60': 60 * 60,
			'240': 60 * 240,
			'1D': 60 * 60 * 24,
		}
		return map[resolution]
	} else if (exchange == 'bybit_delivery') {
		let map = {
			'1': 60,
			'5': 60 * 5,
			'15': 60 * 15,
			'60': 60 * 60,
			'240': 60 * 240,
			'1D': 60 * 60 * 24,
		}
		return map[resolution]
	} else if (exchange == 'bybit') {
		let map = {
			'1': 60,
			'5': 60 * 5,
			'15': 60 * 15,
			'60': 60 * 60,
			'240': 60 * 240,
			'1D': 60 * 60 * 24,
		}
		return map[resolution]
	}
	return 0
}

function uuid(len, radix) {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    var uuid = [],
        i;
    radix = radix || chars.length;
    if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
        // rfc4122, version 4 form
        var r;
        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';
        // Fill in random data.  At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | Math.random() * 16;
                uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
            }
        }
    }
    return uuid.join('');
}

function CreateID(prefix){
    if(!prefix) prefix = 'ID';
    return prefix + '_' + uuid(8, 16) + '_' + Math.round( Math.random() * 100 )
}

function AsyncQueue() {
    var self = this;
    self.queue = [];
    self.countdown = 0;
    self._finish = function() {};
    self.reset = function(){
        self.countdown = 0;
    };
    self.action = function(fn){
        self.queue.push(fn);
    };
    self.finish = function(fn){
        self._finish = fn;
    };
    self.start = function(){
        if(self.queue.length == 0){
            return;
        }
        var finish = function(){
            if (++ self.countdown == self.queue.length) {
                self._finish();
            }
        };
        for (var i in self.queue) {
            self.queue[i](finish);
        }
    };
}

export default function FtUDFCompatibleDatafeed(callbacks) {

	let tvid = CreateID()
	
	// 原始数据
	let RawData = {
		origin: null,
		config: {
			supports_search: true,
			supports_group_request: false,
			supports_marks: true,
			supports_timescale_marks: true,
			supports_time: true,
			exchanges: [{
				value: "",
				name: "All Exchanges",
				desc: ""
			}],
			symbols_types: [{
				name: "All types",
				value: ""
			}],
			supported_resolutions: ResolutionList
		},
		search_symbols: [
			// {
			// 	symbol: "AAPL",
			// 	full_name: "AAPL",
			// 	description: "Apple Inc.",
			// 	exchange: "NasdaqNM",
			// 	type: "stock"
			// }
		],
		info_symbols: [
			// {
			// 	name: symbolName,
			// 	description: '',
			// 	type: 'crypto',
			// 	session: '24x7',
			// 	timezone: 'Etc/UTC',
			// 	ticker: symbolName,
			// 	exchange: split_data[0],
			// 	minmov: 1,
			// 	pricescale: 100,
			// 	has_intraday: true,
			// 	intraday_multipliers: ['1', '60'],
			// 	supported_resolution:  supportedResolutions,
			// 	volume_precision: 8,
			// 	data_status: 'streaming',
			// }
		]
	}

	// 历史数据查询条件
	let HistoryBarRequest = {
		// "BTC/USDT": [
		// 	{start: 0, end: 0}
		// ]
	}

	// 订阅的商品
	let _subscribe = []
	let _subscribeIntervalId = 0
	let _request_task_ing = 0

	this.getTvId = () => {
		return tvid
	}

	this.setSubscribeInterval = () => {
		if (_subscribeIntervalId == 0 && _subscribe.length) {
			_subscribeIntervalId = setInterval(() => {
				if (_request_task_ing > 0) {
					return
				}
				_subscribe.forEach((sub_task) => {
					_request_task_ing ++
					// console.log('订阅任务', sub_task.lastBar.time)
					// let time = new Date(sub_task.lastBar.time)
					// console.log('Last Bar', time.getFullYear(), time.getMonth() + 1, time.getDate(), time.getHours(), time.getMinutes(), time.getSeconds())
					let symbolInfo = sub_task.symbolInfo
					// let from = Math.round(sub_task.lastBar.time / 1000)
					let from = Math.round(Date.now() / 1000)
					let to = 0
					let firstDataRequest = true
					let limit = 1
					let targetResolution = getSupportedResolution(symbolInfo.exchange, sub_task.resolution)
					// 倒退时间
					let mtime = convertSupportedResolutionSecond(symbolInfo.exchange, sub_task.resolution)
					// console.log('倒退时间', mtime)
					from -= mtime
					historyProvider.getBars(symbolInfo, targetResolution, from, to, firstDataRequest, limit, '1')
						.then(bars => {
							_request_task_ing --
							this.updateLastBar(bars, sub_task)
						}).catch(err => {
							_request_task_ing --
							console.log('订阅发生错误', {err})
							// onError(err)
						})
				})
			}, 2000)
			localStorage.setItem(tvid + '-' + 'subscribeIntervalId', _subscribeIntervalId)
		}
	}
	
	this.tryUnsetSubscribe = () => {
		if (_subscribeIntervalId > 0 && _subscribe.length) {
			// for (var name in HistoryBarRequest) {
			// 	if (_subscribe.filter(sub => {
			// 		return sub.symbolInfo.name == name
			// 	}).length == 0) {
			// 		HistoryBarRequest[name] = []
			// 	}
			// }
		}
	}

	this.tryHistoryRequest = (taskRequest) => {
		if (!HistoryBarRequest[taskRequest.symbolInfo.name] 
			|| taskRequest.periodParams.firstDataRequest) {
			HistoryBarRequest[taskRequest.symbolInfo.name] = []
		}
		let list = HistoryBarRequest[taskRequest.symbolInfo.name]
		if (list.length == 0) {
			HistoryBarRequest[taskRequest.symbolInfo.name].push(taskRequest)
			return taskRequest
		}
		let lastRerquest = list[list.length - 1]
		let start = lastRerquest.periodParams.from
		let end = lastRerquest.periodParams.to
		list.forEach(request => {
			if (request.periodParams.from < start) {
				start = request.periodParams.from
			}
			if (request.periodParams.to > end) {
				end = request.periodParams.to
			}
		})
		if (taskRequest.periodParams.from < start && taskRequest.periodParams.to < start) {
			// 更久远的数据
			console.log('====加载更久远的数据, 时间校准')
			taskRequest.periodParams.to = start
		}
		return taskRequest
	}

	this.updateLastBar = (bars, sub_task) => {
		if (bars.length) {
			let symbolInfo = sub_task.symbolInfo
			let oldLastBar = sub_task.lastBar
			let newLastBar = bars[bars.length - 1]
			if (newLastBar.time > oldLastBar.time) {
				// 更新上一个bar, 消除误差
				this.updateTargetBar(oldLastBar, sub_task).then(() => {
					historyProvider.history[symbolInfo.name] = newLastBar
					sub_task.lastBar = newLastBar
					sub_task.listener(newLastBar)
				})
			} else {
				historyProvider.history[symbolInfo.name] = newLastBar
				sub_task.lastBar = newLastBar
				sub_task.listener(newLastBar)
			}
			// console.log('=====updateLastBar', bar.lastBar)
		}
	}

	this.updateTargetBar = (sourceBar, sub_task) => {
		return new Promise((resolve) => {
			let symbolInfo = sub_task.symbolInfo
			let from = Math.round(sub_task.lastBar.time / 1000)
			// 分段时间
			let mtime = convertSupportedResolutionSecond(symbolInfo.exchange, sub_task.resolution)
			let to = from + mtime
			let firstDataRequest = true
			let limit = 1
			let targetResolution = getSupportedResolution(symbolInfo.exchange, sub_task.resolution)
			historyProvider.getBars(symbolInfo, targetResolution, from, to, firstDataRequest, limit)
				.then(bars => {
					if (bars.length) {
						let targetBar = bars[0]
						console.log('更新上一个 last bar', targetBar)
						sub_task.listener(targetBar)
						// console.log('=====updateLastBar', bar.lastBar)
					}
					resolve()
				}).catch(err => {
					console.log('订阅发生错误', {err})
					// onError(err)
					resolve()
				})
		})
	}

	this.onReady = cb => {
		// console.log('=====onReady running')
		DatafeedApiService.config((response) => {
			console.log('配置', response)
			RawData.origin = response.data
			// 交易所
			let exchanges_map = {}
			let exchanges = [{
				value: "",
				name: "All Exchanges",
				desc: ""
			}]
			RawData.origin.forEach(item => {
				if (!exchanges_map[item.exchange]) {
					exchanges_map[item.exchange] = true
					exchanges.push({
						value: item.exchange,
						name: item.exchange,
						desc: (() => {
							if (item.exchange == 'binance') {
								return 'future & spot'
							}
							if (item.exchange == 'bybit') {
								return 'future & spot & delivery'
							}
							return item.type
						})()
					})
				}
			})
			RawData.config.exchanges = exchanges
			// 商品类型 && 商品列表
			let search_symbols = []
			let info_symbols = []
			let symbols_types_map = {}
			let symbols_types = [{
				name: "All types",
				value: ""
			}]
			RawData.origin.forEach(exchange => {
				exchange.symbols.forEach(item => {
					// 商品类型
					if (item.type && !symbols_types_map[item.type]) {
						symbols_types_map[item.type] = true
						symbols_types.push({
							value: item.type,
							name: item.type,
							desc: item.type
						})
					}
					// let symbol = exchange.exchange == 'binance' ? (item.name + '_' + exchange.type.toUpperCase()) : item.name 
					// let desctn = exchange.exchange + ':' + symbol
					let full_name = item.name
					if (exchange.exchange == 'binance') {
						full_name = [
							// exchange.exchange,
							item.name,
							exchange.type.toUpperCase()
						].join('_')
					}
					if (exchange.exchange == 'bybit') {
						full_name = [
							// exchange.exchange,
							item.name,
							'BYBIT',
							exchange.type.toUpperCase()
						].join('_')
					}

					// 商品
					search_symbols.push({
						symbol: full_name,
						full_name: full_name,
						description: full_name,
						exchange: exchange.exchange,
						type: item.type
					})
					let pricescale = 1000000
					if (item.pricePrecision > 0) {
						pricescale = 1
						for (var i = item.pricePrecision ; i > 0 ; i --) {
							pricescale *= 10
						}
					}
					info_symbols.push({
						symbol: full_name,
						full_name: full_name,
						description: full_name,
						ticker: full_name,
						name: full_name,
						type: 'crypto',
						session: '24x7',
						timezone: '',
						exchange: exchange.exchange,
						exchange_type: exchange.type,
						minmov: 1,
						minmov2: 0,
						pricescale: pricescale,
						has_intraday: true,
						intraday_multipliers: IntradayList,
						supported_resolution:  ResolutionList,
						volume_precision: 8,
						data_status: 'streaming',
					})
				})
			})
			RawData.search_symbols = search_symbols
			RawData.info_symbols = info_symbols
			RawData.config.symbols_types = symbols_types
			cb(RawData.config)
			// setTimeout(() => cb(config), 0)
		}, () => {
			console.log('配置ERROR')
		})
		
	}

    this.filterByExchange = (symbol, exchange) => {
        if (exchange) {
            if (symbol.exchange && exchange && symbol.exchange.toLowerCase() === exchange.toLowerCase()) {
                return true;
            }
            return false;
        }
        return true;
    }

    this.filterBySymbolType = (symbol, symbolType) => {
        if (symbolType) {
            if (symbol.type && symbolType && symbol.type.toLowerCase() === symbolType.toLowerCase()) {
                return true;
            }
            return false;
        }
        return true;
    }

    this.filterByUserInput = (symbol, userInput) => {
        if (userInput) {
            if (symbol.symbol && userInput && symbol.symbol.toLowerCase().indexOf(userInput.toLowerCase()) > -1) {
                return true;
            }
            return false;
        }
        return true;
    }

    this.searchSymbols = (userInput, exchange, symbolType, onResultReadyCallback) => {
		console.log('====Search Symbols running', userInput, exchange, symbolType)
		onResultReadyCallback(RawData.search_symbols.filter(symbol => {
            return this.filterBySymbolType(symbol, symbolType) && this.filterByExchange(symbol, exchange) && this.filterByUserInput(symbol, userInput);
		}))
	}

	this.resolveSymbol = (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
		// expects a symbolInfo object in response
		console.log('======resolveSymbol running', symbolName)
		// var split_data = symbolName.split(/[:]/)
		let symbols = RawData.info_symbols.filter(item => {
			if (item.full_name != symbolName) {
				return false
			}
			// if (item.exchange == 'binance' && item.exchange_type.toUpperCase() != split_data[1]) {
			// 	return false
			// }
			return true
		})
		console.log('======resolveSymbol running result', symbols)
		// // eslint-disable-next-line no-debugger
		// debugger
		if (symbols.length) {
			onSymbolResolvedCallback(symbols[0])
		} else {
			onResolveErrorCallback('Not feeling it today')
		}
		// var split_data = symbolName.split(/[:/]/)
		// var symbol_stub = {
		// 	name: symbolName,
		// 	description: '',
		// 	type: 'crypto',
		// 	session: '24x7',
		// 	timezone: 'Etc/UTC',
		// 	ticker: symbolName,
		// 	exchange: split_data[0],
		// 	minmov: 1,
		// 	pricescale: 100,
		// 	has_intraday: true,
		// 	intraday_multipliers: ['1', '60'],
		// 	supported_resolution:  supportedResolutions,
		// 	volume_precision: 8,
		// 	data_status: 'streaming',
		// }

		// if (split_data[2].match(/USD|EUR|JPY|AUD|GBP|KRW|CNY/)) {
		// 	symbol_stub.pricescale = 100
		// }

		// setTimeout(function() {
		// 	onSymbolResolvedCallback(symbol_stub)
		// 	console.log('Resolving that symbol....', symbol_stub)
		// }, 1000)
		
		// onResolveErrorCallback('Not feeling it today')

	}

	this.getBars = function(symbolInfo, resolution, periodParams, onResult, onError) {
		// console.log('=====getBars ', symbolInfo.name, resolution, `${new Date(periodParams.from * 1000).toISOString()} and ${new Date(periodParams.to * 1000).toISOString()}`)
		// console.log(`Requesting bars between ${new Date(from * 1000).toISOString()} and ${new Date(to * 1000).toISOString()}`)
		// let taskRequest = this.tryHistoryRequest({symbolInfo, resolution, periodParams})
		// console.log(`Requesting bars between ${new Date(from * 1000).toISOString()} and ${new Date(to * 1000).toISOString()}`)
		let taskRequest = {symbolInfo, resolution, periodParams}
		if (callbacks && callbacks.getBars) {
			callbacks.getBars(symbolInfo, resolution, taskRequest.periodParams)
		}
		let from = taskRequest.periodParams.from
		let to = taskRequest.periodParams.to
		let firstDataRequest = taskRequest.periodParams.firstDataRequest
		let limit = taskRequest.periodParams.countBack
		let targetResolution = getSupportedResolution(symbolInfo.exchange, resolution)
		// console.log('=====getBars 校准结果', symbolInfo.name, resolution, `${new Date(taskRequest.periodParams.from * 1000).toISOString()} and ${new Date(taskRequest.periodParams.to * 1000).toISOString()}`, limit)
		console.log('=====getBars 校准结果', `${new Date(taskRequest.periodParams.from * 1000).toISOString().substring(0, 16).replace('T', ' ')}  ~  ${new Date(taskRequest.periodParams.to * 1000).toISOString().substring(0, 16).replace('T', ' ')}`, limit)
		
		if (limit > 1000) {
			this.getBarsQueue(symbolInfo, resolution, targetResolution, from, to, firstDataRequest, limit, '0')
			.then(bars => {
				if (bars.length) {
					console.log('=====getBarsQueue 返回结果', bars[0].timeString.substring(0, 16).replace('T', ' '), ' ~ ', bars[bars.length - 1].timeString.substring(0, 16).replace('T', ' '))
					onResult(bars, {noData: false})
				} else {
					onResult(bars, {noData: true})
				}
			}).catch(err => {
				console.log('====getBarsQueue Error', {err})
				onError(err)
			})
		} else {
			historyProvider.getBars(symbolInfo, targetResolution, from, to, firstDataRequest, limit, '0')
				.then(bars => {
					if (bars.length) {
						console.log('=====getBars 返回结果', bars[0].timeString.substring(0, 16).replace('T', ' '), ' ~ ', bars[bars.length - 1].timeString.substring(0, 16).replace('T', ' '))
						onResult(bars, {noData: false})
					} else {
						onResult(bars, {noData: true})
					}
				}).catch(err => {
					console.log('====getBars Error', {err})
					onError(err)
				})
		}

	}

	this.getBarsQueue = function(symbolInfo, sourceResolution, targetResolution, from, to, first, limit, last) {
		console.log('=====分段查询', symbolInfo, sourceResolution, targetResolution, from, to, first, limit, last)
		return new Promise((resolve) => {
			let mtime = convertSupportedResolutionSecond(symbolInfo.exchange, sourceResolution)
			let partSize = 1000
			// let taskCount = Math.ceil(limit / partSize)
			let tasks = []
			let taskTmp = null
			let timePoint = from
			for (var i = 0 ; i < limit ; i ++) {
				if (taskTmp == null) {
					taskTmp = {
						from: timePoint,
						to: timePoint,
						limit: 1
					}
					tasks.push(taskTmp)
					continue
				} else if (i % partSize == 0) {
					taskTmp = {
						from: timePoint,
						to: timePoint,
						limit: 1
					}
					tasks.push(taskTmp)
					continue
				}
				timePoint += mtime
				taskTmp.to = timePoint
				taskTmp.limit ++
			}
			console.log('=====分段查询 任务列表', tasks)

			let result = []
			let resultMap = {}
			let queue = new AsyncQueue()
			tasks.forEach(() => {
				queue.action((finish) => {
					let task = tasks.shift()
					console.log('=====分段查询 任务片段', task)
					historyProvider.getBars(symbolInfo, targetResolution, task.from, task.to, first, task.limit, last)
						.then(bars => {
							if (bars.length) {
								bars.forEach(bar => {
									if (!resultMap[bar.timeString]) {
										resultMap[bar.timeString] = true
										result.push(bar)
									}
								})
							}
							finish()
						}).catch(err => {
							console.log('====getBars Error', {err})
							// onError(err)
							finish()
						})
				})
			})
			queue.finish(() => {
				result.sort((a, b) => {
					return a.time - b.time
				})
				resolve(result)
			})
			queue.start()

		})
	}

	this.subscribeBars = (symbolInfo, resolution, onRealtimeCallback, subscribeUID) => {
		// console.log('=====subscribeBars runnning', symbolInfo, resolution, subscribeUID)
		// stream.subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback)
        console.log('====订阅ID', tvid + '-' + subscribeUID)
		if (callbacks && callbacks.subscribeBars) {
			callbacks.subscribeBars(symbolInfo, resolution, tvid + '-' + subscribeUID)
		}
		var likeSubscribeUid = tvid + '-' + (subscribeUID.substring(0, subscribeUID.lastIndexOf('_')))
		var oldSubIndex = -1
		_subscribe.forEach((item, index) => {
			if (item.uid.indexOf(likeSubscribeUid) === 0) {
				oldSubIndex = index
				return false
			}
		})
        if (oldSubIndex > -1) {
			// 删除旧的订阅
			console.log('====更新订阅', likeSubscribeUid)
			_subscribe.splice(oldSubIndex, 1)
        }
        var newSub = {
            uid: tvid + '-' + subscribeUID,
            resolution,
            symbolInfo,
            lastBar: historyProvider.history[symbolInfo.name],
            listener: onRealtimeCallback,
        }
        _subscribe.push(newSub)
        this.setSubscribeInterval()
	}

	this.unsubscribeBars = subscriberUID => {
		// console.log('=====unsubscribeBars running', subscriberUID)
		// stream.unsubscribeBars(subscriberUID)
        console.log('====取消订阅', tvid + '-' + subscriberUID)
		if (callbacks && callbacks.unsubscribeBars) {
			callbacks.unsubscribeBars(tvid + '-' + subscriberUID)
		}
        var subIndex = _subscribe.findIndex(e => e.uid === tvid + '-' + subscriberUID)
        if (subIndex === -1) {
            return
        }
        // var sub = _subscribe[subIndex]
        // //   socket.emit('SubRemove', {subs: [sub.channelString]})
        _subscribe.splice(subIndex, 1)
        this.tryUnsetSubscribe()
	}

	// , resolutionBack, intervalBack
	this.calculateHistoryDepth = (resolution) => {
		//optional
		// console.log('=====calculateHistoryDepth running')
		// while optional, this makes sure we request 24 hours of minute data at a time
		// CryptoCompare's minute data endpoint will throw an error if we request data beyond 7 days in the past, and return no data
		return resolution < 60 ? {resolutionBack: 'D', intervalBack: '1'} : undefined
	}

	// symbolInfo, startDate, endDate, onDataCallback, resolution
	this.getMarks = () => {
		//optional
		// console.log('=====getMarks running')
	}

	// symbolInfo, startDate, endDate, onDataCallback, resolution
	this.getTimeScaleMarks = () => {
		//optional
		// console.log('=====getTimeScaleMarks running')
	}

	this.getServerTime = cb => {
		// console.log('=====getServerTime running')
		cb(Math.round(Date.now() / 1000))
	}
}
