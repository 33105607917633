<template>
  <div>
    <el-drawer
        size="100%"
        :before-close="() => {$emit('afterUpdate');drawer = false}"
        :close-on-click-modal=false
        :close-on-press-escape=false
        :wrapperClosable=false
        custom-class="black-card"
        :visible.sync="drawer"
        direction="ltr"
        style="overflow-y: auto;padding: 50px 6% 50px 6%">
      <el-card v-if="strategyObj && strategyObj.editors && strategyObj.editors.length > 0" class="black-card" style="margin-top: 0;border: 0;border-radius: 0" shadow="none">
        <el-card class="black-card" body-style="padding: 20px 20px 20px 20px">
          <el-row style="margin-bottom: 10px">
            <span class="dark-mode-font" style="color: white">本策略庫由以下人員參與編輯</span>
          </el-row>
          <el-row v-for="a in strategyObj.editors" :key="a.id">
            <span class="dark-mode-font" style="font-size: 13px">{{ a.user.alias }}</span>
            <span class="dark-mode-font" style="font-size: 13px;display: inline-block;margin-left: 10px">{{ a.editCount }} 次</span>
            <span class="dark-mode-font" style="font-size: 13px;display: inline-block;margin-left: 10px">最後編輯于：{{ new Date(a.updateTime * 1000) }}</span>
          </el-row>
        </el-card>
      </el-card>
      <el-card class="black-card" shadow="none" style="border: 0">
        <el-collapse class="trialpha-collapse" v-model="dataCollapse" style="border: 0;">
          <el-collapse-item class="trialpha-collapse" name="1">
            <template slot="title">
              <i class="el-icon-s-data" style="margin-left: 2px;color: #0ecb81;margin-top: 1px;border: 0"/><h4
                class="dark-mode-font" style="margin-left: 10px;padding-top: 6px;color: white">Data Dashboard</h4>
            </template>
            <el-card class="black-card" style="height: 668px;border: 0;margin-bottom: 5px" shadow="none"
                     body-style="padding: 20px">
              <el-row style="padding-top: 0">
                <el-row>
                  <el-button-group style="float: left;margin-top: 10px">
                    <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 118px;height: 32px;text-align: center"
                               :disabled="analysisType === 'FEE'" @click="changeAnalysis">资金费分析
                    </el-button>
                    <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 118px;height: 32px;text-align: center"
                               :disabled="analysisType === 'GAP'" @click="changeAnalysis">差价资金费分析
                    </el-button>
                  </el-button-group>
                </el-row>
                <el-row>
                  <FeeAnalysisV2 v-if="analysisType === 'FEE'" ref="feeV2"></FeeAnalysisV2>
                  <el-card class="black-card" style="height: 590px;border: 0;margin-top: 10px;overflow-y: auto"
                           shadow="none" body-style="padding: 0" v-if="analysisType === 'GAP'">
                    <data-monitor-medium/>
                  </el-card>
                </el-row>
              </el-row>
            </el-card>
          </el-collapse-item>
        </el-collapse>
      </el-card>
      <el-card class="black-card" style="margin-top: 0;border: 0;border-radius: 0" shadow="none">
        <el-collapse class="trialpha-collapse" v-model="infoCollapse" style="border: 0;">
          <el-collapse-item class="trialpha-collapse" name="1">
            <template slot="title">
              <i class="el-icon-s-tools" style="margin-left: 2px;color: #0ecb81;margin-top: 1px;border: 0"/><h4
                class="dark-mode-font" style="margin-left: 10px;padding-top: 6px;color: white">Information</h4>
            </template>
            <el-row style="margin-top: 0;;height: 568px;margin-bottom: 40px">
              <el-col :span="16" style=";height: 100%;padding-left: 20px;padding-top: 15px;padding-right: 0">
                <el-card class="black-card">
                  <el-row>
                    <span class="dark-mode-font"
                          style="color: white;display: inline-block;float: left;height: 100%;font-size: 16px">Basic Information</span>
                  </el-row>
                  <el-row style="margin-top: 50px">
                    <el-col :span="5">
                      <span class="dark-mode-font"
                            style="display: inline-block;float: right;color: white;margin-top: 5px">Strategy Name</span>
                    </el-col>
                    <el-col :span="15" :offset="1">
                      <input class="trialpha-input" style="width: 90%;float: left;height: 38px"
                             v-model="strategyObj.name"/>
                    </el-col>
                  </el-row>
                  <el-row style="margin-top: 20px">
                    <el-col :span="5" style="padding-top: 3px">
                      <span class="dark-mode-font"
                            style="display: inline-block;float: right;color: white">Platforms</span>
                    </el-col>
                    <el-col :span="15" :offset="1">
                      <el-select v-model="strategyObj.platforms" multiple placeholder="请选择"
                                 style="width: 90%;float: left;color: white;" size="small" popper-class="black-card">
                        <el-option
                            style="color: gray"
                            v-for="item in Object.keys(exchangeInfo)"
                            :key="item"
                            :label="item"
                            :value="item"
                            :disabled="!exchangeInfo[item].active">
                        </el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                  <el-row style="margin-top: 20px">
                    <el-col :span="5" style="padding-top: 3px">
                      <span class="dark-mode-font" style="display: inline-block;float: right;color: white">Coins</span>
                    </el-col>
                    <el-col :span="15" :offset="1">
                      <el-select v-model="strategyObj.coins" multiple placeholder="请选择"
                                 style="width: 90%;float: left;color: white;" size="small" popper-class="black-card">
                        <el-option
                            style="color: gray"
                            v-for="item in ['USD', 'BTC', 'ETH']"
                            :key="item"
                            :label="item"
                            :value="item">
                        </el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                  <el-row style="margin-top: 25px">
                    <el-col :span="5">
                      <span class="dark-mode-font"
                            style="display: inline-block;float: right;color: white">Description</span>
                    </el-col>
                    <el-col :span="15" :offset="1">
                      <textarea class="trialpha-input"
                                style="width: 90%;float: left;height: 253px;padding: 5px;font-size: 13px"
                                v-model="strategyObj.description"/>
                    </el-col>
                  </el-row>
                  <el-row style="margin-top: 20px">
                    <el-col :span="15" :offset="6">
                      <el-button class="dark-mode-btn"
                                 style=";margin-right: 10%;width: 268px;border-radius: 3px;float: right"
                                 @click="updateBasicInfo" :loading="loading.updateBasicInfo">SUBMIT
                      </el-button>
                    </el-col>
                  </el-row>
                </el-card>
              </el-col>
              <el-col :span="8" style=";padding-left: 15px;padding-top: 15px;padding-right: 15px">
                <el-card class="black-card">
                  <el-row>
                    <span class="dark-mode-font"
                          style="color: white;display: inline-block;float: left;height: 100%;font-size: 16px">Comments</span>
                  </el-row>
                  <el-row>
                    <el-card class="black-card" style="border: 0;height: 368px;overflow-y: auto" shadow="none">
                      <el-card class="black-card" v-for="c in strategyObj.comments" :key="c.id" shadow="none"
                               style="margin-top: 10px" body-style="padding: 10px">
                        <el-row>
                          <span class="dark-mode-font"
                                style="color: white;font-size: 12px;display: inline-block;float: left">留言用户：{{
                              c.user.alias
                            }}</span>
                        </el-row>
                        <el-row>
                          <span class="dark-mode-font"
                                style="color: white;font-size: 12px;display: inline-block;float: left">留言时间：{{
                              new Date(c.updateTime * 1000)
                            }}</span>
                        </el-row>
                        <el-row>
                          <span class="dark-mode-font"
                                style="color: white;font-size: 12px;display: inline-block;float: left;text-align: left">留言内容：{{
                              c.content
                            }}</span>
                        </el-row>
                      </el-card>
                      <el-card class="black-card"
                               v-if="strategyObj && strategyObj.comments &&  strategyObj.comments.length === 0"
                               shadow="none" style="margin-top: 150px;border: 0" body-style="padding: 10px">
                        <span class="dark-mode-font" style=";font-size: 15px;color: lightgray">Empty comments...</span>
                      </el-card>
                    </el-card>
                    <div style="padding: 20px">
                      <el-row>
                        <textarea class="trialpha-input" v-model="newComment" placeholder="Leave your message..."
                                  style="margin-top: 15px;height: 68px;border-radius: 3px 3px 0 0"></textarea>
                      </el-row>
                      <el-row style="margin-top: -7px">
                        <el-button class="dark-mode-btn" style="width: 100%;border-radius: 0 0 3px 3px;border-top: 0"
                                   @click="addComment" :loading="loading.addComment">SUBMIT COMMENT
                        </el-button>
                      </el-row>
                    </div>
                  </el-row>
                </el-card>
              </el-col>
            </el-row>
          </el-collapse-item>
        </el-collapse>
      </el-card>
      <el-card class="black-card" style="margin-top: 0;border: 0;border-radius: 0" shadow="none">
        <el-card class="black-card" body-style="padding: 20px 20px 20px 20px">
          <el-row>
            <span class="dark-mode-font" style="color: white;display: inline-block;float: left;height: 100%;font-size: 16px">Strategy</span>
          </el-row>
          <el-row style="margin-top: 35px">
            <el-col :span="5">
              <span class="dark-mode-font" style="color: white;display: inline-block;float: left;font-size: 12px">Platform</span>
            </el-col>
            <el-col :span="5">
              <span class="dark-mode-font" style="color: white;display: inline-block;float: left;font-size: 12px">Type</span>
            </el-col>
            <el-col :span="5">
              <span class="dark-mode-font" style="color: white;display: inline-block;float: left;font-size: 12px">Symbol</span>
            </el-col>
            <el-col :span="5">
              <span class="dark-mode-font" style="color: white;display: inline-block;float: left;font-size: 12px">Side</span>
            </el-col>
          </el-row>
          <el-row style="margin-top: 5px">
            <el-col :span="5" style="padding-right: 15px">
              <el-select class="select-symbol" filterable style="width: 100%" v-model="platformIns1.selectPlatform" placeholder="请选择交易平台" @change="changePlatform(platformIns1)">
                <el-option
                    v-for="item in platformIns1.platforms"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="5" style="padding-right: 15px">
              <el-select class="select-symbol" filterable style="width: 100%" v-model="platformIns1.selectSymbolType" placeholder="请选择交易类型" @change="changeSymbolType(platformIns1)">
                <el-option
                    v-for="item in platformIns1.symbolType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="5" style="padding-right: 15px">
              <el-select class="select-symbol" filterable style="width: 100%" v-model="platformIns1.selectSymbol" placeholder="请选择交易标的">
                <el-option
                    v-for="item in platformIns1.symbols"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="5" style="padding-right: 15px">
              <el-select class="select-symbol" filterable style="width: 100%" v-model="platformIns1.selectSide" placeholder="请选择交易方向">
                <el-option
                    v-for="item in platformIns1.side"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="4" style="padding-right: 0">
              <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 118px;height: 32px;float: right;text-align: center" @click="createNewObj(true)">创建单个实例</el-button>
            </el-col>
          </el-row>
          <el-row style="margin-top: 15px">
            <el-col :span="2">
              <span class="dark-mode-font" style="color: white;display: inline-block;float: left;font-size: 12px">Platform 1</span>
            </el-col>
            <el-col :span="3">
              <span class="dark-mode-font" style="color: white;display: inline-block;float: left;font-size: 12px">Type 1</span>
            </el-col>
            <el-col :span="3">
              <span class="dark-mode-font" style="color: white;display: inline-block;float: left;font-size: 12px">Symbol 1</span>
            </el-col>
            <el-col :span="2">
              <span class="dark-mode-font" style="color: white;display: inline-block;float: left;font-size: 12px">Side 1</span>
            </el-col>
            <el-col :span="2">
              <span class="dark-mode-font" style="color: white;display: inline-block;float: left;font-size: 12px">Platform 2</span>
            </el-col>
            <el-col :span="3">
              <span class="dark-mode-font" style="color: white;display: inline-block;float: left;font-size: 12px">Type 2</span>
            </el-col>
            <el-col :span="3">
              <span class="dark-mode-font" style="color: white;display: inline-block;float: left;font-size: 12px">Symbol 2</span>
            </el-col>
            <el-col :span="2">
              <span class="dark-mode-font" style="color: white;display: inline-block;float: left;font-size: 12px">Side 2</span>
            </el-col>
          </el-row>
          <el-row style="margin-top: 5px">
            <el-col :span="2" style="padding-right: 15px">
              <el-select class="select-symbol" filterable style="width: 100%" v-model="platformIns1.selectPlatform" placeholder="请选择交易平台" @change="changePlatform(platformIns1)">
                <el-option
                    v-for="item in platformIns1.platforms"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="3" style="padding-right: 15px">
              <el-select class="select-symbol" filterable style="width: 100%" v-model="platformIns1.selectSymbolType" placeholder="请选择交易类型" @change="changeSymbolType(platformIns1)">
                <el-option
                    v-for="item in platformIns1.symbolType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="3" style="padding-right: 15px">
              <el-select class="select-symbol" filterable style="width: 100%" v-model="platformIns1.selectSymbol" placeholder="请选择交易标的">
                <el-option
                    v-for="item in platformIns1.symbols"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="2" style="padding-right: 15px">
              <el-select class="select-symbol" filterable style="width: 100%" v-model="platformIns1.selectSide" placeholder="请选择交易方向">
                <el-option
                    v-for="item in platformIns1.side"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="2" style="padding-right: 15px">
              <el-select class="select-symbol" filterable style="width: 100%" v-model="platformIns2.selectPlatform" placeholder="请选择交易平台" @change="changePlatform(platformIns2)">
                <el-option
                    v-for="item in platformIns2.platforms"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="3" style="padding-right: 15px">
              <el-select class="select-symbol" filterable style="width: 100%" v-model="platformIns2.selectSymbolType" placeholder="请选择交易类型" @change="changeSymbolType(platformIns2)">
                <el-option
                    v-for="item in platformIns2.symbolType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="3" style="padding-right: 15px">
              <el-select class="select-symbol" filterable style="width: 100%" v-model="platformIns2.selectSymbol" placeholder="请选择交易标的">
                <el-option
                    v-for="item in platformIns2.symbols"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="2" style="padding-right: 15px">
              <input class="trialpha-input" :value="platformIns1.selectSide === 'LONG' ? 'SHORT' : 'LONG'" style="height: 35px" disabled/>
            </el-col>
            <el-col :span="4" style="padding-right: 0">
              <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 118px;height: 32px;float: right;text-align: center" @click="createNewObj(false)" >创建对冲实例</el-button>
            </el-col>
          </el-row>
          <el-row style="margin-top: 30px">

            <!-- Position Title -->
            <el-card class="black-card" style="border-radius: 3px 3px 0 0;border-bottom: 0;background-color: #1c2128" body-style="padding: 10px" shadow="none">
              <el-row>
                <el-col :span="8">
                  <span class="dark-mode-font" style="color: white;display: inline-block;float: left">Design positions</span>
                </el-col>
                <el-col :span="16">
                  <export-excel
                      :name="'' + strategyObj.name + '-' + (new Date().getTime()) + '.xls'"
                      :data = "positionExport">
                    <i class="el-icon-download" style="cursor: pointer;float: right;font-size: 20px;margin-top: 8px;color: lightgray"></i>
                  </export-excel>
                  <input class="dark-mode-input" v-model="filterName" placeholder="Filter..." style="margin-right: 10px;color: white;height: 25px;outline: none;font-size: 12px;background-color: rgba(0, 0, 0, 0);border: 0;width: 98px;float: right;border-bottom: 1px solid #444c56;padding-left: 18px;padding-bottom: 1px" />
                  <i class="el-icon-search" style="float: right;color: #444c56;margin-top: 5px;margin-right: -15px"></i>
                </el-col>
              </el-row>
            </el-card>

            <!-- Position 表头 -->
            <el-card class="black-card" style="border-radius: 0;height: 28px;background-color: #1c2128" shadow="none" body-style="padding: 4px 10px 3px 10px">
              <el-row>
                <el-col :span="4">
                  <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">Symbol 1</span>
                </el-col>
                <el-col :span="4">
                  <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">Symbol 2</span>
                </el-col>
                <el-col :span="2">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">Size</span>
                </el-col>
                <el-col :span="2">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">Gap</span>
                </el-col>
                <el-col :span="3">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">Week Fee</span>
                </el-col>
                <el-col :span="3">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">Two weeks Fee</span>
                </el-col>
                <el-col :span="3">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">Month Fee</span>
                </el-col>
                <el-col :span="3">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">Operation</span>
                </el-col>
              </el-row>
            </el-card>

            <!-- Position 数据展示 -->
            <el-card class="black-card" style="border-radius: 0;border-top: 0;" shadow="none" body-style="padding: 0">
              <div v-if="strategyObj.strategyConfig && strategyObj.strategyConfig.instanceList && strategyObj.strategyConfig.instanceList.length > 0">
                <el-card class="black-card-hover" shadow="none" style="border: 0;border-radius: 0" body-style="padding:0" v-for="(p, index) in strategyObj.strategyConfig.instanceList.filter((f) => doStrategyFilter(f))" :key="index">
                  <el-row style="height: 28px;line-height: 28px;border-bottom: 1px solid #444c56;padding: 0 10px 0 10px">
                    <el-col :span="4">
                      <img :src="exchangeInfo[p.platform1].logo" style="height: 12px;width: 12px;margin-top: 8px;float: left" />
                      <span class="dark-mode-font" :style="'margin-left: 10px;display: inline-block;float: left;text-align: left;font-size: 12px;' + ((p['side1'] === 'LONG') ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ p['symbol1'] }}</span>
                    </el-col>
                    <el-col :span="4">
                      <img :src="exchangeInfo[p.platform2].logo" style="height: 12px;width: 12px;margin-top: 8px;float: left" v-if="p['symbol2'] !== '-'" />
                      <span class="dark-mode-font" :style="'margin-left: 10px;display: inline-block;float: left;text-align: left;font-size: 12px;' + ((p['side2'] === 'LONG') ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ p['symbol2'] }}</span>
                    </el-col>
                    <el-col :span="2" style="line-height: 28px;padding: 0;margin-top: -2px">
                      <input class="trialpha-input" v-model="p.position" style=";height: 28px;margin-top: -5px;border-top: 0;border-radius: 0" @change="createReport"/>
                    </el-col>
                    <el-col :span="2">
                      <span class="dark-mode-font" :style="'display: inline-block;float: right;text-align: right;font-size: 12px;' + ((getSymbolGap(p) > 0) ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ getSymbolGap(p)}}%</span>
                    </el-col>
                    <el-col :span="3">
                      <span class="dark-mode-font" :style="'display: inline-block;float: right;text-align: right;font-size: 12px;' + ((getFundingFee(p, 7) > 0) ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ getFundingFee(p, 7) }}% / {{ getRealProfit(p, 7) }}%</span>
                    </el-col>
                    <el-col :span="3">
                      <span class="dark-mode-font" :style="'display: inline-block;float: right;text-align: right;font-size: 12px;' + ((getFundingFee(p, 15) > 0) ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ getFundingFee(p, 15) }}% / {{ getRealProfit(p, 15) }}%</span>
                    </el-col>
                    <el-col :span="3">
                      <span class="dark-mode-font" :style="'display: inline-block;float: right;text-align: right;font-size: 12px;' + ((getFundingFee(p, 30) > 0) ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ getFundingFee(p, 30) }}% / {{ getRealProfit(p, 30) }}%</span>
                    </el-col>
                    <el-col :span="3">
                      <el-button class="dark-mode-btn" style="border-radius: 0;border-top: 0;border-right: 0;height: 28px;line-height: 28px;float: right;margin-top: 0;font-size: 12px;padding: 0;width: 68px;margin-right: -10px" @click="deleteObj(index)">Remove</el-button>
                    </el-col>
                  </el-row>
                </el-card>
              </div>
              <div v-if="!(strategyObj.strategyConfig && strategyObj.strategyConfig.instanceList && strategyObj.strategyConfig.instanceList.length > 0)">
                <el-row style="height: 25px;border: 0;background-color: rgba(0, 0, 0, 0);padding: 3px 10px 3px 10px;text-align: center;margin-top: 20px;margin-bottom: 25px">
                  <span class="dark-mode-font" style=";font-size: 12px;color:gray;text-align: center;"><i class="el-icon-s-order" /> EMPTY...</span>
                </el-row>
              </div>
            </el-card>

            <!-- Position Title -->
            <el-card class="black-card" style="border-radius: 0 0 3px 3px;border-top: 0;background-color: #1c2128" body-style="padding: 10px" shadow="none">
              <el-row style="margin-bottom: 10px">
                <el-col :span="8">
                  <span class="dark-mode-font" style="color: white;display: inline-block;float: left">Report</span>
                </el-col>
              </el-row>
              <el-row v-for="e in Object.keys(report.platformInfo)" :key="e">
                <el-col :span="4">
                  <span class="dark-mode-font" style="color: white;display: inline-block;float: left; text-align: left;font-size: 12px">{{ e }}</span>
                </el-col>
                <el-col :span="7">
                  <span class="dark-mode-font" style="color: white;display: inline-block;float: left; text-align: left;font-size: 12px">总仓位 {{ report.platformInfo[e].totalUsd.toFixed(2) }}</span>
                </el-col>
                <el-col :span="13">
                  <span class="dark-mode-font" style="color: white;display: inline-block;float: left; text-align: left;font-size: 12px">多空净敞口 {{ report.platformInfo[e].netValue.toFixed(2) }} ({{((report.platformInfo[e].netValue / report.totalUsd) * 100).toFixed(3)}}%)</span>
                </el-col>
              </el-row>
              <el-row style="margin-top: 5px">
                <el-col :span="24">
                  <span class="dark-mode-font" style="color: white;display: inline-block;float: left; text-align: left;font-size: 12px">过往7日资金费：{{report.profit.WEEK}}%，过往7日年化：{{((report.profit.WEEK) * 52.14).toFixed(3)}}%</span>
                </el-col>
              </el-row>
              <el-row style="margin-top: 0">
                <el-col :span="24">
                  <span class="dark-mode-font" style="color: white;display: inline-block;float: left; text-align: left;font-size: 12px">过往15日资金费：{{((report.profit.TWO_WEEKS))}}%，过往15日年化：{{((report.profit.TWO_WEEKS) * 24.3).toFixed(3)}}%</span>
                </el-col>
              </el-row>
              <el-row style="margin-top: 0">
                <el-col :span="24">
                  <span class="dark-mode-font" style="color: white;display: inline-block;float: left; text-align: left;font-size: 12px">过往30日资金费：{{((report.profit.MONTH))}}%，过往30日年化：{{((report.profit.MONTH) * 12.16).toFixed(3)}}%</span>
                </el-col>
              </el-row>
              <el-row style="margin-top: 0">
                <el-col :span="24">
                  <span class="dark-mode-font" style="color: white;display: inline-block;float: left; text-align: left;font-size: 12px">单倍本金成本损益（由标的间距对冲时差价引起的损益，只有均为同币种对冲时此值有意义）：{{(Number(report.profit.COST) - 0.4).toFixed(3)}}%（包含预估进出手续费和滑点损耗共计0.4%，可根据账号实际情况下调此损耗）</span>
                </el-col>
              </el-row>
              <el-row style="margin-top: 0">
                <el-col :span="24">
                  <span class="dark-mode-font" style="color: white;display: inline-block;float: left; text-align: left;font-size: 12px">单倍本金策略年化收益终值预期：{{(((((report.profit.WEEK) * 52.14) + ((report.profit.TWO_WEEKS) * 24.3) + ((report.profit.MONTH) * 12.16)) / 3) + Number(report.profit.COST) - 0.4).toFixed(3)}}%</span>
                </el-col>
              </el-row>
            </el-card>

            <el-row style="margin-top:20px">
              <el-button class="dark-mode-btn" style="width: 268px;border-radius: 3px;border-top: 0;float: right"
                         @click="updateAll" :loading="loading.updateBasicInfo">SUBMIT
              </el-button>
            </el-row>

          </el-row>
        </el-card>
      </el-card>
    </el-drawer>
  </div>
</template>

<script>

import {Message} from "element-ui";
import StrategyLibsService from "@/api/strategyLibs/StrategyLibsService";
import FeeAnalysisV2 from "@/pages/controlPanel/analysis/FeeAnalysisV2";
import DataMonitorMedium from "@/pages/controlPanel/analysis/DataMonitorMedium";
import ExchangeInformations from "@/const/ExchangeInformations";
import GeneralApiService from "@/api/trade/GeneralApiService";
import SymbolInfoService from "@/utils/SymbolInfoService";
import SymbolFundingFeeService from "@/utils/SymbolFundingFeeService";

export default {
  name: "StrategyDetailsDrawer",
  components: {DataMonitorMedium, FeeAnalysisV2},
  data: function () {
    return {
      libId: 0,
      newComment: '',
      analysisType: 'FEE',
      exchangeInfo: {
        BINANCE: {
          logo: ExchangeInformations.exchangeInfos.BINANCE.icon,
          fontColor: '#f5bc2f',
          active: true
        },
        DERIBIT: {
          logo: ExchangeInformations.exchangeInfos.DERIBIT.icon,
          fontColor: '#04cfbe',
          active: true
        },
        OKX: {
          logo: ExchangeInformations.exchangeInfos.OKX.icon,
          fontColor: '#67ccdf',
          active: true
        },
        BYBIT: {
          logo: ExchangeInformations.exchangeInfos.BYBIT.icon,
          fontColor: '#67ccdf',
          active: true
        },
        MEXC: {
          logo: ExchangeInformations.exchangeInfos.MEXC.icon,
          fontColor: '#67ccdf',
          active: true
        },
        DYDX: {
          logo: ExchangeInformations.exchangeInfos.DYDX.icon,
          fontColor: '#67ccdf',
          active: true
        }
      },
      drawer: false,
      strategyObj: {},
      loading: {
        addComment: false,
        updateBasicInfo: false
      },
      filterName: '',
      dataCollapse: ['1'],
      infoCollapse: ['1'],
      defaultSymbol: {
        BINANCE: {
          SYMBOL_TYPE: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT,
          SPOT: 'BTCUSDT',
          USD_CONTRACT: 'BTCUSDT',
          COIN_CONTRACT: 'BTCUSD_PERP'
        },
        BYBIT: {
          SYMBOL_TYPE: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT,
          SPOT: 'BTCUSDT',
          USD_CONTRACT: 'BTCUSDT'
        },
        OKX: {
          SYMBOL_TYPE: GeneralApiService.SYMBOL_TYPE.ALL,
          ALL: 'BTC-USDT-SWAP',
          SPOT: 'BTC-USDT-SWAP',
          USD_CONTRACT: 'BTC-USDT-SWAP',
          COIN_CONTRACT: 'BTC-USDT-SWAP'
        },
        DERIBIT: {
          SYMBOL_TYPE: GeneralApiService.SYMBOL_TYPE.ALL,
          ALL: 'BTC-PERPETUAL',
          SPOT: 'BTC-PERPETUAL',
          USD_CONTRACT: 'BTC-PERPETUAL',
          COIN_CONTRACT: 'BTC-PERPETUAL'
        },
        MEXC: {
          SYMBOL_TYPE: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT,
          SPOT: 'BTC_USDT',
          USD_CONTRACT: 'BTC_USDT',
          COIN_CONTRACT: 'BTC_USD'
        },
        DYDX: {
          SYMBOL_TYPE: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT,
          SPOT: 'BTC-USD',
          USD_CONTRACT: 'BTC-USD',
          COIN_CONTRACT: 'BTC-USD'
        }
      },
      platformIns1: {
        selectSymbol: '',
        selectPlatform: GeneralApiService.PLATFORM.BINANCE,
        selectSymbolType: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT,
        symbolType: [{label: 'Spot', value: GeneralApiService.SYMBOL_TYPE.SPOT}, {label: 'USDⓈ Contract', value: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT}],
        platforms: [GeneralApiService.PLATFORM.BINANCE, GeneralApiService.PLATFORM.OKX, GeneralApiService.PLATFORM.BYBIT, GeneralApiService.PLATFORM.DERIBIT, GeneralApiService.PLATFORM.MEXC, GeneralApiService.PLATFORM.DYDX],
        symbols: [],
        selectSide: 'LONG',
        side: ['LONG', 'SHORT']
      },
      platformIns2: {
        selectSymbol: '',
        selectPlatform: GeneralApiService.PLATFORM.BINANCE,
        selectSymbolType: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT,
        symbolType: [{label: 'Spot', value: GeneralApiService.SYMBOL_TYPE.SPOT}, {label: 'USDⓈ Contract', value: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT}],
        platforms: [GeneralApiService.PLATFORM.BINANCE, GeneralApiService.PLATFORM.OKX, GeneralApiService.PLATFORM.BYBIT, GeneralApiService.PLATFORM.DERIBIT, GeneralApiService.PLATFORM.MEXC, GeneralApiService.PLATFORM.DYDX],
        symbols: [],
        selectSide: 'LONG',
        side: ['LONG', 'SHORT']
      },
      newObj: {
        symbol1: '',
        platform1: '',
        symbolType1: '',
        side1: '',
        symbol2: '',
        platform2: '',
        symbolType2: '',
        side2: '',
        position: 100
      },
      report: {
        totalUsd: 0,
        platformInfo: {},
        profit: {
          WEEK: 0,
          TWO_WEEKS: 0,
          MONTH: 0,
          COST: 0
        }
      },
      positionExport: []
    }
  },
  methods: {
    render: function (libId) {
      let that = this;
      that.libId = libId;
      that.drawer = true;
      that.report = {
        totalUsd: 0,
        platformInfo: {},
        profit: {
          WEEK: 0,
              TWO_WEEKS: 0,
              MONTH: 0,
              COST: 0
        }
      };
      that.positionExport = [];
      that.$nextTick(() => {
        that.$refs['feeV2'].render();
      });
      GeneralApiService.getAllPrice(this.platformIns1.selectPlatform, this.platformIns1.selectSymbolType, function (data) {
        that.platformIns1.symbols = Object.keys(data);
        that.platformIns1.selectSymbol = that.defaultSymbol[that.platformIns1.selectPlatform][that.platformIns1.selectSymbolType];
        that.platformIns2.symbols = Object.keys(data);
        that.platformIns2.selectSymbol = that.defaultSymbol[that.platformIns2.selectPlatform][that.platformIns2.selectSymbolType];
      });
      StrategyLibsService.strategyLibQuery(libId, function (res) {
        that.strategyObj = res[0];
        if (!that.strategyObj.strategyConfig) {
          that.strategyObj.strategyConfig = '[]';
        }
        that.strategyObj.strategyConfig = JSON.parse(that.strategyObj.strategyConfig);
        if (that.strategyObj.strategyConfig.instanceList) {
          that.createReport();
        }
      }, function () {
        Message.error('Please try to fetch strategy again')
      });
    },
    changeAnalysis: function () {
      if (this.analysisType === 'FEE') {
        this.analysisType = 'GAP';
      } else {
        this.analysisType = 'FEE';
        this.$nextTick(() => {
          this.$refs['feeV2'].render();
        });
      }
    },
    addComment: function () {
      let that = this;
      that.loading.addComment = true;
      StrategyLibsService.addComment(this.libId, this.newComment, function () {
        StrategyLibsService.strategyLibQuery(that.libId, function (res) {
          that.strategyObj.comments = res[0].comments;
          that.strategyObj.editors = res[0].editors;
          that.loading.addComment = false;
        });
      });
    },
    changeSymbolType: function (ins) {
      let that = this;
      GeneralApiService.getAllPrice(ins.selectPlatform, ins.selectSymbolType, function (data) {
        ins.symbols = Object.keys(data);
        ins.selectSymbol = that.defaultSymbol[ins.selectPlatform][ins.selectSymbolType];
      });
    },
    changePlatform: function (ins) {
      if (ins.selectPlatform === GeneralApiService.PLATFORM.BINANCE) {
        ins.symbolType = [{label: 'Spot', value: GeneralApiService.SYMBOL_TYPE.SPOT}, {label: 'USDⓈ Contract', value: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT}];
      } else if (ins.selectPlatform === GeneralApiService.PLATFORM.BYBIT) {
        ins.symbolType = [{label: 'Spot', value: GeneralApiService.SYMBOL_TYPE.SPOT}, {label: 'USDⓈ Contract', value: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT}];
      } else if (ins.selectPlatform === GeneralApiService.PLATFORM.OKX) {
        ins.symbolType = [{label: 'ALL', value: GeneralApiService.SYMBOL_TYPE.ALL}];
      } else if (ins.selectPlatform === GeneralApiService.PLATFORM.DYDX) {
        ins.symbolType = [{label: 'USDⓈ Contract', value: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT}];
      } else if (ins.selectPlatform === GeneralApiService.PLATFORM.MEXC) {
        ins.symbolType = [{label: 'Spot', value: GeneralApiService.SYMBOL_TYPE.SPOT}, {label: 'USDⓈ Contract', value: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT}];
      }
      ins.selectSymbolType = this.defaultSymbol[ins.selectPlatform]['SYMBOL_TYPE'];
      this.changeSymbolType(ins)
    },
    doStrategyFilter: function (obj) {
      if (!this.filterName || this.filterName === '') {
        return obj;
      }
      if (obj.symbol1.toUpperCase().indexOf(this.filterName.toUpperCase()) > -1 || obj.symbol2.toUpperCase().indexOf(this.filterName.toUpperCase()) > -1) {
        return obj;
      }
    },
    createNewObj: function (isSingle) {
      let newObj = isSingle ? {
        symbol1: this.platformIns1.selectSymbol,
        platform1: this.platformIns1.selectPlatform,
        symbolType1: this.platformIns1.selectSymbolType,
        side1: this.platformIns1.selectSide,
        symbol2: '-',
        platform2: '-',
        symbolType2: '-',
        side2: '-',
        position: 100
      } : {
        symbol1: this.platformIns1.selectSymbol,
        platform1: this.platformIns1.selectPlatform,
        symbolType1: this.platformIns1.selectSymbolType,
        side1: this.platformIns1.selectSide,
        symbol2: this.platformIns2.selectSymbol,
        platform2: this.platformIns2.selectPlatform,
        symbolType2: this.platformIns2.selectSymbolType,
        side2: this.platformIns1.selectSide === 'LONG' ? 'SHORT' : 'LONG',
        position: 100
      }
      if (!this.strategyObj.strategyConfig.instanceList) {
        this.strategyObj.strategyConfig = {};
        this.strategyObj.strategyConfig.instanceList = [];
      }
      this.strategyObj.strategyConfig.instanceList.push(newObj);
      this.createReport();
      this.$forceUpdate();
    },
    deleteObj: function (index) {
      this.strategyObj.strategyConfig.instanceList.splice(index, 1);
      this.createReport();
      this.$forceUpdate();
    },
    getSymbolGap: function (obj) {
      if (obj.symbol1 === '-') {
        return 0;
      }
      if (obj.symbol2 === '-') {
        return 0;
      }
      let price1 = SymbolInfoService.getSymbolPrice(obj.platform1, obj.symbolType1, obj.symbol1);
      let price2 = SymbolInfoService.getSymbolPrice(obj.platform2, obj.symbolType2, obj.symbol2);
      if (obj.side1 === 'LONG') {
        return Number((((price2 - price1) / price1) * 100).toFixed(3));
      } else {
        return Number((((price1 - price2) / price2) * 100).toFixed(3));
      }
    },
    getFundingFee: function (obj, feeDays) {
      let symbol1Fee = SymbolFundingFeeService.getFundingFee(obj.platform1, obj.symbolType1, obj.symbol1, feeDays);
      let symbol2Fee = SymbolFundingFeeService.getFundingFee(obj.platform2, obj.symbolType2, obj.symbol2, feeDays);
      let totalFee = 0;
      if (obj.side1 === 'LONG' && obj.symbol1 !== '-') {
        totalFee = totalFee - symbol1Fee;
      } else {
        totalFee = totalFee + symbol1Fee;
      }
      if (obj.side2 === 'LONG' && obj.symbol2 !== '-') {
        totalFee = totalFee - symbol2Fee;
      } else {
        totalFee = totalFee + symbol2Fee;
      }
      return Number(totalFee.toFixed(3));
    },
    updateBasicInfo: function () {
      let that = this;
      that.loading.updateBasicInfo = true;
      StrategyLibsService.strategyLibUpdate(this.libId, this.strategyObj.name, this.strategyObj.platforms, this.strategyObj.coins, this.strategyObj.description, '', function () {
        Message.success('Update basic info success');
        that.loading.updateBasicInfo = false;
        StrategyLibsService.strategyLibQuery(that.libId, function (res) {
          that.strategyObj.comments = res[0].comments;
          that.strategyObj.editors = res[0].editors;
        });
      }, function () {
        Message.error('Please try again');
        that.loading.updateBasicInfo = false;
      });
    },
    updateAll: function () {
      let that = this;
      that.loading.updateBasicInfo = true;
      StrategyLibsService.strategyLibUpdate(this.libId, this.strategyObj.name, this.strategyObj.platforms, this.strategyObj.coins, this.strategyObj.description, JSON.stringify(this.strategyObj.strategyConfig), function () {
        Message.success('Update strategy lib success');
        that.loading.updateBasicInfo = false;
        StrategyLibsService.strategyLibQuery(that.libId, function (res) {
          that.strategyObj.comments = res[0].comments;
          that.strategyObj.editors = res[0].editors;
        });
      }, function () {
        Message.error('Please try again')
        that.loading.updateBasicInfo = false;
      });
    },
    getRealProfit: function (obj, days) {
      let fundingFee = this.getFundingFee(obj, days);
      let totalUsd = 0;
      this.strategyObj.strategyConfig.instanceList.forEach((ins) => {
        totalUsd += Number(ins.position);
      });
      return ((obj.position * fundingFee) / totalUsd).toFixed(3);
    },
    createReport: function () {
      let totalUsd = 0;
      let platformInfo = {}
      let profit = {
        WEEK: 0,
        TWO_WEEKS: 0,
        MONTH: 0,
        COST: 0
      }
      let exportResult = [];
      this.strategyObj.strategyConfig.instanceList.forEach((ins) => {
        totalUsd += Number(ins.position);
        exportResult.push(ins);
        if (ins.platform1 !== '-' && !platformInfo[ins.platform1]) {
          platformInfo[ins.platform1] = {
            totalUsd: 0,
            netValue: 0
          };
        }
        if (ins.platform2 !== '-' && !platformInfo[ins.platform2]) {
          platformInfo[ins.platform2] = {
            totalUsd: 0,
            netValue: 0
          }
        }
        if (ins.platform1 !== '-') {
          platformInfo[ins.platform1]['totalUsd'] += Number(ins.position);
          platformInfo[ins.platform1]['netValue'] += ((ins.side1 === 'LONG' ? 1 : -1) * Number(ins.position));
        }
        if (ins.platform2 !== '-') {
          platformInfo[ins.platform2]['totalUsd'] += Number(ins.position);
          platformInfo[ins.platform2]['netValue'] += ((ins.side2 === 'LONG' ? 1 : -1) * Number(ins.position));
        }
        let fundingFee7 = this.getFundingFee(ins, SymbolFundingFeeService.FUNDING_DAYS.WEEK);
        let fundingFee15 = this.getFundingFee(ins, SymbolFundingFeeService.FUNDING_DAYS.TWO_WEEK);
        let fundingFee30 = this.getFundingFee(ins, SymbolFundingFeeService.FUNDING_DAYS.MONTH);
        let profit7 = fundingFee7 * Number(ins.position) / 100;
        let profit15 = fundingFee15 * Number(ins.position) / 100;
        let profit30 = fundingFee30 * Number(ins.position) / 100;
        if (ins.platform1 !== ins.platform2) {
          profit7 = profit7 / 2;
          profit15 = profit15 / 2;
          profit30 = profit30 / 2;
        }
        profit.WEEK += profit7;
        profit.TWO_WEEKS += profit15;
        profit.MONTH += profit30;
        profit.COST += this.getSymbolGap(ins) * Number(ins.position) / 100;
      });
      profit.WEEK = ((profit.WEEK / totalUsd) * 100).toFixed(3);
      profit.TWO_WEEKS = ((profit.TWO_WEEKS / totalUsd) * 100).toFixed(3);
      profit.MONTH = ((profit.MONTH / totalUsd) * 100).toFixed(3);
      profit.COST = ((profit.COST / totalUsd) * 100).toFixed(3);
      this.report.totalUsd = totalUsd;
      this.report.profit = profit;
      this.report.platformInfo = platformInfo;
      this.positionExport = exportResult;
    }
  }
}
</script>

<style scoped>

.el-collapse {
  color: #FFFFFF;
  border-top: 1px solid #444c56;
  border-bottom: 1px solid #444c56;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: white;
}

.el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 2px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(119, 109, 109, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(119, 109, 109, 0.4);
}

</style>