<template>
    <div id="crossPairChart" ref="crossPairChart" :color="color" :width="width" style="height: 100%;width: 100%"></div>
</template>


<script>

export default {
  name: "crossPairChart",
  components: {},
  props: {
    id: String,
    color: String,
    width: String
  },
  data() {
    return {
      init: false,
      timeList: [],
      price: [],
      data: []
    }
  },
  methods: {
    getProfitOptionOnlyProfit(xTimeList, profitList) {
      let series = [{
        showSymbol: true,
        name: '收益',
        type: 'line',
        data: profitList,
        smooth: true,
        lineStyle: {
          color: this.color,
          width: Number(this.width),   //这里是坐标轴的宽度,可以去掉
        }
      }];
      return {
        tooltip: {
          trigger: 'axis'
        },
        color:[this.color],
        legend: {
          orient: 'vertical',
          data: ['收益'],
          textStyle:{
            color:'white',
            fontSize:10
          }
        },
        calculable: true,
        xAxis: [{
          type: 'category',
          boundaryGap: true,
          show: true,
          data: xTimeList,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: 8, //更改坐标轴文字大小
              color: 'white'
            }
          },
        }],
        grid: {
          x: 50,
          y: 35,
          x2: 18,
          y2: 25
        },
        yAxis: [{
          type: 'value',
          show: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: 'white', //更改坐标轴文字颜色
              fontSize: 8 //更改坐标轴文字大小
            }
          },
        }],
        series: series
      };
    },
    timestampToDate(timestamp) {
      let date = new Date(Number(timestamp));
      let Y = date.getFullYear() + '-';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let D = date.getDate() + ' ';
      let h = date.getHours() + ':';
      let m = date.getMinutes() + ':';
      let s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    render: function (data) {
      this.data = data;
      let that = this;
      let xTimeList = [];
      let volList = [];
      // console.log('chart' + data);
      // that.data.forEach((obj) => {
      //   xTimeList.push((obj.dateString));
      //   volList.push(obj.totalVal.toFixed(2));
      // });
      this.data['indexList'].forEach((obj) => {
        xTimeList.push(this.timestampToDate(obj));
      });
      // xTimeList = this.data['indexList'];
      volList = this.data['profitList'];
      if (that.$refs.crossPairChart) {
        that.crossPairChart = that.$echarts.init(that.$refs.crossPairChart);
        that.crossPairChart.clear();
        that.crossPairChart.setOption(that.getProfitOptionOnlyProfit(xTimeList, volList));
        window.addEventListener('resize', () => (that.crossPairChart.resize()));
      }
    }
  }

}


</script>