<script type="text/javascript">

import ServiceMapper from "@/const/ServiceMapper";
import AbmNet from "@/api/net/AbmNet";

function queryAllStrategyName(success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.strategyInstanceQueryAllStrategyName, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function queryModel(insId, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.strategyInstanceQueryModel + '?insId=' + insId, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function create(req, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.strategyInstanceCreate, req, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function query(insId, strategyName, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.strategyInstanceQuery + '?insId=' + insId + '&strategyName=' + strategyName, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function querySingle(sid, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.strategyInstanceQuerySingle + '?sid=' + sid, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function reset(sid, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.strategyInstanceReset + '?sid=' + sid, {}, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function update(dto, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.strategyInstanceUpdate, dto, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function deleteIns(sid, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.strategyInstanceDelete + '?sid=' + sid, {}, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

export default {
  //查询所有的策略名称
  queryAllStrategyName,
  //查询所有策略模型
  queryModel,
  create,
  query,
  querySingle,
  reset,
  update,
  deleteIns
}


</script>