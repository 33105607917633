<template>
    <div class="ft-paging not-select">
        <div class="ft-paging-item" @click="actionTargetIndex(0)">
            <div class="ft-paging-item-inner">首页</div>
        </div>
        <div class="ft-paging-item" @click="actionTargetIndex(pageIndex - 1)">
            <div class="ft-paging-item-inner">上一页</div>
        </div>
        <template v-if="pageTotal == 0">
            <div class="ft-paging-item active" @click="actionTargetIndex(pageIndex)">
                <div class="ft-paging-item-inner">
                {{pageIndex + 1}}
                </div>
            </div>
        </template>
        <template v-else v-for="n in list">
            <div v-bind:key="n.index" v-if="n.index <= lastPageIndex" class="ft-paging-item" :class="[n.index == pageIndex ? 'active' : '']" 
                @click="actionPageIndex(n)">
                <div class="ft-paging-item-inner">
                {{n.text + 1}}
                </div>
            </div>
        </template>
        <div class="ft-paging-item" @click="actionTargetIndex(pageIndex + 1)">
            <div class="ft-paging-item-inner">下一页</div>
        </div>
        <div class="ft-paging-item" @click="actionTargetIndex(lastPageIndex)">
            <div class="ft-paging-item-inner">尾页</div>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        
    },
    props: {
        pageIndex: {
            type: Number,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: 0
        },
        pageSize: {
            type: Number,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: 10
        },
        pageTotal: {
            type: Number,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: 0
        }
    },
    data() {
        return {
            itemActive: 2,
        }
    },
    computed: {
        list() {
            var pagingItems = []

            // 最后一页
            var lastIndex = Math.ceil(this.pageTotal / this.pageSize) - 1

            // 开始页
            var startIndex = this.pageIndex - 4
            if (startIndex < 0) {
                startIndex = 0
            }

            for (var i = startIndex ; i <= lastIndex ; i ++) {
                pagingItems.push({index: i, text: i})
                if (pagingItems.length >= 9) {
                    break
                }
            }

            if (pagingItems.length < 9 && startIndex > 0) {
                do {
                    startIndex --
                    pagingItems.splice(0, 0, {index: startIndex, text: startIndex})
                } while (pagingItems.length < 9 && startIndex > 0)
            }
            
            return pagingItems
        },
        lastPageIndex() {
            var index = Math.ceil(this.pageTotal / this.pageSize) - 1
            return index
        }
    },
    methods: {
        actionPageIndex(item) {
            this.$emit('index-change', item.index);
        },
        actionTargetIndex(index) {
            if (index < 0) {
                return
            }
            if (index > this.lastPageIndex) {
                return
            }
            this.$emit('index-change', index);
        }
    }
}
</script>

<style scoped>
.ft-paging{

}
.ft-paging .ft-paging-item{
    display: inline-block; margin: 8px; min-width: 24px; height: 24px; border-radius: 4px; border: 1px solid #3E454E;
    cursor: pointer;
}
.ft-paging .ft-paging-item .ft-paging-item-inner {
    min-width: 18px; height: 18px; line-height: 18px; font-size: 16px; background: transparent; color: #999; border-radius: 2px; margin: 2px;
}
.ft-paging .ft-paging-item.active{
    display: inline-block; margin: 8px; min-width: 24px; height: 24px; border-radius: 4px; border: 1px solid #3E454E;
}
.ft-paging .ft-paging-item.active .ft-paging-item-inner {
    min-width: 18px; height: 18px; line-height: 18px; font-size: 16px; background: #909399; color: #fff; border-radius: 2px; margin: 2px;
}
.ft-paging .ft-paging-item:hover{
    display: inline-block; margin: 8px; min-width: 24px; height: 24px; border-radius: 4px; border: 1px solid #3E454E;
}
.ft-paging .ft-paging-item:hover .ft-paging-item-inner {
    min-width: 18px; height: 18px; line-height: 18px; font-size: 16px; background: #909399; color: #fff; border-radius: 2px; margin: 2px;
}
.not-select{
    -moz-user-select:none; /*火狐*/
    -webkit-user-select:none; /*webkit浏览器*/
    -ms-user-select:none; /*IE10*/
    -khtml-user-select:none; /*早期浏览器*/
    user-select:none;
}

</style>