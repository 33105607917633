<template>
  <div>
    <el-drawer
        size="68%"
        :close-on-click-modal=false
        :close-on-press-escape=false
        :wrapperClosable=false
        custom-class="black-card"
        :visible.sync="drawer"
        direction="ltr"
        :before-close="close"
        style="overflow-y: auto">
      <h4 class="dark-mode-font" style="font-size: 20px;color: white"><b>Oneport ChatGPT</b></h4>
      <el-card id="cBody" class="black-card" style="margin-left: 0; margin-right: 0;margin-top: 15px;height: 86%;background-color: #1c2128;border-radius: 0;border-left: 0;border-right: 0;overflow-y: auto" body-style="padding: 0">
        <el-card class="black-card" :style="'border-left: 0;border-right: 0;border-top: 0;border-radius: 0;' + (c.isUser ? 'background-color: rgba(0, 0, 0, 0)' : '')" v-for="(c, index) in conversations" :key="index" shadow="none">
          <el-row>
            <el-col :span="12" v-if="c.isUser">
              <el-avatar :size="30" :src="basicAvatarUrl + userObj.userInfo.id + '-avatar.png'"
                         style=";background-color: rgba(0, 0, 0, 0);float: left"
                         @error="true" :key="basicAvatarUrl + userObj.userInfo.id + '-avatar.png'"
                         fit="fill">
                <el-avatar icon="el-icon-s-custom" :size="30"
                           style="color: cornflowerblue;font-size: 13px;background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);float:left;"></el-avatar>
              </el-avatar>
              <span class="dark-mode-font" style="color: white;display: inline-block;float: left;margin-left: 10px;margin-top: 4px">{{ userObj.userInfo.alias }}</span>
            </el-col>
            <el-col :span="12" v-if="!c.isUser">
              <el-avatar :size="30" :src="gptAvatar"
                         style=";background-color: rgba(0, 0, 0, 0);float: left"
                         @error="true" :key="gptAvatar"
                         fit="fill">
                <el-avatar icon="el-icon-s-custom" :size="30"
                           style="color: cornflowerblue;font-size: 13px;background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);float:left;"></el-avatar>
              </el-avatar>
              <span class="dark-mode-font" style="color: white;display: inline-block;float: left;margin-left: 10px;margin-top: 4px">Oneport ChatGPT</span>
            </el-col>
            <el-col :span="12">
              <span class="dark-mode-font" style="color: gray;display: inline-block;float: right;margin-right: 10px;margin-top: 4px;font-size: 12px">{{ c.time }}</span>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px;padding-left: 42px" v-if="c.isUser || c.hasError">
            <pre style=";text-align: left;font-size: 12px;float: left;display: inline-block;margin-top: 3px;margin-bottom: 10px;color: #b0b9bf;">{{c.content}}</pre>
          </el-row>
          <el-row style="margin-top: 6px;padding-left: 42px" v-if="!c.isUser && !c.hasError">
            <pre style="text-align: left;font-size: 12px;float: left;display: inline-block;margin-top: 0;margin-bottom: 10px;color: #b0b9bf;">{{c.content}}</pre>
          </el-row>
        </el-card>
      </el-card>
      <el-row style=";margin-top: 30px;">
        <textarea class="trialpha-input" style="border-radius: 5px;width: 80%;height: 46px;padding-right: 68px;padding-top: 6px;padding-left: 8px" v-model="inputMsg" @keyup.enter="send" :disabled="pendingResponse"/>
        <el-button class="dark-mode-btn" style="margin-left: -68px;width: 68px;height: 43px;border-radius: 5px;padding: 0;background-color: rgba(0, 0, 0, 0);border: 0;margin-top: 3px;position:absolute;">
          <i v-if="!pendingResponse" class="el-icon-s-promotion" style="font-size: 20px;padding: 0" @click="send"></i>
          <i v-if="pendingResponse" class="el-icon-loading" style="font-size: 20px;padding: 0"></i>
        </el-button>
        <el-button class="dark-mode-btn" style="margin-left: 0;width: 68px;height: 43px;border-radius: 5px;padding: 0;background-color: rgba(0, 0, 0, 0);border: 0;margin-top: 3px;position:absolute;">
          <i v-if="!pendingResponse" class="el-icon-refresh" style="font-size: 20px;padding: 0" @click="reset"></i>
          <i v-if="pendingResponse" class="el-icon-loading" style="font-size: 20px;padding: 0"></i>
        </el-button>
      </el-row>
      <el-row style="margin-top: 15px">
        <span class="dark-mode-font" style="font-size: 12px">All content is from ChatGPT, not related to Oneport, Oneport does not assume any responsibility for it.</span>
      </el-row>
    </el-drawer>
  </div>
</template>

<script>

import UserService from "@/api/user/UserService";
import ChainApiService from "@/web3/chain-api/ChainApiService";
import {Message} from "element-ui";
import ConfirmProvider from "@/components/ConfirmProvider";

export default {
  name: "FtGPT",
  data: function () {
    return {
      adding: false,
      drawer: false,
      userObj: UserService.getUserObj(),
      basicAvatarUrl: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/web/avatar/',
      avatar: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/web/avatar/' + UserService.getUserObj().userInfo.id + '-avatar.png',
      gptAvatar: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/web/avatar/chatgpt-icon.webp',
      conversations: [],
      inputMsg: '',
      pendingResponse: false
    }
  },
  methods: {
    openDrawer: function () {
      this.userObj = UserService.getUserObj();
      this.drawer = true;
    },
    send: function () {
      if (this.pendingResponse){
        return;
      }
      if (this.inputMsg) {
        if (this.inputMsg.endsWith('\n')){
          this.inputMsg = this.inputMsg.substring(0, this.inputMsg.length - 1);
        }
        this.conversations.push({
          isUser: true,
          time: new Date(),
          content: this.inputMsg,
          hasError: false
        });
        let that = this;
        that.$nextTick(() => {
          let msg = document.getElementById('cBody') // 获取对象
          msg.scrollTop = msg.scrollHeight // 滚动高度
        });
        that.pendingResponse = true;
        let sendMsgList = [];
        this.conversations.forEach((msg) => {
          sendMsgList.push({
            role: msg.isUser ? 'user' : 'assistant',
            content: msg.content.replace(/\n/g,"\\n").replace(/\r/g,"\\r").replaceAll('"', '\\"')
          })
        })
        ChainApiService.talkGPT(sendMsgList, function (res) {
          if (res.content.startsWith('\n')){
            res.content = res.content.substr(1);
          }
          that.conversations.push({
            isUser: false,
            time: new Date(),
            content: res.content,
            hasError: res.content === 'Get error message.'
          });
          that.$nextTick(() => {
            let msg = document.getElementById('cBody') // 获取对象
            msg.scrollTop = msg.scrollHeight // 滚动高度
          });
          that.pendingResponse = false;
          that.inputMsg = '';
        }, function () {
          Message.error('Request error');
          that.pendingResponse = false;
        });
      }
    },
    reset: function () {
      let that = this;
      ConfirmProvider.openAlert(that, '重置聊天内容', '当您确认后，会清空当前聊天记录，新的 BOT 将不会有当前聊天内容的任何记忆。', function () {
        that.conversations = [];
      });
    },
    close: function () {
      this.conversations = [];
      this.pendingResponse = false;
      this.inputMsg = '';
      this.drawer = false;
    }
  }
}
</script>

<style>

.createAccount .el-input__inner {
  color: white;
  height: 36px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-radius: 3px;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 2px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(119, 109, 109, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(119, 109, 109, 0.4);
}

</style>