<template>
  <div>
    <el-drawer
        size="80%"
        append-to-body
        :with-header="false"
        :close-on-click-modal=true
        :close-on-press-escape=true
        :wrapperClosable=true
        custom-class="black-card"
        :visible.sync="drawer"
        direction="btt">
        <div style="height: 100%;">
          <!-- <TVChartContainer v-if="drawer" ref="tvWediget" v-on:chart-subscribe="onChartSubscribe" /> -->
          <TVChartContainer v-if="drawer" :hide-header-search="false" />
        </div>
    </el-drawer>
  </div>
</template>

<script>
import TVChartContainer from '@/components/TVChartContainer'

export default {
  name: "DataPanel",
  components: {TVChartContainer},
  data: function () {
    return {
      drawer: false
    }
  },
  mounted() {
  },
  methods: {
    render: function () {
      this.drawer = true;
    },
    // onChartSubscribe(type, args) {
    //   // console.log('====图表事件', type, args)
    //   if (type == 'chart_ready') {
    //     // 图表准备完成, 等待十秒钟后更换商品
    //     // 图表还没准备完成直接调用setSymbol会报错
    //     // 更换商品时需要指定 binsize大小, 支持的单位: "1", "5", "15", "60", "240", "1D"
    //     // 上面的单位是目前控件里面定义好的, 也是图表上可以选择的
    //     // setTimeout(() => {
    //     //   this.$refs.tvWediget.setSymbol('BTCUSDT_FUTURE', '1', () => {
    //     //     console.log('数据到达', JSON.parse(JSON.stringify(arguments)))
    //     //   })
    //     // }, 5000)
    //   }
    // }
  }
}
</script>

<style scoped>

</style>