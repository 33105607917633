<template>
    <div style="border: 1px solid #3E454E; border-radius: 8px; padding: 30px; background: #22272E;"
        :class="[(scrollHeight > 0 ? 'scroll-mode' : '')]" :style="(scrollHeight > 0 ? {height: scrollHeight + 'px'} : {})">
        
        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
            <el-col :span="6" style="text-align: left;">
                <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 5px;">渠道名</label>
            </el-col>
            <el-col :span="18" style="text-align: left;">
                <input type="text" v-model="form.name" placeholder="请输入渠道名称" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 12px"/>
            </el-col>
        </el-row>
        
        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
            <el-col :span="6" style="text-align: left;">
                <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 5px;">渠道背景</label>
            </el-col>
            <el-col :span="18" style="text-align: left;">
                <input type="text" v-model="form.info" placeholder="请输入渠道背景" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 12px"/>
            </el-col>
        </el-row>
        
        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
            <el-col :span="6" style="text-align: left;">
                <label style="color: #999999; font-size: 12px; line-height: 30px; padding-right: 5px;">渠道关键人</label>
            </el-col>
            <el-col :span="18" style="text-align: left;">
                <!-- <select style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;">
                    <option>日不落秦</option>
                    <option>2</option>
                </select> -->
                <input type="text" v-model="form.contact" placeholder="请输入渠道关键人" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 12px"/>
            </el-col>
        </el-row>
        
        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
            <el-col :span="6" style="text-align: left;">
                <label style="color: #999999; font-size: 12px; line-height: 30px; padding-right: 5px;">渠道意愿</label>
            </el-col>
            <el-col :span="18" style="text-align: left;">
                <select v-model="form.desire" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 12px">
                    <option value="高">高</option>
                    <option value="中">中</option>
                    <option value="低">低</option>
                </select>
            </el-col>
        </el-row>
        
        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
            <el-col :span="6" style="text-align: left;">
                <label style="color: #999999; font-size: 12px; line-height: 30px; padding-right: 5px;">渠道归属</label>
            </el-col>
            <el-col :span="6" style="text-align: left;">
                <input type="text" v-model="form.belong" placeholder="请输入渠道归属" readonly style="font-size: 12px;width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
            </el-col>
            <el-col :span="12" style="text-align: left;">
                <!-- <input type="text" placeholder="请输入备注信息" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/> -->
                <div style="display: flex;">
                    <div style="flex: 1;"></div>
                    <FtGroupButtonWidget :buttons="[{
                        id: 'update',
                        text: '保存',
                        textI18n: '',
                    }]" mode="single" v-on:switch-change="eventSwitchChange" style="margin-left: 16px; background: #3E454E;" />
                    <FtGroupButtonWidget :buttons="[{
                        id: 'customer',
                        text: '新增客户',
                        textI18n: '',
                    }]" mode="single" v-on:switch-change="eventSwitchChange" style="margin-left: 16px; background: #3E454E;" />
                </div>
            </el-col>
        </el-row>

        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; display: none;">
            <el-col :span="24" style="text-align: left;">
                <FtGroupButtonWidget :buttons.sync="detailSwitchButtons" v-on:switch-change="eventSwitchChange" />
            </el-col>
        </el-row>
        
        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
            <el-col :span="24" style="text-align: left;">
                <FtTableListWidget 
                    :columns="columns" 
                    :data="data"
                    :scrollHeight="scrollHeightList"
                    v-on:checked-remove="onCheckedRemove" />
            </el-col>
        </el-row>
        
        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
            <el-col :span="24" style="text-align: left;">
                <!-- <input type="text" placeholder="请输入备注信息" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/> -->
                <div style="display: flex;">
                    <div style="flex: 1;">
                        <FtInputBoxWidget v-model="inputRemark" placeholder="请输入备注信息" style="margin-left: 0;" />
                    </div>
                    <FtGroupButtonWidget :buttons="[{
                        id: 'append',
                        text: '输入',
                        textI18n: '',
                    }]" mode="single" v-on:switch-change="eventSwitchChange" style="margin-left: 16px; background: #3E454E;" />
                </div>
            </el-col>
        </el-row>

        <FtCrmCreateCustomer2Dialog v-if="dialogAppendShow" :show.sync="dialogAppendShow" :channelId="form.channelId"
            v-on:success="createCustomerSuccess" />

    </div>
</template>

<script>
import {Message} from "element-ui";
import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";
import FtTableListWidget from "@/components/widgets/FtTableListWidget";
import FtInputBoxWidget from "@/components/widgets/FtInputBoxWidget";
import FtCrmCreateCustomer2Dialog from "@/components/FtCrmCreateCustomer2Dialog";

import CrmChannelManagement from "@/api/crm/CrmChannelManagement";

export default {
    components: {
        FtGroupButtonWidget,
        FtTableListWidget,
        FtInputBoxWidget,
        FtCrmCreateCustomer2Dialog
    },
    props: {
        
    },
    data() {
        return {
            // 滚动面板高度
            scrollHeight: 0,
            scrollHeightList: 0,

            dialogAppendShow: false,

            // 详情面板
            detailSwitchButtons: [
                {
                    id: 'remark',
                    text: '备注',
                    textI18n: '',
                    active: true
                }
            ],

            currentPanel: 'remark',

            // 表单数据
            form: {
                channelId: 0,
                active: true,
                belong: '',
                channelRemarkEntityDtos: [],
                contact: '',
                customerNumber: 0,
                desire: '',
                fundsAmount: 0,
                info: '',
                name: '',
            },

            // 列表信息
            columns: [
                // {textI18n: '', text: '#', align: 'left', width: '5%', name: 'id', checkbox: true},
                {textI18n: '', text: '编号', align: 'left', width: '10%', name: 'id'},
                {textI18n: '', text: '备注', align: 'left', width: '65%', name: 'remark'},
                {textI18n: '', text: '时间', align: 'left', width: '20%', name: 'createTime'},
                {textI18n: '', text: '', icon: 'el-icon-more', align: 'right', width: '5%'},
            ],
            data: [
                // {
                //     id: 1,
                //     text1: '100,000,000  USDT',
                //     text2: '客户后续还有100w，谨慎对待',
                //     text3: '2022.09.30',
                // },
                // {
                //     id: 2,
                //     text1: '100,000,000  USDT',
                //     text2: '客户后续还有100w，谨慎对待',
                //     text3: '2022.09.30',
                // },
                // {
                //     id: 3,
                //     text1: '100,000,000  USDT',
                //     text2: '客户后续还有100w，谨慎对待',
                //     text3: '2022.09.30',
                // }
            ],

            // 金额
            inputMoney: '',
            // 备注信息
            inputRemark: ''
            
        }
    },
    computed: {
        
    },
    mounted() {

        var windowHeight = window.innerHeight
        this.scrollHeight = windowHeight - 180 - 65
        this.scrollHeightList = this.scrollHeight - 380 - 24
        
    },
    methods: {

        setDetail(value) {
            if (this.form.channelId == value.id) {
                return
            }
            console.log(value)
            this.form.channelId = value.id
            this.form.active = value.active
            this.form.belong = value.belong
            this.form.channelRemarkEntityDtos = value.channelRemarkEntityDtos
            this.form.contact = value.contact
            this.form.customerNumber = value.customerNumber
            this.form.desire = value.desire
            this.form.fundsAmount = value.fundsAmount
            this.form.info = value.info
            this.form.name = value.name
            // 加载备注
            this.data = []
            this.qureyChannelRemark()
        },

        qureyChannelRemark() {
            return new Promise((resolve, reject) => {
                CrmChannelManagement.qureyChannelRemark(this.form.channelId,
                    (data) => {
                        let items = data
                        items.forEach(m => {
                            var d = new Date(m.createTime * 1000)
                            m.createTime = d.getFullYear() + '-' + 
                                ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + '-' + 
                                (d.getDate() < 10 ? '0' + d.getDate() : d.getDate())
                        })
                        this.columns = [
                            // {textI18n: '', text: '#', align: 'left', width: '5%', name: 'id', checkbox: true},
                            {textI18n: '', text: '编号', align: 'left', width: '10%', name: '_index'},
                            {textI18n: '', text: '备注', align: 'left', width: '65%', name: 'remark'},
                            {textI18n: '', text: '时间', align: 'left', width: '20%', name: 'createTime'},
                            {textI18n: '', text: '', icon: 'el-icon-more', align: 'right', name: 'option', width: '5%', remove: true},
                        ]
                        items.forEach((item, index) => {
                            item._index = index + 1
                        })
                        this.data = items
                        resolve(items)
                    },
                    () => {
                        // Message.error(this.$i("operationFail"));
                        reject()
                    }
                );
            })
        },

        // 各种切换处理
        eventSwitchChange(item) {
            console.log('switch change event', item.id)
            if (item.id == 'update') {
                // 更新渠道信息
                if (CrmChannelManagement.countStringLength(this.form.name) > 64) {
                    Message.warning('渠道名超长，最多64字符，汉字算两个字符');
                    return
                }
                if (CrmChannelManagement.countStringLength(this.form.info) > 64) {
                    Message.warning('渠道背景超长，最多64字符，汉字算两个字符');
                    return
                }
                if (CrmChannelManagement.countStringLength(this.form.info) > 64) {
                    Message.warning('渠道关键人超长，最多64字符，汉字算两个字符');
                    return
                }
                this.updateChannel()
            }
            if (item.id == 'append') {
                if (this.currentPanel == 'remark') {
                    // 新增备注
                    if (CrmChannelManagement.countStringLength(this.inputRemark) > 200) {
                        Message.warning('备注超长，最多200字符，汉字算两个字符');
                        return
                    }
                    this.addChannelRemark()
                }
            }
            if (item.id == 'customer') {
                // 新增客户
                this.dialogAppendShow = true
            }
        },

        onCheckedRemove(item) {
            console.log('删除动作', item)
            CrmChannelManagement.deleteChannelRemark(item.id,
                () => {
                    Message.success('删除成功');
                    this.qureyChannelRemark()
                },
                () => {
                    // Message.error(this.$i("operationFail"));
                }
            );
        },

        // 更新渠道信息
        updateChannel() {
            return new Promise((resolve, reject) => {
                console.log(this.form)
                CrmChannelManagement.updateChannel(this.form,
                    (data) => {
                        Message.success('保存成功');
                        let items = data
                        this.$emit('changed', 'channel')
                        resolve(items)
                    },
                    () => {
                        // Message.error(this.$i("operationFail"));
                        reject()
                    }
                );
            })
        },
        
        // 新增备注
        addChannelRemark() {
            if (this.inputRemark == '') {
                Message.error('请输入备注');
                return
            }
            let params = {
                channelId: this.form.channelId,
                remark: this.inputRemark
            }
            return new Promise((resolve, reject) => {
                CrmChannelManagement.addChannelRemark(params,
                    () => {
                        Message.success('保存成功');
                        this.inputRemark = ''
                        this.qureyChannelRemark()
                        resolve()
                    },
                    () => {
                        // Message.error(this.$i("operationFail"));
                        reject()
                    }
                );
            })
        },

        createCustomerSuccess(type) {
            this.$emit('changed', type)
        }
        
    }
}
</script>

<style scoped>
.scroll-mode{
    overflow-y: auto;
}

input,select{
    outline: none;
}
</style>