<script>
import AbmNet from "@/api/net/AbmNet";
import ServiceMapper from "@/const/ServiceMapper";
import TracupUser from "@/api/tracup/TracupUser";

function queryProjectView(success, error) {
  const params = new URLSearchParams()
  let tracupUser = TracupUser.getTracupUser();
  params.append('uKey', tracupUser.userkey);
  params.append('_api_key', tracupUser.apikey);
  params.append('pKey', '66a491ebfdf5244e900a645fec317ce1');
  AbmNet.postRequestToTraCup(ServiceMapper.TRACUP_SERVICE.projectView, params, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function queryIssueDetails(issueNo, success, error) {
  const params = new URLSearchParams()
  let tracupUser = TracupUser.getTracupUser();
  params.append('uKey', tracupUser.userkey);
  params.append('_api_key', tracupUser.apikey);
  params.append('pKey', '66a491ebfdf5244e900a645fec317ce1');
  params.append('iNo', issueNo);
  AbmNet.postRequestToTraCup(ServiceMapper.TRACUP_SERVICE.issueDetails, params, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function addNote(issueNo, content, success, error) {
  const params = new URLSearchParams()
  let tracupUser = TracupUser.getTracupUser();
  params.append('uKey', tracupUser.userkey);
  params.append('_api_key', tracupUser.apikey);
  params.append('pKey', '66a491ebfdf5244e900a645fec317ce1');
  params.append('iNo', issueNo);
  params.append('content', content);
  AbmNet.postRequestToTraCup(ServiceMapper.TRACUP_SERVICE.addNote, params, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function updateIssue(req, success, error) {
  const params = new URLSearchParams()
  let tracupUser = TracupUser.getTracupUser();
  params.append('uKey', tracupUser.userkey);
  params.append('_api_key', tracupUser.apikey);
  params.append('pKey', '66a491ebfdf5244e900a645fec317ce1');
  for (const reqKey in req) {
    params.append(reqKey, req[reqKey]);
  }
  AbmNet.postRequestToTraCup(ServiceMapper.TRACUP_SERVICE.updateIssue, params, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function createIssue(req, success, error) {
  const params = new URLSearchParams()
  let tracupUser = TracupUser.getTracupUser();
  params.append('uKey', tracupUser.userkey);
  params.append('_api_key', tracupUser.apikey);
  params.append('pKey', '66a491ebfdf5244e900a645fec317ce1');
  for (const reqKey in req) {
    params.append(reqKey, req[reqKey]);
  }
  AbmNet.postRequestToTraCup(ServiceMapper.TRACUP_SERVICE.createIssue, params, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function queryProjectAllIssue(success, error) {
  queryAllIssueInner(1, success, error, undefined);
}

function queryAllIssueInner(page, success, error, res) {
  const params = new URLSearchParams()
  let tracupUser = TracupUser.getTracupUser();
  params.append('uKey', tracupUser.userkey);
  params.append('_api_key', tracupUser.apikey);
  params.append('pKey', '66a491ebfdf5244e900a645fec317ce1');
  params.append('page', page);
  params.append('perpage', '50');
  AbmNet.postRequestToTraCup(ServiceMapper.TRACUP_SERVICE.issueList, params, function (data) {
    if (data.data.page >= data.data.pageCount) {
      success(res);
    } else {
      if (res === undefined) {
        res = data;
      } else {
        res.data.list.concat(data.data.list);
      }
      queryAllIssueInner(page + 1, success, error, res);
    }
  }, function (err) {
    error(err);
  });
}

export default {
  addNote,
  queryProjectView,
  queryProjectAllIssue,
  updateIssue,
  queryIssueDetails,
  createIssue
}
</script>
