<template>
  <div>
    <el-card class="black-card">
      <el-row>
        <el-col :span="6">
          <el-row>
            <el-card class="trade-black-card" style="height: 116px" shadow="none">
              <el-row>
              <span class="dark-mode-font" style="color: white;display: inline-block;float: left;text-align: left">
                Total Value Locked (USD)
              </span>
              </el-row>
              <el-row style="margin-top: 10px">
              <span class="dark-mode-font"
                    style="color: #0ecb81;display: inline-block;float: left;font-size: 28px;text-align: left">
                ${{ (totalTVL / 1000000).toFixed(2) }}M
              </span>
              </el-row>
            </el-card>
          </el-row>
          <el-row style="margin-top: 20px;">
            <el-card class="trade-black-card" style="height: 116px" shadow="none">
              <el-row>
              <span class="dark-mode-font" style="color: white;display: inline-block;float: left;text-align: left">
                Change (24H / 1M)
              </span>
              </el-row>
              <el-row style="margin-top: 15px">
              <span class="dark-mode-font"
                    style="color: #bababa;display: inline-block;float: left;font-size: 19px;text-align: left">
                <span :style="'color:' + (changeData.h24 > 0 ? '#0ecb81' : '#f6465d')">{{ changeData.h24 }}%</span> / <span :style="'color:' + (changeData.m1 > 0 ? '#0ecb81' : '#f6465d')">{{ changeData.m1 }}%</span>
              </span>
              </el-row>
            </el-card>
          </el-row>
        </el-col>
        <el-col :span="18" style="padding-left: 20px">
          <el-card class="trade-black-card" body-style="" shadow="none">
            <el-row style="position: absolute;text-align: left;margin-top: -15px;margin-left: -10px">
              <span style="font-size: 16px;color: white;">Total Value Locked Chart (Billion)</span>
            </el-row>
            <el-row style="position: absolute;text-align: left;margin-top: 63px;margin-left: 26%">
              <span style="font-size: 35px;color: #2d2d2e;">Oneport</span>
            </el-row>
            <tvl-chart id="tvl_chart" ref="tvl_chart" :color="'#ffeb3b'" width="2" style="height: 210px;margin-top: 0"></tvl-chart>
          </el-card>
        </el-col>
      </el-row>
      <el-divider class="data-divider"><i class="el-icon-s-data" style="color: gray"/></el-divider>
      <el-row>
        <div style="margin: auto">
          <el-card class="black-card" style="border-radius: 3px 3px 0 0;border-bottom: 0;background-color: #1c2128" body-style="padding: 10px" shadow="none">
            <el-row>
              <el-col :span="8">
                <span class="dark-mode-font" style="color: white;display: inline-block;float: left">Yields
                <span class="dark-mode-font" style="display: inline-block;margin-left: 5px;float: right;font-size: 12px;margin-top: 3px;color: #acacac"> (ALL CHAINS)</span>
                </span>
              </el-col>
              <el-col :span="16">
                <input class="dark-mode-input" v-model="filterCoinName" placeholder="Symbol . . ." style="margin-top: -3px;color: white;height: 25px;outline: none;font-size: 12px;background-color: rgba(0, 0, 0, 0);border: 0;width: 128px;float: right;border-bottom: 1px solid #444c56;padding-left: 18px;padding-bottom: 1px" />
                <i class="el-icon-search" style="float: right;color: #444c56;margin-top: 2px;margin-right: -15px"></i>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="black-card" style="border-radius: 0;height: 28px;background-color: #1c2128" shadow="none" body-style="padding: 4px 10px 3px 10px">
            <el-row>
              <el-col :span="7">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:#acacac ">Symbol</span>
              </el-col>
              <el-col :span="4">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:#acacac ">Chain</span>
              </el-col>
              <el-col :span="4">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:#acacac ">Project</span>
              </el-col>
              <el-col :span="2">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:#acacac ">Type</span>
              </el-col>
              <el-col :span="3">
                <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:#acacac;cursor: pointer" @click="sortList('apy')">APY<i class="el-icon-d-caret"/></span>
              </el-col>
              <el-col :span="4">
                <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:#acacac;cursor: pointer" @click="sortList('tvlUsd')">TVL<i class="el-icon-d-caret"/></span>
              </el-col>
            </el-row>
          </el-card>
          <div style="max-height: 60vh;overflow-y: auto">
            <el-skeleton animated :loading="rendering" :throttle="0">
              <template slot="template">
                <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left;margin-left:0'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left;margin-left:0'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left;margin-left:0'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left;margin-left:0'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left;margin-left:0'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left;margin-left:0'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left;margin-left:0'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left;margin-left:0'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left;margin-left:0'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left;margin-left:0'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left;margin-left:0'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left;margin-left:0'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left;margin-left:0;margin-bottom: 20px'"></trialpha-sk>
              </template>
              <template>
                <el-card class="black-card-hover" shadow="none" style=";border: 0;border-radius: 0" body-style="padding:0" v-for="(p, index) in yieldsList.filter((info) => doCoinFilter(info.symbol))" :key="index">
                  <el-row style="height: 25px;border-bottom: 1px solid #2d2f31;border-left: 1px solid #444c56;border-right: 1px solid #444c56;padding: 3px 10px 3px 10px">
                    <el-col :span="7">
                      <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{ p.symbol }}</span>
                    </el-col>
                    <el-col :span="4">
                      <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white "><img style="margin-top: -2px;border-radius: 50%" :src="'https://icons.llamao.fi/icons/chains/rsz_' + p.chain.toLowerCase() + '?w=16&h=16'"  alt=""/></span>
                    </el-col>
                    <el-col :span="4">
                      <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{ p.project.toUpperCase() }}</span>
                    </el-col>
                    <el-col :span="2">
                      <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{ p.exposure.toUpperCase() }}</span>
                    </el-col>
                    <el-col :span="3">
                      <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{ p.apy.toFixed(2) }}%</span>
                    </el-col>
                    <el-col :span="4">
                      <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{ (p.tvlUsd / 1000000).toFixed(2) }}M</span>
                    </el-col>
                  </el-row>
                </el-card>
              </template>
            </el-skeleton>
          </div>
        </div>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import DefiliamaService from "@/api/web3/DefiliamaService";
import TvlChart from "@/pages/controlPanel/web3/TvlChart";
import TrialphaSk from "@/components/TrialphaSk";

export default {
  name: "DefiData",
  components: {TrialphaSk, TvlChart},
  data: function () {
    return {
      rendering: false,
      totalTVL: 0,
      displayChain: ['Ethereum', 'Binance', 'Optimism', 'Avalanche', 'Polygon', 'Arbitrum'],
      defiCharts: [],
      changeData: {
        h24: 0,
        d7: 0,
        m1: 0
      },
      yieldsList: [],
      filterCoinName: '',
      sortRule: {
        apy: 'Default',
        tvlUsd: 'Default',
        changeSort: function (sort) {
          if (sort === 'Default') {
            return  'HighToLow';
          } else if (sort === 'HighToLow') {
            return  'LowToHigh';
          } else if (sort === 'LowToHigh') {
            return  'HighToLow';
          }
        }
      },
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      let totalTVL = 0;
      let that = this;
      that.rendering = true;
      DefiliamaService.pools(function (res) {
        let poolTmp = [];
        res.data.forEach((info) => {
          if (info && info.tvlUsd > 3000000 && info.apy && info.apyBase && info.apy > 0 && info.apyBase > 0) {
            poolTmp.push(info);
          }
        });
        that.yieldsList = (poolTmp);
        that.rendering = false;
      });
      DefiliamaService.chains(function (res) {
        res.forEach((obj) => {
          totalTVL += obj.tvl;
        });
        that.totalTVL = totalTVL;
      });
      DefiliamaService.charts(function (res) {
        that.defiCharts = res;
        let change24H = (((res[res.length - 1].totalLiquidityUSD - res[res.length - 2].totalLiquidityUSD) / res[res.length - 2].totalLiquidityUSD) * 100).toFixed(2);
        let change7D = (((res[res.length - 1].totalLiquidityUSD - res[res.length - 8].totalLiquidityUSD) / res[res.length - 8].totalLiquidityUSD) * 100).toFixed(2);
        let change1M = (((res[res.length - 1].totalLiquidityUSD - res[res.length - 31].totalLiquidityUSD) / res[res.length - 31].totalLiquidityUSD) * 100).toFixed(2);
        that.changeData = {
          h24: change24H,
          d7: change7D,
          m1: change1M
        };
        that.$refs['tvl_chart'].render(res);
      });
    },
    sortList: function (sortKey) {
      this.sortRule[sortKey] = this.sortRule.changeSort(this.sortRule[sortKey]);
      this.yieldsList.sort((a, b) => {
        if (a[sortKey] < b[sortKey]) {
          if (this.sortRule[sortKey] === 'LowToHigh') {
            return -1;
          } else {
            return 1;
          }
        } else if (a[sortKey] > b[sortKey]) {
          if (this.sortRule[sortKey] === 'LowToHigh') {
            return 1;
          } else {
            return -1;
          }
        }
        return 0;
      });
    },
    doCoinFilter: function (coin) {
      if (!this.filterCoinName) {
        return (coin);
      }
      if (coin.toUpperCase().indexOf(this.filterCoinName.toUpperCase()) > -1) {
        return (coin);
      }
    }
  }
}
</script>

<style scoped>

.el-divider.data-divider {
  border-top: 1px solid #444c56;
}

.data-divider > .el-divider__text {
  background-color: #22272e;
}

</style>