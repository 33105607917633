<script>
import {Message} from "element-ui";

const ChainCfg = {
  1: {
    chainId: '0x1',
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://etherscan.io'],
  },
  3: {
    chainId: '0x3',
    chainName: 'Ropsten testNet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://ropsten.etherscan.io'],
  },
  5: {
    chainId: '0x5',
    chainName: 'Goerli Testnet',
    nativeCurrency: {
      name: 'GoerliETH',
      symbol: 'GoerliETH',
      decimals: 18,
    },
    rpcUrls: ['https://goerli.infura.io/v3/'],
    blockExplorerUrls: ['https://goerli.etherscan.io'],
  },
  42: {
    chainId: '0x2a',
    chainName: 'Kovan testNet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://kovan.etherscan.io'],
  },
  56: {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com/'],
  },
  97: {
    chainId: '0x61',
    chainName: 'Binance Smart Chain - TestNet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com/'],
  },
  1088: {
    chainId: '0x440',
    chainName: 'Maas - TestNet',
    nativeCurrency: {
      name: 'Maas',
      symbol: 'Maas',
      decimals: 18,
    },
    rpcUrls: ['https://maas-test-node.onchain.com/'],
    blockExplorerUrls: ['https://maas-test-explorer.onchain.com/'],
  },
  2088: {
    chainId: '0x828',
    chainName: 'Maas',
    nativeCurrency: {
      name: 'Maas',
      symbol: 'Maas',
      decimals: 18,
    },
    rpcUrls: ['https://maas-node.onchain.com/'],
    blockExplorerUrls: ['https://maas-explorer.onchain.com/'],
  },
  42161: {
    chainId: '0xa4b1',
    chainName: 'Arbitrum',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://arbiscan.io/'],
  }
};

// 添加链节点
async function changeNetwork(id) {
  let cfg = ChainCfg[id];
// console.log(cfg);
  if (!window.ethereum) {
    return false;
  }
  const request = (window.ethereum).request;
// 获取当前链id
  const chainId = await request({ method: "eth_chainId" });
  console.log(`chainId:${chainId}`);
  if (chainId !== cfg.chainId) {
    Message.warning('正在切换链为:' + cfg.chainName);
  }

  try {
// 切换
    await request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: cfg.chainId }],
    });
    return true;
  } catch (e) {
    const err = e;
    console.log(err);
    if (err.code === 4902) {
      try {
// 添加
        await request({
          method: "wallet_addEthereumChain",
          params: [cfg],
        });
      } catch (addError) {
        console.error(addError);
      }
    } else {
      Message.error(`ERROR:${err.message}`);
    }
    return true;
  }
}

export default {
  changeNetwork
}
</script>