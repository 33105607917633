<script>

let exchangeInfos = {
  BINANCE: {
    url: 'https://www.binance.com',
    icon: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/logo/Binance.png'
  },
  OKX: {
    url: 'https://www.okx.com',
    icon: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/logo/OKX.png'
  },
  DERIBIT: {
    url: 'https://deribit.com',
    icon: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/logo/Deribit.png'
  },
  FTX: {
    url: 'https://ftx.com',
    icon: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/logo/FTX.png'
  },
  BYBIT: {
    url: 'https://www.bybit.com',
    icon: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/logo/BYBIT-LOGO-SQUARE.png'
  },
  KUCOIN: {
    url: 'https://kucoin.com',
    icon: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/logo/kucoin.png'
  },
  BITMEX: {
    url: 'https://www.bitmex.com',
    icon: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/logo/BitMEX.png'
  },
  GATE: {
    url: 'https://www.gate.io',
    icon: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/logo/gate.io.png'
  },
  MEXC: {
    url: 'https://mexc.com',
    icon: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/logo/MEXC.png'
  },
  HUOBI: {
    url: 'https://www.huobi.com',
    icon: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/logo/huobi.png'
  },
  DYDX: {
    url: 'https://trade.dydx.exchange',
    icon: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/logo/dydx.png'
  },
  EVM_BASED_WALLET: {
    url: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/logo/MetaMask_Fox.png',
    icon: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/logo/MetaMask_Fox.png'
  }
}

export default {
  exchangeInfos
}
</script>
