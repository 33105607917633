<script lang="javascript">
import param from "@/paras/Paras";

const trans = {
  lang: 'English',
  copyright: 'Copyright © 2022 ' + param.getCompanyDisplayValue(),
  lang_china: '繁體中文',
  lang_english: 'English',
  lang_japan: '日本',
  lang_korea: '한국인',
  lang_thailand: 'ไทย',
  changePassword: 'Change password',
  manageAccount: 'Account Management',
  pleaseLoginFirst: 'Please login at first',
  operationSuccess: 'Operation Success',
  operationFail: 'Operation Fail',
  confirm: 'Confirm',
  action: "Action",
  loading: 'Loading...',
  customerPanel: {
    initInfo: 'Please do not send API information to anyone, including our staff!',
    subtitle: 'Customer confidential information collection system, this page is only valid for 30 minutes',
    delA1: 'Delete ',
    delA2: ' account',
    addA1: 'Add ',
    st: 'st',
    nd: 'nd',
    rd: 'rd',
    th: 'th',
    addA2: ' account',
    preCheck: 'API check',
    submit: 'Submit'
  },
  week: {
    Sun: 'Sun',
    Mon: 'Mon',
    Tue: 'Tue',
    Wed: 'Web',
    Thu: 'Thu',
    Fri: 'Fri',
    Sat: 'Sat'
  },
  login: {
    login: 'Log in',
    processing: 'Processing...',
    subTitle: 'Unified authentication management platform',
    logout: 'Logout',
    register: 'Register',
    email: 'Email',
    username: 'Username',
    password: 'Password',
    forgetPwd: 'Forget password',
    changePwd: 'Setting',
    start: 'Log in',
    dontHaveAccount: 'I don\'t have account',
    loginSuccess: 'Login success',
    logoutSuccess: 'Your already logout',
    loginTitle: 'Login Notification'
  },
  lock: {
    description: 'Web page is locked due to security policy.',
    unlock: 'Unlock',
    lock: 'Lock'
  },
  userInfo: {
    status: 'Status',
    role: 'Role',
    alias: 'Alias',
    userInfoSetting: 'User information settings',
    email: 'Email',
    username: 'Username',
    password: 'Password',
    newPassword: 'New Password',
    verification: 'Verification',
    send: 'SEND',
    update: 'UPDATE'
  },
  navBars: {
    navigation: 'Navigation',
    controlPanel: 'Control Panel',
    accountMgnt: 'Account Management',
    analysisMgnt: 'Analysis',
    riskMgnt: 'Risk Management',
    strategyMgnt: 'Strategy Management',
    tradeMgnt: 'Trade Management',
    testMgnt: '测试中心',
    web3Mgnt: 'WEB 3',
    userPermissionManagement: 'User Permission Management',
    traCupPanel: 'Tracup',
    channelMgnt: 'CRM',
    wallet: 'Wallet'
  },
  mobileNav: {
    controlPanel: 'Home',
    accountMgnt: 'Accounts',
    news: 'News',
    tradeMgnt: 'Trade',
    userConfig: 'Config',
    chatGPT: 'ChatGPT'
  },
  permission: {
    SUPER_ADMIN: 'Super Admin',
    ADMIN: 'Admin',
    USER: 'User',
    CONTROL_CENTER: 'Platform Admin',
    PLATFORM_ADMIN: 'Platform Admin',
    PLATFORM_USER: 'Platform User',
    DEMO_USER: 'Demo User',
    RECEPTIONIST: 'Marketing Department',
    INSTANCE_OPERATOR: 'Account Admin',
    CUSTOMER_ROLE_001: 'Customer role 1',
    CUSTOMER_ROLE_002: 'Customer role 2',
    CUSTOMER_ROLE_003: 'Customer role 3',
    ChangeUserRole: "Change Role",
    readFlag: 'R',
    writeFlag: 'W',
    deleteFlag: 'D',
    userList: 'User list',
    roleInstanceList: 'Permission list'
  },
  controlPanelHome: {
    lastLoginTime: ', Last login at: ',
    allAccountOperation: 'All activities',
    queryAll: 'View all',
    tradeTimes: 'Cumulative transaction times',
    tradeAmounts: 'Cumulative transaction volume',
    tradeAmount30: 'Transaction volume in the past 30 days',
    errorPosition: 'Error position notification',
    message: 'Personal message',
    groupMessage: 'Group message',
    system7Vol: 'Trading volume',
    unreadMsg: 'Message',
    unreadGroupMsg: 'Group Msg',
    taskConfig: 'Task Config',
    pendingTask: 'Pending task'
  },
  tradeMgnt: {
    load: 'Load',
    kPanel: {
      open: 'OPEN',
      close: 'CLOSE',
      high: 'HIGH',
      low: 'LOW',
      change: 'CHANGE',
      amplitude: 'AMPLITUDE',
      vol: 'VOL'
    },
    infoPanel: {
      change24: '24h Change',
      low24: '24h Low',
      high24: '24h High',
      vol24: '24h Vol (Coin)',
      volU24: '24h Vol (USD)'
    },
    klineType: {
      m1: '1m',
      m5: '5m',
      m15: '15m',
      m30: '30m',
      h1: '1H',
      h2: '2H',
      h4: '4H',
      d1: '1D',
      week: '1W'
    }
  },
  accountMgnt: {
    assetInfo: 'Assets',
    total: 'Balance',
    profit: 'Profits',
    tradeStatus: 'Trading status',
    manageInstance: 'Instances',
    news: 'News',
    addAccount: 'Add Account',
    linkAccount: 'Link Account',
    accountName: 'Alias',
    originBalance: 'Initial',
    currentBalance: 'Current',
    add: 'Add',
    rename: 'Rename',
    reset: 'Reset',
    cancelLink: 'Unlink',
    deleteAccount: 'Delete',
    bindCustomer: 'Bind Customer',
    nickName: 'Alias',
    createInstance: 'Create Instance',
    exchange: 'Exchange',
    subAccountName: 'Sub-name',
    userInfo: 'User Information'
  },
  instanceStatics: {
    AssetDollarRatio: 'Asset dollar ratio',
    accountStatistics: 'Account statistics',
    exchangeDollarAssets: 'Exchange dollar assets',
    exchange: 'Exchange',
    count: 'Count',
    total: 'Total'
  },
  customerProfit: {
    totalEarning: 'Total earnings',
    accounts: 'accounts'
  },
  riskFreeProfit: {
    RiskFreeRateOfReturn: 'Risk-free rate of return',
    FromBinanceDemandDepositRates: 'From Binance Demand Deposit Rates',
    APR: 'APR'
  },
  downloadPage: {
    HowToDownloadTheAppForIOS: 'How to download the APP for IOS',
    step0: 'App does not need to install any certificate, it can be downloaded directly from the official website, One-time installation, permanent automatic update',
    step1: 'Open the website',
    step1_1: 'using Safari(required) browser and click the share button on the browser toolbar.',
    step2: 'Click Add to Home Screen.',
    step3: 'After waiting for 1~2 seconds for the logo to load, click the Add button.',
    step4: 'You will see the Oneport app installed on your desktop.'
  }
}

export default {
  trans
}

</script>
