<template>
  <div>
    <el-collapse class="trialpha-collapse" v-model="createCollapse" style="border: 0;" @change="change">
      <el-collapse-item class="trialpha-collapse">
        <template slot="title">
          <i class="el-icon-s-marketing" style="margin-left: 2px;color: #0ecb81;margin-top: 1px;border: 0"/><h4 class="dark-mode-font" style="margin-left: 10px;padding-top: 6px;color: white">QUICK PLACE ORDER</h4>
        </template>
        <el-card class="black-card" body-style="padding: 0" style="border: 0;margin-left: -10px;margin-right: -10px" shadow="none">
          <div style="width: 100%;height: 418px;padding: 0;margin-top: 0">
            <TVChartContainer v-if="active" ref="quick-order-tv" :hide-header-search="true" :symbol="getTvSymbolName(selectSymbol)" style="padding: 0" v-on:chart-subscribe="onChartSubscribe"/>
          </div>
          <el-row style="margin-top: 25px">
            <el-col :span="10" :offset="1">
              <el-select class="select-symbol" filterable style="width: 100%" v-model="selectSymbolType" placeholder="请选择交易类型" @change="changeSymbolType">
                <el-option
                    v-for="item in tvReady ? symbolType : []"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="10" :offset="2">
              <el-select class="select-symbol" filterable style="width: 100%" v-model="selectSymbol" placeholder="请选择交易标的" @change="changeSymbol">
                <el-option
                    v-for="item in tvReady ? symbols : []"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row style="margin-top: 25px">
            <el-col :span="10" :offset="1">
              <el-select class="select-symbol" style="width: 100%" v-model="orderType" placeholder="请选择交易类型">
                <el-option
                    v-for="item in [{label: 'MARKET', disable: false}, {label: 'LIMIT', disable: false}]"
                    :key="item.label"
                    :label="item.label"
                    :value="item.label"
                    :disabled="item.disable">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="10" :offset="2">
              <el-select class="select-symbol" style="width: 100%" v-model="operation" placeholder="请选择交易方向" >
                <el-option
                    v-for="item in operationOrderType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row style="margin-top: 25px">
            <el-col :span="10" :offset="1">
              <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="price" :disabled="orderType === 'MARKET'">
                <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">Price</span></template>
              </el-input>
            </el-col>
            <el-col :span="10" :offset="2">
              <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="amount">
                <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">Amount</span></template>
              </el-input>
            </el-col>
          </el-row>
          <el-row style="margin-top: 25px">
            <el-col :span="22" :offset="1">
              <el-button class="dark-mode-btn" style="width: 100%;color: #FFFFFF;padding: 10px" @click="openOrder" :loading="processing" v-auth:dbWriteApi>SUBMIT</el-button>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row style="margin-top: 25px">
            <el-col :span="10" :offset="1">
              <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="leverage" :disabled="['ALL', 'USD_CONTRACT', 'COIN_CONTRACT'].indexOf(selectSymbolType) === -1">
                <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">Leverage</span></template>
              </el-input>
            </el-col>
            <el-col :span="10" :offset="2">
              <el-button class="dark-mode-btn" style="width: 100%;color: #FFFFFF;padding: 10px" @click="updateLeverage" :loading="updatingLeverage" v-auth:dbWriteApi :disabled="['ALL', 'USD_CONTRACT', 'COIN_CONTRACT'].indexOf(selectSymbolType) === -1">Change</el-button>
            </el-col>
          </el-row>
        </el-card>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import {Message} from "element-ui";
import ConfirmProvider from "@/components/ConfirmProvider";
import TVChartContainer from "@/components/TVChartContainer";
import GeneralApiService from "@/api/trade/GeneralApiService";

export default {
  name: "GeneralOrderPanel",
  components: {TVChartContainer},
  data: function () {
    return {
      createCollapse: ['1'],
      updatingLeverage: false,
      processing: false,
      operation: 'OPEN_LONG',
      operationOrderType: [
        {label: 'OPEN_LONG', value: GeneralApiService.ORDER_TYPE.OPEN_LONG},
        {label: 'OPEN_SHORT', value: GeneralApiService.ORDER_TYPE.OPEN_SHORT},
        {label: 'CLOSE_LONG', value: GeneralApiService.ORDER_TYPE.CLOSE_LONG},
        {label: 'CLOSE_SHORT', value: GeneralApiService.ORDER_TYPE.CLOSE_SHORT}
      ],
      orderType: 'MARKET',
      selectSymbol: undefined,
      selectSymbolType: GeneralApiService.SYMBOL_TYPE.SPOT,
      symbolType: [{label: 'Spot', value: GeneralApiService.SYMBOL_TYPE.SPOT}, {label: 'USDⓈ Contract', value: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT}, {label: 'Coin Contract', value: GeneralApiService.SYMBOL_TYPE.COIN_CONTRACT}, {label: 'Options', value: GeneralApiService.SYMBOL_TYPE.OPTIONS}],
      amount: 0,
      price: 0,
      leverage: 0,
      symbols: [],
      callback: {},
      tradeAccount: {},
      platform: 'BINANCE',
      active: false,
      tvReady: false,
      defaultSymbol: {
        BINANCE: {
          SYMBOL_TYPE: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT,
          SPOT: 'BTCUSDT',
          USD_CONTRACT: 'BTCUSDT',
          COIN_CONTRACT: 'BTCUSD_PERP'
        },
        BYBIT: {
          SYMBOL_TYPE: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT,
          SPOT: 'BTCUSDT',
          USD_CONTRACT: 'BTCUSDT'
        },
        OKX: {
          SYMBOL_TYPE: GeneralApiService.SYMBOL_TYPE.ALL,
          ALL: 'BTC-USDT-SWAP',
          SPOT: 'BTC-USDT-SWAP',
          USD_CONTRACT: 'BTC-USDT-SWAP',
          COIN_CONTRACT: 'BTC-USDT-SWAP'
        },
        DYDX: {
          SYMBOL_TYPE: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT,
          ALL: 'BTC-USD',
          SPOT: 'BTC-USD',
          USD_CONTRACT: 'BTC-USD',
          COIN_CONTRACT: 'BTC-USD'
        },
        DERIBIT: {
          SYMBOL_TYPE: GeneralApiService.SYMBOL_TYPE.ALL,
          ALL: 'BTC-PERPETUAL',
          SPOT: 'BTC-PERPETUAL',
          USD_CONTRACT: 'BTC-PERPETUAL',
          COIN_CONTRACT: 'BTC-PERPETUAL'
        },
        MEXC: {
          SYMBOL_TYPE: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT,
          SPOT: 'BTC_USDT',
          USD_CONTRACT: 'BTC_USDT',
          COIN_CONTRACT: 'BTC_USD'
        }
      }
    }
  },
  methods: {
    render: function (tradeAccount, tradeCallback) {
      this.callback = tradeCallback;
      this.tradeAccount = tradeAccount;
      this.platform = tradeAccount.platform;
      this.processing = false;
      if (this.platform === GeneralApiService.PLATFORM.BINANCE) {
        this.symbolType = [{label: 'Spot', value: GeneralApiService.SYMBOL_TYPE.SPOT}, {label: 'USDⓈ Contract', value: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT}, {label: 'Coin Contract', value: GeneralApiService.SYMBOL_TYPE.COIN_CONTRACT}];
      } else if (this.platform === GeneralApiService.PLATFORM.BYBIT) {
        this.symbolType = [{label: 'Spot', value: GeneralApiService.SYMBOL_TYPE.SPOT}, {label: 'USDⓈ Contract', value: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT}];
      } else if (this.platform === GeneralApiService.PLATFORM.OKX) {
        this.symbolType = [{label: 'ALL', value: GeneralApiService.SYMBOL_TYPE.ALL}];
      } else if (this.platform === GeneralApiService.PLATFORM.FTX) {
        this.symbolType = [{label: 'ALL', value: GeneralApiService.SYMBOL_TYPE.ALL}];
      } else if (this.platform === GeneralApiService.PLATFORM.MEXC) {
        this.symbolType = [{label: 'USDⓈ Contract', value: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT}];
      } else if (this.platform === GeneralApiService.PLATFORM.DYDX) {
        this.symbolType = [{label: 'USDⓈ Contract', value: GeneralApiService.SYMBOL_TYPE.USD_CONTRACT}];
      }
      if (this.selectSymbol === undefined) {
        this.selectSymbolType = this.defaultSymbol[this.platform]['SYMBOL_TYPE'];
        this.changeSymbolType();
      }
    },
    openOrder: function () {
      let that = this;
      ConfirmProvider.openConfirm(that, '下单确认', that.platform + ': ' + that.selectSymbol + ' ' + that.operation + ' ' + that.amount , function () {
        that.processing = true;
        if (that.orderType === 'MARKET') {
          GeneralApiService.placeMarketOrder(that.platform, that.selectSymbolType, that.operation, that.tradeAccount.apiKey, that.selectSymbol, that.amount, function (orderInfo) {
            Message.success(that.platform + ': ' + that.selectSymbol + ' ' + that.operation + ' ' + orderInfo.realAmount + ' AT ' + orderInfo.realCostPrice);
            that.callback();
            that.processing = false;
          }, function () {
            that.processing = false;
          });
        } else if (that.orderType === 'LIMIT') {
          GeneralApiService.placeLimitOrder(that.platform, that.selectSymbolType, that.operation, that.tradeAccount.apiKey, that.selectSymbol, that.price, that.amount, function (orderInfo) {
            Message.success(that.platform + ': ' + that.selectSymbol + ' ' + that.operation + ' ' + orderInfo.realAmount + ' AT ' + orderInfo.realCostPrice);
            that.callback();
            that.processing = false;
          }, function () {
            that.processing = false;
          });
        }
      });
    },
    updateLeverage: function () {
      let that = this;
      if (that.leverage < 1) {
        Message.error('杠杆配置不合法');
        return;
      }
      that.updatingLeverage = true;
      GeneralApiService.updateLeverage(that.platform, that.selectSymbolType, that.tradeAccount.apiKey, that.selectSymbol, that.leverage, function () {
        Message.success(that.platform + ': ' + that.selectSymbol + ' leverage update to ' + that.leverage);
        that.callback();
        that.updatingLeverage = false;
      }, function () {
        that.updatingLeverage = false;
      });
    },
    change: function (string) {
      if (string[0] === '1') {
        this.active = !this.active;
      }
    },
    changeSymbol: function () {
      try {
        let showTvSymbolName = this.getTvSymbolName(this.selectSymbol);
        if (this.$refs["quick-order-tv"]) {
          this.$refs["quick-order-tv"].setSymbol(showTvSymbolName, '15', () => {});
        }
      } catch (e) {
        console.error(e);
      }
    },
    getTvSymbolName: function (symbol) {
      let showTvSymbolName = symbol;
      if (this.platform === GeneralApiService.PLATFORM.BINANCE) {
        if (this.selectSymbolType === GeneralApiService.SYMBOL_TYPE.SPOT) {
          showTvSymbolName = symbol + '_SPOT';
        } else if (this.selectSymbolType === GeneralApiService.SYMBOL_TYPE.USD_CONTRACT) {
          showTvSymbolName = symbol + '_FUTURE';
        }
      } else if (this.platform === GeneralApiService.PLATFORM.BYBIT) {
        if (this.selectSymbolType === GeneralApiService.SYMBOL_TYPE.SPOT) {
          showTvSymbolName = symbol + '_BYBIT_SPOT';
        } else if (this.selectSymbolType === GeneralApiService.SYMBOL_TYPE.USD_CONTRACT) {
          showTvSymbolName = symbol + '_BYBIT_FUTURE';
        }
      }
      return showTvSymbolName;
    },
    changeSymbolType: function () {
      let that = this;
      GeneralApiService.getAllPrice(this.platform, this.selectSymbolType, function (data) {
        that.symbols = Object.keys(data);
        if (that.platform === 'DERIBIT') {
          that.selectSymbol = that.tradeAccount.baseCoin.replaceAll('_U', '') + '-PERPETUAL';
        } else {
          that.selectSymbol = that.defaultSymbol[that.platform][that.selectSymbolType];
        }
        that.changeSymbol();
      });
    },
    onChartSubscribe(type) {
      if (['chart_ready', 'undo_redo_state_changed', 'onAutoSaveNeeded', 'series_properties_changed', 'get_bars', 'study_event', 'onTick'].indexOf(type) > -1) {
        this.tvReady = true;
      }
    }
  }
}
</script>

<style scoped>

.el-divider {
  border-top: 1px solid #444c56;
}

.el-collapse {
  color: #FFFFFF;
  border-top: 1px solid #444c56;
  border-bottom: 1px solid #444c56;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: white;
}

.el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

</style>