<template>
  <div>

    <!-- 列表 -->
    <FtTableListWidget :columns="columns" :data="personalListShow"
                       :scrollHeight="scrollHeight"
                       v-on:checked-change="onCheckedChange"
                       v-on:checked-remove="onCheckedRemove"
                       v-on:action-cell="onAccountStatusController" style="border-bottom: 1px solid #3E454E;"/>

    <!-- 分页器 -->
    <!-- <FtPagingWidget style="display: none;"
                    :pageIndex="paging.pageIndex"
                    :pageSize="paging.pageSize"
                    :pageTotal="paging.pageTotal"
                    v-on:index-change="onPagingIndexChange"/> -->

  </div>
</template>

<script>
// import {Message} from "element-ui";
import FtTableListWidget from "@/components/widgets/FtTableListWidget";
// import FtPagingWidget from "@/components/widgets/FtPagingWidget";
import ConfirmProvider from '@/components/ConfirmProvider'

import { useCrmStore, useCrmOutsideStore } from '@/store/crm'
import CrmChannelManagement from "@/api/crm/CrmChannelManagement";

export default {
  components: {
    FtTableListWidget,
    // FtPagingWidget
  },
  props: {},
  data() {
    return {
      // 列表信息
      columns: [
        // {textI18n: '', text: '#', align: 'left', width: '8%', name: 'id', checkbox: true},
        {textI18n: '', text: '姓名', align: 'left', width: '20%', name: 'name'},
        {
          textI18n: '', text: '资金', align: 'left', width: '20%', name: 'startFundsAmount', render: (name, rowScope) => {
            return rowScope[name] + ' ' + rowScope['coinName']
          }
        },
        // {textI18n: '', text: '最大回撤', align: 'left', width: '12%', name: 'maxDrawdown', render: (name, rowScope) => {
        //     return rowScope[name] + '%'
        // }},
        // {textI18n: '', text: '截止时间', align: 'left', width: '10%', name: 'settlementTimeString', render: (name, rowScope) => {
        //     return rowScope[name].substring(0, 10)
        // }},
        {
          textI18n: '', text: '进度', align: 'left', width: '20%', name: 'progress', render: (name, rowScope) => {
            var value = rowScope[name]
            // 接触中
            if (value === '接触中') {
              return '<span style="color: #FFB324; background: rgba(255, 179, 36, 0.1); padding: 6px 12px; border-radius: 8px; font-size: 12px;">'
                  + value
                  + '</span>'
            }
            // 沟通中
            if (value === '沟通中') {
              return '<span style="color: #EEFF24; background: rgba(238, 255, 36, 0.1); padding: 6px 12px; border-radius: 8px; font-size: 12px;">'
                  + value
                  + '</span>'
            }
            // 达成合作
            if (value === '达成合作') {
              return '<span style="color: #00C683; background: rgba(0, 198, 131, 0.1); padding: 6px 12px; border-radius: 8px; font-size: 12px;">'
                  + value
                  + '</span>'
            }
            // 沟通失败
            if (value === '沟通失败') {
              return '<span style="background: #3E454E; padding: 6px 12px; border-radius: 8px; font-size: 12px;">'
                  + value
                  + '</span>'
            }
            return ''
          }
        },
        {
          textI18n: '', text: '状态', align: 'left', width: '20%', name: 'accountStatus', render: (name, rowScope) => {
            return this.getAccountStatusElementTag(name, rowScope)
          }
        },
        {
          textI18n: '', text: '渠道归属', align: 'left', width: '16%', name: 'text6', render: (name, rowScope) => {
            return rowScope['channel']['name']
          }
        },
        {
          textI18n: '', text: '', icon: 'el-icon-more', align: 'right', width: '4%', name: 'remove', remove: true, render: () => {
            return '<el-button class="dark-mode-btn" style="background: rgba(0, 0, 0, 0);border: 0"><i class="el-icon-delete-solid"></i></el-button>';
          }
        },
      ],
      data: [
        // {
        //     id: 1,
        //     "accountNumber": 1,
        //     "accountStatus": "运行中",
        //     "accountTag": "string",
        //     "channel": {
        //         "id": 10,
        //         "name": "李宁渠道"
        //     },
        //     "coinName": "BTC",
        //     "followUser": "string",
        //     "fundUtilizationRate": 0.2,
        //     "maxDrawdown": 0,
        //     "name": "李宁",
        //     "platform": "FTX",
        //     "progress": "接触中",
        //     "settlementTime": "2022-07-01T02:29:23.114Z",
        //     "startFundsAmount": 22223.2,
        //     "strategyRemark": "string",
        //     "user": "string"
        // },
        // {
        //     id: 2,
        //     "accountNumber": 2,
        //     "accountStatus": "运行中",
        //     "accountTag": "string",
        //     "channel": {
        //         "id": 10,
        //         "name": "李宁渠道"
        //     },
        //     "coinName": "BTC",
        //     "followUser": "string",
        //     "fundUtilizationRate": 0.2,
        //     "maxDrawdown": 0,
        //     "name": "李宁2",
        //     "platform": "FTX",
        //     "progress": "接触中",
        //     "settlementTime": "2022-07-01T02:29:23.114Z",
        //     "startFundsAmount": 22223.2,
        //     "strategyRemark": "string",
        //     "user": "string"
        // }
      ],
      // 滚动面板高度
      scrollHeight: 0,
      // 进度信息
      progress: '',
      // 模糊查询
      search: '',
      // 分页信息
      paging: {
        pageIndex: 0,
        pageSize: 20,
        pageTotal: 123
      },
      // 激活的客户ID
      activeCustomer: 0,
      // 选中的客户详情
      personalActive: null,

      // 查询模式, list/search
      queryMode: 'list',

      // 交易所列表
      platforms: [
        {code: "-1", desc: "请选择"},
        {code: "0", desc: "FTX"},
        {code: "1", desc: "Binance"},
        {code: "2", desc: "OKX"},
        {code: "3", desc: "Deribit"},
        {code: "4", desc: "BITMEX"},
        {code: "5", desc: "Bybit"},
        {code: "6", desc: "Kucoin"},
        {code: "7", desc: "Huobi"},
        {code: "8", desc: "Gate"},
        {code: "9", desc: "Bitcoke"},
        {code: "10", desc: "MEXC"},
      ],

    }
  },
  computed: {
    // 显示的列表
    personalListShow: () => {
      var crmOutsideStore = useCrmOutsideStore()
      return crmOutsideStore.personalListShow.map(row => {
        return row.view
      })
    }
  },
  mounted() {
    console.log('个人面板')
    this.customerList()

    var windowHeight = window.innerHeight
    this.scrollHeight = windowHeight - 240 - 65

  },
  methods: {

    // 设置查询条件
    setWhile(name, value) {
      if (name === 'progress') {
        this.progress = value
        this.search = ''
        this.paging.pageIndex = 0
        this.queryMode = 'list'
        this.customerList()
      }
      if (name === 'search') {
        this.search = value
        this.queryMode = 'search'
        this.likeCustomerList()
      }
    },

    // 根据当前条件刷新
    setRefresh() {
      return new Promise(resolve => {
        if (this.queryMode === 'list') {
          this.customerList().then((items) => {
            if (this.personalActive) {
              var active = this.personalActive
              var item = items.filter(m => {
                return m.id === active.id
              })[0]
              if (item) {
                this.$emit('checked-refresh', 'personal', item);
              }
            }
            resolve()
          }, () => {
            resolve()
          })
        }
        if (this.queryMode === 'search') {
          this.likeCustomerList().then((items) => {
            if (this.personalActive) {
              var active = this.personalActive
              var item = items.filter(m => {
                return m.id === active.id
              })[0]
              if (item) {
                this.$emit('checked-refresh', 'personal', item);
              }
            }
            resolve()
          }, () => {
            resolve()
          })
        }
      })
    },

    customerList() {
      return new Promise((resolve, reject) => {
        var crmStore = useCrmStore()
        crmStore.loadPersonalData().then(items => {
          resolve(items)
        }, err => {
          reject(err)
        })
        // CrmChannelManagement.queryCustomer(this.paging.pageIndex + 1, this.paging.pageSize, this.progress, '',
        //     (data) => {
        //       let items = data.content;
        //       items.forEach(m => {
        //         var d = new Date(m.settlementTime * 1000)
        //         m.settlementTimeString = d.getFullYear() + '-' +
        //             ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + '-' +
        //             (d.getDate() < 10 ? '0' + d.getDate() : d.getDate())
        //       })
        //       this.data = items
        //       this.paging.pageTotal = data.totalElements
        //       resolve(items)
        //     },
        //     (err) => {
        //       // Message.error(this.$i("operationFail"));
        //       reject(err)
        //     }
        // );
      })
    },

    likeCustomerList() {
      return new Promise((resolve, reject) => {
        CrmChannelManagement.likeQueryCustomer(this.search,
            (data) => {
              let items = data;
              items.forEach(m => {
                var d = new Date(m.settlementTime * 1000)
                m.settlementTimeString = d.getFullYear() + '-' +
                    ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + '-' +
                    (d.getDate() < 10 ? '0' + d.getDate() : d.getDate())
              })
              this.data = items
              this.paging.pageTotal = data.totalElements



              resolve(items)
            },
            (err) => {
              // Message.error(this.$i("operationFail"));
              reject(err)
            }
        );
      })
    },

    onPagingIndexChange(index) {
      console.log('onPagingIndexChange', index)
      this.paging.pageIndex = index
      this.setRefresh()
    },

    onCheckedChange(item) {
      this.personalActive = item
      var crmStore = useCrmStore()
      crmStore.setCurrentSource('personal')
      this.$emit('checked-change', 'personal', item);
    },

    onCheckedRemove(item) {
      console.log('删除动作', item)
      ConfirmProvider.openConfirm(this, '删除操作', '请确认删除该客户: ' + item.name, () => {
        CrmChannelManagement.deleteCustomer(item.id,
            () => {
              this.setRefresh()
            },
            () => {
              // Message.error(this.$i("operationFail"));
            }
        );
      });
    },

    // Registered : 显示: KYC审核  点击后，更新状态为KYCChecked
    // KYCChecked : 显示: 生成连接  点击后，然后调用createCasualID，返回UUID后，引导用户去填写api信息，状态会变成SettingApi，然后调用setApiInfo
    // Setting    : 显示: 账号配置  点击后，更新状态为Handing
    // Handing    : 显示: 待运行    点击后，更新状态为Running
    // Running    : 显示: 运行中
    getAccountStatusElementTag(name, item) {
      const value = item[name];
      let html = '';
      if (value === 'Registered') {
        html += '<span style="color: #EEFF24; background: rgba(238, 255, 36, 0.1); padding: 6px 12px; border-radius: 8px; font-size: 12px; cursor: pointer;">'
        html += 'KYC审核'
        html += '</span>'
      } else if (value === 'KYCChecked') {
        html += '<span style="color: #EEFF24; background: rgba(238, 255, 36, 0.1); padding: 6px 12px; border-radius: 8px; font-size: 12px; cursor: pointer;">'
        html += '生成连接'
        html += '</span>'
      } else if (value === 'SettingApi') {
        html += '<span style="background: #3E454E; padding: 6px 12px; border-radius: 8px; font-size: 12px;">'
        html += 'Api配置'
        html += '</span>'
      } else if (value === 'Setting') {
        html += '<span style="color: #EEFF24; background: rgba(238, 255, 36, 0.1); padding: 6px 12px; border-radius: 8px; font-size: 12px; cursor: pointer;">'
        html += '账号配置'
        html += '</span>'
      } else if (value === 'Handing') {
        html += '<span style="color: #EEFF24; background: rgba(238, 255, 36, 0.1); padding: 6px 12px; border-radius: 8px; font-size: 12px; cursor: pointer;">'
        html += '待运行'
        html += '</span>'
      } else if (value === 'Running') {
        html += '<span style="background: #3E454E; padding: 6px 12px; border-radius: 8px; font-size: 12px;">'
        html += '运行中'
        html += '</span>'
      }
      return html
    },

    // 账号状态流程控制
    // Registered : 显示: KYC审核  点击后，confirm 更新状态为KYCChecked
    // KYCChecked : 显示: 生成连接  点击后，confirm 然后调用createCasualID，返回UUID后，引导用户去填写api信息，状态会变成SettingApi，然后调用setApiInfo
    // Setting    : 显示: 账号配置  点击后， confirm 更新状态为Handing
    // Handing    : 显示: 待运行    点击后， confirm 更新状态为Running
    // Running    : 显示: 运行中
    onAccountStatusController(name, item) {
      const that = this;
      if (name === 'accountStatus') {
        console.log(item)
        var crmStore = useCrmStore()
        let customer = crmStore.getCurrentIndexAccount();
        if (!customer) {
          customer = item
        }
        let value = item[name];
        if (value === 'Registered') {
          // html += 'KYC审核'
          ConfirmProvider.openConfirm(that, '操作确认', '请确认用户的KYC资料', function () {
            var form = {
              accountNumber: customer.accountNumber,
              accountStatus: 'KYCChecked',
              accountTag: customer.accountTag,
              channelId: customer.channel.id,
              coinName: customer.coinName,
              customerId: customer.id,
              followUser: customer.followUser,
              fundUtilizationRate: customer.fundUtilizationRate,
              maxDrawdown: customer.maxDrawdown,
              name: customer.name,
              platform: (() => {
                var m = that.platforms.filter(item => {
                  return item.desc == customer.platform
                })[0]
                return m ? m.code : ''
              })(),
              progress: customer.progress,
              settlementTime: new Date(customer.settlementTime * 1000).getTime(),
              startFundsAmount: customer.startFundsAmount,
              strategyRemark: customer.strategyRemark,
              user: customer.user,
            }
            CrmChannelManagement.updateCustomerInfo(form,
                () => {
                  that.setRefresh().then(() => {
                    // 刷新右侧详情
                    crmStore.setCurrentMainAccount(customer.name, customer.accountSerialNumber)
                  })
                },
                () => {
                  // Message.error(that.$i("operationFail"));
                }
            );
          });
        } else if (value === 'KYCChecked') {
          // html += '生成连接'
          ConfirmProvider.openConfirm(that, '操作确认', '请确认生成连接', function () {
            CrmChannelManagement.createCasualID(customer.id,
                (data) => {
                  ConfirmProvider.openAlert(that, customer.name + '专属URL', 'https://quant.forthtech.io/ap/customerKey?randomID=' + data, function () {
                    that.setRefresh().then(() => {
                      // 刷新右侧详情
                      crmStore.setCurrentMainAccount(customer.name, customer.accountSerialNumber)
                    })
                  })
                },
                () => {
                  // Message.error(that.$i("operationFail"));
                }
            );
          });
        } else if (value === 'SettingApi') {
          // SettingApi状态时，等待设置完成后，会自动变成Setting
        } else if (value === 'Setting') {
          // html += '账号配置'
          ConfirmProvider.openConfirm(that, '操作确认', '请确认客户的账号全部配置完毕', function () {
            var form = {
              accountNumber: customer.accountNumber,
              accountStatus: 'Handing',
              accountTag: customer.accountTag,
              channelId: customer.channel.id,
              coinName: customer.coinName,
              customerId: customer.id,
              followUser: customer.followUser,
              fundUtilizationRate: customer.fundUtilizationRate,
              maxDrawdown: customer.maxDrawdown,
              name: customer.name,
              platform: (() => {
                var m = that.platforms.filter(item => {
                  return item.desc == customer.platform
                })[0]
                return m ? m.code : ''
              })(),
              progress: customer.progress,
              settlementTime: new Date(customer.settlementTime * 1000).getTime(),
              startFundsAmount: customer.startFundsAmount,
              strategyRemark: customer.strategyRemark,
              user: customer.user,
            }
            CrmChannelManagement.updateCustomerInfo(form,
                () => {
                  that.setRefresh().then(() => {
                    // 刷新右侧详情
                    crmStore.setCurrentMainAccount(customer.name, customer.accountSerialNumber)
                  })
                },
                () => {
                  // Message.error(that.$i("operationFail"));
                }
            );
          });
        } else if (value === 'Handing') {
          // html += '待运行'
          ConfirmProvider.openConfirm(that, '操作确认', '请确认客户的账号已经启动完毕', function () {
            var form = {
              accountNumber: customer.accountNumber,
              accountStatus: 'Running',
              accountTag: customer.accountTag,
              channelId: customer.channel.id,
              coinName: customer.coinName,
              customerId: customer.id,
              followUser: customer.followUser,
              fundUtilizationRate: customer.fundUtilizationRate,
              maxDrawdown: customer.maxDrawdown,
              name: customer.name,
              platform: (() => {
                var m = that.platforms.filter(item => {
                  return item.desc == customer.platform
                })[0]
                return m ? m.code : ''
              })(),
              progress: customer.progress,
              settlementTime: new Date(customer.settlementTime * 1000).getTime(),
              startFundsAmount: customer.startFundsAmount,
              strategyRemark: customer.strategyRemark,
              user: customer.user,
            }
            CrmChannelManagement.updateCustomerInfo(form,
                () => {
                  that.setRefresh().then(() => {
                    // 刷新右侧详情
                    crmStore.setCurrentMainAccount(customer.name, customer.accountSerialNumber)
                  })
                },
                () => {
                  // Message.error(that.$i("operationFail"));
                }
            );
          });
        } else if (value === 'Running') {
          // html += '运行中'
        }
      } else if (name === 'remove') {
        this.onCheckedRemove(item);
      } else {
        that.onCheckedChange(item)
      }
    }

  }
}
</script>

<style>

</style>