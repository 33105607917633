<template>
  <div>
    <el-row class="hidden-less-than-992" style="padding: 0 5px 0 5px">
      <el-row>
        <el-col :span="16" style="padding: 0 10px 0 0">
          <el-row>
            <el-card class="black-card" body-style="padding: 0" style="width: 100%;border-radius: 3px 3px 0 0;border-bottom: 0;background-color: #454c56">
              <el-row>
                <el-col :span="24">
                  <el-popover
                      @show="report"
                      :visible-arrow=false
                      transition="none"
                      width="1368"
                      header-row-class-name="black-card"
                      cell-class-name="black-card"
                      trigger="click">
                    <funding-fee-report ref="funding-fee-report"></funding-fee-report>
                    <div slot="reference">
                      <el-button v-auth:fundingFeeTools class="dark-mode-btn ellipsis-line" size="small" style="float: left;width: 128px;height: 25px;line-height: 25px;padding: 0 10px 0 10px" @click="report">Analysis Report</el-button>
                    </div>
                  </el-popover>
                  <el-button-group style="float: right;margin-right: 2px">
                    <el-button class="dark-mode-btn ellipsis-line" size="small" style="float: right;width: 98px;height: 25px;line-height: 25px;padding: 0 10px 0 10px" @click="typeChanged" :disabled="typeFilter === 'ALL'">ALL</el-button>
                    <el-button class="dark-mode-btn ellipsis-line" size="small" style="float: right;width: 98px;height: 25px;line-height: 25px;padding: 0 10px 0 10px" @click="typeChanged" :disabled="typeFilter === 'FUTURE'">Only Future</el-button>
                  </el-button-group>
                  <el-input class="dark-mode-input-search" placeholder="Filter" v-model="search" style="float: right;width: 98px"></el-input>
                </el-col>
              </el-row>
            </el-card>
            <el-card class="black-card" body-style="padding: 0" style="width: 100%;border-radius: 0;border-bottom: 0;background-color: #454c56" shadow="none">
              <el-row>
                <el-col :span="4">
                  <span class="dark-mode-font" style="color: white;float: left;margin-left: 10px">Binance</span>
                </el-col>
                <el-col :span="7" :offset="1">
                  <span class="dark-mode-font title-right ellipsis-line-strategy">FUNDING( Real / 30 days / 1 year )</span>
                </el-col>
                <el-col :span="3" v-for="head in binanceFuturesType" :key="head">
                  <span class="dark-mode-font title-right ellipsis-line-strategy">{{ head }}</span>
                </el-col>
              </el-row>
            </el-card>
            <el-card class="black-card" style="width: 100%;border-radius: 0;overflow-y: auto" body-style="padding: 0">
              <el-skeleton animated :loading="renderingBinance" :throttle="200">
                <template slot="template">
                  <trialpha-sk :style="'margin-top: 20px;width: 60%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 50%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 50%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk style="margin-top: 20px;width: 60%;float: left;margin-left:10px;margin-bottom: 20px"></trialpha-sk>
                </template>
                <template>
                  <el-card class="black-card-hover" body-style="padding: 0" style="border-top: 0;border-left: 0;border-right: 0;height: 25px;border-radius: 0" v-for="item in binance.filter((item) => { return binanceFilter(item) })" :key="item">
                    <el-row>
                      <el-col :span="3">
                        <span class="dark-mode-font ellipsis-line-strategy" style="float: left;margin-left: 10px;font-size: 12px;line-height: 25px">{{ item.replace('-USD', '-C').replace('-CT', '-U') }}</span>
                      </el-col>
                      <el-col :span="9">
                        <el-row>
                          <el-col :span="8">
                            <span class="dark-mode-font ellipsis-line-strategy small-font" :style="'' + (getFundingFee('Binance', 'REAL', item.replaceAll('USDT', '').replaceAll('BUSD', ''), 'U') < 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ getFundingFee('Binance', 'REAL', item.replaceAll('USDT', '').replaceAll('BUSD', ''), 'U') }}%</span>
                          </el-col>
                          <el-col :span="8">
                            <span class="dark-mode-font ellipsis-line-strategy small-font" :style="'' + (getFundingFee('Binance', '30DAY', item.replaceAll('USDT', '').replaceAll('BUSD', ''), 'U') < 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ getFundingFee('Binance', '30DAY', item.replaceAll('USDT', '').replaceAll('BUSD', ''), 'U') }}%</span>
                          </el-col>
                          <el-col :span="8">
                            <span class="dark-mode-font ellipsis-line-strategy small-font" :style="'' + (getFundingFee('Binance', '1YEAR', item.replaceAll('USDT', '').replaceAll('BUSD', ''), 'U') < 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ getFundingFee('Binance', '1YEAR', item.replaceAll('USDT', '').replaceAll('BUSD', ''), 'U') }}%</span>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="3" v-for="head in binanceFuturesType" :key="item + '-' + head">
                        <span class="dark-mode-font title-right" :style="'font-size: 12px;line-height: 25px;' + (binanceAllPrice[item + 'USDT_' + head] > (binanceAllPrice[item + 'USDT'] ? binanceAllPrice[item + 'USDT'] : binanceAllPrice[item + 'BUSD']) ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ ((((binanceAllPrice[item + 'USDT_' + head] - (binanceAllPrice[item + 'USDT'] ? binanceAllPrice[item + 'USDT'] : binanceAllPrice[item + 'BUSD'])) / (binanceAllPrice[item + 'USDT'] ? binanceAllPrice[item + 'USDT'] : binanceAllPrice[item + 'BUSD'])) * 100).toFixed(2) + '%').replace('NaN%', '-') }}</span>
                      </el-col>
                    </el-row>
                  </el-card>
                </template>
              </el-skeleton>
            </el-card>
          </el-row>
          <el-row>
            <el-card class="black-card" body-style="padding: 0" style="width: 100%;border-radius: 0;border-bottom: 0;background-color: #454c56">
              <el-row>
                <el-col :span="3">
                  <span class="dark-mode-font" style="color: white;float: left;margin-left: 10px">OKX</span>
                </el-col>
                <el-col :span="7" :offset="2">
                  <span class="dark-mode-font title-right ellipsis-line-strategy">FUNDING( Real / 30 days / 1 year )</span>
                </el-col>
                <el-col :span="3" v-for="head in okxFuturesType" :key="head">
                  <span class="dark-mode-font title-right ellipsis-line-strategy">{{ head }}</span>
                </el-col>
              </el-row>
            </el-card>
            <el-card class="black-card" style="width: 100%;border-radius: 0;overflow-y: auto" body-style="padding: 0">
              <el-skeleton animated :loading="renderingOkx" :throttle="200">
                <template slot="template">
                  <trialpha-sk :style="'margin-top: 25px;width: 60%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 50%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 50%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 60%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 90%;float: left;margin-left:10px;margin-bottom: 20px'"></trialpha-sk>
                </template>
                <template>
                  <el-card class="black-card-hover" body-style="padding: 0" style="border-top: 0;border-left: 0;border-right: 0;height: 25px;border-radius: 0" v-for="item in okx.filter((item) => { return okxFilter(item) })" :key="item">
                    <el-row>
                      <el-col :span="3">
                        <span class="dark-mode-font ellipsis-line-strategy" style="float: left;margin-left: 10px;font-size: 12px;line-height: 25px">{{ item.replace('-USD', '-C').replace('-CT', '-U') }}</span>
                      </el-col>
                      <el-col :span="9">
                        <el-row>
                          <el-col :span="8">
                            <span class="dark-mode-font ellipsis-line-strategy small-font" :style="'' + (getFundingFee('OKX', 'REAL', item.split('-')[0], item.replace('-USD', '-C').replace('-CT', '-U').split('-')[1]) < 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ getFundingFee('OKX', 'REAL', item.split('-')[0], item.replace('-USD', '-C').replace('-CT', '-U').split('-')[1]) }}%</span>
                          </el-col>
                          <el-col :span="8">
                            <span class="dark-mode-font ellipsis-line-strategy small-font" :style="'' + (getFundingFee('OKX', '30DAY', item.split('-')[0], item.replace('-USD', '-C').replace('-CT', '-U').split('-')[1]) < 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ getFundingFee('OKX', '30DAY', item.split('-')[0], item.replace('-USD', '-C').replace('-CT', '-U').split('-')[1]) }}%</span>
                          </el-col>
                          <el-col :span="8">
                            <span class="dark-mode-font ellipsis-line-strategy small-font" :style="'' + (getFundingFee('OKX', '1YEAR', item.split('-')[0], item.replace('-USD', '-C').replace('-CT', '-U').split('-')[1]) < 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ getFundingFee('OKX', '1YEAR', item.split('-')[0], item.replace('-USD', '-C').replace('-CT', '-U').split('-')[1]) }}%</span>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="3" v-for="head in okxFuturesType" :key="item + '-' + head">
                        <span class="dark-mode-font title-right" :style="'font-size: 12px;line-height: 25px;' + (okxAllPrice[item + '-' + head] > okxAllPrice[item + '-SWAP'] ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ ((((okxAllPrice[item + '-' + head] - okxAllPrice[item + '-SWAP']) / okxAllPrice[item + '-SWAP']) * 100).toFixed(2) + '%').replace('NaN%', '-') }}</span>
                      </el-col>
                    </el-row>
                  </el-card>
                </template>
              </el-skeleton>
            </el-card>
          </el-row>
          <!--

          <el-row>
            <el-card class="black-card" body-style="padding: 0" style="width: 100%;border-radius: 0;border-bottom: 0;background-color: #454c56">
              <el-row>
                <el-col :span="3">
                  <span class="dark-mode-font" style="color: white;float: left;margin-left: 10px">FTX</span>
                </el-col>
                <el-col :span="9">
                  <span class="dark-mode-font title-right ellipsis-line-strategy">FUNDING( Real / 30 days / 1 year )</span>
                </el-col>
                <el-col :span="3">
                  <el-card class="black-card" style="background-color: #454c56;border: 0;height: 20px" shadow="none"></el-card>
                </el-col>
                <el-col :span="3" v-for="head in ftxFuturesType" :key="head">
                  <span class="dark-mode-font title-right ellipsis-line-strategy">{{ head }}</span>
                </el-col>
              </el-row>
            </el-card>
            <el-card class="black-card" style="width: 100%;border-radius: 0;overflow-y: auto;" body-style="padding: 0">
              <el-skeleton animated :loading="renderingFtx" :throttle="200">
                <template slot="template">
                  <trialpha-sk :style="'margin-top: 25px;width: 60%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 50%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 50%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 60%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 90%;float: left;margin-left:10px;margin-bottom: 20px'"></trialpha-sk>
                </template>
                <template>
                  <el-card class="black-card-hover" body-style="padding: 0" style="border-top: 0;border-left: 0;border-right: 0;height: 25px;border-radius: 0" v-for="item in ftx.filter((item) => { return ftxFilter(item) })" :key="item">
                    <el-row>
                      <el-col :span="3">
                        <span class="dark-mode-font ellipsis-line-strategy" style="float: left;margin-left: 10px;font-size: 12px;line-height: 25px">{{ item }}</span>
                      </el-col>
                      <el-col :span="9">
                        <el-row>
                          <el-col :span="8">
                            <span class="dark-mode-font ellipsis-line-strategy small-font" :style="'' + (getFundingFee('FTX', 'REAL', item, 'U') < 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ getFundingFee('FTX', 'REAL', item, 'U') }}%</span>
                          </el-col>
                          <el-col :span="8">
                            <span class="dark-mode-font ellipsis-line-strategy small-font" :style="'' + (getFundingFee('FTX', '30DAY', item, 'U') < 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ getFundingFee('FTX', '30DAY', item, 'U') }}%</span>
                          </el-col>
                          <el-col :span="8">
                            <span class="dark-mode-font ellipsis-line-strategy small-font" :style="'' + (getFundingFee('FTX', '1YEAR', item, 'U') < 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ getFundingFee('FTX', '1YEAR', item, 'U') }}%</span>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="3">
                        <el-card class="black-card" style="background-color: rgba(0, 0, 0, 0);border: 0" shadow="none"></el-card>
                      </el-col>
                      <el-col :span="3" v-for="head in ftxFuturesType" :key="item + '-' + head">
                        <span class="dark-mode-font title-right" :style="'font-size: 12px;line-height: 25px;' + (ftxAllPrice[item + '-' + head] > ftxAllPrice[item + '-PERP'] ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ ((((ftxAllPrice[item + '-' + head] - ftxAllPrice[item + '-PERP']) / ftxAllPrice[item + '-PERP']) * 100).toFixed(2) + '%').replace('NaN%', '-') }}</span>
                      </el-col>
                    </el-row>
                  </el-card>
                </template>
              </el-skeleton>
            </el-card>
          </el-row>

          -->

          <el-row>
            <el-card class="black-card" body-style="padding: 0" style="width: 100%;border-radius: 0;border-bottom: 0;background-color: #454c56">
              <el-row>
                <el-col :span="3">
                  <span class="dark-mode-font" style="color: white;float: left;margin-left: 10px">DERIBIT</span>
                </el-col>
                <el-col :span="5">
                  <span class="dark-mode-font title-right ellipsis-line-strategy">FUNDING( Real / 30 days / 1 year )</span>
                </el-col>
                <el-col :span="2" v-for="head in deribitFuturesType" :key="head">
                  <span class="dark-mode-font title-right ellipsis-line-strategy">{{ head }}</span>
                </el-col>
              </el-row>
            </el-card>
            <el-card class="black-card" style="width: 100%;border-radius: 0 0 3px 3px;overflow-y: auto;" body-style="padding: 0">
              <el-skeleton animated :loading="renderingDeribit" :throttle="200">
                <template slot="template">
                  <trialpha-sk :style="'margin-top: 20px;width: 60%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 90%;float: left;margin-left:10px;margin-bottom: 20px'"></trialpha-sk>
                </template>
                <template>
                  <el-card class="black-card-hover" body-style="padding: 0" style="border-top: 0;border-left: 0;border-right: 0;height: 25px;border-radius: 0" v-for="item in deribit.filter((item) => { return deribitFilter(item) })" :key="item">
                    <el-row>
                      <el-col :span="3">
                        <span class="dark-mode-font ellipsis-line-strategy" style="float: left;margin-left: 10px;font-size: 12px;line-height: 25px">{{ item }}</span>
                      </el-col>
                      <el-col :span="5">
                        <el-row>
                          <el-col :span="8">
                            <span class="dark-mode-font ellipsis-line-strategy small-font" :style="'' + (getFundingFee('Deribit', 'REAL', item, 'C') < 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ getFundingFee('Deribit', 'REAL', item, 'C') }}%</span>
                          </el-col>
                          <el-col :span="8">
                            <span class="dark-mode-font ellipsis-line-strategy small-font" :style="'' + (getFundingFee('Deribit', '30DAY', item, 'C') < 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ getFundingFee('Deribit', '30DAY', item, 'C') }}%</span>
                          </el-col>
                          <el-col :span="8">
                            <span class="dark-mode-font ellipsis-line-strategy small-font" :style="'' + (getFundingFee('Deribit', '1YEAR', item, 'C') < 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ getFundingFee('Deribit', '1YEAR', item, 'C') }}%</span>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="2" v-for="head in deribitFuturesType" :key="item + '-' + head">
                        <span class="dark-mode-font title-right" :style="'font-size: 12px;line-height: 25px;' + (deribitAllPrice[item + '-' + head] > deribitAllPrice[item + '-PERPETUAL'] ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ ((((deribitAllPrice[item + '-' + head] - deribitAllPrice[item + '-PERPETUAL']) / deribitAllPrice[item + '-PERPETUAL']) * 100).toFixed(2) + '%').replace('NaN%', '-') }}</span>
                      </el-col>
                    </el-row>
                  </el-card>
                </template>
              </el-skeleton>
            </el-card>
          </el-row>
        </el-col>
        <el-col :span="8" style="padding: 0 0 0 10px">
          <el-row>
            <el-col :span="12" style="padding: 0 3px 0 0">
              <el-card class="black-card" style="height: 27px;background-color: #454c56; border-radius: 3px 3px 0 0" body-style="padding: 0">
                <span class="dark-mode-font" style="line-height: 27px;font-size: 13px;color: #FFFFFF">4小时爆仓统计</span>
              </el-card>
              <el-card class="black-card" style="height: 75px;border-radius: 0 0 3px 3px" body-style="padding: 0">
                <span class="dark-mode-font" style="font-size: 23px;line-height: 75px;color: rgb(246, 70, 93)">${{ Number.prototype.toFormatNum(liquidation.h4TotalVolUsd.toFixed(0)) }}</span>
              </el-card>
            </el-col>
            <el-col :span="12" style="padding: 0 0 0 3px">
              <el-card class="black-card" style="height: 27px;background-color: #454c56; border-radius: 3px 3px 0 0" body-style="padding: 0">
                <span class="dark-mode-font" style="line-height: 27px;font-size: 13px;color: #FFFFFF">24小时爆仓统计</span>
              </el-card>
              <el-card class="black-card" style="height: 75px;border-radius: 0 0 3px 3px" body-style="padding: 0">
                <span class="dark-mode-font" style="font-size: 23px;line-height: 75px;color: rgb(246, 70, 93)">${{ Number.prototype.toFormatNum(liquidation.h24TotalVolUsd.toFixed(0)) }}</span>
              </el-card>
            </el-col>
          </el-row>
          <el-row style="margin-top: 25px">
            <future-data style="height: 125px;width: 100%"></future-data>
          </el-row>
          <el-row style="margin-top: 25px">
            <option-data style="height: 560px;width: 100%"></option-data>
          </el-row>
        </el-col>
      </el-row>
    </el-row>
    <el-row class="hidden-more-than-992">
      <el-row>
        <el-col :span="12" style="padding: 0 3px 0 0">
          <el-card class="black-card" style="height: 27px;background-color: #454c56; border-radius: 3px 3px 0 0" body-style="padding: 0">
            <span class="dark-mode-font" style="line-height: 27px;font-size: 13px;color: #FFFFFF">4小时爆仓统计</span>
          </el-card>
          <el-card class="black-card" style="height: 75px;border-radius: 0 0 3px 3px" body-style="padding: 0">
            <span class="dark-mode-font" style="font-size: 22px;line-height: 75px;color: rgb(246, 70, 93)">${{ Number.prototype.toFormatNum(liquidation.h4TotalVolUsd.toFixed(0)) }}</span>
          </el-card>
        </el-col>
        <el-col :span="12" style="padding: 0 0 0 3px">
          <el-card class="black-card" style="height: 27px;background-color: #454c56; border-radius: 3px 3px 0 0" body-style="padding: 0">
            <span class="dark-mode-font" style="line-height: 27px;font-size: 13px;color: #FFFFFF">24小时爆仓统计</span>
          </el-card>
          <el-card class="black-card" style="height: 75px;border-radius: 0 0 3px 3px" body-style="padding: 0">
            <span class="dark-mode-font" style="font-size: 22px;line-height: 75px;color: rgb(246, 70, 93)">${{ Number.prototype.toFormatNum(liquidation.h24TotalVolUsd.toFixed(0)) }}</span>
          </el-card>
        </el-col>
      </el-row>
      <el-row style="margin-top: 25px">
        <future-data style="height: 125px;width: 100%"></future-data>
      </el-row>
      <el-row style="margin-top: 25px">
        <option-data style="height: 560px;width: 100%"></option-data>
      </el-row>
      <el-row style="margin-top: 25px">
        <el-col :span="24" style="padding: 0">
          <el-card class="black-card" body-style="padding: 0" style="width: 100%;border-radius: 3px 3px 0 0;border-bottom: 0;background-color: #454c56">
            <el-row>
              <el-col :span="4">
                <span class="dark-mode-font" style="color: white;float: left;margin-left: 10px;font-size: 12px;line-height: 25px">OKX</span>
              </el-col>
              <el-col :span="5" v-for="head in okxFuturesType" :key="head">
                <span class="dark-mode-font title-right ellipsis-line-strategy small-font">{{ head }}</span>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="black-card" style="width: 100%;height: 300px;border-radius: 0 0 3px 3px;overflow-y: auto" body-style="padding: 0">
            <el-skeleton animated :loading="renderingOkx" :throttle="200">
              <template slot="template">
                <trialpha-sk :style="'margin-top: 25px;width: 60%;float: left;margin-left:10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 50%;float: left;margin-left:10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 50%;float: left;margin-left:10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 60%;float: left;margin-left:10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 90%;float: left;margin-left:10px;margin-bottom: 20px'"></trialpha-sk>
              </template>
              <template>
                <el-card class="black-card-hover" body-style="padding: 0" style="border-top: 0;border-left: 0;border-right: 0;height: 25px;border-radius: 0" v-for="item in okx" :key="item">
                  <el-row>
                    <el-col :span="4">
                      <span class="dark-mode-font ellipsis-line-strategy" style="float: left;margin-left: 10px;font-size: 12px;line-height: 25px">{{ item.replace('-USD', '-C').replace('-CT', '-U') }}</span>
                    </el-col>
                    <el-col :span="5" v-for="head in okxFuturesType" :key="item + '-' + head">
                      <span class="dark-mode-font title-right small-font" :style="'line-height: 25px;' + (okxAllPrice[item + '-' + head] > okxAllPrice[item + '-SWAP'] ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ ((((okxAllPrice[item + '-' + head] - okxAllPrice[item + '-SWAP']) / okxAllPrice[item + '-SWAP']) * 100).toFixed(2) + '%').replace('NaN%', '-') }}</span>
                    </el-col>
                  </el-row>
                </el-card>
              </template>
            </el-skeleton>
          </el-card>
        </el-col>
      </el-row>

      <!--
      <el-row style="margin-top: 20px">
        <el-col :span="24" style="padding: 0">
          <el-card class="black-card" body-style="padding: 0" style="width: 100%;border-radius: 3px 3px 0 0;border-bottom: 0;background-color: #454c56">
            <el-row>
              <el-col :span="3">
                <span class="dark-mode-font" style="color: white;float: left;margin-left: 10px;font-size: 12px;line-height: 25px">FTX</span>
              </el-col>
              <el-col :span="7" v-for="head in ftxFuturesType" :key="head">
                <span class="dark-mode-font title-right ellipsis-line-strategy small-font">{{ head }}</span>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="black-card" style="width: 100%;height: 300px;border-radius: 0 0 3px 3px;overflow-y: auto;" body-style="padding: 0">
            <el-skeleton animated :loading="renderingFtx" :throttle="200">
              <template slot="template">
                <trialpha-sk :style="'margin-top: 25px;width: 60%;float: left;margin-left:10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 50%;float: left;margin-left:10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 50%;float: left;margin-left:10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 60%;float: left;margin-left:10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 90%;float: left;margin-left:10px;margin-bottom: 20px'"></trialpha-sk>
              </template>
              <template>
                <el-card class="black-card-hover" body-style="padding: 0" style="border-top: 0;border-left: 0;border-right: 0;height: 25px;border-radius: 0" v-for="item in ftx" :key="item">
                  <el-row>
                    <el-col :span="3">
                      <span class="dark-mode-font ellipsis-line-strategy" style="float: left;margin-left: 10px;font-size: 12px;line-height: 25px">{{ item }}</span>
                    </el-col>
                    <el-col :span="7" v-for="head in ftxFuturesType" :key="item + '-' + head">
                      <span class="dark-mode-font title-right small-font" :style="'line-height: 25px;' + (ftxAllPrice[item + '-' + head] > ftxAllPrice[item + '-PERP'] ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ ((((ftxAllPrice[item + '-' + head] - ftxAllPrice[item + '-PERP']) / ftxAllPrice[item + '-PERP']) * 100).toFixed(2) + '%').replace('NaN%', '-') }}</span>
                    </el-col>
                  </el-row>
                </el-card>
              </template>
            </el-skeleton>
          </el-card>
        </el-col>
      </el-row>
      -->

      <el-row style="margin-top: 20px">
        <el-col :span="24" style="padding: 0">
          <el-card class="black-card" body-style="padding: 0" style="width: 100%;border-radius: 3px 3px 0 0;border-bottom: 0;background-color: #454c56">
            <el-row>
              <el-col :span="8">
                <span class="dark-mode-font ellipsis-line-strategy" style="color: white;float: left;margin-left: 10px;font-size: 12px;line-height: 25px">DERIBIT</span>
              </el-col>
              <el-col :span="2" v-for="head in deribitFuturesType" :key="head">
                <span class="dark-mode-font title-right ellipsis-line-strategy small-font">{{ head }}</span>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="black-card" style="width: 100%;height: 100px;border-radius: 0 0 3px 3px;overflow-y: auto;" body-style="padding: 0">
            <el-skeleton animated :loading="renderingDeribit" :throttle="200">
              <template slot="template">
                <trialpha-sk :style="'margin-top: 20px;width: 60%;float: left;margin-left:10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left:10px'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 90%;float: left;margin-left:10px;margin-bottom: 20px'"></trialpha-sk>
              </template>
              <template>
                <el-card class="black-card-hover" body-style="padding: 0" style="border-top: 0;border-left: 0;border-right: 0;height: 25px;border-radius: 0" v-for="item in deribit" :key="item">
                  <el-row>
                    <el-col :span="8">
                      <span class="dark-mode-font ellipsis-line-strategy" style="float: left;margin-left: 10px;font-size: 12px;line-height: 25px">{{ item }}</span>
                    </el-col>
                    <el-col :span="2" v-for="head in deribitFuturesType" :key="item + '-' + head">
                      <span class="dark-mode-font title-right small-font" :style="'line-height: 25px;' + (deribitAllPrice[item + '-' + head] > deribitAllPrice[item + '-PERPETUAL'] ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{ ((((deribitAllPrice[item + '-' + head] - deribitAllPrice[item + '-PERPETUAL']) / deribitAllPrice[item + '-PERPETUAL']) * 100).toFixed(2) + '%').replace('NaN%', '-') }}</span>
                    </el-col>
                  </el-row>
                </el-card>
              </template>
            </el-skeleton>
          </el-card>
        </el-col>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import TradeApiService from "@/api/trade/TradeApiService";
import DataApiService from "@/api/trade/DataApiService";
import OptionData from "@/pages/controlPanel/analysis/OptionData";
import FutureData from "@/pages/controlPanel/analysis/FutureData";
import TrialphaSk from "@/components/TrialphaSk";
import FundingFeeReport from "@/pages/controlPanel/analysis/FundingReport";

export default {
  name: "DataMonitorMedium",
  components: {FundingFeeReport, TrialphaSk, FutureData, OptionData},
  data: function () {
    return {
      search: '',
      okx: [],
      okxFuturesType: [],
      okxAllPrice: {},
      renderingOkx: true,
      ftx: [],
      ftxFuturesType: [],
      ftxAllPrice: {},
      renderingFtx: true,
      binance: [],
      binanceFuturesType: [],
      binanceAllPrice: {},
      renderingBinance: true,
      deribit: [],
      deribitFuturesType: [],
      deribitAllPrice: {},
      renderingDeribit: true,
      allFundingFee: {},
      liquidation: {
        h4TotalVolUsd: 0,
        h24TotalVolUsd: 0
      },
      timeInterval: {},
      typeFilter: 'FUTURE'
    }
  },
  created() {
    this.loadingFunding(()=>{
      this.renderingBinance = true;
      this.renderingOkx = true;
      this.renderingFtx = true;
      this.renderingDeribit = true;
      this.loadingBinance();
      this.loadingOkx();
      // this.loadingFtx();
      this.loadingDeribit();
    });
    this.loadingLiquidation();
    this.timeInterval = setInterval(() => {
      this.loadingFunding(()=>{
        this.loadingBinance();
        this.loadingOkx();
        // this.loadingFtx();
        this.loadingDeribit();
      });
      this.loadingLiquidation();
    }, 36 * 1000);
  },
  destroyed: function () {
    clearInterval(this.timeInterval);
  },
  methods: {
    report() {
      this.$nextTick(() => {
        this.$refs['funding-fee-report'].render(this.allFundingFee);
      });
    },
    typeChanged: function () {
      this.typeFilter = this.typeFilter === 'ALL' ? 'FUTURE' : 'ALL';
      this.loadingFunding(()=>{
        this.renderingBinance = true;
        this.renderingOkx = true;
        this.renderingFtx = true;
        this.renderingDeribit = true;
        this.loadingBinance();
        this.loadingOkx();
        // this.loadingFtx();
        this.loadingDeribit();
      });
    },
    okxFilter: function (item) {
      if(this.search === '' || item.replace('-USD', '-C').replace('-CT', '-U').indexOf(this.search.toUpperCase()) > -1) {
        return item;
      }
    },
    ftxFilter: function (item) {
      if (this.search === '' || item.indexOf(this.search.toUpperCase()) > -1) {
        return item;
      }
    },
    deribitFilter: function (item) {
      if (this.search === '' || item.indexOf(this.search.toUpperCase()) > -1) {
        return item;
      }
    },
    binanceFilter: function (item) {
      if (this.search === '' || item.indexOf(this.search.toUpperCase()) > -1) {
        return item;
      }
    },
    loadingOkx: function () {
      let that = this;
      let tmpOkx = [];
      let futuresType = []
      TradeApiService.getAllPrice('OKX', 'SWAP', function (swap) {
        TradeApiService.getAllPrice('OKX', 'FUTURES', function (future) {
          that.okxAllPrice = Object.assign(swap, future);
          for (const futureKey in future) {
            let coin = futureKey.split('-')[0] + '-' + futureKey.split('-')[1];
            let futureType = futureKey.split('-')[2];
            if (futuresType.indexOf(futureType) === -1) {
              futuresType.push(futureType);
            }
            if (tmpOkx.indexOf(coin) === -1) {
              tmpOkx.push(coin);
            }
          }
          if (that.typeFilter === 'ALL') {
            for (const futureKey in swap) {
              let coin = futureKey.split('-')[0] + '-' + futureKey.split('-')[1];
              if (tmpOkx.indexOf(coin) === -1) {
                tmpOkx.push(coin);
              }
            }
          }
          that.okxFuturesType = futuresType;
          that.okx = tmpOkx;
          that.renderingOkx = false;
        });
      });
    },
    loadingBinance: function () {
      let that = this;
      let tmp = [];
      let futuresType = [];
      TradeApiService.getAllPrice('BINANCE', 'UC', function (allPrice) {
        that.binanceAllPrice = allPrice;
        for (const key in allPrice) {
          if (key.indexOf('_') > -1) {
            let coin = key.split('_')[0].replaceAll('USDT', '').replaceAll('BUSD', '');
            let futureType = key.split('_')[1];
            if (futuresType.indexOf(futureType) === -1) {
              futuresType.push(futureType);
            }
            if (tmp.indexOf(coin) === -1) {
              tmp.push(coin);
            }
          } else {
            if (that.typeFilter === 'ALL') {
              let coin = key.replaceAll('USDT', '').replaceAll('BUSD', '');
              if (tmp.indexOf(coin) === -1 && coin !== 'BTC' && coin !== 'ETH') {
                tmp.push(coin);
              }
            }
          }
        }
        that.binanceFuturesType = futuresType;
        that.binance = tmp;
        that.renderingBinance = false;
        // that.indexCalc();
      });
    },
    loadingFtx: function () {
      let that = this;
      let tmp = [];
      let futuresType = [];
      TradeApiService.getAllPrice('FTX', 'ALL', function (allPrice) {
        that.ftxAllPrice = allPrice;
        for (const key in allPrice) {
          let coin = key.split('-')[0];
          let futureType = key.split('-')[1];
          if (key.split('-').length === 2 && futureType !== 'PERP' && futuresType.indexOf(futureType) === -1) {
            futuresType.push(futureType);
          }
          if (that.typeFilter === 'ALL') {
            if (key.split('-').length === 2 && tmp.indexOf(coin) === -1) {
              tmp.push(coin);
            }
          } else {
            if (key.split('-').length === 2 && futureType !== 'PERP' && tmp.indexOf(coin) === -1) {
              tmp.push(coin);
            }
          }
        }
        that.ftxFuturesType = futuresType;
        that.ftx = tmp;
        that.renderingFtx = false;
        // that.indexCalc();
      });
    },
    loadingDeribit: function () {
      let that = this;
      let tmp = ['BTC', 'ETH', 'SOL'];
      let futuresType = [];
      TradeApiService.getAllPrice('DERIBIT', 'SOL', function (SOL) {
        TradeApiService.getAllPrice('DERIBIT', 'ETH', function (ETH) {
          TradeApiService.getAllPrice('DERIBIT', 'BTC', function (BTC) {
            let tmpMap = Object.assign(ETH, SOL);
            that.deribitAllPrice = Object.assign(tmpMap, BTC);
            for (const futureKey in that.deribitAllPrice) {
              let futureType = futureKey.split('-')[1];
              if (futureKey.split('-').length === 2 && futureType !== 'PERPETUAL' && futuresType.indexOf(futureType) === -1) {
                futuresType.push(futureType);
              }
            }
            that.deribitFuturesType = futuresType;
            that.deribit = tmp;
            that.renderingDeribit = false;
          });
        });
      });
    },
    loadingFunding: function (callback) {
      let that = this;
      DataApiService.getFunding(function (data) {
        that.allFundingFee = data;
        callback();
      });
    },
    loadingLiquidation: function () {
      let that = this;
      DataApiService.getLiquidation(function (data) {
        that.liquidation = data.data.info;
      });
    },
    getFundingFee(platform, time, coin, type) {
      let fee = this.allFundingFee[platform + time][coin + '_' + type];
      if (fee) {
        return Number(fee.toFixed(3));
      }
      return '0';
    },
    indexCalc() {
      let that = this;
      let index = 'ALT';
      let time = '1YEAR';
      let exchPart = {
        BCH:	0.325,
        BNB:	2.565,
        EOS:	16.508,
        ETH:	0.268,
        LTC:	1.154,
        XRP:	431.691,
        TRX:	1250.901,
        DOT:	16.283,
        LINK:	6.785,
        ADA:	543.475
      }
      TradeApiService.getAllPrice('FTX', 'ALL', function (allPrice) {
        let totalUsd = 0;
        let combFee = 0;
        for (const exchPartKey in exchPart) {
          totalUsd += allPrice[exchPartKey + '-PERP'] * exchPart[exchPartKey];
          combFee += that.getFundingFee('FTX', time, exchPartKey, 'U') * (((allPrice[exchPartKey + '-PERP'] * exchPart[exchPartKey]) / allPrice[index + '-PERP']));
        }
        console.log('COMB: ' + totalUsd);
        console.log('EXCH: ' + allPrice[index + '-PERP']);
        console.log('COMB_FEE: ' + (that.getFundingFee('FTX', time, index, 'U') - combFee));
      });
    }
  }
}
</script>

<style scoped>

.ellipsis-line-strategy {
  padding: 0;
  width: 90%;
  float: left;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.title-right {
  font-size: 12px;
  line-height: 25px;
  display: inline-block;
  text-align: right;
  float: right;
  margin-right: 10px
}

@media only screen and (min-width: 992px) {
  .hidden-more-than-992 {
    display: none !important
  }
}

@media only screen and (max-width: 992px) {
  .hidden-less-than-992 {
    display: none !important
  }
}

.small-font {
  width: 90%;
  line-height: 25px;
  font-size: 12px;
  -webkit-transform: scale(1);
  text-align: right;
  display: inline-block;
  float: right;
  margin-right: 10px;
  color: #FFFFFF
}

@media only screen and  (max-width: 768px) {
  .small-font {
    width: 120%;
    line-height: 25px;
    font-size: 12px;
    -webkit-transform: scale(0.75);
    text-align: right;
    display: inline-block;
    float: right;
    margin-right: -5px;
    color: #FFFFFF
  }
}

.dark-mode-input-search>>>.el-input__inner{
  color: white;
  height: 25px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-radius: 3px;
  outline: none;
  padding: 0 8px 0 8px;
  line-height: 25px;
}

</style>