<template>
  <div id="total-container" class="page-container" style="height: 100%">
    <b-navbar id="ABM_NAV" class="" toggleable="lg" type="dark" variant="dark" :style="'position: fixed;height:60px;width: 100%;top:0px;border-bottom: lightgray 0 solid;z-index: 100;padding-top:0;padding-bottom:0'">
      <b-navbar-brand :href="controlPanelHome">
        <div style="display: flex">
          <div class="bit-maker-logo d-inline-block align-top"></div>
          <div class="bit-maker-text d-inline-block align-top"></div>
        </div>
      </b-navbar-brand>
      <b-navbar-toggle class="hidden-sm-and-down" target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" class="hidden-sm-and-down" is-nav>
        <b-navbar-nav class="ml-auto" style="margin-right: 0" type="dark" variant="dark">
          <el-button v-if="!connectWallet" class="dark-link" style="height: 35px;margin-top: 12px;font-size: 16px" @click="connect">Connect to wallet<div style="width: 8px;height: 8px;background-color: red;border-radius: 50px;position: absolute;margin-top: -5px;margin-left: -3px;"></div></el-button>
          <div v-if="connectWallet" style="float: right;height: 35px;margin-top: 13px;font-size: 16px;color: white;padding: 0;display: flex;margin-right: 10px">
            <img src="https://abm-app-image.s3.ap-northeast-1.amazonaws.com/oneport/MetaMask_Fox.png" alt="Metamask" style="height: 32px;width: 32px;float: left;margin-top: 3px;margin-right: 6px" />
            <div style="float: left;margin-top: -2px">
              <span style="display: inline-block;font-size: 12px;font-family: 'Montserrat', sans-serif;font-weight: 600;color: #cecece">{{ addr.substring(0, 16) }}......{{ addr.substring(addr.length - 4, addr.length) }}</span>
              <div style=";position: absolute;margin-top: 0;display: flex;">
                <div style="width: 8px;height: 8px;background-color: #24a424;margin-left: 0;border-radius: 50px;float: left;margin-top: 1px"></div>
                <span style="display: inline-block;font-size: 12px;font-family: 'Montserrat', sans-serif;font-weight: 600;float: right;margin-top: -4px;margin-left: -8px;-webkit-transform: scale(0.76);padding-left: 0;color: #cecece">{{ walletInfo.networkName.toUpperCase() }} : {{ walletInfo.balance }} {{ walletInfo.networkSymbol }}</span>
              </div>
            </div>
          </div>
          <b-nav-item-dropdown class="border-0" text="App" right style="margin-right: 10px;margin-top: 12px;width: 100%;background-color: #343a40;border: 0;outline: none" type="dark" variant="dark" no-caret>
            <b-dropdown-item href="#" v-on:click="iosPage">IOS</b-dropdown-item>
            <b-dropdown-item href="https://abm-app-image.s3.ap-northeast-1.amazonaws.com/download/ForthTech.apk">Android</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown class="border-0" :text="this.$i('lang')" right style="margin-right: 10px;margin-top: 12px;width: 100%;background-color: #343a40;border: 0;outline: none" type="dark" variant="dark" no-caret>
            <b-dropdown-item href="#" v-on:click="updateLang('China')">{{ $i('lang_china') }}</b-dropdown-item>
            <b-dropdown-item href="#" v-on:click="updateLang('English')">{{ $i('lang_english') }}
            </b-dropdown-item>
            <b-dropdown-item href="#" v-on:click="updateLang('Japan')">{{ $i('lang_japan') }}</b-dropdown-item>
            <b-dropdown-item href="#" v-on:click="updateLang('Korea')">{{ $i('lang_korea') }}</b-dropdown-item>
            <b-dropdown-item href="#" v-on:click="updateLang('Thailand')">{{ $i('lang_thailand') }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown class="hidden-md-and-up border-0" menu-class="w-100" :text="$i('navBars.navigation')" right :hidden=!isLogin style="background-color: #343a40;width: 100%;outline: none">
            <b-dropdown-item @click="transTo(controlPanelHome)">{{ $i('navBars.controlPanel') }}</b-dropdown-item>
            <b-dropdown-item @click="transTo(accountMgnt)">{{ $i('manageAccount') }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown no-caret class="login-comp border-0" right :hidden=!isLogin style="background-color: #343a40;width: 100%;height: 60px;border: 0;outline: none">
            <template v-slot:button-content>
              <el-avatar :size="40" :src="avatar" @error="true" :key="avatar" fit="fill" style="margin-top: 3px;background-color: rgba(0, 0, 0, 0)">
                <el-avatar :size="40" style="background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%);font-size: 18px">
                  <span style="padding-top: -5px"> {{ username ? username.substr(0, 1).toUpperCase() : '' }} </span>
                </el-avatar>
              </el-avatar>
            </template>
            <b-dropdown-item v-on:click="lock()">{{ $i('lock.lock') }}</b-dropdown-item>
            <b-dropdown-item v-on:click="userInfoChange()">{{ $i('login.changePwd') }}</b-dropdown-item>
<!--            <b-dropdown-item v-on:click="openGPT()">FT Chat GPT</b-dropdown-item>-->
            <b-dropdown-item v-on:click="logout()">{{ $i('login.logout') }}</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <div style="width: 100%;height: 100vh;flex-direction: row;background-color: #1c2128;position: relative">
      <el-menu id="system-nav" v-if="currentPath !== '/controlPanel/aggregateTransactions'" class="hidden-sm-and-down" :collapse="true" :background-color="that.$c('mainColor')"
               :style="'z-index:99;float: left;top: 0;bottom: 0;margin: auto;height: ' + (navBar.length * 56) + 'px;position: fixed;outline:none;border:0 solid'"
               :default-active="currentPath" :router="true">
        <el-menu-item v-for="nav in navBar" :index="nav.path" :key="nav.index" @select="window.location=nav.path" style="outline: none">
          <i :class="nav.icon"></i>
          <span slot="title">{{ that.$i(nav.displayNameKey) }}</span>
        </el-menu-item>
      </el-menu>

      <div style="width: 100%;margin-left: 0;padding-top: 60px;overflow-x: hidden">
        <router-view id="trialpha-router-view-page" class="trialpha-full-sub-page"></router-view>
      </div>
    </div>


    <el-menu
        :default-active="$route.path"
        class="el-menu-demo hidden-md-and-up"
        mode="horizontal"
        :router="true"
        background-color="#343a40"
        text-color="#fff"
        active-text-color="#3f9eff" style=";position: absolute;bottom: 0;width:100%;border-radius: 0;border-left: 0;border-right: 0;border-bottom: 0;height: 60px;z-index:10">
      <el-menu-item v-for="mN in mobileNavBars" :key="mN.index" :value="mN.index" :index="mN.path" :style="'font-size: 12px;width: ' + (100 / mobileNavBars.length) + '%;text-align: center;padding: 1px'"><i :class="mN.icon" style="margin-left: 3px;margin-bottom: 18px;text-align: center"/></el-menu-item>
    </el-menu>

    <el-menu
        v-if="currentPath === '/controlPanel/aggregateTransactions'"
        :default-active="$route.path"
        class="el-menu-demo hidden-sm-and-down"
        mode="horizontal"
        :router="true"
        background-color="#343a40"
        text-color="#fff"
        active-text-color="#3f9eff" style=";position: absolute;bottom: 0;width:100%;border-radius: 0;border-left: 0;border-right: 0;border-bottom: 0;z-index:10;margin-bottom: 22px">
      <el-menu-item v-for="mN in navBar" :key="mN.index" :value="mN.index" :index="mN.path" :style="'font-size: 12px;width: ' + (100 / navBar.length) + '%;height: 40px'"><i :class="mN.icon" style="margin-bottom: 22px"/></el-menu-item>
    </el-menu>

    <user-info-change ref="user-info-change"></user-info-change>
    <ft-g-p-t ref="open-ft-gpt"></ft-g-p-t>
    <ios-download ref="iosPage"></ios-download>
    <price-info class="hidden-sm-and-down"/>
    <meta-mask-utils ref="metamask" :initConnect="false" @connected="connectedWallet" @walletChange="walletChange"></meta-mask-utils>
  </div>
</template>

<script>
import RouterPath from "@/router/RouterPath";
import UserService from "@/api/user/UserService";
import CacheService from "@/cache/CacheService";
import NavService from "@/const/NavService"
import PriceInfo from "@/components/PriceInfo";
import IosDownload from "@/components/IosDownloadPage";
import Optional from "@/optional/Optional";
import UserPermissionService from "@/api/user/UserPermissionService";
import SymbolNameService from "@/utils/SymbolInfoService";
import UserInfoChange from "@/pages/controlPanel/user/UserInfoUpdateDrawer";
import MetaMaskUtils from "@/web3/MetaMaskUtils";
import Web3Service from "@/web3/Web3Service";
import SymbolFundingFeeService from "@/utils/SymbolFundingFeeService";
import MoralisService from "@/web3/moralis/MoralisService";
import FtGPT from "@/pages/controlPanel/user/FtGPTDrawer";

export default {
  components: {FtGPT, MetaMaskUtils, UserInfoChange, IosDownload, PriceInfo},
  data: function () {
    return {
      that: this,
      isLogin: UserService.isLogin(),
      username: UserService.getUserName(),
      isCollapse: true,
      sideBarClosed: false,
      isControlPanel: window.location.pathname.indexOf(RouterPath.router.controlPanel) > -1,
      currentPath: window.location.pathname.replace(RouterPath.basic, ''),
      navBar: NavService.filterNavBars(),
      controlPanelHome: RouterPath.router.controlPanelChild.controlPanelHome,
      accountMgnt: RouterPath.router.controlPanelChild.accountMgnt,
      mobileUserConfigMgnt: RouterPath.router.controlPanelChild.mobileUserConfigMgnt,
      mobileNavBars: NavService.mobileNavBars,
      active: 0,
      staySecond: 0,
      stayTimer: null,
      avatar: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/web/avatar/' + UserService.getUserObj().userInfo.id + '-avatar.png',
      x: 0,
      y: 0,
      checkX: -1,
      checkY: -1,
      silentSecond: 0,
      silentTimer: {},
      webPermissionConfigTimer: {},
      addr: '',
      connectWallet: false,
      walletInfo: {
        networkName: 'Ethereum',
        networkSymbol: 'ETH',
        balance: 0.0
      }
    }
  },
  watch: {
    $route() {
      this.currentPath = window.location.pathname.replace(RouterPath.basic, '');
    }
  },
  mounted() {
    this.init();
    Web3Service.initWeb3();
    Web3Service.initWalletInfo(this, (result) => {
      this.walletInfo = {
        networkName: result.networkName,
        networkSymbol: result.networkSymbol,
        balance: result.balance
      };
    });
    MoralisService.initMoralis();
  },
  created: function () {
    let that = this;
    Web3Service.web3LoginCheck(that);
    document.onmousemove = (event) => {
      let x1 = event.clientX;
      let y1 = event.clientY;
      this.x = x1;
      this.y = y1;
    }
    document.addEventListener("visibilitychange", function () {
      if (!document.hidden) {
        that.startTimer();
      } else {
        that.resetTimer();
      }
    });
    document.body.addEventListener('mouseenter', function () {
      that.startTimer();
    });
    document.body.addEventListener('mouseleave', function () {
      that.resetTimer();
    });
    this.setActiveIndex();
    this.silentTimer = setInterval(() => {
      this.checkMouseMove();
    }, 1000);
    this.webPermissionConfigTimer = setInterval(() => {
      UserPermissionService.syncWebPermissionConfig();
    }, 1000 * 28);
  },
  beforeDestroy() {
    clearInterval(this.silentTimer);
    clearInterval(this.stayTimer);
    clearInterval(this.webPermissionConfigTimer);
  },
  methods: {
    init() {
      //初始化一些必要的数据
      SymbolNameService.init();
      SymbolFundingFeeService.init();
    },
    connect() {
      this.$refs['metamask'].render();
    },
    connectedWallet(address) {
      if (address === 'NULL') {
        this.addr = '';
        this.connectWallet = false;
      } else {
        this.addr = address;
        this.connectWallet = true;
      }

    },
    walletChange(info) {
      this.walletInfo = info;
    },
    checkMouseMove() {
      if (this.checkX !== this.x || this.checkY !== this.y) {
        this.checkX = this.x;
        this.checkY = this.y;
        this.silentSecond = 0;
      } else {
        this.silentSecond += 1;
      }
      if (this.silentSecond > Optional.orElse(() => {
        return UserService.getUserObj().lockWaitSecond;
      }, 60 * 5) && this.x !== 0 && this.y !== 0) {
        this.lock();
      }
    },
    lock: function () {
      let user = UserService.getUserObj();
      user.isLock = true;
      UserService.updateUserObj(user);
      location.reload(true);
    },
    iosPage: function () {
      this.$refs['iosPage'].openDrawer();
    },
    userInfoChange: function () {
      this.$refs['user-info-change'].openDrawer();
    },
    openGPT: function () {
      this.$refs['open-ft-gpt'].openDrawer();
    },
    startTimer() {
      if (this.stayTimer === null) {
        let that = this;
        this.stayTimer = setInterval(() => {
          that.staySecond += 1;
          if (that.staySecond >= 59) {
            UserService.statistics(this.staySecond);
            that.staySecond = 0;
          }
        }, 1000);
      }
    },
    resetTimer() {
      if (this.staySecond > 0) {
        UserService.statistics(this.staySecond);
      }
      this.staySecond = 0;
      clearInterval(this.stayTimer);
      this.stayTimer = null;
    },
    mouseEnter() {
      this.startTimer();
    },
    mouseLeave() {
      this.resetTimer();
    },
    setActiveIndex() {
      let that = this;
      NavService.mobileNavBars.forEach((nav) => {
        if (nav.path === window.location.pathname.replace(RouterPath.basic, '')) {
          that.active = nav.index;
        }
      });
    },
    logout: function () {
      this.isLogin = false;
      this.$message(this.$i('login.logoutSuccess'));
      let that = this;
      UserService.logout(() => {
        CacheService.clear();
        that.$router.push({path: RouterPath.router.login});
      }, () => {
        CacheService.clear();
        that.$router.push({path: RouterPath.router.login});
      });
    },
    updateLang: function (lang) {
      this.setLanguage(lang);
    },
    transTo: function (path) {
      this.$router.push({
        path: path
      });
    }
  }
}
</script>

<style scoped lang="css">

.page-container {
  position: relative;
  min-height: 100vh;
  overflow-y: hidden;
}

.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 40px;
}

#mobile-n .mu-tab {
  color: #adbac7;
  text-decoration: none;
}

#mobile-n .mu-tab:hover {
  color: white;
  text-decoration: none;
}

#mobile-n .mu-tabs {
  color: #ffffff;
  text-decoration: none;
}

#mobile-n .mu-tabs:hover {
  color: white;
  text-decoration: none;
}

#mobile-n .mu-tab.exact-active.m-active.mu-tab-active {
  color: white;
  text-decoration: none;
}

.m-active {
  color: white;
  text-decoration: none;
}

.m-active:hover {
  color: white;
  text-decoration: none;
}

.m-active:active {
  color: white;
  text-decoration: none;
}

@media only screen and (max-width: 990px) {
  body::-webkit-scrollbar {
    display: none;
  }
}

.small-title-m {
  font-size: 12px;
  -webkit-transform: scale(0.75);
}

.el-menu {
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}

li a {
  border: 0;
  outline: none;
}

</style>