<template>
  <div>
    <el-card class="trialpha-sk-card" style="width: 100%;height: 8px;border-radius: 25px;border: 0"></el-card>
  </div>
</template>

<script>
export default {
  name: "TrialphaSk",
  props: {
    width: String
  }
}
</script>

<style scoped>
.trialpha-sk-card {
  background: linear-gradient(90deg,#343a40 25%, rgba(91, 91, 91, 0.58) 37%,#343a40 63%);
  background-size: 400% 100%;
  -webkit-animation: el-skeleton-loading 1.68s ease infinite;
  animation: el-skeleton-loading 1.68s ease infinite;
}
</style>