<template>
  <div>
    <el-row style="margin-top: 5px;margin-bottom: 20px;">
      <mavon-editor ref=md @imgAdd="addImage" @imgDel="imgDel" v-model="content" :ishljs="true" :html="false" default-open="preview" :xssOptions="false" @save="saveDesc" style="height: 518px"></mavon-editor>
      <el-button class="dark-mode-btn" size="small" style="margin-top: 18px;width: 100%" @click="saveDesc" :disabled="loading">保存</el-button>
    </el-row>
  </div>
</template>

<script>

import {AwsTracupUpdate} from "@/utils/UploadFile";
import AbmNet from "@/api/net/AbmNet";
import ServiceMapper from "@/const/ServiceMapper";
import {Message} from "element-ui";

export default {
  name: "AccountPlan",
  data: function () {
    return {
      instance: {},
      loading: true,
      content: ''
    }
  },
  methods: {
    addImage: function (pos, $file) {
      AwsTracupUpdate($file).then(res => {
        this.$refs.md.$img2Url(pos, res.Location);
      });
    },
    imgDel: function () {

    },
    saveDesc: function () {
      let that = this;
      that.loading = true;
      AbmNet.postRequest(ServiceMapper.WEB_SERVICE.accountPlanUpdate, {
        instanceId: this.instance.instanceId,
        content: this.content
      }, function () {
        Message.success('操作成功');
        that.loading = false;
      }, function () {
        that.loading = false;
      });
    },
    render: function (instance) {
      this.instance = instance;
      let that = this;
      that.loading = true;
      that.content = '**Loading...**';
      AbmNet.getRequest(ServiceMapper.WEB_SERVICE.accountPlanView + "?instanceId=" + this.instance.instanceId, function (data) {
        that.content = data.content;
        that.loading = false;
      });
    }
  }
}
</script>

<style>

.msg-area textarea {
  border: #444c56 1px solid;
  font-size: 14px!important;
  font-family: "DIN-Bold", "Microsoft YaHei", "黑体", "宋体", sans-serif;
  color: #c5b6b6 !important;
  background-color: #22272e!important;
}

</style>