<template>
  <div>
    <el-row>
      <el-col :span="20">
        <el-button-group style="float: left;margin-top: 10px">
          <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 118px;height: 32px;text-align: center" v-for="day in Object.keys(FUNDING_DAYS)" :key="day" :disabled="FUNDING_DAYS[day] === showFeeDays" @click="showFeeDays = FUNDING_DAYS[day]">{{ day.replaceAll('_', ' ') }}</el-button>
        </el-button-group>
      </el-col>
      <el-col :span="4" style="padding-top: 12px">
        <input class="dark-mode-input" v-model="filterCoinName" placeholder="Filter..." style="color: white;height: 25px;outline: none;font-size: 12px;background-color: rgba(0, 0, 0, 0);border: 0;width: 128px;float: right;border-bottom: 1px solid #444c56;padding-left: 18px;padding-bottom: 1px" />
        <i class="el-icon-search" style="float: right;color: #444c56;margin-top: 5px;margin-right: -15px"></i>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="20">
        <el-checkbox-group style="float: left;margin-top: 10px" v-model="platformDisplayConfig" @change="changeDisplayConfig">
          <el-checkbox :label=p v-for="p in allPlatforms" :key="p" ></el-checkbox>
        </el-checkbox-group>
      </el-col>
    </el-row>
    <el-row style="margin-top: 0;border-bottom: 1px solid #444c56;">
      <el-col :style="'width: ' + (100 / ((platforms).length + 1)) + '%;padding-left: 10px'">
        <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 13px;color:white;cursor: pointer" @click="sortCoinList('coinName')">Coins<i class="el-icon-d-caret"></i></span>
      </el-col>
      <el-col v-for="p in platforms" :key="p.platform" :style="'width: ' + (100 / (platforms.length + 1)) + '%;padding-right: 10px'">
        <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 13px;color:white;cursor: pointer" @click="sortCoinListByFeeValue(p.platform)">{{ p.platform }}<i class="el-icon-d-caret" v-if="p.platform !== 'DIFF'"></i></span>
        <i :class="{'el-icon-s-help': platformFilter[p.platform], 'el-icon-help': !platformFilter[p.platform]}" style="float: right;color: gray;font-size: 15px;margin-right: 5px;margin-top: 3px;cursor: pointer" @click="changePlatformFilter(p.platform)" v-if="p.platform !== 'DIFF'"></i>
      </el-col>
    </el-row>
    <el-card class="black-card" style="height: 495px;overflow-y: auto;border: 0" body-style="padding: 0" shadow="none">
      <el-row class="black-card-hover" style="height: 30px;line-height: 30px;border-top: 0" v-for="coin in coinList.filter((c) => doPlatformFilter(c))" :key="coin">
        <el-col :style="'width: ' + (100 / ((platforms).length + 1)) + '%;padding-left: 10px'">
          <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{ coin }}</span>
        </el-col>
        <el-col v-for="p in platforms" :key="p.platform" :style="'width: ' + (100 / (platforms.length + 1)) + '%;padding-right: 10px'">
        <span class="dark-mode-font" v-if="p.platform !== 'DIFF'"
              :style="'display: inline-block;float: right;text-align: right;font-size: 12px;color:' + getFundingFeeColor(coin, p.platform)"
        >{{ (fundingFeeMap[coin] && fundingFeeMap[coin][p.platform] && fundingFeeMap[coin][p.platform][showFeeDays]) ? (fundingFeeMap[coin][p.platform][showFeeDays] + '%') : '-' }}</span>
          <span class="dark-mode-font" v-if="p.platform === 'DIFF'" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white"
          >{{ getFundingFeeDiff(coin) }}</span>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import SymbolFundingFeeService from "@/utils/SymbolFundingFeeService";
import Optional from "@/optional/Optional";
import GeneralApiService from "@/api/trade/GeneralApiService";

export default {
  name: "FeeAnalysisV2",
  data: function () {
    return {
      filterCoinName: '',
      showFeeDays: 7,
      FUNDING_DAYS: SymbolFundingFeeService.FUNDING_DAYS,
      platformFilter: {
        DERIBIT: false,
        BYBIT: false,
        MEXC: false,
        KUCOIN: false,
        BINANCE: false,
        FTX: false,
        OKX: false,
        DYDX: false
      },
      platforms: [],
      allPlatforms: [],
      FUNDING_MODEL: {},
      fundingFeeMap: {},
      coinList: [],
      sortRule: {
        coinName: 'HighToLow',
        DERIBIT: 'Default',
        OKX: 'Default',
        // FTX: 'Default',
        BINANCE: 'Default',
        BYBIT: 'Default',
        MEXC: 'Default',
        KUCOIN: 'Default',
        DYDX: 'Default',
        changeSort: function (sort) {
          if (sort === 'Default') {
            return  'HighToLow';
          } else if (sort === 'HighToLow') {
            return  'LowToHigh';
          } else if (sort === 'LowToHigh') {
            return  'HighToLow';
          }
        }
      },
      platformDisplayConfig: []
    }
  },
  methods: {
    changeDisplayConfig: function () {
      this.render(true);
      this.$forceUpdate();
    },
    render: function (update) {
      this.FUNDING_MODEL = SymbolFundingFeeService.getFundingFeeModel();
      let allPlatformTmp = [];
      let platformTmp = [];
      let dataPlatform = Object.keys(this.FUNDING_MODEL);
      for (let i = 0; i < dataPlatform.length; i++) {
        if (GeneralApiService.INACTIVE_PLATFORM.indexOf(dataPlatform[i]) === -1) {
          allPlatformTmp.push(dataPlatform[i]);
          if (!update) {
            if (this.platformDisplayConfig.indexOf(dataPlatform[i]) === -1) {
              this.platformDisplayConfig.push(dataPlatform[i]);
            }
          }
        }
      }
      for (let i = 0; i < dataPlatform.length; i++) {
        if (GeneralApiService.INACTIVE_PLATFORM.indexOf(dataPlatform[i]) === -1 && (this.platformDisplayConfig.indexOf(dataPlatform[i]) > -1)) {
          platformTmp.push({
            platform: dataPlatform[i],
            show: true
          });
          if (this.platformDisplayConfig.indexOf(dataPlatform[i]) === -1) {
            this.platformDisplayConfig.push(dataPlatform[i]);
          }
        }
      }
      this.platforms = platformTmp;
      this.allPlatforms = allPlatformTmp;
      if (this.platforms.length === 2) {
        this.platforms.push({
          platform: 'DIFF',
          show: true
        })
      }
      let fundingFeeMapTmp = {};
      for (const platform in this.FUNDING_MODEL) {
        let symbolFeeMap = this.FUNDING_MODEL[platform]
        for (const symbol in symbolFeeMap) {
          let feeData = symbolFeeMap[symbol];
          if (!fundingFeeMapTmp[symbol]) {
            fundingFeeMapTmp[symbol] = {};
            fundingFeeMapTmp[symbol][platform] = {};
            if (this.coinList.indexOf(symbol) === -1) {
              this.coinList.push(symbol);
            }
            if (platform === GeneralApiService.PLATFORM.DYDX || (platform === GeneralApiService.PLATFORM.DERIBIT && symbol.endsWith('-USD'))) {
              if (!fundingFeeMapTmp[symbol + 'T']) {
                fundingFeeMapTmp[symbol + 'T'] = {};
                fundingFeeMapTmp[symbol + 'T'][platform] = {};
              }
              if (this.coinList.indexOf(symbol + 'T') === -1) {
                this.coinList.push(symbol + 'T');
              }
              fundingFeeMapTmp[symbol + 'T'][platform] = feeData;
            }
          }
          fundingFeeMapTmp[symbol][platform] = feeData;
          if (platform === GeneralApiService.PLATFORM.DYDX || (platform === GeneralApiService.PLATFORM.DERIBIT && symbol.endsWith('-USD'))) {
            if (!fundingFeeMapTmp[symbol + 'T']) {
              fundingFeeMapTmp[symbol + 'T'] = {};
              fundingFeeMapTmp[symbol + 'T'][platform] = {};
            }
            fundingFeeMapTmp[symbol + 'T'][platform] = feeData;
          }
        }
      }
      this.sortCoinList('coinName');
      this.fundingFeeMap = fundingFeeMapTmp;
    },
    getFundingFeeColor: function (coin, platform) {
      if (this.fundingFeeMap[coin] && this.fundingFeeMap[coin][platform] && this.fundingFeeMap[coin][platform][this.showFeeDays]) {
        return this.fundingFeeMap[coin][platform][this.showFeeDays] > 0 ? '#f6465d' : '#0ecb81';
      }
      return 'white';
    },
    getFundingFeeDiff: function (coin) {
      let platform1 = this.platforms[0].platform;
      let platform2 = this.platforms[1].platform;
      if (this.fundingFeeMap[coin] && this.fundingFeeMap[coin][platform1] && this.fundingFeeMap[coin][platform1][this.showFeeDays] && this.fundingFeeMap[coin][platform2] && this.fundingFeeMap[coin][platform2][this.showFeeDays]) {
        return Math.abs(this.fundingFeeMap[coin][platform1][this.showFeeDays] - this.fundingFeeMap[coin][platform2][this.showFeeDays]).toFixed(4) + '%';
      } else {
        return '-';
      }
    },
    sortCoinList: function (sortKey) {
      this.sortRule[sortKey] = this.sortRule.changeSort(this.sortRule[sortKey]);
      this.coinList.sort((a, b) => {
        if (a < b) {
          if (this.sortRule[sortKey] === 'LowToHigh') {
            return -1;
          } else {
            return 1;
          }
        } else if (a > b) {
          if (this.sortRule[sortKey] === 'LowToHigh') {
            return 1;
          } else {
            return -1;
          }
        }
        return 0;
      });
    },
    sortCoinListByFeeValue: function (sortKey) {
      this.sortRule[sortKey] = this.sortRule.changeSort(this.sortRule[sortKey]);
      this.coinList.sort((a, b) => {
        if (Optional.orElse(() => this.fundingFeeMap[a][sortKey][this.showFeeDays], 999999) < Optional.orElse(() => this.fundingFeeMap[b][sortKey][this.showFeeDays], 999999)) {
          if (this.sortRule[sortKey] === 'LowToHigh') {
            return -1;
          } else {
            return 1;
          }
        } else if (Optional.orElse(() => this.fundingFeeMap[a][sortKey][this.showFeeDays],  -999999) > Optional.orElse(() => this.fundingFeeMap[b][sortKey][this.showFeeDays], -999999)) {
            if (this.sortRule[sortKey] === 'LowToHigh') {
              return 1;
            } else {
              return -1;
            }
          }
        return 0;
      });
    },
    doCoinFilter: function (coin) {
      if (!this.filterCoinName) {
        return (coin);
      }
      if (coin.toUpperCase().indexOf(this.filterCoinName.toUpperCase()) > -1) {
        return (coin);
      }
    },
    doPlatformFilter: function (coin) {
      let show = true;
      for (const key in (this.platformFilter)) {
        if ((this.platformFilter)[key]) {
          if (!this.fundingFeeMap[coin][key]) {
            show = false;
          }
        }
      }
      if (show) {
        return this.doCoinFilter(coin);
      }
    },
    changePlatformFilter: function (platform) {
      this.platformFilter[platform] = !this.platformFilter[platform];
      this.$forceUpdate();
    }
  }
}
</script>

<style scoped>

</style>