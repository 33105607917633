<template>
  <div class="panel-scoped">
  
    <el-row>
      <el-col :span="12">
        <FtGroupButtonWidget :buttons="[{
            id: 'append',
            text: '待审核消息',
            textI18n: '',
        }]" mode="single" textMode="true" v-on:switch-change="onAction" 
        style="background: #3E454E;" />
      </el-col>
      <el-col :span="12" style="text-align: right;">
        <select v-model="filterStatus" @change="onFilterStatus" style="width: 120px; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px">
          <option value="">全部</option>
          <option value="padding">待审批</option>
          <option value="resolve">审批通过</option>
          <option value="reject">审批拒绝</option>
        </select>
      </el-col>
    </el-row>

    <div style="margin-top: 20px;">

      <FtOperationListWidget ref="olw" selectMode="multiple"
        :columns="columns" :data="paddingTransfers"
        :scrollHeight="scrollHeight"
        :buttons="[{
              id: 'resolve',
              text: '通过',
              textI18n: '',
              width: '80px',
              padding: '0 20px 0 20px',
              mode: 'padding'
          }, {
              id: 'reject',
              text: '拒绝',
              textI18n: '',
              width: '80px',
              padding: '0 20px 0 20px',
              mode: 'padding'
          }]" 
          v-on:switch-change="onAction" 
          v-on:action-row="onActionRow"
          v-on:action-cell="onActionCell"
          oWidth="160px" style="border-bottom: 1px solid #3E454E;"/>

    </div>
  
    <div style="margin-top: 20px; display: flex;">
      <div style="flex: 1;">&nbsp;</div>
      <div>

        <FtGroupButtonWidget :buttons="[{
            id: 'resolves',
            text: '批量通过',
            textI18n: '',
        }]" mode="single" v-on:switch-change="onAction" 
        style="background: #3E454E; margin-right: 15px;" />

        <FtGroupButtonWidget :buttons="[{
            id: 'rejects',
            text: '批量拒绝',
            textI18n: '',
        }]" mode="single" v-on:switch-change="onAction" 
        style="background: #3E454E;" />

      </div>
    </div>

  </div>
</template>

<script>
import {Message} from "element-ui";
import ConfirmProvider from '@/components/ConfirmProvider'

// import CrmChannelManagement from "@/api/crm/CrmChannelManagement";
import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";
import FtOperationListWidget from "@/components/widgets/FtOperationListWidget";

import { useWalletStore, useWalletOutsideStore } from '@/store/wallet'
import UserService from "@/api/user/UserService";
import TrialphaLoading from "@/components/TrialphaLoading";

export default {
  components: {
    FtGroupButtonWidget,
    FtOperationListWidget
  },
  props: {},
  data() {
    return {
      // 列表信息
      columns: [
        {textI18n: '', text: '#', align: 'left', width: '4%', name: 'id', checkbox: true},
        // {textI18n: '', text: '编号', align: 'left', width: '5%', name: 'id', render: (name, rowScope, rowIndex) => {
        //   return rowIndex + 1
        // }},
        {textI18n: '', text: '钱包名', align: 'left', width: '18%', name: 'fromWalletAddress', render: (name, rowScope) => {
          return rowScope['fromWalletHumanReadableName'] ? rowScope['fromWalletHumanReadableName'] : rowScope['fromWalletAddress']
        }},
        {textI18n: '', text: '目标地址', align: 'left', width: '20%', name: 'toWalletAddress', render: (name, rowScope) => {
          return rowScope['toWalletHumanReadableName'] ? rowScope['toWalletHumanReadableName'] : rowScope['toWalletAddress']
        }},
        {textI18n: '', text: '转出金额', align: 'left', width: '14%', name: 'tokenAmount', render: (name, rowScope) => {
          return rowScope[name] + ' ' + rowScope['tokenType']
        }},
        {textI18n: '', text: '转出日期', align: 'left', width: '11%', name: 'createTime', render: (name, rowScope) => {
          var createTime = rowScope[name]
          var d = new Date(createTime * 1000)
          return d.getFullYear() + '-' +
              ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + '-' +
              (d.getDate() < 10 ? '0' + d.getDate() : d.getDate())
        }},
        {textI18n: '', text: '备注', align: 'left', width: '17%', name: 'notes'},
        {textI18n: '', text: '审核状态', align: 'left', width: '16%', name: 'paddingStatus', render: (name, rowScope) => {
          let statusHumanReadableName = rowScope.statusHumanReadableName
          if (rowScope.status == 'PENDING_APPROVAL') {
            var userName = UserService.getUserName()
            if (rowScope.approvers && rowScope.approvers.indexOf(userName) > -1) {
              // 当前用户审批通过
              statusHumanReadableName = '审批通过'
              statusHumanReadableName += `(${rowScope.approvers ? rowScope.approvers.length : 0} / ${rowScope.requireApprovalNumber})`
            }
            else if (rowScope.decliners && rowScope.decliners.indexOf(userName) > -1) {
              // 当前用户审批拒绝
              statusHumanReadableName = '审批拒绝'
              // 总人数
              var userTotal = rowScope.allManagers.length
              // 失败需要的人数
              var requireDeclinerNumber = userTotal - rowScope.requireApprovalNumber + 1
              statusHumanReadableName += `(${rowScope.decliners ? rowScope.decliners.length : 0} / ${requireDeclinerNumber})`
            } else {
              statusHumanReadableName += `(${rowScope.approvers ? rowScope.approvers.length : 0} / ${rowScope.requireApprovalNumber})`
            }
          }
          return `
          <span v-if="address.wallet" style="color: #FFB324; background: rgba(255,179,36,0.1); padding: 6px 12px; border-radius: 8px; font-size: 12px;">
            ${statusHumanReadableName}
          </span>`
        }}
      ],

      // 滚动面板高度
      scrollHeight: 0,

      filterStatus: '',
      
      paddingTransfers: []
    }
  },
  computed: {
    // 待审批列表
    paddingTransfersAll: () => {
      let walletOutsideStore = useWalletOutsideStore()
      return walletOutsideStore.paddingTransfers
    },
  },
  mounted() {

    // var windowHeight = window.innerHeight
    this.scrollHeight = 260

    this.onFilterStatus()

  },
  methods: {
    onFilterStatus() {
      var userName = UserService.getUserName()
      var status = this.filterStatus
      if (status) {
        this.paddingTransfers = this.paddingTransfersAll.filter(item => {
          if (status == 'padding') {
            return (!item.approvers || item.approvers.indexOf(userName) == -1) && (!item.decliners || item.decliners.indexOf(userName) == -1)
          }
          if (status == 'resolve') {
            return item.approvers && item.approvers.indexOf(userName) > -1
          }
          if (status == 'reject') {
            return item.decliners && item.decliners.indexOf(userName) > -1
          }
          return false
        })
      } else {
        this.paddingTransfers = this.paddingTransfersAll
      }
    },
    onActionRow(item) {
      console.log('转账详情数据', item)
      this.$emit('switch-change', {
        id: 'transferDetail',
        value: item
      })
    },
    onActionCell(name, item) {
      console.log('onActionCell', name, item)
      if (name === 'paddingStatus') {
        // this.onAction({id: 'transferApproval', value: item})
        this.$emit('switch-change', {
          id: 'approvalDetail',
          value: item
        })
      } else {
        this.onActionRow(item)
      }
      // let userName = UserService.getUserName()
      // if (item.createdBy == userName) {
      //   console.log('转账详情数据', item)
      //   this.$emit('switch-change', {
      //     id: 'transferDetail',
      //     value: item
      //   })
      // }
    },

    hasDuplicate(item, type) {
      let result = false
      let userName = UserService.getUserName()
      if (type == 'approvers' && item.approvers && item.approvers.indexOf(userName) > -1) {
        // 同意
        result = true
      }
      if (type == 'decliners' && item.decliners && item.decliners.indexOf(userName) > -1) {
        // 拒绝
        result = true
      }
      return result
    },

    // 判断是否已经存在已经审批的数据
    hasApproved(paddingList) {
      let result = false
      let userName = UserService.getUserName()
      paddingList.forEach(item => {
        if (item.approvers && item.approvers.indexOf(userName) > -1) {
          // 同意
          result = true
          return false
        }
        if (item.decliners && item.decliners.indexOf(userName) > -1) {
          // 拒绝
          result = true
          return false
        }
      })
      return result
    },

    // 各种切换处理
    onAction(item) {
      let that = this
      if (item.id == 'resolve') {
          let msg = '请确认通过审批'
          let paddingList = [item.value]
          if (that.hasDuplicate(item.value, 'approvers')) {
            // msg = '当前数据已经审批过，请确认重新审批'
            return
          }
          ConfirmProvider.openConfirm(that, '操作确认', msg, function () {
            console.log('选中数据 resolve', paddingList)
            let loading = TrialphaLoading.loading(that);
            let walletStore = useWalletStore()
            walletStore.approveTransfer(paddingList).then(() => {
              Message.success('操作成功')
              loading.close()
              that.onFilterStatus()
            }, () => {
              Message.error('操作异常')
              loading.close()
              that.onFilterStatus()
            })
          })
      }
      else if (item.id == 'reject') {
          // 拒绝操作暂时没有
          let msg = '请确认拒绝审批'
          let paddingList = [item.value]
          if (that.hasDuplicate(item.value, 'decliners')) {
            // msg = '当前数据已经审批过，请确认重新审批'
            return
          }
          ConfirmProvider.openConfirm(that, '操作确认', msg, function () {
            console.log('选中数据 reject', paddingList)
            let loading = TrialphaLoading.loading(that);
            let walletStore = useWalletStore()
            walletStore.approveTransferDecline(paddingList).then(() => {
              Message.success('操作成功')
              loading.close()
              that.onFilterStatus()
            }, () => {
              Message.error('操作异常')
              loading.close()
              that.onFilterStatus()
            })
          })
      }
      else if (item.id == 'resolves') {
          let msg = '请确认批量通过审批'
          let paddingList = that.$refs.olw.getFormData()
          if (paddingList.length == 0) {
            return
          }
          if (that.hasApproved(paddingList)) {
            msg = '当前存在已经审批过的数据，请确认重新审批'
          }
          ConfirmProvider.openConfirm(that, '操作确认', msg, function () {
            console.log('选中数据', paddingList)
            let loading = TrialphaLoading.loading(that);
            let walletStore = useWalletStore()
            walletStore.approveTransfer(paddingList).then(() => {
              Message.success('操作成功')
              loading.close()
              that.onFilterStatus()
            }, () => {
              Message.error('操作异常')
              loading.close()
              that.onFilterStatus()
            })
          })
      }
      else if (item.id == 'rejects') {
          // 拒绝操作暂时没有
          let msg = '请确认批量拒绝审批'
          let paddingList = that.$refs.olw.getFormData()
          if (paddingList.length == 0) {
            return
          }
          if (that.hasApproved(paddingList)) {
            msg = '当前存在已经审批过的数据，请确认重新审批'
          }
          ConfirmProvider.openConfirm(that, '操作确认', msg, function () {
            console.log('选中数据', paddingList)
            let loading = TrialphaLoading.loading(that);
            let walletStore = useWalletStore()
            walletStore.approveTransferDecline(paddingList).then(() => {
              Message.success('操作成功')
              loading.close()
              that.onFilterStatus()
            }, () => {
              Message.error('操作异常')
              loading.close()
              that.onFilterStatus()
            })
          })
      }
    },

  }
}
</script>

<style scoped>
.panel-scoped{
  background: #22272E;
  padding: 20px;
  border: 1px solid #3E454E;
  border-radius: 8px;
}

.panel-scoped .ft-checkbox{
  width: 20px; height: 20px; border-radius: 4px; border: 1px solid #3E454E; margin-top: 6px;
  cursor: pointer;
}

.panel-scoped .ft-checkbox > div{
  width: 12px; height: 12px; background: transparent; border-radius: 2px; margin: 3px;
}

.panel-scoped .ft-checkbox.active > div{
  background: #fff;
}
</style>