<template>
  <el-card class="trade-black-card" style="overflow-y: auto;height: 200px;border-radius: 0;border-right: 0;border-left: 0;border-bottom: 0;border-top: 0" body-style="padding: 0" shadow="none">
    <el-card class="orderbook-item-ask" body-style="padding: 0" shadow="none" v-for="trade in trades" :key="trade.a">
      <el-row class="orderbook-item-ask" style="z-index: 10;padding: 0 15px 0 15px;width: 100%;height: 20px;">
        <el-col :span=8 style=";padding-top: 0">
          <span :style="'' + (trade.buyerMaker ? 'color: rgb(246, 70, 93)' : 'color: rgb(14, 203, 129)') + ';font-size: 12px;display: inline-block;float: left;background-color: rgba(0, 0, 0, 0)'">{{ trade.price }}</span>
        </el-col>
        <el-col :span="8" style=";padding-top: 0">
          <span style="color: rgb(183, 189, 198);font-size: 12px;display: inline-block;float: right">{{ trade.qty }}</span>
        </el-col>
        <el-col :span="8" style=";padding-top: 0">
          <span style="color: rgb(183, 189, 198);font-size: 12px;display: inline-block;float: right">{{ timestampToDate(trade.time) }}</span>
        </el-col>
      </el-row>
    </el-card>
  </el-card>
</template>

<script>

import DatafeedApiService from "@/api/trade/DatafeedApiService";

export default {
  name: "TradesPanel",
  props: {
    symbol: String
  },
  data: function () {
    return {
      trades: [],
      precision: 3,
      tradeTimer: {}
    }
  },
  created() {
  },
  beforeDestroy() {
    clearInterval(this.tradeTimer);
  },
  methods: {
    timestampToDate(timestamp) {
      let date = new Date(Number(timestamp));
      let h = date.getHours() + ':';
      let m = date.getMinutes() + ':';
      let s = date.getSeconds();
      return h + m + s;
    },
    render: function (platform, kind, symbol) {
      let that = this;
      if (this.tradeTimer) {
        clearInterval(this.tradeTimer);
      }
      DatafeedApiService.lastTrades(platform, kind, symbol, function (data) {
        data = data.data.trades;
        data.reverse();
        that.trades = data;
      });
      this.tradeTimer = setInterval(() => {
        DatafeedApiService.lastTrades(platform, kind, symbol, function (data) {
          data = data.data.trades;
          data.reverse();
          that.trades = data;
        });
      }, 6868);
    }
  }

}
</script>

<style scoped>

</style>