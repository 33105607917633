<script type="text/javascript">


import AbmNet from "@/api/net/AbmNet";
import ServiceMapper from "@/const/ServiceMapper";

function getOkxOptionMarketInfo(success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.tradeApiServiceGetOkxTickers + '?instType=OPTION', function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

export default {
  getOkxOptionMarketInfo
}


</script>