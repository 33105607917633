<script lang="javascript">
import dark from "@/resources/dark"

let theme = localStorage.getItem('theme') || 'Dark';

let color = {
  Dark: dark.color
}

export default {
  install(Vue) {

    Vue.prototype.setTheme = function (theme_) {
      theme = theme_;
      localStorage.setItem('theme', theme_);
      location.reload();
    };

    Vue.prototype.$c = function (key) {
      let colorObj = color[theme];
      if (key.indexOf('.') > -1) {
        let obj;
        let keys = key.split('.');
        for (let index in keys) {
          obj = colorObj[keys[index]];
          colorObj = obj;
        }
        return obj;
      } else {
        return colorObj[key];
      }
    }

  }
}
</script>