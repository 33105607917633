<script lang="javascript">

const color = {
  mainColor: '#343a40'
}

export default {
  color
}

</script>