<template>
  <el-card class="trade-black-card" style="overflow-y: auto;height: 100%;border-radius: 0;border: 0;margin-top: 5px" body-style="padding: 0" shadow="none">
    <div class="hide-less-971">
      <el-card class="trade-black-card" body-style="padding: 0" shadow="none" style="border: 0">
        <el-row style="border-bottom: 1px solid rgba(128,128,128,0.18);padding-left: 15px;padding-right: 15px">
          <el-col :span="2">
            <span class="position-header" style="float: left">Date</span>
          </el-col>
          <el-col :span="3">
            <span class="position-header" style="float: left">Symbol</span>
          </el-col>
          <el-col :span="2">
            <span class="position-header">Size</span>
          </el-col>
          <el-col :span="2">
            <span class="position-header">Entry Price</span>
          </el-col>
          <el-col :span="2">
            <span class="position-header">PNL</span>
          </el-col>
          <el-col :span="2">
            <span class="position-header">Delta</span>
          </el-col>
          <el-col :span="2">
            <span class="position-header">Gamma</span>
          </el-col>
          <el-col :span="2">
            <span class="position-header">Theta</span>
          </el-col>
          <el-col :span="2">
            <span class="position-header">vega</span>
          </el-col>
          <el-col :span="5">
            <span class="position-header">Operation</span>
          </el-col>
        </el-row>
      </el-card>
      <div v-for="type in (typeArray)" :key="type">
        <el-card class="position-item" body-style="padding: 0" shadow="none">
          <el-row>
            <el-col :span="3">
              <span class="position-header" style="color: white;float: left">{{ type }}</span>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="position-item" body-style="padding: 0" shadow="none" v-for="(item, index) in typeMap[type]" :key="index">
          <el-row>
            <el-col :span="3" :offset="2">
              <span class="position-header ellipsis-line" style="color: white;float: left">{{ item.instrument_name }}</span>
            </el-col>
            <el-col :span="2">
              <span class="position-header" :style="'' + (Number(item.size) > 0 ? 'color: #0ecb81' : 'color: #ef5350') + ''">{{ item.size }}</span>
            </el-col>
            <el-col :span="2">
              <span class="position-header" style="color: white">{{ item.average_price }}</span>
            </el-col>
            <el-col :span="2">
              <span class="position-header" :style="'' + (Number(item.total_profit_loss) > 0 ? 'color: #0ecb81' : 'color: #ef5350') + ''">{{ item.total_profit_loss.toFixed(3) }}</span>
            </el-col>
            <el-col :span="2">
              <span class="position-header" style="color: white">{{ item.delta.toFixed(3) }}</span>
            </el-col>
            <el-col :span="2">
              <span class="position-header" style="color: white">{{ item.gamma.toFixed(3) }}</span>
            </el-col>
            <el-col :span="2">
              <span class="position-header" style="color: white">{{ item.theta.toFixed(3) }}</span>
            </el-col>
            <el-col :span="2">
              <span class="position-header" style="color: white">{{ item.vega.toFixed(3) }}</span>
            </el-col>
            <el-col :span="5">
              <el-button class="close-position-btn" style="font-size: 12px;padding: 0 15px 0 15px;float: right;margin-top: 5px">MARKET CLOSE</el-button>
              <input class="trade-black-card" type="text" style="margin-top: 5px;background-color: #1f2b3a;width: 110px;outline: none;border: 0;color: white;margin-right: 8px;font-size: 12px;height: 24px;line-height: 24px;padding-left: 5px;border-radius: 3px;float: right" placeholder="Amount">
            </el-col>
          </el-row>
        </el-card>
      </div>
    </div>
    <div class="hide-more-971">
      <div v-for="type in (typeArray)" :key="type">
        <el-card class="position-item" body-style="padding: 0" shadow="none">
          <el-row>
            <el-col :span="3">
              <span class="position-header" style="color: white;float: left">{{ type }}</span>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="position-card-item" body-style="padding: 0" shadow="none" v-for="(item, index) in typeMap[type]" :key="index">
          <el-row style="margin-top: 5px">
            <el-col :span="10" style="margin-top: 8px">
              <span class="position-header ellipsis-line" style="color: white;float: left;font-size: 13px">{{ item.instrument_name }}</span>
            </el-col>
            <el-col :span="14">
              <el-button class="close-position-btn" v-auth:tradePanelWrite style="font-size: 12px;padding: 0 15px 0 15px;float: right;margin-top: 5px">MARKET CLOSE</el-button>
              <input class="trade-black-card" v-auth:tradePanelWrite type="text" style="margin-top: 5px;background-color: #1f2b3a;width: 60px;outline: none;border: 0;color: white;margin-right: 8px;font-size: 12px;height: 24px;line-height: 24px;padding-left: 5px;border-radius: 3px;float: right" placeholder="Amount">
            </el-col>
          </el-row>
          <el-row style="margin-top: 5px">
            <el-row>
              <el-col :span="12">
                <span class="position-header" style="float: left">PNL ({{ item.kind.toUpperCase() === 'OPTION' ? (item.instrument_name.split('-')[0]) : 'USD' }})</span>
              </el-col>
              <el-col :span="12">
                <span class="position-header" style="float: right">Size</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <span class="position-header" :style="'' + (Number(item.total_profit_loss) > 0 ? 'color: #0ecb81' : 'color: #ef5350') + ';float: left;font-size: 15px'">{{ item.total_profit_loss.toFixed(3) }}</span>
              </el-col>
              <el-col :span="12">
                <span class="position-header" :style="'' + (Number(item.size) > 0 ? 'color: #0ecb81' : 'color: #ef5350') + ';float: right;font-size: 15px'">{{ item.size }}</span>
              </el-col>
            </el-row>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="3">
              <span class="position-header" style="color: grey;float: left;font-size: 12px">Delta</span>
            </el-col>
            <el-col :span="5">
              <span class="position-header" style="color: grey;float: right">Gamma</span>
            </el-col>
            <el-col :span="5">
              <span class="position-header" style="color: grey;float: right">Theta</span>
            </el-col>
            <el-col :span="5">
              <span class="position-header" style="color: grey;float: right">Vega</span>
            </el-col>
            <el-col :span="6">
              <span class="position-header" style="color: grey;float: right">Entry Price</span>
            </el-col>
          </el-row>
          <el-row style="margin-top: 5px">
            <el-col :span="3">
              <span class="position-header" style="color: white;float: left">{{ item.delta.toFixed(3) }}</span>
            </el-col>
            <el-col :span="5">
              <span class="position-header" style="color: white;float: right">{{ item.gamma.toFixed(3) }}</span>
            </el-col>
            <el-col :span="5">
              <span class="position-header" style="color: white;float: right">{{ item.theta.toFixed(3) }}</span>
            </el-col>
            <el-col :span="5">
              <span class="position-header" style="color: white;float: right">{{ item.vega.toFixed(3) }}</span>
            </el-col>
            <el-col :span="6">
              <span class="position-header" style="color: white;float: right">{{ item.average_price }}</span>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </div>
    <el-row v-if="Object.keys(typeMap).length <= 0" style="margin-top: 100px">
      <i class="el-icon-s-order" style="font-size: 25px;color: rgba(128,128,128,0.48)"></i>
    </el-row>
    <el-row v-if="Object.keys(typeMap).length <= 0" style="margin-top: -5px">
      <span style="font-size: 12px;color: rgba(128,128,128,0.48)">Empty</span>
    </el-row>
  </el-card>
</template>

<script>

import TradeApiService from "@/api/trade/TradeApiService";

export default {
  name: "DeribitPositionPanel",
  props: {
    symbol: String
  },
  data: function () {
    return {
      platform: 'DERIBIT',
      tradeAccountIns: {},
      totalSize: 0,
      totalPnl: 0,
      renderingPosition: false,
      typeArray: [],
      typeMap: {},
      positionTimer: {}
    }
  },
  created() {
  },
  beforeDestroy() {
    clearInterval(this.positionTimer);
  },
  methods: {
    timestampToDate(timestamp) {
      let date = new Date(Number(timestamp));
      let h = date.getHours() + ':';
      let m = date.getMinutes() + ':';
      let s = date.getSeconds();
      return h + m + s;
    },
    render: function (tradeAccount) {
      let that = this;
      this.tradeAccountIns = tradeAccount;
      TradeApiService.getAllPosition(that.platform, this.tradeAccountIns.apiKey, function (data) {
        that.initPosition(data);
      });
      that.positionTimer = setInterval(() => {
        TradeApiService.getAllPosition(that.platform, this.tradeAccountIns.apiKey, function (data) {
          that.initPosition(data);
        });
      }, 6868);
    },
    initPosition: function (data) {
      let typeArrayTmp = [];
      let typeMapTmp = {};
      for (const dataKey in data) {
        let p = data[dataKey];
        let type = p.instrument_name.split('-')[1];
        if (typeArrayTmp.indexOf(type) === -1) {
          typeArrayTmp.push(type);
          typeMapTmp[type] = [];
        }
        typeMapTmp[type].push(p);
      }
      this.typeArray = typeArrayTmp;
      this.typeMap = typeMapTmp;
    }
  }

}
</script>

<style scoped>

.ellipsis-line {
  padding: 0;
  text-align: left;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and  (max-width: 868px) {
  .ellipsis-line {
    padding: 0;
    text-align: left;
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

</style>