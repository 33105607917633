<template>
  <div>
    <div v-if="!(watchList && watchList.length > 0)">
      <el-button class="dark-mode-btn" style="float: left" size="small" @click="addWatchList">Add wallet</el-button>
    </div>
    <div v-if="watchList && watchList.length > 0">
      <el-row>
        <el-button-group style="float: left;border-bottom: 0;">
          <el-button class="dark-mode-btn" style="border-bottom: 0;border-radius: 0" size="small" @click="selectWallet = wallet"
                     :disabled="selectWallet === wallet" v-for="wallet in watchList" :key="wallet">
            {{ wallet.substr(0, 4) }}....{{ wallet.substr(38, 42) }}
          </el-button>
          <el-button class="dark-mode-btn" style="border-bottom: 0;border-radius: 0" size="small" @click="addWatchList">
            Add wallet
          </el-button>
        </el-button-group>
      </el-row>
      <el-card class="black-card" style="border-radius: 0;border-bottom: 0;background-color: #1c2128"
               body-style="padding: 10px" shadow="none">
        <el-row>
          <el-col :span="24">
            <span class="dark-mode-font" style="color: white;display: inline-block;float: left"> Total wallet USD value: <span class="dark-mode-font" style="color: #0ecb81;">${{ totalUsd.toFixed(2) }}</span></span>
            <span class="dark-mode-font" style="color: white;display: inline-block;float: right" v-if="walletUsdMap[selectWallet]">This wallet USD value: <span class="dark-mode-font" style="color: #0ecb81;">${{ walletUsdMap[selectWallet].toFixed(2) }}</span></span>
          </el-col>
        </el-row>
      </el-card>
      <el-row style="margin-top: 0" v-if="walletInfo[selectWallet]">
        <div v-for="(chain, index) in supportChains" :key="index">
          <div v-if="walletInfo[selectWallet] && walletInfo[selectWallet][chain.name]" style="margin: auto">
            <el-card class="black-card" style="border-radius: 0;border-bottom: 0;background-color: #1c2128"
                     body-style="padding: 10px" shadow="none">
              <el-row>
                <el-col :span="8">
                  <span class="dark-mode-font" style="color: white;display: inline-block;float: left">{{
                      chain.name
                    }}</span>
                </el-col>
                <el-col :span="16">
                  <span class="dark-mode-font"
                        style="display: inline-block;float: right;font-size: 12px;margin-top: 3px;color: white">USD Value: ${{
                      walletInfo[selectWallet][chain.name].totalUsd.toFixed(2)
                    }}</span>
                </el-col>
              </el-row>
            </el-card>
            <el-card class="black-card" style="border-radius: 0;height: 28px;background-color: #1c2128" shadow="none"
                     body-style="padding: 4px 10px 3px 10px">
              <el-row>
                <el-col :span="6">
                  <span class="dark-mode-font"
                        style="display: inline-block;float: left;text-align: left;font-size: 12px;color:gray ">Asset</span>
                </el-col>
                <el-col :span="6">
                  <span class="dark-mode-font"
                        style="display: inline-block;float: right;text-align: right;font-size: 12px;color:gray ">Amount</span>
                </el-col>
                <el-col :span="6">
                  <span class="dark-mode-font"
                        style="display: inline-block;float: right;text-align: right;font-size: 12px;color:gray ">Value</span>
                </el-col>
                <el-col :span="6">
                  <span class="dark-mode-font"
                        style="display: inline-block;float: right;text-align: right;font-size: 12px;color:gray ">Operation</span>
                </el-col>
              </el-row>
            </el-card>
            <el-card class="black-card-hover" shadow="none" style=";border: 0;border-radius: 0" body-style="padding:0"
                     v-for="(p, index) in walletInfo[selectWallet][chain.name].erc20TokenList" :key="index">
              <el-row
                  style="height: 25px;border-bottom: 1px solid #444c56;border-left: 1px solid #444c56;border-right: 1px solid #444c56;padding: 3px 10px 3px 10px">
                <el-col :span="6">
                  <span class="dark-mode-font"
                        style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{
                      p.symbol
                    }}</span>
                </el-col>
                <el-col :span="6">
                  <span class="dark-mode-font"
                        style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{
                      p.balance.toFixed(4)
                    }}</span>
                </el-col>
                <el-col :span="6">
                  <span class="dark-mode-font"
                        style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">${{
                      p.usdValue.toFixed(2)
                    }}</span>
                </el-col>
                <el-col :span="6">
                  <el-button class="dark-mode-btn"
                             style="border-radius: 0;border-top: 0;border-bottom: 0;border-right: 0;height: 26px;line-height: 26px;float: right;margin-top: -5px;font-size: 12px;padding: 0;width: 68px;margin-right: -10px" @click="hideToken(chain.name, p.ta)">
                    Hide
                  </el-button>
                </el-col>
              </el-row>
            </el-card>
            <div v-if="walletInfo[selectWallet][chain.name].erc20TokenList.length === 0">
              <el-row
                  style="height: 65px;border-top: 0;border-bottom: #444c56 1px solid;border-left: #444c56 1px solid;border-right: #444c56 1px solid;background-color: rgba(0, 0, 0, 0);padding: 3px 10px 3px 10px;text-align: center;margin-top: 0;margin-bottom: 0">
                <span class="dark-mode-font"
                      style="margin-top: 20px;display: inline-block;font-size: 12px;color:gray;text-align: center;"><i
                    class="el-icon-s-order"/> EMPTY...</span>
              </el-row>
            </div>
          </div>
        </div>
      </el-row>
      <el-row style="margin-top: 0" v-if="!walletInfo[selectWallet]">
        <el-row
            style="height: 65px;border-bottom: #444c56 1px solid;border-top: #444c56 1px solid;border-left: #444c56 1px solid;border-right: #444c56 1px solid;background-color: rgba(0, 0, 0, 0);padding: 3px 10px 3px 10px;text-align: center;margin-top: 0;margin-bottom: 0">
                <span class="dark-mode-font"
                      style="margin-top: 20px;display: inline-block;font-size: 12px;color:gray;text-align: center;"><i
                    class="el-icon-s-order"/> Loading...</span>
        </el-row>
      </el-row>
    </div>
  </div>
</template>

<script>

import Web3Service from "@/web3/Web3Service";
import CacheService from "@/cache/CacheService";
import ConfirmProvider from "@/components/ConfirmProvider";
import SymbolInfoService from "@/utils/SymbolInfoService";
import GeneralApiService from "@/api/trade/GeneralApiService";
import UserService from "@/api/user/UserService";
import {Message} from "element-ui";
import ChainApiService from "@/web3/chain-api/ChainApiService";

export default {
  name: "MyWeb3Wallet",
  data: function () {
    return {
      destroyed: false,
      totalUsd: 0,
      walletUsdMap: {},
      selectWallet: '',
      watchList: CacheService.get(UserService.getUserId() + '_WATCH_WALLET') || [],
      supportChains: [
        {
          name: ChainApiService.ALL_CHAINS.ETH,
          symbol: 'ETH'
        },
        {
          name: ChainApiService.ALL_CHAINS.OKC,
          symbol: 'OKT'
        },
        {
          name: ChainApiService.ALL_CHAINS.BSC,
          symbol: 'BNB'
        },
        {
          name: ChainApiService.ALL_CHAINS.POLYGON,
          symbol: 'MATIC'
        },
        {
          name: ChainApiService.ALL_CHAINS.AVAXC,
          symbol: 'AVAX'
        },
        {
          name: ChainApiService.ALL_CHAINS.ARBITRUM,
          symbol: 'ETH'
        },
        {
          name: ChainApiService.ALL_CHAINS.OPTIMISM,
          symbol: 'ETH'
        }
      ],
      walletInfo: {},
      ignoredToken: CacheService.get(UserService.getUserId() + '_IGNORED_TOKEN') || {}
    }
  },
  destroyed() {
    this.destroyed = true;
  },
  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    resetData() {
      this.destroyed = false;
      this.walletUsdMap = {};
      this.totalUsd = 0;
      this.selectWallet = '';
      this.watchList = CacheService.get(UserService.getUserId() + '_WATCH_WALLET') || [];
      this.walletInfo = {};
      this.ignoredToken = CacheService.get(UserService.getUserId() + '_IGNORED_TOKEN') || {};
    },
    async initData() {
      let that = this;
      that.resetData();
      Web3Service.initWalletInfo(this, function (res) {
        that.walletInfo = res;
      });
      if (this.watchList && this.watchList.length > 0) {
        this.selectWallet = this.watchList[0];
        for (const walletKey in that.watchList) {
          let wallet = that.watchList[walletKey];
          if (that.destroyed) {
            break;
          }
          for (const supportChainsKey in that.supportChains) {
            if (that.destroyed) {
              break;
            }
            let chain = that.supportChains[supportChainsKey];
            ChainApiService.getAddressSummary(chain.name, wallet, function (nativeBalanceRes) {
              if (nativeBalanceRes && nativeBalanceRes[0]) {
                let nativeBalance = Number(nativeBalanceRes[0]['balance']);
                let totalU = 0;
                let usdV = ((SymbolInfoService.getSymbolPrice(GeneralApiService.PLATFORM.OKX, GeneralApiService.SYMBOL_TYPE.SPOT, chain.symbol + '-USDT') || 0) * nativeBalance);
                let balanceList = [{
                  symbol: chain.symbol,
                  balance: nativeBalance,
                  usdValue: usdV,
                  logo: '',
                  ta: ''
                }];
                totalU += usdV;
                ChainApiService.getTokenList(chain.name, wallet, function (erc20BalanceList) {
                  if (erc20BalanceList) {
                    erc20BalanceList.forEach((ERC20) => {
                      let obj = {
                        symbol: ERC20.token,
                        balance: Number(ERC20.holdingAmount),
                        usdValue: Number(ERC20.valueUsd),
                        ta: ERC20.tokenContractAddress
                      };
                      if (!that.ignoredToken[chain.name + '-' + obj.ta] && obj.usdValue > 0) {
                        totalU += obj.usdValue;
                        balanceList.push(obj);
                      }
                    });
                    if (!that.walletInfo[wallet]){
                      that.walletInfo[wallet] = {};
                    }
                    that.walletInfo[wallet][chain.name] = {
                      chain: chain.name,
                      totalUsd: totalU,
                      erc20TokenList: balanceList
                    }
                    that.totalUsd += totalU;
                    if (!that.walletUsdMap[wallet]) {
                      that.walletUsdMap[wallet] = 0;
                    }
                    that.walletUsdMap[wallet] = that.walletUsdMap[wallet] + totalU;
                    that.$forceUpdate();
                  }
                }, function (err) {
                  console.log(err);
                });
              }
            }, function (err) {
              console.log(err);
            });
            await that.sleep(168);
          }
          that.$forceUpdate();
          await that.sleep(268);
        }
      }
    },
    addWatchList() {
      let that = this;
      ConfirmProvider.openConfirmWithInputWithoutRegex(this, '添加关注钱包地址', '', function (value) {
        if (that.watchList.indexOf(value) === -1) {
          that.watchList.push(value);
          CacheService.set(UserService.getUserId() + '_WATCH_WALLET', that.watchList, 1000 * 60 * 60 * 24 * 365 * 100);
          that.initData();
        } else {
          Message.error('This wallet is already exists.');
        }
      }, undefined);
    },
    hideToken(chainName, ta) {
      this.ignoredToken[chainName + '-' + ta] = true;
      CacheService.set(UserService.getUserId() + '_IGNORED_TOKEN', this.ignoredToken, 1000 * 60 * 60 * 24 * 365 * 100);
      this.initData();
    }
  }
}
</script>

<style scoped>

.el-divider.data-divider {
  border-top: 1px solid #444c56;
}

.data-divider > .el-divider__text {
  background-color: #22272e;
}

</style>