<template>
  <div style="margin: 60px auto auto auto;width: 568px;padding: 0 6.18% 100px 6.18%">
    <el-card class="black-card" style="border-radius: 8px">
      <el-row>
        <span class="dark-mode-font" style="color: white;text-align: left;display: inline-block;float: left">Oneport Swapper</span>
        <span class="dark-mode-font" style="color: white;text-align: right;display: inline-block;float: right;color: gray;font-size: 12px;margin-top: 2px">Aggregated By 1INCH</span>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-card class="trade-black-card" style="border-radius: 10px">
          <el-row>
            <span class="dark-mode-font" style="color: lightgray;text-align: left;display: inline-block;float: left">You sell</span>
            <span class="dark-mode-font" style="color: lightgray;text-align: right;display: inline-block;float: right">Balance: 0.0</span>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="12">
              <button style="background-color: rgba(0, 0, 0, 0);border: 0;height: 30px;width: 100%;float: left;padding: 0">
                <img src="https://tokens.1inch.io/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82.png" alt="" style="width: 25px;height: 25px;float: left" />
                <span style="color: white;display: inline-block;float: left;margin-left: 15px">CAKE</span>
                <i class="el-icon-arrow-down" style="color: white;font-size: 12px;float: left;margin-left: 15px;margin-top: 6px"></i>
              </button>
            </el-col>
            <el-col :span="12" style="padding-left: 10px">
              <input class="trialpha-input" style="border-radius: 0;border-left: 0;border-top: 0;border-right: 0;height: 30px;text-align: right;font-size: 15px" placeholder="0.0" />
            </el-col>
          </el-row>
        </el-card>
      </el-row>
      <el-row style="position: absolute;z-index: 9;margin-top: -5px;text-align: center;padding-left: 165px">
        <el-card style="background-color: white;border-radius: 50%;width: 20px;height: 20px;" body-style="padding: 0 0 8px 0">
          <i class="el-icon-caret-bottom" style="color: cornflowerblue;font-weight: bolder;margin-top: 2px;margin-bottom: 0;margin-left: -8px;position: absolute"/>
        </el-card>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-card class="trade-black-card" style="border-radius: 10px">
          <el-row>
            <span class="dark-mode-font" style="color: lightgray;text-align: left;display: inline-block;float: left">You buy</span>
            <span class="dark-mode-font" style="color: lightgray;text-align: right;display: inline-block;float: right">Balance: 0.0</span>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="12">
              <button style="background-color: rgba(0, 0, 0, 0);border: 0;height: 30px;width: 100%;float: left;padding: 0">
                <img src="https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png" alt="" style="width: 25px;height: 25px;float: left" />
                <span style="color: white;display: inline-block;float: left;margin-left: 15px">USDC</span>
                <i class="el-icon-arrow-down" style="color: white;font-size: 12px;float: left;margin-left: 15px;margin-top: 6px"></i>
              </button>
            </el-col>
            <el-col :span="12" style="padding-left: 10px">
              <input class="trialpha-input" style="border-radius: 0;border-left: 0;border-top: 0;border-right: 0;height: 30px;text-align: right;font-size: 15px" placeholder="0.0" disabled/>
            </el-col>
          </el-row>
        </el-card>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-button class="dark-operation-btn-active" style="float: right;border-bottom: 0;padding: 0 5px;color: white;background-image: linear-gradient(-20deg, #6e45e2 0%, #88d3ce 100%);;border-radius: 10px;width: 100%;height: 38px">SWAP</el-button>
      </el-row>
    </el-card>
  </div>
</template>

<script>

import Web3Service from "@/web3/Web3Service";

export default {
  name: "FtSwap",
  data: function () {
    return {
      sendAmount: '',
      walletInfo: {},
      rendering: false,
      bridgeInSupport: [
        {
          network: 'BSC'
        }
      ]
    }
  },
  created() {
    let that = this;
    Web3Service.getWeb3(that).currentProvider.on('networkChanged', function(){
      that.initData();
    });
    this.initData();
  },
  methods: {
    initData() {
      let that = this;
      Web3Service.initWalletInfo(this, function (res) {
        console.log(res);
        that.walletInfo = res;
      })
    }
  }
}
</script>

<style scoped>

.el-divider.data-divider {
  border-top: 1px solid #444c56;
}

.data-divider > .el-divider__text {
  background-color: #22272e;
}

</style>