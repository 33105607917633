<template>
  <div :nftObj="nftObj">
    <el-card class="black-card" body-style="padding: 0" style="border: 0;background-color: rgba(0, 0, 0, 0);box-shadow: 0 0 8px #576789" shadow="always">
      <el-row>
        <el-image
            v-if="obj && obj.thumbnail"
            style="width: 128px; height: 128px"
            :src="obj.thumbnail"
            :preview-src-list="[obj.thumbnail]"
            fit="fill"></el-image>
      </el-row>
      <el-row style="margin-top: -6px">
        <span class="dark-mode-font" style="color: white;font-size: 12px;display: inline-block;margin-left: 6px">{{obj.symbol}}</span>
      </el-row>
      <el-row style="margin-top: -6px;margin-bottom: 5px">
        <span class="dark-mode-font" style="color: white;font-size: 12px;display: inline-block;margin-left: 6px;float: left">#{{nftObj.tokenId}}</span>
        <span class="dark-mode-font" style="color: white;font-size: 12px;display: inline-block;margin-right: 6px;float: right">{{obj.floorPrice}} E</span>
      </el-row>
      <el-row></el-row>
    </el-card>
  </div>
</template>

<script>

import ChainApiService from "@/web3/chain-api/ChainApiService";

export default {
  name: "NftCard",
  props: {
    nftObj: Object
  },
  data: function () {
    return {
      obj: {}
    }
  },
  created() {
    let that = this;
    ChainApiService.getErc721Info(this.nftObj.tokenContractAddress, this.nftObj.tokenId, function (info) {
      that.obj = info;
      that.$forceUpdate();
    }, function (err) {
      console.log(err);
    });
  },
  methods: {

  }
}
</script>

<style scoped>

</style>