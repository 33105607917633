<template>
  <div>
    <el-row style="width: 100%;position: absolute;z-index: 568">
      <el-col :span="2" :offset="22">
        <b-navbar style=";background-color:rgba(0,0,0,0);width: 198px;top:0;margin-top: 5px;border:0;padding-top:0;padding-bottom:0;float: right">
          <b-collapse is-nav>
            <b-nav-item-dropdown class="border-0" text="App" toggle-class="text-light"
                                 style="margin-right: 15px;margin-top: 2%;float: right;border: 0;outline: 0" no-caret>
              <b-dropdown-item href="#" v-on:click="iosPage">IOS</b-dropdown-item>
              <b-dropdown-item href="https://abm-app-image.s3.ap-northeast-1.amazonaws.com/download/ForthTech.apk">Android</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown :text="$i('lang')" toggle-class="text-light" no-caret
                                 style="margin-right: 15px;margin-top: 2%;float: right;border: 0;outline: 0">
              <b-dropdown-item href="#" v-on:click="setLanguage('China')">{{ $i('lang_china') }}</b-dropdown-item>
              <b-dropdown-item href="#" v-on:click="setLanguage('English')">{{ $i('lang_english') }}</b-dropdown-item>
              <b-dropdown-item href="#" v-on:click="setLanguage('Japan')">{{ $i('lang_japan') }}</b-dropdown-item>
              <b-dropdown-item href="#" v-on:click="setLanguage('Korea')">{{ $i('lang_korea') }}</b-dropdown-item>
              <b-dropdown-item href="#" v-on:click="setLanguage('Thailand')">{{ $i('lang_thailand') }}</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-collapse>
        </b-navbar>
      </el-col>
    </el-row>
    <div class="login-bk">
      <el-card class="login-card" shadow="none">
        <el-row style="padding-top: 10%">
          <el-row>
            <div class="login-logo-ft"></div>
          </el-row>
        </el-row>
        <el-row style="margin-top: 10%">
          <el-row>
            <span style="font-size: 15px;color: #FFFFFF"><b>{{ username }}: {{ $i('lock.description') }}</b></span>
          </el-row>
          <el-row>
            <div class="container" style="border: 0">
              <div class="row" style="border: 0">
                <div class="col-md-12 col-md-offset-12" style="border: 0">
                  <div class="login-panel panel panel-default" style="border: 0">
                    <br>
                    <div>
                      <fieldset>
                        <div class="form-group">
                          <input class="form-control username-input"
                                 style="border-radius: 20px;background-color: rgba(67,78,91,0.45);border: 1px solid rgba(67,78,91,0.45);padding-left: 35px;color: lightgray"
                                 :placeholder="$i('login.password')" v-model="password" type="password" autofocus :disabled="web3Login">
                          <i class="el-icon-lock"
                             style="position: absolute;margin-top: -26px;margin-left: -185px;color: lightgray"></i>
                        </div>
                        <div style="text-align: center;"><span></span></div>
                        <br>
                        <el-button class="login-btn" :loading="logining" :disabled="web3Login"
                                   style="margin-top: -60px;width: 100%;border:0;border-radius: 20px"
                                   type="primary"
                                   v-on:click="login"> {{ $i('lock.unlock') }}
                        </el-button>
                        <br>
                        <el-button :disabled="logining"
                                   style="outline: none;margin-top: 15px;width: 100%;border:0;border-radius: 20px;padding: 5px 10px 5px 10px;height: 38px;background-color: rgba(0, 0, 0, 0)"
                                   type="primary"
                                   v-on:click="loginWithMetaMask"><img src="https://abm-app-image.s3.ap-northeast-1.amazonaws.com/oneport/MetaMask_Fox.png" alt="Metamask" style="height: 30px;width: 30px;" /> Unlock with metamask
                        </el-button>
                      </fieldset>
                      <div style="text-align: center;margin-top: 40px;margin-bottom: 10px">

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br>
              <footer class="row">
                <div class="col-lg-2"></div>
                <div class="col-lg-8">
                  <div style="text-align: center;">
                    <span style="color: lightgray;opacity: 0.68">{{ $i('copyright') }}</span>
                  </div>
                </div>
                <div class="col-lg-2"></div>
              </footer>
            </div>
          </el-row>
        </el-row>
      </el-card>
    </div>
    <ios-download ref="iosPage"></ios-download>
  </div>
</template>

<script>
import {Message} from "element-ui";
import UserService from "@/api/user/UserService";
import RouterPath from "@/router/RouterPath";
import IosDownload from "@/components/IosDownloadPage";
import Web3 from "web3";
import Web3Service from "@/web3/Web3Service";

export default {
  name: "Lock",
  components: {IosDownload},
  data: function () {
    return {
      username: UserService.getUserObj().userInfo.alias,
      password: '',
      logining: false,
      web3Login: UserService.getUserObj().web3Login
    }
  },
  beforeRouteEnter(to, from, next) {
    if (UserService.isLogin()) {
      UserService.isValidLoginUser(() => {
        if (!UserService.isLock()) {
          next({path: RouterPath.router.controlPanel})
        } else {
          next()
        }
      }, () => {
        UserService.clear();
        next({path: RouterPath.router.login})
      });
    } else {
      UserService.clear();
      next({path: RouterPath.router.login})
    }
  },
  methods: {
    iosPage: function () {
      this.$refs['iosPage'].openDrawer();
    },
    login: function () {
      this.logining = true;
      let user = UserService.getUserObj();
      if (UserService.getPassword() === this.password) {
        user.isLock = false;
        UserService.updateUserObj(user);
        location.reload(true);
      } else {
        Message.error('Verification failed');
        this.logining = false;
      }
    },
    loginWithMetaMask: function () {
      if (window.ethereum && window.ethereum.isMetaMask) {
        let web3 = new Web3(window.ethereum);
        web3.eth.getAccounts().then((address) => {
          if (address.length > 0) {
            this.loginProcess(web3, address[0]);
          } else {
            Web3Service.requestMetaMask().then(() => {
              web3.eth.getAccounts().then((address) => {
                this.loginProcess(web3, address[0]);
              });
            });
          }
        });
      } else {
        Message.warning('Please install metamask at first');
      }
    },
    loginProcess: function (web3, address) {
      let that = this;
      this.logining = true;
      UserService.getUnlockNonce(address, function (data) {
        let hexData = web3.utils.utf8ToHex(data);
        web3.eth.personal.sign(hexData, address, '').then((sign) => {
          UserService.postLoginNonce(sign, address, function () {
            let user = UserService.getUserObj();
            user.isLock = false;
            UserService.updateUserObj(user);
            location.reload(true);
          }, function (err) {
            that.logining = false;
            console.error(err);
          });
        });
      });
    }
  }
}
</script>

<style scoped>

.login-bk {
  text-align: center;
  margin: auto;
  height: 100%;
  width: 100%;
  position: absolute;
  background-image: url("https://abm-app-image.s3.ap-northeast-1.amazonaws.com/oneport/bk_n3_1.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.login-bk:before {
  content: '';
  background: inherit;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  box-shadow: inset 0 0 0 3000px rgba(34, 39, 46, 0.85);
  filter: blur(0px);
}

.login-bk-m {
  text-align: center;
  margin: auto;
  height: 100%;
  width: 100%;
  position: absolute;
  background-image: url("https://abm-app-image.s3.ap-northeast-1.amazonaws.com/oneport/bk_n3_1_m.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.login-bk-m:before {
  content: '';
  background: inherit;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  box-shadow: inset 0 0 0 3000px rgba(34, 39, 46, 0.825);
  filter: blur(0px);
}

.login-card {
  width: 468px;
  height: 518px;
  text-align: center;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.95;
  position: absolute;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}

.login-logo {
  text-align: center;
  margin: auto;
  height: 30px;
  width: 300px;
  background-image: url("https://abm-app-image.s3.ap-northeast-1.amazonaws.com/oneport/Trialpha_black.png");
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
}

.login-card-small {
  width: 368px;
  height: 568px;
  text-align: center;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}

.login-logo-small {
  text-align: center;
  margin: auto;
  height: 20px;
  width: 200px;
  background-image: url("https://abm-app-image.s3.ap-northeast-1.amazonaws.com/oneport/Trialpha_black.png");
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
}

.login-logo {
  text-align: center;
  margin: auto;
  height: 30px;
  width: 300px;
  background-image: url("https://abm-app-image.s3.ap-northeast-1.amazonaws.com/oneport/Trialpha_black.png");
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
}

.login-logo-ft-small {
  text-align: center;
  margin: auto;
  height: 26px;
  width: 186px;
  background-image: url("https://abm-app-image.s3.ap-northeast-1.amazonaws.com/oneport/FT_NEW_TEXT_W.png");
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
}

.login-logo-ft {
  text-align: center;
  margin: auto;
  height: 50px;
  width: 274px;
  background-image: url("https://abm-app-image.s3.ap-northeast-1.amazonaws.com/oneport/white-logo.png");
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
}

body {
  text-align: center
}

.loginT-enter {
  opacity: 0;
}

.loginT-enter-to {
  opacity: 0.95;
}

.loginT-enter-active {
  transition: all 1s;
}

li {
  list-style: none;
}

.username-input:focus {
  outline: none;
}

.username-input::-webkit-input-placeholder {
  outline: none;
  color: lightgray;
}

.username-input::-ms-input-placeholder {
  outline: none;
  color: lightgray;
}

.login-btn {
  background-color: rgba(80, 143, 232, 0.25);
}

.login-btn:hover {
  background-color: rgba(80, 143, 232, 0.55);
}

.login-btn:focus {
  outline: none;
}

.login-btn:active {
  outline: none;
}

.login-btn:focus:not(.login-btn:hover){
  outline: none;
}

.login-btn:disabled {
  cursor: not-allowed;
  color: gray;
  background-color: rgba(61, 62, 63, 0.55);
}

.login-btn:disabled:hover {
  cursor: not-allowed;
  color: gray;
  background-color: rgba(61, 62, 63, 0.55);
}

.login-btn-small {
  background-color: rgba(80, 143, 232, 0.25);
}

.login-btn-small:hover {
  background-color: rgba(80, 143, 232, 0.55);
}

</style>