import UserService from "@/api/user/UserService";
import TimeService from "@/utils/TimeService";

var AWS = require('aws-sdk');

// Set the region
AWS.config.update({
  region: 'ap-northeast-1',//aws服务器所在地区编号
  apiVersion: '2006-03-01',
  accessKeyId: 'AKIAXYPFHHDIXQJ74RU5',
  secretAccessKey: '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'
});
//创建 aws 服务
var s3 = new AWS.S3();

export function AwsTracupUpdate(file) {
  var uploadParams = {
    Bucket: 'abm-app-image/tracup',//桶名称
    Key: UserService.getUserObj().userInfo.id + '-' + TimeService.getTimeStamp() + '-' + file.name, //图片名称
    Body: file,// file
    "ACL": "public-read", //类型
    'Access-Control-Allow-Origin': '*' //策应请求头
  };
  var imgFile = new Promise(function (resolve) {
//做一些异步操作
    s3.upload(uploadParams, function (err, data) {
      if (err) {
        console.log("Error", err);
      }
      if (data) {
        resolve(data);
      }
    });
  });
  return imgFile
}

export function AwsUpdateAvatar(filename, file) {
  var uploadParams = {
    Bucket: 'abm-app-image/web/avatar',//桶名称
    Key: filename, //图片名称
    Body: file,// file
    "ACL": "public-read", //类型
    'Access-Control-Allow-Origin': '*' //策应请求头
  };
  var imgFile = new Promise(function (resolve) {
//做一些异步操作
    s3.upload(uploadParams, function (err, data) {
      if (err) {
        console.log("Error", err);
      }
      if (data) {
        resolve(data);
      }
    });
  });
  return imgFile
}