<template>
    <div class="ft-tabs">
        <div class="ft-tabs-inner">
            <div class="ft-tabs-item" v-for="item in tabs" v-bind:key="item.id">
                <span :class="item.active ? 'active' : ''" @click="eventTap(item)" style="font-size: 12px">
                    {{item.textI18n ? $i(item.textI18n) : item.text}}
                    ({{item.count}})
                </span>
            </div>
        </div>
        <div class="ft-tabs-search">
            <div class="ft-search-inner">
                <div>
                    <input type="text" v-model="keyword" placeholder="输入模糊昵称" @keypress="eventSearchReturn" style="font-size: 12px;"/>
                    <i class="el-icon-search" @click="eventSearch"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        
    },
    props: {
        tabs: {
            type: Array,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: [
                // {
                //     id: 'all',
                //     text: '全部',
                //     textI18n: '',
                //     count: 100,
                //     active: true
                // },
                // {
                //     id: 'InContact',
                //     text: '接触中',
                //     textI18n: '',
                //     count: 100,
                //     active: false
                // },
                // {
                //     id: 'InConnect',
                //     text: '沟通中',
                //     textI18n: '',
                //     count: 100,
                //     active: false
                // },
                // {
                //     id: 'InReach',
                //     text: '达成合作',
                //     textI18n: '',
                //     count: 100,
                //     active: false
                // },
                // {
                //     id: 'FailConnect',
                //     text: '沟通失败',
                //     textI18n: '',
                //     count: 100,
                //     active: false
                // }
            ]
        },
    },
    data() {
        return {
            keyword: ''
        }
    },
    methods: {
        eventTap(item) {
            var tabs = this.tabs
            tabs.forEach((b) => {
                if (b.id == item.id) {
                    b.active = true
                } else {
                    b.active = false
                }
            })
            this.$emit('update:tabs', tabs);
            this.$emit('switch-change', item);
        },
        eventSearch() {
            this.$emit('like-search', this.keyword);
        },
        eventSearchReturn(e) {
            if (e.keyCode == 13) {
                this.eventSearch()
            }
        }
    }
}
</script>

<style scoped>
.ft-tabs {
    display: flex;
}
.ft-tabs .ft-tabs-inner{
    flex: 1; display: flex; padding: 0 15px; color: white; border-bottom: 1px solid #3E454E; line-height: 30px; font-size: 13px;
}
.ft-tabs .ft-tabs-inner .ft-tabs-item{
    flex: 1;
}
.ft-tabs .ft-tabs-inner .ft-tabs-item span{
    display: inline-block; padding: 0 8px;
    cursor: pointer;
}
.ft-tabs .ft-tabs-inner .ft-tabs-item span.active{
    border-bottom: 4px solid #556AB4;
}
.ft-tabs .ft-tabs-search{
    height: 30px; padding: 0 10px; border: 1px solid #3E454E; border-radius: 5px; margin-left: 10px;font-size: 13px;
}
.ft-tabs .ft-tabs-search .ft-search-inner{
    display: flex; color: white;
}
.ft-tabs .ft-tabs-search .ft-search-inner > div{
    padding: 0 0; cursor: pointer; border-radius: 5px; line-height: 29px; position: relative;
}
.ft-tabs .ft-tabs-search .ft-search-inner input{
    height: 30px; width: 100%; background-color: transparent; color: white; outline: none; border: 0;
}
.ft-tabs .ft-tabs-search .ft-search-inner i{
    position: absolute; right: 4px; top: 8px; font-weight: bold;
}



</style>