<template>
  <div class="trialpha-full-sub-page">
    <div style="margin: 56px auto auto auto;max-width: 2068px;padding: 0 6.18% 100px 6.18%">
      <el-row>
        <el-button-group style="float: left;margin-top: 10px">
          <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 106px;height: 32px;text-align: center;" @click="coinFilter = 'ALL'" :disabled="coinFilter === 'ALL'">ALL</el-button>
          <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 106px;padding: 6px;text-align: center;" @click="coinFilter = 'USD'" :disabled="coinFilter === 'USD'"><img :src="coinLogo['USD']" style="width: 18px;height: 18px" /></el-button>
          <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 106px;padding: 6px;text-align: center;" @click="coinFilter = 'BTC'" :disabled="coinFilter === 'BTC'"><img :src="coinLogo['BTC']" style="width: 18px;height: 18px" /></el-button>
          <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 106px;padding: 6px;text-align: center;" @click="coinFilter = 'ETH'" :disabled="coinFilter === 'ETH'"><img :src="coinLogo['ETH']" style="width: 18px;height: 18px" /></el-button>
          <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 106px;padding: 6px;height: 32px;text-align: center" @click="render" :loading="loading.refresh">Refresh</el-button>
        </el-button-group>
      </el-row>


      <!-- Position Title -->
      <el-card class="black-card" style="border-radius: 3px 3px 0 0;border-bottom: 0;background-color: #1c2128;margin-top: 30px" body-style="padding: 10px" shadow="none">
        <el-row>
          <el-col :span="8">
            <span class="dark-mode-font" style="color: white;display: inline-block;float: left">Strategy libraries</span>
          </el-col>
          <el-col :span="16">
            <input class="dark-mode-input" v-model="filterName" placeholder="Filter..." style="color: white;height: 25px;outline: none;font-size: 12px;background-color: rgba(0, 0, 0, 0);border: 0;width: 98px;float: right;border-bottom: 1px solid #444c56;padding-left: 18px;padding-bottom: 1px" />
            <i class="el-icon-search" style="float: right;color: #444c56;margin-top: 5px;margin-right: -15px"></i>
          </el-col>
        </el-row>
      </el-card>

      <!-- Position 表头 -->
      <el-card class="black-card" style="border-radius: 0;height: 28px;background-color: #1c2128" shadow="none" body-style="padding: 4px 10px 3px 10px">
        <el-row>
          <el-col :span="4">
            <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">Name</span>
          </el-col>
          <el-col :span="4">
            <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">Coins</span>
          </el-col>
          <el-col :span="4">
            <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">Platforms</span>
          </el-col>
          <el-col :span="8">
            <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">Description</span>
          </el-col>
          <el-col :span="4">
            <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">Comments</span>
          </el-col>
        </el-row>
      </el-card>

      <!-- Position 数据展示 -->
      <el-card class="black-card" style="border-radius: 0;border-top: 0;border-bottom: 0" shadow="none" body-style="padding: 0">
        <el-card class="black-card-hover" style="width: 100%;line-height: 45px;height: 45px;cursor: pointer;float: left;text-align: left;margin-right: 30px;border-top: 0;border-radius: 0;border-left: 0;border-right: 0" v-for="s in strategyList.filter((s) => (coinFilter === 'ALL' || s.coins.indexOf(coinFilter) > -1)).filter((s) => doStrategyFilter(s.name))" :key="s.id" body-style="padding: 0 10px 0 10px" @click.native="openStrategyDetails(s.id)">
          <el-col :span="4">
            <el-tooltip :content="s.name" :open-delay="666">
              <span class="dark-mode-font ellipsis-line-s-l"  style="color:white;display: inline-block;float: left;font-size: 12px">{{ s.name }}</span>
            </el-tooltip>
          </el-col>
          <el-col :span="4">
            <span class="dark-mode-font" style="color:white;display: inline-block;float: left;margin-right: 10px;margin-top: -3px" v-for="c in s.coins" :key="c"><img :src="coinLogo[c]" style="width: 18px;height: 18px" /></span>
          </el-col>
          <el-col :span="4">
            <span class="dark-mode-font" style="color:white;display: inline-block;float: left;margin-right: 10px;margin-top: -3px" v-for="p in s.platforms" :key="p"><img :src="exchangeInfo[p].logo" style="width: 18px;height: 18px" /></span>
          </el-col>
          <el-col :span="8">
            <el-tooltip :content="s.description" :open-delay="666">
              <span class="dark-mode-font ellipsis-line-s-l" style="color:white;display: inline-block;float: left;margin-right: 10px;font-size: 12px">{{ s.description }}</span>
            </el-tooltip>
          </el-col>
          <el-col :span="4">
            <span class="dark-mode-font ellipsis-line-s-l" style="color:white;display: inline-block;float: left;margin-right: 10px;font-size: 12px" v-if="s.comments[0]">{{ s.comments[0].content }}</span>
          </el-col>
        </el-card>
      </el-card>
      <el-card class="black-card-hover" style="width: 100%;height: 45px;cursor: pointer;float: left;text-align: left;margin-right: 30px;margin-bottom: 30px;border-top: 0;border-radius: 0 0 3px 3px" @click.native="createStrategyLib">
        <el-row style="margin-top: -10px;text-align: center">
          <i class="el-icon-circle-plus" style="font-size: 20px;color: white;margin-top: 3px" />
        </el-row>
      </el-card>



      <!--
      <el-row style="margin-top: 25px">
        <el-card class="black-card-hover" style="width: 468px;height: 353px;cursor: pointer;float: left;text-align: left;margin-right: 30px;margin-bottom: 30px" v-for="s in strategyList.filter((s) => (coinFilter === 'ALL' || s.coins.indexOf(coinFilter) > -1))" :key="s.id" body-style="padding: 10px" @click.native="openStrategyDetails(s.id)">
          <el-row style="margin-top: -3px">
            <span class="dark-mode-font" style="color:white;display: inline-block;float: left;font-size: 12px">策略名称：{{ s.name }}</span>
          </el-row>
          <el-row style="margin-top: 15px">
            <span class="dark-mode-font" style="color:white;display: inline-block;float: left;font-size: 12px">适用币种：</span>
            <span class="dark-mode-font" style="color:white;display: inline-block;float: left;margin-right: 10px;margin-top: -3px" v-for="c in s.coins" :key="c"><img :src="coinLogo[c]" style="width: 18px;height: 18px" /></span>
          </el-row>
          <el-row style="margin-top: 15px">
            <span class="dark-mode-font" style="color:white;display: inline-block;float: left;font-size: 12px">适用平台：</span>
            <span class="dark-mode-font" style="color:white;display: inline-block;float: left;margin-right: 10px;margin-top: -3px" v-for="p in s.platforms" :key="p"><img :src="exchangeInfo[p].logo" style="width: 18px;height: 18px" /></span>
          </el-row>
          <el-row style="margin-top: 15px">
            <span class="dark-mode-font" style="color:white;display: inline-block;float: left;font-size: 12px">策略描述：</span>
            <el-card class="black-card" style="background-color: rgba(0, 0, 0, 0);height: 100px;overflow-y: auto" body-style="padding: 5px" shadow="none">
              <el-row>
                <span class="dark-mode-font" style="color:white;display: inline-block;float: left;margin-right: 10px;font-size: 12px">{{ s.description }}</span>
              </el-row>
            </el-card>
          </el-row>
          <el-row style="margin-top: 15px">
            <span class="dark-mode-font" style="color:white;display: inline-block;float: left;font-size: 12px">最新备注：</span>
            <el-card class="black-card" style="background-color: rgba(0, 0, 0, 0);height: 90px;overflow-y: auto;" body-style="padding: 5px" shadow="none">
              <div v-if="s.comments[0]">
                <el-row>
                  <span class="dark-mode-font" style="color:white;display: inline-block;float: left;margin-right: 10px;font-size: 12px">发表用户：{{ s.comments[0].user.alias }}</span>
                </el-row>
                <el-row>
                  <span class="dark-mode-font" style="color:white;display: inline-block;float: left;margin-right: 10px;font-size: 12px">发表时间：{{ new Date(s.comments[0].updateTime * 1000) }}</span>
                </el-row>
                <el-row>
                  <span class="dark-mode-font" style="color:white;display: inline-block;float: left;margin-right: 10px;font-size: 12px">备注内容：{{ s.comments[0].content }}</span>
                </el-row>
              </div>
            </el-card>
          </el-row>
          <el-row style="margin-top: 10px">
            <span class="dark-mode-font" style="color:white;display: inline-block;float: left;text-align: left;font-size: 12px">最近更新：{{ new Date(s.updateTime * 1000) }}</span>
          </el-row>
        </el-card>
        <el-card class="black-card-hover" style="width: 468px;height: 353px;cursor: pointer;float: left;text-align: left;margin-right: 30px;margin-bottom: 30px" @click.native="createStrategyLib">
          <el-row style="margin-top: 108px;padding-left: 168px">
            <span class="dark-mode-font"><i class="el-icon-circle-plus" style="font-size: 80px;color: lightgray"></i></span>
          </el-row>
        </el-card>
      </el-row>
      -->
  </div>
    <CreateStrategyLibDialog ref="createStrategy" @afterCreate="render"></CreateStrategyLibDialog>
    <StrategyDetailsDrawer ref="strategyDetail" @afterUpdate="render"></StrategyDetailsDrawer>
  </div>
</template>

<script>
import ExchangeInformations from "@/const/ExchangeInformations";
import StrategyLibsService from "@/api/strategyLibs/StrategyLibsService";
import CreateStrategyLibDialog from "@/pages/controlPanel/strategyMgnt/CreateStrategyLibDialog";
import StrategyDetailsDrawer from "@/pages/controlPanel/strategyMgnt/StrategyDetailsDrawer";

export default {
  name: "StrategyMgnt",
  components: {StrategyDetailsDrawer, CreateStrategyLibDialog},
  data: function () {
    return {
      filterName: '',
      coinFilter: 'ALL',
      coinLogo: {
        USD: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/coins/USD.png',
        BTC: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/coins/BTC.png',
        ETH: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/coins/ETH.png'
      },
      exchangeInfo: {
        BINANCE: {
          logo: ExchangeInformations.exchangeInfos.BINANCE.icon,
          fontColor: '#f5bc2f'
        },
        BITMEX: {
          logo: ExchangeInformations.exchangeInfos.BITMEX.icon,
          fontColor: '#5b69b1'
        },
        DERIBIT: {
          logo: ExchangeInformations.exchangeInfos.DERIBIT.icon,
          fontColor: '#04cfbe'
        },
        FTX: {
          logo: ExchangeInformations.exchangeInfos.FTX.icon,
          fontColor: '#67ccdf'
        },
        DYDX: {
          logo: ExchangeInformations.exchangeInfos.DYDX.icon,
          fontColor: '#67ccdf'
        },
        OKX: {
          logo: ExchangeInformations.exchangeInfos.OKX.icon,
          fontColor: '#67ccdf'
        },
        BYBIT: {
          logo: ExchangeInformations.exchangeInfos.BYBIT.icon,
          fontColor: '#67ccdf'
        },
        MEXC: {
          logo: ExchangeInformations.exchangeInfos.MEXC.icon,
          fontColor: '#67ccdf'
        }
      },
      strategyList: [],
      loading: {
        refresh: false
      }
    }
  },
  created() {
    this.render();
  },
  methods: {
    render: function () {
      let that = this;
      that.loading.refresh = true;
      StrategyLibsService.strategyLibQuery(undefined, function (res) {
        that.strategyList = res;
        that.loading.refresh = false;
      });
    },
    createStrategyLib: function () {
      this.$refs['createStrategy'].render();
    },
    openStrategyDetails: function (libId) {
      this.$refs['strategyDetail'].render(libId);
    },
    doStrategyFilter: function (name) {
      if (this.filterName === '') {
        return name;
      }
      if (name.toUpperCase().indexOf(this.filterName.toUpperCase()) > -1) {
        return name;
      }
    }
  }
}
</script>

<style scoped>

.ellipsis-line-s-l {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

</style>