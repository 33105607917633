<template>
  <div>
    <el-drawer
        class="open-task-trade-tools"
        size="180px"
        :modal="true"
        append-to-body
        :close-on-click-modal=false
        :close-on-press-escape=false
        :wrapperClosable=false
        custom-class="black-card"
        :visible.sync="drawer"
        :with-header="false"
        direction="btt">
      <el-button class="dark-mode-btn" style="width: 100%;height: 30px;margin-top: 0;border-right: 0;border-top: 0;border-left: 0;border-radius: 0;font-size: 23px;padding: 0" @click="close" :loading="processing"><i class="el-icon-close" /></el-button>
      <el-row style="width: 98%;margin: 20px auto 0 auto">
        <el-col :span="10" :offset="1">
          <el-select class="select-symbol" style="width: 100%" v-model="selectSymbol" placeholder="请选择" >
            <el-option
                v-for="item in symbols"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="10" :offset="2">
          <el-select class="select-symbol" style="width: 100%" v-model="operationSelect" placeholder="请选择" >
            <el-option
                v-for="item in ['SHORT', 'LONG']"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row style="width: 98%;margin: 20px auto 0 auto">
        <el-col :span="10" :offset="1">
          <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="amount">
            <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">Amount</span></template>
          </el-input>
        </el-col>
        <el-col :span="10" :offset="2">
          <el-button class="dark-mode-btn" style="width: 100%;color: #FFFFFF;padding: 10px" @click="openOrder" :loading="processing" v-auth:dbWriteApi>提交</el-button>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>

<script>
import TradeApiService from "@/api/trade/TradeApiService";
import {Message} from "element-ui";
import ConfirmProvider from "@/components/ConfirmProvider";

export default {
  name: "TradeTools",
  data: function () {
    return {
      drawer: false,
      amount: 0,
      operationSelect: 'SHORT',
      selectSymbol: '',
      symbols: [],
      ins: {},
      processing: false,
      platform: ''
    }
  },
  methods: {
    renderFtx: function (strategyInstance) {
      this.platform = 'FTX';
      this.ins = strategyInstance;
      this.drawer = true;
      this.symbols = [];
      this.symbols.push.apply(this.symbols, strategyInstance.strategyConfig.checkPositionSymbol1Array);
      this.symbols.push.apply(this.symbols, strategyInstance.strategyConfig.checkPositionSymbol2Array);
      this.amount = Math.abs(Number.prototype.accAdd(strategyInstance.strategyConfig.checkPositionSymbolList1RealPosition, strategyInstance.strategyConfig.checkPositionSymbolList2RealPosition));
      if (Math.abs(strategyInstance.strategyConfig.checkPositionSymbolList1RealPosition) > Math.abs(strategyInstance.strategyConfig.checkPositionSymbolList2RealPosition)) {
        this.selectSymbol = strategyInstance.strategyConfig.checkPositionSymbol1Array[0];
        this.operationSelect = strategyInstance.strategyConfig.checkPositionSymbolList1RealPosition > 0 ? 'SHORT' : 'LONG';
      } else {
        this.selectSymbol = strategyInstance.strategyConfig.checkPositionSymbol2Array[0];
        this.operationSelect = strategyInstance.strategyConfig.checkPositionSymbolList2RealPosition > 0 ? 'SHORT' : 'LONG';
      }
    },
    renderBinanceUSD: function (strategyInstance) {
      this.platform = 'BINANCE';
      this.ins = strategyInstance;
      this.drawer = true;
      this.symbols = [];
      this.symbols.push.apply(this.symbols, strategyInstance.strategyConfig.checkPositionSymbol1Array);
      this.symbols.push.apply(this.symbols, strategyInstance.strategyConfig.checkPositionSymbol2Array);
      this.amount = Math.abs(Number.prototype.accAdd(strategyInstance.strategyConfig.checkPositionSymbolList1RealPosition, strategyInstance.strategyConfig.checkPositionSymbolList2RealPosition));
      if (Math.abs(strategyInstance.strategyConfig.checkPositionSymbolList1RealPosition) > Math.abs(strategyInstance.strategyConfig.checkPositionSymbolList2RealPosition)) {
        this.selectSymbol = strategyInstance.strategyConfig.checkPositionSymbol1Array[0];
        this.operationSelect = strategyInstance.strategyConfig.checkPositionSymbolList1RealPosition > 0 ? 'SHORT' : 'LONG';
      } else {
        this.selectSymbol = strategyInstance.strategyConfig.checkPositionSymbol2Array[0];
        this.operationSelect = strategyInstance.strategyConfig.checkPositionSymbolList2RealPosition > 0 ? 'SHORT' : 'LONG';
      }
    },
    renderOkx: function (strategyInstance) {
      this.platform = 'OKX';
      this.ins = strategyInstance;
      this.drawer = true;
      this.symbols = [];
      this.symbols.push.apply(this.symbols, strategyInstance.strategyConfig.checkPositionSymbol1Array);
      this.symbols.push.apply(this.symbols, strategyInstance.strategyConfig.checkPositionSymbol2Array);
      this.amount = Math.abs(Number.prototype.accAdd(strategyInstance.strategyConfig.checkPositionSymbolList1RealPosition, strategyInstance.strategyConfig.checkPositionSymbolList2RealPosition));
      if (Math.abs(strategyInstance.strategyConfig.checkPositionSymbolList1RealPosition) > Math.abs(strategyInstance.strategyConfig.checkPositionSymbolList2RealPosition)) {
        this.selectSymbol = strategyInstance.strategyConfig.checkPositionSymbol1Array[0];
        this.operationSelect = strategyInstance.strategyConfig.checkPositionSymbolList1RealPosition > 0 ? 'SHORT' : 'LONG';
      } else {
        this.selectSymbol = strategyInstance.strategyConfig.checkPositionSymbol2Array[0];
        this.operationSelect = strategyInstance.strategyConfig.checkPositionSymbolList2RealPosition > 0 ? 'SHORT' : 'LONG';
      }
    },
    renderDeribit: function (strategyInstance) {
      this.platform = 'DERIBIT';
      this.ins = strategyInstance;
      this.drawer = true;
      this.symbols = [];
      this.symbols.push(strategyInstance.strategyConfig.symbol1);
      this.symbols.push(strategyInstance.strategyConfig.symbol2);
      this.amount = Number(Number.prototype.accSub(strategyInstance.strategyConfig.realCoinDiffValue, 0));
      if (this.amount > 0) {
        if (strategyInstance.strategyConfig.symbol1Short) {
          this.selectSymbol = strategyInstance.strategyConfig.symbol2;
          this.operationSelect = 'SHORT';
        } else {
          this.selectSymbol = strategyInstance.strategyConfig.symbol1;
          this.operationSelect = 'SHORT';
        }
      } else {
        this.amount = Math.abs(this.amount);
        if (strategyInstance.strategyConfig.symbol1Short) {
          this.selectSymbol = strategyInstance.strategyConfig.symbol1;
          this.operationSelect = 'LONG';
        } else {
          this.selectSymbol = strategyInstance.strategyConfig.symbol2;
          this.operationSelect = 'LONG';
        }
      }
    },
    close: function () {
      this.drawer = false;
    },
    openOrder: function () {
      let that = this;
      if (this.amount === 0) {
        Message.error('下单数量异常')
        return;
      }
      ConfirmProvider.openConfirm(this, '下单确认', that.selectSymbol + ' ' + that.operationSelect + ' ' + that.amount, function () {
        that.processing = true;
        TradeApiService.openOrder({
          instanceId: that.ins.instanceEntity.id,
          sid: that.ins.sid,
          platform: that.platform,
          orderType: 'MARKET',
          side: that.operationSelect,
          symbol: that.selectSymbol,
          amount: Math.abs(that.amount)
        }, function (orderInfo) {
          Message.success(that.platform + ': ' + that.selectSymbol + ' ' + that.operationSelect + ' ' + orderInfo.realAmount + ' AT ' + orderInfo.realCostPrice);
          that.processing = false;
        }, function () {
          that.processing = false;
        });
      });
    }
  }
}
</script>

<style scoped>

.open-task-trade-tools {
  width: 60%;
  margin-left: 40%
}

@media only screen and  (max-width: 568px) {
  .open-task-trade-tools {
    width: 100%;
    margin-left: 0
  }
}


</style>