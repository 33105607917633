<template>
  <div class="panel-scoped">
  
    <el-row>
      <el-col :span="16">
        <FtGroupButtonWidget :buttons="[{
            id: 'append',
            text: '常用钱包列表',
            textI18n: '',
        }]" mode="single" textMode="true"
        style="background: #3E454E;" />
      </el-col>
      <el-col :span="8" style="padding-top: 3px">
        <input class="dark-mode-input" v-model="filterWalletName" placeholder="Filter..." style="color: white;height: 25px;outline: none;font-size: 12px;background-color: rgba(0, 0, 0, 0);border: 0;width: 98px;float: right;border-bottom: 1px solid #444c56;padding-left: 18px;padding-bottom: 1px" />
        <i class="el-icon-search" style="float: right;color: #444c56;margin-top: 5px;margin-right: -15px"></i>
      </el-col>
    </el-row>

    <div style="margin-top: 20px;">

      <div :class="[(scrollHeight > 0 ? 'scroll-mode' : '')]" :style="(scrollHeight > 0 ? {height: scrollHeight + 'px'} : {})">
        <div style="display: flex; margin-bottom: 8px;" v-for="(item) in (commonUsedWalletList.filter((w) => doFilter(w)))" :key="item.id"
          v-show="(item.walletType === 'DEX' && filterTargetChain ? (item.dexChain === filterTargetChain) : true)">
          <div style="margin-right: 3px; display: none;">
            <div @click.stop="checkCell(item)" class="ft-checkbox" :class="[checkedList.indexOf(item.id) > -1 ? 'active' : '']">
              <div></div>
            </div>
          </div>
          <div @click.stop="checkCell(item)" class="black-card-hover" style="flex: 1; position: relative;cursor: pointer;border: 0;margin-top: 0;height: 30px;padding-top: 6px;padding-left: 8px;padding-right: 6px">
            <el-row v-if="item.walletType === 'CEX'">
              <el-col :span="4">
                <span class="dark-mode-font" style="float: left;text-align: left;display: inline-block;color: white;width: 90%;font-size: 12px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{(item.humanReadableName ? item.humanReadableName : item.apiKey)}}</span>
              </el-col>
              <el-col :span="16">
                <span class="dark-mode-font" style="float: left;text-align: left;display: inline-block;color: lightgray;width: 90%;font-size: 12px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{(item.address)}}</span>
              </el-col>
              <el-col :span="4">
                <span class="dark-mode-font" style="float: right;text-align: right;display: inline-block;color: white;width: 90%;font-size: 12px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{(item.dexChain)}}</span>
              </el-col>
            </el-row>
            <el-row v-if="item.walletType === 'DEX'">
              <el-col :span="4">
                <span class="dark-mode-font" style="float: left;text-align: left;display: inline-block;color: white;width: 90%;font-size: 12px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{(item.humanReadableName ? item.humanReadableName : item.address)}}</span>
              </el-col>
              <el-col :span="16">
                <span class="dark-mode-font" style="float: left;text-align: left;display: inline-block;color: lightgray;width: 90%;font-size: 12px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{(item.address)}}</span>
              </el-col>
              <el-col :span="4">
                <span class="dark-mode-font" style="float: right;text-align: right;display: inline-block;color: white;width: 90%;font-size: 12px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{(item.dexChain)}}</span>
              </el-col>
            </el-row>

            
            <div @click.stop="onAction({id: 'delCommonUsedWallet', value: item})"
              style="position: absolute; top: 6px; right: -18px; width: 15px; height: 15px; background: rgba(0, 0, 0, 0); color: white; cursor: pointer; border-radius: 16px; font-size: 11px; padding-bottom: 5px;">
              <i class="el-icon-delete del" style="font-size: 12px;border: 0;-webkit-transform: scale(0.8);"></i>
            </div>
          </div>
        </div>
      </div>

      <div style="display: flex;margin-top: 10px; margin-bottom: 5px;">
        <div style="flex: 1;"></div>
        <div style="text-align: center">
          <FtGroupButtonWidget :buttons="[{
              id: 'createCommonUsedWallet',
              text: '添加常用钱包',
              textI18n: '',
          }]" mode="single" v-on:switch-change="onAction" 
          style="background: #3E454E;" />
        </div>
        <div style="flex: 1;"></div>
      </div>

    </div>

  </div>
</template>

<script>
import {Message} from "element-ui";
import ConfirmProvider from '@/components/ConfirmProvider'

// import CrmChannelManagement from "@/api/crm/CrmChannelManagement";
import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";

import { useWalletStore, useWalletOutsideStore } from '@/store/wallet'
import WalletManagement from "@/api/wallet/WalletManagement"
import TrialphaLoading from "@/components/TrialphaLoading";

export default {
  components: {
    FtGroupButtonWidget
  },
  props: {},
  data() {
    return {
      // 选择的钱包
      checkedList: [],

      // 滚动面板高度
      scrollHeight: 0,
      filterWalletName: ''
    }
  },
  computed: {
      // 转出钱包地址
      commonUsedWalletList: () => {
        let walletOutsideStore = useWalletOutsideStore()
        return walletOutsideStore.commonUsedWalletList
      },
      // 目标链类型
      filterTargetChain: () => {
        let walletOutsideStore = useWalletOutsideStore()
        return walletOutsideStore.filterTargetChain
      }
  },
  mounted() {

    var windowHeight = window.innerHeight
    this.scrollHeight = windowHeight - 240 - 120

  },
  methods: {
    checkCell(item) {
      if (this.filterTargetChain == '') {
        Message.error('请先选择目标链')
        return
      }
      if (this.checkedList.indexOf(item.id) > -1) {
        this.checkedList.splice(this.checkedList.indexOf(item.id), 1)
        this.$emit('switch-change', {id: 'commonUsedWalletUnchecked', value: item})
      } else {
        this.checkedList.push(item.id)
        this.$emit('switch-change', {id: 'commonUsedWalletChecked', value: item})
      }
    },
    // 各种切换处理
    onAction(item) {
      let that = this
      console.log(item)
      if (item.id == 'delCommonUsedWallet') {
        let d = item.value
        ConfirmProvider.openConfirm(that, '操作确认', '删除常用钱包确认', function () {
          let params = {
            id: d.cid
          }
          let loading = TrialphaLoading.loading(that);
          WalletManagement.deleteCommonUsedWallet(params).then(() => {
            let walletStore = useWalletStore()
            walletStore.listCommonUsedWallet().then(() => {
              loading.close()
            })
          }, () => {
            let walletStore = useWalletStore()
            walletStore.listCommonUsedWallet().then(() => {
              loading.close()
            })
          })
        })
      } else {
        this.$emit('switch-change', item)
      }
    },
    uncheckedCommonUsedWallet(commonUsedWallet) {
      if (this.checkedList.indexOf(commonUsedWallet.id) > -1) {
        this.checkedList.splice(this.checkedList.indexOf(commonUsedWallet.id), 1)
      }
    },
    resetFormData() {
      // 选择的钱包
      this.checkedList = []
    },
    doFilter(wallet) {
      if (this.filterWalletName === '' || !this.filterWalletName) {
        return wallet;
      } else {
        if ((wallet.humanReadableName && wallet.humanReadableName.toUpperCase().indexOf(this.filterWalletName.toUpperCase()) > -1) ||
            (wallet.apiKey && wallet.apiKey.toUpperCase().indexOf(this.filterWalletName.toUpperCase()) > -1) ||
            (wallet.address && wallet.address.toUpperCase().indexOf(this.filterWalletName.toUpperCase()) > -1)) {
          return wallet;
        }
      }
    }
  }
}
</script>

<style scoped>
.panel-scoped{
  background: #22272E;
  padding: 20px;
  border: 1px solid #3E454E;
  border-radius: 8px;
}

.panel-scoped .ft-checkbox{
  width: 20px; height: 20px; border-radius: 4px; border: 1px solid #3E454E; margin-top: 6px;
  cursor: pointer;
}

.panel-scoped .ft-checkbox > div{
  width: 12px; height: 12px; background: transparent; border-radius: 2px; margin: 3px;
}

.panel-scoped .ft-checkbox.active > div{
  background: #fff;
}

.scroll-mode{
  overflow-y: auto;
  padding: 16px 16px 0 0;
}

.del {
  color: gray;
}

.del:hover {
  color: white;
}
</style>
