<script type="text/javascript">
import AbmNet from "@/api/net/AbmNet";
import ServiceMapper from "@/const/ServiceMapper"
import CacheService from "@/cache/CacheService";
import Optional from "@/optional/Optional";
import CryptoService from "@/utils/CryptoService";

function getTmpConfigs() {
  return {
    isLock: false,
    isLogin: true,
    lockWaitSecond: 60 * 16,
    loadPageSize: 5
  }
}

function getToken() {
  return Optional.orElse(() => {
    return getUserObj().token;
  }, null);
}

function getUserName() {
  return Optional.orElse(() => {
    return getUserObj().userInfo.username;
  }, null);
}

function getUserId() {
  return Optional.orElse(() => {
    return getUserObj().userInfo.id;
  }, null);
}

function getPassword() {
  return Optional.orElse(() => {
    return getUserObj().password;
  }, null);
}

function getEmail() {
  return Optional.orElse(() => {
    return getUserObj().email;
  }, null);
}

function isLogin() {
  return Optional.orElse(() => {
    return getUserObj().isLogin;
  }, false);
}

function isLock() {
  return Optional.orElse(() => {
    return getUserObj().isLock;
  }, false);
}

function getUserObj() {
  let randomKey = CacheService.get('RANDOM_KEY');
  let userStr = CacheService.get('USER');
  if (userStr !== null && randomKey !== null) {
    let realKey = CacheService.get('RANDOM_KEY') + 'AAF';
    let realIV = 'AAF' + (CacheService.get('RANDOM_KEY') + (CacheService.get('RANDOM_KEY') - 168));
    let user = JSON.parse(CryptoService.decrypt(userStr, realKey, realIV));
    if (new Date().getTime() < user.expiredAt) {
      return user;
    } else {
      CacheService.del('USER');
    }
  }
  return null;
}

function updateUserObj(user) {
  CacheService.set('RANDOM_KEY', new Date().getTime());
  let realKey = CacheService.get('RANDOM_KEY') + 'AAF';
  let realIV = 'AAF' + (CacheService.get('RANDOM_KEY') + (CacheService.get('RANDOM_KEY') - 168));
  let value = CryptoService.encrypt(JSON.stringify(user), realKey, realIV);
  CacheService.set('USER', value, user.expiredAt - new Date().getTime());
}

function login(username, password, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.userLogin, {
    username: username,
    password: password
  }, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function logout(success, error) {
  if (getToken() === null || isLogin() === false || isLogin() === null){
    error();
  }
  AbmNet.postRequestWithoutPermissionCheck(ServiceMapper.WEB_SERVICE.userLogout, {
    username: getUserName(),
    token: getToken()
  }, function () {
    success();
  }, function () {
    error();
  });
}

//判断是否是已登录的合法User，是 -》 success，否 -》error
function isValidLoginUser(success, error) {
  if (getToken() === null || isLogin() === false || isLogin() === null){
    error();
  }
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.userLoginValidate, {
    username: getUserName(),
    token: getToken()
  }, function () {
    success();
  }, function () {
    error();
  });
}

function hasAccessPermission(path, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.uiPathPermissionValidate + "?uiPath=" + path, success, error);
}

function statistics(second) {
  if (getUserName() !== null) {
    AbmNet.getRequest(ServiceMapper.WEB_SERVICE.statistics + '?username=' + getUserName() + '&second=' + second, function (){}, function (){});
  }
}

function clear() {
  CacheService.del('USER');
}

function createUser(userObj, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.userRegister, userObj, function () {
    success();
  }, function () {
    error();
  });
}

function getLoginNonce(address, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.userGetLoginNonce + '?address=' + address, function (data) {
    success(data);
  }, function (err) {
    if (error) {
      error(err);
    }
  });
}

function getUnlockNonce(address, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.userGetUnlockNonce + '?address=' + address, function (data) {
    success(data);
  }, function (err) {
    if (error) {
      error(err);
    }
  });
}

function getNonce(address, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.userGetNonceForBindWallet + '?address=' + address, function (data) {
    success(data);
  }, function (err) {
    if (error) {
      error(err);
    }
  });
}

function validateNonce(sign, address, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.userValidateNonceForBindWallet, {
    signature: sign,
    address: address
  }, function (data) {
    success(data);
  }, function (err) {
    if (error) {
      error(err);
    }
  });
}

function bindNewWallet(signature, address, code, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.userBindWallet, {
    signature: signature,
    address: address,
    code: code
  }, function (data) {
    success(data);
  }, function (err) {
    if (error) {
      error(err);
    }
  });
}

function postLoginNonce(sign, address, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.userLoginNonceValidate, {
    signature: sign,
    address: address
  }, function (data) {
    success(data);
  }, function (err) {
    if (error) {
      error(err);
    }
  });
}

export default {
  getNonce,
  validateNonce,
  bindNewWallet,
  getLoginNonce,
  getUnlockNonce,
  postLoginNonce,
  getToken,
  getEmail,
  getUserName,
  getUserId,
  getPassword,
  getUserObj,
  login,
  logout,
  clear,
  isValidLoginUser,
  hasAccessPermission,
  isLogin,
  createUser,
  statistics,
  isLock,
  updateUserObj,
  getTmpConfigs
};
</script>

<style scoped>
</style>