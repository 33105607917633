<template>
  <el-card class="black-card" style="width: 100%;height: 208px;border: 0">
    <el-row>
      <el-col :span="24">
        <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 18px;color: #FFFFFF">{{ $i('riskFreeProfit.RiskFreeRateOfReturn') }}</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 12px;"><i class="el-icon-info" /> {{ $i('riskFreeProfit.FromBinanceDemandDepositRates') }}</span>
        <span class="dark-mode-font" style="display: inline-block;float: right;font-size: 12px;margin-right: 5px;color: #FFFFFF">{{ $i('riskFreeProfit.APR') }}</span>
      </el-col>
    </el-row>
    <el-row style="margin-top: 10px">
      <el-col :span="24">
        <div id="bnRiskFreeChart" ref="bnRiskFreeChart" style="width: 100%;height: 110px"></div>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>

import PlatformInfoService from "@/api/platform/PlatformInfoService";
import TimeService from "@/utils/TimeService";

export default {
  name: "RiskFreeProfit",
  data: function () {
    return {
      xList: [0],
      BTC: [0],
      ETH: [0],
      USDC: [0],
      usds: ['BTC', 'ETH', 'USDT'],
      datas: [
        {
          showSymbol: false,
          name: 'BTC',
          type: 'line',
          data: [0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1],
          smooth: true,
          itemStyle: {
            normal: {
              areaStyle: {
                type: 'default'
              },
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [{
                    offset: 0,
                    color: 'rgba(247,147,26, 0.2)'
                  },
                    {
                      offset: 0.5,
                      color: 'rgba(247,147,26, 0.05)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(247,147,26, 0.01)'
                    }
                  ]
              ),
              lineStyle: {        // 系列级个性化折线样式
                width: 2,
                type: 'solid',
                color: "rgb(247,147,26)"
              },
              opacity: 0
            }
          },
        },
        {
          showSymbol: false,
          name: 'ETH',
          type: 'line',
          data: [0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1],
          smooth: true,
          itemStyle: {
            normal: {
              areaStyle: {
                type: 'default'
              },
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [{
                    offset: 0,
                    color: 'rgba(104, 126, 227, 0.2)'
                  },
                    {
                      offset: 0.5,
                      color: 'rgba(104, 126, 227, 0.05)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(104, 126, 227, 0.01)'
                    }
                  ]
              ),
              lineStyle: {        // 系列级个性化折线样式
                width: 2,
                type: 'solid',
                color: "rgb(104, 126, 227)"
              },
              opacity: 0
            }
          },
        },
        {
          showSymbol: false,
          name: 'USDT',
          type: 'line',
          data: [1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2],
          smooth: true,
          itemStyle: {
            normal: {
              areaStyle: {
                type: 'default'
              },
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [{
                    offset: 0,
                    color: 'rgb(30,172,238,0.2)'
                  },
                    {
                      offset: 0.5,
                      color: 'rgba(30,172,238, 0.05)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(30,172,238, 0.01)'
                    }
                  ]
              ),
              lineStyle: {        // 系列级个性化折线样式
                width: 2,
                type: 'solid',
                color: "rgb(18,177,250)"
              },
              opacity: 0
            }
          },
        }
      ]
    }
  },
  created() {
    this.$nextTick(() =>{
      this.render();
    })
  },
  methods: {
    getOption(datas) {
      return {
        legend: {
          show: true,
          orient: 'horizontal',
          borderWidth: 0,
          itemWidth: 16,
          itemHeight: 16,
          top: 70,
          right: '25px',
          data: [
            {
              name: 'USDC',
              formatter: function () {
                return '';
              },
              textStyle: {
                fontFamily: 'DIN-Bold',
                fontSize: 12,
                color: 'white',
                padding: [4, 1, 1, 1]
              },
              icon: 'image://https://abm-app-image.s3.ap-northeast-1.amazonaws.com/coins/USD.png'
            },
            {
              name: 'USDT',
              formatter: function () {
                return '';
              },
              textStyle: {
                fontFamily: 'DIN-Bold',
                fontSize: 12,
                color: 'white',
                padding: [4, 1, 1, 1]
              },
              icon: 'image://https://abm-app-image.s3.ap-northeast-1.amazonaws.com/coins/USD.png'
            },
            {
              name: 'ETH',
              formatter: function () {
                return '';
              },
              textStyle: {
                fontFamily: 'DIN-Bold',
                fontSize: 12,
                color: 'white',
                padding: [4, 1, 1, 1]
              },
              icon: 'image://https://abm-app-image.s3.ap-northeast-1.amazonaws.com/coins/ETH.png'
            },
            {
              name: 'BTC',
              formatter: function () {
                return '';
              },
              textStyle: {
                fontFamily: 'DIN-Bold',
                fontSize: 12,
                color: 'white',
                padding: [4, 1, 1, 1]
              },
              icon: 'image://https://abm-app-image.s3.ap-northeast-1.amazonaws.com/coins/BTC.png'
            }
          ]
        },
        tooltip: {
          trigger: 'axis'
        },
        calculable: true,
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          show: true,
          data: this.xList,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          }
        }],
        grid: {
          x: 0,
          y: 10,
          x2: 25,
          y2: -5
        },
        yAxis: [{
          position: 'right',
          type: 'value',
          show: true,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#2a3237'
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: 8, //更改坐标轴文字大小
              color: 'white'
            }
          }
        }],
        series: datas
      };
    },
    render() {
      let that = this;
      if (!that.chart) {
        PlatformInfoService.queryBinanceRate(function (data) {
          let x = [];
          let USDT = [];
          let BTC = [];
          let ETH = [];
          if (!data) {
            x = [0, 1, 2, 3];
            USDT = [1.2, 1.2, 1.2, 1.2];
            BTC = [0.1, 0.1, 0.1, 0.1];
            ETH = [0.1, 0.1, 0.1, 0.1];
          } else {
            data.forEach((item) => {
              if (item.ratioMap) {
                x.push(TimeService.timestampToDateWithoutTime(item.timestamp));
                if (item.ratioMap.USDT) {
                  USDT.push((item.ratioMap.USDT * 100).toFixed(2));
                }
                if (item.ratioMap.BTC) {
                  BTC.push((item.ratioMap.BTC * 100).toFixed(2));
                }
                if (item.ratioMap.ETH) {
                  ETH.push((item.ratioMap.ETH * 100).toFixed(2));
                }
              }
            });
          }
          if (that.$refs['bnRiskFreeChart']) {
            that.xList = x;
            that.USDT = USDT;
            that.BTC = BTC;
            that.ETH = ETH;
            that.chart = that.$echarts.init(that.$refs['bnRiskFreeChart']);
            that.chart.clear();
            that.datas.forEach((d) => {
              if (d.name === 'BTC') {
                d.data = that.BTC;
              } else if (d.name === 'ETH') {
                d.data = that.ETH;
              } else if (d.name === 'USDC') {
                d.data= that.USDC;
              } else if (d.name === 'USDT') {
                d.data= that.USDT;
              }
            });
            that.chart.setOption(that.getOption(that.datas));
            window.addEventListener('resize', () => (that.chart.resize()));
          }
        });
      }
    }
  }
}
</script>

<style scoped>

</style>