<template>
  <el-card class="trade-black-card" style="height: 100%;width: 100%;border-radius: 0;border: 0;z-index: 0" body-style="padding: 0" shadow="none">
    <div class="hide-less-1201" style="height: 450px">
      <el-card class="trade-black-card" style="height: 200px;border-radius: 0;border-right: 0;border-bottom: 0;border-top: 0;" body-style="padding: 0" shadow="none">
        <el-card class="orderbook-item-ask" body-style="padding: 0" shadow="none" :style="'background: linear-gradient(to left, rgba(246, 70, 93, 0.08) ' + (askItem.rate) + ', rgba(0, 0, 0, 0) ' + (askItem.rate) + ')'" v-for="askItem in ask" :key="askItem.price">
          <el-row class="orderbook-item-ask" style="z-index: 10;padding: 0 15px 0 15px;width: 100%;height: 20px;">
            <el-col :span=8 style=";padding-top: 0">
              <span style="color: rgb(246, 70, 93);font-size: 12px;display: inline-block;float: left;background-color: rgba(0, 0, 0, 0)">{{ askItem.price }}</span>
            </el-col>
            <el-col :span="8" style=";padding-top: 0">
              <span style="color: rgb(183, 189, 198);font-size: 12px;display: inline-block;float: right">{{ askItem.amount }}</span>
            </el-col>
            <el-col :span="8" style=";padding-top: 0">
              <span style="color: rgb(183, 189, 198);font-size: 12px;display: inline-block;float: right">{{ askItem.total }}</span>
            </el-col>
          </el-row>
        </el-card>
      </el-card>
      <el-card class="trade-black-card" style="height: 50px;border-radius: 0;border-right: 0;border-bottom: 0;border-top: 0;" body-style="padding: 10px 15px 10px 15px" shadow="none">
        <span style="color: white;font-size: 16px;display: inline-block;float: left;margin-left: 0">{{ askR[0].price }}</span>
        <span v-if="ask.length > 0" style="color: grey;font-size: 12px;margin-top: 4px;display: inline-block;float: right;margin-right: 0">SPREAD: {{ midPrice }}</span>
      </el-card>
      <el-card class="trade-black-card" style="height: 200px;border-radius: 0;border-right: 0;border-bottom: 0;border-top: 0;" body-style="padding: 0" shadow="none">
        <el-card class="orderbook-item-ask" body-style="padding: 0" shadow="none" :style="'background: linear-gradient(to left, rgba(14, 203, 129, 0.08) ' + (bidItem.rate) + ', rgba(0, 0, 0, 0) ' + (bidItem.rate) + ')'" v-for="bidItem in bid" :key="bidItem.price">
          <el-row class="orderbook-item-ask" style="z-index: 10;padding: 0 15px 0 15px;width: 100%;height: 20px;">
            <el-col :span=8 style=";padding-top: 0">
              <span style="color: rgb(14, 203, 129);font-size: 12px;display: inline-block;float: left;background-color: rgba(0, 0, 0, 0)">{{ bidItem.price }}</span>
            </el-col>
            <el-col :span="8" style=";padding-top: 0">
              <span style="color: rgb(183, 189, 198);font-size: 12px;display: inline-block;float: right">{{ bidItem.amount }}</span>
            </el-col>
            <el-col :span="8" style=";padding-top: 0">
              <span style="color: rgb(183, 189, 198);font-size: 12px;display: inline-block;float: right">{{ bidItem.total }}</span>
            </el-col>
          </el-row>
        </el-card>
      </el-card>
    </div>
    <div class="hide-more-1201 hide-less-651">
      <el-card class="trade-black-card" style="height: 161px;border-radius: 0;border: 0;width: 50%;float: left;overflow-y: auto" body-style="padding: 0" shadow="none">
        <el-card class="orderbook-item-ask" body-style="padding: 0" shadow="none" :style="'background: linear-gradient(to left, rgba(14, 203, 129, 0.08) ' + (bidItem.rate) + ', rgba(0, 0, 0, 0) ' + (bidItem.rate) + ')'" v-for="bidItem in bid" :key="bidItem.price">
          <el-row class="orderbook-item-ask" style="z-index: 10;padding: 0 15px 0 15px;width: 100%;height: 20px;">
            <el-col :span=12 style=";padding-top: 0">
              <span style="color: rgb(183, 189, 198);font-size: 12px;display: inline-block;float: left;">{{ bidItem.total }}</span>
            </el-col>
            <el-col :span="12" style=";padding-top: 0">
              <span style="color: rgb(14, 203, 129);font-size: 12px;display: inline-block;float: right;">{{ bidItem.price }}</span>
            </el-col>
          </el-row>
        </el-card>
      </el-card>
      <el-card class="trade-black-card" style="height: 161px;border-radius: 0;border: 0;width: 50%;float: right;overflow-y: auto" body-style="padding: 0" shadow="none">
        <el-card class="orderbook-item-ask" body-style="padding: 0" shadow="none" :style="'background: linear-gradient(to right, rgba(246, 70, 93, 0.08) ' + (askItem.rate) + ', rgba(0, 0, 0, 0) ' + (askItem.rate) + ')'" v-for="askItem in askR" :key="askItem.price">
          <el-row class="orderbook-item-ask" style="z-index: 10;padding: 0 15px 0 15px;width: 100%;height: 20px;">
            <el-col :span="12" style=";padding-top: 0">
              <span style="color: rgb(246, 70, 93);font-size: 12px;display: inline-block;float: left;">{{ askItem.price }}</span>
            </el-col>
            <el-col :span=12 style=";padding-top: 0">
              <span style="color: rgb(183, 189, 198);font-size: 12px;display: inline-block;float: right;">{{ askItem.total }}</span>
            </el-col>
          </el-row>
        </el-card>
      </el-card>
    </div>
    <div class="hide-more-651">
      <el-card class="trade-black-card" style="height: 200px;border-radius: 0;border: 0" body-style="padding: 0" shadow="none">
        <el-card class="orderbook-item-ask" body-style="padding: 0" shadow="none" :style="'background: linear-gradient(to left, rgba(246, 70, 93, 0.08) ' + (askItem.rate) + ', rgba(0, 0, 0, 0) ' + (askItem.rate) + ')'" v-for="askItem in ask" :key="askItem.price">
          <el-row class="orderbook-item-ask" style="z-index: 10;padding: 0 5px 0 5px;width: 100%;height: 20px;">
            <el-col :span=12 style=";padding-top: 0">
              <span style="color: rgb(246, 70, 93);font-size: 12px;display: inline-block;float: left;background-color: rgba(0, 0, 0, 0)">{{ askItem.price }}</span>
            </el-col>
            <el-col :span="12" style=";padding-top: 0">
              <span style="color: rgb(183, 189, 198);font-size: 12px;display: inline-block;float: right">{{ askItem.total }}</span>
            </el-col>
          </el-row>
        </el-card>
      </el-card>
      <el-card class="trade-black-card" style="height: 50px;border-radius: 0;border: 0" body-style="padding: 10px 5px 10px 5px" shadow="none">
        <span style="color: white;font-size: 13px;display: inline-block;float: left;margin-left: 0;margin-top: 4px">{{ askR[0].price }}</span>
        <span v-if="ask.length > 0" style="color: grey;font-size: 12px;margin-top: 4px;display: inline-block;float: right;margin-right: 0">GAP: {{ midPrice }}</span>
      </el-card>
      <el-card class="trade-black-card" style="height: 200px;border-radius: 0;border: 0" body-style="padding: 0" shadow="none">
        <el-card class="orderbook-item-ask" body-style="padding: 0" shadow="none" :style="'background: linear-gradient(to left, rgba(14, 203, 129, 0.08) ' + (bidItem.rate) + ', rgba(0, 0, 0, 0) ' + (bidItem.rate) + ')'" v-for="bidItem in bid" :key="bidItem.price">
          <el-row class="orderbook-item-ask" style="z-index: 10;padding: 0 5px 0 5px;width: 100%;height: 20px;">
            <el-col :span=12 style=";padding-top: 0">
              <span style="color: rgb(14, 203, 129);font-size: 12px;display: inline-block;float: left;background-color: rgba(0, 0, 0, 0)">{{ bidItem.price }}</span>
            </el-col>
            <el-col :span="12" style=";padding-top: 0">
              <span style="color: rgb(183, 189, 198);font-size: 12px;display: inline-block;float: right">{{ bidItem.total }}</span>
            </el-col>
          </el-row>
        </el-card>
      </el-card>
    </div>
  </el-card>
</template>

<script>

import DatafeedApiService from "@/api/trade/DatafeedApiService";

export default {
  name: "OrderBookPanel",
  data: function () {
    return {
      midPrice: 0,
      ask: [
        {
          price: 0,
          size: 0,
          total: 0
        }
      ],
      bid: [
        {
          price: 0,
          size: 0,
          total: 0
        }
      ],
      askR: [
        {
          price: 0,
          size: 0,
          total: 0
        }
      ],
      depthLength: 10,
      precision: 3,
      orderbookTimer: {}
    }
  },
  created() {
  },
  beforeDestroy() {
    clearInterval(this.orderbookTimer);
  },
  methods: {
    timestampToDate(timestamp) {
      let date = new Date(Number(timestamp));
      let h = date.getHours() + ':';
      let m = date.getMinutes() + ':';
      let s = date.getSeconds();
      return h + m + s;
    },
    render: function (platform, kind, symbol) {
      let that = this;
      if (this.orderbookTimer) {
        clearInterval(this.orderbookTimer);
      }
      this.orderbookTimer = setInterval(() => {
        DatafeedApiService.aggOrderBook(platform, kind, symbol, function (data) {
          let askTmp = [];
          let askRTmp = [];
          let askTotal = 0;
          let bidTmp = [];
          let bidTotal = 0;
          data = data.data;
          that.midPrice = Number.prototype.accSub(data['asks'][0].price, data['bids'][0].price);
          for (const key in data['asks']) {
            let obj = data['asks'][key];
            if (key < that.depthLength) {
              askTotal = Number((askTotal + Number(obj.size)).toFixed(that.precision));
              askTmp.push({
                price: obj.price,
                amount: obj.size,
                total: askTotal
              })
              askRTmp.push({
                price: obj.price,
                amount: obj.size,
                total: askTotal
              });
            } else {
              break;
            }
          }
          for (const key in data['bids']) {
            let obj = data['bids'][key];
            if (key < that.depthLength) {
              bidTotal = Number((bidTotal + Number(obj.size)).toFixed(that.precision));
              bidTmp.push({
                price: obj.price,
                amount: obj.size,
                total: bidTotal
              })
            } else {
              break;
            }
          }
          askTmp.forEach((ask) => {
            ask['rate'] = (ask.total / (askTotal > bidTotal ? askTotal : bidTotal)) * 100 + '%'
          });
          askRTmp.forEach((ask) => {
            ask['rate'] = (ask.total / (askTotal > bidTotal ? askTotal : bidTotal)) * 100 + '%'
          });
          bidTmp.forEach((bid) => {
            bid['rate'] = (bid.total / (askTotal > bidTotal ? askTotal : bidTotal)) * 100 + '%'
          });
          that.askR = askRTmp;
          askTmp.reverse();
          that.ask = askTmp;
          that.bid = bidTmp;
        });
      }, 1268);
    }
  }

}
</script>

<style scoped>

</style>