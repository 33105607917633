<template>
  <div style="width: 100%;height: 100%;overflow-y: auto;padding: 5px">
    <el-row style="margin-top: 20px">
      <general-order-panel v-if="active" ref="order-panel-mexc" v-auth:quickPlaceOrder></general-order-panel>
    </el-row>
    <el-row style="margin-top: 20px">
      <general-position-panel ref="position-panel"/>
    </el-row>
    <el-button class="dark-mode-btn" style="margin-top: 15px;width: 100%" @click="renderData">Reload</el-button>
  </div>
</template>

<script>
import GeneralApiService from "@/api/trade/GeneralApiService";
import GeneralPositionPanel from "@/components/account/GeneralPositionPanel";
import GeneralOrderPanel from "@/components/account/GeneraalOrderPanel";

export default {
  name: "MexcAccountInfo",
  components: {GeneralOrderPanel, GeneralPositionPanel},
  data: function () {
    return {
      platform: 'MEXC',
      instance: {},
      tradeAccountIns: {},
      active: false,
      position: [],
      totalPositionUsd: 0,
      spotAllPrice: {}
    }
  },
  methods: {
    render: function (ins, tradeAccountIns) {
      this.active = true;
      this.instance = ins;
      this.tradeAccountIns = tradeAccountIns;
      this.renderData();
    },
    uninstall: function () {
      this.active = false;
    },
    renderData: function () {
      let that = this;
      that.$nextTick(function () {
        that.$refs['order-panel-mexc'].render(that.tradeAccountIns, that.renderData);
      });
      that.$refs['position-panel'].render(GeneralApiService.PLATFORM.MEXC, GeneralApiService.SYMBOL_TYPE.USD_CONTRACT, this.tradeAccountIns.apiKey);
    },
  }
}
</script>

<style scoped>

.ellipsis-line-strategy {
  padding: 0;
  width: 90%;
  float: left;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.small-font {
  width: 90%;
  font-size: 12px;
  -webkit-transform: scale(1);
  text-align: right;
  display: inline-block;
  float: right;
  margin-right: 10px;
  color: #FFFFFF
}

@media only screen and  (max-width: 768px) {
  .small-font {
    width: 120%;
    font-size: 12px;
    -webkit-transform: scale(0.75);
    text-align: right;
    display: inline-block;
    float: right;
    margin-right: -2px;
    color: #FFFFFF
  }
}

.el-divider {
  border-top: 1px solid #444c56;
}

.el-collapse {
  color: #FFFFFF;
  border-top: 1px solid #444c56;
  border-bottom: 1px solid #444c56;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: white;
}

.el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

</style>