<template>
  <div :id="id" :instance="instance">
    <div style="width: 100%;height: 100%;z-index: 1" :ref="'profit_' + id + ''"></div>
    <el-row class="hidden-less-than-992" style="position: absolute;margin-top: -96px;width: 100%;margin-left: -218px;z-index: 0">
      <span class="dark-mode-font" style="color: #272b34;font-size: 50px">{{ instance.name }}</span>
    </el-row>
    <el-row class="hidden-less-than-1180" style="margin-top: -138px;margin-left: 8px;position: absolute;z-index: 2">
      <el-col :span="24">
        <div style="display: flex">
          <div v-for="(tradeAccount, index) in instance.tradeAccountList" :key="index" :style="'' + (index > 0 ? 'margin-left: 8px;margin-top: 4px' : ';margin-top: 4px') + ''">
            <img :src="exchangeInfo[tradeAccount.platform].logo" :alt="tradeAccount.platform" style="width: 18px;height: 18px;float: left;margin-top: 4px"/>
            <span class="dark-mode-font hidden-less-than-1398" :style="'margin-left: 6px;margin-top: 4px;color: white;font-size: 12px;display: inline-block;float: left'" v-if="instance.tradeAccountList.length <= 2"><b>{{ tradeAccount.platform }}</b></span>
            <i class="el-icon-link" style="color: #6da1ef;font-size: 12px;margin-left: 8px;margin-top: -5px;transform: rotate(45deg);padding-top: 0;" v-if="index < instance.tradeAccountList.length - 1"/>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="hidden-more-than-992" style="position: absolute;margin-top: -76px;width: 100%;z-index: 0">
      <el-col :span="24">
        <span class="dark-mode-font" style="color: #343947;font-size: 28px">{{ instance.name }}</span>
      </el-col>
    </el-row>
    <el-row class="hidden-more-than-1180" style="margin-top: -110px;margin-left: 8px;position: absolute;z-index: 2">
      <el-col :span="24">
        <div style="display: flex">
          <div v-for="(tradeAccount, index) in instance.tradeAccountList" :key="index" :style="'' + (index > 0 ? 'margin-left: 8px;margin-top: 4px' : ';margin-top: 4px') + ''">
            <img :src="exchangeInfo[tradeAccount.platform].logo" :alt="tradeAccount.platform" style="width: 18px;height: 18px;float: left;margin-top: 4px;z-index: 3"/>
            <span class="dark-mode-font" style="margin-left: 6px;margin-top: 4px;color: white;font-size: 12px;display: inline-block;float: left;z-index: 3" v-if="instance.tradeAccountList.length <= 2"><b>{{ tradeAccount.platform }}</b></span>
            <i class="el-icon-link" style="color: #6da1ef;font-size: 12px;margin-left: 8px;margin-top: -5px;transform: rotate(45deg);padding-top: 0;" v-if="index < instance.tradeAccountList.length - 1"/>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="hidden-sm-and-down" style="width: 100%;height: 50px;position: absolute;margin-top: -70px;margin-left: -365px" v-if="loadingData">
      <div class="trialpha-loading" style="margin-left: 50%;left: 128px">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </el-row>
    <el-row class="hidden-md-and-up" style="width: 100%;height: 50px;position: absolute;margin-top: -70px;margin-left: 0" v-if="loadingData">
      <div class="trialpha-loading" style="margin-left: 46%">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </el-row>
  </div>
</template>

<script>

import InstanceService from "@/api/trade/InstanceService";
import TimeService from "@/utils/TimeService";
import ExchangeInformations from "@/const/ExchangeInformations";

export default {
  name: 'accountProfitPanel',
  components: {},
  props: {
    id: String,
    instance: Object
  },
  data: function () {
    return {
      coinStyle: {
        BTC: {
          showSymbol: false,
          name: 'BTC',
          type: 'line',
          data: [],
          smooth: true,
          itemStyle: {
            normal: {
              areaStyle: {
                type: 'default'
              },
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [{
                    offset: 0,
                    color: 'rgba(247,147,26, 0.3)'
                  },
                    {
                      offset: 0.5,
                      color: 'rgba(247,147,26, 0.1)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(247,147,26, 0.05)'
                    }
                  ]
              ),
              lineStyle: {        // 系列级个性化折线样式
                width: 2,
                type: 'solid',
                color: "rgb(247,147,26)"
              },
              opacity: 0
            }
          },
        },
        ETH: {
          showSymbol: false,
          name: 'ETH',
          type: 'line',
          data: [],
          smooth: true,
          itemStyle: {
            normal: {
              areaStyle: {
                type: 'default'
              },
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [{
                    offset: 0,
                    color: 'rgba(104, 126, 227, 0.3)'
                  },
                    {
                      offset: 0.5,
                      color: 'rgba(104, 126, 227, 0.1)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(104, 126, 227, 0.05)'
                    }
                  ]
              ),
              lineStyle: {        // 系列级个性化折线样式
                width: 2,
                type: 'solid',
                color: "rgb(104, 126, 227)"
              },
              opacity: 0
            }
          },
        },
        USD: {
          showSymbol: false,
          name: 'USD',
          type: 'line',
          data: [],
          smooth: true,
          itemStyle: {
            normal: {
              areaStyle: {
                type: 'default'
              },
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [{
                    offset: 0,
                    color: 'rgb(30,172,238,0.3)'
                  },
                    {
                      offset: 0.5,
                      color: 'rgba(30,172,238, 0.1)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(30,172,238, 0.05)'
                    }
                  ]
              ),
              lineStyle: {        // 系列级个性化折线样式
                width: 2,
                type: 'solid',
                color: "rgb(18,177,250)"
              },
              opacity: 0
            }
          },
        }
      },
      loadingData: false,
      color: {},
      init: false,
      timeList: [],
      price: [],
      exchangeInfo: {
        BINANCE: {
          logo: ExchangeInformations.exchangeInfos.BINANCE.icon,
          fontColor: '#f5bc2f'
        },
        BITMEX: {
          logo: ExchangeInformations.exchangeInfos.BITMEX.icon,
          fontColor: '#5b69b1'
        },
        DERIBIT: {
          logo: ExchangeInformations.exchangeInfos.DERIBIT.icon,
          fontColor: '#04cfbe'
        },
        FTX: {
          logo: ExchangeInformations.exchangeInfos.FTX.icon,
          fontColor: '#67ccdf'
        },
        OKX: {
          logo: ExchangeInformations.exchangeInfos.OKX.icon,
          fontColor: '#67ccdf'
        },
        BYBIT: {
          logo: ExchangeInformations.exchangeInfos.BYBIT.icon,
          fontColor: '#67ccdf'
        },
        MEXC: {
          logo: ExchangeInformations.exchangeInfos.MEXC.icon,
          fontColor: '#67ccdf'
        },
        DYDX: {
          logo: ExchangeInformations.exchangeInfos.DYDX.icon,
          fontColor: '#67ccdf'
        },
        EVM_BASED_WALLET: {
          logo: ExchangeInformations.exchangeInfos.EVM_BASED_WALLET.icon,
          fontColor: '#67ccdf'
        }
      }
    }
  },
  created: function () {
    this.$nextTick(function () {
      this.render();
    });
  },
  methods: {
    getProfitOptionOnlyProfit(xTimeList, profitList) {
      return {
        legend: {
          show: true,
          orient: 'horizontal',
          borderWidth: 0,
          itemWidth: 16,
          itemHeight: 16,
          top: 84,
          right: '2px',
          data: [
            {
              name: 'USD',
              formatter: function () {
                return '';
              },
              textStyle: {
                fontFamily: 'DIN-Bold',
                fontSize: 12,
                color: 'white',
                padding: [4, 1, 1, 1]
              },
              icon: 'image://https://abm-app-image.s3.ap-northeast-1.amazonaws.com/coins/USD.png'
            },
            {
              name: 'ETH',
              formatter: function () {
                return '';
              },
              textStyle: {
                fontFamily: 'DIN-Bold',
                fontSize: 12,
                color: 'white',
                padding: [4, 1, 1, 1]
              },
              icon: 'image://https://abm-app-image.s3.ap-northeast-1.amazonaws.com/coins/ETH.png'
            },
            {
              name: 'BTC',
              formatter: function () {
                return '';
              },
              textStyle: {
                fontFamily: 'DIN-Bold',
                fontSize: 12,
                color: 'white',
                padding: [4, 1, 1, 1]
              },
              icon: 'image://https://abm-app-image.s3.ap-northeast-1.amazonaws.com/coins/BTC.png'
            }
          ]
        },
        tooltip: {
          trigger: 'axis'
        },
        calculable: true,
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          show: true,
          data: xTimeList,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#2a3237'
            }
          }
        }],
        grid: {
          x: 0,
          y: 0,
          x2: 1,
          y2: 0
        },
        yAxis: [{
          type: 'value',
          show: true,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#2a3237'
            }
          },
        }],
        series: profitList
      };
    },
    timestampToDate(timestamp) {
      let date = new Date(Number(timestamp));
      let Y = date.getFullYear() + '-';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let D = date.getDate() + ' ';
      let h = date.getHours() + ':';
      let m = date.getMinutes() + ':';
      let s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    render: function () {
      let that = this;
      that.loadingData = true;
      InstanceService.profitRate(this.instance.instanceId, function (data) {
        that.loadingData = false;
        let dates = [];
        let profitList = [];
        for (let i in data[0].recordTime) {
          dates.push(TimeService.timestampToDate(data[0].recordTime[i]));
        }
        for (let i in data) {
          let obj = data[i];
          if (obj.coin.indexOf('USD') > -1) {
            that.coinStyle['USD'].data = obj.profitRate;
            profitList.push(that.coinStyle['USD'])
          } else {
            that.coinStyle[obj.coin].data = obj.profitRate;
            profitList.push(that.coinStyle[obj.coin])
          }
        }
        if (that.$refs['profit_' + that.id]) {
          that.chart = that.$echarts.init(that.$refs['profit_' + that.id]);
          that.chart.clear();
          that.chart.setOption(that.getProfitOptionOnlyProfit(dates, profitList));
          window.addEventListener('resize', () => (that.chart.resize()));
        }
      }, function () {
        that.loadingData = false;
      });
    }
  }
}
</script>

<style>

@media only screen and (min-width: 992px) {
  .hidden-more-than-992 {
    display: none !important
  }
}

@media only screen and (max-width: 992px) {
  .hidden-less-than-992 {
    display: none !important
  }
}

@media only screen and (min-width: 1180px) {
  .hidden-more-than-1180 {
    display: none !important
  }
}

@media only screen and (max-width: 1180px) {
  .hidden-less-than-1180 {
    display: none !important
  }
}

@media only screen and (max-width: 1398px) {
  .hidden-less-than-1398 {
    display: none !important
  }
}

</style>