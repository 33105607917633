<template>
  <div v-if="walletInfo.networkName.toUpperCase() === chainRequired.toUpperCase()" :type="type">
    <el-card class="trade-black-card">
      <el-row>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: left">{{ type }} Stake Pool</span>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: right">{{ chainRequired }}</span>
      </el-row>
      <el-row style="margin-top: -10px">
        <el-divider class="data-divider"></el-divider>
      </el-row>
      <el-row style="margin-top: -10px">
        <span class="dark-mode-font" style="color: white;display: inline-block;float: left">Ape Price</span>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: right;font-family: 'Microsoft YaHei',serif">${{ getPrice('APEUSDT') }}</span>
      </el-row>
      <el-row>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: left">Ape Balance</span>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: right;font-family: 'Microsoft YaHei',serif">{{ Number(apeWalletBalance).toFixed(2) }}</span>
      </el-row>
      <el-row>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: left">Staked</span>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: right;font-family: 'Microsoft YaHei',serif">{{ apeStakeInfo[0].toFixed(2) }} (${{(apeStakeInfo[0] * getPrice('APEUSDT')).toFixed(2)}})</span>
      </el-row>
      <el-row>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: left">Unclaimed</span>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: right;font-family: 'Microsoft YaHei',serif">{{ apeStakeInfo[1].toFixed(2) }} (${{(apeStakeInfo[1] * getPrice('APEUSDT')).toFixed(2)}})</span>
      </el-row>
      <el-row>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: left">APR</span>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: right;font-family: 'Microsoft YaHei',serif">{{ (((apeStakeInfo[2] * 365) / apeStakeInfo[0]) * 100).toFixed(2) }}%</span>
      </el-row>
      <el-row>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: left">Unclaimed Profit Rate</span>
        <span class="dark-mode-font" style="color: white;display: inline-block;float: right;font-family: 'Microsoft YaHei',serif">{{ (((apeStakeInfo[1]) / apeStakeInfo[0]) * 100).toFixed(2) }}%</span>
      </el-row>
      <!--
      <el-row style="margin-top: 10px">
        <el-button class="dark-mode-btn" style="float: left" size="small" @click="claim">Claim</el-button>
        <el-button class="dark-mode-btn" style="float: left" size="small">Deposit</el-button>
        <el-button class="dark-mode-btn" style="float: left" size="small">Withdraw</el-button>
      </el-row>
      -->
    </el-card>
  </div>
</template>

<script>

import Web3Service from "@/web3/Web3Service";
import SymbolInfoService from "@/utils/SymbolInfoService";
import GeneralApiService from "@/api/trade/GeneralApiService";
import ApeService from "@/api/web3/ApeService";
import ApeContract from "@/web3/contract/ape/ApeContract";

export default {
  name: "APE",
  props: {
    type: String
  },
  data: function () {
    return {
      chainRequired: 'ETHEREUM',
      walletInfo: {},
      apeStakeInfo: [0, 0, 0, 0],
      apeWalletBalance: 0
    }
  },
  methods: {
    init() {
      let that = this;
      that.initWalletInfo();
      Web3Service.getWeb3(that).currentProvider.on('networkChanged', function(){
        that.initWalletInfo();
      });
      Web3Service.getWeb3(that).currentProvider.on('accountsChanged', () => {
        that.initWalletInfo();
      });
    },
    initWalletInfo() {
      let that = this;
      Web3Service.initWalletInfo(this, function (res) {
        that.walletInfo = res;
        that.initApeInfo();
        let web3 = Web3Service.getWeb3(this);
        let currentAddr = web3.currentProvider.selectedAddress;
        let contract = ApeContract.getApeCoinContractAddress(that);
        contract.methods.balanceOf(currentAddr).call().then((res) => {
          that.apeWalletBalance = res;
        });
      })
    },
    initApeInfo() {
      let that = this;
      let web3 = Web3Service.getWeb3(this);
      let currentAddr = web3.currentProvider.selectedAddress;
      ApeService.getApeStakeInfo(this.type, currentAddr, function (res) {
        that.apeStakeInfo = res;
      });
    },
    getPrice(symbol) {
      return SymbolInfoService.getSymbolPrice(GeneralApiService.PLATFORM.BINANCE, GeneralApiService.SYMBOL_TYPE.SPOT, symbol);
    },
    claim() {
      let web3 = Web3Service.getWeb3(this);
      ApeService.claimApeProfit(this.apeStakeInfo[3], web3, this.type, function (res) {
        console.log(res);
      }, function (err) {
        console.log(err);
      });
    }
  }
}
</script>

<style scoped>

.el-divider.data-divider {
  border-top: 1px solid #444c56;
}

.data-divider > .el-divider__text {
  background-color: #22272e;
}

</style>