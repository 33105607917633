<script type="text/javascript">


import AbmNet from "@/api/net/AbmNet";
import ServiceMapper from "@/const/ServiceMapper";

function queryHistoryOrder(sid, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.crossPairHistoryOrder + '?sid=' + sid, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function resetHistoryOrder(sid, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.crossPairResetHistoryOrder + '?sid=' + sid, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getSlopeChangeRate(day, modelDataTime, slope, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.crossPairSlopeChangeRate + '?day=' + day + '&modelDataTime=' + modelDataTime + '&slope=' + slope, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function queryBackTradeResult(hashCode, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.crossPairBackTradeQueryResult + '?hashCode=' + hashCode, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function queryBackTradePictureData(hashCode, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.queryBackTradePictureData + '?hashCode=' + hashCode, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}



export default {
  //查询未平历史订单
  queryHistoryOrder,
  //reset时把数据库里的未结束订单置为Inactive
  resetHistoryOrder,
  //查看不同时间间隔的slope变化率
  getSlopeChangeRate,
  //查询回测历史数据
  queryBackTradeResult,
  //查询
  queryBackTradePictureData
}

</script>


