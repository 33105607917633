<template>
  <div>
    <el-card class="trade-black-card">
      <el-row>
        <span class="dark-mode-font" style="display: inline-block;float: left;color: white;font-size: 28px">Oneport Bridge</span>
        <span class="dark-mode-font" style="display: inline-block; float: right;color: lightgray;margin-top: 10px;margin-right: 20px">{{ walletInfo.address }}</span>
      </el-row>
      <el-card class="black-card" style="margin-top: 15px;border-radius: 10px">
        <el-row>
          <span class="dark-mode-font" style="display: inline-block; float: left;color: white">Send</span>
          <span class="dark-mode-font" style="display: inline-block; float: right;color: white">Balance: {{ walletInfo.balance }}</span>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="12" style="padding-right: 8px">
            <input class="trialpha-input" style="height: 65px;border-radius: 0;border-left: 0;border-top: 0;border-right: 0;background-color: rgba(0, 0, 0, 0);font-size: 50px;" placeholder="0.0" v-model="sendAmount"/>
          </el-col>
          <el-col :span="6" style="padding-left: 20px;padding-right: 0;height: 65px">
            <el-card class="trade-black-card" style="height: 65px;cursor: not-allowed;border-radius: 8px" shadow="none" body-style="padding: 5px">
              <span class="dark-mode-font" style="color: gray;font-size: 26px;display: inline-block;margin-top: 6px">{{ walletInfo.networkName }}</span>
            </el-card>
          </el-col>
          <el-col :span="6" style="padding-left: 20px;padding-right: 0">
            <el-card class="black-card-hover" style="height: 65px;border-radius: 8px;cursor: pointer" shadow="none" body-style="padding: 5px">
              <span class="dark-mode-font" style="color: white;font-size: 26px;display: inline-block;margin-top: 6px">{{ walletInfo.networkSymbol }}</span>
            </el-card>
          </el-col>
        </el-row>
      </el-card>
      <el-row style="margin-top: 15px">
        <span><i class="el-icon-bottom" style="color: white"></i></span>
      </el-row>
      <el-card class="black-card" style="margin-top: 15px;border-radius: 10px">
        <el-row>
          <el-col :span="6">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white">To</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="12" style="padding-right: 8px">
            <input class="trialpha-input" style="height: 65px;border-radius: 0;border-left: 0;border-top: 0;border-right: 0;background-color: rgba(0, 0, 0, 0);font-size: 50px;" placeholder="0.0" v-model="sendAmount" disabled/>
          </el-col>
          <el-col :span="6" style="padding-left: 20px;padding-right: 0">
            <el-card class="black-card-hover" style="height: 65px;border-radius: 8px;cursor: pointer" shadow="none" body-style="padding: 5px">
              <span class="dark-mode-font" style="color: white;font-size: 26px;display: inline-block;margin-top: 6px">{{ walletInfo.networkName }}</span>
            </el-card>
          </el-col>
          <el-col :span="6" style="padding-left: 20px;padding-right: 0;height: 65px">
            <el-card class="trade-black-card" style="height: 65px;cursor: not-allowed;border-radius: 8px" shadow="none" body-style="padding: 5px">
              <span class="dark-mode-font" style="color: gray;font-size: 26px;display: inline-block;margin-top: 6px">{{ walletInfo.networkSymbol }}</span>
            </el-card>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="black-card" style="margin-top: 15px;border-radius: 10px">
        <el-row>
          <el-col :span="12">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;margin-top: 3px">Receive address</span>
          </el-col>
          <el-col :span="12" style="padding-left: 20px">
            <input class="trialpha-input" style="border-radius: 0;border-left: 0;border-top: 0;border-right: 0;background-color: rgba(0, 0, 0, 0);margin-top: 0" placeholder="address"/>
          </el-col>
        </el-row>
      </el-card>
      <el-row style="margin-top: 20px">
        <el-button class="dark-operation-btn-active" style="float: right;border-bottom: 0;padding: 0 5px;color: white">SUBMIT TRANSACTION</el-button>
      </el-row>
    </el-card>
  </div>
</template>

<script>

import Web3Service from "@/web3/Web3Service";

export default {
  name: "FtBridge",
  data: function () {
    return {
      sendAmount: '',
      walletInfo: {},
      rendering: false,
      bridgeInSupport: [
        {
          network: 'BSC'
        }
      ]
    }
  },
  created() {
    let that = this;
    Web3Service.getWeb3(that).currentProvider.on('networkChanged', function(){
      that.initData();
    });
    this.initData();
  },
  methods: {
    initData() {
      let that = this;
      Web3Service.initWalletInfo(this, function (res) {
        console.log(res);
        that.walletInfo = res;
      })
    }
  }
}
</script>

<style scoped>

.el-divider.data-divider {
  border-top: 1px solid #444c56;
}

.data-divider > .el-divider__text {
  background-color: #22272e;
}

</style>