var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel-scoped"},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('FtGroupButtonWidget',{staticStyle:{"background":"#3E454E"},attrs:{"buttons":[{
          id: 'append',
          text: '我参与的',
          textI18n: '',
      }],"mode":"single","textMode":"true"},on:{"switch-change":_vm.onAction}})],1)],1),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('FtOperationListWidget',{staticStyle:{"border-bottom":"1px solid #3E454E"},attrs:{"columns":_vm.columns,"data":_vm.approvedByRequester,"scrollHeight":_vm.scrollHeight,"buttons":[{
            id: 'approverDetailWallet',
            text: '详细成员',
            textI18n: '',
            width: '110px',
            padding: '0 10px 0 10px'
        }],"oWidth":"110px"},on:{"switch-change":_vm.onAction}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }