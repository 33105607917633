<script type="text/javascript">

import axios from "axios";

function getRequestWithFullUrl(url, success, error) {
  axios.get(url, {
    headers: {
      'content-type': 'application/json',
      'accept' : '*/*'
    }
  }).then((res) => {
    success(res.data);
  }).catch((e) => {
    if (error) {
      error(e);
    }
  });
}

function getBookSummaryByCurrency(currency, success, error) {
  getRequestWithFullUrl('https://www.deribit.com/api/v2/public/get_book_summary_by_currency?currency=' + currency + '&kind=option', function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getOrderBookById(id, success, error) {
  getRequestWithFullUrl('https://www.deribit.com/api/v2/public/get_order_book?instrument_name=' + id, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

export default {
  getBookSummaryByCurrency,
  getOrderBookById
}


</script>