<script type="text/javascript">

import ServiceMapper from "@/const/ServiceMapper";
import AbmNet from "@/api/net/AbmNet";

function openOrder(req, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.tradeApiServiceOpenOrder, req, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function generalOrder(req, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.tradeApiServiceGeneralOrder, req, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function cancelOrder(req, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.tradeApiServiceCancelOrder, req, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getAllPrice(platform, symbol, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.tradeApiServiceGetAllPrice + '?platform=' + platform + '&symbol=' + symbol, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getAllActiveOrders(platform, apiKey, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.tradeApiServiceGetAllActiveOrders + '?platform=' + platform + '&apiKey=' + apiKey, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getAllPosition(platform, apiKey, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.tradeApiServiceGetAllPosition + '?platform=' + platform + '&apiKey=' + apiKey, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getAllSpot(platform, apiKey, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.tradeApiServiceGetAllSpot + '?platform=' + platform + '&apiKey=' + apiKey, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getOrderHistory(platform, apiKey, startTime, endTime, symbol, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.tradeApiServiceGetOrderHistory + '?platform=' + platform + '&apiKey=' + apiKey + '&startTime=' + startTime + '&endTime=' + endTime + '&symbol=' + symbol, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getAccountInfo(platform, apiKey, symbol, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.tradeApiServiceGetOrderAccountInfo + '?platform=' + platform + '&apiKey=' + apiKey + '&symbol=' + symbol, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getAccountRisk(platform, apiKey, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.tradeApiServiceGetOrderAccountRisk + '?platform=' + platform + '&apiKey=' + apiKey, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function setPositionMode(platform, apiKey, mode, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.tradeApiServiceGetOrderPositionMode + '?platform=' + platform + '&apiKey=' + apiKey + '&mode=' + mode, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getOkxAssetBalance(platform, apiKey, ccy, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.tradeApiServiceGetOkAssetBalance + '?platform=' + platform + '&apiKey=' + apiKey + '&ccy=' + ccy, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function assetTransfer(platform, apiKey, coin, amount, transferType, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.tradeApiServiceAssetTransfer + '?platform=' + platform + '&apiKey=' + apiKey + '&coin=' + coin + '&amount=' + amount + '&transferType=' + transferType, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

export default {
  //开单
  openOrder,
  cancelOrder,
  getAllPrice,
  getAllActiveOrders,
  getAllPosition,
  getAllSpot,
  getOrderHistory,
  getAccountInfo,
  generalOrder,
  getAccountRisk,
  setPositionMode,
  getOkxAssetBalance,
  assetTransfer
}


</script>