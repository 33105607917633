<script type="text/javascript">

// value will be expired after 1 year by default
function set(key, value, mill) {
  if (mill === undefined) {
    mill = 1000 * 60 * 60 * 24 * 365;
  }
  const now = new Date()

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + mill,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

function get(key) {
  const itemStr = localStorage.getItem(key)
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  const now = new Date()
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key)
    return null
  }
  return item.value
}

function del(key) {
  localStorage.removeItem(key);
}

function clear() {
  localStorage.clear();
}

export default {
  get,
  set,
  del,
  clear
};
</script>

<style scoped>
</style>