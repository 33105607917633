<template>
  <div :dto="dto">
    <el-skeleton animated :loading="rendering" :throttle="268">
      <template slot="template">
        <trialpha-sk style="margin-top: 0;width: 60%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 40%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 100%;float: left"></trialpha-sk>
      </template>
      <template>
        <el-row>
          <el-row style="margin-top: 0">
            <el-button-group>
              <el-button class="dark-mode-btn" style="font-family: 'Microsoft YaHei',serif;font-size: 12px;padding: 8px;border-bottom: 0;border-radius: 0" v-for="(bt, index) in dateArrays" :key="index" size="small" :disabled="bt === selectDate" @click="syncDate(bt)">{{bt}}</el-button>
            </el-button-group>
          </el-row>
          <el-row style="margin-top: 0;">
            <!-- 挂单数据 Header -->
            <el-card class="black-card" style="border-radius: 0;border-bottom: 0;background-color: #1c2128" body-style="padding: 10px" shadow="none">
              <el-row>
                <el-col :span="12">
                  <span class="dark-mode-font" style="color: white">低买（USD）</span>
                </el-col>
                <el-col :span="12">
                  <span class="dark-mode-font" style="color: white;display: inline-block;float: right;margin-top: 2px">{{coin}}: ${{ getPrice() }}</span>
                </el-col>
              </el-row>
            </el-card>

            <!-- 挂单数据表头 -->
            <el-card class="black-card" style="border-radius: 0;height: 28px;background-color: #1c2128" shadow="none" body-style="padding: 4px 10px 3px 10px">
              <el-row>
                <el-col :span="4">
                  <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:darkgray ">目标价(差率)</span>
                </el-col>
                <el-col :span="4">
                  <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:darkgray ">保本价(差率)</span>
                </el-col>
                <el-col :span="2">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">每份收益率</span>
                </el-col>
                <el-col :span="3">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">每份收益额</span>
                </el-col>
                <el-col :span="3">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">年化率</span>
                </el-col>
                <el-col :span="4">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">最大可买(USD)</span>
                </el-col>
                <el-col :span="4">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">买入(USD)</span>
                </el-col>
              </el-row>
            </el-card>

            <!-- 挂单数据展示 -->
            <el-card class="black-card" style="border-radius: 0;border-top: 0;max-height: 224px;overflow-y: auto" shadow="none" body-style="padding: 0">
              <el-skeleton animated :loading="loadingSymbols" :throttle="200">
                <template slot="template">
                  <trialpha-sk :style="'margin-top: 20px;width: 60%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 90%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk style="margin-top: 20px;margin-bottom: 20px;width: 90%;float: left;margin-left: 10px"></trialpha-sk>
                </template>
                <template>
                  <div v-if="dateArrays.length > 0">
                    <el-card class="black-card-hover" shadow="none" style="border: 0;border-radius: 0" body-style="padding:0" v-for="(p, index) in dateSymbolMap[selectDate]" :key="index">
                      <el-row style="height: 28px;border-bottom: 1px solid #2e3033;padding: 4px 10px 4px 10px">
                        <el-col :span="4">
                          <el-row>
                            <el-col :span="12">
                              <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">${{ Number.prototype.toFormatNum(p['symbol'].split('-')[2]) }}</span>
                            </el-col>
                            <el-col :span="5">
                              <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:gray ">{{(((Number(p['symbol'].split('-')[2]) - getPrice()) / getPrice()) * 100).toFixed(2) }}%</span>
                            </el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="4">
                          <el-row>
                            <el-col :span="12">
                              <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">${{ Number(Number(p['symbol'].split('-')[2]) * (1 - (Number(p['profit']) / 100))).toFixed(1) }}</span>
                            </el-col>
                            <el-col :span="5">
                              <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:gray ">{{((((Number(p['symbol'].split('-')[2]) * (1 - (Number(p['profit']) / 100))) - getPrice()) / getPrice()) * 100).toFixed(2) }}%</span>
                            </el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="2">
                          <el-row>
                            <el-col :span="24">
                              <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:#0ecb81 ">{{ p['profit'] }}%</span>
                            </el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="3">
                          <el-row>
                            <el-col :span="24">
                              <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:#0ecb81 ">${{ (Number(p['profit']) * getPrice() / 100).toFixed(2) }}</span>
                            </el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="3">
                          <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:#0ecb81 ">{{ p['yearRate'] }}%</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{ Number.prototype.toFormatNum(((symbolMaxUsdMap[p['symbol']] ? symbolMaxUsdMap[p['symbol']] : 0) * getPrice()).toFixed(0)) }}</span>
                        </el-col>
                        <el-col :span="4">
                          <el-button class="dark-mode-btn" style="border-radius: 0;border-top: 0;border-right: 0;height: 28px;line-height: 28px;float: right;margin-top: -4px;font-size: 12px;padding: 0;width: 40px;margin-right: -10px" @click="sendOrder(p['symbol'], p['profit'])" :loading="submiting">BUY</el-button>
                          <input class="trialpha-input" v-model="orderNum[p['symbol']]" style="border-top: 0;border-right: 0;height: 28px;line-height: 28px;width: 58px;float: right;border-radius: 0;margin-top: -4px;" placeholder="0.0"/>
                        </el-col>
                      </el-row>
                    </el-card>
                  </div>
                </template>
              </el-skeleton>
            </el-card>
          </el-row>

          <el-row style="margin-top: 0;margin-bottom: 20px">
            <!-- 挂单数据 Header -->
            <el-card class="black-card" style="border-top: 0;border-radius: 0;border-bottom: 0;background-color: #1c2128" body-style="padding: 10px" shadow="none">
              <el-row>
                <el-col :span="8">
                  <span class="dark-mode-font" style="color: white">我持有的</span>
                </el-col>
              </el-row>
            </el-card>

            <!-- 挂单数据表头 -->
            <el-card class="black-card" style="border-radius: 0;height: 28px;background-color: #1c2128" shadow="none" body-style="padding: 4px 10px 3px 10px">
              <el-row>
                <el-col :span="4">
                  <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:darkgray ">目标价(差率)</span>
                </el-col>
                <el-col :span="4">
                  <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:darkgray ">保本价(差率)</span>
                </el-col>
                <el-col :span="2">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">总利润</span>
                </el-col>
                <el-col :span="3">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">总资金收益率</span>
                </el-col>
                <el-col :span="3">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">行权亏损率</span>
                </el-col>
                <el-col :span="4">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">持有份额</span>
                </el-col>
                <el-col :span="4">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">到期时间</span>
                </el-col>
              </el-row>
            </el-card>

            <!-- 挂单数据展示 -->
            <el-card class="black-card" style="border-radius: 0;border-top: 0;max-height: 224px;overflow-y: auto" shadow="none" body-style="padding: 0">
              <el-skeleton animated :loading="loadingSymbols" :throttle="200">
                <template slot="template">
                  <trialpha-sk :style="'margin-top: 20px;width: 60%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 90%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk style="margin-top: 20px;margin-bottom: 20px;width: 90%;float: left;margin-left: 10px"></trialpha-sk>
                </template>
                <template>
                  <div v-if="dateArrays.length > 0">
                    <el-card class="black-card-hover" shadow="none" style="border: 0;border-radius: 0" body-style="padding:0" v-for="(p, index) in myPositions" :key="index">
                      <el-row style="height: 28px;border-bottom: 1px solid #2e3033;padding: 4px 10px 4px 10px">
                        <el-col :span="4">
                          <el-row>
                            <el-col :span="12">
                              <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">${{ Number.prototype.toFormatNum(p['instrument_name'].split('-')[2]) }}</span>
                            </el-col>
                            <el-col :span="5">
                              <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:gray ">{{(((Number(p['instrument_name'].split('-')[2]) - getPrice()) / getPrice()) * 100).toFixed(2) }}%</span>
                            </el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="4">
                          <el-row>
                            <el-col :span="12">
                              <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">${{ Number(Number(p['instrument_name'].split('-')[2]) * (1 - (Number(p['profit']) / 100))).toFixed(1) }}</span>
                            </el-col>
                            <el-col :span="5">
                              <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:gray ">{{((((Number(p['instrument_name'].split('-')[2]) * (1 - (Number(p['profit']) / 100))) - getPrice()) / getPrice()) * 100).toFixed(2) }}%</span>
                            </el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="2">
                          <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:#0ecb81 ">${{ (p['average_price'] * Math.abs(p['size']) * getPrice()).toFixed(3) }}</span>
                        </el-col>
                        <el-col :span="3">
                          <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:#0ecb81 ">{{ (((p['average_price'] * Math.abs(p['size']) * getPrice()) / accountUsdBalance) * 100).toFixed(5) }}%</span>
                        </el-col>
                        <el-col :span="3">
                          <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:rgb(246, 70, 93) ">-{{ getLossRate(p) }}%</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white; ">{{ Math.abs(p['size']) }}</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{ p['expiredDay'] }}</span>
                        </el-col>
                      </el-row>
                    </el-card>
                  </div>
                </template>
              </el-skeleton>
            </el-card>

            <el-card class="black-card" style="border-top: 0;border-radius: 0;background-color: #1c2128" body-style="padding: 10px" shadow="none">
              <el-row>
                <el-col :span="12">
                  <span class="dark-mode-font" style="color: white">持有产品占用资金：<span style="color: white">${{ Number.prototype.toFormatNum(Number(totalCost.toFixed(0))) }}</span></span>
                </el-col>
                <el-col :span="12">
                  <span class="dark-mode-font" style="color: white;display: inline-block;float: right">待收利润：<span style="color: #0ecb81">${{ Number.prototype.toFormatNum(Number(totalPnl.toFixed(2))) }}</span>，总资金利润率：<span style="color: #0ecb81">{{ Number.prototype.toFormatNum(Number(((totalPnl / accountUsdBalance) * 100).toFixed(2))) }}%</span></span>
                </el-col>
              </el-row>
            </el-card>
          </el-row>
        </el-row>
      </template>
    </el-skeleton>
  </div>
</template>

<script>
import StrategyInstanceService from "@/api/trade/StrategyInstanceService";
import TrialphaSk from "@/components/TrialphaSk";
import SymbolInfoService from "@/utils/SymbolInfoService";
import GeneralApiService from "@/api/trade/GeneralApiService";
import DeribitPublicApiService from "@/api/platform/DeribitPublicApiService";
import {Message} from "element-ui";
import ConfirmProvider from "@/components/ConfirmProvider";
import TradeApiService from "@/api/trade/TradeApiService";

export default {
  name: "DoubleCoinWin",
  components: {TrialphaSk},
  props: {
    dto: Object
  },
  data: function () {
    return {
      coin: 'ETH',
      apiKey: '',
      selectDate: '',
      loadingSymbols: false,
      monthMap: {
        JAN: '01',
        FEB: '02',
        MAR: '03',
        APR: '04',
        MAY: '05',
        JUN: '06',
        JUL: '07',
        AUG: '08',
        SEP: '09',
        OCT: '10',
        NOV: '11',
        DEC: '12'
      },
      processing: false,
      rendering: true,
      instance: {},
      instanceList: [],
      accountInstance: {},
      dateArrays: [],
      dateSymbolMap: {},
      symbolMaxUsdMap: {},
      orderNum: {},
      myPositions: [],
      totalCost: 0,
      totalPnl: 0,
      accountUsdBalance: 0,
      submiting: false
    }
  },
  methods: {
    sendOrder(id, profit) {
      let that = this;
      let price = id.split('-')[2];
      let sendNum = (this.orderNum[id] / this.getPrice()).toFixed(this.coin === 'BTC' ? 1 : 0);
      let equalUsd = Number((sendNum * this.getPrice()).toFixed(2));
      let profitAmount = Number((sendNum * profit / 100).toFixed(5));
      ConfirmProvider.openConfirm(that, '购入双币宝产品', '您实际购入额为 ' + this.coin + ':' + sendNum + ', 折合 USD: ' + equalUsd + '。当到期时价格大于 ' + price + ', 您将获得 ' + equalUsd + ' USD 本金和利息 ' + profitAmount + ' ' + this.coin +
      '。当到期时价格小于 ' + price + ', 您将获得 ' + (equalUsd / price).toFixed(4) + ' ' + this.coin + ' 本金和利息 ' + profitAmount + ' ' + this.coin + '。', function () {
        that.submiting = true;
        GeneralApiService.placeMarketOrder(GeneralApiService.PLATFORM.DERIBIT, GeneralApiService.SYMBOL_TYPE.OPTIONS, GeneralApiService.ORDER_TYPE.OPEN_SHORT, that.apiKey, id, sendNum, function () {
          Message.success('操作成功!');
          setTimeout(() => {
            that.submiting = false;
            that.syncFullSymbol()
          }, 888);
        }, function (err) {
          console.log(err);
          Message.error('Send error, please try again.');
          that.submiting = false;
        });
      });
    },
    getOrderBook(id) {
      let that = this;
      DeribitPublicApiService.getOrderBookById(id, function (res) {
        that.symbolMaxUsdMap[id] = res.result.bids[0][1];
        that.$nextTick(() => {
          that.$forceUpdate();
        });
      });
    },
    syncDate(bt) {
      let that = this;
      this.selectDate = bt;
      this.dateSymbolMap[this.selectDate].forEach((obj) => {
        that.getOrderBook(obj.symbol);
      });
    },
    getPrice() {
      return SymbolInfoService.getSymbolPrice(GeneralApiService.PLATFORM.BINANCE, GeneralApiService.SYMBOL_TYPE.SPOT, this.coin + 'USDT');
    },
    render: function (accountInstance) {
      let that = this;
      that.rendering = true;
      that.accountInstance = accountInstance;
      that.instance = JSON.parse(JSON.stringify(that.dto.strategyConfig));
      this.syncFullSymbol();
      StrategyInstanceService.query(that.accountInstance.instanceId, that.dto.strategyName, function (data) {
        that.instanceList = data.strategyInsList;
        that.rendering = false;
      }, function () {
        that.rendering = false;
      });
    },
    syncFullSymbol: function () {
      let that = this;
      that.loadingSymbols = true;
      this.accountInstance.tradeAccountList.forEach((tradeIns) => {
        if (tradeIns.platform === GeneralApiService.PLATFORM.DERIBIT) {
          that.coin = tradeIns.baseCoin.replaceAll('_U', '');
          that.apiKey = tradeIns.apiKey;
          let balance = tradeIns.currentBalance;
          let usdPrice = SymbolInfoService.getSymbolPrice(GeneralApiService.PLATFORM.BINANCE, GeneralApiService.SYMBOL_TYPE.SPOT, that.coin + 'USDT');
          that.accountUsdBalance = balance * (tradeIns.baseCoin.indexOf('_U') > -1 ? 1 : usdPrice);
        }
      });
      this.syncPositions();
      DeribitPublicApiService.getBookSummaryByCurrency(that.coin, function (res) {
        let symbolMap = {};
        res.result.forEach((obj) => {
          if (obj.instrument_name.endsWith('-P') && obj.bid_price) {
            let date = obj.instrument_name.split('-')[1];
            // let price = Number(obj.instrument_name.split('-')[2]);
            // if (price < SymbolInfoService.getSymbolPrice(GeneralApiService.PLATFORM.BINANCE, GeneralApiService.SYMBOL_TYPE.SPOT, that.coin + 'USDT') * 1.01) {
              if (date.length === 6) {
                date = String('0' + date);
              }
              let year = date.substr(date.length - 2, date.length - 1);
              let mon = date.substr(date.length - 5, date.length - 4);
              let day = date.replaceAll(mon + year, '');
              let expiredTime = (new Date('20' + year + '-' + that.monthMap[mon] + '-' + day + ' 16:00:00').getTime())
              let newDate = year + that.monthMap[mon] + day;
              if (!symbolMap[newDate]) {
                symbolMap[newDate] = [];
              }
              symbolMap[newDate].push({
                symbol: obj.instrument_name,
                profit: Number((((obj.bid_price / 1) * 100) - (that.isDailyOptions(expiredTime) ? 0.03 : 0.045)).toFixed(4)),
                yearRate: Number(((((obj.bid_price / 1) * 100) - (that.isDailyOptions(expiredTime) ? 0.03 : 0.045)) / (expiredTime - Date.now()) * (1000 * 60 * 60 * 24 * 365)).toFixed(2))
              });
              that.orderNum[obj.instrument_name] = 0;
            // }
          }
        });
        that.dateArrays = Object.keys(symbolMap);
        that.dateArrays.sort();
        that.selectDate = Object.keys(symbolMap)[0];
        that.dateSymbolMap = symbolMap;
        that.loadingSymbols = false;
        that.syncDate(that.selectDate);
        that.$nextTick(() => {
          that.$forceUpdate();
        });
      });
    },
    syncPositions: function () {
      let that = this;
      let positions = [];
      TradeApiService.getAllPosition(GeneralApiService.PLATFORM.DERIBIT, this.apiKey, function (data) {
        let totalInvest = 0;
        let totalPnl = 0;
        for (const dataKey in data) {
          let position = data[dataKey];
          if (position.kind === 'option' && position.instrument_name.endsWith('-P')) {
            let date = position.instrument_name.split('-')[1];
            if (date.length === 6) {
              date = String('0' + date);
            }
            let year = date.substr(date.length - 2, date.length - 1);
            let mon = date.substr(date.length - 5, date.length - 4);
            let day = date.replaceAll(mon + year, '');
            let expiredTime = (new Date('20' + year + '-' + that.monthMap[mon] + '-' + day + ' 16:00:00').getTime());
            let expiredToNow = expiredTime - new Date().getTime();
            let twoDaysTime = (1000 * 60 * 60 * 24 * 2);
            position.profit = Number((((position.average_price / 1) * 100) - 0.045).toFixed(4));
            position.yearRate = Number(((((position.average_price / 1) * 100) - 0.045) / (expiredToNow > twoDaysTime ? expiredToNow : twoDaysTime) * (1000 * 60 * 60 * 24 * 365)).toFixed(2));
            position.expiredDay = '20' + year + '-' + that.monthMap[mon] + '-' + day;
            positions.push(position);
            totalInvest += Math.abs(position['size']) * that.getPrice();
            totalPnl += (Math.abs(position['size']) * Math.abs(position['average_price'])) * that.getPrice();
          }
        }
        that.myPositions = positions;
        that.totalCost = totalInvest;
        that.totalPnl = totalPnl;
      }, function () {

      });
    },
    getLossRate: function (p) {
      if (this.getPrice() >= Number(p['instrument_name'].split('-')[2])) {
        return '0.00000';
      } else {
        return (((((Number(p['instrument_name'].split('-')[2]) - this.getPrice()) / this.getPrice()) * (p['average_price'] * Math.abs(p['size']) * this.getPrice())) / this.accountUsdBalance) * 100).toFixed(5);
      }
    },
    isDailyOptions: function (expiredTimes) {
      return expiredTimes - Date.now() < (1000 * 60 * 60 * 24);
    }
  }
}
</script>

<style scoped>

.ellipsis-line {
  padding: 8px;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-collapse {
  color: #FFFFFF;
  border-top: 1px solid #444c56;
  border-bottom: 1px solid #444c56;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: white;
}

.el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-divider {
  border-top: 1px solid #444c56;
}

.el-divider.info {
  width: 80%;
  border-top: 1px solid #444c56;
}

.el-divider__text {
  color: white;
  background-color: #444c56;
}

.select-symbol .el-input__inner {
  color: white;
  height: 35px !important;
  line-height: 35px;
  width: 100%;
  padding: 0 10px 0 10px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-right: 0;
  border-radius: 0;
  font-size: 12px;
  outline: none;
}

.select-symbol.el-select .el-input.is-focus .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:hover .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:hover .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:focus .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:visited .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:focus .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:visited .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

</style>