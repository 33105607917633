<template>
<div :hide-header-search="hideHeaderSearch" class="TVChartContainer" ref="chartContainer" />
</template>

<script>

import FtUDFCompatibleDatafeed from './datafeed/ft-udf-compatible-datafeed';
import {widget} from "../../public/ap/charting_library";

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export default {
  name: 'TVChartContainer',
  props: {
    symbol: {
      default: 'BTCUSDT_SPOT',
      type: String,
    },
    interval: {
      default: '5',
      type: String,
    },
    datafeedUrl: {
      default: 'https://demo_feed.tradingview.com',
      // default: 'http://tv.local.cn/df',
      type: String,
    },
    libraryPath: {
      default: '/ap/charting_library/',
      type: String,
    },
    chartsStorageUrl: {
      default: 'https://saveload.tradingview.com',
      type: String,
    },
    chartsStorageApiVersion: {
      default: '1.1',
      type: String,
    },
    clientId: {
      default: 'tradingview.com',
      type: String,
    },
    userId: {
      default: 'public_user_id',
      type: String,
    },
    fullscreen: {
      default: false,
      type: Boolean,
    },
    autosize: {
      default: true,
      type: Boolean,
    },
    studiesOverrides: {
      type: Object,
    },
    hideHeaderSearch: Boolean
  },
  tvWidget: null,
  tvid: null,
  mounted() {
    const datafeed = new FtUDFCompatibleDatafeed({
      getBars: (symbolInfo, resolution, periodParams) => {
          this.$emit('chart-subscribe', 'get_bars', [symbolInfo, resolution, periodParams])
      },
      subscribeBars: (symbolInfo, resolution, subscribeUID) => {
          this.tvWidget.chart().executeActionById("timeScaleReset");
          this.$emit('chart-subscribe', 'subscribe_bars', [symbolInfo, resolution, subscribeUID])
      },
      unsubscribeBars: (subscribeUID) => {
          this.$emit('chart-subscribe', 'unsubscribe_bars', [subscribeUID])
      }
    })
    this.tvid = datafeed.getTvId()
    const container = this.$refs.chartContainer;
    const widgetOptions = {
      symbol: this.symbol,
      // BEWARE: no trailing slash is expected in feed URL
      // datafeed: new window.Datafeeds.UDFCompatibleDatafeed(this.datafeedUrl),
      datafeed: datafeed,
      // datafeed: CustoCompatibleDatafeed,
      interval: this.interval,
      container: container,
      library_path: this.libraryPath,

      locale: getLanguageFromURL() || 'en',
      disabled_features: ['use_localstorage_for_settings', this.hideHeaderSearch ? 'header_symbol_search' : ''],
      enabled_features: ['study_templates', 'hide_left_toolbar_by_default'],
      // charts_storage_url: this.chartsStorageUrl,
      // charts_storage_api_version: this.chartsStorageApiVersion,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      client_id: this.clientId,
      user_id: this.userId,
      fullscreen: this.fullscreen,
      autosize: this.autosize,
      studies_overrides: this.studiesOverrides,
      theme: 'dark',
			custom_css_url: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/web/tv/themed.css',
      timeframe: '1D',
    };

    const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget;

    const onCallEmit = (type) => {
      return (a1, a2, a3, a4, a5, a6, a7, a8, a9) => {
        var args = [a1, a2, a3, a4, a5, a6, a7, a8, a9]
        this.$emit('chart-subscribe', type, args.filter(item => {
          return item !== undefined
        }))
      }
    }

    tvWidget.onChartReady(() => {
      // 当图表初始化并准备就绪时，图表库将调用提供的回调。 你可以从这一刻安全地调用所有其他方法。
      this.$emit('chart-subscribe', 'chart_ready', [])
      tvWidget.headerReady().then(() => {
        // this.$emit('chart-subscribe', 'header_ready', [])
        // const button = tvWidget.createButton();
        // button.setAttribute('title', 'Click to show a notification popup');
        // button.classList.add('apply-common-tooltip');
        // button.addEventListener('click', () => tvWidget.showNoticeDialog({
        //     title: 'Notification',
        //     body: 'TradingView Charting Library API works correctly',
        //     callback: () => {
        //       // eslint-disable-next-line no-console
        //       console.log('Noticed!');
        //     },
        //   }));
        // button.innerHTML = 'Check API';
      });

      // 指标将添加到图表中。参数包含一个带有 value 字段的对象，该字段与指标名称相对应。
      tvWidget.subscribe('study', onCallEmit('study'))
      //      
      tvWidget.subscribe('undo', onCallEmit('undo'))
      //      
      tvWidget.subscribe('redo', onCallEmit('redo'))
      // Undo/Redo状态已更改。参数包含一个具有Undo/Redo堆栈状态的对象。该对象与方法的返回值具有相同的结构。
      tvWidget.subscribe('undo_redo_state_changed', onCallEmit('undo_redo_state_changed'))
      // 重置比例按钮被点击
      tvWidget.subscribe('reset_scales', onCallEmit('reset_scales'))
      // 显示比较对话框
      tvWidget.subscribe('compare_add', onCallEmit('compare_add'))
      // 添加了一个商品的比较
      tvWidget.subscribe('add_compare', onCallEmit('add_compare'))
      // 一个指标模板被加载
      tvWidget.subscribe('load_study', onCallEmit('load_study'))
      // 最新k线被更新
      tvWidget.subscribe('onTick', onCallEmit('onTick'))
      // 用户修改了图表, Chart change 表示可以撤销用户的任何操作。回调函数不可以在5秒内多次调用, 详见: 
      tvWidget.subscribe('onAutoSaveNeeded', onCallEmit('onAutoSaveNeeded'))
      // 服务器返回屏幕截图URL
      tvWidget.subscribe('onScreenshotReady', onCallEmit('onScreenshotReady'))
      // 用户点击。标记ID将作为参数传递
      tvWidget.subscribe('onMarkClick', onCallEmit('onMarkClick'))
      // 用户点击时间刻度标记。标记ID将作为参数传递
      tvWidget.subscribe('onTimescaleMarkClick', onCallEmit('onTimescaleMarkClick'))
      // 择的线条工具已更改
      tvWidget.subscribe('onSelectedLineToolChanged', onCallEmit('onSelectedLineToolChanged'))
      // 指标从图表中删除。回调函数接收2个参数: 指标id和event类型 (当前这个参数唯一可能的值是remove)
      tvWidget.subscribe('study_event', onCallEmit('study_event'))
      // 与系列有关的事件。回调函数接收1个参数: event类型（当前，此参数唯一可能的值为price_scale_changed）
      tvWidget.subscribe('series_event', onCallEmit('series_event'))
      // 隐藏，显示，移动，移除或单击绘图。 回调函数接收2个参数: 指标id和event类型。event类型的可能值是hide，show、move、remove、click
      tvWidget.subscribe('drawing_event', onCallEmit('drawing_event'))
      // 指标属性已更改。实体ID将作为参数传递。
      tvWidget.subscribe('study_properties_changed', onCallEmit('study_properties_changed'))
      // 主数据列属性发生变化
      tvWidget.subscribe('series_properties_changed', onCallEmit('series_properties_changed'))
      // 窗格大小已更改。
      tvWidget.subscribe('panes_height_changed', onCallEmit('panes_height_changed'))
      // 窗格订单发生变化。
      tvWidget.subscribe('panes_order_changed', onCallEmit('panes_order_changed'))
      // 图表的数量或位置即将改变
      tvWidget.subscribe('layout_about_to_be_changed', onCallEmit('layout_about_to_be_changed'))
      // 图表的数量或位置已更改
      tvWidget.subscribe('layout_changed', onCallEmit('layout_changed'))
      // 活动的图表已变更
      tvWidget.subscribe('activeChartChanged', onCallEmit('activeChartChanged'))
    });
  },
  destroyed() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
    let subscribeIntervalId = localStorage.getItem(this.tvid + '-' + 'subscribeIntervalId')
    console.log('关闭订阅', this.tvid + '-' + subscribeIntervalId)
    if (subscribeIntervalId) {
      clearInterval(subscribeIntervalId)
    }
  },
  methods: {
    // 使图表更改其商品和周期。新商品的数据到达后调用回调。
    setSymbol(symbol, interval, callback) {
      if (this.tvWidget !== null && this.tvWidget) {
        this.tvWidget.setSymbol(symbol, interval, callback)
      }
    }
  }
}
</script>

<style scoped>
.TVChartContainer {
  height: 100%;
}
</style>
