<template>
  <div :dto="dto">
    <el-skeleton animated :loading="rendering" :throttle="268">
      <template slot="template">
        <trialpha-sk style="margin-top: 0;width: 60%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 40%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 100%;float: left"></trialpha-sk>
      </template>
      <template>
        <el-row>
          <el-collapse class="trialpha-collapse" v-model="activeNames" style="border: 0">
            <el-collapse-item class="trialpha-collapse" style="border: 0" :name="index" v-for="(ins, index) in instanceList" :key="index">
              <template slot="title">
                <el-row style="width: 100%">
                  <el-col :span="4" style="padding: 0">
                    <el-card class="black-card" body-style="padding: 0" style="line-height: 46px;margin-left: -10px;margin-top: 9px;border-left: 0;border-top: 0;border-bottom: 0;border-radius: 0;height: 46px;width: 50px;background-color: #444c56">
                      <span class="dark-mode-font" style="padding: 0;font-size: 12px;width: 100%;display: inline-block;text-align: center">{{ ins.strategyConfig.feePairList.length }}</span>
                    </el-card>
                  </el-col>
                  <el-col :span="16">
                    <el-row style="display: flex">
                      <span class="dark-mode-font ellipsis-line" :style="'display: inline-block;text-align: center;' + (ins.isActive ? 'color: #FFFFFF' : '') + ''">CONFIGURATION</span>
                    </el-row>
                  </el-col>
                </el-row>
              </template>
              <el-row style="height: 728px;overflow-y: auto">

                <!-- 理论数据 -->
                <el-card class="black-card" body-style="padding: 5px 0 5px 0" style="margin-top: 5px;border: 0;border-radius: 0;width: 100%;background-color: rgba(0, 0, 0, 0)" shadow="none">
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">Binance理论净敞口(USD)</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">dYdX理论净敞口(USD)</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">OKX理论净敞口(USD)</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">Bybit理论净敞口(USD)</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="6" style="padding-right: 8px">
                    <input class="trialpha-input" :value="positionReport.BINANCE.MARGIN.toFixed(2) + ' / ' + ((positionReport.BINANCE.MARGIN * 100) / (positionReport.BINANCE.TOTAL)).toFixed(2) + '%'" disabled />
                  </el-col>
                  <el-col :span="6" style="padding-right: 8px">
                    <input class="trialpha-input" :value="positionReport.DYDX.MARGIN.toFixed(2) + ' / ' + ((positionReport.DYDX.MARGIN * 100) / (positionReport.DYDX.TOTAL)).toFixed(2) + '%'" disabled />
                  </el-col>
                  <el-col :span="6" style="padding-right: 8px">
                    <input class="trialpha-input" :value="positionReport.OKX.MARGIN.toFixed(2) + ' / ' + ((positionReport.OKX.MARGIN * 100) / (positionReport.OKX.TOTAL)).toFixed(2) + '%'" disabled />
                  </el-col>
                  <el-col :span="6" style="padding-right: 8px">
                    <input class="trialpha-input" :value="positionReport.BYBIT.MARGIN.toFixed(2) + ' / ' + ((positionReport.BYBIT.MARGIN * 100) / (positionReport.BYBIT.TOTAL)).toFixed(2) + '%'" disabled />
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">Binance理论总仓位(USD)</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">dYdX理论总仓位(USD)</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">OKX理论总仓位(USD)</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">Bybit理论总仓位(USD)</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="6" style="padding-right: 8px">
                    <input class="trialpha-input" :value="positionReport.BINANCE.TOTAL.toFixed(2)" disabled />
                  </el-col>
                  <el-col :span="6" style="padding-right: 8px">
                    <input class="trialpha-input" :value="positionReport.DYDX.TOTAL.toFixed(2)" disabled />
                  </el-col>
                  <el-col :span="6" style="padding-right: 8px">
                    <input class="trialpha-input" :value="positionReport.OKX.TOTAL.toFixed(2)" disabled />
                  </el-col>
                  <el-col :span="6" style="padding-right: 8px">
                    <input class="trialpha-input" :value="positionReport.BYBIT.TOTAL.toFixed(2)" disabled />
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">理论总仓位(USD)</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="6" style="padding-right: 8px">
                    <input class="trialpha-input" :value="(positionReport.BINANCE.TOTAL + positionReport.DYDX.TOTAL + positionReport.OKX.TOTAL + positionReport.BYBIT.TOTAL).toFixed(2)" disabled />
                  </el-col>
                </el-row>
                </el-card>

                <!-- 实际数据 -->
                <el-card class="black-card" body-style="padding: 5px 0 15px 0" style="margin-top: 20px;border-left: 0;border-right: 0;border-bottom: 0;border-radius: 0;width: 100%;background-color: rgba(0, 0, 0, 0)" shadow="none">
                  <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">Binance实际净敞口(USD)</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">dYdX实际净敞口(USD)</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">OKX实际净敞口(USD)</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">Bybit实际净敞口(USD)</span>
                    </el-col>
                  </el-row>
                  <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                    <el-col :span="6" style="padding-right: 8px">
                      <input class="trialpha-input" :value="positionRealReport.BINANCE.MARGIN.toFixed(2) + ' / ' + ((positionRealReport.BINANCE.MARGIN * 100) / (positionRealReport.BINANCE.TOTAL)).toFixed(2) + '%' + ' / ' + ((positionRealReport.BINANCE.MARGIN * 100) / (accountUsdBalance.BINANCE)).toFixed(2) + '%'" disabled />
                    </el-col>
                    <el-col :span="6" style="padding-right: 8px">
                      <input class="trialpha-input" :value="positionRealReport.DYDX.MARGIN.toFixed(2) + ' / ' + ((positionRealReport.DYDX.MARGIN * 100) / (positionRealReport.DYDX.TOTAL)).toFixed(2) + '%' + ' / ' + ((positionRealReport.DYDX.MARGIN * 100) / (accountUsdBalance.DYDX)).toFixed(2) + '%'" disabled />
                    </el-col>
                    <el-col :span="6" style="padding-right: 8px">
                      <input class="trialpha-input" :value="positionRealReport.OKX.MARGIN.toFixed(2) + ' / ' + ((positionRealReport.OKX.MARGIN * 100) / (positionRealReport.OKX.TOTAL)).toFixed(2) + '%' + ' / ' + ((positionRealReport.OKX.MARGIN * 100) / (accountUsdBalance.OKX)).toFixed(2) + '%'" disabled />
                    </el-col>
                    <el-col :span="6" style="padding-right: 8px">
                      <input class="trialpha-input" :value="positionRealReport.BYBIT.MARGIN.toFixed(2) + ' / ' + ((positionRealReport.BYBIT.MARGIN * 100) / (positionRealReport.BYBIT.TOTAL)).toFixed(2) + '%' + ' / ' + ((positionRealReport.BYBIT.MARGIN * 100) / (accountUsdBalance.BYBIT)).toFixed(2) + '%'" disabled />
                    </el-col>
                  </el-row>
                  <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">Binance实际总仓位,保证金(USD)</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">dYdX实际总仓位,保证金(USD)</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">OKX实际总仓位,保证金(USD)</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">Bybit实际总仓位,保证金(USD)</span>
                    </el-col>
                  </el-row>
                  <el-row style="width: 98%;margin: 5px auto 5px;">
                    <el-col :span="6" style="padding-right: 8px">
                      <input class="trialpha-input" :value="positionRealReport.BINANCE.TOTAL.toFixed(2) + ' / ' + accountUsdBalance.BINANCE" disabled />
                    </el-col>
                    <el-col :span="6" style="padding-right: 8px">
                      <input class="trialpha-input" :value="positionRealReport.DYDX.TOTAL.toFixed(2) + ' / ' + accountUsdBalance.DYDX" disabled />
                    </el-col>
                    <el-col :span="6" style="padding-right: 8px">
                      <input class="trialpha-input" :value="positionRealReport.OKX.TOTAL.toFixed(2) + ' / ' + accountUsdBalance.OKX" disabled />
                    </el-col>
                    <el-col :span="6" style="padding-right: 8px">
                      <input class="trialpha-input" :value="positionRealReport.BYBIT.TOTAL.toFixed(2) + ' / ' + accountUsdBalance.BYBIT" disabled />
                    </el-col>
                  </el-row>
                  <el-row style="width: 98%;margin-top: 0;margin-left: auto;margin-right: auto;">
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">实际总仓位(USD)</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">实际总净值</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">资金费过往数据统计(天: 收益率)</span>
                    </el-col>
                  </el-row>
                  <el-row style="width: 98%;margin: 5px auto 10px;">
                    <el-col :span="6" style="padding-right: 8px">
                      <input class="trialpha-input" :value="(positionRealReport.BINANCE.TOTAL + positionRealReport.DYDX.TOTAL + positionRealReport.OKX.TOTAL + positionRealReport.BYBIT.TOTAL).toFixed(2)" disabled />
                    </el-col>
                    <el-col :span="6" style="padding-right: 8px">
                      <input class="trialpha-input" :value="(accountUsdBalance.TOTAL).toFixed(2)" disabled />
                    </el-col>
                    <el-col :span="12" style="padding-right: 8px">
                      <input class="trialpha-input" :value="'1: ' + ((totalFundingFee.DAY * 100) / accountUsdBalance.TOTAL).toFixed(3) + '%' + ' / ' + '3: ' + ((totalFundingFee.THREE_DAY * 100) / accountUsdBalance.TOTAL).toFixed(3) + '%' + ' / ' + '7: ' + ((totalFundingFee.WEEK * 100) / accountUsdBalance.TOTAL).toFixed(3) + '%' + ' / ' + '15: ' + ((totalFundingFee.TWO_WEEK * 100) / accountUsdBalance.TOTAL).toFixed(3) + '%' + ' / ' + '30: ' + ((totalFundingFee.MONTH * 100) / accountUsdBalance.TOTAL).toFixed(3) + '%'" disabled />
                    </el-col>
                  </el-row>
                </el-card>

                <!-- 新建 -->
                <el-card class="black-card" body-style="padding: 5px 0 15px 0" style="border-left: 0;border-right: 0;border-bottom: 0;border-radius: 0;width: 100%;background-color: rgba(0, 0, 0, 0)" shadow="none">
                  <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                    <el-col :span="5">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">做空交易所</span>
                    </el-col>
                    <el-col :span="5">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">做空币种</span>
                    </el-col>
                    <el-col :span="5">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">做多交易所</span>
                    </el-col>
                    <el-col :span="5">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">做多币种</span>
                    </el-col>
                  </el-row>
                  <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                    <el-col :span="5" style="padding-right: 10px">
                      <el-select class="select-symbol" style="width: 100%" v-model="newPair.shortPlatform" placeholder="请选择">
                        <el-option
                            v-for="item in exchangeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="5" style="padding-right: 10px">
                      <input class="trialpha-input" v-model="newPair.shortSymbol" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 35px;padding: 0 5px 0 5px;width: 100%"/>
                    </el-col>
                    <el-col :span="5" style="padding-right: 10px">
                      <el-select class="select-symbol" style="width: 100%" v-model="newPair.longPlatform" placeholder="请选择">
                        <el-option
                            v-for="item in exchangeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="5" style="padding-right: 10px">
                      <input class="trialpha-input" v-model="newPair.longSymbol" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 35px;padding: 0 5px 0 5px;width: 100%"/>
                    </el-col>
                    <el-col :span="4">
                      <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 3px" @click="addSymbol" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i class="el-icon-plus" style="font-size: 16px"/></el-button>
                    </el-col>
                  </el-row>
                </el-card>

                <!-- 全局参数 -->
                <el-card class="black-card" body-style="padding: 5px 0 15px 0" style="border-left: 0;border-right: 0;border-bottom: 0;border-radius: 0;width: 100%;background-color: rgba(0, 0, 0, 0)" shadow="none">
                  <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">实例状态</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">实例模式</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">搜索</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">累计交易额(USD)</span>
                    </el-col>
                  </el-row>
                  <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                    <el-col :span="6">
                      <el-select class="select-symbol" style="width: 100%;padding-right: 8px" v-model="ins.isActive" placeholder="请选择">
                        <el-option
                            v-for="item in [{value: true, label: 'Active'}, {value: false, label: 'Inactive'}]"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="6">
                      <el-select class="select-symbol" style="width: 100%;padding-right: 8px" v-model="ins.strategyConfig.monitorMode" placeholder="请选择">
                        <el-option
                            v-for="item in [{value: true, label: 'Monitor'}, {value: false, label: 'Operator'}]"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="6" style="display: flex;padding-right: 8px">
                      <input class="trialpha-input" style="height: 35px" v-model="search" />
                    </el-col>
                    <el-col :span="6" style="display: flex">
                      <input class="trialpha-input" style="height: 35px" :value="ins.strategyConfig.tradeVol.toFixed(2)" disabled />
                    </el-col>
                  </el-row>
                  <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">单次最大成交额(USD)</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">报警敞口(USD)</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">仓位调整</span>
                    </el-col>
                  </el-row>
                  <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto">
                    <el-col :span="6" style="display: flex;padding-right: 8px">
                      <input class="trialpha-input" v-model="ins.strategyConfig.maxUsdPerTime" />
                    </el-col>
                    <el-col :span="6" style="display: flex;padding-right: 8px">
                      <input class="trialpha-input" v-model="ins.strategyConfig.alertDiffUsd" />
                    </el-col>
                    <el-col :span="6" style="display: flex;padding-right: 8px">
                      <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 28px;padding: 0 10px;font-size: 12px;border-radius: 3px" @click="positionChange(1.1)" :disabled="processing" :loading="processing" v-auth:dbWriteApi>+10%</el-button>
                    </el-col>
                    <el-col :span="6" style="display: flex">
                      <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 28px;padding: 0 10px;font-size: 12px;border-radius: 3px" @click="positionChange(0.9)" :disabled="processing" :loading="processing" v-auth:dbWriteApi>-10%</el-button>
                    </el-col>
                  </el-row>
                  <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">杠杆调整</span>
                    </el-col>
                  </el-row>
                  <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto">
                    <el-col :span="6" style="display: flex;padding-right: 8px">
                      <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 28px;padding: 0 10px;font-size: 12px;border-radius: 3px" @click="leverageChange(1)" :disabled="processing" :loading="processing" v-auth:dbWriteApi>+1</el-button>
                    </el-col>
                    <el-col :span="6" style="display: flex;padding-right: 8px">
                      <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 28px;padding: 0 10px;font-size: 12px;border-radius: 3px" @click="leverageChange(-1)" :disabled="processing" :loading="processing" v-auth:dbWriteApi>-1</el-button>
                    </el-col>
                    <el-col :span="12" style="display: flex">
                      <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 28px;padding: 0 10px;font-size: 12px;border-radius: 3px" @click="allPositionZero" :disabled="processing" :loading="processing" v-auth:dbWriteApi>所有仓位设置为 0</el-button>
                    </el-col>
                  </el-row>
                </el-card>

                <!-- 写入按钮 -->
                <el-row style="width: 98%;margin: 10px auto 0 auto">
                  <el-col :span="8">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 3px 0 0 3px;border-right: 0" @click="reset(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i class="el-icon-c-scale-to-original" style="font-size: 16px"/></el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0;border-right: 0" @click="deleteIns(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i class="el-icon-delete" style="font-size: 16px"/></el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0 3px 3px 0;border-top: 0" @click="save(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i class="el-icon-check" style="font-size: 16px"/></el-button>
                  </el-col>
                </el-row>
                <br>


                <!-- 排序 -->
                <el-card class="black-card" body-style="padding: 5px 0 15px 0" style="border-left: 0;border-right: 0;border-bottom: 0;border-radius: 0;width: 100%;background-color: rgba(0, 0, 0, 0)" shadow="none">
                  <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                    <el-col :span="5">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">按资金费时间由低到高排序</span>
                    </el-col>
                  </el-row>
                  <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                    <el-col :span="24">
                      <el-button-group style="float: left">
                        <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 118px;height: 32px;text-align: center" :disabled="sortRule.CURRENT_SORT === 'DAY'" @click="sortChange('DAY')">1 Day</el-button>
                        <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 118px;height: 32px;text-align: center" :disabled="sortRule.CURRENT_SORT === 'THREE_DAY'" @click="sortChange('THREE_DAY')">3 Days</el-button>
                        <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 118px;height: 32px;text-align: center" :disabled="sortRule.CURRENT_SORT === 'WEEK'" @click="sortChange('WEEK')">1 Week</el-button>
                        <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 118px;height: 32px;text-align: center" :disabled="sortRule.CURRENT_SORT === 'TWO_WEEK'" @click="sortChange('TWO_WEEK')">2 Week</el-button>
                        <el-button class="dark-mode-btn ellipsis-line" size="small" style="width: 118px;height: 32px;text-align: center" :disabled="sortRule.CURRENT_SORT === 'MONTH'" @click="sortChange('MONTH')">1 Month</el-button>
                      </el-button-group>
                    </el-col>
                  </el-row>
                </el-card>

                <!-- 交易对详细 -->
                <el-card class="black-card" body-style="padding: 5px 0 15px 0" style="border-left: 0;border-right: 0;border-bottom: 0;border-radius: 0;width: 100%;background-color: rgba(0, 0, 0, 0)" v-for="(pair, index) in keyList.filter((s) => { if (search === '' || s.indexOf(search.toUpperCase()) > -1) { return true; } })" :key="index" shadow="none">
                  <el-row v-if="pairMap[pair]" style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">做空交易对</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">做多交易对</span>
                    </el-col>
                    <el-col :span="6" v-if="pairMap[pair] && pairMap[pair].DAY">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">1 Day</span>
                    </el-col>
                    <el-col :span="6" v-if="pairMap[pair] && pairMap[pair].THREE_DAY">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">3 Days</span>
                    </el-col>
                  </el-row>
                  <el-row v-if="pairMap[pair]" style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                    <el-col :span="6" style="padding-right: 10px">
                      <input class="trialpha-input" v-model="pairMap[pair].shortSymbol" style="outline: none;color: white;border-radius: 3px;font-size: 12px;height: 28px;padding: 0 5px 0 5px;width: 100%" disabled/>
                      <img :src="exchangeInfo[pairMap[pair].shortPlatform].logo" style="width: 18px;height: 18px;position: absolute;margin-top: 5px;margin-left: -22px" />
                    </el-col>
                    <el-col :span="6" style="padding-right: 10px">
                      <input class="trialpha-input" v-model="pairMap[pair].longSymbol" style="outline: none;color: white;border-radius: 3px;font-size: 12px;height: 28px;padding: 0 5px 0 5px;width: 100%" disabled/>
                      <img :src="exchangeInfo[pairMap[pair].longPlatform].logo" style="width: 18px;height: 18px;position: absolute;margin-top: 5px;margin-left: -22px" />
                    </el-col>
                    <el-col :span="6" style="padding-right: 10px" v-if="pairMap[pair] && pairMap[pair].DAY">
                      <input class="trialpha-input" :value="((pairMap[pair].DAY)).toFixed(3) + '%' + ' / ' + ((pairMap[pair].DAY * 100) / accountUsdBalance.TOTAL).toFixed(3) + '%'" :style="'color: ' + getProfitFontColor(dayProfitArray, pairMap[pair].DAY) + ';outline: none;border-radius: 3px;font-size: 12px;height: 28px;padding: 0 5px 0 5px;width: 100%'" disabled/>
                    </el-col>
                    <el-col :span="6" style="padding-right: 0" v-if="pairMap[pair] && pairMap[pair].THREE_DAY">
                      <input class="trialpha-input" :value="((pairMap[pair].THREE_DAY)).toFixed(3) + '%' + ' / ' + ((pairMap[pair].THREE_DAY * 100) / accountUsdBalance.TOTAL).toFixed(3) + '%'" :style="'color: ' + getProfitFontColor(threeDayProfitArray, pairMap[pair].THREE_DAY) + ';outline: none;border-radius: 3px;font-size: 12px;height: 28px;padding: 0 5px 0 5px;width: 100%'" disabled/>
                    </el-col>
                  </el-row>
                  <el-row v-if="pairMap[pair]" style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">做空实际仓位</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">做多实际仓位</span>
                    </el-col>
                    <el-col :span="4">
                      <span class="dark-mode-font" style="color: white;font-size: 12px" v-if="pairMap[pair].WEEK">7 Days</span>
                    </el-col>
                    <el-col :span="4">
                      <span class="dark-mode-font" style="color: white;font-size: 12px" v-if="(pairMap[pair].TWO_WEEK)">15 Days</span>
                    </el-col>
                    <el-col :span="4" v-if="(pairMap[pair].MONTH)">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">30 Days</span>
                    </el-col>
                  </el-row>
                  <el-row v-if="pairMap[pair]" style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                    <el-col :span="6" style="padding-right: 10px">
                      <input class="trialpha-input" v-model="pairMap[pair].shortSymbolRealPosition" style="outline: none;color: grey;border-radius: 3px;font-size: 12px;height: 28px;padding: 0 5px 0 5px;width: 100%" disabled/>
                    </el-col>
                    <el-col :span="6" style="padding-right: 10px">
                      <input class="trialpha-input" v-model="pairMap[pair].longSymbolRealPosition" style="outline: none;color: grey;border-radius: 3px;font-size: 12px;height: 28px;padding: 0 5px 0 5px;width: 100%" disabled/>
                    </el-col>
                    <el-col :span="4" style="padding-right: 10px" v-if="pairMap[pair].WEEK">
                      <input class="trialpha-input" :value="((pairMap[pair].WEEK)).toFixed(3) + '%' + ' / ' + ((pairMap[pair].WEEK * 100) / accountUsdBalance.TOTAL).toFixed(3) + '%'" :style="'color: ' + getProfitFontColor(weekProfitArray, pairMap[pair].WEEK) + ';outline: none;border-radius: 3px;font-size: 12px;height: 28px;padding: 0 5px 0 5px;width: 100%'" disabled/>
                    </el-col>
                    <el-col :span="4" style="padding-right: 10px" v-if="(pairMap[pair].TWO_WEEK)">
                      <input class="trialpha-input" :value="((pairMap[pair].TWO_WEEK)).toFixed(3) + '%' + ' / ' + ((pairMap[pair].TWO_WEEK * 100) / accountUsdBalance.TOTAL).toFixed(3) + '%'" :style="'color: ' + getProfitFontColor(twoWeekProfitArray, pairMap[pair].TWO_WEEK) + ';outline: none;border-radius: 3px;font-size: 12px;height: 28px;padding: 0 5px 0 5px;width: 100%'" disabled/>
                    </el-col>
                    <el-col :span="4" v-if="(pairMap[pair].MONTH)">
                      <input class="trialpha-input" :value="((pairMap[pair].MONTH)).toFixed(3) + '%' + ' / ' + ((pairMap[pair].MONTH * 100) / accountUsdBalance.TOTAL).toFixed(3) + '%'" :style="'color: ' + getProfitFontColor(monthProfitArray, pairMap[pair].MONTH) + ';outline: none;border-radius: 3px;font-size: 12px;height: 28px;padding: 0 5px 0 5px;width: 100%'" disabled/>
                    </el-col>
                  </el-row>
                  <el-row v-if="pairMap[pair]" style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">对冲币数</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">美元价值</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">杠杆配置</span>
                    </el-col>
                  </el-row>
                  <el-row v-if="pairMap[pair]" style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                    <el-col :span="6" style="padding-right: 10px">
                      <input class="trialpha-input" v-model="pairMap[pair].position" style="outline: none;color: white;border-radius: 3px;font-size: 12px;height: 28px;padding: 0 5px 0 5px;width: 100%" @change="createReport"/>
                    </el-col>
                    <el-col :span="6" style="padding-right: 10px">
                      <input class="trialpha-input" :value="(pairMap[pair].position * pairMap[pair].price).toFixed(2)" style="outline: none;color: gray;border-radius: 3px;font-size: 12px;height: 28px;padding: 0 5px 0 5px;width: 100%" disabled/>
                    </el-col>
                    <el-col :span="6" style="padding-right: 10px">
                      <input class="trialpha-input" v-model="pairMap[pair].leverage" style="outline: none;color: white;border-radius: 3px;font-size: 12px;height: 28px;padding: 0 5px 0 5px;width: 100%"/>
                    </el-col>
                    <el-col :span="6">
                      <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 28px;padding: 0 10px;font-size: 12px;border-radius: 3px" @click="deleteSymbol(pairMap[pair])" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i class="el-icon-delete" style="font-size: 16px"/></el-button>
                    </el-col>
                  </el-row>
                </el-card>
              </el-row>
            </el-collapse-item>
          </el-collapse>
          <el-collapse class="trialpha-collapse" v-model="createCollapse" v-if="instanceList.length === 0" style="border-top: 0">
            <el-collapse-item class="trialpha-collapse">
              <template slot="title">
                <i class="el-icon-menu" style="margin-left: 2px;color: #0ecb81;margin-top: 1px"/><h4 class="dark-mode-font" style="margin-left: 8px;padding-top: 6px;color: white">Create New Instance</h4>
              </template>
              <input
                  style="margin-top: 25px;width: 100%"
                  type="file"
                  accept=".xls,.xlsx"
                  class="upload_file"
                  @change="readExcel($event, instance)"
              />
            </el-collapse-item>
          </el-collapse>
        </el-row>
      </template>
    </el-skeleton>
  </div>
</template>

<script>
import {Message} from "element-ui";
import StrategyInstanceService from "@/api/trade/StrategyInstanceService";
import ConfirmProvider from "@/components/ConfirmProvider";
import TrialphaSk from "@/components/TrialphaSk";
import SymbolInfoService from "@/utils/SymbolInfoService";
import SymbolFundingFeeService from "@/utils/SymbolFundingFeeService";
import GeneralApiService from "@/api/trade/GeneralApiService";
import * as XLSX from 'xlsx';
import ExchangeInformations from "@/const/ExchangeInformations";

export default {
  name: "FundingFeeStrategyConfigV2",
  components: {TrialphaSk},
  props: {
    dto: Object
  },
  data: function () {
    return {
      processing: false,
      rendering: true,
      activeNames: ['1'],
      createCollapse: ['1'],
      instance: {},
      instanceList: [],
      accountInstance: {},
      allPrice: {},
      allSymbols: [],
      configArea: '',
      search: '',
      pairMap: {},
      newPair: {
        coin: '',
        shortSymbol: '',
        longSymbol: '',
        shortPlatform: '',
        longPlatform: '',
        leverage: 5,
        position: 0,
        maxPerTime: 0
      },
      exchangeInfo: {
        BINANCE: {
          logo: ExchangeInformations.exchangeInfos.BINANCE.icon,
          fontColor: '#f5bc2f'
        },
        BITMEX: {
          logo: ExchangeInformations.exchangeInfos.BITMEX.icon,
          fontColor: '#5b69b1'
        },
        DERIBIT: {
          logo: ExchangeInformations.exchangeInfos.DERIBIT.icon,
          fontColor: '#04cfbe'
        },
        DYDX: {
          logo: ExchangeInformations.exchangeInfos.DYDX.icon,
          fontColor: '#67ccdf'
        },
        OKX: {
          logo: ExchangeInformations.exchangeInfos.OKX.icon,
          fontColor: '#67ccdf'
        },
        BYBIT: {
          logo: ExchangeInformations.exchangeInfos.BYBIT.icon,
          fontColor: '#67ccdf'
        }
      },
      exchangeList: [{value: 'BINANCE', label: 'Binance'}, {value: 'OKX', label: 'OKX'}, {value: 'BYBIT', label: 'Bybit'}, {value: 'DYDX', label: 'dYdX'}],
      positionRealReport: {
        BINANCE: {
          MARGIN: 0,
          TOTAL: 0
        },
        DYDX: {
          MARGIN: 0,
          TOTAL: 0
        },
        OKX: {
          MARGIN: 0,
          TOTAL: 0
        },
        BYBIT: {
          MARGIN: 0,
          TOTAL: 0
        }
      },
      positionReport: {
        BINANCE: {
          MARGIN: 0,
          TOTAL: 0
        },
        DYDX: {
          MARGIN: 0,
          TOTAL: 0
        },
        OKX: {
          MARGIN: 0,
          TOTAL: 0
        },
        BYBIT: {
          MARGIN: 0,
          TOTAL: 0
        }
      },
      accountUsdBalance: {
        BINANCE: 0,
        BYBIT: 0,
        OKX: 0,
        DYDX: 0,
        TOTAL: 0
      },
      totalFundingFee: {
        DAY: 0,
        THREE_DAY: 0,
        WEEK: 0,
        TWO_WEEK: 0,
        MONTH: 0
      },
      dayProfitArray: [],
      threeDayProfitArray: [],
      weekProfitArray: [],
      twoWeekProfitArray: [],
      monthProfitArray: [],
      keyList: [],
      sortRule: {
        CURRENT_SORT: 'TWO_WEEK',
        DAY: 'LowToHigh',
        THREE_DAY: 'LowToHigh',
        WEEK: 'LowToHigh',
        TWO_WEEK: 'LowToHigh',
        MONTH: 'LowToHigh'
      }
    }
  },
  created() {
    this.closeReadOnly(true);
  },
  methods: {
    allPositionZero() {
      Object.keys(this.pairMap).forEach((s) => {
        this.pairMap[s].position = 0;
      });
      this.createReport();
    },
    positionChange(value) {
      Object.keys(this.pairMap).forEach((s) => {
        this.pairMap[s].position = Number((this.pairMap[s].position * value).toFixed(4));
      });
      this.createReport();
    },
    leverageChange(value) {
      Object.keys(this.pairMap).forEach((s) => {
        this.pairMap[s].leverage = Number((Number.prototype.accAdd(this.pairMap[s].leverage, value).toFixed(1)));
      });
    },
    closeReadOnly(val) {
      this.$nextTick(() => {
        if (val) {
          this.$el.querySelectorAll('.chose-symbol.select-symbol .el-input__inner').forEach((item) => {
            item.removeAttribute('readonly');
          })
        }
      })
    },
    openTradeTools(ins) {
      this.$nextTick(function () {
        this.$refs['open-tools'].renderFtx(ins);
      });
    },
    handleShortLong(ins) {
      ins.symbol1Short = !ins.symbol1Short;
    },
    render: function (accountInstance) {
      let that = this;
      that.rendering = true;
      that.accountInstance = accountInstance;
      let totalUsdBalance = 0;
      for (const accountKey in accountInstance.tradeAccountList) {
        let accountObj = accountInstance.tradeAccountList[accountKey];
        if (accountObj.platform.toUpperCase() === GeneralApiService.PLATFORM.DERIBIT) {
          continue;
        }
        if (accountObj.baseCoin === 'USD' || accountObj.baseCoin === 'USDT') {
          that.accountUsdBalance[accountObj.platform.toUpperCase()] = accountObj.currentBalance;
        } else {
          try {
            let symbolName = SymbolInfoService.getPerpSymbolName(accountObj.baseCoin,  accountObj.platform.toUpperCase()).toUpperCase();
            let price = SymbolInfoService.getSymbolPrice(accountObj.platform.toUpperCase(), GeneralApiService.SYMBOL_TYPE.USD_CONTRACT, symbolName);
            that.accountUsdBalance[accountObj.platform.toUpperCase()] = Number((accountObj.currentBalance * price).toFixed(2));
          } catch (e) {
            console.error(e);
          }
        }
        totalUsdBalance += that.accountUsdBalance[accountObj.platform.toUpperCase()];
      }
      that.accountUsdBalance.TOTAL = Number(totalUsdBalance.toFixed(2));
      that.instance = JSON.parse(JSON.stringify(that.dto.strategyConfig));
      StrategyInstanceService.query(that.accountInstance.instanceId, that.dto.strategyName, function (data) {
        that.instanceList = data.strategyInsList;
        if (that.instanceList.length > 0) {
          let pairMapTmp = {};
          that.instanceList[0].strategyConfig.feePairList.forEach((s) => {
            let key = s.shortPlatform + s.longPlatform + s.shortSymbol + s.longSymbol;
            s.price = SymbolInfoService.getSymbolPrice(s.shortPlatform.toUpperCase(), GeneralApiService.SYMBOL_TYPE.USD_CONTRACT, s.shortSymbol.toUpperCase());
            pairMapTmp[key] = s;
          });
          that.pairMap = pairMapTmp;
          that.createReport();
        }
        that.rendering = false;
        that.processing = false;
      }, function () {
        that.rendering = false;
        that.processing = false;
      });
    },
    reset: function (ins) {
      let that = this;
      ConfirmProvider.openConfirm(this, '确认重置', '该操作将会重置所有非配置的数据', function () {
        that.processing = true;
        StrategyInstanceService.reset(ins.sid, function () {
          that.processing = false;
          Message.success('已成功重置');
          that.render(that.accountInstance);
        }, function () {
          that.processing = false;
        });
      });
    },
    deleteIns: function (ins) {
      let that = this;
      ConfirmProvider.openConfirm(this, '确认删除', '该操作将会删除该实例，此操作无法恢复', function () {
        that.processing = true;
        StrategyInstanceService.deleteIns(ins.sid, function () {
          that.processing = false;
          Message.success('已成功删除');
          that.render(that.accountInstance);
        }, function () {
          that.processing = false;
        });
      });
    },
    save: function (ins) {
      let that = this;
      that.processing = true;
      let newPairList = [];
      ins.strategyConfig.feePairList.forEach((pair) => {
        let key = pair.shortPlatform + pair.longPlatform + pair.shortSymbol + pair.longSymbol;
        let obj = that.pairMap[key];
        if (obj) {
          let newPair = {
            shortSymbol: obj.shortSymbol,
            longSymbol: obj.longSymbol,
            shortPlatform: obj.shortPlatform,
            longPlatform: obj.longPlatform,
            shortSymbolRealPosition: obj.shortSymbolRealPosition,
            longSymbolRealPosition: obj.longSymbolRealPosition,
            shortSymbolPrice: obj.shortSymbolPrice,
            longSymbolPrice: obj.longSymbolPrice,
            leverage: obj.leverage,
            position: obj.position,
            maxPerTime: obj.maxPerTime
          };
          newPairList.push(newPair);
        }
      });
      ins.strategyConfig.feePairList = newPairList;
      StrategyInstanceService.update({
        sid: ins.sid,
        strategyName: ins.strategyName,
        config: (ins.strategyConfig),
        instanceEntity: {
          id: that.accountInstance.instanceId
        },
        isActive: ins.isActive
      }, function () {
        that.processing = false;
        Message.success('已成功更新');
        that.render(that.accountInstance);
      }, function () {
        that.processing = false;
      });
    },
    add: function (ins) {
      let pairs = [];
      if (this.configArea.length > 10) {
        this.configArea.split('\n').forEach((line) => {
          let data = line.split('\t');
          if (data.length >= 3) {
            let shortSymbol = SymbolInfoService.getPerpSymbolName(data[2].replaceAll('_U', ''),  data[0]).toUpperCase();
            let longSymbol = SymbolInfoService.getPerpSymbolName(data[2].replaceAll('_U', ''),  data[1]).toUpperCase();
            let positionUSD = data[data.length - 1];
            let price = SymbolInfoService.getSymbolPrice(data[0], GeneralApiService.SYMBOL_TYPE.USD_CONTRACT, shortSymbol);
            pairs.push({
              shortSymbol: shortSymbol,
              longSymbol: longSymbol,
              shortPlatform: data[0].toUpperCase().replaceAll('OKEX', 'OKX'),
              longPlatform: data[1].toUpperCase().replaceAll('OKEX', 'OKX'),
              leverage: 5,
              position: Number((positionUSD / price).toFixed(4)),
              shortSymbolPrice: price,
              longSymbolPrice: price
            });
          }
        });
      }
      if (pairs.length <= 0) {
        Message.warning('请输入有效数据');
        return;
      }
      ins.feePairList = pairs;
      let that = this;
      that.processing = true;
      StrategyInstanceService.create({
        insId: this.accountInstance.instanceId,
        strategyName: this.dto.strategyName,
        config: JSON.stringify(ins)
      }, function () {
        Message.success('已成功创建新的实例');
        setTimeout(() => that.render(that.accountInstance), 888);
      }, function () {
        that.processing = false;
      });
    },
    addSymbol: function () {
      if (this.newPair.shortSymbol && this.newPair.longSymbol && this.newPair.shortPlatform && this.newPair.longPlatform) {
        let price = SymbolInfoService.getSymbolPrice(this.newPair.shortPlatform, GeneralApiService.SYMBOL_TYPE.USD_CONTRACT, this.newPair.shortSymbol.toUpperCase());
        let pair = {
          shortSymbol: this.newPair.shortSymbol.toUpperCase(),
          longSymbol: this.newPair.longSymbol.toUpperCase(),
          shortPlatform: this.newPair.shortPlatform,
          longPlatform: this.newPair.longPlatform,
          leverage: 10,
          position: 0,
          maxPerTime: 0,
          shortSymbolRealPosition: 0,
          longSymbolRealPosition: 0,
          shortSymbolPrice: price,
          longSymbolPrice: price
        };
        this.instanceList[0].strategyConfig.feePairList.push(pair);
        let key = pair.shortPlatform + pair.longPlatform + pair.shortSymbol + pair.longSymbol;
        this.pairMap[key] = pair;
        this.createReport();
      } else {
        Message.warning('必要信息未填写');
      }
    },
    deleteSymbol: function (pair) {
      this.$nextTick(() => {
        let key = pair.shortPlatform + pair.longPlatform + pair.shortSymbol + pair.longSymbol;
        delete this.pairMap[key];
        this.$forceUpdate();
      });
      this.createReport();
    },
    createReport: function () {
      let tmpReport = {
        BINANCE: {
          MARGIN: 0,
          TOTAL: 0
        },
        DYDX: {
          MARGIN: 0,
          TOTAL: 0
        },
        OKX: {
          MARGIN: 0,
          TOTAL: 0
        },
        BYBIT: {
          MARGIN: 0,
          TOTAL: 0
        }
      }
      let tmpRealReport = {
        BINANCE: {
          MARGIN: 0,
          TOTAL: 0
        },
        DYDX: {
          MARGIN: 0,
          TOTAL: 0
        },
        OKX: {
          MARGIN: 0,
          TOTAL: 0
        },
        BYBIT: {
          MARGIN: 0,
          TOTAL: 0
        }
      }
      let realPositionCalc = [];
      let totalFundingFeeTmp = {
        DAY: 0,
        THREE_DAY: 0,
            WEEK: 0,
            TWO_WEEK: 0,
            MONTH: 0
          };
      let dayArray = [];
      let threeDayArray = [];
      let weekArray = [];
      let twoWeekArray = [];
      let monthArray = [];
      Object.keys(this.pairMap).forEach((pair) => {
        let obj = this.pairMap[pair];
        let coinPosition = obj.position;
        let shortPrice = SymbolInfoService.getSymbolPrice(obj.shortPlatform, GeneralApiService.SYMBOL_TYPE.USD_CONTRACT, obj.shortSymbol);
        let longPrice = SymbolInfoService.getSymbolPrice(obj.longPlatform, GeneralApiService.SYMBOL_TYPE.USD_CONTRACT, obj.longSymbol);
        let shortDayFee = SymbolFundingFeeService.getFundingFee(obj.shortPlatform.toUpperCase(), GeneralApiService.SYMBOL_TYPE.USD_CONTRACT, obj.shortSymbol, SymbolFundingFeeService.FUNDING_DAYS.DAY);
        let longDayFee = SymbolFundingFeeService.getFundingFee(obj.longPlatform.toUpperCase(), GeneralApiService.SYMBOL_TYPE.USD_CONTRACT, obj.longSymbol, SymbolFundingFeeService.FUNDING_DAYS.DAY);
        let short3DayFee = SymbolFundingFeeService.getFundingFee(obj.shortPlatform.toUpperCase(), GeneralApiService.SYMBOL_TYPE.USD_CONTRACT, obj.shortSymbol, SymbolFundingFeeService.FUNDING_DAYS.THREE_DAY);
        let long3DayFee = SymbolFundingFeeService.getFundingFee(obj.longPlatform.toUpperCase(), GeneralApiService.SYMBOL_TYPE.USD_CONTRACT, obj.longSymbol, SymbolFundingFeeService.FUNDING_DAYS.THREE_DAY);
        let shortWeekFee = SymbolFundingFeeService.getFundingFee(obj.shortPlatform.toUpperCase(), GeneralApiService.SYMBOL_TYPE.USD_CONTRACT, obj.shortSymbol, SymbolFundingFeeService.FUNDING_DAYS.WEEK);
        let longWeekFee = SymbolFundingFeeService.getFundingFee(obj.longPlatform.toUpperCase(), GeneralApiService.SYMBOL_TYPE.USD_CONTRACT, obj.longSymbol, SymbolFundingFeeService.FUNDING_DAYS.WEEK);
        let shortTwoWeekFee = SymbolFundingFeeService.getFundingFee(obj.shortPlatform.toUpperCase(), GeneralApiService.SYMBOL_TYPE.USD_CONTRACT, obj.shortSymbol, SymbolFundingFeeService.FUNDING_DAYS.TWO_WEEK);
        let longTwoWeekFee = SymbolFundingFeeService.getFundingFee(obj.longPlatform.toUpperCase(), GeneralApiService.SYMBOL_TYPE.USD_CONTRACT, obj.longSymbol, SymbolFundingFeeService.FUNDING_DAYS.TWO_WEEK);
        let shortMonthFee = SymbolFundingFeeService.getFundingFee(obj.shortPlatform.toUpperCase(), GeneralApiService.SYMBOL_TYPE.USD_CONTRACT, obj.shortSymbol, SymbolFundingFeeService.FUNDING_DAYS.MONTH);
        let longMonthFee = SymbolFundingFeeService.getFundingFee(obj.longPlatform.toUpperCase(), GeneralApiService.SYMBOL_TYPE.USD_CONTRACT, obj.longSymbol, SymbolFundingFeeService.FUNDING_DAYS.MONTH);
        obj.DAY = (shortDayFee - longDayFee);
        obj.THREE_DAY = (short3DayFee - long3DayFee);
        obj.WEEK = (shortWeekFee - longWeekFee);
        obj.TWO_WEEK = (shortTwoWeekFee - longTwoWeekFee);
        obj.MONTH = (shortMonthFee - longMonthFee);
        dayArray.push(obj.DAY);
        threeDayArray.push(obj.THREE_DAY);
        weekArray.push(obj.WEEK);
        twoWeekArray.push(obj.TWO_WEEK);
        monthArray.push(obj.MONTH);
        totalFundingFeeTmp.DAY += obj.DAY * (coinPosition * shortPrice) / 100;
        totalFundingFeeTmp.THREE_DAY += obj.THREE_DAY * (coinPosition * shortPrice) / 100;
        totalFundingFeeTmp.WEEK += obj.WEEK * (coinPosition * shortPrice) / 100;
        totalFundingFeeTmp.TWO_WEEK += obj.TWO_WEEK * (coinPosition * shortPrice) / 100;
        totalFundingFeeTmp.MONTH += obj.MONTH * (coinPosition * shortPrice) / 100;
        if (!shortPrice || !longPrice) {
          console.log(obj);
        }
        tmpReport[obj.shortPlatform.toUpperCase()].MARGIN = tmpReport[obj.shortPlatform.toUpperCase()].MARGIN - (coinPosition * shortPrice);
        tmpReport[obj.longPlatform.toUpperCase()].MARGIN = tmpReport[obj.longPlatform.toUpperCase()].MARGIN + (coinPosition * longPrice);
        tmpReport[obj.shortPlatform.toUpperCase()].TOTAL = tmpReport[obj.shortPlatform.toUpperCase()].TOTAL + (coinPosition * shortPrice);
        tmpReport[obj.longPlatform.toUpperCase()].TOTAL = tmpReport[obj.longPlatform.toUpperCase()].TOTAL + (coinPosition * longPrice);
        let shortKey = obj.shortPlatform.toUpperCase() + '-' + obj.shortSymbol;
        let longKey = obj.longPlatform.toUpperCase() + '-' + obj.longSymbol;
        if (realPositionCalc.indexOf(shortKey) === -1) {
          realPositionCalc.push(shortKey);
          tmpRealReport[obj.shortPlatform.toUpperCase()].MARGIN = tmpRealReport[obj.shortPlatform.toUpperCase()].MARGIN - (Math.abs(obj.shortSymbolRealPosition * shortPrice));
          tmpRealReport[obj.shortPlatform.toUpperCase()].TOTAL = tmpRealReport[obj.shortPlatform.toUpperCase()].TOTAL + (Math.abs(obj.shortSymbolRealPosition * shortPrice));
        }
        if (realPositionCalc.indexOf(longKey) === -1) {
          realPositionCalc.push(longKey);
          tmpRealReport[obj.longPlatform.toUpperCase()].MARGIN = tmpRealReport[obj.longPlatform.toUpperCase()].MARGIN + (Math.abs(obj.longSymbolRealPosition * longPrice));
          tmpRealReport[obj.longPlatform.toUpperCase()].TOTAL = tmpRealReport[obj.longPlatform.toUpperCase()].TOTAL + (Math.abs(obj.longSymbolRealPosition * longPrice));
        }
      });
      this.totalFundingFee = totalFundingFeeTmp;
      this.positionReport = tmpReport;
      this.positionRealReport = tmpRealReport;
      dayArray.sort();
      threeDayArray.sort();
      weekArray.sort();
      twoWeekArray.sort();
      monthArray.sort();
      this.dayProfitArray = dayArray;
      this.threeDayProfitArray = threeDayArray;
      this.weekProfitArray = weekArray;
      this.twoWeekProfitArray = twoWeekArray;
      this.monthProfitArray = monthArray;
      this.getFeePairNameList();
    },
    getProfitFontColor: function (queryProfitArray, profit) {
      if (profit < 0) {
        return 'rgb(246, 70, 93)';
      }
      let rank = queryProfitArray.length - 1 - queryProfitArray.indexOf(profit);
      let rankRate = rank / (queryProfitArray.length - 1);
      if (rankRate <= 0.33) {
        return 'rgb(14, 203, 129)';
      } else if (rankRate > 0.66){
        return 'rgb(206,120,131)';
      } else {
        return 'rgb(230,166,102)';
      }
    },
    readExcel(e, ins) {
      // 读取表格文件
      let that = this;
      const files = e.target.files;
      if (files.length <= 0) {
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$message({
          message: "上传格式不正确，请上传xls或者xlsx格式",
          type: "warning"
        });
        return false;
      } else {
        // 更新获取文件名
        that.upload_file = files[0].name;
      }

      const fileReader = new FileReader();
      fileReader.onload = ev => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "string"
          });
          const wsName = workbook.SheetNames[0]; //取第一张表
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsName]); //生成json表格内容
          let pairs = [];
          for (const wsKey in ws) {
            let obj = ws[wsKey];
            if (obj.platform1 === '-' || obj.platform2 === '-') {
              Message.error('文件内容不合法');
              return;
            }
            let shortSymbol = obj.side1 === 'LONG' ? obj.symbol2 : obj.symbol1;
            let longSymbol = obj.side1 === 'LONG' ? obj.symbol1 : obj.symbol2;
            let positionUSD = obj.position;
            let price = SymbolInfoService.getSymbolPrice(obj.platform1, obj.symbolType1, obj.symbol1);
            pairs.push({
              shortSymbol: shortSymbol,
              longSymbol: longSymbol,
              shortPlatform: obj.side1 === 'LONG' ? obj.platform2 : obj.platform1,
              longPlatform: obj.side1 === 'LONG' ? obj.platform1 : obj.platform2,
              leverage: 5,
              position: Number((positionUSD / price).toFixed(4)),
              shortSymbolPrice: price,
              longSymbolPrice: price
            });
          }
          if (pairs.length <= 0) {
            Message.warning('请输入有效数据');
            return;
          }
          ins.feePairList = pairs;
          let that = this;
          that.processing = true;
          StrategyInstanceService.create({
            insId: this.accountInstance.instanceId,
            strategyName: this.dto.strategyName,
            config: JSON.stringify(ins)
          }, function () {
            Message.success('已成功创建新的实例');
            setTimeout(() => that.render(that.accountInstance), 888);
          }, function () {
            that.processing = false;
          });
        } catch (e) {
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
    getFeePairNameList() {
      this.keyList = Object.keys(this.pairMap);
      this.sortCoinList(this.sortRule.CURRENT_SORT);
    },
    sortCoinList: function (sortKey) {
      this.keyList.sort((a, b) => {
        let objA = this.pairMap[a];
        let objB = this.pairMap[b];
        if (objA[sortKey] < objB[sortKey]) {
          if (this.sortRule[sortKey] === 'LowToHigh') {
            return -1;
          } else {
            return 1;
          }
        }
        return 0;
      });
    },
    sortChange: function (key) {
      this.sortRule.CURRENT_SORT = key;
      this.sortCoinList(key);
      this.$forceUpdate();
    }
  }
}
</script>

<style scoped>

.ellipsis-line {
  padding: 8px;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-collapse {
  color: #FFFFFF;
  border-top: 1px solid #444c56;
  border-bottom: 1px solid #444c56;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: white;
}

.el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-divider {
  border-top: 1px solid #444c56;
}

.el-divider.info {
  width: 80%;
  border-top: 1px solid #444c56;
}

.el-divider__text {
  color: white;
  background-color: #444c56;
}

.select-symbol .el-input__inner {
  color: white;
  height: 35px !important;
  line-height: 35px;
  width: 100%;
  padding: 0 10px 0 10px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-right: 0;
  border-radius: 0;
  font-size: 12px;
  outline: none;
}

.select-symbol.el-select .el-input.is-focus .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:hover .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:hover .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:focus .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:visited .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:focus .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:visited .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

</style>