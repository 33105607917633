<template>
  <el-card class="trade-black-card" style="overflow-y: auto;height: 100%;border-radius: 0;border: 0;margin-top: 5px" body-style="padding: 0" shadow="none">
    <div class="hide-less-971">
      <el-card class="trade-black-card" body-style="padding: 0" shadow="none" style="border: 0">
        <el-row style="border-bottom: 1px solid rgba(128,128,128,0.18);padding-left: 15px;padding-right: 15px">
          <el-col :span="3">
            <span class="position-header" style="float: left">Symbol</span>
          </el-col>
          <el-col :span="2">
            <span class="position-header">Size</span>
          </el-col>
          <el-col :span="3">
            <span class="position-header">Entry Price</span>
          </el-col>
          <el-col :span="3">
            <span class="position-header">Liquidation Price</span>
          </el-col>
          <el-col :span="3">
            <span class="position-header">PNL</span>
          </el-col>
          <el-col :span="3">
            <span class="position-header">Collateral Used</span>
          </el-col>
          <el-col :span="4" :offset="3">
            <span class="position-header">Operation</span>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="position-item" body-style="padding: 0" shadow="none" v-for="symbol in Object.keys(positions)" :key="symbol">
        <el-row>
          <el-col :span="3">
            <span class="position-header" style="color: white;float: left">{{ symbol }}</span>
          </el-col>
          <el-col :span="2">
            <span class="position-header" :style="'' + (positions[symbol].size > 0 ? 'color: #0ecb81' : 'color: #ef5350') + ''">{{ positions[symbol].size }}</span>
          </el-col>
          <el-col :span="3">
            <span class="position-header" style="color: white">{{ positions[symbol].recentBreakEvenPrice }}</span>
          </el-col>
          <el-col :span="3">
            <span class="position-header" style="color: white">{{ positions[symbol].estimatedLiquidationPrice }}</span>
          </el-col>
          <el-col :span="3">
            <span class="position-header" :style="'' + (positions[symbol].recentPnl > 0 ? 'color: #0ecb81' : 'color: #ef5350') + ''">${{ positions[symbol].recentPnl }}</span>
          </el-col>
          <el-col :span="3">
            <span class="position-header" style="color: white">{{ positions[symbol].collateralUsed }}</span>
          </el-col>
          <el-col :span="7">
            <el-button class="close-position-btn" v-auth:tradePanelWrite style="font-size: 12px;padding: 0 15px 0 15px;float: right;margin-top: 5px">MARKET CLOSE</el-button>
            <input class="trade-black-card" v-auth:tradePanelWrite type="text" style="margin-top: 5px;background-color: #1f2b3a;width: 110px;outline: none;border: 0;color: white;margin-right: 8px;font-size: 12px;height: 24px;line-height: 24px;padding-left: 5px;border-radius: 3px;float: right" placeholder="Amount">
          </el-col>
        </el-row>
      </el-card>
    </div>
    <div class="hide-more-971">
      <el-card class="position-card-item" body-style="padding: 0" shadow="none" v-for="symbol in Object.keys(positions)" :key="symbol">
        <el-row style="margin-top: 5px">
          <el-col :span="11" style="margin-top: 8px">
            <span class="position-header ellipsis-line" style="color: white;float: left;font-size: 13px;">{{ symbol }}</span>
          </el-col>
          <el-col :span="13">
            <el-button class="close-position-btn" style="font-size: 12px;padding: 0 15px 0 15px;float: right;margin-top: 5px">MARKET CLOSE</el-button>
            <input class="trade-black-card" type="text" style="margin-top: 5px;background-color: #1f2b3a;width: 60px;outline: none;border: 0;color: white;margin-right: 8px;font-size: 12px;height: 24px;line-height: 24px;padding-left: 5px;border-radius: 3px;float: right" placeholder="Amount">
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-row>
            <el-col :span="12">
              <span class="position-header" style="float: left">PNL</span>
            </el-col>
            <el-col :span="12">
              <span class="position-header" style="float: right">Size</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <span class="position-header" :style="'' + (Number(positions[symbol].recentPnl) > 0 ? 'color: #0ecb81' : 'color: #ef5350') + ';float: left;font-size: 15px'">{{ positions[symbol].recentPnl }}</span>
            </el-col>
            <el-col :span="12">
              <span class="position-header" :style="'' + (Number(positions[symbol].size) > 0 ? 'color: #0ecb81' : 'color: #ef5350') + ';float: right;font-size: 15px'">{{ positions[symbol].size }}</span>
            </el-col>
          </el-row>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="7">
            <span class="position-header" style="color: grey;float: left;font-size: 12px">Entry Price</span>
          </el-col>
          <el-col :span="7">
            <span class="position-header" style="color: grey;float: right;font-size: 12px">Liquidation Price</span>
          </el-col>
          <el-col :span="10">
            <span class="position-header" style="color: grey;float: right;font-size: 12px">Collateral Used</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 0">
          <el-col :span="7">
            <span class="position-header" style="color: white;float: left;font-size: 12px">{{ positions[symbol].recentBreakEvenPrice }}</span>
          </el-col>
          <el-col :span="7">
            <span class="position-header" style="color: white;float: right">{{ positions[symbol].estimatedLiquidationPrice }}</span>
          </el-col>
          <el-col :span="10">
            <span class="position-header" style="color: white;float: right;font-size: 12px">{{ positions[symbol].collateralUsed }}</span>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <el-row v-if="Object.keys(positions).length <= 0" style="margin-top: 100px">
      <i class="el-icon-s-order" style="font-size: 25px;color: rgba(128,128,128,0.48)"></i>
    </el-row>
    <el-row v-if="Object.keys(positions).length <= 0" style="margin-top: -5px">
      <span style="font-size: 12px;color: rgba(128,128,128,0.48)">Empty</span>
    </el-row>
  </el-card>
</template>

<script>

import TradeApiService from "@/api/trade/TradeApiService";

export default {
  name: "FtxPositionPanel",
  props: {
    symbol: String
  },
  data: function () {
    return {
      platform: 'FTX',
      positions: {},
      tradeAccountIns: {},
      totalSize: 0,
      totalPnl: 0,
      renderingPosition: false,
      positionTimer: {}
    }
  },
  created() {
  },
  beforeDestroy() {
    clearInterval(this.positionTimer);
  },
  methods: {
    timestampToDate(timestamp) {
      let date = new Date(Number(timestamp));
      let h = date.getHours() + ':';
      let m = date.getMinutes() + ':';
      let s = date.getSeconds();
      return h + m + s;
    },
    render: function (tradeAccount) {
      let that = this;
      this.tradeAccountIns = tradeAccount;
      TradeApiService.getAllPosition(that.platform, this.tradeAccountIns.apiKey, function (data) {
        that.initPosition(data);
      });
      that.positionTimer = setInterval(() => {
        TradeApiService.getAllPosition(that.platform, this.tradeAccountIns.apiKey, function (data) {
          that.initPosition(data);
        })
      }, 6868);
    },
    initPosition: function (data) {
      let tmp = {};
      let pnlT = 0;
      let totalUSize = 0;
      let that = this;
      for (let dataKey in data) {
        if (data[dataKey].netSize !== 0) {
          tmp[dataKey] = data[dataKey];
          pnlT += data[dataKey].recentPnl;
          totalUSize += Math.abs(data[dataKey].size) * data[dataKey].entryPrice;
        }
      }
      that.totalSize = totalUSize.toFixed(2);
      that.totalPnl = pnlT.toFixed(2);
      that.positions = tmp;
      that.renderingPosition = false;
    }
  }

}
</script>

<style scoped>

</style>