<template>
    <div class="create-customer-dialog" :style="[show ? 'display: block;' : 'display: none;']">

        <div class="create-customer-dialog-content">

            <div class="create-customer-dialog-content-inner">
                <div @click="onAction({id: 'close'})" style="position: absolute; top: -56px; right: -46px; width: 32px; height: 32px; background: #3E454E; color: white; cursor: pointer; border-radius: 16px; font-size: 19px; padding: 3px;">
                    <i class="el-icon-close"></i>
                </div>
            
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="7" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">转出地址</label>
                    </el-col>
                    <el-col :span="14" style="text-align: left;">
                        <select v-model="sourceWallet" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px">
                            <option v-for="(item, index) in sourceWalletList" :key="index" :value="item.value">{{item.origin && item.origin.humanReadableName ? item.origin.humanReadableName + ' : ' : ''}}{{item.text}}</option>
                        </select>
                    </el-col>
                </el-row>

                <!-- 步骤控制 -->
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; margin-top: 20px;">
                    <el-col :span="24">
                        
                        <FtGroupButtonWidget :buttons="[{
                            id: 'delete',
                            icon: '',
                            text: '删除',
                            textI18n: '',
                        }]" mode="single" v-on:switch-change="onAction" style="float: none; display: inline-block; margin: auto; background: #3E454E;" />
                    
                    </el-col>
                </el-row>
            </div>

        </div>

    </div>
</template>

<script>
import {Message} from "element-ui";
import ConfirmProvider from '@/components/ConfirmProvider'
import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";

import WalletManagement from "@/api/wallet/WalletManagement";
import UserService from "@/api/user/UserService";

import { useWalletStore, useWalletOutsideStore } from '@/store/wallet'
import TrialphaLoading from "@/components/TrialphaLoading";

export default {
    components: {
        FtGroupButtonWidget,
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'cex'
        }
    },
    data() {
        return {
            userName: '',
            // 选中的钱包
            sourceWallet: '',
        }
    },
    computed: {
        // 转出钱包地址
        sourceWalletList: () => {
            let walletOutsideStore = useWalletOutsideStore()
            return [{text: '请选择', value: ''}].concat(walletOutsideStore.sourceWalletList)
        },
    },
    mounted() {
        this.userName = UserService.getUserName()
    },
    methods: {

        // 各种切换处理
        onAction(item) {
            let that = this
            if (item.id == 'close') {
                console.log('switch change event', item.id)
                this.$emit('update:show', false)
                let walletStore = useWalletStore()
                walletStore.resetFormData()
            } else if (item.id == 'delete') {
                let wallet = that.sourceWalletList.filter(w => {
                    return w.value == that.sourceWallet
                })[0]
                console.log('删除钱包信息', wallet, that.sourceWallet, that.sourceWalletList)
                if (!wallet || !wallet.origin) {
                    Message.error('请选择钱包');
                    return
                }
                let params = {
                    walletId: wallet.origin.id
                }
                ConfirmProvider.openConfirm(that, '操作确认', '删除钱包确认', function () {
                    var loading = TrialphaLoading.loading(that);
                    WalletManagement.deleteWallet(params).then(
                        (res) => {
                            console.log('删除成功', res)
                            Message.success('删除成功');
                            let walletStore = useWalletStore()
                            // walletStore.listWallet()
                            walletStore.listWalletCreatedByRequester().then(() => {
                                that.$emit('update:show', false)
                                that.$emit('success', {id: 'deleteWalletSuccess', value: params})
                                loading.close()
                            }, () => {
                                loading.close()
                            })
                        },
                        () => {
                            console.log('删除失败')
                            // Message.error(this.$i("operationFail"));
                            loading.close()
                        }
                    );
                })
            }
        },

        // 提示框
        doTipBoxShow() {
            this.showTipBox = true
        },
        doTipBoxHide() {
            this.showTipBox = false
        }
        
    }
}
</script>

<style scoped>
.create-customer-dialog{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
}
.create-customer-dialog-content{
    background: #22272E;
    width: 500px;
    /* height: 360px; */
    padding: 30px;
    border: 1px solid #3E454E;
    border-radius: 8px;
    position: fixed;
    left: 50%;
    top: 30%;
    margin-left: -250px;
    margin-top: -140px;
}
.create-customer-dialog-content-inner{
    position: relative;
}
</style>