<script>

import Web3Service from "@/web3/Web3Service";
import AbmTokenAbi from "@/web3/abis/AbmTokenAbi";

let contractAddress = '0x9862f8d8BA03542C14C064199A6FFb1d5895c3E7';

function getContract(that) {
  let web3 = Web3Service.getWeb3(that);
  return new web3.eth.Contract(AbmTokenAbi.ABI, contractAddress);
}

export default {
  getContract,
  contractAddress
}
</script>