<script type="text/javascript">
import AbmNet from "@/api/net/AbmNet";
import ServiceMapper from "@/const/ServiceMapper"

function updateUserRole(role, userId, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.updateUserRole, {
    role: role,
    userId: userId
  }, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getUserList(page, pageSize, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.getUserList + "?page=" + page + "&size=" + pageSize, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function getVerificationCode(success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.getVerificationCode, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function updateUserInfo(req, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.updateUserInfo, req, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function getRolePermissionList(success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.rolePermissionList,
      function (data) {
        success(data)
      },
      function (err) {
        error(err)
      });
}

function updateRolePermission(updateUserRole, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.updateRolePermission, updateUserRole, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

export default {
  updateUserRole,
  getUserList,
  updateRolePermission,
  getRolePermissionList,
  updateUserInfo,
  getVerificationCode
}
</script>