<template>
    <div class="ft-input-box">
        <div class="ft-input-box-inner">
            <div>
                <template v-if="displayText">
                    <input type="text" :value="displayText" :placeholder="placeholder" :readonly="'true'" />
                </template>
                <template v-else>
                    <input type="text" v-model="text" :placeholder="placeholder" :readonly="readonly == 'true'" v-on:blur="onBlur" />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        
    },
    props: {
        value: {
            type: [String, Number]
        },
        placeholder: {
            type: String
        },
        readonly: {
            type: String
        },
        displayText: {
            type: String
        },
    },
    data() {
        return {
            
        }
    },
    computed: {
        text: {
            get() {
                return this.value;
            },
            set(v) {
                console.log('input', v)
                this.$emit("input", v);
            }
        }
    },
    methods: {
        onBlur() {
            this.$emit("switch-blur");
        }
    }
}
</script>

<style scoped>
.ft-input-box{
    height: 30px; padding: 0; border: 1px solid #3E454E; border-radius: 3px; margin-left: 16px;font-size: 12px;
}
.ft-input-box .ft-input-box-inner{
    display: flex;color: white;font-size: 12px;
    width: 100%;
}
.ft-input-box .ft-input-box-inner > div{
    padding: 0 0; cursor: pointer; border-radius: 3px; line-height: 29px; position: relative;font-size: 12px;
    width: 100%;
}
.ft-input-box .ft-input-box-inner input{
    height: 30px; width: 100%; background-color: transparent; color: white; outline: none; border: 0;font-size: 12px;padding: 5px 10px 5px 10px;line-height: 30px;
}
.ft-input-box .ft-input-box-inner i{
    position: absolute; right: 4px; top: 8px; font-weight: bold;font-size: 12px;
}



</style>