<template>
    <div class="create-customer-dialog" :style="[show ? 'display: block;' : 'display: none;']">

        <div class="create-customer-dialog-content">

            <div class="create-customer-dialog-content-inner">
                <div @click="onAction({id: 'close'})" style="position: absolute; top: -56px; right: -46px; width: 32px; height: 32px; background: #3E454E; color: white; cursor: pointer; border-radius: 16px; font-size: 19px; padding: 3px;">
                    <i class="el-icon-close"></i>
                </div>
                
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; margin-bottom: 20px;">
                    <el-col :span="24" style="text-align: left;">
                        <FtGroupButtonWidget :buttons="[{
                            id: '',
                            icon: '',
                            text: '添加常用钱包',
                            textI18n: '',
                            active: true
                        }]" />
                    </el-col>
                </el-row>
                        
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; display: none;">
                    <el-col :span="7" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">类型</label>
                    </el-col>
                    <el-col :span="14" style="text-align: left;">
                        <select v-model="form.walletType" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px">
                            <option v-for="(item, index) in [{value: 'CEX', text: '交易所钱包'}, {value: 'DEX', text: '链上钱包'}]" :key="index" :value="item.value">{{item.text}}</option>
                        </select>
                    </el-col>
                </el-row>
                        
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="7" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                            钱包名
                        </label>
                    </el-col>
                    <el-col :span="14" style="text-align: left;">
                        <input v-model="form.name" type="text" value="" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                    </el-col>
                </el-row>
                        
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="7" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                            地址
                        </label>
                    </el-col>
                    <el-col :span="14" style="text-align: left;">
                        <input v-model="form.address" type="text" value="" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                    </el-col>
                </el-row>
                        
                <el-row v-if="form.walletType == 'DEX'" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="7" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">链</label>
                    </el-col>
                    <el-col :span="14" style="text-align: left;">
                        <select v-model="form.chainName" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px">
                            <option v-for="(item, index) in targetChainList" :key="index" :value="item.value">{{item.text}}</option>
                        </select>
                    </el-col>
                </el-row>
                
                <!-- 步骤控制 -->
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; margin-top: 20px;">
                    <el-col :span="24">
                        <FtGroupButtonWidget :buttons="[{
                            id: 'append',
                            icon: '',
                            text: '确定',
                            textI18n: '',
                        }]" mode="single" v-on:switch-change="onAction" style="float: none; display: inline-block; margin: auto; background: #3E454E;" />
                    </el-col>
                </el-row>
            </div>

        </div>

    </div>
</template>

<script>
import {Message} from "element-ui";
import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";

import WalletManagement from "@/api/wallet/WalletManagement";
import UserService from "@/api/user/UserService";

import { useWalletStore, useWalletOutsideStore } from '@/store/wallet'
import TrialphaLoading from "@/components/TrialphaLoading";

export default {
    components: {
        FtGroupButtonWidget,
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'cex'
        }
    },
    data() {
        return {
            // 表单数据
            form: {
                name: '',
                address: '',
                chainName: '',
                walletType: 'DEX',
            },
        }
    },
    computed: {
      // 目标链列表
      targetChainList: () => {
        // let walletOutsideStore = useWalletOutsideStore()
        // return walletOutsideStore.dexTargetChainList
        let walletOutsideStore = useWalletOutsideStore()
        var map = {}
        var list = [{text: '请选择', value: ''}]
        for (var n in walletOutsideStore.dexTargetChainList) {
          if (!map[n]) {
            map[n] = true
            list.push({text: n, value: n})
          }
        }
        for (var k in walletOutsideStore.cexTargetChainList) {
          for (var m in walletOutsideStore.cexTargetChainList[k]) {
            for (var h in walletOutsideStore.cexTargetChainList[k][m]) {
              var v = walletOutsideStore.cexTargetChainList[k][m][h]
              if (!map[v]) {
                map[v] = true
                list.push({text: v, value: v})
              }
            }
          }
        }
        return list
      },
    },
    mounted() {
        
    },
    methods: {

        // 各种切换处理
        onAction(item) {
            let that = this
            if (item.id == 'close') {
            console.log('switch change event', item.id)
                this.$emit('update:show', false)
            } else if (item.id == 'append') {
                // 新建
                let params = {
                    userName: UserService.getUserName(),
                    humanReadableName: this.form.name,
                    walletType: "DEX",
                    wallet: this.form.address,
                    dexChain: this.form.chainName,
                }
                if (params.humanReadableName == '') {
                    Message.error('请设置钱包名')
                    return
                }
                if (params.wallet == '') {
                    Message.error('请设置地址')
                    return
                }
                if (params.dexChain == '') {
                    Message.error('请选择链')
                    return
                }
                let loading = TrialphaLoading.loading(that);
                WalletManagement.addCommonUsedWallet(params).then(
                    (res) => {
                        console.log(res)
                        Message.success('添加成功');
                        let walletStore = useWalletStore()
                        walletStore.listCommonUsedWallet()
                        this.$emit('update:show', false)
                        this.$emit('success', 'addCommonUsedWallet')
                        loading.close()
                    },
                    () => {
                        loading.close()
                    }
                );
            }
        },
        
    }
}
</script>

<style scoped>
.create-customer-dialog{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
}
.create-customer-dialog-content{
    background: #22272E;
    width: 500px;
    /* height: 360px; */
    padding: 30px;
    border: 1px solid #3E454E;
    border-radius: 8px;
    position: fixed;
    left: 50%;
    top: 30%;
    margin-left: -250px;
    margin-top: -140px;
}
.create-customer-dialog-content-inner{
    position: relative;
}
</style>
