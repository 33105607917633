<template>
    <div class="create-customer-dialog" v-show="show">

        <div class="create-customer-dialog-content">

            <div class="create-customer-dialog-content-inner">
                <div @click="onAction({id: 'close'})" style="position: absolute; top: -56px; right: -46px; width: 32px; height: 32px; background: #3E454E; color: white; cursor: pointer; border-radius: 16px; font-size: 19px; padding: 3px;">
                    <i class="el-icon-close"></i>
                </div>
            
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="24" style="text-align: left; color: white; font-weight: 100;">
                        {{show}}
                    </el-col>
                </el-row>

                <!-- 步骤控制 -->
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; margin-top: 20px;">
                    <el-col :span="24">
                        
                        <FtGroupButtonWidget :buttons="[{
                            id: 'close',
                            icon: '',
                            text: '关闭',
                            textI18n: '',
                        }]" mode="single" v-on:switch-change="onAction" style="float: none; display: inline-block; margin: auto; background: #3E454E;" />
                    
                    </el-col>
                </el-row>
            </div>

        </div>

    </div>
</template>

<script>
import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";

import { useCrmStore, useCrmOutsideStore } from '@/store/crm'

export default {
    components: {
        FtGroupButtonWidget,
    },
    props: {
        
    },
    data() {
        return {
           
        }
    },
    computed: {
        show: () => {
            let crmOutsideStore = useCrmOutsideStore()
            return crmOutsideStore.remarkText
        },
    },
    mounted() {
        
    },
    methods: {

        // 各种切换处理
        onAction(item) {
            if (item.id == 'close') {
                let crmStore = useCrmStore()
                crmStore.setRemarkText(false)
            }
        },
        
    }
}
</script>

<style scoped>
.create-customer-dialog{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
}
.create-customer-dialog-content{
    background: #22272E;
    width: 500px;
    /* height: 360px; */
    padding: 30px;
    border: 1px solid #3E454E;
    border-radius: 8px;
    position: fixed;
    left: 50%;
    top: 30%;
    margin-left: -250px;
    margin-top: -140px;
}
.create-customer-dialog-content-inner{
    position: relative;
}
</style>