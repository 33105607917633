<template>
  <el-dialog custom-class="black-card" style="color: white" :visible.sync="dialogTableVisible" :before-close="close">
    <el-row>
      <span class="dark-mode-font" style="color: white;font-size: 20px">Create New Strategy</span>
    </el-row>
    <el-row style="margin-top: 25px">
      <el-col :span="8">
        <span class="dark-mode-font" style="display: inline-block;float: right;color: white;margin-top: 5px">Strategy Name</span>
      </el-col>
      <el-col :span="15" :offset="1">
        <input class="trialpha-input" style="width: 90%;float: left;height: 38px" v-model="data.name" />
      </el-col>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-col :span="8" style="padding-top: 3px">
        <span class="dark-mode-font" style="display: inline-block;float: right;color: white">Platforms</span>
      </el-col>
      <el-col :span="15" :offset="1">
        <el-select v-model="data.platformsArray" multiple placeholder="请选择" style="width: 90%;float: left;color: white;" size="small" popper-class="black-card">
          <el-option
              style="color: gray"
              v-for="item in Object.keys(exchangeInfo)"
              :key="item"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-col :span="8" style="padding-top: 3px">
        <span class="dark-mode-font" style="display: inline-block;float: right;color: white">Coins</span>
      </el-col>
      <el-col :span="15" :offset="1">
        <el-select v-model="data.coinsArray" multiple placeholder="请选择" style="width: 90%;float: left;color: white;" size="small" popper-class="black-card">
          <el-option
              style="color: gray"
              v-for="item in ['USD', 'BTC', 'ETH']"
              :key="item"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row style="margin-top: 30px;margin-bottom: 10px">
      <div style="text-align: center;">
        <el-button class="dark-mode-btn" style="" @click="create" :loading="data.loading">CREATE</el-button>
      </div>
    </el-row>
  </el-dialog>
</template>

<script>
import ExchangeInformations from "@/const/ExchangeInformations";
import {Message} from "element-ui";
import StrategyLibsService from "@/api/strategyLibs/StrategyLibsService";

export default {
  name: "CreateStrategyLibDialog",
  data: function () {
    return {
      dialogTableVisible: false,
      coinLogo: {
        USD: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/coins/USD.png',
        BTC: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/coins/BTC.png',
        ETH: 'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/coins/ETH.png'
      },
      exchangeInfo: {
        BINANCE: {
          logo: ExchangeInformations.exchangeInfos.BINANCE.icon,
          fontColor: '#f5bc2f'
        },
        // BITMEX: {
        //   logo: ExchangeInformations.exchangeInfos.BITMEX.icon,
        //   fontColor: '#5b69b1'
        // },
        DERIBIT: {
          logo: ExchangeInformations.exchangeInfos.DERIBIT.icon,
          fontColor: '#04cfbe'
        },
        DYDX: {
          logo: ExchangeInformations.exchangeInfos.DYDX.icon,
          fontColor: '#67ccdf'
        },
        OKX: {
          logo: ExchangeInformations.exchangeInfos.OKX.icon,
          fontColor: '#67ccdf'
        },
        BYBIT: {
          logo: ExchangeInformations.exchangeInfos.BYBIT.icon,
          fontColor: '#67ccdf'
        },
        MEXC: {
          logo: ExchangeInformations.exchangeInfos.MEXC.icon,
          fontColor: '#67ccdf'
        }
      },
      data: {
        name: '',
        platformsArray: [],
        coinsArray: [],
        loading: false
      }
    }
  },
  methods: {
    render: function () {
      this.dialogTableVisible = true;
    },
    close: function () {
      this.dialogTableVisible = false;
    },
    create: function () {
      if (this.data.name && this.data.platformsArray.length > 0 && this.data.coinsArray.length > 0) {
        this.data.loading = true;
        let that = this;
        StrategyLibsService.strategyLibCreate(this.data.name, this.data.platformsArray, this.data.coinsArray, function () {
          that.data.loading = false;
          Message.success('Create new strategy success');
          that.close();
          that.$emit("afterCreate");
        }, function () {
          that.data.loading = false;
          Message.error('Please try again');
        });
      } else {
        Message.warning('All fields are required');
      }
    }
  }
}
</script>

<style scoped>

</style>