<template>
  <div style="width: 100%;height: 100%;overflow-y: auto;padding: 5px">
    <el-row style="margin-top: 20px">
      <el-card class="black-card" style="height: 348px">
        <el-row style="margin-top: -5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">UID</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy" style="display: inline-block; float: left;color: white;font-size: 12px">{{ accountInfo.uid }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">账户模式</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy" style="display: inline-block; float: left;color: white;font-size: 12px">{{ accountModel[accountInfo.acctLv] }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">真实等级</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy" style="display: inline-block; float: left;color: white;font-size: 12px">{{ accountInfo.level }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">临时等级</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy" style="display: inline-block; float: left;color: white;font-size: 12px">{{ accountInfo.levelTmp === '' ? accountInfo.level : accountInfo.levelTmp }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">持仓方式</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy" style="display: inline-block; float: left;color: white;font-size: 12px">{{ accountInfo.posMode === 'net_mode' ? '单向持仓' : '双向持仓' }} <i class="el-icon-sort" v-auth:strategyPanel style="font-size: 12px;transform:rotate(90deg);color: white;cursor:pointer;margin-left: 5px" @click="changePositionMode(accountInfo.posMode)"></i></span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">借币模式</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy" style="display: inline-block; float: left;color: white;font-size: 12px">{{ accountInfo.autoLoan ? '自动' : '需手动' }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">衍生品逐仓模式</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy" style="display: inline-block; float: left;color: white;font-size: 12px">{{ accountInfo.ctIsoMode === 'automatic' ? '开仓划转' : '自主划转' }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">币币杠杆逐仓模式</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy" style="display: inline-block; float: left;color: white;font-size: 12px">{{ accountInfo.mgnIsoMode === 'automatic' ? '开仓划转' : '自主划转' }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">有效保证金</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy" style="display: inline-block; float: left;color: white;font-size: 12px">${{ Number(accountRisk.adjEq).toFixed(2) }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">仓位美元价值</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy" style="display: inline-block; float: left;color: white;font-size: 12px">${{ Number(accountRisk.notionalUsd).toFixed(2) }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">占用保证金</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy" style="display: inline-block; float: left;color: white;font-size: 12px">${{ Number(accountRisk.imr).toFixed(2) }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">维持保证金</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy" style="display: inline-block; float: left;color: white;font-size: 12px">${{ Number(accountRisk.mmr).toFixed(2) }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">总杠杆</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy" style="display: inline-block; float: left;color: white;font-size: 12px">{{ (accountRisk.notionalUsd / accountRisk.adjEq).toFixed(2) }}X</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">保证金率</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy" style="display: inline-block; float: left;color: white;font-size: 12px">{{ ((accountRisk.adjEq / accountRisk.mmr) * 98.6).toFixed(2) }}%</span>
          </el-col>
        </el-row>
      </el-card>
    </el-row>
    <el-row style="margin-top: 15px">
      <general-order-panel v-if="active" ref="order-panel-okx" v-auth:quickPlaceOrder></general-order-panel>
    </el-row>

    <el-collapse class="trialpha-collapse" style="border: 0;margin-top: 15px">
      <el-collapse-item class="trialpha-collapse">
        <template slot="title">
          <i class="el-icon-s-finance" style="margin-left: 2px;color: #0ecb81;margin-top: 1px;border: 0"/><h4
            class="dark-mode-font" style="margin-left: 10px;padding-top: 6px;color: white">Asset balance</h4>
        </template>
        <el-row style="margin-top: 15px">
          <el-card class="black-card" body-style="padding: 0" style="width: 100%;border-radius: 5px 5px 0 0;border-bottom: 0;height: 25px;line-height: 25px;margin-top: 5px;background-color: #454c56">
            <el-row>
              <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy" style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 8px;color: #FFFFFF">Symbol</span></el-col>
              <el-col :span="7"><span class="dark-mode-font ellipsis-line-strategy" style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 15px;color: #FFFFFF">Balance</span></el-col>
              <el-col :span="6"><span class="dark-mode-font ellipsis-line-strategy" style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 15px;color: white">USD</span></el-col>
            </el-row>
          </el-card>
          <el-card class="black-card" body-style="padding: 0" style="width: 100%;height: 197px;overflow-y: auto;border-radius: 0">
            <el-skeleton animated :loading="renderingSpot" :throttle="200">
              <template slot="template">
                <trialpha-sk :style="'margin-top: 25px;width: 60%;float: left'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left'"></trialpha-sk>
              </template>
              <template>
                <el-card class="black-card-hover" body-style="padding: 0" v-for="(item, index) in Object.keys(assetBalance)" :key="index" style="border-radius: 0;border-bottom: 1px solid #444c56;border-top: 0;height: 28px;line-height: 28px;border-left: 0;border-right: 0">
                  <el-row>
                    <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy" style="color: white;text-align: left;font-size: 12px;display: inline-block;float: left;margin-left: 8px">{{ item }}</span></el-col>
                    <el-col :span="7"><span class="dark-mode-font ellipsis-line-strategy" style="color: white;text-align: left;font-size: 12px;display: inline-block;float: left;margin-left: 15px">{{ assetBalance[item].availBal }}</span></el-col>
                    <el-col :span="6"><span class="dark-mode-font ellipsis-line-strategy" style="color: white;text-align: left;font-size: 12px;display: inline-block;float: left;margin-left: 15px">{{
                        ((allPrice[item + '-USDT'] ? allPrice[item + '-USDT'] : (item === 'USDT' ? 1 : 0)) * assetBalance[item].availBal).toFixed(2)
                      }}</span></el-col>
                  </el-row>
                </el-card>
              </template>
            </el-skeleton>
          </el-card>
        </el-row>
      </el-collapse-item>
    </el-collapse>
    <general-position-panel ref="position-panel" style="margin-top: 15px"/>
    <el-button class="dark-mode-btn" style="margin-top: 15px;width: 100%" :loading="renderingSpot || loadingOrder" @click="renderData">Reload</el-button>
  </div>
</template>

<script>
import TradeApiService from "@/api/trade/TradeApiService";
import TimeService from "@/utils/TimeService";
import TrialphaSk from "@/components/TrialphaSk";
import {Message} from "element-ui";
import GeneralOrderPanel from "@/components/account/GeneraalOrderPanel";
import GeneralPositionPanel from "@/components/account/GeneralPositionPanel";
import GeneralApiService from "@/api/trade/GeneralApiService";

export default {
  name: "OkxAccountInfo",
  components: {GeneralPositionPanel, GeneralOrderPanel, TrialphaSk},
  data: function () {
    return {
      accountModel: {
        1: '简单交易模式',
        2: '单币种保证金模式',
        3: '跨币种保证金模式',
        4: '组合保证金模式'
      },
      platform: 'OKX',
      instance: {},
      tradeAccountIns: {},
      position: {},
      allSpot: {},
      moreThanZeroSpot: {},
      showAllPosition: false,
      renderingSpot: true,
      renderingPosition: true,
      renderingHistory: true,
      historyOrderToEnd: false,
      loadingOrder: false,
      allPrice: {},
      totalSpotUsd: 0,
      totalPnl: 0,
      totalSize: 0,
      history: [],
      startTime: 0,
      endTime: 0,
      historyAmount: {},
      historyCostUsd: {},
      analysis: [],
      accountInfo: {},
      accountRisk: {},
      active: false,
      assetBalance: {}
    }
  },
  methods: {
    getTimeString: function (time) {
      return TimeService.timestampToDate(time);
    },
    changeAllSymbol: function () {
      this.showAllPosition = !this.showAllPosition;
    },
    render: function (ins, tradeAccountIns) {
      this.active = true;
      this.instance = ins;
      this.tradeAccountIns = tradeAccountIns;
      this.renderData();
    },
    uninstall: function () {
      this.active = false;
    },
    renderData: function () {
      let that = this;
      this.startTime = 0;
      this.history = [];
      this.analysis = [];
      this.position = {};
      this.accountInfo = {};
      this.historyOrderToEnd = false;
      this.loadingOrder = false;
      that.renderingSpot = true;
      // that.renderingPosition = true;
      that.$nextTick(function () {
        that.$refs['order-panel-okx'].render(that.tradeAccountIns, that.renderData);
      });
      this.$refs['position-panel'].render(GeneralApiService.PLATFORM.OKX, GeneralApiService.SYMBOL_TYPE.ALL, this.tradeAccountIns.apiKey);
      TradeApiService.getAccountInfo(that.platform, this.tradeAccountIns.apiKey, 'ALL', function (data) {
        that.accountInfo = data[0];
      });
      TradeApiService.getAccountRisk(that.platform, this.tradeAccountIns.apiKey, function (data) {
        that.accountRisk = data[0];
      });
      TradeApiService.getAllPosition(that.platform, this.tradeAccountIns.apiKey, function (data) {
        that.initPosition(data);
      })
      TradeApiService.getAllPrice(that.platform, 'SPOT', function (spot) {
        TradeApiService.getOkxAssetBalance(that.platform, that.tradeAccountIns.apiKey, '', function (data) {
          if (data && data.symbolAssetBalances) {
            that.assetBalance = data.symbolAssetBalances;
          }
        });
        TradeApiService.getAllSpot(that.platform, that.tradeAccountIns.apiKey, function (data) {
          that.allSpot = data;
          let tmp = {};
          let totalU = 0;
          for (let spotKey in that.allSpot) {
            if (that.allSpot[spotKey] !== 0) {
              tmp[spotKey] = that.allSpot[spotKey];
              let price = that.allPrice[spotKey + '-USDT'] ? that.allPrice[spotKey + '-USDT'] : (spotKey === 'USDT' ? 1 : 0);
              totalU += price * that.allSpot[spotKey];
            }
          }
          that.totalSpotUsd = totalU.toFixed(2);
          that.moreThanZeroSpot = tmp;
          that.renderingSpot = false;
        });
        TradeApiService.getAllPrice(that.platform, 'SWAP', function (swap) {
          TradeApiService.getAllPrice(that.platform, 'FUTURES', function (future) {
            let tmpMap = Object.assign(spot,swap);
            that.allPrice = Object.assign(tmpMap,future)
          });
        });
      });
    },
    initPosition: function (data) {
      let tmp = {};
      let pnlT = 0;
      let totalUSize = 0;
      let that = this;
      for (let dataKey in data) {
        if (Number(data[dataKey].pos) !== 0) {
          tmp[dataKey] = data[dataKey];
          pnlT += Number(data[dataKey].upl);
          totalUSize += Number(data[dataKey].notionalUsd);
        }
      }
      that.totalSize = totalUSize.toFixed(2);
      that.totalPnl = pnlT.toFixed(2);
      that.position = tmp;
      that.renderingPosition = false;
    },
    changePositionMode: function (mode) {
      let that = this;
      TradeApiService.setPositionMode('OKX', this.tradeAccountIns.apiKey, mode === 'net_mode' ? 'long_short_mode' : 'net_mode', function () {
        Message.success('Change position mode to ' + (mode === 'net_mode' ? 'long_short_mode' : 'net_mode') + ' success');
        that.renderData();
      });
    }
  }
}
</script>

<style scoped>

.ellipsis-line-strategy {
  padding: 0;
  width: 90%;
  float: left;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.small-font {
  width: 90%;
  font-size: 12px;
  -webkit-transform: scale(1);
  text-align: right;
  display: inline-block;
  float: right;
  margin-right: 5px;
  color: #FFFFFF
}

@media only screen and  (max-width: 768px) {
  .small-font {
    width: 120%;
    font-size: 12px;
    -webkit-transform: scale(0.75);
    text-align: right;
    display: inline-block;
    float: right;
    margin-right: -2px;
    color: #FFFFFF
  }
}

.el-collapse {
  color: #FFFFFF;
  border-top: 1px solid #444c56;
  border-bottom: 1px solid #444c56;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: white;
}

.el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

</style>