<template>
  <div class="trialpha-stander-sub-page">
    <div class="trialpha-stander-sub-page" style="padding: 0 0 100px 0">
      <div style="margin-top: 68px;">

        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static">
          <el-col :span="20" style="display: flex">
            <!-- 个人、渠道 切换 -->
            <FtGroupButtonWidget :buttons.sync="panelSwitchButtons" v-on:switch-change="eventSwitchChange" />
            <!-- 个人、渠道 新建 -->
            <FtGroupButtonWidget :buttons="[{
                id: 'append',
                icon: 'el-icon-plus',
                text: '新建',
                textI18n: '',
            }]" mode="single" v-on:switch-change="eventSwitchChange" style="margin-left: 16px;" />
            <!-- 个人、渠道 新建 -->
            <FtGroupButtonWidget :buttons="[{
                id: 'refresh',
                icon: 'el-icon-refresh',
                text: '刷新',
                textI18n: '',
            }]" mode="single" v-on:switch-change="eventSwitchChange" style="margin-left: 16px;" />
          </el-col>
        </el-row>

        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static">
          <el-col :span="13">
            <!-- 状态条件 -->
            <FtTabsWidget :tabs="panelSwitchTabs" v-on:switch-change="eventSwitchChange" v-on:like-search="eventLikeSearch" />
          </el-col>
          <el-col :span="7" v-if="detailPanel == 'personal'">
            
            <FtGroupButtonWidget :buttons="[{
                id: 'dup-account-create',
                text: '添加子账号',
                textI18n: '',
            }]" mode="single" v-on:switch-change="eventSwitchChange" style="margin-left: 60px;" />

            <template v-if="currentLinkAccountListCount > 1">
              <FtGroupButtonWidget  :buttons="[{
                  id: 'dup-account-prev',
                  text: '前一个',
                  textI18n: '',
              }, {
                  id: 'dup-account-next',
                  text: '后一个',
                  textI18n: '',
              }]" mode="single" v-on:switch-change="eventSwitchChange" style="margin-left: 30px;" />

              <div style="line-height: 30px; float: left; margin-left: 30px; color: white;">
                {{showAccountIndex}} / {{currentLinkAccountListCount}}
              </div>

            </template>
            
          </el-col>
          <el-col :span="detailPanel == 'personal' ? 4 : 11">
            <!-- 数据导出 -->
            <FtGroupButtonWidget :buttons="[{
                id: 'data-export',
                icon: 'el-icon-upload2',
                text: '数据导出',
                textI18n: '',
            }]" mode="single" v-on:switch-change="eventSwitchChange" style="float: right;" />
          </el-col>
        </el-row>

        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
          <el-col :span="13">

            <!-- 列表 -->
            <FtCrmPersonalList v-show="currentPanel == 'personal'" ref="personalList" 
              v-on:checked-change="onCheckedChange"
              v-on:checked-refresh="onCheckedRefresh" />

            <FtCrmChannelList v-show="currentPanel == 'channel'" ref="channelList" 
              v-on:checked-change="onCheckedChange"
              v-on:checked-refresh="onCheckedRefresh"/>

          </el-col>
          <el-col :span="11">

            <div style="border-left: 1px solid #3E454E; margin-left: 30px; padding-left: 30px;">
              <FtCrmPersonalDetail v-show="detailPanel == 'personal'" ref="personalDetail" v-on:changed="detailChanged" />

              <FtCrmChannelDetail v-show="detailPanel == 'channel'" ref="channelDetail" v-on:changed="detailChanged" />
            </div>

          </el-col>
        </el-row>
        
      </div>
    </div>
    <FtCrmCreateCustomerDialog v-if="dialogAppendShow" :show.sync="dialogAppendShow" :mode="currentPanel" 
      v-on:success="detailChanged" />

    <FtCrmRemarkDialog />
    
  </div>
</template>

<script>
// import {Message} from "element-ui";
import ConfirmProvider from '@/components/ConfirmProvider'
import UserPermissionService from "@/api/user/UserPermissionService";

import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";
import FtTabsWidget from "@/components/widgets/FtTabsWidget";
import FtCrmPersonalList from "@/components/FtCrmPersonalList";
import FtCrmPersonalDetail from "@/components/FtCrmPersonalDetail";
import FtCrmChannelList from "@/components/FtCrmChannelList";
import FtCrmChannelDetail from "@/components/FtCrmChannelDetail";
import FtCrmCreateCustomerDialog from "@/components/FtCrmCreateCustomerDialog";
import FtCrmRemarkDialog from "@/components/FtCrmRemarkDialog";
import TrialphaLoading from "@/components/TrialphaLoading";

import { useCrmStore, useCrmOutsideStore } from '@/store/crm'
import CrmChannelManagement from "@/api/crm/CrmChannelManagement";

export default {
  name: "ChannelMgnt",
  components: {
    FtGroupButtonWidget,
    FtTabsWidget,
    FtCrmPersonalList,
    FtCrmPersonalDetail,
    FtCrmChannelList,
    FtCrmChannelDetail,
    FtCrmCreateCustomerDialog,
    FtCrmRemarkDialog
  },
  data: function () {
    return {
      currentPanel: 'personal',
      detailPanel: '',

      dialogAppendShow: false,

      // 进度状态
      progress: '',
      // 模糊查询
      search: '',

      // 面板切换: 个人/渠道
      panelSwitchButtons: [
          {
              id: 'personal',
              text: '个人',
              textI18n: '',
              active: true
          },
          {
              id: 'channel',
              text: '渠道',
              textI18n: '',
              active: false
          }
      ],

      // 状态面板切换: 
      panelSwitchTabs: [
          {
              id: 'StatusAll',
              text: '全部',
              textI18n: '',
              count: 0,
              active: true
          },
          {
              id: 'StatusInContact',
              text: '接触中',
              textI18n: '',
              count: 0,
              active: false
          },
          {
              id: 'StatusInConnect',
              text: '沟通中',
              textI18n: '',
              count: 0,
              active: false
          },
          {
              id: 'StatusInReach',
              text: '达成合作',
              textI18n: '',
              count: 0,
              active: false
          },
          {
              id: 'StatusFailConnect',
              text: '沟通失败',
              textI18n: '',
              count: 0,
              active: false
          }
      ],

      // 渠道列表
      channels: [],

      personalDetail: null,
      channelDetail: null,

    }
  },
  computed: {
      showAccountIndex: function() {
          var crmOutsideStore = useCrmOutsideStore()
          return crmOutsideStore.showAccountIndex + 1
      },
      currentLinkAccountListCount: function() {
          var crmOutsideStore = useCrmOutsideStore()
          return crmOutsideStore.currentLinkAccountList.length
      }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.refreshWidth();
      })();
    };

    this.getCustomerSizeByProgress()
  },
  created() {
    
  },
  destroyed: function () {
    
  },
  methods: {
    hasPermission(featureName) {
      return UserPermissionService.isAllowed(featureName);
    },
    refreshWidth: function () {
      window.fullWidth = document.documentElement.clientWidth;
      this.windowWidth = window.fullWidth;
      if (this.windowWidth > 1000) {
        this.configWidth = '60%';
        this.positionWidth = '40%'
      } else {
        this.configWidth = '100%';
        this.positionWidth = '100%'
      }
    },

    // 各种切换处理
    eventSwitchChange(item) {
      var that = this
      console.log('switch change event', item.id)
      var crmStore = useCrmStore()
      if (item.id == 'personal') {
        this.currentPanel = item.id
        this.detailPanel = ''
        this.$refs.personalList.setWhile('progress', this.progress)
      } else if (item.id == 'channel') {
        this.currentPanel = item.id
        this.detailPanel = ''
        this.$refs.channelList.setWhile('progress', this.progress)
      } else if (item.id == 'append') {
        // 新建
        this.dialogAppendShow = true
      } else if (item.id == 'refresh') {
        // 刷新
        let loading = TrialphaLoading.loading(that);
        if (this.currentPanel == 'personal') {
          this.$refs.personalList.setRefresh().then(() => {
            setTimeout(() => {
              loading.close()
            }, 1000)
          })
        }
        if (this.currentPanel == 'channel') {
          this.$refs.channelList.setRefresh().then(() => {
            setTimeout(() => {
              loading.close()
            }, 1000)
          })
        }
        
      } else if (item.id == 'data-export') {
        // 
      } else if (['StatusAll',
                  'StatusInContact',
                  'StatusInConnect',
                  'StatusInReach',
                  'StatusFailConnect'].indexOf(item.id) > -1) {
        let progress = {
          StatusAll: '',
          StatusInContact: '接触中',
          StatusInConnect: '沟通中',
          StatusInReach: '达成合作',
          StatusFailConnect: '沟通失败'
        }
        this.progress = progress[item.id]
        if (this.currentPanel == 'personal') {
          this.$refs.personalList.setWhile('progress', this.progress)
        }
        if (this.currentPanel == 'channel') {
          this.$refs.channelList.setWhile('progress', this.progress)
        }
      } else if (item.id == 'dup-account-prev') {
        crmStore.setCurrentPrevAccount()
      } else if (item.id == 'dup-account-next') {
        crmStore.setCurrentNextAccount()
      } else if (item.id == 'dup-account-create') {
        ConfirmProvider.openConfirm(this, '确认操作', '确认添加子账号？', () => {
          crmStore.createCurrentSubAccount()
        });
      }
    },

    // 统计数量
    getCustomerSizeByProgress() {
        let total = 0
        CrmChannelManagement.getCustomerSizeByProgress('接触中',
            (data) => {
                console.log(data)
                total += data
                this.panelSwitchTabs[1].count = data
                CrmChannelManagement.getCustomerSizeByProgress('沟通中',
                    (data) => {
                        console.log(data)
                        total += data
                        this.panelSwitchTabs[2].count = data
                        CrmChannelManagement.getCustomerSizeByProgress('达成合作',
                            (data) => {
                                console.log(data)
                                total += data
                                this.panelSwitchTabs[3].count = data
                                CrmChannelManagement.getCustomerSizeByProgress('沟通失败',
                                    (data) => {
                                        console.log(data)
                                        total += data
                                        this.panelSwitchTabs[4].count = data
                                        this.panelSwitchTabs[0].count = total
                                    },
                                    (err) => {
                                        // Message.error(this.$i("operationFail"));
                                        console.log(err)
                                    }
                                );
                            },
                            (err) => {
                                // Message.error(this.$i("operationFail"));
                                console.log(err)
                            }
                        );
                    },
                    (err) => {
                        // Message.error(this.$i("operationFail"));
                        console.log(err)
                    }
                );
            },
            (err) => {
                // Message.error(this.$i("operationFail"));
                console.log(err)
            }
        );
    },

    // 模糊查询处理
    eventLikeSearch(keyword) {
      console.log('like search event', keyword)
      this.search = keyword
      if (keyword) {
        if (this.currentPanel == 'personal') {
          this.$refs.personalList.setWhile('search', keyword)
        }
        if (this.currentPanel == 'channel') {
          this.$refs.channelList.setWhile('search', keyword)
        }
      } else {
        if (this.currentPanel == 'personal') {
          this.$refs.personalList.setWhile('progress', this.progress)
        }
        if (this.currentPanel == 'channel') {
          this.$refs.channelList.setWhile('progress', this.progress)
        }
      }
    },

    // 列表选中事件
    onCheckedChange(type, item) {
        console.log('>>>', type, item)
        this.detailPanel = type
        if (type == 'personal') {
          this.personalDetail = item
          this.$refs.personalDetail.setDetail(item)
        } else if (type == 'channel') {
          this.channelDetail = item
          this.$refs.channelDetail.setDetail(item)
        } else if (type == 'detailclear') {
          this.detailPanel = ''
        }
    },

    onCheckedRefresh(type, item) {
        console.log('>>>', type, item)
        this.detailPanel = type
        if (type == 'personal') {
          this.personalDetail = item
          this.$refs.personalDetail.setRefresh(item)
        } else if (type == 'channel') {
          this.channelDetail = item
          this.$refs.channelDetail.setRefresh(item)
        }
    },

    // 详情界面变更事件
    detailChanged(type) {
        console.log('详情界面变更', type)
        if (type == 'personal') {
          if (this.currentPanel == 'personal') {
            this.$refs.personalList.setRefresh()
          } else if (this.currentPanel == 'channel') {
            this.$refs.channelList.setRefresh()
          }
        } else if (type == 'channel') {
          this.$refs.channelList.setRefresh()
        } else if (type == 'customer') {
          this.$refs.channelList.setRefresh()
        }

        this.getCustomerSizeByProgress()
    }
  }
}
</script>

<style>


</style>