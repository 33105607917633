<template>
  <div class="ft-table-list">
    <!-- list header -->
    <div class="ft-table-list-header">
      <div v-for="(column, jndex) in columns" v-bind:key="jndex"
           :style="{width: column.width, textAlign: column.align, position: 'relative'}">
        <template v-if="column.remove">
          <i v-if="column.icon" :class="column.icon" @click="removeAction" style="cursor: pointer;"></i>
          <text @click="removeAction">{{column.textI18n ? $i(column.textI18n) : column.text}}</text>

          <div @click="removeActionTap" class="ft-remove not-select" :style="{display: (removeActionShow ? 'block' : '')}">
            移除
          </div>
        </template>
        <template v-else>
          <i v-if="column.icon" :class="column.icon"></i>
          {{column.textI18n ? $i(column.textI18n) : column.text}}
        </template>
      </div>
    </div>

    <!-- list content -->
    <div :class="[(scrollHeight > 0 ? 'scroll-mode' : '')]" :style="(scrollHeight > 0 ? {height: scrollHeight + 'px'} : {})">

      <div class="ft-table-list-item" v-for="(item, index) in data" v-bind:key="index" :class="[item.id === itemActive ? 'active' : '']" @click="eventTap(item)">
        <div class="ellipsis-line-crm" v-for="(column, jndex) in columns" v-bind:key="jndex" :style="{width: column.width, textAlign: column.align}">
          
          <template v-if="column.checkbox">
            <div @click.stop="checkCell(item)" class="ft-checkbox" :class="[item.id === itemActive ? 'active' : '']">
              <div></div>
            </div>
          </template>
          <template v-else-if="column.render">
            <div>
              <div style="display: inline-block;" @click.stop="actionCell(column.name, item)" v-html="column.render(column.name, item)"></div>
            </div>
          </template>
          <template v-else>
            <span style="display: inline-block;" @click.stop="actionCell(column.name, item)">{{item[column.name]}}</span>
          </template>

          <!-- <template v-if="column.checkbox">
            <div @click.stop="checkCell(item)" class="ft-checkbox" :class="[item.id === itemActive ? 'active' : '']">
              <div></div>
            </div>
          </template>
          <template v-else-if="column.render">
            <div>
              <div style="display: inline-block;" @click.stop="actionCell(column.name, item)" v-html="column.render(column.name, item)"></div>
            </div>
          </template>
          <template v-else>
            <span style="display: inline-block;" @click.stop="actionCell(column.name, item)">{{item[column.name]}}</span>
          </template> -->
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {
    columns: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [
        // {textI18n: '', text: '#', align: 'left', width: '8%', name: 'id', checkbox: true},
        // {textI18n: '', text: '姓名', align: 'left', width: '15%', name: 'name'},
        // {textI18n: '', text: '资金', align: 'left', width: '22%', name: 'text1'},
        // {textI18n: '', text: '资金利用率', align: 'left', width: '12%', name: 'text2'},
        // {textI18n: '', text: '截止时间', align: 'left', width: '10%', name: 'text3'},
        // {textI18n: '', text: '进度', align: 'left', width: '10%', name: 'contactStatus', render: (name, rowScope) => {
        //     var value = rowScope[name]
        //     // 接触中
        //     if (value == '接触中') {
        //         return '<span style="color: #FFB324; background: rgba(255, 179, 36, 0.1); padding: 3px 10px; border-radius: 8px; font-sie: 12px;">'
        //             + value
        //             + '</span>'
        //     }
        //     // 沟通中
        //     if (value == '沟通中') {
        //         return '<span style="color: #EEFF24; background: rgba(238, 255, 36, 0.1); padding: 3px 10px; border-radius: 8px; font-sie: 12px;">'
        //             + value
        //             + '</span>'
        //     }
        //     // 开始合作
        //     if (value == '开始合作') {
        //         return '<span style="color: #00C683; background: rgba(0, 198, 131, 0.1); padding: 3px 10px; border-radius: 8px; font-sie: 12px;">'
        //             + value
        //             + '</span>'
        //     }
        //     return ''
        // }},
        // {textI18n: '', text: '状态', align: 'left', width: '10%', name: 'runningStatus', render: (name, rowScope) => {
        //     var value = rowScope[name]
        //     return '<span style="background: #3E454E; padding: 3px 10px; border-radius: 8px; font-sie: 12px;">'
        //         + value
        //         + '</span>'
        // }},
        // {textI18n: '', text: '渠道归属', align: 'left', width: '10%', name: 'text6'},
        // {textI18n: '', text: '', icon: 'el-icon-more', align: 'right', width: '5%', remove: true},
      ]
    },
    data: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [
        // {
        //     id: 1,
        //     name: '日不落秦',
        //     text1: '100,000,000  USDT',
        //     text2: '100%',
        //     text3: '2022.09.30',
        //     contactStatus: '接触中',
        //     runningStatus: '运行中',
        //     text6: '无'
        // },
        // {
        //     id: 2,
        //     name: '日不落秦',
        //     text1: '100,000,000  USDT',
        //     text2: '100%',
        //     text3: '2022.09.30',
        //     contactStatus: '沟通中',
        //     runningStatus: '运行中',
        //     text6: '无'
        // },
        // {
        //     id: 3,
        //     name: '日不落秦',
        //     text1: '100,000,000  USDT',
        //     text2: '100%',
        //     text3: '2022.09.30',
        //     contactStatus: '开始合作',
        //     runningStatus: '运行中',
        //     text6: '无'
        // }
      ]
    },
    scrollHeight: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      itemActive: 0,
      removeActionShow: false
    }
  },
  methods: {
    checkCell(item) {
      if (this.itemActive == item.id) {
        this.itemActive = 0
      } else {
        this.eventTap(item)
      }
    },
    eventTap(item) {
      this.itemActive = item.id
      this.$emit('checked-change', item);
    },
    actionCell(name, item) {
      this.itemActive = item.id
      this.$emit('action-cell', name, item);
    },
    removeAction() {
      this.removeActionShow = !this.removeActionShow
    },
    removeActionTap() {
      this.removeActionShow = false
      if (this.itemActive) {
        let item = this.data.filter(m => {
          return m.id == this.itemActive
        })[0]
        this.$emit('checked-remove', item);
      }
    }
  }
}
</script>

<style scoped>
.ft-table-list {

}
.ft-table-list .ft-table-list-header {
  display: flex; width: 100%; padding: 4px 24px; line-height: 24px; text-align: left; border: 1px solid #3E454E; color: #999999; border-radius: 8px; font-size: 12px; margin-bottom: 16px;
  background: #22272E;
}

.ft-table-list .scroll-mode{
  overflow-y: auto;
}

.ft-table-list .ft-table-list-item {
  cursor: pointer;
  display: flex; width: 100%; padding: 12px 25px; line-height: 34px; text-align: left; border: 1px solid #3E454E; color: #ffffff; border-radius: 8px; font-size: 14px; margin-bottom: 8px;
  background: #22272E;
}

.ft-table-list .ft-table-list-item .ft-checkbox{
  width: 20px; height: 20px; border-radius: 4px; border: 1px solid #3E454E; margin-top: 7px;
  cursor: pointer;
}

.ft-table-list .ft-table-list-item .ft-checkbox > div{
  width: 12px; height: 12px; background: transparent; border-radius: 2px; margin: 3px;
}

.ft-table-list .ft-table-list-item .ft-checkbox.active > div{
  background: #fff;
}

.ft-table-list .ft-table-list-item.active {
  background-color: #323841;
  border: #444c56 1px solid;
}

.ft-table-list .ft-table-list-item:hover {
  background-color: #323841;
  border: #444c56 1px solid;
}

.ft-remove{
  display: none;
  position: absolute; right: -25px; top: 30px; width: 100px; background: #22272E; color: #fff; border: 1px solid #3E454E; border-radius: 8px; text-align: center; cursor: pointer; height: 40px; line-height: 40px;
  font-size: 14px;
}
.ft-remove:hover{
  background: #3E454E;
}
.not-select{
  -moz-user-select:none; /*火狐*/
  -webkit-user-select:none; /*webkit浏览器*/
  -ms-user-select:none; /*IE10*/
  -khtml-user-select:none; /*早期浏览器*/
  user-select:none;
}

.ellipsis-line-crm {
  padding: 0;
  text-align: left;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}
</style>