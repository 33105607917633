<template>
  <div>
    <el-drawer
        :before-close="() => {$emit('updateAssetInfo');}"
        class="open-task-trade-tools"
        size="180px"
        :modal="true"
        append-to-body
        :close-on-click-modal=false
        :close-on-press-escape=false
        :wrapperClosable=false
        custom-class="black-card"
        :visible.sync="drawer"
        :with-header="false"
        direction="btt">
      <el-button class="dark-mode-btn" style="width: 100%;height: 30px;margin-top: 0;border-right: 0;border-top: 0;border-left: 0;border-radius: 0;font-size: 23px;padding: 0" @click="close" :loading="loading"><i class="el-icon-close" /></el-button>
      <el-row style="width: 98%;margin: 20px auto 0 auto">
        <el-col :span="10" :offset="1">
          <input class="trialpha-input" :value="tokenName + ': ' + tokenAddress" disabled style="height: 35px" />
        </el-col>
        <el-col :span="10" :offset="2">
          <input class="trialpha-input" v-model="to" placeholder="Target address..." style="height: 35px" />
        </el-col>
      </el-row>
      <el-row style="width: 98%;margin: 20px auto 0 auto">
        <el-col :span="3" :offset="1">
          <input class="trialpha-input" v-model="tokenAmount" placeholder="Send amount..." style="border-radius: 3px 0 0 3px;border-right: 0;height: 35px" />
        </el-col>
        <el-col :span="7">
          <input class="trialpha-input" v-model="remark" placeholder="Transaction remark..." style="border-radius: 0 3px 3px 0;height: 35px" />
        </el-col>
        <el-col :span="10" :offset="2">
          <el-button class="dark-mode-btn" style="width: 100%;color: #FFFFFF;padding: 10px" @click="submit" :loading="loading" v-auth:dbWriteApi>Submit</el-button>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>

<script>
import {Message} from "element-ui";
import AbmWalletContract from "@/web3/contract/AbmWalletContract";
import Web3Service from "@/web3/Web3Service";

export default {
  name: "RequestWithdraw",
  data: function () {
    return {
      drawer: false,
      tokenName: '',
      tokenAddress: '',
      tokenAmount: '',
      to: '',
      remark: '',
      loading: false
    }
  },
  methods: {
    render: function (name, address) {
      this.drawer = true;
      this.tokenName = name;
      this.tokenAddress = address;
    },
    close: function () {
      this.drawer = false;
    },
    submit: function () {
      let that = this;
      if (!this.tokenAddress || !this.remark || !this.to || !this.tokenAmount || !this.tokenName) {
        Message.error('Required parameter missing...')
        return;
      }
      let web3 = Web3Service.getWeb3(this);
      let abmWalletContract = AbmWalletContract.getContract(this);
      this.loading = true;
      abmWalletContract.methods.requestTransfer(this.tokenAddress, this.to, web3.utils.toWei(this.tokenAmount + ''), this.remark).send({from: web3.currentProvider.selectedAddress}).on("receipt", function(receipt) {
        Message.success('Request withdraw success, transaction hash is ' + receipt.transactionHash);
        that.loading = false;
        that.$emit('updateAssetInfo');
      }).on("error", function(error) {
        Message.error(error.message);
        that.loading = false;
      });
    }
  }
}
</script>

<style scoped>


</style>