<script>

function loading(that) {
  return that.$loading({
    lock: true,
    text: 'Loading...',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.568)',
    customClass: 'trialpha-mask'
  });
}

export default {
  loading
}
</script>

<style>
.trialpha-mask {
  z-index: 999999999999;
}
</style>