<template>
  <div :exchangesCountMap="exchangesCountMap" style="position:relative">
    <el-card class="black-card" style="width: 100%;height: 268px;overflow-x: auto;display: flex;position: absolute;border: 0;background-color: rgba(0, 0 ,0 ,0)" body-style="padding: 0">
      <el-skeleton animated :loading="loadingInfo" :throttle="268">
        <template slot="template">
          <div :style="'width: ' + ((10 * 368) + 2) + 'px'">
            <el-card class="black-card" style="height: 100%;float: left;width: 368px;border-radius: 0" body-style="padding: 10px" v-for="i in 10" :key="i">
              <trialpha-sk :style="'margin-top: 2px;width: 60%;float: left'"></trialpha-sk>
              <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
              <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
              <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
              <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left'"></trialpha-sk>
              <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left'"></trialpha-sk>
              <trialpha-sk :style="'margin-top: 20px;width: 60%;float: left'"></trialpha-sk>
              <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
              <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
              <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
              <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left'"></trialpha-sk>
              <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left'"></trialpha-sk>
            </el-card>
          </div>
        </template>
        <template>
          <div :style="'width: ' + ((exchangesInfo.length * 368) + 2) + 'px'">
            <el-card class="black-card" style="height: 100%;float: left;width: 368px;border-radius: 0" body-style="padding: 10px" v-for="i in exchangesInfo" :key="i.platformName">
              <el-row><a target="_blank" :href="exchanges[i.platformName.toUpperCase()].url" style="text-decoration: none;color: #FFFFFF">
                <img :src="exchanges[i.platformName.toUpperCase()].icon"
                     :style=exchanges[i.platformName.toUpperCase()].iconStyle :alt=i.platformName />
                <span class="dark-mode-font" :style="'float:left;display: inline-block;' + exchanges[i.platformName.toUpperCase()].font">{{ i.platformName }}</span></a>
                <span class="dark-mode-font" style="font-size: 12px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 6px;margin-left: 0;color: white;float: right"><span style="color: #0ecb81">{{ i.ping }}ms</span></span>
              </el-row>
              <el-row style="margin-top: 12px">
                <span class="dark-mode-font" style="float:left;display: inline-block;font-size: 13px;margin-top: 5px;margin-left: 15px;color: #999999;">{{ $i('accountMgnt.tradeStatus') }}</span>
                <span class="dark-mode-font" style="font-size: 12px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 6px;margin-left: 0;color: white;float: right">{{ i.tradeStatus.toUpperCase() }}</span>
              </el-row>
              <el-row style="margin-top: 6px">
                <span class="dark-mode-font" style="float:left;display: inline-block;font-size: 13px;margin-top: 5px;margin-left: 15px;color: #999999;">{{ $i('accountMgnt.manageInstance') }}</span>
                <span class="dark-mode-font" style="font-size: 12px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 6px;margin-left: 0;color: white;float: right">{{ exchangesCountMap[i.platformName.toUpperCase()] }}</span>
              </el-row>
              <el-row style="margin-top: 6px">
                <span class="dark-mode-font" style="float:left;display: inline-block;font-size: 13px;margin-top: 5px;margin-left: 15px;color: #999999;">{{ $i('accountMgnt.news') }}</span>
              </el-row>
              <div style="margin-top: 5px;height: 110px;overflow-y: auto">
                <el-row style="margin-top: 0" v-for="a in i.announcementInfo" :key="a.code">
                  <a class="dark-mode-font ellipsis-line" :title="a.title" style="padding: 5px 2px 5px 5px;float: left;margin-left: 10px;text-align: left;cursor: pointer;color: #999999;font-size: 12px;font-weight: 100"
                     @click="toPage(i.platformName, a.title, a.code)">{{ a.title }}</a>
                </el-row>
              </div>
            </el-card>
          </div>
        </template>
      </el-skeleton>
    </el-card>
    <div style="margin-left: calc(100% - 58px);position:absolute;width: 58px;height: 268px;;background: linear-gradient(270deg, #232c39 0%, rgba(34, 39, 46, 0.1) 100%);opacity: 1;border-radius: 0;"></div>
  </div>
</template>

<script>
import PlatformInfoService from "@/api/platform/PlatformInfoService";
import TrialphaSk from "@/components/TrialphaSk";
import ExchangeInformations from "@/const/ExchangeInformations";

export default {
  name: "ExchangeInfo",
  components: {TrialphaSk},
  props: {
    exchangesCountMap: Object
  },
  data: function () {
    return {
      loadingInfo: true,
      exchanges: {
        BINANCE: {
          url: ExchangeInformations.exchangeInfos.BINANCE.url,
          icon: ExchangeInformations.exchangeInfos.BINANCE.icon,
          iconStyle: 'width: 18px;height: 18px;margin-top: 5px;float:left',
          font: 'font-size: 13px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 5px;margin-left: 10px;color: white;'
        },
        OKX: {
          url: ExchangeInformations.exchangeInfos.OKX.url,
          icon: ExchangeInformations.exchangeInfos.OKX.icon,
          iconStyle: 'width: 18px;height: 18px;margin-top: 5px;float:left',
          font: 'font-size: 13px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 5px;margin-left: 10px;color: white'
        },
        DERIBIT: {
          url: ExchangeInformations.exchangeInfos.DERIBIT.url,
          icon: ExchangeInformations.exchangeInfos.DERIBIT.icon,
          iconStyle: 'width: 18px;height: 18px;margin-top: 5px;float:left',
          font: 'font-size: 13px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 5px;margin-left: 10px;color: white'
        },
        DYDX: {
          url: ExchangeInformations.exchangeInfos.DYDX.url,
          icon: ExchangeInformations.exchangeInfos.DYDX.icon,
          iconStyle: 'width: 18px;height: 18px;margin-top: 5px;float:left',
          font: 'font-size: 13px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 5px;margin-left: 10px;color: white'
        },
        BYBIT: {
          url: ExchangeInformations.exchangeInfos.BYBIT.url,
          icon: ExchangeInformations.exchangeInfos.BYBIT.icon,
          iconStyle: 'width: 18px;height: 18px;margin-top: 5px;float:left',
          font: 'font-size: 13px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 5px;margin-left: 10px;color: white'
        },
        KUCOIN: {
          url: ExchangeInformations.exchangeInfos.KUCOIN.url,
          icon: ExchangeInformations.exchangeInfos.KUCOIN.icon,
          iconStyle: 'width: 18px;height: 18px;margin-top: 5px;float:left',
          font: 'font-size: 13px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 5px;margin-left: 10px;color: white'
        },
        BITMEX: {
          url: ExchangeInformations.exchangeInfos.BITMEX.url,
          icon: ExchangeInformations.exchangeInfos.BITMEX.icon,
          iconStyle: 'width: 18px;height: 18px;margin-top: 5px;float:left',
          font: 'font-size: 13px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 5px;margin-left: 10px;color: white'
        },
        GATE: {
          url: ExchangeInformations.exchangeInfos.GATE.url,
          icon: ExchangeInformations.exchangeInfos.GATE.icon,
          iconStyle: 'width: 18px;height: 18px;margin-top: 5px;float:left',
          font: 'font-size: 13px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 5px;margin-left: 10px;color: white'
        },
        MEXC: {
          url: ExchangeInformations.exchangeInfos.MEXC.url,
          icon: ExchangeInformations.exchangeInfos.MEXC.icon,
          iconStyle: 'width: 18px;height: 18px;margin-top: 5px;float:left',
          font: 'font-size: 13px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 5px;margin-left: 10px;color: white'
        },
        HUOBI: {
          url: ExchangeInformations.exchangeInfos.HUOBI.url,
          icon: ExchangeInformations.exchangeInfos.HUOBI.icon,
          iconStyle: 'width: 18px;height: 18px;margin-top: 5px;float:left',
          font: 'font-size: 13px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 5px;margin-left: 10px;color: white'
        }
      },
      exchangesInfo: [],
      refreshTimer: {}
    }
  },
  created() {
    this.loadPlatformInfo();
    this.refreshTimer = setInterval(this.loadPlatformInfo, 1000 * 60 * 5);
  },
  destroyed: function () {
    clearInterval(this.refreshTimer);
  },
  methods: {
    toPage(platform, title, url) {
      if (platform.toUpperCase() === 'DERIBIT') {
        this.$alert(url, title, {
          customClass: 'black-card web-message-box',
          dangerouslyUseHTMLString: true,
          confirmButtonClass: 'dark-mode-btn'
        });
      } else {
        window.open(url, '_blank');
      }
    },
    loadPlatformInfo() {
      let that = this;
      PlatformInfoService.query(function (data) {
        that.loadingInfo = false;
        that.exchangesInfo = data;
      });
    }
  }
}
</script>

<style scoped>

.smaller-font {
  -webkit-transform-origin-x: 0;
  -webkit-transform: scale(0.85);
}

li {
  list-style: none;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 2px;
  height: 10px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  background-color: #22272e;
  border: 1px solid #3E454E;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 8px 8px;
  padding: 2px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 0 0 8px 8px;
  background: #3e454e;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #3e454e;
}

.ellipsis-line {
  padding: 8px;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

ul {
  width: 200px;
  height: 100px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap; /* ul中的内容不换行 */
}

ul li {
  display: inline-block;
}

.web-message-box {
  width: 888px;
  height: 688px;
}


</style>