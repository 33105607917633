<template>
  <div class="trialpha-stander-sub-page">
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
    <el-card style="width: 350px;height: 200px;margin: 50px;background-color: #007bff" shadow="always" body-style="padding: 0">
      <span>123123</span>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "RiskMgnt"
}
</script>

<style scoped>

</style>