<template>
  <div style="height: 100%;width: 100%">
    <el-skeleton animated :loading="rendering" :throttle="233">
      <template slot="template">
        <trialpha-sk style="margin-top: 15px;width: 60%;float: left;margin-left:10px"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left;margin-left:10px"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left;margin-left:10px"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left;margin-left:10px"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 40%;float: left;margin-left:10px"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 90%;float: left;margin-left:10px"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left;margin-left:10px"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 90%;float: left;margin-left:10px"></trialpha-sk>
      </template>
      <template>
        <el-card class="black-card" v-for="(news, index) in newest.filter((news)=> {if (replaceString(news.data.content) !== '' && news.type !== 1) return news})" :key="index" style="border-radius: 0;background-color: rgba(0, 0, 0, 0);border-top: 0;border-left: 0;border-right: 0" body-style="padding: 0 20px 0 20px">
          <el-row>
            <span class="dark-mode-font" style="text-align: left;font-size: 12px;float: left;display: inline-block;margin-top: 10px;color: #999999;" v-if="news.data.content">{{ getTimeString(new Date(news.time).getTime()) }}</span>
          </el-row>
          <el-row>
            <div class="trialpha-news" v-html="news.data.content" :style="'text-align: left;font-size: 12px;float: left;display: inline-block;margin-top: 3px;margin-bottom: 10px;' + (news.important === 1 ? 'color: #ff5265;' : 'color: #b0b9bf;') + ''"></div>
          </el-row>
          <el-card class="black-card" v-if="news.data.pic !== '' && news.data.pic !== undefined && news.data.pic !== null" style="overflow:hidden;width:250px;height: 135px;margin-bottom: 15px;margin-left: auto;margin-right: auto;box-shadow: 0 0 10px #c1c1c1;" body-style="padding: 0" shadow="always">
            <el-image
                style="width: 250px; height: auto;object-fit: cover;"
                :z-index=5000
                :src="news.data.pic"
                :preview-src-list="[news.data.pic]">
            </el-image>
          </el-card>
        </el-card>
      </template>
    </el-skeleton>
    <div id="dialog_large_image"></div>
  </div>
</template>

<script>

import PlatformInfoService from "@/api/platform/PlatformInfoService";
import TimeService from "@/utils/TimeService";
import TrialphaSk from "@/components/TrialphaSk";

export default {
  name: "Newest",
  components: {TrialphaSk},
  data: function () {
    return {
      newest: [],
      updateTimer: {},
      rendering: true
    }
  },
  created() {
    this.load();
  },
  destroyed: function () {
    clearInterval(this.updateTimer);
  },
  methods: {
    load: function () {
      let that = this;
      PlatformInfoService.queryNews(function (data) {
        that.rendering = false;
        that.newest = data;
      });
      this.updateTimer = setInterval(() => {
        PlatformInfoService.queryNews(function (data) {
          that.newest = data;
        });
      }, 1000 * 18);
    },
    replaceString: function (content) {
      return content?.replace(/<[^>]*>/g, "");
    },
    getTimeString: function (time) {
      return TimeService.timestampToDate(time);
    }
  }
}
</script>

<style scoped>

.ellipsis-line-strategy {
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.trialpha-news img {
  max-width: 100px;
}

.el-image-viewer__img {
  z-index: 5001 !important;
}

</style>