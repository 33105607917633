let SERVER = 'https://quant.forthtech.io';//process.env.VUE_APP_TRIALPHA_PROD;

function getCompanyValue() {
  return process.env.VUE_APP_COMPANY_NAME;
}

function getCompanyDisplayValue() {
  return 'Oneport';
}

export default {
  SERVER,
  getCompanyDisplayValue,
  getCompanyValue
}