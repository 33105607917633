<template>
  <div :dto="dto">
    <el-skeleton animated :loading="rendering" :throttle="268">
      <template slot="template">
        <trialpha-sk style="margin-top: 0;width: 60%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 40%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 100%;float: left"></trialpha-sk>
      </template>
      <template>
        <el-row>
          <el-row style="margin-top: 0">
            <el-button-group>
              <el-button class="dark-mode-btn" style="width: 68px;font-family: 'Microsoft YaHei',serif;font-size: 12px;padding: 8px;border-radius: 3px 0 0 0;border-bottom: 0" size="small" :disabled="coin === 'BTC'" @click="changeCoin('BTC')">BTC</el-button>
              <el-button class="dark-mode-btn" style="width: 68px;font-family: 'Microsoft YaHei',serif;font-size: 12px;padding: 8px;border-radius: 0 3px 0 0;border-bottom: 0" size="small" :disabled="coin === 'ETH'" @click="changeCoin('ETH')">ETH</el-button>
            </el-button-group>
          </el-row>
          <el-row style="margin-top: 0">
            <el-button-group>
              <el-button class="dark-mode-btn" style="width: 68px;font-family: 'Microsoft YaHei',serif;font-size: 12px;padding: 8px;border-bottom: 0;border-radius: 0" v-for="(bt, index) in dateArrays" :key="index" size="small" :disabled="bt === selectDate" @click="syncDate(bt)">{{bt}}</el-button>
            </el-button-group>
          </el-row>
          <el-row style="margin-top: 0;">
            <!-- 挂单数据 Header -->
            <el-card class="black-card" style="border-radius: 0;border-bottom: 0;background-color: #1c2128" body-style="padding: 10px" shadow="none">
              <el-row>
                <el-col :span="12">
                  <span class="dark-mode-font" style="color: white">低买（USD）</span>
                </el-col>
                <el-col :span="12">
                  <span class="dark-mode-font" style="color: white;display: inline-block;float: right;margin-top: 2px">{{coin}}: ${{ getPrice() }}</span>
                </el-col>
              </el-row>
            </el-card>

            <!-- 挂单数据表头 -->
            <el-card class="black-card" style="border-radius: 0;height: 28px;background-color: #1c2128" shadow="none" body-style="padding: 4px 10px 3px 10px">
              <el-row>
                <el-col :span="3">
                  <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:darkgray ">目标价格</span>
                </el-col>
                <el-col :span="5">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">净收益</span>
                </el-col>
                <el-col :span="5">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">年化率</span>
                </el-col>
                <el-col :span="5">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">最大可买(USD)</span>
                </el-col>
                <el-col :span="6">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">买入(USD)</span>
                </el-col>
              </el-row>
            </el-card>

            <!-- 挂单数据展示 -->
            <el-card class="black-card" style="border-radius: 0;border-top: 0;max-height: 196px;overflow-y: auto" shadow="none" body-style="padding: 0">
              <el-skeleton animated :loading="loadingSymbols" :throttle="200">
                <template slot="template">
                  <trialpha-sk :style="'margin-top: 20px;width: 60%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 90%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk style="margin-top: 20px;margin-bottom: 20px;width: 90%;float: left;margin-left: 10px"></trialpha-sk>
                </template>
                <template>
                  <div v-if="dateArrays.length > 0">
                    <el-card class="black-card-hover" shadow="none" style="border: 0;border-radius: 0" body-style="padding:0" v-for="(p, index) in dateSymbolMap[selectDate]" :key="index">
                      <el-row style="height: 28px;border-bottom: 1px solid #2e3033;padding: 4px 10px 4px 10px">
                        <el-col :span="3">
                          <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">${{ Number.prototype.toFormatNum(p['symbol'].split('-')[3]) }}</span>
                          <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:gray ">{{ (((Number(p['symbol'].split('-')[3]) - getPrice()) / getPrice()) * 100).toFixed(2) }}%</span>
                        </el-col>
                        <el-col :span="5">
                          <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:#0ecb81 ">{{ p['profit'] }}%</span>
                        </el-col>
                        <el-col :span="5">
                          <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:#0ecb81 ">{{ p['yearRate'] }}%</span>
                        </el-col>
                        <el-col :span="5">
                          <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">${{ Number.prototype.toFormatNum(p['maxUsd']) }}</span>
                        </el-col>
                        <el-col :span="6">
                          <el-button class="dark-mode-btn" style="border-radius: 0;border-top: 0;border-right: 0;height: 28px;line-height: 28px;float: right;margin-top: -4px;font-size: 12px;padding: 0;width: 68px;margin-right: -10px" @click="sendOrder(p['symbol'], p['profit'], p['bidPx'])">BUY</el-button>
                          <input class="trialpha-input" v-model="orderNum[p['symbol']]" style="border-top: 0;border-right: 0;height: 28px;line-height: 28px;width: 100px;float: right;border-radius: 0;margin-top: -4px;" placeholder="0.0"/>
                        </el-col>
                      </el-row>
                    </el-card>
                  </div>
                </template>
              </el-skeleton>
            </el-card>
          </el-row>

          <el-row style="margin-top: 0;margin-bottom: 20px">
            <!-- 挂单数据 Header -->
            <el-card class="black-card" style="border-top: 0;border-radius: 0;border-bottom: 0;background-color: #1c2128" body-style="padding: 10px" shadow="none">
              <el-row>
                <el-col :span="8">
                  <span class="dark-mode-font" style="color: white">我持有的</span>
                </el-col>
              </el-row>
            </el-card>

            <!-- 挂单数据表头 -->
            <el-card class="black-card" style="border-radius: 0;height: 28px;background-color: #1c2128" shadow="none" body-style="padding: 4px 10px 3px 10px">
              <el-row>
                <el-col :span="3">
                  <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:darkgray ">目标价格</span>
                </el-col>
                <el-col :span="5">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">净收益</span>
                </el-col>
                <el-col :span="5">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">年化率</span>
                </el-col>
                <el-col :span="5">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">预计利润</span>
                </el-col>
                <el-col :span="6">
                  <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">到期时间</span>
                </el-col>
              </el-row>
            </el-card>

            <!-- 挂单数据展示 -->
            <el-card class="black-card" style="border-radius: 0;border-top: 0;max-height: 238px;overflow-y: auto" shadow="none" body-style="padding: 0">
              <el-skeleton animated :loading="loadingSymbols" :throttle="200">
                <template slot="template">
                  <trialpha-sk :style="'margin-top: 20px;width: 60%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 90%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left;margin-left: 10px'"></trialpha-sk>
                  <trialpha-sk style="margin-top: 20px;margin-bottom: 20px;width: 90%;float: left;margin-left: 10px"></trialpha-sk>
                </template>
                <template>
                  <div v-if="dateArrays.length > 0">
                    <el-card class="black-card-hover" shadow="none" style="border: 0;border-radius: 0" body-style="padding:0" v-for="(p, index) in myPositions" :key="index">
                      <el-row style="height: 28px;border-bottom: 1px solid #2e3033;padding: 4px 10px 4px 10px">
                        <el-col :span="3">
                          <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">${{ Number.prototype.toFormatNum(p['instId'].split('-')[3]) }}</span>
                          <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:gray ">{{ (((Number(p['instId'].split('-')[3]) - getPrice(p['instId'].split('-')[0])) / getPrice(p['instId'].split('-')[0])) * 100).toFixed(2) }}%</span>
                        </el-col>
                        <el-col :span="5">
                          <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:#0ecb81 ">{{ p['profit'] }}%</span>
                        </el-col>
                        <el-col :span="5">
                          <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:#0ecb81 ">{{ p['yearRate'] }}%</span>
                        </el-col>
                        <el-col :span="5">
                          <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:#0ecb81 ">${{ (Math.abs(p['avgPx']) * Math.abs(p['pos']) * getPrice(p['instId'].split('-')[0]) / (p['instId'].split('-')[0] === 'ETH' ? 10 : 100)).toFixed(3) }}</span>
                        </el-col>
                        <el-col :span="6">
                          <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{ p['expiredDay'] }}</span>
                        </el-col>
                      </el-row>
                    </el-card>
                  </div>
                </template>
              </el-skeleton>
            </el-card>

            <el-card class="black-card" style="border-top: 0;border-radius: 0;background-color: #1c2128" body-style="padding: 10px" shadow="none">
              <el-row>
                <el-col :span="12">
                  <span class="dark-mode-font" style="color: white">持有产品占用资金：<span style="color: white">${{ Number.prototype.toFormatNum(Number(totalCost.toFixed(0))) }}</span></span>
                </el-col>
                <el-col :span="12">
                  <span class="dark-mode-font" style="color: white;display: inline-block;float: right">待收利润：<span style="color: #0ecb81">${{ Number.prototype.toFormatNum(Number(totalPnl.toFixed(2))) }}</span>，总资金利润率：<span style="color: #0ecb81">{{ Number.prototype.toFormatNum(Number(((totalPnl / accountUsdBalance) * 100).toFixed(2))) }}%</span></span>
                </el-col>
              </el-row>
            </el-card>
          </el-row>
        </el-row>
      </template>
    </el-skeleton>
  </div>
</template>

<script>
import StrategyInstanceService from "@/api/trade/StrategyInstanceService";
import TrialphaSk from "@/components/TrialphaSk";
import SymbolInfoService from "@/utils/SymbolInfoService";
import GeneralApiService from "@/api/trade/GeneralApiService";
import DeribitPublicApiService from "@/api/platform/DeribitPublicApiService";
import {Message} from "element-ui";
import ConfirmProvider from "@/components/ConfirmProvider";
import TradeApiService from "@/api/trade/TradeApiService";
import OkxPublicApiService from "@/api/platform/OkxPublicApiService";

export default {
  name: "DoubleCoinWinOkx",
  components: {TrialphaSk},
  props: {
    dto: Object
  },
  data: function () {
    return {
      coin: 'ETH',
      apiKey: '',
      selectDate: '',
      loadingSymbols: false,
      monthMap: {
        JAN: '01',
        FEB: '02',
        MAR: '03',
        APR: '04',
        MAY: '05',
        JUN: '06',
        JUL: '07',
        AUG: '08',
        SEP: '09',
        OCT: '10',
        NOV: '11',
        DEC: '12'
      },
      processing: false,
      rendering: true,
      instance: {},
      instanceList: [],
      accountInstance: {},
      dateArrays: [],
      dateSymbolMap: {},
      symbolMaxUsdMap: {},
      orderNum: {},
      myPositions: [],
      totalCost: 0,
      totalPnl: 0,
      accountUsdBalance: 0
    }
  },
  methods: {
    sendOrder(id, profit, bidPx) {
      let that = this;
      let price = Number(id.split('-')[3]);
      let sendNum = (this.orderNum[id] / this.getPrice()).toFixed(this.coin === 'BTC' ? 2 : 1);
      let equalUsd = Number((sendNum * this.getPrice()).toFixed(2));
      let profitAmount = Number((sendNum * profit / 100).toFixed(5));
      ConfirmProvider.openConfirm(that, '购入双币宝产品', '您实际购入额为 ' + this.coin + ':' + sendNum + ', 折合 USD: ' + equalUsd + '。当到期时价格大于 ' + price + ', 您将获得 ' + equalUsd + ' USD 本金和利息 ' + profitAmount + ' ' + this.coin +
      '。当到期时价格小于 ' + price + ', 您将获得 ' + (equalUsd / price).toFixed(4) + ' ' + this.coin + ' 本金和利息 ' + profitAmount + ' ' + this.coin + '。', function () {
        GeneralApiService.placeLimitOrder(GeneralApiService.PLATFORM.OKX, GeneralApiService.SYMBOL_TYPE.OPTIONS, GeneralApiService.ORDER_TYPE.OPEN_SHORT, that.apiKey, id, bidPx, sendNum * (that.coin === 'ETH' ? 10 : 100), function () {
          Message.success('操作成功!');
          setTimeout(() => {
            that.syncFullSymbol()
          }, 888);
        }, function (err) {
          console.log(err);
          Message.error('Send error, please try again.');
        });
      });
    },
    getOrderBook(id) {
      let that = this;
      DeribitPublicApiService.getOrderBookById(id, function (res) {
        that.symbolMaxUsdMap[id] = res.result.bids[0][1];
        that.$nextTick(() => {
          that.$forceUpdate();
        });
      });
    },
    changeCoin(coin) {
      this.coin = coin;
      this.syncFullSymbol();
    },
    syncDate(bt) {
      this.selectDate = bt;
    },
    getPrice(coin) {
      return SymbolInfoService.getSymbolPrice(GeneralApiService.PLATFORM.BINANCE, GeneralApiService.SYMBOL_TYPE.SPOT, (coin ? coin : this.coin) + 'USDT');
    },
    render: function (accountInstance) {
      let that = this;
      that.rendering = true;
      that.accountInstance = accountInstance;
      that.instance = JSON.parse(JSON.stringify(that.dto.strategyConfig));
      that.syncFullSymbol();
      StrategyInstanceService.query(that.accountInstance.instanceId, that.dto.strategyName, function (data) {
        that.instanceList = data.strategyInsList;
        that.rendering = false;
      }, function () {
        that.rendering = false;
      });
    },
    syncFullSymbol: function () {
      let that = this;
      that.loadingSymbols = true;
      this.accountInstance.tradeAccountList.forEach((tradeIns) => {
        if (tradeIns.platform === GeneralApiService.PLATFORM.OKX) {
          that.apiKey = tradeIns.apiKey;
          let balance = tradeIns.currentBalance;
          let usdPrice = (tradeIns.baseCoin === 'USD' || tradeIns.baseCoin === 'USDT' || tradeIns.baseCoin === 'USDC' || tradeIns.baseCoin === 'BUSD') ? 1 : SymbolInfoService.getSymbolPrice(GeneralApiService.PLATFORM.BINANCE, GeneralApiService.SYMBOL_TYPE.SPOT, tradeIns.baseCoin + 'USDT');
          that.accountUsdBalance = balance * usdPrice;
        }
      });
      this.syncPositions();
      OkxPublicApiService.getOkxOptionMarketInfo( function (res) {
        let symbolMap = {};
        res.data.forEach((obj) => {
          if (obj.instId.endsWith('-P') && obj.bidPx && that.coin === obj.instId.split('-')[0]) {
            let date = obj.instId.split('-')[2];
            let price = Number(obj.instId.split('-')[3]);
            let currentPrice = SymbolInfoService.getSymbolPrice(GeneralApiService.PLATFORM.BINANCE, GeneralApiService.SYMBOL_TYPE.SPOT, that.coin + 'USDT');
            if (price < currentPrice) {
              let year = date.substr(0, 2);
              let mon = date.substr(2, 2);
              let day = date.substr(4, 2);
              let expiredTime = (new Date('20' + year + '-' + mon + '-' + day + ' 16:00:00').getTime())
              if (!symbolMap[date]) {
                symbolMap[date] = [];
              }
              symbolMap[date].push({
                symbol: obj.instId,
                profit: Number((((Number(obj.bidPx)) * 100) - 0.045).toFixed(4)),
                yearRate: Number(((((Number(obj.bidPx)) * 100) - 0.045) / (expiredTime - Date.now()) * (1000 * 60 * 60 * 24 * 365)).toFixed(2)),
                maxUsd: (Number(obj.bidSz) * currentPrice / (that.coin === 'BTC' ? 100 : 10)).toFixed(0),
                bidPx: Number(obj.bidPx)
              });
              that.orderNum[obj.instId] = 0;
            }
          }
        });
        that.selectDate = Object.keys(symbolMap)[0];
        that.dateArrays = Object.keys(symbolMap);
        that.dateSymbolMap = symbolMap;
        that.loadingSymbols = false;
        that.$nextTick(() => {
          that.$forceUpdate();
        });
      });
    },
    syncPositions: function () {
      let that = this;
      let positions = [];
      TradeApiService.getAllPosition(GeneralApiService.PLATFORM.OKX, this.apiKey, function (data) {
        let totalInvest = 0;
        let totalPnl = 0;
        for (const dataKey in data) {
          let position = data[dataKey];
          if (dataKey.endsWith('-P')) {
            let date = dataKey.split('-')[2];
            let year = date.substr(0, 2);
            let mon = date.substr(2, 2);
            let day = date.substr(4, 2);
            let expiredTime = (new Date('20' + year + '-' + mon + '-' + day + ' 16:00:00').getTime())
            position.profit = Number((((Number(position.avgPx)) * 100) - 0.045).toFixed(4));
            position.yearRate = Number(((((Number(position.avgPx)) * 100) - 0.045) / (expiredTime - Number(position.cTime)) * (1000 * 60 * 60 * 24 * 365)).toFixed(2));
            position.expiredDay = '20' + year + '-' + mon + '-' + day;
            positions.push(position);
            totalInvest += (Math.abs(position['pos']) * that.getPrice(position['instId'].split('-')[0]) / (position['instId'].split('-')[0] === 'ETH' ? 10 : 100));
            totalPnl += (Math.abs(position['avgPx']) * Math.abs(position['pos']) * that.getPrice(position['instId'].split('-')[0]) / (position['instId'].split('-')[0] === 'ETH' ? 10 : 100))
          }
        }
        that.myPositions = positions;
        that.totalCost = totalInvest;
        that.totalPnl = totalPnl;
      }, function () {

      });
    }
  }
}
</script>

<style scoped>

.ellipsis-line {
  padding: 8px;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-collapse {
  color: #FFFFFF;
  border-top: 1px solid #444c56;
  border-bottom: 1px solid #444c56;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: white;
}

.el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-divider {
  border-top: 1px solid #444c56;
}

.el-divider.info {
  width: 80%;
  border-top: 1px solid #444c56;
}

.el-divider__text {
  color: white;
  background-color: #444c56;
}

.select-symbol .el-input__inner {
  color: white;
  height: 35px !important;
  line-height: 35px;
  width: 100%;
  padding: 0 10px 0 10px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-right: 0;
  border-radius: 0;
  font-size: 12px;
  outline: none;
}

.select-symbol.el-select .el-input.is-focus .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:hover .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:hover .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:focus .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:visited .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:focus .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:visited .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

</style>