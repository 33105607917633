import { defineStore } from 'pinia'
import { piniaStore } from '@/store/index'
import moment from "moment"

import SymbolInfoService from "@/utils/SymbolInfoService";
import CrmChannelManagement from "@/api/crm/CrmChannelManagement";

// function TaskChain(){
//     var self = this;
//     self.index = 0;
//     self.chain = [];
//     self.reset = function(){
//         self.index = 0;
//     };
//     self.clear = function(){
//         self.chain = [];
//         self.index = 0;
//     };
//     self.method = function(fn){
//         self.chain.push(fn);
//     };
//     self.start = function(){
//         if(self.chain.length == 0){
//             return;
//         }
//         var next = function(data){
//             var fn = self.chain[++self.index];
//             if(fn){
//                 fn(next, data);
//             }
//         };
//         self.chain[0](next);
//     };
//     return self;
// }

function uuid(len, radix) {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    var uuid = [],
        i;
    radix = radix || chars.length;
    if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
        // rfc4122, version 4 form
        var r;
        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';
        // Fill in random data.  At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | Math.random() * 16;
                uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
            }
        }
    }
    return uuid.join('');
}

function CreateID(prefix){
    if(!prefix) prefix = 'ID';
    return prefix + '_' + uuid(8, 16) + '_' + Math.round( Math.random() * 100 )
}

export function AsyncQueue() {
    var self = this;
    self.queue = [];
    self.countdown = 0;
    self._finish = function() {};
    self.reset = function(){
        self.countdown = 0;
    };
    self.action = function(fn){
        self.queue.push(fn);
    };
    self.finish = function(fn){
        self._finish = fn;
    };
    self.start = function(){
        if(self.queue.length == 0){
            return;
        }
        var finish = function(){
            if (++ self.countdown == self.queue.length) {
                self._finish();
            }
        };
        for (var i in self.queue) {
            self.queue[i](finish);
        }
    };
}

export const useCrmStore = defineStore(
    'crm',
    {
        // arrow function recommended for full type inference
        state: () => {
            return {

                // 数据来源 personal, channel
                currentPanel: 'personal',

                // 个人列表（全部的）
                personalListAll: [],
                // 个人列表（显示的）
                personalListShow: [],
                
                // 渠道列表
                channelList: [],
                // 展开的渠道
                channelActive: '',
                // 展开的渠道账号列表（全部的）
                channelListAll: [],
                // 展开的渠道账号列表（显示的）
                channelListShow: [],

                // 当前主账号
                currentMainAccount: '',
                // 当前主账号所属渠道
                currentMainAccountChannel: 0,
                // 当前主账号关联的子账号, 主账号+子账号的列表
                currentLinkAccountList: [],
                // 显示第几个账号索引, 主账号+子账号的列表
                showAccountIndex: 0,
                // 显示第几个账号信息
                showAccountDetail: null,
                // 结算记录、备注
                currentAccountSettlement: [],
                // 详情界面绘制次数
                drawCurrentDetail: 0,

                // 备注弹框文本
                remarkText: ''

            }
        },
        // 相当于 vue 中的 computed 计算属性
        getters: {
        },
        // 相当于 vue 中的 methods 方法
        actions: {
            // 加载个人列表数据
            loadPersonalData() {
                var that = this
                return new Promise((resolve, reject) => {
                    CrmChannelManagement.queryCustomer(1, 99999999, '', '',
                        (data) => {
                          let items = data.content;
                          items.forEach(m => {
                            m.settlementTimeString = moment(m.settlementTime * 1000).format('YYYY-MM-DD')
                          })
                          resolve(
                            that.setPersonalData(items)
                          )
                        },
                        (err) => {
                          // Message.error(this.$i("operationFail"));
                          reject(err)
                        }
                    );
                })
            },
            // 设置个人列表数据
            setPersonalData(items) {
                var that = this
                var itemMap = {}
                items.forEach(item => {
                    item.uuid = CreateID()
                    if (itemMap[item.name]) {
                        itemMap[item.name].push(item)
                    } else {
                        itemMap[item.name] = [item]
                    }
                })
                // 重置accountSerialNumber
                for (var name in itemMap) {
                    itemMap[name].sort((a, b) => {
                        return a.createTime - b.createTime
                    })
                    itemMap[name].forEach((item, index) => {
                        item.accountSerialNumber = index
                    })
                }
                this.personalListShow = items.filter(item => {
                    return item.accountSerialNumber === 0
                }).map(item => {
                    var subs = itemMap[item.name]
                    var view = that.calcLeftAccountView(item, subs, 0)
                    return {
                        view: view,
                        items: subs.sort((a, b) => {
                            return a.accountSerialNumber - b.accountSerialNumber
                        })
                    }
                })
                console.log('设置个人列表数据', JSON.parse(JSON.stringify(this.personalListShow)))
                return this.personalListShow
            },
            // 加载渠道列表数据
            loadChannelData() {
                var that = this
                return new Promise((resolve, reject) => {
                    CrmChannelManagement.queryChannel(1, 99999999, '', '',
                        (data) => {
                            data.content.forEach((item) => {
                                if (!item.forthtechCustomerInfoEntityDtos) {
                                    item.forthtechCustomerInfoEntityDtos = []
                                }
                                item.totalFundsAmount = 0
                            })
                            var items = data.content.filter(item => {
                                return item.belong == this.userName
                            }).concat(data.content.filter(item => {
                                return item.belong != this.userName
                            }))
                            resolve(
                              that.setChannelData(items)
                            )
                        },
                        (err) => {
                            // Message.error(this.$i("operationFail"));
                            reject(err)
                        }
                    );
                })
            },
            // 设置渠道列表数据
            setChannelData(items) {
                this.channelList = items
                return this.channelList
            },
            // 设置展开的渠道账号列表数据
            setChannelExpandData(items) {
                var that = this
                items.forEach(m => {
                    m.settlementTimeString = moment(m.settlementTime * 1000).format('YYYY-MM-DD')
                })
                var itemMap = {}
                items.forEach(item => {
                    item.uuid = CreateID()
                    if (itemMap[item.name]) {
                        itemMap[item.name].push(item)
                    } else {
                        itemMap[item.name] = [item]
                    }
                })
                // 重置accountSerialNumber
                for (var name in itemMap) {
                    itemMap[name].sort((a, b) => {
                        return a.createTime - b.createTime
                    })
                    itemMap[name].forEach((item, index) => {
                        item.accountSerialNumber = index
                    })
                }
                this.channelListShow = items.filter(item => {
                    return item.accountSerialNumber === 0
                }).map(item => {
                    var subs = itemMap[item.name]
                    var view = that.calcLeftAccountView(item, subs, 0)
                    return {
                        view: view,
                        items: subs.sort((a, b) => {
                            return a.accountSerialNumber - b.accountSerialNumber
                        })
                    }
                })
                return this.channelListShow
            },

            // 设置来源
            setCurrentSource(panel) {
                this.currentPanel = panel
            },
            // 设置主账号
            setCurrentMainAccount(name, index) {
                var listShow = (this.currentPanel == 'personal' ? this.personalListShow : this.channelListShow)
                var target = listShow.filter(item => {
                    return item.view.name == name
                })[0]
                console.log('设置主账号', JSON.parse(JSON.stringify(target)))
                if (target) {
                    this.currentMainAccount = name
                    this.currentMainAccountChannel = target.view.channel.id
                    this.currentLinkAccountList = target.items
                    this.showAccountIndex = index == undefined ? 0 : index
                    this.showAccountDetail = target.items[0]
                    this.drawCurrentDetail ++
                    this.setCurrentLeftAccount()
                    return this.showAccountDetail
                } else {
                    this.currentMainAccount = ''
                    this.currentMainAccountChannel = 0
                    this.currentLinkAccountList = []
                    this.showAccountIndex = 0
                    this.showAccountDetail = null
                    this.drawCurrentDetail ++
                    return null
                }
            },
            // 设置左侧显示的view数据
            setCurrentLeftAccount() {
                var name = this.currentMainAccount
                var listShow = (this.currentPanel == 'personal' ? this.personalListShow : this.channelListShow)
                var target = listShow.filter(item => {
                    return item.view.name == name
                })[0]
                console.log('设置左侧显示的view数据', JSON.parse(JSON.stringify(target)))

                target.view = this.calcLeftAccountView(target.view, target.items, this.showAccountIndex)
                return target.view
            },
            // 计算左侧的值
            calcLeftAccountView(targetview, items, index) {
                var view = JSON.parse(JSON.stringify(targetview))
                var vitem = items[index]
                view.id = vitem.id

                // 总金额
                var total = 0
                items.map(item => {
                    var v = parseFloat(item.startFundsAmount)
                    if (item.coinName == 'USDT') {
                        return v
                    }
                    if (item.coinName == 'USD') {
                        return v
                    }
                    if (item.coinName == 'BUSD') {
                        return v
                    }
                    if (item.coinName == 'USDC') {
                        return v
                    }
                    console.log('价值参数', 'BINANCE', 'SPOT', item.coinName + 'USDT')
                    var p = SymbolInfoService.getSymbolPrice('BINANCE', 'SPOT', item.coinName + 'USDT')
                    console.log('价值', p)
                    console.log('金额转换结果', v * p)
                    return v * p
                }).forEach(v => {
                    total += v
                })
                console.log('总金额转换结果', total)
                
                if (isNaN(total)) {
                    view.startFundsAmount = 0
                    view.coinName = 'USDT'
                } else {
                    view.startFundsAmount = Math.round(total * 100) / 100
                    view.coinName = 'USDT'
                }

                // 进度
                view.progress = vitem.progress

                // 状态
                view.accountStatus = vitem.accountStatus

                view.accountNumber = vitem.accountNumber
                view.accountSerialNumber = vitem.accountSerialNumber
                view.accountTag = vitem.accountTag
                view.coinName = vitem.coinName
                view.followUser = vitem.followUser
                view.fundUtilizationRate = vitem.fundUtilizationRate
                view.maxDrawdown = vitem.maxDrawdown
                view.name = vitem.name
                view.platvitem = vitem.platvitem
                view.settlementTime = vitem.settlementTime
                view.strategyRemark = vitem.strategyRemark

                return view

            },
            // 切换账号（前一个账号）
            setCurrentPrevAccount() {
                if (this.showAccountIndex == 0) {
                    this.showAccountIndex = this.currentLinkAccountList.length - 1
                    this.showAccountDetail = this.currentLinkAccountList[this.showAccountIndex]
                } else {
                    this.showAccountIndex --
                    this.showAccountDetail = this.currentLinkAccountList[this.showAccountIndex]
                }
                this.drawCurrentDetail ++
                this.setCurrentLeftAccount()
            },
            // 切换账号（后一个账号）
            setCurrentNextAccount() {
                if (this.showAccountIndex == this.currentLinkAccountList.length - 1) {
                    this.showAccountIndex = 0
                    this.showAccountDetail = this.currentLinkAccountList[this.showAccountIndex]
                } else {
                    this.showAccountIndex ++
                    this.showAccountDetail = this.currentLinkAccountList[this.showAccountIndex]
                }
                this.drawCurrentDetail ++
                this.setCurrentLeftAccount()
            },
            // 切换账号（指定第几个）
            setCurrentIndexAccount(index) {
                this.showAccountIndex = index
                this.showAccountDetail = this.currentLinkAccountList[this.showAccountIndex]
                this.drawCurrentDetail ++
                this.setCurrentLeftAccount()
            },
            // 获取当前显示的账号详情
            getCurrentIndexAccount() {
                return this.currentLinkAccountList[this.showAccountIndex]
            },
            // 新建子账号，使用当前主账号的customerId创建
            createCurrentSubAccount() {
                var that = this
                var targetMainAccountDetail = that.currentLinkAccountList[0]
                return new Promise((resolve, reject) => {
                    CrmChannelManagement.registerChildrenCustomer({ customerId: targetMainAccountDetail.id},
                        (data) => {
                            // TODO data是新添加的数据，需要放到currentLinkAccountList并切换到这条数据
                            // that.currentLinkAccountList.push(data)
                            that.pushAccountListCache(data)
                            that.setCurrentMainAccount(data.name)
                            var index = that.currentLinkAccountList.length - 1
                            that.setCurrentIndexAccount(index)
                            resolve(data)
                        },
                        (err) => {
                            // Message.error(this.$i("operationFail"));
                            reject(err)
                        }
                    )
                })
            },
            // 添加新账号到Show缓存
            pushAccountListCache(data) {
                var that = this
                // var listShow = (that.currentPanel == 'personal' ? that.personalListShow : that.channelListShow)
                if (that.currentPanel == 'personal') {
                    that.personalListShow.forEach(item => {
                        if (item.view.name == data.name) {
                            item.items.push(data)
                            return false
                        }
                    })
                } else {
                    that.channelList.forEach(channel => {
                        if (channel.id == that.currentMainAccountChannel) {
                            channel.forthtechCustomerInfoEntityDtos.push(data)
                            that.setChannelExpandData(channel.forthtechCustomerInfoEntityDtos)
                            return false
                        }
                    })
                }
            },
            // form转data
            updateAccountCacheConvert(form, data) {
                data.accountNumber = form.accountNumber
                data.accountSerialNumber = form.accountSerialNumber
                data.accountStatus = form.accountStatus
                data.accountTag = form.accountTag
                data.channel.id = form.channelId
                data.channel.name = ''
                data.coinName = form.coinName
                data.followUser = form.followUser
                data.fundUtilizationRate = form.fundUtilizationRate
                data.maxDrawdown = form.maxDrawdown
                data.name = form.name
                data.platform = form.platform
                data.progress = form.progress
                data.settlementTime = moment(form.settlementTime).toDate().getTime() / 1000
                data.settlementTimeString = form.settlementTime
                data.startFundsAmount = form.startFundsAmount
                data.strategyRemark = form.strategyRemark

                console.log('更新后的数据', data)
            },
            // 更新账号到Show缓存
            updateAccountListCache(form) {
                var that = this
                if (that.currentPanel == 'personal') {
                    that.personalListShow.forEach(item => {
                        if (item.view.name == form.name) {
                            that.updateAccountCacheConvert(form, item.items[that.showAccountIndex])
                            var index = that.showAccountIndex
                            that.setCurrentMainAccount(form.name)
                            that.setCurrentIndexAccount(index)
                            return false
                        }
                    })
                } else {
                    that.channelList.forEach(channel => {
                        if (channel.id == that.currentMainAccountChannel) {
                            // channel.forthtechCustomerInfoEntityDtos.splice(that.showAccountIndex, 1, form)
                            channel.forthtechCustomerInfoEntityDtos.forEach(data => {
                                if (data.id == form.id) {
                                    that.updateAccountCacheConvert(form, data)
                                    return false
                                }
                            })
                            that.setChannelExpandData(channel.forthtechCustomerInfoEntityDtos)
                            var index = that.showAccountIndex
                            that.setCurrentMainAccount(form.name)
                            that.setCurrentIndexAccount(index)
                            return false
                        }
                    })
                }
            },
            // 删除账号到Show缓存
            deleteAccountListCache(data) {
                var that = this
                if (that.currentPanel == 'personal') {
                    that.personalListShow.forEach(item => {
                        if (item.view.name == data.name) {
                            item.items.splice(that.showAccountIndex, 1)
                            if (item.items.length > 0) {
                                that.setCurrentMainAccount(data.name)
                            } else {
                                // 重新加载
                            }
                            return false
                        }
                    })
                } else {
                    that.channelList.forEach(channel => {
                        if (channel.id == that.currentMainAccountChannel) {
                            var index = -1
                            channel.forthtechCustomerInfoEntityDtos.forEach((m, idx) => {
                                if (m.id == data.id) {
                                    index = idx
                                }
                            })
                            channel.forthtechCustomerInfoEntityDtos.splice(index, 1)
                            that.setChannelExpandData(channel.forthtechCustomerInfoEntityDtos)
                            that.setCurrentMainAccount(data.name)
                            return false
                        }
                    })
                }
            },

            // 更新账号信息
            updateCurrentAccount(form) {
                // var that = this
                return new Promise((resolve, reject) => {
                    CrmChannelManagement.updateCustomerInfo(form,
                        (data) => {
                            // TODO 更新缓存数据
                            resolve(data)
                        },
                        () => {
                            reject()
                        }
                    );
                })
            },

            // 设置备注弹框文本
            setRemarkText(txt) {
                this.remarkText = txt
            }

        }
    }
)

export function useCrmOutsideStore() {
    return useCrmStore(piniaStore)
}
