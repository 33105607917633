<template>
  <div class="trialpha-full-sub-page">
    <div style="margin: 56px auto auto auto;max-width: 2068px;padding: 0 6.18% 100px 6.18%">
      <el-row class="show-4-symbol" style="margin-top: 0">
        <el-col :span="24">
          <el-row>
            <el-col :span="6" style="padding: 10px 10px 10px 0">
              <coin-simple-info :symbol="'BTCUSDT'"></coin-simple-info>
            </el-col>
            <el-col :span="6" style="padding: 10px">
              <coin-simple-info :symbol="'ETHUSDT'"></coin-simple-info>
            </el-col>
            <el-col :span="6" style="padding: 10px">
              <coin-simple-info :symbol="'BNBUSDT'"></coin-simple-info>
            </el-col>
            <el-col :span="6" style="padding: 10px 0 10px 10px">
              <coin-simple-info :symbol="'APEUSDT'"></coin-simple-info>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="show-3-symbol" style="margin-top: 0">
        <el-col :span="24">
          <el-row>
            <el-col :span="8" style="padding: 10px 10px 10px 0">
              <coin-simple-info :symbol="'BTCUSDT'"></coin-simple-info>
            </el-col>
            <el-col :span="8" style="padding: 10px">
              <coin-simple-info :symbol="'ETHUSDT'"></coin-simple-info>
            </el-col>
            <el-col :span="8" style="padding: 10px 0 10px 10px">
              <coin-simple-info :symbol="'APEUSDT'"></coin-simple-info>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="show-2-symbol" style="margin-top: 0">
        <el-col :span="24">
          <el-row>
            <el-col :span="12" style="padding: 10px 10px 10px 0">
              <coin-simple-info :symbol="'BTCUSDT'"></coin-simple-info>
            </el-col>
            <el-col :span="12" style="padding: 10px 0 10px 10px">
              <coin-simple-info :symbol="'ETHUSDT'"></coin-simple-info>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="show-1-symbol" style="margin-top: 0">
        <el-col :span="24">
          <el-row>
            <el-col :span="24" style="padding: 10px 0 10px 0">
              <coin-simple-info :symbol="'BTCUSDT'"></coin-simple-info>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px">
        <ahr999 style="width: 100%;height: 300px"></ahr999>
      </el-row>
      <el-row style="margin-top: 15px">
        <data-monitor-medium></data-monitor-medium>
      </el-row>
    </div>
  </div>
</template>

<script>
import Ahr999 from "@/pages/controlPanel/analysis/Ahr999";
import DataMonitorMedium from "@/pages/controlPanel/analysis/DataMonitorMedium";
import CoinSimpleInfo from "@/pages/controlPanel/home/CoinSimpleInfo";
export default {
  name: "AnalysisMgnt",
  components: {CoinSimpleInfo, DataMonitorMedium, Ahr999}
}
</script>

<style scoped>

@media only screen and (min-width: 800px) {
  .show-1-symbol {
    display: none !important
  }
}

@media only screen and (max-width: 800px) {
  .show-2-symbol {
    display: none !important
  }
}

@media only screen and (min-width: 1200px) {
  .show-2-symbol {
    display: none !important
  }
}

@media only screen and (max-width: 1200px) {
  .show-3-symbol {
    display: none !important
  }
}

@media only screen and (min-width: 1500px) {
  .show-3-symbol {
    display: none !important
  }
}

@media only screen and (max-width: 1500px) {
  .show-4-symbol {
    display: none !important
  }
}
</style>