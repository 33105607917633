<script type="text/javascript">

import ServiceMapper from "@/const/ServiceMapper";
import AbmNet from "@/api/net/AbmNet";

let PLATFORM = {
  BINANCE: 'BINANCE',
  OKX: 'OKX',
  // FTX: 'FTX',
  DERIBIT: 'DERIBIT',
  BYBIT: 'BYBIT',
  KUCOIN: 'KUCOIN',
  MEXC: 'MEXC',
  DYDX: 'DYDX'
}

let INACTIVE_PLATFORM = ['FTX'];

let SYMBOL_TYPE = {
  ALL: 'ALL',
  OPTIONS: 'OPTIONS',
  SPOT: 'SPOT',
  USD_CONTRACT: 'USD_CONTRACT',
  COIN_CONTRACT: 'COIN_CONTRACT'
}

let ORDER_TYPE = {
  OPEN_LONG: 'OPEN_LONG',
  OPEN_SHORT: 'OPEN_SHORT',
  CLOSE_LONG: 'CLOSE_LONG',
  CLOSE_SHORT: 'CLOSE_SHORT'
}

function getKLineList(platform, symbolType, symbol, size, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.generalApiServiceKLineList + '?platform=' + platform + '&symbolType=' + symbolType + '&symbol=' + symbol + '&size=' + size, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getFirstAskBid(platform, symbolType, symbol, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.generalApiServiceFirstAskBid + '?platform=' + platform + '&symbolType=' + symbolType + '&symbol=' + symbol, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getAllPrice(platform, symbolType, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.generalApiServiceAllPrice + '?platform=' + platform + '&symbolType=' + symbolType, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getAllSymbolModel(platform, symbolType, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.generalApiServiceAllSymbolModel + '?platform=' + platform + '&symbolType=' + symbolType, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getAssetsBalance(platform, symbolType, apiKey, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.generalApiServiceAssetsBalance + '?platform=' + platform + '&symbolType=' + symbolType + '&apiKey=' + apiKey, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getAllActiveOrders(platform, symbolType, apiKey, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.generalApiServiceAllActiveOrders + '?platform=' + platform + '&symbolType=' + symbolType + '&apiKey=' + apiKey, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getAllPositions(platform, symbolType, apiKey, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.generalApiServiceAllPositions + '?platform=' + platform + '&symbolType=' + symbolType + '&apiKey=' + apiKey, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getOrderHistory(platform, symbolType, apiKey, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.generalApiServiceOrderHistory + '?platform=' + platform + '&symbolType=' + symbolType + '&apiKey=' + apiKey, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function cancelOrder(platform, symbolType, apiKey, symbol, orderId, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.generalApiServiceCancelOrder, {
    platform: platform,
    symbolType: symbolType,
    symbol: symbol,
    apiKey: apiKey,
    orderId: orderId
  }, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function updateLeverage(platform, symbolType, apiKey, symbol, leverage, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.generalApiServiceUpdateLeverage + '?platform=' + platform + '&symbolType=' + symbolType + '&apiKey=' + apiKey + '&symbol=' + symbol + '&leverage=' + leverage, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function placeMarketOrder(platform, symbolType, orderType, apiKey, symbol, amount, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.generalApiServiceMarketOrder, {
    platform: platform,
    symbolType: symbolType,
    orderType: orderType,
    apiKey: apiKey,
    symbol: symbol,
    price: 0,
    amount: amount
  }, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function placeLimitOrder(platform, symbolType, orderType, apiKey, symbol, price, amount, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.generalApiServiceLimitOrder, {
    platform: platform,
    symbolType: symbolType,
    orderType: orderType,
    apiKey: apiKey,
    symbol: symbol,
    price: price,
    amount: amount
  }, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

export default {
  PLATFORM,
  INACTIVE_PLATFORM,
  SYMBOL_TYPE,
  ORDER_TYPE,
  getKLineList,
  getFirstAskBid,
  getAllPrice,
  getAllSymbolModel,
  getAssetsBalance,
  getAllActiveOrders,
  getAllPositions,
  getOrderHistory,
  updateLeverage,
  cancelOrder,
  placeMarketOrder,
  placeLimitOrder
}


</script>