<template>
    <div style="border: 1px solid #3E454E; border-radius: 8px; padding: 30px; background: #22272E; position: relative;"
        :class="[(scrollHeight > 0 ? 'scroll-mode' : '')]" :style="(scrollHeight > 0 ? {height: scrollHeight + 'px'} : {})"
        :data-draw-index="drawCurrentDetail">

        <div class="btn-delete-flat" @click="onActionDelete">
            删除
        </div>
                
        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
            <el-col :span="4" style="text-align: left;">
            <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">交易所</label>
            </el-col>
            <el-col :span="5" style="text-align: left;">
                <select v-model="form.platform" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px">
                    <option v-for="(item, index) in platforms" :key="index" :value="item.code">{{item.desc}}</option>
                </select>
            </el-col>
            <el-col :span="5" style="text-align: right;">
            <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">账号标签</label>
            </el-col>
            <el-col :span="10" style="text-align: left;">
                <!-- <select v-model="form.accountTag" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;">
                    <option v-for="(item, index) in accountTags" :key="index" :value="item.code">{{item.desc}}</option>
                </select> -->
                <input v-model="form.accountTag" type="text" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px"/>
            </el-col>
        </el-row>
        
        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
            <el-col :span="4" style="text-align: left;">
            <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">账户数量</label>
            </el-col>
            <el-col :span="5" style="text-align: left;">
                <input v-model="form.accountNumber" type="text" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px;"/>
            </el-col>
            <el-col :span="5" style="text-align: right;">
            <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">托管资金</label>
            </el-col>
            <el-col :span="7" style="text-align: left;">
                <input v-model="form.startFundsAmount" type="text" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px;"/>
            </el-col>
            <el-col :span="3" style="text-align: left;">
                <select v-model="form.coinName" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px;">
                    <option v-for="(item, index) in coinList" :key="index" :value="item.code">{{item.desc}}</option>
                </select>
            </el-col>
        </el-row>
        
        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
            <el-col :span="4" style="text-align: left;">
            <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">最大回撤</label>
            </el-col>
            <el-col :span="5" style="text-align: left;">
                <select v-model="form.maxDrawdown" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px;">
                    <option v-for="(item, index) in 100" :key="index" :value="item">{{item}}%</option>
                </select>
            </el-col>
        </el-row>
        
        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
            <el-col :span="4" style="text-align: left;">
            <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">策略描述</label>
            </el-col>
            <el-col :span="20" style="text-align: left;">
                <textarea v-model="form.strategyRemark" type="text" rows="2" value="期现套利，配对交易，期权套利" style="width: 100%; background: transparent; color: white; border: 0px; border: 1px solid #3E454E; line-height: 28px; height: 60px; font-size: 13px;"></textarea>
            </el-col>
        </el-row>
        
        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
            <el-col :span="4" style="text-align: left;">
            <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">用户名</label>
            </el-col>
            <el-col :span="5" style="text-align: left;">
                <input v-model="form.name" type="text" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px;"/>
            </el-col>
            <el-col :span="5" style="text-align: right;">
            <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">归属渠道</label>
            </el-col>
            <el-col :span="10" style="text-align: left;">
                <select v-model="form.channelId" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px;">
                    <option v-for="(item, index) in channels" :key="index" :value="item.id">{{item.name}}</option>
                </select>
            </el-col>
        </el-row>
        
        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
            <el-col :span="4" style="text-align: left;">
            <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">客戶状态</label>
            </el-col>
            <el-col :span="5" style="text-align: left;">
                <select v-model="form.progress" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px;">
                    <option v-for="(item, index) in progressList" :key="index" :value="item.code">{{item.desc}}</option>
                </select>
            </el-col>
            <el-col :span="5" style="text-align: right;">
            <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">账号状态</label>
            </el-col>
            <el-col :span="10" style="text-align: left;">
                <select v-model="form.accountStatus" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px;">
                    <option v-for="(item, index) in accountStatusList" :key="index" :value="item.code">{{item.desc}}</option>
                </select>
            </el-col>
        </el-row>
        
        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
            <el-col :span="4" style="text-align: left;">
            <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">跟进人员</label>
            </el-col>
            <el-col :span="5" style="text-align: left;">
                <input v-model="form.followUser" type="text" readonly style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px;"/>
            </el-col>
            <el-col :span="5" style="text-align: right;">
            <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">结算时间</label>
            </el-col>
            <el-col :span="10" style="text-align: left;">
                <input v-model="form.settlementTime" type="date" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px;"/>
            </el-col>
        </el-row>
        
        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
            <el-col :span="4" style="text-align: left;">
                <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">添加时间</label>
            </el-col>
            <el-col :span="5" style="text-align: left;">
                <input :value="form.createTime" readonly type="text" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px;"/>
            </el-col>
            <el-col :span="15" style="text-align: left;">
                <!-- <input type="text" placeholder="请输入备注信息" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/> -->
                <div style="display: flex;">
                    <div style="flex: 1;">
                        
                    </div>
                    <FtGroupButtonWidget :buttons="[{
                        id: 'update',
                        text: '保存',
                        textI18n: ''
                    }]" mode="single" v-on:switch-change="eventSwitchChange" style="margin-left: 16px; background: #3E454E;" />
                </div>
            </el-col>
        </el-row>

        <div style="border-top: 1px solid #3E454E; margin: 15px 0;"></div>
        
        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
            <el-col :span="24" style="text-align: left;">
                <FtGroupButtonWidget :buttons.sync="detailSwitchButtons" v-on:switch-change="eventSwitchChange" />
            </el-col>
        </el-row>
        
        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
            <el-col :span="24" style="text-align: left;">
                <!-- 结算记录、备注 -->
                <FtTableListWidget 
                    :columns="columns" 
                    :data="data"
                    :scrollHeight="scrollHeightList"
                    v-on:checked-change="onActionRow"
                    v-on:action-cell="onActionCell"
                    v-on:checked-remove="onCheckedRemove" />
            </el-col>
        </el-row>
        
        <el-row v-if="currentPanel == 'record'" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
            <el-col :span="24" style="text-align: left;">
                <!-- <input type="text" placeholder="请输入备注信息" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/> -->
                <div style="display: flex;">
                    <div style="flex: 1;">
                        <FtInputBoxWidget v-model="startAmount" placeholder="初始金额" style="margin-left: 0px;" />
                    </div>
                    <div style="flex: 1;">
                        <FtInputBoxWidget v-model="incomeAmount" placeholder="收益金额" style="margin-left: 16px;" />
                    </div>
                    <div style="flex: 1;">
                        <FtInputBoxWidget v-model="nextIssueAmount" placeholder="下期金额" style="margin-left: 16px;" />
                    </div>
                    <FtGroupButtonWidget :buttons="[{
                        id: 'append',
                        text: '输入',
                        textI18n: '',
                    }]" mode="single" v-on:switch-change="eventSwitchChange" style="margin-left: 16px; background: #3E454E;" />
                </div>
            </el-col>
        </el-row>
        
        <el-row v-if="currentPanel == 'remark'" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
            <el-col :span="6" style="text-align: left;" v-show="currentPanel == 'record'">
                <!-- <input type="text" placeholder="请输入金额" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/> -->
                <FtInputBoxWidget v-model="inputMoney" placeholder="请输入结算金额" style="margin-left: 0px; margin-right: 16px;" />
            </el-col>
            <el-col :span="currentPanel == 'record' ? 18 : 24" style="text-align: left;">
                <!-- <input type="text" placeholder="请输入备注信息" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/> -->
                <div style="display: flex;">
                    <div style="flex: 1;">
                        <FtInputBoxWidget v-model="inputRemark" placeholder="请输入备注信息" style="margin-left: 0px;" />
                    </div>
                    <FtGroupButtonWidget :buttons="[{
                        id: 'append',
                        text: '输入',
                        textI18n: '',
                    }]" mode="single" v-on:switch-change="eventSwitchChange" style="margin-left: 16px; background: #3E454E;" />
                </div>
            </el-col>
        </el-row>

    </div>
</template>

<script>
import {Message} from "element-ui";
import moment from "moment"
import ConfirmProvider from '@/components/ConfirmProvider'
import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";
import FtTableListWidget from "@/components/widgets/FtTableListWidget";
import FtInputBoxWidget from "@/components/widgets/FtInputBoxWidget";

import { useCrmStore, useCrmOutsideStore } from '@/store/crm'
import CrmChannelManagement from "@/api/crm/CrmChannelManagement";
import UserService from "@/api/user/UserService";
import UserPermissionService from "@/api/user/UserPermissionService";

export default {
    components: {
        FtGroupButtonWidget,
        FtTableListWidget,
        FtInputBoxWidget
    },
    props: {
        
    },
    data() {
        return {
            userName: '',
            // 滚动面板高度
            scrollHeight: 0,
            scrollHeightList: 0,
            // 交易所列表
            platforms: [
                { code: "-1", desc: "请选择"},
                { code: "0", desc: "FTX"},
                { code: "1", desc: "Binance"},
                { code: "2", desc: "OKX"},
                { code: "3", desc: "Deribit"},
                { code: "4", desc: "BITMEX"},
                { code: "5", desc: "Bybit"},
                { code: "6", desc: "Kucoin"},
                { code: "7", desc: "Huobi"},
                { code: "8", desc: "Gate"},
                { code: "9", desc: "Bitcoke"},
                { code: "10", desc: "MEXC"},
            ],
            // 账号标签列表
            accountTags: [
                { code: "-1", desc: "请选择"},
                // { code: "0", desc: "FTX"},
                // { code: "1", desc: "Binance"},
                // { code: "2", desc: "OKX"},
                // { code: "3", desc: "Deribit"},
                // { code: "4", desc: "BITMEX"},
                // { code: "5", desc: "Bybit"},
                // { code: "6", desc: "Kucoin"},
                // { code: "7", desc: "Huobi"},
                // { code: "8", desc: "Gate"},
                // { code: "9", desc: "Bitcoke"},
                // { code: "10", desc: "MEXC"},
                { code: "FTX", desc: "FTX"},
                { code: "Binance", desc: "Binance"},
                { code: "OKX", desc: "OKX"},
                { code: "Deribit", desc: "Deribit"},
                { code: "BITMEX", desc: "BITMEX"},
                { code: "Bybit", desc: "Bybit"},
                { code: "Kucoin", desc: "Kucoin"},
                { code: "Huobi", desc: "Huobi"},
                { code: "Gate", desc: "Gate"},
                { code: "Bitcoke", desc: "Bitcoke"},
                { code: "MEXC", desc: "MEXC"},
            ],
            // 币种列表
            coinList: [
                { code: "USDT", desc: "USDT"},
                { code: "USD", desc: "USD"},
                { code: "BTC", desc: "BTC"},
                { code: "ETH", desc: "ETH"},
            ],
            // 账号状态列表
            progressList: [
                { code: '', desc: "请选择"},
                { code: '接触中', desc: "接触中"},
                { code: '沟通中', desc: "沟通中"},
                { code: '达成合作', desc: "达成合作"},
                { code: '沟通失败', desc: "沟通失败"},
            ],
            // 运行状态列表
            accountStatusList: [
                // {code: "-1", desc: "Inactive"},
                // {code: "0", desc: "Registered"},
                // {code: "1", desc: "Kyccheckin"},
                // {code: "5", desc: "Running"},
                // {code: "4", desc: "Setting"},
                // {code: "Inactive", desc: "Inactive"},
                {code: "Registered", desc: "已注册"},
                {code: "KYCChecked", desc: "KYC审核"},
                {code: "SettingApi", desc: "Api配置"},
                {code: "Setting", desc: "账号配置"},
                {code: "Handing", desc: "待运行"},
                {code: "Running", desc: "运行中"},
            ],
            // 渠道列表
            channels: [],
            // 表单数据
            form: {
                accountNumber: 0,
                accountSerialNumber: 0,

                accountStatus: '',
                accountTag: '',
                channelId: 0,
                coinName: '',
                customerId: '',
                followUser: '',
                fundUtilizationRate: 0,
                maxDrawdown: 0,
                name: '',
                platform: '',
                progress: '',
                settlementTime: 0,
                startFundsAmount: 0,
                strategyRemark: '',
                user: '',

                createTime: ''
            },

            // 详情面板
            detailSwitchButtons: [
                {
                    id: 'record',
                    text: '结算记录',
                    textI18n: '',
                    active: true
                },
                {
                    id: 'remark',
                    text: '备注',
                    textI18n: '',
                    active: false
                }
            ],

            currentPanel: 'record',

            // 结算信息列表信息、备注列表信息
            columns: [
                // {textI18n: '', text: '#', align: 'left', width: '5%', name: 'id', checkbox: true},
                {textI18n: '', text: '编号', align: 'left', width: '12%', name: 'id'},
                {textI18n: '', text: '金额', align: 'left', width: '15%', name: 'amount'},
                {textI18n: '', text: '备注', align: 'left', width: '45%', name: 'remark'},
                {textI18n: '', text: '时间', align: 'left', width: '25%', name: 'time'},
                {textI18n: '', text: '', icon: 'el-icon-more', align: 'right', name: 'option', width: '3%'},
            ],
            data: [
                // {
                //     id: 4,
                //     text1: '100,000,000  USDT',
                //     text2: '客户后续还有100w，谨慎对待',
                //     text3: '2022.09.30',
                // },
            ],
            // 金额
            inputMoney: '',
            // 备注
            inputRemark: '',
            // 初始金额
            startAmount: '',
            endAmount: '',
            // 收益金额
            incomeAmount: '',
            // 下期金额
            nextIssueAmount: '',
            
        }
    },
    computed: {
        drawCurrentDetail: function() {
            var crmOutsideStore = useCrmOutsideStore()
            if (crmOutsideStore.showAccountDetail) {
                this.setFormValue(crmOutsideStore.showAccountDetail)
            }
            return crmOutsideStore.drawCurrentDetail
        }
    },
    mounted() {
        this.userName = UserService.getUserName()

        var windowHeight = window.innerHeight
        this.scrollHeight = windowHeight - 180 - 65
        this.scrollHeightList = this.scrollHeight - 650 - 24
        
    },
    methods: {

        setFormValue(item) {

            console.log('更新 setFormValue', JSON.parse(JSON.stringify(item)))

            var that = this
            
            if (item.createTime) {
                that.form.createTime = moment(item.createTime).format('YYYY-MM-DD')
            }

            that.form.id = item.id
            that.form.accountSerialNumber = item.accountSerialNumber
            that.form.accountNumber = item.accountNumber
            that.form.accountStatus = (() => {
                var m = that.accountStatusList.filter(t => {
                    return t.code == item.accountStatus
                })[0]
                return m ? m.code : ''
            })()
            that.form.accountTag = item.accountTag
            that.form.channelId = item.channel.id
            that.form.coinName = item.coinName
            that.form.customerId = item.id
            that.form.followUser = item.followUser
            that.form.fundUtilizationRate = item.fundUtilizationRate
            that.form.maxDrawdown = item.maxDrawdown
            that.form.name = item.name
            that.form.platform = (() => {
                var m = that.platforms.filter(t => {
                    return t.desc == item.platform || t.code == item.platform
                })[0]
                return m ? m.code : ''
            })()
            that.form.progress = item.progress
            that.form.settlementTime = item.settlementTimeString
            that.form.startFundsAmount = item.startFundsAmount
            that.form.strategyRemark = item.strategyRemark
            that.form.user = item.user
            // // 加载渠道列表
            // that.channelList()
            // 加载结算记录
            if (that.currentPanel == 'record') {
                that.data = []
                that.queryCustomerSettleInfo()
            }
            // 加载备注
            else if (that.currentPanel == 'remark') {
                that.data = []
                that.queryCustomerRemark()
            }

        },

        setDetail(value) {
            if (this.form.name == value.name) {
                return
            }

            var crmStore = useCrmStore()
            // var item = 
            crmStore.setCurrentMainAccount(value.name)

            // 加载渠道列表
            this.channelList()

            // this.setFormValue(item)
        },

        setRefresh(value) {
            console.log('刷新', value)

            var crmStore = useCrmStore()
            // var item = 
            crmStore.setCurrentMainAccount(value.name)

            // 加载渠道列表
            this.channelList()

            // this.setFormValue(item)

        },

        queryCustomerSettleInfo() {
            return new Promise((resolve, reject) => {
                CrmChannelManagement.queryCustomerSettle(this.form.id,
                    (data) => {
                        let items = data
                        items.forEach(m => {
                            m.time = moment(m.time * 1000).format('YYYY-MM-DD')
                        })
                        this.columns = [
                            // {textI18n: '', text: '#', align: 'left', width: '5%', name: 'id', checkbox: true},
                            {textI18n: '', text: '编号', align: 'left', width: '10%', name: '_index'},
                            {textI18n: '', text: '初始金额', align: 'left', width: '20%', name: 'startAmount'},
                            {textI18n: '', text: '收益金额', align: 'left', width: '20%', name: 'incomeAmount'},
                            {textI18n: '', text: '下期金额', align: 'left', width: '20%', name: 'nextIssueAmount'},
                            {textI18n: '', text: '时间', align: 'left', width: '30%', name: 'time'},
                            {textI18n: '', text: '', icon: 'el-icon-more', align: 'right', name: 'option', width: '5%', remove: true},
                        ]
                        items.forEach((item, index) => {
                            item._index = index + 1
                        })
                        this.data = items
                        resolve(items)
                    },
                    () => {
                        // Message.error(this.$i("operationFail"));
                        reject()
                    }
                );
            })
        },

        queryCustomerRemark() {
            return new Promise((resolve, reject) => {
                CrmChannelManagement.queryCustomerRemark(this.form.id,
                    (data) => {
                        let items = data
                        items.forEach(m => {
                            m.createTime = moment(m.createTime * 1000).format('YYYY-MM-DD')
                        })
                        this.columns = [
                            // {textI18n: '', text: '#', align: 'left', width: '5%', name: 'id', checkbox: true},
                            {textI18n: '', text: '编号', align: 'left', width: '10%', name: '_index'},
                            // {textI18n: '', text: '备注', align: 'left', width: '65%', name: 'remark'},
                            {textI18n: '', text: '备注', align: 'left', width: '65%', name: 'remark', render: (name, rowScope) => {
                                if (rowScope[name].length > 20) {
                                    return rowScope[name].substring(0, 20) + '...'
                                }
                                return rowScope[name]
                            }},
                            {textI18n: '', text: '时间', align: 'left', width: '20%', name: 'createTime'},
                            {textI18n: '', text: '', icon: 'el-icon-more', align: 'right', name: 'option', width: '5%', remove: true},
                        ]
                        items.forEach((item, index) => {
                            item._index = index + 1
                        })
                        this.data = items
                        resolve(items)
                    },
                    () => {
                        // Message.error(this.$i("operationFail"));
                        reject()
                    }
                );
            })
        },

        // 加载渠道列表
        channelList() {
            return new Promise((resolve, reject) => {
                CrmChannelManagement.queryChannel(0, 999999, '', '',
                    (data) => {
                        let items = UserPermissionService.isAllowed('crmFullPermission') ? data.content : data.content.filter(item => {
                            return item.belong == this.userName
                        })
                        this.channels = [{id: 1, name: 'personal', belong: 'personal'}].concat(items)
                        resolve(items)
                    },
                    () => {
                        // Message.error(this.$i("operationFail"));
                        reject()
                    }
                );
            })
        },

        // 各种切换处理
        eventSwitchChange(item) {
            console.log('switch change event', item.id)
            if (item.id == 'record') {
                this.currentPanel = item.id
                this.queryCustomerSettleInfo()
            } else if (item.id == 'remark') {
                this.currentPanel = item.id
                this.queryCustomerRemark()
            } else if (item.id == 'update') {
                // 更新客户信息
                if (CrmChannelManagement.countStringLength(this.form.accountTag) > 64) {
                    Message.warning('账号标签超长，最多64字符，汉字算两个字符');
                    return
                }
                if (CrmChannelManagement.countStringLength(this.form.strategyRemark) > 200) {
                    Message.warning('运行策略描述超长，最多200字符，汉字算两个字符');
                    return
                }
                if (CrmChannelManagement.countStringLength(this.form.name) > 36) {
                    Message.warning('用户名超长，最多36字符，汉字算两个字符');
                    return
                }
                if (CrmChannelManagement.countStringLength(this.form.followUser) > 64) {
                    Message.warning('跟进人员超长，最多64字符，汉字算两个字符');
                    return
                }
                this.updateCustomerInfo()
            } else if (item.id == 'append') {
                if (this.currentPanel == 'record') {
                    // 新增结算记录
                    if (CrmChannelManagement.countStringLength(this.inputRemark) > 200) {
                        Message.warning('备注超长，最多200字符，汉字算两个字符');
                        return
                    }
                    this.addCustomerSettlementInfo()
                } else if (this.currentPanel == 'remark') {
                    // 新增备注
                    if (CrmChannelManagement.countStringLength(this.inputRemark) > 200) {
                        Message.warning('备注超长，最多200字符，汉字算两个字符');
                        return
                    }
                    this.addCustomerRemark()
                }
            }
        },

        onCheckedRemove(item) {
            console.log('删除动作', item)
            if (this.currentPanel == 'record') {
                CrmChannelManagement.deleteSettleInfo(item.id,
                    () => {
                        Message.success('删除成功');
                        this.queryCustomerSettleInfo()
                    },
                    () => {
                        // Message.error(this.$i("operationFail"));
                    }
                );
            } else if (this.currentPanel == 'remark') {
                CrmChannelManagement.deleteCustomerRemark(item.id,
                    () => {
                        Message.success('删除成功');
                        this.queryCustomerRemark()
                    },
                    () => {
                        // Message.error(this.$i("operationFail"));
                    }
                );
            }
        },

        // 更新客户信息
        updateCustomerInfo() {
            var that = this
            if (that.form.platform == '' 
                || that.form.platform == '-1') {
                Message.warning('请选择当前托管交易所');
                return
            }
            if (that.form.accountNumber === '' 
                || parseInt(that.form.accountNumber) < 0) {
                Message.warning('请设置当前托管账户数量');
                return
            }
            if (isNaN(that.form.accountNumber)) {
                Message.warning('请设置正确的当前托管账户数量');
                return
            }
            if (that.form.startFundsAmount === '' 
                || parseInt(that.form.startFundsAmount) < 0) {
                Message.warning('请设置托管资金');
                return
            }
            if (isNaN(that.form.startFundsAmount)) {
                Message.warning('请设置正确的托管资金');
                return
            }
            if (that.form.coinName == '') {
                Message.warning('请选择托管资金币种');
                return
            }
            if (that.form.settlementTime == '') {
                Message.warning('请设置结算时间');
                return
            }
            return new Promise((resolve, reject) => {
                console.log(that.form)
                var crmStore = useCrmStore()
                crmStore.updateCurrentAccount(that.form).then(data => {
                    Message.success('保存成功');
                    let items = data
                    that.$emit('changed', 'personal')
                    let crmStore = useCrmStore()
                    crmStore.updateAccountListCache(that.form)
                    resolve(items)
                }, () => {
                    // Message.error(that.$i("operationFail"));
                    reject()
                })
                // CrmChannelManagement.updateCustomerInfo(that.form,
                //     (data) => {
                //         Message.success('保存成功');
                //         let items = data
                //         that.$emit('changed', 'personal')
                //         resolve(items)
                //     },
                //     () => {
                //         // Message.error(that.$i("operationFail"));
                //         reject()
                //     }
                // );
            })
        },

        // 新增结算记录
        addCustomerSettlementInfo() {
            if (this.startAmount == '') {
                Message.error('请输入初始金额');
                return
            }
            if (isNaN(this.startAmount)) {
                Message.error('请输入正确的初始金额');
                return
            }
            if (this.incomeAmount == '') {
                Message.error('请输入收益金额');
                return
            }
            if (isNaN(this.incomeAmount)) {
                Message.error('请输入正确的收益金额');
                return
            }
            if (this.nextIssueAmount == '') {
                Message.error('请输入下期金额');
                return
            }
            if (isNaN(this.nextIssueAmount)) {
                Message.error('请输入正确的下期金额');
                return
            }
            // if (this.inputRemark == '') {
            //     Message.error('请输入备注');
            //     return
            // }
            let params = {
                customerId: this.form.customerId,
                // amount: this.inputMoney,
                // remark: this.inputRemark,
                startAmount: this.startAmount,
                endAmount: this.startAmount,
                incomeAmount: this.incomeAmount,
                nextIssueAmount: this.nextIssueAmount,
            }
            return new Promise((resolve, reject) => {
                CrmChannelManagement.addCustomerSettlement(params,
                    () => {
                        Message.success('保存成功');
                        // this.inputMoney = ''
                        // this.inputRemark = ''
                        this.startAmount = ''
                        this.endAmount = ''
                        this.incomeAmount = ''
                        this.nextIssueAmount = ''
                        this.queryCustomerSettleInfo()
                        resolve()
                    },
                    () => {
                        // Message.error(this.$i("operationFail"));
                        reject()
                    }
                );
            })
        },
        // 新增备注
        addCustomerRemark() {
            if (this.inputRemark == '') {
                Message.error('请输入备注');
                return
            }
            let params = {
                customerId: this.form.customerId,
                remark: this.inputRemark
            }
            return new Promise((resolve, reject) => {
                CrmChannelManagement.addCustomerRemark(params,
                    () => {
                        Message.success('保存成功');
                        this.inputRemark = ''
                        this.queryCustomerRemark()
                        resolve()
                    },
                    () => {
                        // Message.error(this.$i("operationFail"));
                        reject()
                    }
                );
            })
        },

        // 显示备注弹框
        onActionRow(item) {
            if (this.currentPanel == 'remark') {
                let crmStore = useCrmStore()
                crmStore.setRemarkText(item.remark)
            }
        },
        onActionCell(name, item) {
            if (this.currentPanel == 'remark') {
                let crmStore = useCrmStore()
                crmStore.setRemarkText(item.remark)
            }
        },

        // 删除当前展示的账号
        onActionDelete() {
            var that = this
            console.log('删除动作', that.form)
            ConfirmProvider.openConfirm(that, '删除操作', '请确认删除该客户: ' + that.form.name, () => {
                CrmChannelManagement.deleteCustomer(that.form.id,
                    () => {
                        let crmStore = useCrmStore()
                        crmStore.deleteAccountListCache(that.form)
                    },
                    () => {
                        // Message.error(this.$i("operationFail"));
                    }
                );
            });
        },
        
    }
}
</script>

<style scoped>
.scroll-mode{
    overflow-y: auto;
}
input,select{
    outline: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    background: none;
    display: block;
    width: 3px;
    height: 3px;
    border-left: 1.5px solid #fff;
    border-bottom: 1.5px solid #fff;
    color: white;
    transform: rotate(-45deg);
    margin-right: 6px;
}

.btn-delete-flat{
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 30px;
    line-height: 30px;
    background: #3E454E;
    color: white;
    cursor: pointer;
    border-radius: 0px 8px 0px 8px;
    font-size: 12px;
}
.btn-delete-flat:hover{
    background: #ff4747;
}

</style>