<template>
  <div>
    <div class="show-more-888">
      <el-row style="margin-top: 25px">
        <el-col :span="8" v-for="item in platformArray" :key="item.COIN">
          <el-row>
            <span class="dark-mode-font">{{ item.COIN }}</span>
          </el-row>
          <el-row>
            <span class="dark-mode-font" style="color: #FFFFFF;font-size: 35px">{{
                item.VALUE.toFixed(item.SIZE)
              }}</span>
          </el-row>
          <el-row>
            <span class="dark-mode-font">{{ item.COUNT }} {{ $i('customerProfit.accounts') }}</span>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="show-less-888">
      <el-row style="margin-top: 25px" v-for="item in platformArray" :key="item.COIN">
        <el-col :span="24">
          <el-row>
            <span class="dark-mode-font">{{ item.COIN }}</span>
          </el-row>
          <el-row>
            <span class="dark-mode-font" style="color: #FFFFFF;font-size: 35px">{{
                item.VALUE.toFixed(item.SIZE)
              }}</span>
          </el-row>
          <el-row>
            <span class="dark-mode-font">{{ item.COUNT }} {{ $i('customerProfit.accounts') }}</span>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

export default {
  name: "CustomerProfit",
  data: function () {
    return {
      platform: {
        USD: {
          COUNT: 0,
          VALUE: 0,
          SIZE: 2
        },
        BTC: {
          COUNT: 0,
          VALUE: 0,
          SIZE: 3
        },
        ETH: {
          COUNT: 0,
          VALUE: 0,
          SIZE: 3
        }
      },
      platformArray: []
    }
  },
  methods: {
    activePanel(instanceData) {
      this.$nextTick(() => {
        this.loadInstance(instanceData);
      })
    },
    loadInstance(instanceData) {
      let that = this;
      let platformTmp = {
        USD: {
          COUNT: 0,
          VALUE: 0,
          SIZE: 2
        },
        BTC: {
          COUNT: 0,
          VALUE: 0,
          SIZE: 3
        },
        ETH: {
          COUNT: 0,
          VALUE: 0,
          SIZE: 3
        }
      };
      instanceData.forEach((item) => {
        item.tradeAccountList.forEach((account) => {
          if (account.baseCoin.indexOf('USD') > -1 || account.baseCoin.indexOf('_U') > -1) {
            platformTmp.USD.COUNT = platformTmp.USD.COUNT + 1;
            platformTmp.USD.VALUE = platformTmp.USD.VALUE + (account.currentBalance - account.originBalance);
          } else {
            platformTmp[account.baseCoin].COUNT = platformTmp[account.baseCoin].COUNT + 1;
            platformTmp[account.baseCoin].VALUE = platformTmp[account.baseCoin].VALUE + (account.currentBalance - account.originBalance);
          }
        });
      });
      for (let platformTmpKey in platformTmp) {
        that.$set(that.platformArray, that.platformArray.length, {
          COIN: platformTmpKey,
          SIZE: platformTmp[platformTmpKey].SIZE,
          VALUE: platformTmp[platformTmpKey].VALUE,
          COUNT: platformTmp[platformTmpKey].COUNT
        });
      }
    }
  }
}
</script>

<style scoped>

@media only screen and (min-width: 888px) {
  .show-less-888 {
    display: none !important
  }
}

@media only screen and (max-width: 888px) {
  .show-more-888 {
    display: none !important
  }
}

</style>