<script lang="javascript">
import param from "@/paras/Paras";

const trans = {
  lang: '한국인',
  copyright: 'Copyright © 2022 ' + param.getCompanyDisplayValue(),
  lang_china: '繁體中文',
  lang_english: 'English',
  lang_japan: '日本',
  lang_korea: '한국인',
  lang_thailand: 'ไทย',
  changePassword: '비밀번호 변경',
  manageAccount: '계정 관리',
  pleaseLoginFirst: '먼저 로그인을 해주세요',
  week: {
    Sun: 'Sun',
    Mon: 'Mon',
    Tue: 'Tue',
    Wed: 'Web',
    Thu: 'Thu',
    Fri: 'Fri',
    Sat: 'Sat'
  },
  login: {
    login: '로그인',
    processing: '처리...',
    subTitle: '통합 인증 관리 플랫폼',
    logout: '로그아웃',
    register: '등록하다',
    email: '우편',
    username: '사용자 이름',
    password: '비밀번호',
    forgetPwd: '비밀번호를 잊으 셨나요',
    start: '로그인',
    dontHaveAccount: '아직 계정이 없습니다',
    loginSuccess: '당신은 성공적으로 로그인했습니다',
    logoutSuccess: '현재 계정에서 로그아웃되었습니다',
    loginTitle: '로그인 알림'
  },
  navBars: {
    controlPanel: '제어판',
    accountMgnt: '계정 관리',
    tradeMgnt: '집계 거래 센터',
    riskMgnt: '위기 관리',
    testMgnt: '테스트 센터',
    documentMgnt: '문서 센터',
    channelMgnt: '고객 관계',
    wallet: '지갑'
  },
  permission: {
    SUPER_ADMIN: '최고 관리자',
    ADMIN: '관리자'
  },
  controlPanelHome: {
    lastLoginTime: ', 마지막 로그인 시간： ',
    allAccountOperation: '모두의 활동',
    queryAll: '모두보기',
    tradeTimes: '누적 거래 시간',
    tradeAmounts: '누적 회전율',
    tradeAmount30: '지난 30일 동안의 거래량',
    errorPosition: '잘못된 위치 알림',
    message: '비공개 메시지 알림',
    groupMessage: '그룹 메시지 알림',
    system7Vol: '거래량',
    unreadMsg: '사적인 편지',
    unreadGroupMsg: '그룹 메시지',
    taskConfig: '작업 구성',
    pendingTask: '처리되지 않은 작업'
  },
  tradeMgnt: {
    load: '짐',
    kPanel: {
      open: '열려 있는',
      close: '받다',
      high: '높은',
      low: '낮은',
      change: '견적 변경',
      amplitude: '진폭',
      vol: '용량'
    },
    infoPanel: {
      change24: '24h기복',
      low24: '24h최저 가격',
      high24: '24h최고가',
      vol24: '24h용량',
      volU24: '24h회전율'
    },
    klineType: {
      m1: '1분',
      m5: '5분',
      m15: '15분',
      m30: '30분',
      h1: '1시간',
      h2: '2시간',
      h4: '4시간',
      d1: '1일',
      week: '일주'
    }
  }
}

export default {
  trans
}

</script>