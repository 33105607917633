<script type="text/javascript">

import ServiceMapper from "@/const/ServiceMapper";
import AbmNet from "@/api/net/AbmNet";

function aggOrderBook(platform, kind, symbol, success, error) {
  AbmNet.getRequestWithParams(ServiceMapper.DATA_CENTER.orderbook.replaceAll('{platform}', platform).replaceAll('{kind}', kind) + '?symbol=' + symbol + '&depth=10', {}, function (data) {
    success(data);
  }, function (err) {
    if (error) {
      error(err);
    }
  });
}

function lastTrades(platform, kind, symbol, success, error) {
  AbmNet.getRequestWithParams(ServiceMapper.DATA_CENTER.lastTrades.replaceAll('{platform}', platform).replaceAll('{kind}', kind) + '?symbol=' + symbol + '&limit=100', {}, function (data) {
    success(data);
  }, function (err) {
    if (error) {
      error(err);
    }
  });
}

function markPrice(platform, kind, symbol, success, error) {
  AbmNet.getRequestWithParams(ServiceMapper.DATA_CENTER.markPrices.replaceAll('{platform}', platform).replaceAll('{kind}', kind) + '?symbol=' + symbol, {}, function (data) {
    success(data);
  }, function (err) {
    if (error) {
      error(err);
    }
  });
}

function lastPriceIn24hr(platform, kind, symbol, success, error) {
  AbmNet.getRequestWithParams(ServiceMapper.DATA_CENTER.lastPriceIn24hr.replaceAll('{platform}', platform).replaceAll('{kind}', kind) + (symbol === '' ? '' : '?symbol=' + symbol), {}, function (data) {
    success(data);
  }, function (err) {
    if (error) {
      error(err);
    }
  });
}

function config(success, error) {
  AbmNet.getRequestWithParams(ServiceMapper.DATA_CENTER.tvConfig, {}, function (data) {
    success(data);
  }, function (err) {
    if (error) {
      error(err);
    }
  });
}

function klines(exchange, kind, symbol, resolution, from, to, limit, last, success, error) {
  if (!kind) {
    kind = 'future'
  }
  let params = {
    symbol,
    windowSize: resolution,
    startTime: from,
    startTimeString: new Date(from * 1000).toISOString().substring(0, 16).replace('T', ' ')
  }
  if (to) {
    params.endTime = to
    params.endTimeString = new Date(to * 1000).toISOString().substring(0, 16).replace('T', ' ')
  }
  if (limit) {
    params.limit = limit
  }
  if (last) {
    params.last = last
  }
  AbmNet.postRequest('/api-datacenter/api/' + exchange + '/' + kind + '/klines?limit=' + limit + '&last=' + last, params, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

export default {
  config,
  klines,
  aggOrderBook,
  lastTrades,
  markPrice,
  lastPriceIn24hr
}

</script>