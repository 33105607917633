<template>
  <div>
    <el-row class="hidden-sm-and-down" style="margin-top: 32px; width: 100%;position: static;">
      <el-col :span="14">
        <!-- 选择源地址 -->
        <FtWalletAssembleSourceAddressPanel ref="asap" v-on:switch-change="onAction" />
        <!-- 选择目标地址 -->
        <FtWalletAssembleTargetAddressPanel ref="atap" v-on:switch-change="onAction" style="margin-top: 20px;"/>
      </el-col>
      <el-col :span="10">
        <div style="border-left: 1px solid #3E454E; margin-left: 30px; padding-left: 30px;">
          <FtWalletAddressOftenPanel ref="aop" v-on:switch-change="onAction" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {Message} from "element-ui";
import ConfirmProvider from '@/components/ConfirmProvider'
import UserPermissionService from "@/api/user/UserPermissionService";
import FtWalletAssembleSourceAddressPanel from "@/components/FtWalletAssembleSourceAddressPanel";
import FtWalletAssembleTargetAddressPanel from "@/components/FtWalletAssembleTargetAddressPanel";
import FtWalletAddressOftenPanel from "@/components/FtWalletAddressOftenPanel";
import TrialphaLoading from "@/components/TrialphaLoading";

import { useWalletStore } from '@/store/wallet'

export default {
  components: {
    FtWalletAssembleSourceAddressPanel,
    FtWalletAssembleTargetAddressPanel,
    FtWalletAddressOftenPanel
  },
  data: function () {
    return {


    }
  },
  mounted() {

    this.refresh()

  },
  methods: {
    hasPermission(featureName) {
      return UserPermissionService.isAllowed(featureName);
    },

    refresh() {
      let that = this
      console.log('刷新页面=====')
      let loading = TrialphaLoading.loading(that)
      const walletStore = useWalletStore()
      walletStore.listCommonUsedWallet()
      walletStore.listWalletCreatedByRequester()
      walletStore.listWalletApprovedByRequester()
      walletStore.listAllChainTokenMappings()
      
      this.$refs.asap.eachWalletMoney(false).then(() => {
        setTimeout(() => {
          loading.close()
        }, 1000)
      })
    },

    resetFormData() {
      console.log('清理表单=====')
        this.$refs.asap.resetFormData()
        this.$refs.atap.resetFormData()
        this.$refs.aop.resetFormData()
    },

    // 各种切换处理
    onAction(item) {
      let that = this
      if (item.id == 'confirm') {
        // 
        let sourceData = this.$refs.asap.getFormData()
        let targetData = this.$refs.atap.getFormData()
        console.log('转出', sourceData)
        console.log('转入', targetData)

        // 转出地址检查
        if (sourceData.length == 0) {
          Message.error('请添加转出地址')
          return
        }
        let address = false
        sourceData.forEach(item => {
          if (!item.sourceWallet) {
            address = true
          }
        })
        if (address) {
          Message.error('请设置转出地址')
          return
        }
        // 代币检查
        let coin = false
        sourceData.forEach(item => {
          if (!item.coinType) {
            coin = true
          }
        })
        if (coin) {
          Message.error('请设置转出代币')
          return
        }
        // 代币检查, 是否有不同的代币出现
        let coinDiff = []
        sourceData.forEach(item => {
          if (coinDiff.indexOf(item.coinType) == -1) {
            coinDiff.push(item.coinType)
          }
        })
        if (coinDiff.length > 1) {
          Message.error('转出代币需要为相同类型')
          return
        }
        // 转出数量检查
        let zero = false
        sourceData.forEach(item => {
          if (isNaN(item.count)) {
            zero = true
          }
        })
        if (zero) {
          Message.error('转出数量无效')
          return
        }
        zero = false
        sourceData.forEach(item => {
          if (item.count == 0) {
            zero = true
          }
        })
        if (zero) {
          Message.error('转出数量为0')
          return
        }
        // let overMax = false
        // sourceData.forEach(item => {
        //   if (item.count > item.countMax && item.countMax > 0) {
        //     overMax = true
        //   }
        // })
        // if (overMax) {
        //   Message.error('转出数量超出')
        //   return
        // }

        // 转入地址检查
        if (targetData.addressList[0].display == '') {
          Message.error('请设置目标地址')
          return
        }

        // 备注检查
        if (targetData.remark == '') {
          Message.error('请输入备注信息')
          return
        }

        let walletStore = useWalletStore()
        // 检查是否满足当前币种的转账数量
        walletStore.checkTransferAmount(sourceData, targetData, targetData.remark).then(errors => {
          if (errors.length > 0) {
            // 提示信息
            that.$refs.asap.setTransferAmountCheckResult(errors)
            return
          }
          ConfirmProvider.openConfirm(this, '确认操作', '请确认转账', () => {
            let walletStore = useWalletStore()
            walletStore.createTransferAssemble(sourceData, targetData.addressList[0], targetData.remark).then(() => {
              that.resetFormData()
              Message.success('发起转账成功')
              that.$emit('switch-change', {id: 'Examine'})
            }, () => {
              Message.error('发起转账失败')
            })
          });
        })
      } else if (item.id == 'transferAmountCheck') {
        // 
        let sourceData = this.$refs.asap.getFormData()
        let targetData = this.$refs.atap.getFormData()
        console.log('转出', sourceData)
        console.log('转入', targetData)

        // 转出地址检查
        if (sourceData.length == 0) {
          Message.error('请添加转出地址')
          return
        }
        let address = false
        sourceData.forEach(item => {
          if (!item.sourceWallet) {
            address = true
          }
        })
        if (address) {
          Message.error('请设置转出地址')
          return
        }
        // 代币检查
        let coin = false
        sourceData.forEach(item => {
          if (!item.coinType) {
            coin = true
          }
        })
        if (coin) {
          Message.error('请设置转出代币')
          return
        }
        // 转出数量检查
        let zero = false
        sourceData.forEach(item => {
          if (isNaN(item.count)) {
            zero = true
          }
        })
        if (zero) {
          Message.error('转出数量无效')
          return
        }

        let walletStore = useWalletStore()
        // 检查是否满足当前币种的转账数量
        walletStore.checkTransferAmount(sourceData, targetData, targetData.remark).then(errors => {
          that.$refs.asap.setTransferAmountCheckResult(errors)
        })
      } else if (item.id == 'commonUsedWalletChecked') {
        // 添加目标地址
        this.$refs.atap.pushCommonUsedWallet(item.value)
      } else if (item.id == 'commonUsedWalletUnchecked') {
        // 取消目标地址
        this.$refs.atap.popCommonUsedWallet(item.value)
      } else if (item.id == 'removeTargetWallet') {
        // 取消常用钱包
        console.log('===取消常用钱包', item.value)
        if (item.value) {
          this.$refs.aop.uncheckedCommonUsedWallet(item.value)
        }
      } else {
        this.$emit('switch-change', item)
      }
    }

  }
}
</script>

<style>


</style>