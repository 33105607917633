<template>
  <div class="panel-scoped">

    <div style="display: flex;" v-for="(item, index) in addressList" :key="index">
      <div style="flex: 1;">

        <div style="display: flex;">
          <div>
            <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">选择目标地址</label>
          </div>
          <div style="flex: 1;">
            <FtInputBoxWidget v-model="item.display" :displayText="item.displayText" placeholder="请输入目标地址" style="margin-left: 0px;" />

            <FtInputBoxWidget v-model="form.remark" placeholder="请输入备注信息" style="margin-left: 0px; margin-top: 20px;" />
          </div>
        </div>
        
      </div>
      <div>
        <FtGroupButtonWidget :buttons="[{
            id: 'confirm',
            text: '确认转出',
            textI18n: '',
        }]" mode="single" v-on:switch-change="onAction" 
        style="margin-left: 16px; background: #3E454E;" />
      </div>
    </div>



  </div>
</template>

<script>
// import {Message} from "element-ui";
// import ConfirmProvider from '@/components/ConfirmProvider'

// import CrmChannelManagement from "@/api/crm/CrmChannelManagement";
import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";
import FtInputBoxWidget from "@/components/widgets/FtInputBoxWidget";

export default {
  components: {
    FtGroupButtonWidget,
    FtInputBoxWidget
  },
  props: {},
  data() {
    return {
      form: {
        remark: ''
      },
      // 交易所列表
      addressList: [
        { display: '', count: 0, walletType: 'dex', bind: null},
      ],
    }
  },
  computed: {},
  mounted() {

  },
  methods: {

    // 各种切换处理
    onAction(item) {
      if (item.id == 'addAddress') {
          this.addressList.push({ display: '', count: 0, walletType: 'dex', bind: null})
      }
      else if (item.id == 'confirm') {
        this.$emit('switch-change', item)
      }
    },
    getFormData() {
      return {
        addressList: this.addressList,
        remark: this.form.remark
      }
    },
    resetFormData() {
      // 表单数据
      this.form = {
        remark: ''
      }
      // 交易所列表
      this.addressList = [
        { display: '', count: 0, walletType: 'dex', bind: null},
      ]
    },
    pushCommonUsedWallet(commonUsedWallet) {
      if (this.addressList.length) {
        this.$emit('switch-change', {id: 'removeTargetWallet', value: this.addressList[0].bind})
        this.addressList.pop()
      }
      if (commonUsedWallet.walletType == "CEX") {
        this.addressList.push({
          display: commonUsedWallet.apiKey, 
          displayText: (commonUsedWallet.humanReadableName ? commonUsedWallet.humanReadableName + ' : ' + commonUsedWallet.apiKey : commonUsedWallet.apiKey), 
          count: 0, 
          walletType: 'cex', 
          bind: commonUsedWallet
        })
      } else if (commonUsedWallet.walletType == "DEX") {
        this.addressList.push({
          display: commonUsedWallet.address, 
          displayText: (commonUsedWallet.humanReadableName ? commonUsedWallet.humanReadableName + ' : ' + commonUsedWallet.address : commonUsedWallet.address) + ' : ' + commonUsedWallet.dexChain, 
          count: 0, 
          walletType: 'dex', 
          bind: commonUsedWallet
        })
      }
    },
    popCommonUsedWallet() {
      if (this.addressList.length) {
        this.$emit('switch-change', {id: 'removeTargetWallet', value: this.addressList[0].bind})
        this.addressList.pop()
      }
      this.addressList.push({ display: '', count: 0, walletType: 'dex', bind: null})
    }

  }
}
</script>

<style scoped>
.panel-scoped{
  background: #22272E;
  padding: 20px;
  border: 1px solid #3E454E;
  border-radius: 8px;
}
</style>