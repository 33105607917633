<script>

import chinese from "@/resources/i18n-chinese"
import english from "@/resources/i18n-english"
import japan from '@/resources/i18n-japan'
import korea from '@/resources/i18n-korea'
import Optional from "@/optional/Optional";

let defaultLang = 'English'

let lang = localStorage.getItem('Language') || defaultLang;

let trans = {
  China: chinese.trans,
  English: english.trans,
  Japan: japan.trans,
  Korea: korea.trans
}

export default {
  lang,
  install(Vue) {

    Vue.prototype.setLanguage = function (language) {
      if (lang !== language) {
        lang = language;
        localStorage.setItem('Language', language);
        location.reload();
      }
    };

    Vue.prototype.$i = function (key) {
      return Optional.orElse(() => {
        return getLocalization(key);
      }, getDefaultLocalization(key));
    }

    function getLocalization(key) {
      let transObj = Optional.orElse(() => {
        return trans[lang];
      }, trans[defaultLang]);
      if (key.indexOf('.') > -1) {
        let obj;
        let keys = key.split('.');
        for (let index in keys) {
          obj = transObj[keys[index]];
          transObj = obj;
        }
        return obj;
      } else {
        return transObj[key];
      }
    }

    function getDefaultLocalization(key) {
      let transObj = trans[defaultLang];
      if (key.indexOf('.') > -1) {
        let obj;
        let keys = key.split('.');
        for (let index in keys) {
          obj = transObj[keys[index]];
          transObj = obj;
        }
        return obj;
      } else {
        return transObj[key];
      }
    }

  }
}

</script>