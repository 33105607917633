<template>
  <div style="width: 100%;height: 300px">
    <div id="id-ahr999Chart" ref="ahr999Chart" style="height: 100%;z-index: 1"></div>
    <el-row style="position: absolute;margin-top: -186px;width: 100%;z-index: 0">
      <el-col :span="24">
        <span class="dark-mode-font" style="color: #343947;font-size: 38px" v-if="!loadingData">AHR999</span>
        <div class="trialpha-loading" v-if="loadingData" style="margin-left: calc(50% - 22px)">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import DataApiService from "@/api/trade/DataApiService";

export default {
  name: 'ahr999',
  components: {},
  data: function () {
    return {
      color: {},
      loadingData: true,
      timeList: [],
      price: [],
      timeInterval: {}
    }
  },
  created: function () {
    this.loadingData = true;
    this.render();
    this.timeInterval = setInterval(this.render, 60 * 1000);
  },
  destroyed: function () {
    clearInterval(this.timeInterval);
  },
  methods: {
    getProfitOptionOnlyProfit(xTimeList, price, avg, ahr999, dcaLine, lowestLine) {
      let series = [{
        showSymbol: false,
        name: 'BTC-Price',
        type: 'line',
        data: price,
        smooth: true,
        lineStyle: {
          color: 'rgb(247,147,26)',
          width: 1,   //这里是坐标轴的宽度,可以去掉
        }
      }, {
          showSymbol: false,
          name: 'Ahr999',
          type: 'line',
          yAxisIndex: 1,
          data: ahr999,
          smooth: true,
          itemStyle: {
            normal: {
              areaStyle: {
                type: 'default'
              },
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [{
                    offset: 0,
                    color: 'rgb(30,172,238,0.1)'
                  },
                    {
                      offset: 0.5,
                      color: 'rgba(30,172,238, 0.05)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(30,172,238, 0.01)'
                    }
                  ]
              ),
              lineStyle: {        // 系列级个性化折线样式
                width: 1,
                type: 'solid',
                color: "rgb(18,177,250)"
              },
              opacity: 0
            }
          },
        }, {
          showSymbol: false,
          name: '200-DCA',
          type: 'line',
          data: avg,
          smooth: true,
          lineStyle: {
            color: '#75718d',
            width: 1,   //这里是坐标轴的宽度,可以去掉
          }
        }, {
          showSymbol: false,
          name: 'DCA-Line',
          type: 'line',
          yAxisIndex: 1,
          data: dcaLine,
          smooth: true,
          lineStyle: {
            color: '#88f165',
            width: 1,   //这里是坐标轴的宽度,可以去掉
          }
        }, {
          showSymbol: false,
          name: '抄底线',
          type: 'line',
          yAxisIndex: 1,
          data: lowestLine,
          smooth: true,
          itemStyle: {
            normal: {
              areaStyle: {
                type: 'default'
              },
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [{
                    offset: 0,
                    color: 'rgb(165,39,39,0.1)'
                  },
                    {
                      offset: 0.5,
                      color: 'rgba(165,39,39, 0.05)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(165,39,39, 0.01)'
                    }
                  ]
              ),
              lineStyle: {        // 系列级个性化折线样式
                width: 1,
                type: 'solid',
                color: "rgb(165,39,39)"
              },
              opacity: 0
            }
          },
        }];
      return {
        color: ['#F7931A', '#75718D', '#12B1FA', '#88F165', '#A52727'],
        legend: {
          data: ['BTC-Price', 'Ahr999', '200-DCA', 'DCA-Line', '抄底线'],
          textStyle: {
            color: 'white',
            fontSize: 10
          }
        },
        calculable: true,
        tooltip: {
          show: true, // 是否显示
          trigger: 'axis', // 触发类型  'item'图形触发：散点图，饼图等无类目轴的图表中使用； 'axis'坐标轴触发；'none'：什么都不触发。
          axisPointer: { // 坐标轴指示器配置项。
            type: 'cross', // 'line' 直线指示器  'shadow' 阴影指示器  'none' 无指示器  'cross' 十字准星指示器。
            axis: 'auto', // 指示器的坐标轴。
            snap: true, // 坐标轴指示器是否自动吸附到点上
          }
        },
        xAxis: [{
          type: 'category',
          boundaryGap: true,
          show: true,
          data: xTimeList,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: 8, //更改坐标轴文字大小
              color: 'white'
            }
          },
        }],
        grid: {
          x: 20,
          y: 50,
          x2: 40,
          y2: 60
        },
        yAxis: [{
          type: 'value',
          position: 'right',
          show: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: 'white', //更改坐标轴文字颜色
              fontSize: 8 //更改坐标轴文字大小
            }
          },
        }, {
            type: 'value',
            position: 'left',
            axisLine: {
              show: true,
              lineStyle: {
                color: '#444c56',
                width: 1,   //这里是坐标轴的宽度,可以去掉
              }
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: '#444c56',
                width: 1,   //这里是坐标轴的宽度,可以去掉
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: 'white', //更改坐标轴文字颜色
                fontSize: 8 //更改坐标轴文字大小
              }
            }
          }],
        dataZoom: [
          {
            show: true,
            startValue: price.length - 368,
            endValue: price.length - 1,
            textStyle: {
              color: '#8392A5'
            },
            dataBackground: {
              areaStyle: {
                color: '#8392A5'
              },
              lineStyle: {
                opacity: 0.8,
                color: '#8392A5'
              }
            }
          },
          {
            type: 'inside'
          }
        ],
        series: series
      };
    },
    timestampToDate(timestamp) {
      let date = new Date(Number(timestamp));
      let Y = date.getFullYear() + '-';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let D = date.getDate() + ' ';
      let h = date.getHours() + ':';
      let m = date.getMinutes() + ':';
      let s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    render: function () {
      let that = this;
      let xTimeList = [];
      let avg = [];
      let price = [];
      let ahr999 = [];
      let dcaLine = [];
      let lowestLine = [];
      DataApiService.getAhr999(function (data) {
        data.data.forEach((obj) => {
          xTimeList.push(obj.date);
          avg.push((obj.avg));
          ahr999.push(obj.ahr999);
          price.push((obj.value));
          dcaLine.push(1.2);
          lowestLine.push(0.45);
        });
        if (!that.ahr999Chart && that.$refs.ahr999Chart) {
          that.ahr999Chart = that.$echarts.init(that.$refs.ahr999Chart);
          that.ahr999Chart.clear();
          window.addEventListener('resize', () => (that.ahr999Chart.resize()));
        }
        that.ahr999Chart.setOption(that.getProfitOptionOnlyProfit(xTimeList, price, avg, ahr999, dcaLine, lowestLine));
        that.loadingData = false;
      });
    }
  }
}
</script>