var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-customer-dialog",style:([_vm.show ? 'display: block;' : 'display: none;'])},[_c('div',{staticClass:"create-customer-dialog-content"},[_c('div',{staticClass:"create-customer-dialog-content-inner"},[_c('div',{staticStyle:{"position":"absolute","top":"-46px","right":"-46px","width":"32px","height":"32px","background":"#3E454E","color":"white","cursor":"pointer","border-radius":"16px","font-size":"19px","padding":"3px"},on:{"click":function($event){return _vm.onAction({id: 'close'})}}},[_c('i',{staticClass:"el-icon-close"})]),_c('el-row',{staticClass:"hidden-sm-and-down",staticStyle:{"width":"100%","position":"static","margin-bottom":"20px"}},[_c('el-col',{staticStyle:{"text-align":"left"},attrs:{"span":24}},[_c('FtGroupButtonWidget',{attrs:{"buttons":[
                        {
                            id: 'detail',
                            text: '转账审批详情',
                            textI18n: '',
                            active: true
                        }
                    ]}})],1)],1),_c('el-row',{staticClass:"hidden-sm-and-down",staticStyle:{"margin-top":"10px","width":"100%","position":"static"}},[_c('el-col',{staticStyle:{"text-align":"center"},attrs:{"span":12}},[_c('label',{staticStyle:{"color":"#999999","font-size":"14px","line-height":"30px"}},[_vm._v(" 用户名 ")])]),_c('el-col',{staticStyle:{"text-align":"center"},attrs:{"span":12}},[_c('label',{staticStyle:{"color":"#999999","font-size":"14px","line-height":"30px"}},[_vm._v(" 状态 ")])])],1),_vm._l((_vm.userMappings),function(item){return _c('el-row',{key:item.user,staticClass:"hidden-sm-and-down",staticStyle:{"margin-top":"10px","width":"100%","position":"static"}},[_c('el-col',{staticStyle:{"text-align":"center"},attrs:{"span":12}},[_vm._v(" "+_vm._s(item.user)+" ")]),_c('el-col',{staticStyle:{"text-align":"center"},attrs:{"span":12}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)}),_c('el-row',{staticClass:"hidden-sm-and-down",staticStyle:{"margin-top":"20px","width":"100%","position":"static"}},[_c('el-col',{attrs:{"span":24}},[_c('FtGroupButtonWidget',{staticStyle:{"float":"none","display":"inline-block","margin":"auto","background":"#3E454E"},attrs:{"buttons":[{
                        id: 'close',
                        icon: '',
                        text: '确定',
                        textI18n: '',
                    }],"mode":"single"},on:{"switch-change":_vm.onAction}})],1)],1)],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }