<template>
  <div>
    <el-drawer
        class="open-task-trade-tools"
        size="368px"
        :modal="true"
        append-to-body
        :close-on-click-modal=false
        :close-on-press-escape=false
        :wrapperClosable=false
        custom-class="black-card"
        :visible.sync="drawer"
        :with-header="false"
        direction="btt">
      <el-button class="dark-mode-btn" style="width: 100%;height: 30px;margin-top: 0;border-right: 0;border-top: 0;border-left: 0;border-radius: 0;font-size: 23px;padding: 0" @click="close" :loading="processing"><i class="el-icon-close" /></el-button>
      <!--
      <div v-if="operation === 'SWAP'">
        <el-row style="margin-top: 25px;width: 100%">
          <el-col :span="12" :offset="6">
            <el-row>
              <el-col :span="12">
                <span class="dark-mode-font" style="display: inline-block;float: left;margin-top: 8px">Wallet Token</span>
              </el-col>
              <el-col :span="12">
                <el-select class="select-symbol" style="width: 100%;float: left" v-model="swap.queryBalanceToken" placeholder="请选择" @change="swapTokenChange">
                  <el-option
                      v-for="item in swap.walletOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 15px;text-align: center">
          <el-col :span="12" :offset="6">
            <el-row>
              <el-col :span="12">
                <span class="dark-mode-font" style="display: inline-block;float: left;margin-top: 8px">Wallet Token Balance</span>
              </el-col>
              <el-col :span="12">
                <el-input class="trialpha-dark-input-no-pre" style="width: 100%;float: left" :value="swap.queryTokenBalance" disabled/>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 15px;text-align: center">
          <el-col :span="12" :offset="6">
            <el-row>
              <el-col :span="12">
                <span class="dark-mode-font" style="display: inline-block;float: left;margin-top: 8px">Wallet Token Swap To</span>
              </el-col>
              <el-col :span="12">
                <el-input class="trialpha-dark-input-no-pre" style="width: 100%;float: left" v-model="swap.tokenOutAddress" />
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 15px;text-align: center">
          <el-col :span="12" :offset="6">
            <el-row>
              <el-col :span="12">
                <span class="dark-mode-font" style="display: inline-block;float: left;margin-top: 8px">Wallet Token Swap Amount</span>
              </el-col>
              <el-col :span="12">
                <el-input class="trialpha-dark-input-no-pre" style="width: 100%;float: left" v-model="swap.tokenInAmount" />
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 15px;text-align: center">
          <el-col :span="12" :offset="6">
            <el-row>
              <el-col :span="12">
                <span class="dark-mode-font" style="display: inline-block;float: left;margin-top: 8px">Accept Max Slippage</span>
              </el-col>
              <el-col :span="12">
                <el-input class="trialpha-dark-input-no-pre" style="width: 100%;float: left" v-model="swap.maxSlippage" />
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 15px;text-align: center">
          <el-col :span="12" :offset="6">
            <el-row>
              <el-button class="dark-mode-btn" style="width: 100%;height: 36px;margin-top: 0;border-radius: 3px;font-size: 13px;padding: 0" @click="close" :loading="processing">Confirmed</el-button>
            </el-row>
          </el-col>
        </el-row>
      </div>
      -->

      <div v-if="operation === 'TRANSFER'">
        <el-row style="margin-top: 35px;width: 100%">
          <el-col :span="12" :offset="6">
            <el-row>
              <el-col :span="12">
                <span class="dark-mode-font" style="display: inline-block;float: left;margin-top: 8px">Wallet Token</span>
              </el-col>
              <el-col :span="12">
                <el-select class="select-symbol" style="width: 100%;float: left" v-model="transfer.queryBalanceToken" placeholder="请选择" @change="transferTokenChange">
                  <el-option
                      v-for="item in transfer.walletOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px;text-align: center">
          <el-col :span="12" :offset="6">
            <el-row>
              <el-col :span="12">
                <span class="dark-mode-font" style="display: inline-block;float: left;margin-top: 8px">Wallet Token Balance</span>
              </el-col>
              <el-col :span="12">
                <el-input class="trialpha-dark-input-no-pre" style="width: 100%;float: left" :value="transfer.queryTokenBalance" disabled/>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px;text-align: center">
          <el-col :span="12" :offset="6">
            <el-row>
              <el-col :span="12">
                <span class="dark-mode-font" style="display: inline-block;float: left;margin-top: 8px">Wallet Token Transfer To</span>
              </el-col>
              <el-col :span="12">
                <el-input class="trialpha-dark-input-no-pre" style="width: 100%;float: left" v-model="transfer.transferTo" />
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px;text-align: center">
          <el-col :span="12" :offset="6">
            <el-row>
              <el-col :span="12">
                <span class="dark-mode-font" style="display: inline-block;float: left;margin-top: 8px">Wallet Token Transfer Amount</span>
              </el-col>
              <el-col :span="12">
                <el-input class="trialpha-dark-input-no-pre" style="width: 100%;float: left" v-model="transfer.transferAmount" />
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px;text-align: center">
          <el-col :span="12" :offset="6">
            <el-row>
              <el-button class="dark-mode-btn" style="width: 100%;height: 36px;margin-top: 0;border-radius: 3px;font-size: 13px;padding: 0" @click="transferToken" :loading="processing">Confirmed</el-button>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <div v-if="operation === 'DEPOSIT'">
        <el-row style="margin-top: 55px;width: 100%">
          <el-col :span="12" :offset="6">
            <el-row>
              <el-col :span="12">
                <span class="dark-mode-font" style="display: inline-block;float: left;margin-top: 8px">Wallet Token</span>
              </el-col>
              <el-col :span="12">
                <el-select class="select-symbol" style="width: 100%;float: left" v-model="deposit.queryBalanceToken" placeholder="请选择" @change="depositTokenChange">
                  <el-option
                      v-for="item in deposit.walletOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px;text-align: center">
          <el-col :span="12" :offset="6">
            <el-row>
              <el-col :span="12">
                <span class="dark-mode-font" style="display: inline-block;float: left;margin-top: 8px">Wallet Token Balance</span>
              </el-col>
              <el-col :span="12">
                <el-input class="trialpha-dark-input-no-pre" style="width: 100%;float: left" :value="deposit.queryTokenBalance" disabled/>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px;text-align: center">
          <el-col :span="12" :offset="6">
            <el-row>
              <el-col :span="12">
                <span class="dark-mode-font" style="display: inline-block;float: left;margin-top: 8px">Wallet Token Deposit Amount</span>
              </el-col>
              <el-col :span="12">
                <el-input class="trialpha-dark-input-no-pre" style="width: 100%;float: left" v-model="deposit.depositAmount" />
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px;text-align: center">
          <el-col :span="12" :offset="6">
            <el-row>
              <el-button class="dark-mode-btn" style="width: 100%;height: 36px;margin-top: 0;border-radius: 3px;font-size: 13px;padding: 0" @click="depositToken" :loading="processing">Confirmed</el-button>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <div v-if="operation === 'WITHDRAW'">
        <el-row style="margin-top: 55px;width: 100%">
          <el-col :span="12" :offset="6">
            <el-row>
              <el-col :span="12">
                <span class="dark-mode-font" style="display: inline-block;float: left;margin-top: 8px">Contract Token</span>
              </el-col>
              <el-col :span="12">
                <el-select class="select-symbol" style="width: 100%;float: left" v-model="withdraw.queryBalanceToken" placeholder="请选择" @change="withdrawTokenChange">
                  <el-option
                      v-for="item in withdraw.walletOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px;text-align: center">
          <el-col :span="12" :offset="6">
            <el-row>
              <el-col :span="12">
                <span class="dark-mode-font" style="display: inline-block;float: left;margin-top: 8px">Contract Token Balance</span>
              </el-col>
              <el-col :span="12">
                <el-input class="trialpha-dark-input-no-pre" style="width: 100%;float: left" :value="withdraw.queryTokenBalance" disabled/>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px;text-align: center">
          <el-col :span="12" :offset="6">
            <el-row>
              <el-col :span="12">
                <span class="dark-mode-font" style="display: inline-block;float: left;margin-top: 8px">Contract Token Withdraw Amount</span>
              </el-col>
              <el-col :span="12">
                <el-input class="trialpha-dark-input-no-pre" style="width: 100%;float: left" v-model="withdraw.withdrawAmount" />
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px;text-align: center">
          <el-col :span="12" :offset="6">
            <el-row>
              <el-button class="dark-mode-btn" style="width: 100%;height: 36px;margin-top: 0;border-radius: 3px;font-size: 13px;padding: 0" @click="withdrawToken" :loading="processing">Confirmed</el-button>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </el-drawer>
  </div>
</template>

<script>

import UniPlusService from "@/api/trade/strategy/UniPlusService.vue";
import ConfirmProvider from "@/components/ConfirmProvider.vue";
import {Message} from "element-ui";

export default {
  name: "WalletOperation",
  data: function () {
    return {
      drawer: false,
      processing: false,
      ins: {},
      operation: 'TRANSFER',
      transfer: {
        walletOptions: [],
        queryBalanceToken: '',
        queryTokenBalance: 0,
        transferTo: '',
        transferAmount: 0,
        isNativeToken: false,
      },
      deposit: {
        walletOptions: [],
        queryBalanceToken: '',
        queryTokenBalance: 0,
        depositAmount: 0
      },
      withdraw: {
        walletOptions: [],
        queryBalanceToken: '',
        queryTokenBalance: 0,
        withdrawAmount: 0
      }
    }
  },
  methods: {
    renderTransfer: function (ins) {
      this.ins = ins;
      this.operation = 'TRANSFER';
      this.drawer = true;
      this.transfer.queryBalanceToken = ins.strategyConfig.token0Address;
      this.transfer.walletOptions = [
          {value: ins.strategyConfig.token0Address, label: 'Token0 ' + ins.strategyConfig.binanceToken0Symbol},
        {value: ins.strategyConfig.token1Address, label: 'Token1 ' + ins.strategyConfig.binanceToken1Symbol},
        {value: 'NATIVE_TOKEN', label: 'Native Token'}
      ]
      if (ins.strategyConfig.payTokenAddress !== ins.strategyConfig.token0Address && ins.strategyConfig.payTokenAddress !== ins.strategyConfig.token1Address) {
        this.transfer.walletOptions.push({value: ins.strategyConfig.payTokenAddress, label: 'PayToken ' + ins.strategyConfig.binancePayTokenSymbol});
      }
      this.transferTokenChange();
      this.$forceUpdate();
    },
    transferTokenChange: function () {
      let that = this;
      that.transfer.queryTokenBalance = 'Loading...';
      this.queryBalance(this.transfer.queryBalanceToken === 'NATIVE_TOKEN', true, this.transfer.queryBalanceToken, function (balance) {
        that.transfer.queryTokenBalance = balance;
      });
    },
    transferToken: function () {
      let that = this;
      ConfirmProvider.openConfirm(this, '转账确认', '该操作将会从当前操作钱包中转移指定Token', function () {
        that.processing = true;
        UniPlusService.uniPlusTransfer(that.ins.sid + '', that.transfer.transferTo, that.transfer.queryBalanceToken === 'NATIVE_TOKEN', that.transfer.queryBalanceToken, that.transfer.transferAmount + '', function (result) {
          that.processing = false;
          if (result.toUpperCase() === 'SUCCESS') {
            Message.success('已转账提现');
            that.renderTransfer(that.ins);
          } else {
            Message.error('转账失败');
          }
        }, function () {
          Message.error('转账失败');
          that.processing = false;
        });
      });
    },
    renderDeposit: function (ins) {
      this.ins = ins;
      this.operation = 'DEPOSIT';
      this.drawer = true;
      this.deposit.queryBalanceToken = ins.strategyConfig.token0Address;
      this.deposit.walletOptions = [
        {value: ins.strategyConfig.token0Address, label: 'Token0 ' + ins.strategyConfig.binanceToken0Symbol},
        {value: ins.strategyConfig.token1Address, label: 'Token1 ' + ins.strategyConfig.binanceToken1Symbol}
      ]
      if (ins.strategyConfig.payTokenAddress !== ins.strategyConfig.token0Address && ins.strategyConfig.payTokenAddress !== ins.strategyConfig.token1Address) {
        this.deposit.walletOptions.push({value: ins.strategyConfig.payTokenAddress, label: 'PayToken ' + ins.strategyConfig.binancePayTokenSymbol});
      }
      this.depositTokenChange();
      this.$forceUpdate();
    },
    depositTokenChange: function () {
      let that = this;
      that.deposit.queryTokenBalance = 'Loading...';
      this.queryBalance(false, true, this.deposit.queryBalanceToken, function (balance) {
        that.deposit.queryTokenBalance = balance;
      });
    },
    depositToken: function () {
      let that = this;
      ConfirmProvider.openConfirm(this, '存款确认', '该操作将会从当前操作钱包向合约存入指定Token', function () {
        that.processing = true;
        UniPlusService.uniPlusTransfer(that.ins.sid + '', that.ins.strategyConfig.user.vaultContractAddress, false, that.deposit.queryBalanceToken, that.deposit.depositAmount + '', function (result) {
          that.processing = false;
          if (result.toUpperCase() === 'SUCCESS') {
            Message.success('已成功存入');
            that.renderDeposit(that.ins);
          } else {
            Message.error('存入失败');
          }
        }, function () {
          Message.error('存入失败');
          that.processing = false;
        });
      });
    },
    renderWithdraw: function (ins) {
      this.ins = ins;
      this.operation = 'WITHDRAW';
      this.drawer = true;
      this.withdraw.queryBalanceToken = ins.strategyConfig.token0Address;
      this.withdraw.walletOptions = [
        {value: ins.strategyConfig.token0Address, label: 'Token0 ' + ins.strategyConfig.binanceToken0Symbol},
        {value: ins.strategyConfig.token1Address, label: 'Token1 ' + ins.strategyConfig.binanceToken1Symbol}
      ]
      if (ins.strategyConfig.payTokenAddress !== ins.strategyConfig.token0Address && ins.strategyConfig.payTokenAddress !== ins.strategyConfig.token1Address) {
        this.withdraw.walletOptions.push({value: ins.strategyConfig.payTokenAddress, label: 'PayToken ' + ins.strategyConfig.binancePayTokenSymbol});
      }
      this.withdrawTokenChange();
      this.$forceUpdate();
    },
    withdrawTokenChange: function () {
      let that = this;
      this.withdraw.queryTokenBalance = 'Loading...';
      this.queryBalance(false, false, this.withdraw.queryBalanceToken, function (balance) {
        that.withdraw.queryTokenBalance = balance;
      });
    },
    withdrawToken: function () {
      let that = this;
      ConfirmProvider.openConfirm(this, '提款确认', '该操作将会从合约提款至当前操作钱包', function () {
        that.processing = true;
        UniPlusService.withdrawTokenToWallet(that.ins.sid + '', that.withdraw.queryBalanceToken, that.withdraw.withdrawAmount + '', function (result) {
          that.processing = false;
          if (result.toUpperCase() === 'SUCCESS') {
            Message.success('已成功提款');
            that.renderWithdraw(that.ins);
          } else {
            Message.error('提款失败');
          }
        }, function () {
          Message.error('提款失败');
          that.processing = false;
        });
      });
    },
    close: function () {
      this.drawer = false;
    },
    queryBalance: function (isNativeToken, isQuerySenderWallet, tokenAddress, callback) {
      UniPlusService.uniPlusQueryBalance(this.ins.sid, isNativeToken, isQuerySenderWallet, tokenAddress, function (balance) {
        callback(balance);
      }, function () {
        callback(-1);
      });
    }
  }
}
</script>

<style scoped>

.open-task-trade-tools {
  width: 60%;
  margin-left: 40%
}

@media only screen and  (max-width: 568px) {
  .open-task-trade-tools {
    width: 100%;
    margin-left: 0
  }
}


</style>