<template>
  <div :dto="dto">
    <el-skeleton animated :loading="rendering" :throttle="268">
      <template slot="template">
        <trialpha-sk style="margin-top: 0;width: 60%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 40%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 100%;float: left"></trialpha-sk>
      </template>
      <template>
        <el-row>
          <el-collapse class="trialpha-collapse" v-model="activeNames" style="border: 0">
            <el-collapse-item class="trialpha-collapse" style="border: 0" :name="ins.strategyConfig.symbol1 + ins.strategyConfig.symbol2 + ins.sid" v-for="ins in instanceList" :key="ins.strategyConfig.symbol1 + ins.strategyConfig.symbol2 + ins.sid">
              <template slot="title">
                <el-row style="width: 100%">
                  <el-col :span="4" style="padding: 0">
                    <el-card class="black-card" body-style="padding: 0" style="line-height: 46px;margin-left: -10px;margin-top: 0;border-left: 0;border-top: 0;border-bottom: 0;border-radius: 0;height: 46px;width: 200px;background-color: #444c56">
                      <span class="dark-mode-font" :style="'padding: 0;font-size: 15px;width: 100%;display: inline-block;text-align: center;' + (ins.isActive ? 'color: #FFFFFF' : '')">{{ins.strategyConfig.symbol1 }} & {{ ins.strategyConfig.symbol2 }}</span>
                    </el-card>
                  </el-col>
                  <el-col :span="20">
                  </el-col>
                </el-row>
              </template>
              <el-row style="overflow-y: auto">
                <el-collapse class="trialpha-collapse-position-panel" v-model="historyPanel" style="border: 0; margin-top: 10px;">
                  <el-collapse-item class="trialpha-collapse-position-panel" style="border: 0;padding: 0">
                    <template slot="title">
                      <el-row style="width: 100%;display: flex;padding-left: 10px">
                        <span class="dark-mode-font ellipsis-line" :style="'display: inline-block;float: left;margin-left: 0;color: #FFFFFF'">配置参数</span>
                      </el-row>
                    </template>
                    <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                      <el-col :span="11">
                        <span class="dark-mode-font" style="color: white;font-size: 12px">Symbol 1 交易所</span>
                      </el-col>
                      <el-col :span="12" :offset="1">
                        <span class="dark-mode-font" style="color: white;font-size: 12px">Symbol 1</span>
                      </el-col>
                    </el-row>
                    <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                      <el-col :span="11">
                        <input class="black-card" v-model="ins.strategyConfig.symbol1Platform" style="outline: none;color: gray;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled />
                      </el-col>
                      <el-col :span="12" :offset="1" style="padding-right: 10px">
                        <input class="black-card" v-model="ins.strategyConfig.symbol1" style="outline: none;color: gray;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled />
                      </el-col>
                    </el-row>
                    <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                      <el-col :span="11">
                        <span class="dark-mode-font" style="color: white;font-size: 12px">Symbol 2 交易所</span>
                      </el-col>
                      <el-col :span="12" :offset="1">
                        <span class="dark-mode-font" style="color: white;font-size: 12px">Symbol 2</span>
                      </el-col>
                    </el-row>
                    <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                      <el-col :span="11">
                        <input class="black-card" v-model="ins.strategyConfig.symbol2Platform" style="outline: none;color: gray;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled />
                      </el-col>
                      <el-col :span="12" :offset="1" style="padding-right: 10px">
                        <input class="black-card" v-model="ins.strategyConfig.symbol2" style="outline: none;color: gray;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled />
                      </el-col>
                    </el-row>
                    <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                      <el-col :span="11">
                        <span class="dark-mode-font" style="color: white;font-size: 12px">样本数量</span>
                      </el-col>
                      <el-col :span="12" :offset="1">
                        <span class="dark-mode-font" style="color: white;font-size: 12px">更换参数K线数</span>
                      </el-col>
                    </el-row>
                    <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                      <el-col :span="11">
                        <input class="black-card" v-model="ins.strategyConfig.testSize" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" />
                      </el-col>
                      <el-col :span="12" :offset="1" style="padding-right: 10px">
                        <input class="black-card" v-model="ins.strategyConfig.changeDataSize" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" />
                      </el-col>
                    </el-row>
                    <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                      <el-col :span="11">
                        <span class="dark-mode-font" style="color: white;font-size: 12px">往前追溯K线数</span>
                      </el-col>
                      <el-col :span="4" :offset="1">
                        <span class="dark-mode-font" style="color: white;font-size: 12px">收益记录点</span>
                      </el-col>
                      <el-col :span="5">
                        <span class="dark-mode-font" style="color: white;font-size: 12px">结束时间</span>
                      </el-col>
                      <el-col :span="3">
                        <span class="dark-mode-font" style="color: white;font-size: 12px">是否已执行</span>
                      </el-col>
                    </el-row>
                    <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                      <el-col :span="11">
                        <input class="black-card" v-model="ins.strategyConfig.modelDataSize" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" />
                      </el-col>
                      <el-col :span="4" :offset="1" style="padding-right: 10px">
                        <input class="black-card" v-model="ins.strategyConfig.binSize" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" />
                      </el-col>
                      <el-col :span="5" style="padding-right: 10px;overflow: hidden">
                        <el-date-picker
                            size="small"
                            v-model="ins.strategyConfig.endTime"
                            type="datetime"
                            placeholder="选择日期时间"
                            value-format="timestamp" style="width: 100%">
                        </el-date-picker>
                      </el-col>
                      <el-col :span="3" style="padding-right: 10px">
                        <input class="black-card" :value="ins.strategyConfig.isExecuted" style="outline: none;color: gray;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled/>
                      </el-col>
                    </el-row>
                    <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                      <el-col :span="11">
                        <span class="dark-mode-font" style="color: white;font-size: 12px">哈希值</span>
                      </el-col>
                      <el-col :span="6" :offset="1">
                        <span class="dark-mode-font" style="color: white;font-size: 12px">单次开仓金额</span>
                      </el-col>
                      <el-col :span="6">
                        <span class="dark-mode-font" style="color: white;font-size: 12px">最大单边持仓美元价值</span>
                      </el-col>
                    </el-row>
                    <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                      <el-col :span="11">
                        <input class="black-card" :value="ins.strategyConfig.hashCode" style="outline: none;color: gray;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled/>
                      </el-col>
                      <el-col :span="6" :offset="1" style="padding-right: 10px">
                        <input class="black-card" v-model="ins.strategyConfig.openUsd" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" />
                      </el-col>
                      <el-col :span="6" style="padding-right: 10px">
                        <input class="black-card" v-model="ins.strategyConfig.maxPositionUsd" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" />
                      </el-col>
                    </el-row>
                    <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                      <el-col :span="11">
                        <span class="dark-mode-font" style="color: white;font-size: 12px">实例状态</span>
                      </el-col>
                      <el-col :span="6" :offset="1" style="padding-right: 8px">
                        <span class="dark-mode-font" style="color: white;font-size: 12px" disabled="">Mu.C.Rate</span>
                      </el-col>
                      <el-col :span="6" style="padding-right: 8px">
                        <span class="dark-mode-font" style="color: white;font-size: 12px" disabled="">Theta Min</span>
                      </el-col>
                    </el-row>
                    <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                      <el-col :span="11">
                        <el-select class="select-symbol" style="width: 100%" v-model="ins.isActive" placeholder="请选择">
                          <el-option
                              v-for="item in [{value: true, label: 'Active'}, {value: false, label: 'Inactive'}]"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                          </el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="6" :offset="1" style="padding-right: 8px">
                        <input class="black-card" v-model="ins.strategyConfig.muCoefficientRate" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" />
                      </el-col>
                      <el-col :span="6" style="padding-right: 8px">
                        <input class="black-card" v-model="ins.strategyConfig.thetaMin" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" />
                      </el-col>
                    </el-row>
                    <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                      <el-col :span="5">
                        <span class="dark-mode-font" style="color: white;font-size: 12px">KLine 1 Type</span>
                      </el-col>
                      <el-col :span="6">
                        <span class="dark-mode-font" style="color: white;font-size: 12px">KLine 2 Type</span>
                      </el-col>
                      <el-col :span="6" :offset="1" style="padding-right: 8px">
                        <span class="dark-mode-font" style="color: white;font-size: 12px" disabled="">S.L.D.Min</span>
                      </el-col>
                      <el-col :span="6" style="padding-right: 8px">
                        <span class="dark-mode-font" style="color: white;font-size: 12px" disabled="">Max Deviation</span>
                      </el-col>
                    </el-row>
                    <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                      <el-col :span="5" style="padding-right: 8px">
                        <input class="black-card" v-model="ins.strategyConfig.symbol1WindowSize" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" />
                      </el-col>
                      <el-col :span="6">
                        <input class="black-card" v-model="ins.strategyConfig.symbol2WindowSize" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" />
                      </el-col>
                      <el-col :span="6" :offset="1" style="padding-right: 8px">
                        <input class="black-card" v-model="ins.strategyConfig.stoplossDelayMin" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" />
                      </el-col>
                      <el-col :span="6" style="padding-right: 8px">
                        <input class="black-card" v-model="ins.strategyConfig.maxDeviation" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" />
                      </el-col>
                    </el-row>
                    <div v-for="(intervalCoefficientList, index) in ins.strategyConfig.intervalCoefficientList" :key="index">
                      <el-divider></el-divider>
                      <el-card class="black-card" style="width: 98%;margin-top: -10px;margin-left: auto;margin-right: auto;border: 0" shadow="none" body-style="padding: 0">
                        <el-row style="margin-top: 5px">
                          <el-col :span="11">
                            <span class="dark-mode-font" style="color: white;font-size: 12px">开平仓区间(区间{{index + 1}})</span>
                          </el-col>
                        </el-row>
                        <el-row style="margin-top: 5px">
                          <el-col :span="11">
                            <el-tooltip class="item" effect="dark" content="平仓时将以该值为基准" placement="top-start" :open-delay="666">
                              <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="intervalCoefficientList.stoplossCoefficient">
                                <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">止损系数</span></template>
                              </el-input>
                            </el-tooltip>
                          </el-col>
                          <el-col :span="12" :offset="1">
                            <el-tooltip class="item" effect="dark" content="建立仓位时将以该值为基准" placement="top-start" :open-delay="666">
                              <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="intervalCoefficientList.openOrderCoefficient">
                                <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">开仓系数</span></template>
                              </el-input>
                            </el-tooltip>
                          </el-col>
                        </el-row>
                        <el-row style="margin-top: 5px">
                          <el-col :span="11">
                            <el-tooltip class="item" effect="dark" content="执行任务的仓位占比" placement="top-start" :open-delay="666">
                              <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="intervalCoefficientList.positionRate">
                                <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">仓位占比</span></template>
                              </el-input>
                            </el-tooltip>
                          </el-col>
                          <el-col :span="12" :offset="1">
                            <el-tooltip class="item" effect="dark" content="执行任务的区间名称标识" placement="top-start" :open-delay="666">
                              <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="intervalCoefficientList.confidenceInterval">
                                <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">区间名称标识</span></template>
                              </el-input>
                            </el-tooltip>
                          </el-col>
                        </el-row>
                      </el-card>
                    </div>
                    <el-divider></el-divider>
                    <el-row style="width: 98%;margin: 10px auto">
                      <el-col :span="12">
                        <el-button class="dark-mode-btn" style="float: left;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 3px 0 0 3px;border-right: 0" @click="removeInterval(ins.strategyConfig)"
                                   :disabled="ins.strategyConfig.intervalCoefficientList === undefined || ins.strategyConfig.intervalCoefficientList.length <= 1 || rendering" :loading="processing"><i
                            class="el-icon-minus" style="font-size: 16px"/></el-button>
                      </el-col>
                      <el-col :span="12">
                        <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0;border-right: 0" @click="addInterval(ins.strategyConfig)" :disabled="rendering" :loading="processing"><i class="el-icon-plus"
                                                                                                                                                                                                                                                   style="font-size: 16px"/>
                        </el-button>
                      </el-col>
                    </el-row>

                    <el-row style="width: 98%;margin: 10px auto">
                      <el-col :span="8">
                        <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0;border-right: 0" @click="reset(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i
                            class="el-icon-c-scale-to-original" style="font-size: 16px"/></el-button>
                      </el-col>
                      <el-col :span="8">
                        <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0;border-right: 0" @click="deleteIns(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i class="el-icon-delete" style="font-size: 16px"/>
                        </el-button>
                      </el-col>
                      <el-col :span="8">
                        <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0 3px 3px 0;border-top: 0" @click="save(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i class="el-icon-check" style="font-size: 16px"/>
                        </el-button>
                      </el-col>
                    </el-row>
                  </el-collapse-item>
                </el-collapse>
              </el-row>


              <el-row>
                <el-collapse class="trialpha-collapse-position-panel" v-model="historyPanel" style="border: 0" @change="queryBackTradeResult(ins)">
                  <el-collapse-item class="trialpha-collapse-position-panel" style="border: 0;padding: 0">
                    <template slot="title">
                      <el-row style="width: 100%;display: flex;padding-left: 10px">
                        <span class="dark-mode-font ellipsis-line" :style="'display: inline-block;float: left;margin-left: 0;color: #FFFFFF'">结果</span>
                      </el-row>
                    </template>
                    <div v-if="Object.keys(backTradeResult).length !== 0">
                      <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                        <el-col :span="4">
                          <span class="dark-mode-font" style="color: white;font-size: 12px">总交易成功次数</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font" style="color: white;font-size: 12px">止损次数</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font" style="color: white;font-size: 12px">移动止盈次数</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font" style="color: white;font-size: 12px">止盈次数</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font" style="color: white;font-size: 12px">已结算收益</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font" style="color: white;font-size: 12px">真实剩余价值</span>
                        </el-col>
                      </el-row>
                      <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                        <el-col :span="4" style="padding-right: 10px">
                          <input class="black-card" :value="backTradeResult['tradeCount']" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled/>
                        </el-col>
                        <el-col :span="4" style="padding-right: 10px">
                          <input class="black-card" :value="backTradeResult['stopLossCount']" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled/>
                        </el-col>
                        <el-col :span="4" style="padding-right: 10px">
                          <input class="black-card" :value="backTradeResult['takeprofitCount']" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled/>
                        </el-col>
                        <el-col :span="4" style="padding-right: 10px">
                          <input class="black-card" :value="backTradeResult['tradeCount'] - backTradeResult['stopLossCount'] - backTradeResult['takeprofitCount']" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled/>
                        </el-col>
                        <el-col :span="4" style="padding-right: 10px">
                          <input class="black-card" :value="backTradeResult['profitU'].toFixed(6)" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled/>
                        </el-col>
                        <el-col :span="4" style="padding-right: 10px">
                          <input class="black-card" :value="backTradeResult['realAmountUsd'].toFixed(6)" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled/>
                        </el-col>
                      </el-row>
                      <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                        <el-col :span="4">
                          <span class="dark-mode-font" style="color: white;font-size: 12px">收益率</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font" style="color: white;font-size: 12px">最大回撤时的spread</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font" style="color: white;font-size: 12px">最大回撤时S1价格</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font" style="color: white;font-size: 12px">最大回撤时S2价格</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font" style="color: white;font-size: 12px">最大回撤所在K线</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font" style="color: white;font-size: 12px">最大回撤</span>
                        </el-col>
                      </el-row>
                      <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                        <el-col :span="4" style="padding-right: 10px">
                          <input class="black-card" :value="(Number(backTradeResult['profitRate'].toFixed(7) - 1) * 100).toFixed(5) + '%'"  style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled/>
                        </el-col>
                        <el-col :span="4" style="padding-right: 10px">
                          <input class="black-card" :value="backTradeResult['spread'].toFixed(6)" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled/>
                        </el-col>
                        <el-col :span="4" style="padding-right: 10px">
                          <input class="black-card" :value="backTradeResult['priceSymbol1']" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled/>
                        </el-col>
                        <el-col :span="4" style="padding-right: 10px">
                          <input class="black-card" :value="backTradeResult['priceSymbol2']" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled/>
                        </el-col>
                        <el-col :span="4" style="padding-right: 10px">
                          <input class="black-card" :value="backTradeResult['klineNumber']" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled/>
                        </el-col>
                        <el-col :span="4" style="padding-right: 10px">
                          <input class="black-card" :value="(backTradeResult['withdrawn'].toFixed(6) * 100).toFixed(5) + '%'" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled/>
                        </el-col>
                      </el-row>
                      <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                        <el-col :span="4">
                          <span class="dark-mode-font" style="color: white;font-size: 12px">S1最大杠杆率</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font" style="color: white;font-size: 12px">S1最大所在K线序号</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font" style="color: white;font-size: 12px">S2最大杠杆率</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font" style="color: white;font-size: 12px">S2最大所在K线序号</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font" style="color: white;font-size: 12px">总最大杠杆</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font" style="color: white;font-size: 12px">总最大杠杆所在K线序号</span>
                        </el-col>
                      </el-row>
                      <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                        <el-col :span="4" style="padding-right: 10px">
                          <input class="black-card" :value="backTradeResult['maxLeverageS1'].toFixed(6)" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled/>
                        </el-col>
                        <el-col :span="4" style="padding-right: 10px">
                          <input class="black-card" :value="backTradeResult['maxLeverageS1Index']" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled/>
                        </el-col>
                        <el-col :span="4" style="padding-right: 10px">
                          <input class="black-card" :value="backTradeResult['maxLeverageS2'].toFixed(6)" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled/>
                        </el-col>
                        <el-col :span="4" style="padding-right: 10px">
                          <input class="black-card" :value="backTradeResult['maxLeverageS2Index']" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled/>
                        </el-col>
                        <el-col :span="4" style="padding-right: 10px">
                          <input class="black-card" :value="backTradeResult['maxLeverage'].toFixed(6)" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled/>
                        </el-col>
                        <el-col :span="4" style="padding-right: 10px">
                          <input class="black-card" :value="backTradeResult['maxLeverageIndex']" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" disabled/>
                        </el-col>
                      </el-row>
                    </div>
                    <div v-if="Object.keys(backTradeResult).length === 0">
                      <el-row
                          style="height: 25px;border: 0;background-color: rgba(0, 0, 0, 0);padding: 3px 10px 3px 10px;text-align: center;margin-top: 20px;margin-bottom: 25px">
                          <span class="dark-mode-font" style=";font-size: 12px;color:gray;text-align: center;"><i
                              class="el-icon-s-order"/> EMPTY HISTORY...</span>
                      </el-row>
                    </div>
                    <div v-if="Object.keys(backTradeResult).length !== 0">
                      <cross-pair-chart id="profit_vol_ref_admin" :ref="'profit_vol_ref_admin_' + ins.sid" :color="'#9383e2'" width="3"
                      style="width: 100%; height: 428px; margin-top: 25px"></cross-pair-chart>
                    </div>
                    <div v-if="Object.keys(backTradeResult).length === 0">
                      <el-row
                          style="height: 25px;border: 0;background-color: rgba(0, 0, 0, 0);padding: 3px 10px 3px 10px;text-align: center;margin-top: 20px;margin-bottom: 25px">
                          <span class="dark-mode-font" style=";font-size: 12px;color:gray;text-align: center;"><i
                              class="el-icon-s-order"/> EMPTY HISTORY...</span>
                      </el-row>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </el-row>



            </el-collapse-item>
          </el-collapse>
          <el-collapse class="trialpha-collapse" v-model="createCollapse" style="border-top: 0">
            <el-collapse-item class="trialpha-collapse">
              <template slot="title">
                <i class="el-icon-menu" style="margin-left: 2px;color: #0ecb81;margin-top: 1px"/><h4 class="dark-mode-font" style="margin-left: 8px;padding-top: 6px;color: white">Create New Instance</h4>
              </template>
              <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                <el-col :span="11">
                  <span class="dark-mode-font" style="color: white;font-size: 12px">Symbol 1 交易所</span>
                </el-col>
                <el-col :span="12" :offset="1">
                  <span class="dark-mode-font" style="color: white;font-size: 12px">Symbol 1</span>
                </el-col>
              </el-row>
              <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                <el-col :span="11">
                  <el-select class="chose-symbol select-symbol" v-model="instance.symbol1Platform" placeholder="Symbol 1 Exchange" size="small" style="width: 100%">
                    <el-option
                        v-for="item in exchangeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="12" :offset="1" style="padding-right: 10px">
                  <input class="black-card" v-model="instance.symbol1" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" />
                </el-col>
              </el-row>
              <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                <el-col :span="11">
                  <span class="dark-mode-font" style="color: white;font-size: 12px">Symbol 2 交易所</span>
                </el-col>
                <el-col :span="12" :offset="1">
                  <span class="dark-mode-font" style="color: white;font-size: 12px">Symbol 2</span>
                </el-col>
              </el-row>
              <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                <el-col :span="11">
                  <el-select class="chose-symbol select-symbol" v-model="instance.symbol2Platform" placeholder="Symbol 1 Exchange" size="small" style="width: 100%">
                    <el-option
                        v-for="item in exchangeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="12" :offset="1" style="padding-right: 10px">
                  <input class="black-card" v-model="instance.symbol2" style="outline: none;color: white;background-color: #373e47;border: #444c56 1px solid;border-radius: 3px;font-size: 12px;height: 32px;padding: 0 5px 0 5px;width: 100%" />
                </el-col>
              </el-row>
              <el-row style="height: 85px;overflow-y: auto">
                <el-divider></el-divider>
                <el-row style="width: 98%;margin: 10px auto">
                  <el-col :span="24">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0 3px 3px 0" @click="add(instance)" :disabled="rendering" :loading="processing" v-auth:dbWriteApi><i class="el-icon-check" style="font-size: 16px"/>
                    </el-button>
                  </el-col>
                </el-row>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-row>
      </template>
      <trade-tools ref="open-tools"></trade-tools>
    </el-skeleton>
  </div>
</template>

<script>
import {Message} from "element-ui";
import StrategyInstanceService from "@/api/trade/StrategyInstanceService";
import PairsTradingService from "@/api/trade/strategy/PairsTradingService";
import ConfirmProvider from "@/components/ConfirmProvider";
import TradeTools from "@/pages/controlPanel/accountMgnt/strategies/openTask/ftx/TradeTools";
import TrialphaSk from "@/components/TrialphaSk";
import moment from "moment";
import SymbolInfoService from "@/utils/SymbolInfoService";
import GeneralApiService from "@/api/trade/GeneralApiService";
import CrossPairChart from "@/components/CrossPairChart";

export default {
  name: "GenericCrossPairBackTrade",
  components: {TrialphaSk, TradeTools, CrossPairChart},
  props: {
    dto: Object,
    value: String
  },
  data: function () {
    return {
      day: 1,
      processing: false,
      rendering: true,
      activeNames: ['1'],
      createCollapse: ['1'],
      instance: {},
      instanceList: [],
      accountInstance: {},
      allPrice: {},
      allSymbols: [],
      exchangeList: [{value: 'BINANCE', label: 'Binance'}, {value: 'FTX', label: 'FTX'}, {value: 'OKX', label: 'OKX'}],
      historyOrderMap: {},
      active: 0,
      loading: {
        loadingHistoryOrders: false
      },
      historyPanel: [],
      thetaRate: 'N/A',
      keyList:[],
      backTradeResult: {},
      backTradeChartResult: {}

    }
  },
  created() {
    // this.closeReadOnly(true);
  },
  methods: {
    // closeReadOnly(val) {
    //   this.$nextTick(() => {
    //     if (val) {
    //       this.$el.querySelectorAll('.chose-symbol.select-symbol .el-input__inner').forEach((item) => {
    //         item.removeAttribute('readonly');
    //       })
    //     }
    //   })
    // },
    openTradeTools(ins) {
      this.$nextTick(function () {
        this.$refs['open-tools'].renderFtx(ins);
      });
    },
    handleShortLong(ins) {
      ins.symbol1Short = !ins.symbol1Short;
    },
    render: function (accountInstance) {
      let that = this;
      that.rendering = true;
      that.loading.loadingHistoryOrders = true;
      that.accountInstance = accountInstance;
      that.instance = JSON.parse(JSON.stringify(that.dto.strategyConfig));
      that.addInterval(that.instance);
      // that.queryHistoryOrderList()
      StrategyInstanceService.query(that.accountInstance.instanceId, that.dto.strategyName, function (data) {
        that.instanceList = data.strategyInsList;
        that.rendering = false;
        for (let i = 0; i < data.strategyInsList.length; i++) {
          that.queryHistoryOrderList(data.strategyInsList[i])
        }
        that.loading.loadingHistoryOrders = false;
        that.rendering = false;
      }, function () {
        that.rendering = false;
      });
    },
    reset: function (ins) {
      let that = this;
      ConfirmProvider.openConfirm(this, '确认重置', '该操作将会重置所有非配置的数据', function () {
        that.processing = true;
        StrategyInstanceService.reset(ins.sid, function () {
          that.processing = false;
          Message.success('已成功重置');
          that.render(that.accountInstance);
          that.backTradeResult = undefined;
          that.backTradePictureResult = undefined;
        }, function () {
          that.processing = false;
        });
      });
    },
    removeInterval: function (ins) {
      let that = this;
      ins.intervalCoefficientList.pop();
      that.keyList.pop();
    },
    addInterval: function (ins) {
      let that = this;
      let intervalCoefficient = JSON.parse(JSON.stringify(this.dto.strategyConfig.intervalCoefficient));
      intervalCoefficient.confidenceInterval = ins.intervalCoefficientList.length;
      ins.intervalCoefficientList.push(
          intervalCoefficient
      );
      that.keyList.push(ins.intervalCoefficientList.length);
    },
    deleteIns: function (ins) {
      let that = this;
      ConfirmProvider.openConfirm(this, '确认删除', '该操作将会删除该实例，此操作无法恢复', function () {
        that.processing = true;
        StrategyInstanceService.deleteIns(ins.sid, function () {
          that.processing = false;
          Message.success('已成功删除');
          that.render(that.accountInstance);
        }, function () {
          that.processing = false;
        });
      });
    },
    save: function (ins) {
      let that = this;
      that.processing = true;
      StrategyInstanceService.update({
        sid: ins.sid,
        strategyName: ins.strategyName,
        config: (ins.strategyConfig),
        instanceEntity: {
          id: that.accountInstance.instanceId
        },
        isActive: ins.isActive
      }, function () {
        that.processing = false;
        Message.success('已成功更新');
        that.render(that.accountInstance);
      }, function () {
        that.processing = false;
      });
    },
    add: function (ins) {
      let that = this;
      that.processing = true;
      StrategyInstanceService.create({
        insId: this.accountInstance.instanceId,
        strategyName: this.dto.strategyName,
        config: JSON.stringify(ins)
      }, function () {
        Message.success('已成功创建新的实例');
        that.processing = false;
        setTimeout(() => that.render(that.accountInstance), 888);
      }, function () {
        that.processing = false;
      });
    },
    queryHistoryOrderList: function (ins) {
      let that = this;
      PairsTradingService.queryHistoryOrder(ins.sid, function (orderList) {
        that.historyOrderMap[ins.sid] = orderList;
      }, function () {

      });
    },
    resetHistoryOrderList: function (ins, afterResetFunction) {
      let that = this;
      PairsTradingService.resetHistoryOrder(ins.sid, function () {
        afterResetFunction();
      }, function () {
        //这里要看一下能不能这样写
        that.processing = false;
      });
    },
    queryBackTradeResult: function (ins) {
      let that = this;
      that.$nextTick(function () {
        that.$refs['profit_vol_ref_admin_' + ins.sid][0].render({
          indexList: [Date.now()],
          profitList: [0]
        });
      });
      PairsTradingService.queryBackTradeResult(ins.strategyConfig.hashCode, function (result) {
        that.backTradeResult = result;
      }, function () {

      });
      PairsTradingService.queryBackTradePictureData(ins.strategyConfig.hashCode, function (result) {
        that.backTradeChartResult = result;
        if (result.indexList.length > 0) {
          that.$nextTick(function () {
            that.$refs['profit_vol_ref_admin_' + ins.sid][0].render(that.backTradeChartResult);
          });
        }
      }, function () {

      })
    },
    transferTime: function(item) {
      return  moment(item).format('YYYY-MM-DD HH:mm:ss')

    },
    getSpread: function (symbol1Name, symbol2Name, platform1, platform2, intercept, slope) {
      let symbol1Price = SymbolInfoService.getSymbolPrice(platform1, GeneralApiService.SYMBOL_TYPE.SPOT, symbol1Name);
      let symbol2Price = SymbolInfoService.getSymbolPrice(platform2, GeneralApiService.SYMBOL_TYPE.SPOT, symbol2Name);
      return Math.log(symbol1Price) - slope * Math.log(symbol2Price) - intercept;
    },
    getSlopeChangeRate: function (modelDataTime, slope) {
      let that = this;
      PairsTradingService.getSlopeChangeRate(that.day, modelDataTime, slope, function (data) {
        that.thetaRate =  data;
      }, function () {

      });
    },
    getDayRange: function (start, end) {
      let dayRange = [];
      for (let i = start; i <= end; i++) {
        dayRange.push(i);
      }
      return dayRange;
    },
    towNumber: function (num) {
      return Number(num).toFixed(6);
    }

  }
}
</script>

<style scoped>

.ellipsis-line {
  padding: 8px;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-collapse {
  color: #FFFFFF;
  border-top: 1px solid #444c56;
  border-bottom: 1px solid #444c56;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: white;
}

.el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-divider {
  border-top: 1px solid #444c56;
}

.el-divider.info {
  width: 80%;
  border-top: 1px solid #444c56;
}

.el-divider__text {
  color: white;
  background-color: #444c56;
}

.select-symbol .el-input__inner {
  color: white;
  height: 35px !important;
  line-height: 35px;
  width: 100%;
  padding: 0 10px 0 10px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-right: 0;
  border-radius: 0;
  font-size: 12px;
  outline: none;
}

.select-symbol.el-select .el-input.is-focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:hover .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:hover .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:visited .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:visited .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

</style>