<template>
  <div style="width: 100%;height: 100px">
    <el-row>
      <el-col :span="24">
        <div id="id-coinChart" ref="coinChart" style="height: 120px;z-index: 1"></div>
      </el-col>
    </el-row>
    <el-row style="position: absolute;margin-top: -75px;width: 100%;z-index: 0">
      <el-col :span="24">
        <span class="dark-mode-font" style="color: #343947;font-size: 16px" v-if="!loading">Futures Annualized Rolling Basis (3M)</span>
        <div class="trialpha-loading" v-if="loading" style="margin-left: calc(50% - 22px)">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import DataApiService from "@/api/trade/DataApiService";
import TimeService from "@/utils/TimeService";

export default {
  name: 'futureData',
  components: {},
  data: function () {
    return {
      color: {},
      loading: true,
      timeList: [],
      price: [],
      timeInterval: {}
    }
  },
  created: function () {
    this.loading = true;
    this.renderFutureData();
    this.timeInterval = setInterval(this.renderFutureData, 26 * 1000);
  },
  destroyed: function () {
    clearInterval(this.timeInterval);
  },
  methods: {
    getOption(xTimeList, btc, eth) {
      let series = [{
        showSymbol: false,
        name: 'BTC',
        type: 'line',
        data: btc,
        smooth: true,
        lineStyle: {
          color: 'rgb(247,147,26)',
          width: 1,   //这里是坐标轴的宽度,可以去掉
        }
      },{
        showSymbol: false,
        name: 'ETH',
        type: 'line',
        data: eth,
        smooth: true,
        lineStyle: {
          color: 'rgb(104, 126, 227)',
          width: 1,   //这里是坐标轴的宽度,可以去掉
        }
      }];
      return {
        color: ['rgb(247,147,26)', 'rgb(104, 126, 227)'],
        legend: {
          data: ['BTC', 'ETH'],
          textStyle: {
            color: 'white',
            fontSize: 10
          }
        },
        calculable: true,
        tooltip: {
          show: true, // 是否显示
          trigger: 'axis', // 触发类型  'item'图形触发：散点图，饼图等无类目轴的图表中使用； 'axis'坐标轴触发；'none'：什么都不触发。
          axisPointer: { // 坐标轴指示器配置项。
            type: 'cross', // 'line' 直线指示器  'shadow' 阴影指示器  'none' 无指示器  'cross' 十字准星指示器。
            axis: 'auto', // 指示器的坐标轴。
            snap: true, // 坐标轴指示器是否自动吸附到点上
          }
        },
        xAxis: [{
          type: 'category',
          boundaryGap: true,
          show: true,
          data: xTimeList,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: 8, //更改坐标轴文字大小
              color: 'white'
            }
          },
        }],
        grid: {
          x: 15,
          y: 20,
          x2: 25,
          y2: 15
        },
        yAxis: [{
          type: 'value',
          position: 'right',
          show: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: 'white', //更改坐标轴文字颜色
              fontSize: 8 //更改坐标轴文字大小
            }
          },
        }],
        series: series
      };
    },
    renderFutureData: function () {
      let that = this;
      let xTimeList = [];
      let btcV = [];
      let ethV = [];
      DataApiService.getFutureProfit('ETH', function (eth) {
        DataApiService.getFutureProfit('BTC', function (btc) {
          btc.forEach((item) => {
            xTimeList.push(TimeService.timestampToDateWithoutTime(item.t * 1000));
            btcV.push((item.v * 100).toFixed(1));
          });
          eth.forEach((item) => {
            ethV.push((item.v * 100).toFixed(1));
          });
          if (!that.coinChart && that.$refs.coinChart) {
            that.coinChart = that.$echarts.init(that.$refs.coinChart);
            that.coinChart.clear();
            that.coinChart.setOption(that.getOption(xTimeList, btcV, ethV));
            window.addEventListener('resize', () => (that.coinChart.resize()));
          }
          that.loading = false;
        });
      });
    }
  }
}
</script>