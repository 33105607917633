<template>
  <div style="width: 100%;height: 100%;overflow-y: auto;padding: 5px">
    <el-row style="margin-top: 20px">
      <el-card class="black-card" style="height: 228px">
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font"
                  style="display: inline-block; float: left;color: white;font-size: 12px">手续费等级</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy"
                  style="display: inline-block; float: left;color: white;font-size: 12px">{{
                accountInfo.feeTier
              }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font"
                  style="display: inline-block; float: left;color: white;font-size: 12px">是否可交易</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy"
                  style="display: inline-block; float: left;color: white;font-size: 12px">{{
                accountInfo.canTrade ? 'True' : 'False'
              }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font"
                  style="display: inline-block; float: left;color: white;font-size: 12px">仓位美元价值</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy"
                  style="display: inline-block; float: left;color: white;font-size: 12px">${{ totalSize }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font"
                  style="display: inline-block; float: left;color: white;font-size: 12px">U本位合约净值</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy"
                  style="display: inline-block; float: left;color: white;font-size: 12px">${{
                Number(accountInfo.totalMarginBalance).toFixed(2)
              }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">U本位合约Pnl</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy"
                  style="display: inline-block; float: left;color: white;font-size: 12px">${{
                Number(accountInfo.totalUnrealizedProfit).toFixed(2)
              }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font"
                  style="display: inline-block; float: left;color: white;font-size: 12px">总杠杆</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy"
                  style="display: inline-block; float: left;color: white;font-size: 12px">{{
                (totalSize / accountInfo.totalMarginBalance).toFixed(2)
              }}X</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font"
                  style="display: inline-block; float: left;color: white;font-size: 12px">保证金率</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy"
                  style="display: inline-block; float: left;color: white;font-size: 12px">{{
                ((accountInfo.totalMaintMargin / accountInfo.totalMarginBalance) * 100).toFixed(2)
              }}%</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font"
                  style="display: inline-block; float: left;color: white;font-size: 12px">保证金模式</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy"
                  style="display: inline-block; float: left;color: white;font-size: 12px">{{
                assetMode === 'MULTI' ? '联合保证金' : '单币种保证金'
              }} <i class="el-icon-sort" v-auth:strategyPanel
                    style="font-size: 12px;transform:rotate(90deg);color: white;cursor:pointer;margin-left: 5px"
                    @click="changeMarginType"></i></span>
          </el-col>
        </el-row>
      </el-card>
    </el-row>
    <el-row style="margin-top: 15px">
      <general-order-panel v-if="active" ref="order-panel-binance" v-auth:quickPlaceOrder></general-order-panel>
    </el-row>
    <el-row style="margin-top: 15px">
      <el-collapse class="trialpha-collapse" v-model="spotCollapse" style="border: 0;" @change="spotChange">
        <el-collapse-item class="trialpha-collapse">
          <template slot="title">
            <i class="el-icon-s-finance" style="margin-left: 2px;color: #0ecb81;margin-top: 1px;border: 0"/><h4
              class="dark-mode-font" style="margin-left: 10px;padding-top: 6px;color: white">Spot</h4>
          </template>
          <general-position-panel ref="position-panel-spot" style="margin-top: -1px"/>
        </el-collapse-item>
      </el-collapse>
    </el-row>
    <el-row style="margin-top: 15px">
      <el-collapse class="trialpha-collapse" v-model="usdCollapse" style="border: 0;" @change="usdChange">
        <el-collapse-item class="trialpha-collapse">
          <template slot="title">
            <i class="el-icon-s-finance" style="margin-left: 2px;color: #0ecb81;margin-top: 1px;border: 0"/><h4 class="dark-mode-font" style="margin-left: 10px;padding-top: 6px;color: white">USD Contract</h4>
          </template>
          <general-position-panel ref="position-panel" style="margin-top: -1px"/>
        </el-collapse-item>
      </el-collapse>
    </el-row>
    <el-row style="margin-top: 15px">
      <el-collapse class="trialpha-collapse" v-model="coinCollapse" style="border: 0;" @change="coinChange">
        <el-collapse-item class="trialpha-collapse">
          <template slot="title">
            <i class="el-icon-s-finance" style="margin-left: 2px;color: #0ecb81;margin-top: 1px;border: 0"/><h4 class="dark-mode-font" style="margin-left: 10px;padding-top: 6px;color: white">Coin Contract</h4>
          </template>
          <general-position-panel ref="position-panel-coin" style="margin-top: -1px"/>
        </el-collapse-item>
      </el-collapse>
    </el-row>
    <el-row style="margin-top: 15px" v-auth:quickPlaceOrder>
      <el-collapse class="trialpha-collapse" v-model="transferCollapse" style="border: 0;">
        <el-collapse-item class="trialpha-collapse">
          <template slot="title">
            <i class="el-icon-s-finance" style="margin-left: 2px;color: #0ecb81;margin-top: 1px;border: 0"/><h4 class="dark-mode-font" style="margin-left: 10px;padding-top: 6px;color: white">Assets Transfer</h4>
          </template>
          <div style="padding: 0 10px 20px 10px">
            <el-row style="margin-top: 20px">
              <el-col :span="8">
                <span class="dark-mode-font">划转币种</span>
              </el-col>
              <el-col :span="16">
                <input class="trialpha-input" type="text" v-model="transferCoin" style="padding-left: 15px" />
              </el-col>
            </el-row>
            <el-row style="margin-top: 10px">
              <el-col :span="8">
                <span class="dark-mode-font">划转类型</span>
              </el-col>
              <el-col :span="16">
                <el-select class="select-symbol dark-mode-font" style="width: 100%" size="small" v-model="transferType" placeholder="请选择">
                  <el-option
                      v-for="item in transferTypeArray"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row style="margin-top: 10px">
              <el-col :span="8">
                <span class="dark-mode-font">划转数量</span>
              </el-col>
              <el-col :span="16">
                <input class="trialpha-input" type="text" v-model="transferAmount" style="padding-left: 15px" />
              </el-col>
            </el-row>
            <el-row style="margin-top: 0">
              <el-col :span="16" :offset=8>
                <el-button class="dark-mode-btn dark-mode-font" style="font-size: 12px;margin-top: 10px;width: 100%" size="small" :loading="renderingAssetTransfer" @click="transfer">划转</el-button>
              </el-col>
            </el-row>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-row>
    <el-button class="dark-mode-btn" style="margin-top: 15px;width: 100%" :loading="renderingSpot || loadingOrder || renderingUAsset || renderingCAsset || renderingAssetTransfer" @click="renderData">Reload</el-button>
  </div>
</template>

<script>
import TradeApiService from "@/api/trade/TradeApiService";
import TimeService from "@/utils/TimeService";
import GeneralExchangeService from "@/api/trade/GeneralExchangeService";
import {Message} from "element-ui";
import GeneralOrderPanel from "@/components/account/GeneraalOrderPanel";
import GeneralPositionPanel from "@/components/account/GeneralPositionPanel";
import GeneralApiService from "@/api/trade/GeneralApiService";

export default {
  name: "BinanceAccountInfo",
  components: {GeneralPositionPanel, GeneralOrderPanel},
  data: function () {
    return {
      platform: 'BINANCE',
      instance: {},
      tradeAccountIns: {},
      position: {},
      allSpot: {},
      allUAsset: {},
      allCAsset: {},
      moreThanZeroCAsset: {},
      moreThanZeroUAsset: {},
      moreThanZeroSpot: {},
      showAllPosition: false,
      renderingSpot: true,
      renderingUAsset: true,
      renderingCAsset: true,
      renderingPosition: true,
      renderingHistory: true,
      showAllUAsset: false,
      historyOrderToEnd: false,
      loadingOrder: false,
      allPrice: {},
      totalSpotUsd: 0,
      totalUCUsd: 0,
      totalCUsd: 0,
      totalPnl: 0,
      totalSize: 0,
      history: [],
      startTime: 0,
      endTime: 0,
      historyAmount: {},
      historyCostUsd: {},
      analysis: [],
      accountInfo: {},
      accountCoinCInfo: {},
      accountRisk: {},
      active: false,
      assetMode: 'SINGLE',
      spotCollapse: ['1'],
      spotCollapseActive: false,
      usdCollapse: ['1'],
      usdCollapseActive: false,
      coinCollapse: ['1'],
      coinCollapseActive: false,
      transferCollapse: ['1'],
      transferType: 'MAIN_UMFUTURE',
      transferTypeArray: [{value: 'MAIN_UMFUTURE', label: '现货钱包 至 U本位钱包'}, {value: 'MAIN_CMFUTURE', label: '现货钱包 至 币本位钱包'}, {value: 'UMFUTURE_MAIN', label: 'U本位钱包 至 现货钱包'}, {value: 'CMFUTURE_MAIN', label: '币本位钱包 至 现货钱包'}, {value: 'FUNDING_MAIN', label: '资金钱包 至 现货钱包'}],
      transferCoin: 'USDT',
      transferAmount: 0,
      renderingAssetTransfer: false,
      totalSizeC: 0,
      totalPnlC: 0,
      positionC: 0,
      renderingPositionC: false
    }
  },
  methods: {
    spotChange: function (string) {
      if (string[0] === '1') {
        this.spotCollapseActive = !this.spotCollapseActive;
      }
    },
    usdChange: function (string) {
      if (string[0] === '1') {
        this.usdCollapseActive = !this.usdCollapseActive;
      }
    },
    coinChange: function (string) {
      if (string[0] === '1') {
        this.coinCollapseActive = !this.coinCollapseActive;
      }
    },
    changeMarginType: function () {
      let that = this;
      let newMode = this.assetMode === 'SINGLE' ? 'MULTI' : 'SINGLE'
      GeneralExchangeService.updateAssetMode(this.tradeAccountIns.id, newMode, function () {
        Message.success('Change asset mode to ' + newMode + ' success');
        that.renderData();
      });
    },
    getTimeString: function (time) {
      return TimeService.timestampToDate(time);
    },
    changeAllSymbol: function () {
      this.showAllPosition = !this.showAllPosition;
    },
    changeAllUAsset: function () {
      this.showAllUAsset = !this.showAllUAsset;
    },
    render: function (ins, tradeAccountIns) {
      this.active = true;
      this.instance = ins;
      this.tradeAccountIns = tradeAccountIns;
      this.renderData();
    },
    uninstall: function () {
      this.active = false;
    },
    renderData: function () {
      let that = this;
      this.startTime = 0;
      this.history = [];
      this.analysis = [];
      this.position = {};
      this.accountInfo = {};
      this.historyOrderToEnd = false;
      this.loadingOrder = false;
      this.renderingUAsset = true;
      this.renderingCAsset = true;
      this.renderingPosition = true;
      this.renderingPositionC = true;
      this.$nextTick(function () {
        that.$refs['order-panel-binance'].render(that.tradeAccountIns, that.renderData);
      });
      this.$refs['position-panel-spot'].render(GeneralApiService.PLATFORM.BINANCE, GeneralApiService.SYMBOL_TYPE.SPOT, this.tradeAccountIns.apiKey);
      this.$refs['position-panel'].render(GeneralApiService.PLATFORM.BINANCE, GeneralApiService.SYMBOL_TYPE.USD_CONTRACT, this.tradeAccountIns.apiKey);
      this.$refs['position-panel-coin'].render(GeneralApiService.PLATFORM.BINANCE, GeneralApiService.SYMBOL_TYPE.COIN_CONTRACT, this.tradeAccountIns.apiKey);
      GeneralExchangeService.getAssetMode(this.tradeAccountIns.id, function (data) {
        that.assetMode = data;
      });
      TradeApiService.getAccountInfo(that.platform, this.tradeAccountIns.apiKey, 'ALL', function (data) {
        that.accountInfo = data;
        that.allUAsset = data.assets;
        let tmp = {};
        let totalU = 0;
        for (let key in data.assets) {
          if (data.assets[key].marginBalance !== 0) {
            tmp[key] = data.assets[key];
            let price = that.allPrice[data.assets[key].asset + 'USDT'] ? that.allPrice[data.assets[key].asset + 'USDT'] : (data.assets[key].asset.indexOf('USD') > -1 ? 1 : 0);
            totalU += price * data.assets[key].marginBalance;
          }
        }
        that.totalUCUsd = totalU.toFixed(2);
        that.moreThanZeroUAsset = tmp;
        that.renderingUAsset = false;
      });
      TradeApiService.getAccountInfo(that.platform, this.tradeAccountIns.apiKey, 'UC', function (data) {
        that.allCAsset = data;
        let tmp = {};
        let totalU = 0;
        for (let key in data) {
          if (data[key][0] > 0) {
            tmp[key] = {
              coin: key,
              asset: data[key][0]
            }
            let price = that.allPrice[key + 'USDT'] ? that.allPrice[key + 'USDT'] : (key.indexOf('USD') > -1 ? 1 : 0);
            totalU += price * data[key][0];
          }
        }
        that.totalCUsd = totalU.toFixed(2);
        that.moreThanZeroCAsset = tmp;
        that.renderingCAsset = false;
      });
      TradeApiService.getAllPosition(that.platform, this.tradeAccountIns.apiKey, function (data) {
        that.initPosition(data);
      });
      // TradeApiService.getAllPosition('BINANCE_COIN_FUTURE', this.tradeAccountIns.apiKey, function (data) {
      //   that.initCoinPosition(data);
      // });
      TradeApiService.getAllPrice(that.platform, 'SPOT', function (spot) {
        TradeApiService.getAllSpot(that.platform, that.tradeAccountIns.apiKey, function (data) {
          that.allSpot = data;
          let tmp = {};
          let totalU = 0;
          for (let spotKey in that.allSpot) {
            if (that.allSpot[spotKey] !== 0) {
              tmp[spotKey] = that.allSpot[spotKey];
              let price = that.allPrice[spotKey + 'USDT'] ? that.allPrice[spotKey + 'USDT'] : (spotKey === 'USDT' ? 1 : 0);
              totalU += price * that.allSpot[spotKey];
            }
          }
          that.totalSpotUsd = totalU.toFixed(2);
          that.moreThanZeroSpot = tmp;
          that.renderingSpot = false;
        });
        TradeApiService.getAllPrice(that.platform, 'UC', function (future) {
          that.allPrice = Object.assign(spot, future)
        });
      });
    },
    initPosition: function (data) {
      let tmp = {};
      let pnlT = 0;
      let totalUSize = 0;
      let that = this;
      for (let dataKey in data) {
        if (Number(data[dataKey].positionAmt) !== 0) {
          tmp[dataKey] = data[dataKey];
          pnlT += Number(data[dataKey].unrealizedProfit);
          totalUSize += Math.abs(data[dataKey].positionAmt) * Number(data[dataKey].entryPrice);
        }
      }
      that.totalSize = totalUSize.toFixed(2);
      that.totalPnl = pnlT.toFixed(2);
      that.position = tmp;
      that.renderingPosition = false;
    },
    initCoinPosition: function (data) {
      let tmp = {};
      let that = this;
      for (let dataKey in data) {
        if (Number(data[dataKey].positionAmt) !== 0) {
          tmp[dataKey] = data[dataKey];
        }
      }
      that.positionC = tmp;
      that.renderingPositionC = false;
    },
    transfer: function () {
      let that = this;
      this.renderingAssetTransfer = true;
      TradeApiService.assetTransfer(this.platform, this.tradeAccountIns.apiKey, this.transferCoin, this.transferAmount, this.transferType, function () {
        that.renderingAssetTransfer = false;
        Message.success('Success');
        that.renderData();
      }, function () {
        that.renderingAssetTransfer = false;
        Message.error('Please try again.');
      });
    }
  }
}
</script>

<style scoped>

.ellipsis-line-strategy {
  padding: 0;
  width: 90%;
  float: left;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.small-font {
  width: 90%;
  font-size: 12px;
  -webkit-transform: scale(1);
  text-align: right;
  display: inline-block;
  float: right;
  margin-right: 10px;
  color: #FFFFFF
}

@media only screen and  (max-width: 768px) {
  .small-font {
    width: 120%;
    font-size: 12px;
    -webkit-transform: scale(0.75);
    text-align: right;
    display: inline-block;
    float: right;
    margin-right: -2px;
    color: #FFFFFF
  }
}

.el-divider {
  border-top: 1px solid #444c56;
}

.el-collapse {
  color: #FFFFFF;
  border-top: 1px solid #444c56;
  border-bottom: 1px solid #444c56;
}

.el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  padding: 0;
  color: white;
}

</style>