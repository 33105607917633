<script>

function getLocation() {
  const self = this
  window.AMap.plugin('AMap.Geolocation', function () {
    let geolocation = new window.AMap.Geolocation({
      // 是否使用高精度定位，默认：true
      enableHighAccuracy: true,
      // 设置定位超时时间，默认：无穷大
      timeout: 10000,
    });

    geolocation.getCurrentPosition();
    window.AMap.event.addListener(geolocation, 'complete', onComplete);
    window.AMap.event.addListener(geolocation, 'error', onError);

    function onComplete(data) {
      // data是具体的定位信息
      console.log('定位成功信息：', data);
    }

    function onError(data) {
      // 定位出错
      console.log('定位失败错误：', data);
      // 调用ip定位
      self.getLatLocationByIP();
    }
  })
}

//调用ip定位
function getLatLocationByIP(success) {
  window.AMap.plugin('AMap.CitySearch', function () {
    var citySearch = new window.AMap.CitySearch();
    citySearch.getLocalCity(function (status, result) {
      if (status === 'complete' && result.info === 'OK') {
        // 查询成功，result即为当前所在城市信息
        // console.log('通过ip获取当前城市：', result)
        success(result);
      }
    })
  })
}

export default {
  getLocation,
  getLatLocationByIP
}
</script>