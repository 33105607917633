<script type="text/javascript">
import ServiceMapper from "@/const/ServiceMapper";
import AbmNet from "@/api/net/AbmNet";

function getAssetMode(tid, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.generalExchangeServiceGetAssetMode + '?tradeAccountId=' + tid, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function updateAssetMode(tid, mode, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.generalExchangeServiceChangeAssetMode, {
    tradeAccountId: tid,
    mode: mode
  }, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function updateLeverage(tid, symbol, leverage, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.generalExchangeServiceUpdateLeverage, {
    tradeAccountId: tid,
    symbol: symbol,
    leverage: leverage
  }, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

export default {
  getAssetMode,
  updateAssetMode,
  updateLeverage
}


</script>