<template>
  <div :dto="dto">
    <el-skeleton animated :loading="rendering" :throttle="268">
      <template slot="template">
        <trialpha-sk style="margin-top: 0;width: 60%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 40%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 100%;float: left"></trialpha-sk>
      </template>
      <template>
        <el-row>
          <el-collapse class="trialpha-collapse" v-model="activeNames" style="border: 0">
            <el-collapse-item class="trialpha-collapse" style="border: 0" name="UNI Hedge" v-for="(ins, index) in instanceList" :key="index">
              <template slot="title">
                <el-row style="width: 100%">
                  <el-col :span="4" style="padding: 0">
                    <el-card class="black-card" body-style="padding: 0" style="line-height: 46px;margin-left: -10px;margin-top: 9px;border-left: 0;border-top: 0;border-bottom: 0;border-radius: 0;height: 46px;width: 80px;background-color: #444c56">
                      <span class="dark-mode-font" style="padding: 0;font-size: 12px;width: 100%;display: inline-block;text-align: center">{{ getPrice(ins.strategyConfig) }}</span>
                    </el-card>
                  </el-col>
                  <el-col :span="16">
                    <el-row style="display: flex">
                      <span class="dark-mode-font ellipsis-line" :style="'display: inline-block;text-align: center;' + (ins.isActive ? 'color: #FFFFFF' : '') + ''">{{ ins.strategyConfig.symbol }}</span>
                    </el-row>
                  </el-col>
                </el-row>
              </template>
              <el-row style="height: 518px;overflow-y: auto">
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">实例状态</span>
                  </el-col>
                  <el-col :span="11" :offset="1">
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <el-select class="select-symbol" style="width: 100%" v-model="ins.isActive" placeholder="请选择">
                      <el-option
                          v-for="item in [{value: true, label: 'Active'}, {value: false, label: 'Inactive'}]"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="12" :offset="1" style="display: flex">
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">单次最大成交额</span>
                  </el-col>
                  <el-col :span="11" :offset="1">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">杠杆数</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="ins.strategyConfig.maxUsdPerTime"></el-input>
                  </el-col>
                  <el-col :span="12" :offset="1" style="display: flex">
                    <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="ins.strategyConfig.leverage"></el-input>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">调仓次数</span>
                  </el-col>
                  <el-col :span="11" :offset="1">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">累计交易量</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11" style="display: flex">
                    <el-input class="trialpha-dark-input trialpha-dark-input-para"  :value="ins.strategyConfig.changeTimes" disabled></el-input>
                  </el-col>
                  <el-col :span="12" :offset="1" style="display: flex">
                    <el-input class="trialpha-dark-input trialpha-dark-input-para" :value="ins.strategyConfig.tradeVol.toFixed(2)" disabled></el-input>
                  </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row style="width: 98%;margin: 10px auto">
                  <el-col :span="8">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 3px 0 0 3px;border-right: 0" @click="reset(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i class="el-icon-c-scale-to-original" style="font-size: 16px"/></el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0;border-right: 0" @click="deleteIns(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i class="el-icon-delete" style="font-size: 16px"/></el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0 3px 3px 0;border-top: 0" @click="save(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i class="el-icon-check" style="font-size: 16px"/></el-button>
                  </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-card class="black-card" shadow="none" style="height: 178px">
                  <el-row>
                    <span class="dark-mode-font" style="color: #FFFFFF">Standard position</span>
                  </el-row>
                  <div style="margin-top: 10px;width: 100%;" v-if="ins.strategyConfig && ins.strategyConfig.rightPositionMap">
                    <el-card class="black-card" style="margin: 8px 8px 15px 8px;float: left;width: 198px;height: 35px" v-for="item in Object.keys(ins.strategyConfig.rightPositionMap)" :key="item" shadow="none" body-style="padding: 3px 5px">
                      <el-row>
                        <el-col :span="15">
                          <span class="dark-mode-font" style="width: 100%;display: inline-block;text-align: left">{{ item.replace('USDT', '').replace('BUSD', '') }}</span>
                        </el-col>
                        <el-col :span="9">
                          <span class="dark-mode-font" style="width: 100%;display: inline-block;text-align: right">{{ Number(ins.strategyConfig.rightPositionMap[item]).toFixed(3) }}</span>
                        </el-col>
                      </el-row>
                    </el-card>
                  </div>
                  <div style="margin-top: 10px;width: 100%;" v-if="!(ins.strategyConfig && ins.strategyConfig.rightPositionMap)">
                    <span class="dark-mode-font">尚无数据</span>
                  </div>
                </el-card>
              </el-row>
            </el-collapse-item>
          </el-collapse>
          <el-collapse class="trialpha-collapse" v-model="createCollapse" v-if="instanceList.length === 0" style="border-top: 0">
            <el-collapse-item class="trialpha-collapse">
              <template slot="title">
                <i class="el-icon-menu" style="margin-left: 2px;color: #0ecb81;margin-top: 1px"/><h4 class="dark-mode-font" style="margin-left: 8px;padding-top: 6px;color: white">Create New Instance</h4>
              </template>
              <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                <el-col :span="11">
                  <span class="dark-mode-font" style="color: white;font-size: 12px">对冲Token</span>
                </el-col>
                <el-col :span="11" :offset="1">
                  <span class="dark-mode-font" style="color: white;font-size: 12px">杠杆数</span>
                </el-col>
              </el-row>
              <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                <el-col :span="11" style="display: flex">
                  <el-select class="chose-symbol select-symbol" filterable v-model="instance.symbol" placeholder="Hedge token" style="width: 100%;line-height: 35px">
                    <el-option
                        v-for="item in (symbolArrays)"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="11" :offset="1" style="display: flex">
                  <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="instance.leverage"></el-input>
                </el-col>
              </el-row>
              <el-row style="height: 88px;overflow-y: auto">
                <el-divider></el-divider>
                <el-row style="width: 98%;margin: 10px auto">
                  <el-col :span="24">
                    <el-button class="dark-mode-btn" v-auth:dbWriteApi style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 3px" @click="add(instance)" :disabled="rendering" :loading="processing"><i class="el-icon-check" style="font-size: 16px"/></el-button>
                  </el-col>
                </el-row>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-row>
      </template>
    </el-skeleton>
  </div>
</template>

<script>
import {Message} from "element-ui";
import StrategyInstanceService from "@/api/trade/StrategyInstanceService";
import ConfirmProvider from "@/components/ConfirmProvider";
import TradeApiService from "@/api/trade/TradeApiService";
import TrialphaSk from "@/components/TrialphaSk";
import SymbolInfoService from "@/utils/SymbolInfoService";
import GeneralApiService from "@/api/trade/GeneralApiService";

export default {
  name: "UniHedge",
  components: {TrialphaSk},
  props: {
    dto: Object
  },
  data: function () {
    return {
      processing: false,
      rendering: true,
      activeNames: ['1'],
      createCollapse: ['1'],
      instance: {},
      instanceList: [],
      accountInstance: {},
      allPrice: {},
      allSymbols: [],
      symbolArrays: SymbolInfoService.getSymbolList(GeneralApiService.PLATFORM.DYDX, GeneralApiService.SYMBOL_TYPE.USD_CONTRACT)
    }
  },
  created() {
    this.closeReadOnly(true);
  },
  methods: {
    getPrice(instance) {
      return SymbolInfoService.getSymbolPrice(GeneralApiService.PLATFORM.DYDX, GeneralApiService.SYMBOL_TYPE.USD_CONTRACT, instance.symbol);
    },
    closeReadOnly(val) {
      this.$nextTick(() => {
        if (val) {
          this.$el.querySelectorAll('.chose-symbol.select-symbol .el-input__inner').forEach((item) => {
            item.removeAttribute('readonly');
          })
        }
      })
    },
    openTradeTools(ins) {
      this.$nextTick(function () {
        this.$refs['open-tools'].renderFtx(ins);
      });
    },
    handleShortLong(ins) {
      ins.symbol1Short = !ins.symbol1Short;
    },
    render: function (accountInstance) {
      let that = this;
      that.rendering = true;
      that.accountInstance = accountInstance;
      that.instance = JSON.parse(JSON.stringify(that.dto.strategyConfig));
      TradeApiService.getAllPrice('BINANCE', 'UC', function (allPrice) {
        that.allPrice = allPrice;
        let symbols = [];
        for (const allPriceKey in allPrice) {
          symbols.push(allPriceKey)
        }
        that.allSymbols = symbols;
        StrategyInstanceService.query(that.accountInstance.instanceId, that.dto.strategyName, function (data) {
          that.instanceList = data.strategyInsList;
          that.rendering = false;
        }, function () {
          that.rendering = false;
        });
      });
    },
    reset: function (ins) {
      let that = this;
      ConfirmProvider.openConfirm(this, '确认重置', '该操作将会重置所有非配置的数据', function () {
        that.processing = true;
        StrategyInstanceService.reset(ins.sid, function () {
          that.processing = false;
          Message.success('已成功重置');
          that.render(that.accountInstance);
        }, function () {
          that.processing = false;
        });
      });
    },
    deleteIns: function (ins) {
      let that = this;
      ConfirmProvider.openConfirm(this, '确认删除', '该操作将会删除该实例，此操作无法恢复', function () {
        that.processing = true;
        StrategyInstanceService.deleteIns(ins.sid, function () {
          that.processing = false;
          Message.success('已成功删除');
          that.render(that.accountInstance);
        }, function () {
          that.processing = false;
        });
      });
    },
    save: function (ins) {
      let that = this;
      that.processing = true;
      StrategyInstanceService.update({
        sid: ins.sid,
        strategyName: ins.strategyName,
        config: (ins.strategyConfig),
        instanceEntity: {
          id: that.accountInstance.instanceId
        },
        isActive: ins.isActive
      }, function () {
        that.processing = false;
        Message.success('已成功更新');
        that.render(that.accountInstance);
      }, function () {
        that.processing = false;
      });
    },
    add: function (ins) {
      let that = this;
      that.processing = true;
      StrategyInstanceService.create({
        insId: this.accountInstance.instanceId,
        strategyName: this.dto.strategyName,
        config: JSON.stringify(ins)
      }, function () {
        Message.success('已成功创建新的实例');
        that.processing = false;
        setTimeout(() => that.render(that.accountInstance), 888);
      }, function () {
        that.processing = false;
      });
    }
  }
}
</script>

<style scoped>

.ellipsis-line {
  padding: 8px;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-collapse {
  color: #FFFFFF;
  border-top: 1px solid #444c56;
  border-bottom: 1px solid #444c56;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: white;
}

.el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-divider {
  border-top: 1px solid #444c56;
}

.el-divider.info {
  width: 80%;
  border-top: 1px solid #444c56;
}

.el-divider__text {
  color: white;
  background-color: #444c56;
}

.select-symbol .el-input__inner {
  color: white;
  height: 35px !important;
  line-height: 35px;
  width: 100%;
  padding: 0 10px 0 10px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-right: 0;
  border-radius: 0;
  font-size: 12px;
  outline: none;
}

.select-symbol.el-select .el-input.is-focus .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:hover .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:hover .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:focus .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:visited .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:focus .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:visited .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

</style>