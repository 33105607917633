<template>
  <el-row style="width: 100%">
    <div id="SettlementProfitStaticsId" ref="SettlementProfitStaticsRef" style="width: 100%;height: 240px;margin-top: -8px;"></div>
  </el-row>
</template>

<script>

export default {
  name: "SettlementProfitStatics",
  data: function () {
    return {
      renderingInstance: true,
      statistics: [],
      partyAProfit: 3,
      partyBProfit: 7
    }
  },
  methods: {
    activePanel(instanceData) {
      this.$nextTick(() => {
        this.loadInstance(instanceData);
      })
    },
    getOption(partyA, partyB) {
      return {
        tooltip: {
          trigger: 'item'
        },
        grid: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 0
        },
        series: [
          {
            name: 'Asset Dollar Ratio',
            type: 'pie',
            radius: ['42%', '72%'],
            center: ['45%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: true,
              textStyle: {
                fontFamily: 'DIN-Bold',
                fontSize: 12
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '12',
                fontWeight: 'bold'
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            labelLine: {
              show: true
            },
            data: [
              {value: this.partyAProfit.toFixed(2), name: partyA, itemStyle: {color: 'rgba(55,164,215,0.93)'}},
              {value: this.partyBProfit.toFixed(2), name: partyB, itemStyle: {color: 'rgba(247,147,26, 0.86)'}}
            ]
          }
        ]
      }
    },
    render(partyA, partyB, ftValue, userValue) {
      let that = this;
      that.partyBProfit = ftValue;
      that.partyAProfit = userValue;
      if (that.$refs.SettlementProfitStaticsRef) {
        that.chart = that.$echarts.init(that.$refs.SettlementProfitStaticsRef);
        that.chart.clear();
        that.chart.setOption(that.getOption(partyA, partyB));
      }
      window.addEventListener('resize', () => {
        if (that.chart) {
          that.chart.resize();
        }
      });
    }
  }
}
</script>

<style scoped>

@media only screen and (min-width: 820px) {
  .show-less-820 {
    display: none !important
  }
}

@media only screen and (max-width: 820px) {
  .show-than-820 {
    display: none !important
  }
}

@media only screen and (max-width: 1240px) {
  .show-more-1240 {
    display: none !important
  }
}

@media only screen and (min-width: 1240px) {
  .show-less-1240 {
    display: none !important
  }
}

</style>