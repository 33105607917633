<script type="text/javascript">

import ArbContract from "@/web3/contract/gmx/ArbContract";

function mint(web3, success, error) {
  let contract = ArbContract.getContract(this);
  contract.methods.claim().send({from: web3.currentProvider.selectedAddress}).on("receipt", function(receipt) {
    success(receipt);
  }).on("error", function(err) {
    error(err);
  });
}

export default {
  mint
}


</script>