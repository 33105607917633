<template>
    <div>

        <div class="ft-block-list" :class="[(scrollHeight > 0 ? 'scroll-mode' : '')]" :style="(scrollHeight > 0 ? {height: scrollHeight + 'px'} : {})">
            <div v-for="(item, index) in channelListShow" v-bind:key="index">
                <!-- 渠道头部信息 -->
                <div class="ft-block-list-item" @click="eventTap(item)">
                    <div style="display: flex; width: 100%;">
                        <div style="width: 15%;">
                            <i v-if="item.belong == userName" class="el-icon-star-on" style="margin-right: 5px; color: #FFB324;"></i>
                            <i v-if="item.belong != userName" class="el-icon-star-on" style="margin-right: 5px; color: #FFB324; visibility: hidden;"></i>
                            <span style="color: #3C2700; background: #FFB324; padding: 3px 10px; border-radius: 8px; font-sie: 12px;">
                                渠道
                            </span>
                            <!-- <span style="color: #3C2700; background: #FFB324; padding: 3px 10px; border-radius: 8px; font-sie: 12px; margin-left: 5px; text-align: center;"
                                v-if="item.belong == userName">
                                我的
                            </span> -->
                        </div>
                        <div style="width: 20%;padding-left: 10px" class="ellipsis" :title="item.name">
                            {{item.name}}
                        </div>
<!--                         <div style="width: 25%;">总金额：{{item.fundsAmount}}  USDT</div>-->
                         <div style="width: 20%;">客户数：{{item.customerNumber}}</div>
                        <!-- <div style="width: 20%;">渠道归属：{{item.belong}}</div> -->
<!--                        <div style="width: 30%;" class="ellipsis" :title="item.info">渠道背景：{{item.info ? item.info : '待输入'}}</div>-->
                        <div style="width: 24%;">总金额：{{item.totalFundsAmount ? item.totalFundsAmount : '0'}}</div>
                        <div style="width: 20%;">归属：{{item.belong ? item.belong : '待输入'}}</div>

                        <div style="width: 5%; text-align: right;">
                            <el-button v-if="item.belong == userName || hasFullPermission" class="dark-mode-btn" style="background: rgba(0, 0, 0, 0);border: 0"
                                @click.stop="onDeleteChannel(item)"><i class="el-icon-delete-solid"></i></el-button>
                        </div>
                        <div style="width: 5%; text-align: right;">
                            <i v-if="(item.belong == userName) || hasFullPermission" :class="[item.id == channelActive ? 'el-icon-arrow-down' : 'el-icon-arrow-right']"
                                style="cursor: pointer;"
                                @click.stop="toggleCollapse(item)"></i>
                        </div>

                    </div>
                </div>
                <!-- 列表 -->
                <FtTableListWidget v-if="item.id == channelActive" :columns="columns" :data="entityListShow" 
                    v-on:checked-change="onCheckedChange"
                    v-on:checked-remove="onCheckedRemove"
                    v-on:action-cell="onAccountStatusController"
                    style="margin-bottom: 16px;" />
            </div>
        </div>

        <!-- 分页器 -->
        <FtPagingWidget style="display: none;"
            :pageIndex="paging.pageIndex" 
            :pageSize="paging.pageSize" 
            :pageTotal="paging.pageTotal" 
            v-on:index-change="onPagingIndexChange" />

    </div>
</template>

<script>
import {Message} from "element-ui";
import FtTableListWidget from "@/components/widgets/FtTableListWidget";
import FtPagingWidget from "@/components/widgets/FtPagingWidget";
import ConfirmProvider from '@/components/ConfirmProvider'

import { useCrmStore, useCrmOutsideStore } from '@/store/crm'
import CrmChannelManagement from "@/api/crm/CrmChannelManagement";
import UserService from "@/api/user/UserService";
import UserPermissionService from "@/api/user/UserPermissionService";

export default {
    components: {
        FtTableListWidget,
        FtPagingWidget
    },
    props: {
        
    },
    data() {
        return {
            userName: '',
            hasFullPermission: false,
            header: [
                // {
                //     id: 1,
                //     name: '渠道',
                //     text1: '100,000,000  USDT',
                //     text2: '100%',
                //     text3: '2022.09.30',
                //     contactStatus: '接触中',
                //     runningStatus: '运行中',
                //     text6: '无'
                // },
                // {
                //     id: 2,
                //     name: '日不落秦',
                //     text1: '100,000,000  USDT',
                //     text2: '100%',
                //     text3: '2022.09.30',
                //     contactStatus: '沟通中',
                //     runningStatus: '运行中',
                //     text6: '无'
                // },
            ],
            // 滚动面板高度
            scrollHeight: 0,
            // 列表信息
            columns: [
                // {textI18n: '', text: '#', align: 'left', width: '8%', name: 'id', checkbox: true},
                {textI18n: '', text: '姓名', align: 'left', width: '25%', name: 'name'},
                {textI18n: '', text: '资金', align: 'left', width: '20%', name: 'startFundsAmount', render: (name, rowScope) => {
                    return rowScope[name] + ' ' + rowScope['coinName']
                }},
                // {textI18n: '', text: '最大回撤', align: 'left', width: '12%', name: 'maxDrawdown', render: (name, rowScope) => {
                //     return rowScope[name] + '%'
                // }},
                // {textI18n: '', text: '截止时间', align: 'left', width: '10%', name: 'settlementTimeString', render: (name, rowScope) => {
                //     return rowScope[name]
                // }},
                {textI18n: '', text: '进度', align: 'left', width: '25%', name: 'progress', render: (name, rowScope) => {
                    var value = rowScope[name]
                    // 接触中
                    if (value == '接触中') {
                        return '<span style="color: #FFB324; background: rgba(255, 179, 36, 0.1); padding: 3px 10px; border-radius: 8px; font-sie: 12px;">'
                            + value
                            + '</span>'
                    }
                    // 沟通中
                    if (value == '沟通中') {
                        return '<span style="color: #EEFF24; background: rgba(238, 255, 36, 0.1); padding: 3px 10px; border-radius: 8px; font-sie: 12px;">'
                            + value
                            + '</span>'
                    }
                    // 达成合作
                    if (value == '达成合作') {
                        return '<span style="color: #00C683; background: rgba(0, 198, 131, 0.1); padding: 3px 10px; border-radius: 8px; font-sie: 12px;">'
                            + value
                            + '</span>'
                    }
                    // 沟通失败
                    if (value == '沟通失败') {
                        return '<span style="background: #3E454E; padding: 3px 10px; border-radius: 8px; font-sie: 12px;">'
                            + value
                            + '</span>'
                    }
                    return ''
                }},
                {textI18n: '', text: '状态', align: 'left', width: '25%', name: 'accountStatus', render: (name, rowScope) => {
                    return this.getAccountStatusElementTag(name, rowScope)
                }},
                // {textI18n: '', text: '渠道归属', align: 'left', width: '10%', name: 'text6', render: (name, rowScope) => {
                //     return rowScope['channel']['name']
                // }},
              {
                textI18n: '',
                text: '',
                icon: 'el-icon-more',
                align: 'right',
                width: '5%',
                name: 'remove',
                remove: true,
                render: () => {
                  return '<el-button class="dark-mode-btn" style="background: rgba(0, 0, 0, 0);border: 0"><i class="el-icon-delete-solid"></i></el-button>';
                }
              }
            ],
            data: [
                // {
                //     id: 1,
                //     "accountNumber": 1,
                //     "accountStatus": "运行中",
                //     "accountTag": "string",
                //     "channel": {
                //         "id": 10,
                //         "name": "李宁渠道"
                //     },
                //     "coinName": "BTC",
                //     "followUser": "string",
                //     "fundUtilizationRate": 0.2,
                //     "maxDrawdown": 0,
                //     "name": "李宁",
                //     "platform": "FTX",
                //     "progress": "接触中",
                //     "settlementTime": "2022-07-01T02:29:23.114Z",
                //     "startFundsAmount": 22223.2,
                //     "strategyRemark": "string",
                //     "user": "string"
                // },
                // {
                //     id: 2,
                //     "accountNumber": 2,
                //     "accountStatus": "运行中",
                //     "accountTag": "string",
                //     "channel": {
                //         "id": 10,
                //         "name": "李宁渠道"
                //     },
                //     "coinName": "BTC",
                //     "followUser": "string",
                //     "fundUtilizationRate": 0.2,
                //     "maxDrawdown": 0,
                //     "name": "李宁2",
                //     "platform": "FTX",
                //     "progress": "接触中",
                //     "settlementTime": "2022-07-01T02:29:23.114Z",
                //     "startFundsAmount": 22223.2,
                //     "strategyRemark": "string",
                //     "user": "string"
                // }
            ],
            // 进度信息
            progress: '',
            // 模糊查询
            search: '',
            // 分页信息
            paging: {
                pageIndex: 0,
                pageSize: 20,
                pageTotal: 123
            },
            // 选中的渠道，展开
            channelActive: 0,
            // 选中的客户详情
            personalActive: null,

            // 查询模式, list/search
            queryMode: 'list',

            // 交易所列表
            platforms: [
                { code: "-1", desc: "请选择"},
                { code: "0", desc: "FTX"},
                { code: "1", desc: "Binance"},
                { code: "2", desc: "OKX"},
                { code: "3", desc: "Deribit"},
                { code: "4", desc: "BITMEX"},
                { code: "5", desc: "Bybit"},
                { code: "6", desc: "Kucoin"},
                { code: "7", desc: "Huobi"},
                { code: "8", desc: "Gate"},
                { code: "9", desc: "Bitcoke"},
                { code: "10", desc: "MEXC"},
            ],

            // 总金额数据
            totalFundsAmountMap: {}
        }
    },
    computed: {
        // 显示的列表
        channelListShow: () => {
            var crmOutsideStore = useCrmOutsideStore()
            return crmOutsideStore.channelList
        },
        // 显示的账号列表
        entityListShow: () => {
            var crmOutsideStore = useCrmOutsideStore()
            return crmOutsideStore.channelListShow.map(item => {
                return item.view
            })
        }
        
    },
    mounted() {
        console.log('渠道面板')
        this.userName = UserService.getUserName()
        this.hasFullPermission = UserPermissionService.isAllowed(UserPermissionService.all_permission.crmFullPermission);
        this.channelList()

        var windowHeight = window.innerHeight
        this.scrollHeight = windowHeight - 180 - 65
    },
    methods: {
        // 设置查询条件
        setWhile(name, value) {
            if (name == 'progress') {
                this.progress = value
                this.search = ''
                this.paging.pageIndex = 0
                this.queryMode = 'list'
                this.channelList().then(() => {
                    // 查询总金额
                    this.getTotalFundsAmountMap()
                })
            }
            if (name == 'search') {
                this.search = value
                this.queryMode = 'search'
                this.likeChannelList().then(() => {
                    // 查询总金额
                    this.getTotalFundsAmountMap()
                })
            }
        },

        // 根据当前条件刷新
        setRefresh() {
            return new Promise(resolve => {

                if (this.queryMode == 'list') {
                    this.channelList().then(() => {
                        this.header.forEach((item) => {
                            if (item.id == this.channelActive) {
                                this.data = item.forthtechCustomerInfoEntityDtos.map(m => {
                                    var d = new Date(m.settlementTime * 1000)
                                    m.settlementTimeString = d.getFullYear() + '-' + 
                                        ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + '-' + 
                                        (d.getDate() < 10 ? '0' + d.getDate() : d.getDate())
                                    return m
                                })
                                if (this.personalActive) {
                                    var active = this.personalActive
                                    var detail = this.data.filter(m => {
                                        return m.id == active.id
                                    })[0]
                                    if (detail) {
                                        this.$emit('checked-refresh', 'personal', detail);
                                    }
                                }
                            }
                        })
                        // 查询总金额
                        this.getTotalFundsAmountMap()
                        resolve()
                    }, () => {
                        resolve()
                    })
                }
                if (this.queryMode == 'search') {
                    this.likeChannelList().then(() => {
                        this.header.forEach((item) => {
                            if (item.id == this.channelActive) {
                                this.data = item.forthtechCustomerInfoEntityDtos.map(m => {
                                    var d = new Date(m.settlementTime * 1000)
                                    m.settlementTimeString = d.getFullYear() + '-' + 
                                        ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + '-' + 
                                        (d.getDate() < 10 ? '0' + d.getDate() : d.getDate())
                                    return m
                                })
                                if (this.personalActive) {
                                    var active = this.personalActive
                                    var detail = this.data.filter(m => {
                                        return m.id == active.id
                                    })[0]
                                    if (detail) {
                                        this.$emit('checked-refresh', 'personal', detail);
                                    }
                                }
                            }
                        })
                        // 查询总金额
                        this.getTotalFundsAmountMap()
                        resolve()
                    }, () => {
                        resolve()
                    })
                }
            })
        },

        channelList() {
            return new Promise((resolve, reject) => {
                var crmStore = useCrmStore()
                crmStore.loadChannelData().then(() => {
                    resolve()
                }, err => {
                    reject(err)
                })
                // CrmChannelManagement.queryChannel(this.paging.pageIndex + 1, this.paging.pageSize, this.progress, '',
                //     (data) => {
                //         data.content.forEach((item) => {
                //             if (!item.forthtechCustomerInfoEntityDtos) {
                //                 item.forthtechCustomerInfoEntityDtos = []
                //             }
                //             item.totalFundsAmount = 0
                //         })
                //         this.header = data.content.filter(item => {
                //             return item.belong == this.userName
                //         }).concat(data.content.filter(item => {
                //             return item.belong != this.userName
                //         }))
                //         this.paging.pageTotal = data.totalElements
                //         resolve()
                //     },
                //     (err) => {
                //         // Message.error(this.$i("operationFail"));
                //         reject(err)
                //     }
                // );
            })
        },

        likeChannelList() {
            return new Promise((resolve, reject) => {
                CrmChannelManagement.likeQueryChannel(this.search,
                    (data) => {
                        data.forEach((item) => {
                            if (!item.forthtechCustomerInfoEntityDtos) {
                                item.forthtechCustomerInfoEntityDtos = []
                            }
                            item.totalFundsAmount = 0
                        })
                        this.header = data.filter(item => {
                            return item.belong == this.userName
                        }).concat(data.filter(item => {
                            return item.belong != this.userName
                        }))
                        this.paging.pageTotal = data.totalElements
                        resolve()
                    },
                    (err) => {
                        // Message.error(this.$i("operationFail"));
                        reject(err)
                    }
                );
            })
        },

        onPagingIndexChange(index) {
            console.log('onPagingIndexChange', index)
            this.paging.pageIndex = index
            this.setRefresh()
        },

        getTotalFundsAmountMap() {
            this.header.forEach(item => {
                this.getChannelFundsAmount(item.id)
            })
        },


        getChannelFundsAmount(channelId) {
            return new Promise((resolve, reject) => {
                CrmChannelManagement.getChannelFundsAmount(channelId,
                    (data) => {
                        this.totalFundsAmountMap[channelId] = data
                        this.header.forEach(h => {
                            if (h.id == channelId) {
                                h.totalFundsAmount = CrmChannelManagement.formatMoneyK(data)
                            }
                        })
                        resolve(data)
                    },
                    () => {
                        // Message.error(this.$i("operationFail"));
                        reject()
                    }
                );
            })
        },

        eventTap(item) {
            if (item.belong !== this.userName && !this.hasFullPermission) {
                this.channelActive = 0
                this.$emit('checked-change', 'detailclear', item);
                Message.error('你没有查看权限')
                return
            }
            this.channelActive = item.id
            var crmStore = useCrmStore()
            // this.data = 
            crmStore.setChannelExpandData(item.forthtechCustomerInfoEntityDtos)
            this.$emit('checked-change', 'channel', item);
        },

        toggleCollapse(item) {
            if (this.channelActive > 0) {
                if (this.channelActive == item.id) {
                    this.channelActive = 0
                } else {
                    this.eventTap(item)
                }
            } else {
                this.eventTap(item)
            }
        },

        onCheckedChange(item) {
            this.personalActive = item
            var crmStore = useCrmStore()
            crmStore.setCurrentSource('channel')
            this.$emit('checked-change', 'personal', item);
        },

        onCheckedRemove(item) {
            console.log('删除动作', item)
          ConfirmProvider.openConfirm(this, '删除操作', '请确认删除该客户: ' + item.name, () => {
            CrmChannelManagement.deleteCustomer(item.id,
                () => {
                  this.setRefresh()
                },
                () => {
                  // Message.error(this.$i("operationFail"));
                }
            );
          });
        },

        onDeleteChannel(item) {
            console.log('删除动作', item.id)
            ConfirmProvider.openConfirm(this, '删除操作', '请确认删除该渠道: ' + item.name, () => {
                CrmChannelManagement.deleteChannel(item.id,
                    () => {
                        Message.success('删除成功');
                        let index = -1
                        this.header.filter((m, i) => {
                            if (m.id == item.id) {
                                index = i
                            }
                        })
                        this.header.splice(index, 1)
                    },
                    () => {
                        // Message.error(this.$i("operationFail"));
                    }
                );
            });
        },

        // Registered : 显示: KYC审核  点击后，更新状态为KYCChecked
        // KYCChecked : 显示: 生成连接  点击后，然后调用createCasualID，返回UUID后，引导用户去填写api信息，状态会变成SettingApi，然后调用setApiInfo
        // Setting    : 显示: 账号配置  点击后，更新状态为Handing
        // Handing    : 显示: 待运行    点击后，更新状态为Running
        // Running    : 显示: 运行中
        getAccountStatusElementTag(name, item) {
            var value = item[name]
            var html = ''
            if (value == 'Registered') {
                html += '<span style="color: #EEFF24; background: rgba(238, 255, 36, 0.1); padding: 3px 10px; border-radius: 8px; font-sie: 12px; cursor: pointer;">'
                html += 'KYC审核'
                html += '</span>'
            } else if (value == 'KYCChecked') {
                html += '<span style="color: #EEFF24; background: rgba(238, 255, 36, 0.1); padding: 3px 10px; border-radius: 8px; font-sie: 12px; cursor: pointer;">'
                html += '生成连接'
                html += '</span>'
            } else if (value == 'SettingApi') {
                html += '<span style="background: #3E454E; padding: 6px 12px; border-radius: 8px; font-size: 12px;">'
                html += 'Api配置'
                html += '</span>'
            } else if (value == 'Setting') {
                html += '<span style="color: #EEFF24; background: rgba(238, 255, 36, 0.1); padding: 3px 10px; border-radius: 8px; font-sie: 12px; cursor: pointer;">'
                html += '账号配置'
                html += '</span>'
            } else if (value == 'Handing') {
                html += '<span style="color: #EEFF24; background: rgba(238, 255, 36, 0.1); padding: 3px 10px; border-radius: 8px; font-sie: 12px; cursor: pointer;">'
                html += '待运行'
                html += '</span>'
            } else if (value == 'Running') {
                html += '<span style="background: #3E454E; padding: 3px 10px; border-radius: 8px; font-sie: 12px;">'
                html += '运行中'
                html += '</span>'
            }
            return html
        },

        // 账号状态流程控制
        // Registered : 显示: KYC审核  点击后，confirm 更新状态为KYCChecked
        // KYCChecked : 显示: 生成连接  点击后，confirm 然后调用createCasualID，返回UUID后，引导用户去填写api信息，状态会变成SettingApi，然后调用setApiInfo
        // Setting    : 显示: 账号配置  点击后， confirm 更新状态为Handing
        // Handing    : 显示: 待运行    点击后， confirm 更新状态为Running
        // Running    : 显示: 运行中
        onAccountStatusController(name, item) {
            var that = this
            if (name == 'accountStatus') {
                console.log('账号状态流程控制', item)
                var customer = item
                var value = item[name]
                if (value == 'Registered') {
                    // html += 'KYC审核'
                    ConfirmProvider.openConfirm(that, '操作确认', '请确认用户的KYC资料', function () {
                        var form = {
                            accountNumber: customer.accountNumber,
                            accountStatus: 'KYCChecked',
                            accountTag: customer.accountTag,
                            channelId: customer.channel.id,
                            coinName: customer.coinName,
                            customerId: customer.id,
                            followUser: customer.followUser,
                            fundUtilizationRate: customer.fundUtilizationRate,
                            maxDrawdown: customer.maxDrawdown,
                            name: customer.name,
                            platform: (() => {
                                var m = that.platforms.filter(item => {
                                    return item.desc == customer.platform
                                })[0]
                                return m ? m.code : ''
                            })(),
                            progress: customer.progress,
                            settlementTime: new Date(customer.settlementTime * 1000).getTime(),
                            startFundsAmount: customer.startFundsAmount,
                            strategyRemark: customer.strategyRemark,
                            user: customer.user,
                        }
                        console.log('>>>', form)
                        CrmChannelManagement.updateCustomerInfo(form,
                            () => {
                                that.setRefresh()
                            },
                            () => {
                                // Message.error(that.$i("operationFail"));
                            }
                        );
                    });
                } else if (value == 'KYCChecked') {
                    // html += '生成连接'
                    ConfirmProvider.openConfirm(that, '操作确认', '请确认生成连接', function () {
                        CrmChannelManagement.createCasualID(customer.id,
                            (data) => {
                                ConfirmProvider.openAlert(that, customer.name + '专属URL', 'https://quant.forthtech.io/ap/customerKey?randomID=' + data, function () {
                                    that.setRefresh()
                                })
                            },
                            () => {
                                // Message.error(that.$i("operationFail"));
                            }
                        );
                    });
                } else if (value === 'SettingApi') {
                // SettingApi状态时，等待设置完成后，会自动变成Setting
                } else if (value == 'Setting') {
                    // html += '账号配置'
                    ConfirmProvider.openConfirm(that, '操作确认', '请确认客户的账号全部配置完毕', function () {
                        var form = {
                            accountNumber: customer.accountNumber,
                            accountStatus: 'Handing',
                            accountTag: customer.accountTag,
                            channelId: customer.channel.id,
                            coinName: customer.coinName,
                            customerId: customer.id,
                            followUser: customer.followUser,
                            fundUtilizationRate: customer.fundUtilizationRate,
                            maxDrawdown: customer.maxDrawdown,
                            name: customer.name,
                            platform: (() => {
                                var m = that.platforms.filter(item => {
                                    return item.desc == customer.platform
                                })[0]
                                return m ? m.code : ''
                            })(),
                            progress: customer.progress,
                            settlementTime: new Date(customer.settlementTime * 1000).getTime(),
                            startFundsAmount: customer.startFundsAmount,
                            strategyRemark: customer.strategyRemark,
                            user: customer.user,
                        }
                        CrmChannelManagement.updateCustomerInfo(form,
                            () => {
                                that.setRefresh()
                            },
                            () => {
                                // Message.error(that.$i("operationFail"));
                            }
                        );
                    });
                } else if (value == 'Handing') {
                    // html += '待运行'
                    ConfirmProvider.openConfirm(that, '操作确认', '请确认客户的账号已经启动完毕', function () {
                        var form = {
                            accountNumber: customer.accountNumber,
                            accountStatus: 'Running',
                            accountTag: customer.accountTag,
                            channelId: customer.channel.id,
                            coinName: customer.coinName,
                            customerId: customer.id,
                            followUser: customer.followUser,
                            fundUtilizationRate: customer.fundUtilizationRate,
                            maxDrawdown: customer.maxDrawdown,
                            name: customer.name,
                            platform: (() => {
                                var m = that.platforms.filter(item => {
                                    return item.desc == customer.platform
                                })[0]
                                return m ? m.code : ''
                            })(),
                            progress: customer.progress,
                            settlementTime: new Date(customer.settlementTime * 1000).getTime(),
                            startFundsAmount: customer.startFundsAmount,
                            strategyRemark: customer.strategyRemark,
                            user: customer.user,
                        }
                        CrmChannelManagement.updateCustomerInfo(form,
                            () => {
                                that.setRefresh()
                            },
                            () => {
                                // Message.error(that.$i("operationFail"));
                            }
                        );
                    });
                } else if (value == 'Running') {
                    // html += '运行中'
                }
            } else if (name === 'remove') {
              this.onCheckedRemove(item);
            }  else {
                that.onCheckedChange(item)
            }
        }
        
    }
}
</script>

<style scoped>

.ft-block-list {
    border-bottom: 1px solid #3E454E;
}

.ft-block-list.scroll-mode{
    overflow-y: auto;
}

.ft-block-list .ft-block-list-header {
    display: flex; width: 100%; padding: 4px 24px; line-height: 34px; text-align: left; border: 1px solid #3E454E; color: #999999; border-radius: 8px; font-size: 12px; margin-bottom: 16px;
}

.ft-block-list .ft-block-list-item {
    display: flex; width: 100%; padding: 20px 24px; line-height: 34px; text-align: left; border: 1px solid #3E454E; color: #ffffff; border-radius: 8px; font-size: 14px; margin-bottom: 16px;
    background: #22272E;
}

.ft-block-list .ft-block-list-item .ft-checkbox{
    width: 20px; height: 20px; border-radius: 4px; border: 1px solid #3E454E; margin-top: 7px;
}

.ft-block-list .ft-block-list-item .ft-checkbox > div{
    width: 12px; height: 12px; background: transparent; border-radius: 2px; margin: 3px;
}

.ft-block-list .ft-block-list-item .ft-checkbox.active > div{
    background: #fff;
}

.ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding-right: 16px;
}
</style>