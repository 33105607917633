<template>
  <div>
    <el-drawer
        size="500px"
        :close-on-click-modal=false
        :close-on-press-escape=false
        :wrapperClosable=false
        custom-class="black-card"
        :visible.sync="drawer"
        direction="ltr"
        style="overflow-y: auto">
      <div style="padding: 10px" v-if="userObj">
        <h4 class="dark-mode-font" style="font-size: 20px;color: white"><b>{{ $i('userInfo.userInfoSetting') }}</b></h4>
        <el-row style="margin-top: 35px">
          <el-col :span="7" style="padding-left: 25px">
            <span class="dark-mode-font" style="color: white;float: left">{{ $i('userInfo.alias') }}</span>
          </el-col>
          <el-col :span="17" style="padding-right: 25px">
            <input class="black-card" style="width: 100%;outline: none;color: white;padding: 8px 10px 8px 10px;font-size: 12px;height: 28px;line-height: 28px" v-model="userObj.userInfo.alias" :disabled="userObj.web3Login" />
          </el-col>
        </el-row>
        <el-row style="margin-top: 25px">
          <el-col :span="7" style="padding-left: 25px">
            <span class="dark-mode-font" style="color: white;float: left">{{ $i('userInfo.email') }}</span>
          </el-col>
          <el-col :span="17" style="padding-right: 25px">
            <input class="black-card" style="width: 100%;outline: none;color: white;padding: 8px 10px 8px 10px;font-size: 12px;height: 28px;line-height: 28px" v-model="userObj.userInfo.email" :disabled="userObj.web3Login" />
          </el-col>
        </el-row>
        <el-row style="margin-top: 25px">
          <el-col :span="7" style="padding-left: 25px">
            <span class="dark-mode-font" style="color: white;float: left">{{ $i('userInfo.username') }}</span>
          </el-col>
          <el-col :span="17" style="padding-right: 25px">
            <input class="black-card" style="width: 100%;outline: none;color: white;padding: 8px 10px 8px 10px;font-size: 12px;height: 28px;line-height: 28px" v-model="userObj.userInfo.username" :disabled="userObj.web3Login" />
          </el-col>
        </el-row>
        <el-row style="margin-top: 25px">
          <el-col :span="7" style="padding-left: 25px">
            <span class="dark-mode-font" style="color: white;float: left">{{ $i('userInfo.password') }}</span>
          </el-col>
          <el-col :span="17" style="padding-right: 25px">
            <input class="black-card" style="width: 100%;outline: none;color: white;padding: 8px 10px 8px 10px;font-size: 12px;height: 28px;line-height: 28px" type="password" v-model="oldPassword" :disabled="userObj.web3Login" />
          </el-col>
        </el-row>
        <el-row style="margin-top: 25px">
          <el-col :span="7" style="padding-left: 25px">
            <span class="dark-mode-font" style="color: white;float: left">{{ $i('userInfo.newPassword') }}</span>
          </el-col>
          <el-col :span="17" style="padding-right: 25px">
            <input class="black-card" style="width: 100%;outline: none;color: white;padding: 8px 10px 8px 10px;font-size: 12px;height: 28px;line-height: 28px" type="password" v-model="newPassword"  :disabled="userObj.web3Login"/>
          </el-col>
        </el-row>
        <el-row style="margin-top: 25px">
          <el-col :span="7" style="padding-left: 25px">
            <span class="dark-mode-font" style="color: white;float: left">{{ $i('userInfo.verification') }}</span>
          </el-col>
          <el-col :span="11" style="">
            <input class="black-card" style="width: 100%;outline: none;color: white;padding: 8px 10px 8px 10px;font-size: 12px;height: 28px;line-height: 28px;border-radius: 3px 0 0 3px;border-right: 0" v-model="code" :disabled="userObj.web3Login" />
          </el-col>
          <el-col :span="6" style="padding-right: 25px">
            <el-button class="dark-mode-btn" style="height: 28px;line-height: 28px;padding: 0 10px 0 10px;width: 100%;border-radius: 0 3px 3px 0" @click="sendVerification" :disabled="sending || userObj.web3Login">{{ sending ? resend : $i('userInfo.send') }}</el-button>
          </el-col>
        </el-row>
        <el-row style="margin-top: 25px">
          <el-col :span="24" style="padding-left: 25px;padding-right: 25px">
            <el-button class="dark-mode-btn" style="height: 35px;line-height: 35px;padding: 0 10px 0 10px;width: 100%;border-radius: 3px" @click="update" :loading="processing" :disabled="userObj.web3Login">{{ $i('userInfo.update') }}</el-button>
          </el-col>
        </el-row>
      </div>
      <span style="color: white;font-size: 12px;display: inline-block;margin-top: 10px" v-if="userObj.web3Login">You must be logged in with a password to change this configuration</span>
      <el-divider></el-divider>
      <div style="padding: 10px;margin-top: 25px" v-if="userObj">
        <h4 class="dark-mode-font" style="font-size: 20px;color: white"><b>Wallet Authentication</b></h4>
        <el-row style="margin-top: 25px">
          <el-col :span="7" style="padding-left: 25px">
            <span class="dark-mode-font" style="color: white;float: left">{{ $i('userInfo.password') }}</span>
          </el-col>
          <el-col :span="17" style="padding-right: 25px">
            <input class="black-card" style="width: 100%;outline: none;color: white;padding: 8px 10px 8px 10px;font-size: 12px;height: 28px;line-height: 28px" type="password" v-model="oldPasswordForBindWallet" :disabled="userObj.web3Login" />
          </el-col>
        </el-row>
        <el-row style="margin-top: 25px">
          <el-col :span="7" style="padding-left: 25px">
            <span class="dark-mode-font" style="color: white;float: left">{{ $i('userInfo.verification') }}</span>
          </el-col>
          <el-col :span="11" style="">
            <input class="black-card" style="width: 100%;outline: none;color: white;padding: 8px 10px 8px 10px;font-size: 12px;height: 28px;line-height: 28px;border-radius: 3px 0 0 3px;border-right: 0" v-model="codeForBindWallet" :disabled="userObj.web3Login" />
          </el-col>
          <el-col :span="6" style="padding-right: 25px">
            <el-button class="dark-mode-btn" style="height: 28px;line-height: 28px;padding: 0 10px 0 10px;width: 100%;border-radius: 0 3px 3px 0" @click="sendVerificationForBindWallet" :disabled="sending || userObj.web3Login">{{ sending ? resend : $i('userInfo.send') }}</el-button>
          </el-col>
        </el-row>
        <el-row style="margin-top: 25px">
          <el-col :span="7" style="padding-left: 25px">
            <span class="dark-mode-font" style="color: white;float: left">Web3 Wallet</span>
          </el-col>
          <el-col :span="11" style="">
            <input class="black-card" style="width: 100%;outline: none;color: white;padding: 8px 10px 8px 10px;font-size: 12px;height: 28px;line-height: 28px;border-radius: 3px 0 0 3px;border-right: 0" :value="currentPublicAddress" disabled />
          </el-col>
          <el-col :span="6" style="padding-right: 25px">
            <el-button class="dark-mode-btn" style="height: 28px;line-height: 28px;padding: 0 10px 0 10px;width: 100%;border-radius: 0 3px 3px 0" @click="bindWallet" :disabled="userObj.web3Login">Change</el-button>
          </el-col>
        </el-row>
        <el-row style="margin-top: 25px">
          <el-col :span="24" style="padding-left: 25px;padding-right: 25px">
            <el-button class="dark-mode-btn" style="height: 35px;line-height: 35px;padding: 0 10px 0 10px;width: 100%;border-radius: 3px" @click="updateWallet" :loading="processing" :disabled="userObj.web3Login">{{ $i('userInfo.update') }}</el-button>
          </el-col>
        </el-row>
        <span style="color: white;font-size: 12px;display: inline-block;margin-top: 20px" v-if="userObj.web3Login">You must be logged in with a password to change this configuration</span>
      </div>
      <el-divider></el-divider>
      <div style="padding: 10px;margin-top: 25px" v-if="userObj">
        <h4 class="dark-mode-font" style="font-size: 20px;color: white"><b>Website configuration</b></h4>
        <el-row style="margin-top: 25px">
          <el-col :span="10" style="padding-left: 25px">
            <span class="dark-mode-font" style="color: white;float: left;text-align: left;font-size: 12px;margin-top: 3px">Auto lock wait time (second)</span>
          </el-col>
          <el-col :span="14" style="padding-right: 25px">
            <input class="black-card" style="width: 100%;outline: none;color: white;padding: 8px 10px 8px 10px;font-size: 12px;height: 28px;line-height: 28px" type="text" onkeyup="value=value.replace(/[^\d]/g,'')" maxlength="3" minlength="2" v-model="autoLockTime" />
          </el-col>
        </el-row>
        <el-row style="margin-top: 25px">
          <el-col :span="10" style="padding-left: 25px">
            <span class="dark-mode-font" style="color: white;float: left;text-align: left;font-size: 12px;margin-top: 3px">Page load size</span>
          </el-col>
          <el-col :span="14" style="padding-right: 25px">
            <input class="black-card" style="width: 100%;outline: none;color: white;padding: 8px 10px 8px 10px;font-size: 12px;height: 28px;line-height: 28px" type="text" onkeyup="value=value.replace(/[^\d]/g,'')" maxlength="2" minlength="1" v-model="loadPageSize" />
          </el-col>
        </el-row>
        <el-row style="margin-top: 25px">
          <el-col :span="24" style="padding-left: 25px;padding-right: 25px">
            <el-button class="dark-mode-btn" style="height: 35px;line-height: 35px;padding: 0 10px 0 10px;width: 100%;border-radius: 3px" @click="updateWebConfig">{{ $i('userInfo.update') }}</el-button>
          </el-col>
        </el-row>
      </div>
    </el-drawer>
  </div>
</template>

<script>

import UserService from "@/api/user/UserService";
import AdminUserManagement from "@/api/user/AdminUserManagement";
import {Message} from "element-ui";
import CacheService from "@/cache/CacheService";
import RouterPath from "@/router/RouterPath";
import Web3Service from "@/web3/Web3Service";

export default {
  name: "UserInfoChange",
  data: function () {
    return {
      adding: false,
      drawer: false,
      userObj: UserService.getUserObj(),
      oldPassword: '',
      newPassword: '',
      sending: false,
      resend: 60,
      processing: false,
      code: '',
      currentPublicAddress: UserService.getUserObj().userInfo.publicAddress ? UserService.getUserObj().userInfo.publicAddress : '',
      autoLockTime: UserService.getUserObj().lockWaitSecond ? UserService.getUserObj().lockWaitSecond : 960,
      loadPageSize: UserService.getUserObj().loadPageSize ? UserService.getUserObj().loadPageSize : 5,
      alreadySign: false,
      oldPasswordForBindWallet: '',
      codeForBindWallet: '',
      sign: ''
    }
  },
  methods: {
    openDrawer: function () {
      this.userObj = UserService.getUserObj();
      this.drawer = true;
    },
    sendVerification: function () {
      if (this.sending) {
        return;
      }
      if (this.oldPassword !== this.userObj.password){
        Message.error('Your current password verification failed')
        return;
      }
      this.sending = true;
      let sendTimer = setInterval(() => {
        this.resend--;
        if (this.resend <= 0) {
          this.resend = 60;
          this.sending = false;
          clearInterval(sendTimer);
        }
      }, 1000);
      let that = this;
      AdminUserManagement.getVerificationCode(function () {
        Message.success('Verification code already send to you email: ' + that.userObj.userInfo.email);
      });
    },
    update: function () {
      let req = {
        id: this.userObj.userInfo.id,
        email: this.userObj.userInfo.email,
        alias: this.userObj.userInfo.alias,
        code: this.code,
        username: this.userObj.userInfo.username,
        password: this.newPassword
      }
      if (!req.email || !req.alias || !req.code || !req.username) {
        Message.warning('Required field not filled');
        return;
      }
      if (this.oldPassword !== this.userObj.password){
        Message.error('Your current password verification failed')
        return;
      }
      let that = this;
      that.processing = true;
      AdminUserManagement.updateUserInfo(req, function () {
        Message.success('Update success, please re-login');
        setTimeout(() => {
          that.isLogin = false;
          UserService.logout(() => {
            CacheService.clear();
            that.$router.push({path: RouterPath.router.login});
          }, () => {
            CacheService.clear();
            that.$router.push({path: RouterPath.router.login});
          });
        }, 568);
      }, function () {
        that.processing = false;
      });
    },
    bindWallet: function () {
      Web3Service.requestMetaMask().then(() => {
        Web3Service.getWeb3(this).eth.getAccounts().then((address) => {
          if (address.length > 0) {
            if (address[0].toUpperCase() === this.userObj.userInfo.publicAddress.toUpperCase()) {
              Message.warning('Your bound wallet is the same as the current one.');
            } else {
              let that = this;
              Web3Service.sign(this, address[0], function (sign, address) {
                that.sign = sign;
                that.currentPublicAddress = address;
                that.alreadySign = true;
              });
            }
          }
        });
      });
    },
    sendVerificationForBindWallet: function () {
      if (this.sending) {
        return;
      }
      if (this.oldPasswordForBindWallet !== this.userObj.password){
        Message.error('Your current password verification failed')
        return;
      }
      this.sending = true;
      let sendTimer = setInterval(() => {
        this.resend--;
        if (this.resend <= 0) {
          this.resend = 60;
          this.sending = false;
          clearInterval(sendTimer);
        }
      }, 1000);
      let that = this;
      AdminUserManagement.getVerificationCode(function () {
        Message.success('Verification code already send to you email: ' + that.userObj.userInfo.email);
      });
    },
    updateWallet: function () {
      if (this.oldPasswordForBindWallet && this.codeForBindWallet && this.currentPublicAddress) {
        if (!this.sign || !this.alreadySign) {
          Message.warning('You haven\'t finished signing');
          return;
        }
        let that = this;
        that.processing = true;
        UserService.bindNewWallet(this.sign, this.currentPublicAddress, this.codeForBindWallet, function () {
          Message.success('Successfully bind wallet');
          that.processing = false;
          let userObj = UserService.getUserObj();
          userObj.userInfo.publicAddress = that.currentPublicAddress;
          UserService.updateUserObj(userObj);
        }, function () {
          Message.error('Failed to bind wallet, please try again');
          that.processing = false;
        });
      } else {
        Message.warning('Required field not filled');
      }
    },
    updateWebConfig: function () {
      let userObj = UserService.getUserObj();
      if (!this.autoLockTime) {
        this.autoLockTime = 60;
      }
      if (!this.loadPageSize) {
        this.loadPageSize = 5;
      }
      userObj.lockWaitSecond = this.autoLockTime;
      userObj.loadPageSize = this.loadPageSize;
      UserService.updateUserObj(userObj);
      Message.success('Update success');
    }
  }
}
</script>

<style>

.createAccount .el-input__inner {
  color: white;
  height: 36px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-radius: 3px;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 2px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(119, 109, 109, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(119, 109, 109, 0.4);
}

</style>