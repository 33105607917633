let basic = '/ap';

const router = {
  login: '/login',
  lock: '/lock',
  controlPanel: '/controlPanel',
  customerKey: '/customerKey',
  controlPanelChild: {
    controlPanelHome: '/controlPanel/homePage',
    userPermissionManagement: '/controlPanel/userPermissionManagement',
    traCupPanel: '/controlPanel/tracupPanel',
    accountMgnt: '/controlPanel/accountMgnt',
    analysisMgnt: '/controlPanel/analysisMgnt',
    channelMgnt: '/controlPanel/channelMgnt',
    wallet: '/controlPanel/wallet',
    tradeMgnt: '/controlPanel/aggregateTransactions',
    riskMgnt: '/controlPanel/riskMgnt',
    testMgnt: '/controlPanel/testMgnt',
    web3Mgnt: '/controlPanel/web3',
    strategyMgnt: '/controlPanel/strategyMgnt',
    mobileUserConfigMgnt: '/controlPanel/mobileUserConfigMgnt',
    mobileNews: '/controlPanel/mobileNews',
    ftChatGPT: '/controlPanel/ftChatGPT'
  }
}

export default {
  basic,
  router
}