<template>
  <div>
    <el-drawer
        size="68%"
        append-to-body
        :with-header="true"
        :close-on-click-modal=true
        :close-on-press-escape=true
        custom-class="black-card"
        :destroy-on-close="true"
        :show-close=true
        :visible.sync="drawer"
        direction="rtl">
      <div style="height: 96%;padding: 0 25px 128px 25px">
        <el-row>
          <span class="dark-mode-font" style="display: inline-block;float: left;color: #FFFFFF">{{
              issueObj.issueTitle
            }}</span>
        </el-row>
        <el-row style="margin-top: 10px">
          <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 12px">{{ issueObj.author }} 创建于 {{
              issueObj.issueCreated
            }}</span>
          <span class="dark-mode-font"
                style="display: inline-block;float: left;font-size: 12px" v-if="history.length > 0">，{{history[history.length - 1].issueActivityUserName}} 最后更新 {{ issueObj.issueUpdated }}</span>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="10">
            <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 13px">状态</span>
          </el-col>
          <el-col :span="9">
            <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 13px">优先级</span>
          </el-col>
          <el-col :span="5">
            <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 13px">类型</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="10">
            <el-select class="select-symbol" style="width: 168px" v-model="issueObj.issueStatus" placeholder="请选择" @change="update('issueStatus', issueObj.issueStatus)"
                       size="small">
              <el-option
                  v-for="key in Object.keys(statusKeyStatusMap)"
                  :key="key"
                  :label="statusKeyStatusMap[key].label"
                  :value="key">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="9">
            <el-select class="select-symbol" style="width: 168px" v-model="issueObj.issuePriority" placeholder="请选择" @change="update('issuePriority', issueObj.issuePriority)"
                       size="small">
              <el-option
                  v-for="key in priority"
                  :key="key"
                  :label="priorityMap[key]"
                  :value="key">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-select class="select-symbol" style="width: 168px" v-model="issueObj.issueType" placeholder="请选择" @change="update('issueType', issueObj.projectTypeKey)"
                       size="small">
              <el-option
                  v-for="key in Object.keys(typeKeyTypeMap)"
                  :key="key"
                  :label="typeKeyTypeMap[key].projectTypeName"
                  :value="typeKeyTypeMap[key].projectTypeName">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row style="margin-top: 0">
          <el-col :span="10">
            <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 13px">指派</span>
          </el-col>
          <el-col :span="9">
            <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 13px">关注者</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="10">
            <el-select class="select-symbol" style="width: 168px" v-model="issueObj.issueAssignee" placeholder="请选择" @change="update('issueAssignee', issueObj.issueAssignee)"
                       size="small">
              <el-option
                  v-for="key in Object.keys(userMap)"
                  :key="key"
                  :label="key"
                  :value="userMap[key].userEmail ? userMap[key].userEmail : userMap[key].userKey">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="13">
            <el-select class="select-symbol" style="width: 100%" v-model="issueFollow" multiple placeholder="请选择" @change="update('issueFollower', issueFollow.join(';'))"
                       size="small">
              <el-option
                  v-for="key in Object.keys(userEmailMap)"
                  :key="key"
                  :label="userEmailMap[key].userName"
                  :value="key">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="10">
            <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 13px">描述</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px;margin-bottom: 20px;">
          <mavon-editor ref=md @imgAdd="addImage" @imgDel="imgDel" v-model="content" :ishljs="true" :html="false" default-open="preview" :xssOptions="false" @save="saveDesc" style="height: 518px"></mavon-editor>
          <el-button class="dark-mode-btn" size="small" style="margin-top: 10px;width: 100%" @click="saveDesc" :disabled="loading">保存</el-button>
        </el-row>
        <el-row style="margin-top: 20px" v-if="issueDetails.data && issueDetails.data.fileList">
          <el-col :span="10">
            <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 13px">附件</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px;margin-bottom: 20px;" v-if="issueDetails.data && issueDetails.data.fileList">
          <el-row v-for="f in issueDetails.data.fileList" :key="f.issueAttachementFileKey">
            <a :href="f.url" target="_blank">{{ f.issueAttachementFileName }}</a><span class="dark-mode-font" style="color: #898a8d;font-size: 12px;margin-left: 10px">({{ f.issueAttachementFileSize }})</span>
          </el-row>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="16">
            <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 13px">变更记录</span>
          </el-col>
          <el-col :span="8">
            <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 13px;margin-left: 18px">留言</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px;margin-bottom: 20px;padding-bottom: 30px">
          <el-row>
            <el-col :span="16">
              <el-card class="black-card" style="height: 268px;overflow-y: auto">
                <div v-if="issueDetails.data" style="margin-top: -10px">
                  <el-row v-for="(activity, index) in history" :key="index" style="margin-top: 10px">
                    <span class="dark-mode-font" style="color: #898a8d;font-size: 12px">{{activity.issueActivityUserName}}: <span class="dark-mode-font" style="color: #d3d5d8;font-size: 12px"> {{ activity.log }} {{ activity.noteContentOrg }}</span></span>
                    <span class="dark-mode-font" style="color: #898a8d;font-size: 12px;float: right">{{activity.createdTime}}</span>
                    <div style="padding-left: 10px;padding-top: 10px" v-if="activity.logContent">
                      <div style="font-size: 12px;color: #d1cccc" v-html="activity.logContent"></div>
                    </div>
                    <div style="padding-left: 10px;padding-top: 10px" v-if="trendFile[activity.issueActivityKey]">
                      <div style="font-size: 12px;color: #d1cccc">
                        <a :href="'https://www.tracup.com/cloud/#/fileview/' + trendFile[activity.issueActivityKey].na_key + '/note'" target="_blank">{{trendFile[activity.issueActivityKey].shortFileName}}</a><span class="dark-mode-font" style="color: #898a8d;font-size: 12px;margin-left: 10px">({{ trendFile[activity.issueActivityKey].na_file_size }})</span>
                      </div>
                    </div>
                  </el-row>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card class="black-card" style="height: 268px;border: 0" shadow="none">
                <el-row style="margin-top: -20px">
                  <el-input resize="none" type="textarea" :rows="10" class="msg-area" size="large" v-model="msg" placeholder="Message"></el-input>
                </el-row>
                <el-row>
                  <el-button class="dark-mode-btn" size="small" style="margin-top: 10px;width: 100%" @click="addNote" :disabled="loading">提交</el-button>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </el-row>
      </div>
    </el-drawer>
  </div>
</template>

<script>

import TracupService from "@/api/tracup/TracupService";
import {Message} from "element-ui";
import {AwsTracupUpdate} from "@/utils/UploadFile";

export default {
  name: "IssueDetails",
  data: function () {
    return {
      loading: false,
      drawer: false,
      priority: ["1", "2", "3"],
      priorityMap: {
        "1": '高',
        "2": '中',
        "3": '低'
      },
      issueObj: {},
      issuePriorityColor: {},
      typeKeyTypeMap: {},
      statusKeyStatusMap: {},
      userMap: {},
      userEmailMap: {},
      content: '',
      issueDetails: {},
      issueFollow: [],
      history: [],
      trendFile: [],
      msg: ''
    }
  },
  methods: {
    init() {
      this.issueObj = {};
      this.issuePriorityColor = {};
      this.typeKeyTypeMap = {};
      this.statusKeyStatusMap = {};
      this.userMap = {};
      this.userEmailMap = {};
      this.content = '';
      this.issueDetails = {};
      this.issueFollow = [];
      this.history = [];
      this.msg = '';
      this.content = '';
    },
    addImage: function (pos, $file) {
      AwsTracupUpdate($file).then(res => {
        this.$refs.md.$img2Url(pos, res.Location);
      });
    },
    imgDel: function () {

    },
    addNote: function () {
      if (this.msg) {
        let that = this;
        TracupService.addNote(this.issueObj.issueNo, this.msg, function () {
          Message.success('Add new note success');
          that.loadDetails();
        }, function (err) {
          console.error(err);
        });
      } else {
        Message.error('请填写内容');
      }
    },
    saveDesc: function () {
      this.update('issueDescription', this.content);
    },
    update: function (k, v) {
      let that = this;
      let req = {
        iNo: that.issueObj.issueNo
      };
      req[k] = v;
      that.loading = true;
      TracupService.updateIssue(req, function (data) {
        if (data.code === 0 && data.message === '') {
          Message.success('Update ' + k + ' to ' + v + ' success');
          that.$emit('update',true);
          that.loadDetails();
        } else {
          Message.error(data.message);
        }
        that.loading = false;
      });
    },
    render: function (issueObj, issuePriorityColor, typeKeyTypeMap, statusKeyStatusMap, userMap) {
      this.init();
      this.drawer = true;
      this.content = '';
      this.issueObj = issueObj;
      this.issuePriorityColor = issuePriorityColor;
      this.typeKeyTypeMap = typeKeyTypeMap;
      this.statusKeyStatusMap = statusKeyStatusMap;
      this.userMap = userMap;
      for (const userMapKey in userMap) {
        let obj = userMap[userMapKey];
        this.userEmailMap[obj.userEmail] = obj;
      }
      if (this.isJsonString(issueObj.issueDescription)) {
        let array = JSON.parse(issueObj.issueDescription);
        for (let msg in array) {
          let obj = array[msg];
          if (obj.type === 'paragraph') {
            for (let c in obj.children) {
              let str = (obj.children[c].text);
              this.content += str + '\r';
            }
          } else if (obj.type === 'image') {
            let str = '![image](' + obj.url + ')\r';
            this.content += str;
          }
        }
      } else {
        this.content = issueObj.issueDescription;
      }
      this.loadDetails();
    },
    loadDetails() {
      let that = this;
      TracupService.queryIssueDetails(this.issueObj.issueNo,function (data) {
        that.issueDetails = data;
        let tmp = [];
        for (const dataKey in that.issueDetails.data.info.issueFollow) {
          let obj = that.issueDetails.data.info.issueFollow[dataKey];
          tmp.push(obj.userEmail);
        }
        if (data.data.trendList) {
          that.history = data.data.trendList.reverse();
        }
        let tf = {};
        for (let index in data.data.trendFileList) {
          let obj = data.data.trendFileList[index];
          tf[obj.issueNoteKey] = obj;
        }
        that.trendFile = tf;
        that.issueFollow = tmp;
      }, function (err) {
        console.error(err);
      });
    },
    isJsonString(str) {
      try {
        if (typeof JSON.parse(str) == "object") {
          return true;
        }
      } catch (e) {
        return false;
      }
    }
  }
}
</script>

<style>

.msg-area textarea {
  border: #444c56 1px solid;
  font-size: 14px!important;
  font-family: "DIN-Bold", "Microsoft YaHei", "黑体", "宋体", sans-serif;
  color: #c5b6b6 !important;
  background-color: #22272e!important;
}

</style>