<template>
    <div class="create-customer-dialog" :style="[show ? 'display: block;' : 'display: none;']">

        <div class="create-customer-dialog-content">

            <div class="create-customer-dialog-content-inner">
                <div @click="onAction({id: 'close'})" style="position: absolute; top: -46px; right: -46px; width: 32px; height: 32px; background: #3E454E; color: white; cursor: pointer; border-radius: 16px; font-size: 19px; padding: 3px;">
                    <i class="el-icon-close"></i>
                </div>
            
                <el-row class="hidden-sm-and-down" style="width: 100%;position: static; margin-bottom: 20px;">
                    <el-col :span="24" style="text-align: left;">
                        <FtGroupButtonWidget :buttons="[
                            {
                                id: 'detail',
                                text: '转账详情',
                                textI18n: '',
                                active: true
                            }
                        ]" />
                    </el-col>
                </el-row>
                        
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="6" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                            时间
                        </label>
                    </el-col>
                    <el-col :span="15" style="text-align: left;padding-top: 3px">
                        <template v-if="form.createTimeDate">
                            {{form.createTimeDate}}
                        </template>
                    </el-col>
                </el-row>
                
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="6" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                            发起人
                        </label>
                    </el-col>
                    <el-col :span="15" style="text-align: left;padding-top: 3px">
                        {{form.createdBy}}
                    </el-col>
                </el-row>
                
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="6" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                            付款钱包
                        </label>
                    </el-col>
                    <el-col :span="15" style="text-align: left;padding-top: 3px">
                        {{form.fromWalletHumanReadableName}} {{form.fromWalletAddress}} : {{form.fromWalletRoute}}
                    </el-col>
                </el-row>
                
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="6" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                            收款钱包
                        </label>
                    </el-col>
                    <el-col :span="15" style="text-align: left;padding-top: 3px">
                        {{form.toWalletHumanReadableName}} {{form.toWalletAddress}} : {{form.toWalletRoute}}
                    </el-col>
                </el-row>
                
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; display: none;">
                    <el-col :span="6" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                            公链
                        </label>
                    </el-col>
                    <el-col :span="15" style="text-align: left;padding-top: 3px">
                        {{form.fromWalletRoute}} - {{form.toWalletRoute}} 
                    </el-col>
                </el-row>
                
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="6" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                            数量
                        </label>
                    </el-col>
                    <el-col :span="15" style="text-align: left;padding-top: 3px">
                        {{form.tokenAmount}} {{form.tokenType}}
                    </el-col>
                </el-row>

                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="6" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                            状态
                        </label>
                    </el-col>
                    <el-col :span="15" style="text-align: left;padding-top: 3px">
                        <!-- <template v-if="form.status == 'PENDING_APPROVAL'">
                            审核中
                        </template>
                        <template v-else>
                            {{form.status == 'FAILED' ? '失败' : '成功'}}
                        </template> -->
                        {{form.statusHumanReadableName}}
                    </el-col>
                </el-row>

                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="6" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                            交易Hash
                        </label>
                    </el-col>
                    <el-col :span="15" style="text-align: left;padding-top: 3px">
                        <!-- https://etherscan.io/tx/0x838c0521eca328eeb421e27f893aad908367b3f57a29820e97c1b3be86be7faf -->
                        <template v-if="(form.transactionHash && form.transactionHash.indexOf('internal transfer') > -1)">
                            {{form.transactionHash}}
                        </template>
                        <template v-if="(form.transactionHash && form.transactionHash.indexOf('internal transfer') == -1)">
                            <template v-if="form.fromWalletRoute == 'BSC'">
                                <a style="color: white;" target="_blank" :href="('https://bscscan.com/tx/' + form.transactionHash)">{{form.transactionHash}}</a>
                            </template>
                            <template v-else-if="form.fromWalletRoute == 'POLYGON'">
                                <a style="color: white;" target="_blank" :href="('https://polygonscan.com/tx/' + form.transactionHash)">{{form.transactionHash}}</a>
                            </template>
                            <template v-else-if="form.fromWalletRoute == 'ETH'">
                                <a style="color: white;" target="_blank" :href="('https://etherscan.io/tx/' + form.transactionHash)">{{form.transactionHash}}</a>
                            </template>
                            <template v-else-if="form.fromWalletRoute == 'TRON'">
                                <a style="color: white;" target="_blank" :href="('https://tronscan.org/#/transaction/' + form.transactionHash)">{{form.transactionHash}}</a>
                            </template>
                            <template v-else>
                                {{form.transactionHash}}
                            </template>
                        </template>

                    </el-col>
                </el-row>

                <el-row v-if="form.status == 'FAILED'" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="6" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                            拒绝人员
                        </label>
                    </el-col>
                    <el-col :span="15" style="text-align: left;padding-top: 3px">
                        <template v-if="form.decliners && form.decliners.length">
                            {{form.decliners.join(',')}}
                        </template>
                        <template v-else>
                            <span style="color: #7C859C; font-style: italic;">无</span>
                        </template>
                    </el-col>
                </el-row>
                
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="6" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                            备注
                        </label>
                    </el-col>
                    <el-col :span="15" style="text-align: left;padding-top: 3px">
                        {{form.notes}} 
                    </el-col>
                </el-row>
                
                <!-- 步骤控制 -->
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; margin-top: 20px;">
                    <el-col :span="24">
                        <FtGroupButtonWidget :buttons="[{
                            id: 'close',
                            icon: '',
                            text: '确定',
                            textI18n: '',
                        }]" mode="single" v-on:switch-change="onAction" 
                        style="float: none; display: inline-block; margin: auto; background: #3E454E;" />
                    </el-col>
                </el-row>
            </div>

        </div>

    </div>
</template>

<script>
// import {Message} from "element-ui";
// import moment from "moment"
import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";

// import WalletManagement from "@/api/wallet/WalletManagement";
// import UserService from "@/api/user/UserService";


export default {
    components: {
        FtGroupButtonWidget,
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'cex'
        }
    },
    data() {
        return {
            userName: '',
            // 表单数据
            form: {
                approvers: [],
                createTime: 0,
                createTimeDate: '',
                createdBy: "",
                decliners: null,
                fromWalletAddress: "",
                fromWalletHumanReadableName: "",
                fromWalletRoute: "",
                fromWalletType: "",
                id: "",
                notes: null,
                requireApprovalNumber: 2,
                status: "",
                statusHumanReadableName: '',
                toWalletAddress: "",
                toWalletHumanReadableName: "",
                toWalletRoute: "",
                toWalletType: "",
                tokenAmount: 0,
                tokenType: "",
                transactionHash: ""
            },
        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {

        setData(item) {
            console.log('转账详情, 设置参数', item)
            // this.form.id = item.id
            // this.currentPanel = item.walletType.toLowerCase()
            // if (item.approvers) {
            //     this.approverUsernameList = item.approvers.map(a => {
            //         return {
            //             value: a
            //         }
            //     })
            // }
            // this.form.requireApprovalNumber = item.requireApprovalNumber
            this.form = item
            // this.form.createTimeDate = moment(item.createTime * 1000).format('YYYY/MM/DD HH:mm:ss')
        },

        // 各种切换处理
        onAction(item) {
            if (item.id == 'close') {
                console.log('switch change event', item.id)
                this.$emit('update:show', false)
            }
        },

        paddingZero(v) {
            return v > 9 ? v : ('0' + v)
        }
        
    }
}
</script>

<style scoped>
.create-customer-dialog{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    color: white;
}
.create-customer-dialog-content{
    background: #22272E;
    width: 1000px;
    /* height: 360px; */
    padding: 30px;
    border: 1px solid #3E454E;
    border-radius: 8px;
    position: fixed;
    left: 50%;
    top: 30%;
    margin-left: -400px;
    margin-top: -140px;
}
.create-customer-dialog-content-inner{
    position: relative;
}
</style>
