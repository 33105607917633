<template>
  <div style="width: 100%;height: 100%;overflow-y: auto;padding: 5px">
    <el-row style="margin-top: 20px">
      <el-card class="black-card" style="height: 188px">
        <el-row style="margin-top: -5px">
          <el-col :span="11">
            <span class="dark-mode-font"
                  style="display: inline-block; float: left;color: white;font-size: 12px">账号</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font ellipsis-line-strategy"
                  style="display: inline-block; float: left;color: white;font-size: 12px">{{
                accountInfo.username
              }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">Taker Fee</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">{{
                (accountInfo.takerFee * 100).toFixed(5)
              }}%</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font"
                  style="display: inline-block; float: left;color: white;font-size: 12px">仓位总价值</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">${{
                Number.prototype.toFormatNum(Number(accountInfo.totalPositionSize || 0))
              }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font"
                  style="display: inline-block; float: left;color: white;font-size: 12px">总杠杆</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">{{
                accountInfo.marginFraction == null ? 0 : (1 / accountInfo.marginFraction).toFixed(4)
              }}X</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font"
                  style="display: inline-block; float: left;color: white;font-size: 12px">杠杆设置</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">{{
                accountInfo.leverage
              }}X</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font"
                  style="display: inline-block; float: left;color: white;font-size: 12px">保证金率</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">{{
                accountInfo.openMarginFraction == null ? 0 : (accountInfo.openMarginFraction).toFixed(4)
              }}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col :span="11">
            <span class="dark-mode-font"
                  style="display: inline-block; float: left;color: white;font-size: 12px">初始保证金率</span>
          </el-col>
          <el-col :span="13">
            <span class="dark-mode-font" style="display: inline-block; float: left;color: white;font-size: 12px">{{
                (accountInfo.initialMarginRequirement).toFixed(4)
              }}</span>
          </el-col>
        </el-row>
      </el-card>
    </el-row>
    <el-row style="margin-top: 15px">
      <general-order-panel v-if="active" v-auth:quickPlaceOrder ref="order-panel-ftx"></general-order-panel>
    </el-row>
    <!--
    <el-row style="margin-top: 15px">
      <el-collapse class="trialpha-collapse" v-model="balanceCollapse" style="border: 0;">
        <el-collapse-item class="trialpha-collapse">
          <template slot="title">
            <i class="el-icon-s-finance" style="margin-left: 2px;color: #0ecb81;margin-top: 1px;border: 0"/><h4
              class="dark-mode-font" style="margin-left: 10px;padding-top: 6px;color: white">Balances</h4>
          </template>
          <el-row style="margin-top: 10px">
        <span class="dark-mode-font"
              style="font-size: 12px;display: inline-block;float: left;color: #FFFFFF;margin-left: 5px">Spot balance</span>
          </el-row>
          <el-card class="black-card" body-style="padding: 0"
                   style="width: 100%;border-radius: 5px 5px 0 0;border-bottom: 0;height: 25px;line-height: 25px;margin-top: 5px;background-color: #454c56">
            <el-row>
              <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy"
                                      style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 8px;color: #FFFFFF">Symbol</span>
              </el-col>
              <el-col :span="7"><span class="dark-mode-font ellipsis-line-strategy"
                                      style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 15px;color: #FFFFFF">Balance</span>
              </el-col>
              <el-col :span="6"><span class="dark-mode-font ellipsis-line-strategy"
                                      style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 15px;color: white">USD</span>
              </el-col>
              <el-col :span="6">
                <el-button class="dark-mode-btn"
                           style="border: 1px;height: 25px;font-size: 12px;line-height: 25px;padding: 0 5px 0 5px;float: right;border-radius: 0 3px 0 0;width: 100px"
                           @click="changeAllSymbol">{{
                    showAllPosition ? '隐藏0余额' : '显示所有币种'
                  }}
                </el-button>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="black-card" body-style="padding: 0"
                   style="width: 100%;height: 197px;overflow-y: auto;border-radius: 0">
            <el-skeleton animated :loading="renderingSpot" :throttle="200">
              <template slot="template">
                <trialpha-sk :style="'margin-top: 25px;width: 60%;float: left'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left'"></trialpha-sk>
              </template>
              <template>
                <el-card class="black-card-hover" body-style="padding: 0"
                         v-for="(item, index) in (showAllPosition ? allSpot : moreThanZeroSpot)" :key="index"
                         style="border-radius: 0;border-bottom: 1px solid #444c56;border-top: 0;height: 28px;line-height: 28px;border-left: 0;border-right: 0">
                  <el-row>
                    <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy"
                                            style="color: white;text-align: left;font-size: 12px;display: inline-block;float: left;margin-left: 8px">{{
                        index
                      }}</span></el-col>
                    <el-col :span="7"><span class="dark-mode-font ellipsis-line-strategy"
                                            style="color: white;text-align: left;font-size: 12px;display: inline-block;float: left;margin-left: 15px">{{
                        item
                      }}</span></el-col>
                    <el-col :span="6"><span class="dark-mode-font ellipsis-line-strategy"
                                            style="color: white;text-align: left;font-size: 12px;display: inline-block;float: left;margin-left: 15px">{{
                        ((allPrice[index + '/USD'] ? allPrice[index + '/USD'] : (index === 'USD' ? 1 : 0)) * item).toFixed(2)
                      }}</span></el-col>
                  </el-row>
                </el-card>
              </template>
            </el-skeleton>
          </el-card>
          <el-card class="black-card" body-style="padding: 0"
                   style="width: 100%;border-radius: 0 0 5px 5px;border-top: 0;height: 25px;line-height: 25px;margin-top: 0">
            <el-row>
              <el-col :span="6"><span class="dark-mode-font ellipsis-line-strategy"
                                      style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 8px">Total</span>
              </el-col>
              <el-col :span="6" :offset="6"><span class="dark-mode-font ellipsis-line-strategy"
                                                  style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 15px;color: #FFFFFF">$ {{
                  totalSpotUsd
                }}</span></el-col>
            </el-row>
          </el-card>
        </el-collapse-item>
      </el-collapse>
    </el-row>
    -->
    <el-row style="margin-top: 15px">
      <el-collapse class="trialpha-collapse" v-model="spotCollapse" style="border: 0;">
        <el-collapse-item class="trialpha-collapse">
          <template slot="title">
            <i class="el-icon-s-finance" style="margin-left: 2px;color: #0ecb81;margin-top: 1px;border: 0"/><h4
              class="dark-mode-font" style="margin-left: 10px;padding-top: 6px;color: white">Position Info</h4>
          </template>
          <general-position-panel ref="position-panel" style="margin-top: 10px"/>
          <!--
          <el-row style="margin-top: 10px">
            <span class="dark-mode-font"
                  style="font-size: 12px;display: inline-block;float: left;color: #FFFFFF;margin-left: 5px">Active orders</span>
          </el-row>
          <el-card class="black-card" body-style="padding: 0"
                   style="width: 100%;border-radius: 5px 5px 0 0 ;border-bottom: 0;height: 25px;line-height: 25px;margin-top: 5px;background-color: #454c56">
            <el-row>
              <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy"
                                      style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 8px;color: #FFFFFF">Symbol</span>
              </el-col>
              <el-col :span="7"><span class="dark-mode-font ellipsis-line-strategy"
                                      style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 15px;color: #FFFFFF">Price</span>
              </el-col>
              <el-col :span="6"><span class="dark-mode-font ellipsis-line-strategy"
                                      style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 15px;color: white">Amount</span>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="black-card" body-style="padding: 0"
                   style="width: 100%;height: 197px;overflow-y: auto;border-radius: 0">
            <el-skeleton animated :loading="renderingOrder" :throttle="200">
              <template slot="template">
                <trialpha-sk :style="'margin-top: 25px;width: 60%;float: left'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left'"></trialpha-sk>
                <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left'"></trialpha-sk>
              </template>
              <template>
                <el-card class="black-card-hover" body-style="padding: 0" v-for="(item, index) in (activeOrders)"
                         :key="index"
                         style="border-radius: 0;border-bottom: 1px solid #444c56;border-top: 0;height: 28px;line-height: 28px;border-left: 0;border-right: 0">
                  <el-row>
                    <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy"
                                            style="color: white;text-align: left;font-size: 12px;display: inline-block;float: left;margin-left: 8px">{{
                        item.symbol
                      }}</span></el-col>
                    <el-col :span="7"><span class="dark-mode-font ellipsis-line-strategy"
                                            style="color: white;text-align: left;font-size: 12px;display: inline-block;float: left;margin-left: 15px">{{
                        item.realCostPrice
                      }}</span></el-col>
                    <el-col :span="6"><span class="dark-mode-font ellipsis-line-strategy"
                                            :style="'text-align: left;font-size: 12px;display: inline-block;float: left;margin-left: 15px;' + (item.side === 'buy' ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{
                        Number.prototype.accSub(item.realAmount, item.dealData.R)
                      }}/{{ item.realAmount }}</span></el-col>
                    <el-col :span="6">
                      <el-button class="dark-mode-btn"
                                 style="font-size: 12px;height: 28px;line-height: 28px;padding: 0 10px 0 10px;float: right;border-radius: 0"
                                 @click="cancelOrder(item.orderID, item.symbol)">Cancel
                      </el-button>
                    </el-col>
                  </el-row>
                </el-card>
              </template>
            </el-skeleton>
          </el-card>
          <el-card class="black-card" body-style="padding: 0"
                   style="width: 100%;border-radius: 0 0 5px 5px;border-top: 0;height: 25px;line-height: 25px;margin-top: 0">
            <el-row>
              <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy"
                                      style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 8px">Total</span>
              </el-col>
              <el-col :span="6" :offset="7">
                <el-tooltip class="item" effect="dark" :content="totalR.toFixed(4) + '/' + totalLimitAmount.toFixed(4)"
                            placement="top">
                  <span class="dark-mode-font ellipsis-line-strategy"
                        style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 15px;color: #FFFFFF">{{
                      (totalLimitAmount - totalR).toFixed(4)
                    }}/{{ totalLimitAmount.toFixed(4) }}</span>
                </el-tooltip>
              </el-col>
              <el-col :span="6"><span class="dark-mode-font ellipsis-line-strategy"
                                      style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 15px;color: #FFFFFF">${{
                  totalLimitUsd.toFixed(2)
                }}</span></el-col>
            </el-row>
          </el-card>
          <el-row style="margin-top: 15px">
            <el-row>
              <span class="dark-mode-font"
                    style="font-size: 12px;display: inline-block;float: left;color: #FFFFFF;margin-left: 5px">Positions</span>
            </el-row>
            <el-card class="black-card" body-style="padding: 0"
                     style="width: 100%;border-radius: 5px 5px 0 0;border-bottom: 0;height: 25px;line-height: 25px;margin-top: 5px;background-color: #454c56">
              <el-row>
                <el-col :span="7"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">Symbol</span>
                </el-col>
                <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">Size</span>
                </el-col>
                <el-col :span="6"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">Entry price</span>
                </el-col>
                <el-col :span="6"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 15px;color: #FFFFFF">Pnl</span>
                </el-col>
              </el-row>
            </el-card>
            <el-card class="black-card" body-style="padding: 0"
                     style="width: 100%;height: 197px;overflow-y: auto;border-radius: 0">
              <el-skeleton animated :loading="renderingPosition" :throttle="200">
                <template slot="template">
                  <trialpha-sk :style="'margin-top: 25px;width: 60%;float: left'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left'"></trialpha-sk>
                  <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left'"></trialpha-sk>
                </template>
                <template>
                  <el-card class="black-card-hover" body-style="padding: 0" v-for="(item, index) in position"
                           :key="index"
                           style="border-radius: 0;border-bottom: 1px solid #444c56;border-top: 0;height: 28px;line-height: 28px;border-left: 0;border-right: 0">
                    <el-row>
                      <el-col :span="7"><span class="dark-mode-font ellipsis-line-strategy"
                                              style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px">{{
                          item.future
                        }}</span></el-col>
                      <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy"
                                              :style="'font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;' + (item.netSize > 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">{{
                          item.netSize
                        }}</span></el-col>
                      <el-col :span="6"><span class="dark-mode-font ellipsis-line-strategy"
                                              style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px">{{
                          Number(item.recentBreakEvenPrice).toFixed(4)
                        }}</span></el-col>
                      <el-col :span="6"><span class="dark-mode-font ellipsis-line-strategy"
                                              :style="'font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 15px;' + (item.recentPnl > 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">$ {{
                          Number(item.recentPnl).toFixed(1)
                        }}</span>
                      </el-col>
                    </el-row>
                  </el-card>
                </template>
              </el-skeleton>
            </el-card>
            <el-card class="black-card" body-style="padding: 0"
                     style="width: 100%;border-radius: 0 0 5px 5px;border-top: 0;height: 25px;line-height: 25px;margin-top: 0">
              <el-row>
                <el-col :span="7"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 8px">Total</span>
                </el-col>
                <el-col :span="9"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 8px;color: #FFFFFF">$ {{
                    totalSize
                  }}</span></el-col>
                <el-col :span="6" :offset="2"><span class="dark-mode-font ellipsis-line-strategy"
                                                    :style="'font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 15px;' + (totalPnl > 0 ? 'color: #0ecb81;' : 'color: #f6465d;') + ''">$ {{
                    totalPnl
                  }}</span></el-col>
              </el-row>
            </el-card>
          </el-row>
          -->
        </el-collapse-item>
      </el-collapse>
    </el-row>
    <el-button class="dark-mode-btn" style="margin-top: 25px;width: 100%"
               :loading="renderingOrder || renderingSpot || renderingPosition || renderingHistory || loadingOrder"
               @click="renderData">Reload
    </el-button>
    <el-row style="margin-top: 15px">
      <el-row>
        <span class="dark-mode-font"
              style="font-size: 12px;display: inline-block;float: left;color: #FFFFFF;margin-left: 5px">History</span>
        <span class="dark-mode-font"
              style="font-size: 12px;display: inline-block;float: right;color: #FFFFFF;margin-left: 5px">Orders count: {{
            history.length
          }}</span>
      </el-row>
      <el-card class="black-card" body-style="padding: 0"
               style="width: 100%;border-radius: 5px 5px 0 0;border-bottom: 0;height: 25px;line-height: 25px;margin-top: 5px;background-color: #454c56">
        <el-row>
          <el-col :span="7"><span class="dark-mode-font ellipsis-line-strategy"
                                  style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">Time</span>
          </el-col>
          <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy"
                                  style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">Symbol</span>
          </el-col>
          <el-col :span="6"><span class="dark-mode-font ellipsis-line-strategy"
                                  style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">Entry price</span>
          </el-col>
          <el-col :span="6"><span class="dark-mode-font ellipsis-line-strategy"
                                  style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 15px;color: #FFFFFF">Amount</span>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="black-card" body-style="padding: 0"
               style="width: 100%;height: 228px;overflow-y: auto;border-radius: 0" v-infinite-scroll="loadMoreOrder"
               :infinite-scroll-disabled="historyOrderToEnd">
        <el-skeleton animated :loading="renderingHistory" :throttle="200">
          <template slot="template">
            <trialpha-sk :style="'margin-top: 25px;width: 60%;float: left'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 40%;float: left'"></trialpha-sk>
            <trialpha-sk :style="'margin-top: 20px;width: 100%;float: left'"></trialpha-sk>
          </template>
          <template>
            <el-card class="black-card-hover" body-style="padding: 0" v-for="(item, index) in history" :key="index"
                     style="border-radius: 0;border-bottom: 1px solid #444c56;border-top: 0;height: 28px;line-height: 28px;border-left: 0;border-right: 0">
              <el-row>
                <el-col :span="7"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px">{{
                    getTimeString(new Date(item.time).getTime())
                  }}</span></el-col>
                <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="color: #FFFFFF;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;">{{
                    item.symbol
                  }}</span></el-col>
                <el-col :span="6"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px">{{
                    item.realCostPrice
                  }}</span></el-col>
                <el-col :span="6"><span class="dark-mode-font ellipsis-line-strategy"
                                        :style="'font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 15px;' + (item.side === 'sell' ? 'color: #f6465d;' : 'color: #0ecb81;') + ''">{{
                    item.side === 'sell' ? '-' : ''
                  }}{{ item.realAmount }}</span></el-col>
              </el-row>
            </el-card>
          </template>
        </el-skeleton>
      </el-card>
      <el-card class="black-card" body-style="padding: 0"
               style="width: 100%;border-radius: 0;border-top: 0;height: 25px;line-height: 25px;margin-top: 0;background-color: #454c56">
        <el-row>
          <el-col :span="7"><span class="dark-mode-font ellipsis-line-strategy"
                                  style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 8px;color: white">Analysis</span>
          </el-col>
          <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy"
                                  style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 8px;">Amount</span>
          </el-col>
          <el-col :span="6"><span class="dark-mode-font ellipsis-line-strategy"
                                  style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 8px;">USD</span>
          </el-col>
          <el-col :span="6"><span class="dark-mode-font ellipsis-line-strategy"
                                  style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 8px;">Entry price</span>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="black-card" body-style="padding: 0"
               style="width: 100%;border-radius: 0;border-top: 0;height: 168px;line-height: 25px;margin-top: 0;overflow-y: auto">
        <el-card class="black-card-hover" body-style="padding: 0" v-for="(item, index) in analysis" :key="index"
                 style="border-radius: 0;border-bottom: 1px solid #444c56;border-top: 0;border-right: 0;border-left: 0;height: 28px;line-height: 28px">
          <el-row>
            <el-col :span="7"><span class="dark-mode-font ellipsis-line-strategy"
                                    style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px">{{
                item.symbol
              }}</span></el-col>
            <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy"
                                    style="color: #FFFFFF;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;">{{
                item.amount.toFixed(4)
              }}</span></el-col>
            <el-col :span="6"><span class="dark-mode-font ellipsis-line-strategy"
                                    style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px">{{
                item.usd.toFixed(2)
              }}</span></el-col>
            <el-col :span="6"><span class="dark-mode-font ellipsis-line-strategy"
                                    style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;">{{
                Number(item.entryPrice).toFixed(5)
              }}</span></el-col>
          </el-row>
        </el-card>
      </el-card>
    </el-row>
  </div>
</template>

<script>
import TradeApiService from "@/api/trade/TradeApiService";
import TimeService from "@/utils/TimeService";
import {Message} from "element-ui";
import ConfirmProvider from "@/components/ConfirmProvider";
import TrialphaSk from "@/components/TrialphaSk";
import GeneralOrderPanel from "@/components/account/GeneraalOrderPanel";
import GeneralApiService from "@/api/trade/GeneralApiService";
import GeneralPositionPanel from "@/components/account/GeneralPositionPanel";

export default {
  name: "FtxAccountInfo",
  components: {GeneralPositionPanel, GeneralOrderPanel, TrialphaSk},
  data: function () {
    return {
      instance: {},
      tradeAccountIns: {},
      position: {},
      allSpot: {},
      moreThanZeroSpot: {},
      showAllPosition: false,
      renderingOrder: false,
      renderingSpot: true,
      renderingPosition: true,
      renderingHistory: true,
      historyOrderToEnd: false,
      loadingOrder: false,
      allPrice: {},
      totalSpotUsd: 0,
      totalPnl: 0,
      totalSize: 0,
      history: [],
      startTime: 0,
      endTime: 0,
      historyAmount: {},
      historyCostUsd: {},
      analysis: [],
      accountInfo: {
        totalPositionSize: 0,
        initialMarginRequirement: 0
      },
      activeOrders: [],
      totalR: 0,
      totalLimitUsd: 0,
      totalLimitAmount: 0,
      active: false,
      spotCollapse: ['1'],
      balanceCollapse: ['1']
    }
  },
  methods: {
    cancelOrder: function (id, symbol) {
      let req = {
        orderId: id,
        instanceId: this.instance.instanceId,
        sid: this.tradeAccountIns.id,
        platform: 'FTX',
        orderType: 'market',
        side: 'buy',
        symbol: symbol,
        price: 0,
        amount: 0
      }
      let that = this;
      ConfirmProvider.openConfirm(that, '取消确认', '该操作将会取消此订单', function () {
        TradeApiService.cancelOrder(req, function () {
          Message.success('订单撤销成功')
          that.renderData();
        });
      });
    },
    getTimeString: function (time) {
      return TimeService.timestampToDate(time);
    },
    changeAllSymbol: function () {
      this.showAllPosition = !this.showAllPosition;
    },
    render: function (ins, tradeAccountIns) {
      this.active = true;
      this.instance = ins;
      this.tradeAccountIns = tradeAccountIns;
      this.renderData();
    },
    uninstall: function () {
      this.active = false;
    },
    renderData: function () {
      let that = this;
      this.startTime = 0;
      this.history = [];
      this.analysis = [];
      this.position = {};
      this.accountInfo = {
        totalPositionSize: 0,
        initialMarginRequirement: 0
      }
      this.historyOrderToEnd = false;
      this.loadingOrder = false;
      // that.renderingOrder = true;
      that.renderingSpot = true;
      that.renderingPosition = true;
      that.renderingHistory = true;
      that.$nextTick(function () {
        that.$refs['order-panel-ftx'].render(that.tradeAccountIns, that.renderData);
      });
      this.$refs['position-panel'].render(GeneralApiService.PLATFORM.FTX, GeneralApiService.SYMBOL_TYPE.ALL, this.tradeAccountIns.apiKey);
      TradeApiService.getAccountInfo('FTX', this.tradeAccountIns.apiKey, 'ALL', function (data) {
        that.accountInfo = data;
      });
      /*
      TradeApiService.getAllActiveOrders('FTX', this.tradeAccountIns.apiKey, function (data) {
        that.activeOrders = data;
        let totalR = 0;
        let totalSize = 0;
        let totalUsd = 0;
        data.forEach((o) => {
          totalSize += o.realAmount;
          totalR += o.dealData.R;
          totalUsd += o.realAmount * o.realCostPrice;
        });
        that.totalR = totalR;
        that.totalLimitUsd = totalUsd;
        that.totalLimitAmount = totalSize;
        that.renderingOrder = false;
      });
       */
      TradeApiService.getAllPosition('FTX', this.tradeAccountIns.apiKey, function (data) {
        that.initPosition(data);
      });
      TradeApiService.getAllPrice('FTX', 'ALL', function (data) {
        that.allPrice = data;
        TradeApiService.getAllSpot('FTX', that.tradeAccountIns.apiKey, function (data) {
          that.allSpot = data;
          let tmp = {};
          let totalU = 0;
          for (let spotKey in that.allSpot) {
            if (that.allSpot[spotKey] !== 0) {
              tmp[spotKey] = that.allSpot[spotKey];
              let price = that.allPrice[spotKey + '/USD'] ? that.allPrice[spotKey + '/USD'] : (spotKey === 'USD' ? 1 : 0);
              totalU += price * that.allSpot[spotKey];
            }
          }
          that.totalSpotUsd = totalU.toFixed(2);
          that.moreThanZeroSpot = tmp;
          that.renderingSpot = false;
        });
      });
      this.loadMoreOrder();
    },
    initPosition: function (data) {
      let tmp = {};
      let pnlT = 0;
      let totalUSize = 0;
      let that = this;
      for (let dataKey in data) {
        if (data[dataKey].netSize !== 0) {
          tmp[dataKey] = data[dataKey];
          pnlT += data[dataKey].recentPnl;
          totalUSize += Math.abs(data[dataKey].size) * data[dataKey].entryPrice;
        }
      }
      that.totalSize = totalUSize.toFixed(2);
      that.totalPnl = pnlT.toFixed(2);
      that.position = tmp;
      that.renderingPosition = false;
    },
    loadMoreOrder: function () {
      let that = this;
      if (that.loadingOrder) {
        return;
      }
      that.loadingOrder = true;
      TradeApiService.getOrderHistory('FTX', this.tradeAccountIns.apiKey, this.startTime, this.endTime, 'ALL', function (data) {
        that.historyCostUsd = {};
        that.historyAmount = {};
        that.analysis = [];
        data.forEach((item) => {
          that.history.push(item);
        });
        that.history.forEach((item) => {
          let symbol = item.symbol;
          let entryPrice = item.realCostPrice;
          let amount = item.realAmount * (item.side === 'sell' ? -1 : 1);
          let costUsd = entryPrice * amount;
          if (!that.historyCostUsd[symbol]) {
            that.historyCostUsd[symbol] = 0;
          }
          if (!that.historyAmount[symbol]) {
            that.historyAmount[symbol] = 0;
          }
          that.historyCostUsd[symbol] = that.historyCostUsd[symbol] + costUsd;
          that.historyAmount[symbol] = that.historyAmount[symbol] + amount;
        });
        if (data.length > 0) {
          that.endTime = ((new Date(data[data.length - 1].time).getTime() - 1000) / 1000).toFixed(0);
          that.startTime = that.endTime - (60 * 60 * 24 * 60);
        }
        if (data.length === 0) {
          that.historyOrderToEnd = true;
        }
        that.renderingHistory = false;
        that.loadingOrder = false;
        for (const historyAmountKey in that.historyAmount) {
          let amount = that.historyAmount[historyAmountKey];
          let usd = that.historyCostUsd[historyAmountKey];
          let entryPrice = (Math.abs(usd / amount)).toFixed(6);
          that.analysis.push({
            symbol: historyAmountKey,
            amount: amount,
            usd: usd,
            entryPrice: entryPrice
          });
        }
      }, function () {
        that.loadingOrder = false;
      });
    }
  }
}
</script>

<style scoped>

.ellipsis-line-strategy {
  padding: 0;
  width: 90%;
  float: left;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-divider {
  border-top: 1px solid #444c56;
}

.el-collapse {
  color: #FFFFFF;
  border-top: 1px solid #444c56;
  border-bottom: 1px solid #444c56;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: white;
}

.el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}
</style>