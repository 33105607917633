<template>
    <div class="create-customer-dialog" :style="[show ? 'display: block;' : 'display: none;']">

        <div class="create-customer-dialog-content">

            <div class="create-customer-dialog-content-inner">
                <div @click="onAction({id: 'close'})" style="position: absolute; top: -56px; right: -46px; width: 32px; height: 32px; background: #3E454E; color: white; cursor: pointer; border-radius: 16px; font-size: 19px; padding: 3px;">
                    <i class="el-icon-close"></i>
                </div>

                <!-- 第一步 -->
                <template v-if="step == 1">
                
                    <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; margin-bottom: 20px;">
                        <el-col :span="24" style="text-align: left;">
                            <FtGroupButtonWidget :buttons.sync="detailSwitchButtons" v-on:switch-change="onAction" />
                        </el-col>
                    </el-row>
                            
                    <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                        <el-col :span="7" style="text-align: right;">
                            <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                                <!-- 钱包别名 -->
                                <template v-if="currentPanel == 'cex'">钱包名</template>
                                <template v-if="currentPanel == 'dex'">钱包名</template>
                            </label>
                        </el-col>
                        <el-col :span="14" style="text-align: left;">
                            <input v-model="form.name" type="text" value="" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                        </el-col>
                    </el-row>

                    <el-row v-if="currentPanel == 'cex'" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                        <el-col :span="7" style="text-align: right;">
                            <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">交易所</label>
                        </el-col>
                        <el-col :span="14" style="text-align: left;">
                            <select v-model="form.platform" @change="doTipBoxHide()" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px">
                                <option v-for="(item, index) in exchangeList" :key="index" :value="item.value">{{item.text}}</option>
                            </select>
                        </el-col>
                        <!-- 提示信息 -->
                        <el-col :span="3" style="position: relative;" v-show="form.platform == 'BINANCE'">
                            <div style="width: 16px; height: 16px; border-radius: 16px; background-color: white; color: black; font-size: 10px; line-height: 16px; cursor: pointer; margin: 7px;"
                                @mouseenter="doTipBoxShow">
                                !
                            </div>
                            <div v-show="showTipBox" style="width: 324px; height: 90px; border-radius: 8px; border: 1px solid #3E454E; background: #22272E; color: white; font-size: 12px; position: absolute; text-align: left; padding: 8px;">
                                注意：需要赋予 “允许提现权限” 至以下ip地址<br />
                                35.72.76.174 35.72.58.188 18.182.211.103 18.182.157.47 18.181.94.193 18.180.74.96 54.238.79.189 35.76.163.91 18.179.79.121 18.176.233.98

                                <div @click="doTipBoxHide" style="position: absolute; top: -8px; right: -8px; width: 16px; height: 16px; background: #3E454E; color: white; cursor: pointer; border-radius: 16px; font-size: 10px; text-align: center; line-height: 16px; border: 1px solid #3E454E;">
                                    <i class="el-icon-close"></i>
                                </div>
                            </div>
                            <!-- doTipBoxShow -->
                        </el-col>
                        <!-- 提示信息 -->
                        <el-col :span="3" style="position: relative;" v-show="form.platform == 'OKX'">
                            <div style="width: 16px; height: 16px; border-radius: 16px; background-color: white; color: black; font-size: 10px; line-height: 16px; cursor: pointer; margin: 7px;"
                                @mouseenter="doTipBoxShow">
                                !
                            </div>
                            <div v-show="showTipBox" style="width: 324px; height: 90px; border-radius: 8px; border: 1px solid #3E454E; background: #22272E; color: white; font-size: 12px; position: absolute; text-align: left; padding: 8px;">
                                注意：OKX提现地址需要在快捷提币名单中，否则无法提币。

                                <div @click="doTipBoxHide" style="position: absolute; top: -8px; right: -8px; width: 16px; height: 16px; background: #3E454E; color: white; cursor: pointer; border-radius: 16px; font-size: 10px; text-align: center; line-height: 16px; border: 1px solid #3E454E;">
                                    <i class="el-icon-close"></i>
                                </div>
                            </div>
                            <!-- doTipBoxShow -->
                        </el-col>
                    </el-row>
                            
                    <el-row v-if="currentPanel == 'cex' && form.platform == 'FTX'" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                        <el-col :span="7" style="text-align: right;">
                            <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">账户名</label>
                        </el-col>
                        <el-col :span="14" style="text-align: left;">
                            <input v-model="form.accountName" type="text" value="" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                        </el-col>
                    </el-row>
                    
                    <el-row v-if="currentPanel == 'cex'" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                        <el-col :span="7" style="text-align: right;">
                            <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">API KEY</label>
                        </el-col>
                        <el-col :span="14" style="text-align: left;">
                            <input v-model="form.apiKey" type="text" value="" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                        </el-col>
                    </el-row>
                            
                    <el-row v-if="currentPanel == 'cex'" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                        <el-col :span="7" style="text-align: right;">
                            <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">SECRET KEY</label>
                        </el-col>
                        <el-col :span="14" style="text-align: left;">
                            <input v-model="form.secretKey" type="text" value="" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                        </el-col>
                    </el-row>
                            
                    <el-row v-if="currentPanel == 'cex' && form.platform == 'FTX'" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                        <el-col :span="7" style="text-align: right;">
                            <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">二步验证码</label>
                        </el-col>
                        <el-col :span="14" style="text-align: left;">
                            <input v-model="form.exchangeKey" type="text" value="" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                        </el-col>
                    </el-row>
                            
                    <el-row v-if="currentPanel == 'cex' && (form.platform == 'FTX' || form.platform == 'OKX')" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                        <el-col :span="7" style="text-align: right;">
                            <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">提现密码</label>
                        </el-col>
                        <el-col :span="14" style="text-align: left;">
                            <input v-model="form.withdrawPassword" type="text" value="" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                        </el-col>
                    </el-row>
                            
                    <el-row v-if="currentPanel == 'dex'" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; display: none;">
                        <el-col :span="7" style="text-align: right;">
                            <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                                钱包地址
                            </label>
                        </el-col>
                        <el-col :span="14" style="text-align: left;">
                            <input v-model="form.address" type="text" value="" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                        </el-col>
                    </el-row>
                            
                    <el-row v-if="currentPanel == 'dex'" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                        <el-col :span="7" style="text-align: right;">
                            <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">私钥</label>
                        </el-col>
                        <el-col :span="14" style="text-align: left;">
                            <input v-model="form.privateKey" type="text" value="" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                        </el-col>
                    </el-row>

                    <el-row v-if="currentPanel == 'dex'" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                        <el-col :span="7" style="text-align: right;">
                            <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">链</label>
                        </el-col>
                        <el-col :span="14" style="text-align: left;">
                            <select v-model="form.chainName" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px">
                                <option v-for="(item, index) in targetChainList2" :key="index" :value="item.value">{{item.text}}</option>
                            </select>
                        </el-col>
                    </el-row>

                </template>

                <!-- 第二步 -->
                <template v-if="step == 2">
            
                    <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; margin-bottom: 20px;">
                        <el-col :span="24" style="text-align: left;">
                            <FtGroupButtonWidget v-if="currentPanel == 'cex'" :buttons="[
                                {
                                    id: 'cex',
                                    text: '交易所钱包',
                                    textI18n: '',
                                    active: true
                                }
                            ]" />
                            <FtGroupButtonWidget v-if="currentPanel == 'dex'" :buttons="[
                                {
                                    id: 'dex',
                                    text: '链上钱包',
                                    textI18n: '',
                                    active: true
                                }
                            ]" />
                        </el-col>
                    </el-row>
                            
                    <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                        <el-col :span="7" style="text-align: right;">
                            <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                                创建者
                            </label>
                        </el-col>
                        <el-col :span="14" style="text-align: left;">
                            <input v-model="userName" type="text" readonly style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                        </el-col>
                    </el-row>

                    <el-row v-for="(item, index) in approverUsernameList" :key="index" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                        <el-col :span="7" style="text-align: right;">
                            <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">审批人</label>
                        </el-col>
                        <el-col :span="14" style="text-align: left;">
                            <!-- <input v-model="item.value" type="text" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/> -->
                            <select v-model="item.value" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px">
                                <option v-for="(item, index) in filterAccountList(accountList)" :key="index" :value="item.value">{{item.text}}</option>
                            </select>
                        </el-col>
                        <el-col :span="2" style="text-align: left;">
                            <i class="el-icon-remove-outline" style="color: red; margin-left: 10px; cursor: pointer;" 
                                @click="onAction({id: 'delApproverUser', value: index})"></i>
                        </el-col>
                    </el-row>

                    <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                        <el-col :span="24">
                            <FtGroupButtonWidget :buttons="[{
                                id: 'addApproverUser',
                                icon: '',
                                text: '添加',
                                textI18n: '',
                            }]" mode="single" v-on:switch-change="onAction" style="float: none; display: inline-block; margin: auto; background: #3E454E;" />
                        </el-col>
                    </el-row>

                </template>

                <!-- 第三步 -->
                <template v-if="step == 3">
            
                    <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; margin-bottom: 20px;">
                        <el-col :span="24" style="text-align: left;">
                            <FtGroupButtonWidget v-if="currentPanel == 'cex'" :buttons="[
                                {
                                    id: 'cex',
                                    text: '审核通过设置',
                                    textI18n: '',
                                    active: true
                                }
                            ]" />
                        </el-col>
                    </el-row>
                            
                    <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                        <el-col :span="7" style="text-align: right;">
                            <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                                创建者
                            </label>
                        </el-col>
                        <el-col :span="14" style="text-align: left;">
                            <input v-model="userName" type="text" readonly style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                        </el-col>
                    </el-row>
                            
                    <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                        <el-col :span="7" style="text-align: right;">
                            <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                                审核通过人数
                            </label>
                        </el-col>
                        <el-col :span="3" style="text-align: left;">
                            <input v-model="form.requireApprovalNumber" type="text" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                        </el-col>
                        <el-col :span="7" style="text-align: left; color: #fff;">
                            of {{approverUsernameList.length + 1}}
                        </el-col>
                    </el-row>

                </template>
                
                <!-- 步骤控制 -->
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; margin-top: 20px;">
                    <el-col :span="24">
                        <FtGroupButtonWidget v-if="step == 1" :buttons="[{
                            id: 'nextstep',
                            icon: '',
                            text: '下一步（1/3）',
                            textI18n: '',
                        }]" mode="single" v-on:switch-change="onAction" style="float: none; display: inline-block; margin: auto; background: #3E454E;" />

                        <FtGroupButtonWidget v-if="step == 2" :buttons="[{
                            id: 'prevstep',
                            icon: '',
                            text: '上一步（1/3）',
                            textI18n: '',
                        }]" mode="single" v-on:switch-change="onAction" style="float: none; display: inline-block; margin: auto; background: #3E454E;" />

                        <FtGroupButtonWidget v-if="step == 2" :buttons="[{
                            id: 'nextstep',
                            icon: '',
                            text: '下一步（3/3）',
                            textI18n: '',
                        }]" mode="single" v-on:switch-change="onAction" style="float: none; display: inline-block; margin: auto; background: #3E454E;" />

                        <FtGroupButtonWidget v-if="step == 3" :buttons="[{
                            id: 'prevstep',
                            icon: '',
                            text: '上一步（2/3）',
                            textI18n: '',
                        }]" mode="single" v-on:switch-change="onAction" style="float: none; display: inline-block; margin: auto; background: #3E454E;" />

                        <FtGroupButtonWidget v-if="step == 3" :buttons="[{
                            id: 'append',
                            icon: '',
                            text: '确定',
                            textI18n: '',
                        }]" mode="single" v-on:switch-change="onAction" style="float: none; display: inline-block; margin: auto; background: #3E454E;" />
                    </el-col>
                </el-row>
            </div>

        </div>

    </div>
</template>

<script>
import {Message} from "element-ui";
import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";

import WalletManagement from "@/api/wallet/WalletManagement";
import UserService from "@/api/user/UserService";

import { useWalletStore, useWalletOutsideStore } from '@/store/wallet'
import TrialphaLoading from "@/components/TrialphaLoading";

export default {
    components: {
        FtGroupButtonWidget,
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'cex'
        }
    },
    data() {
        return {
            userName: '',
            // 新建面板
            detailSwitchButtons: [
                {
                    id: 'cex',
                    text: '交易所钱包',
                    textI18n: '',
                    active: true
                },
                {
                    id: 'dex',
                    text: '链上钱包',
                    textI18n: '',
                    active: false
                }
            ],
            currentPanel: 'cex',

            // 第几个步骤
            step: 1,

            // // 审批人员名单
            // usernameList: [
            //     {value: '', text: ''},
            //     {value: '', text: ''},
            //     {value: '', text: ''},
            // ],

            // 审批人员名单(已选择)
            approverUsernameList: [
                {value: ''},
                {value: ''},
                {value: ''},
            ],

            // 表单数据
            form: {
                /**
                 * 地址录入
                 */
                platform: '',
                accountName: '', // FTX 账户名
                name: '',
                address: '',
                apiKey: '',
                secretKey: '',
                exchangeKey: '', // -> secondaryVerification
                withdrawPassword: '', // 提现密码
                privateKey: '',
                chainName: 'ETH',
                userName: '',
                /**
                 * 审核成员
                 */
                approverUsernameList: [],
                /**
                 * 审核通过设置
                 */
                requireApprovalNumber: 1
            },

            // 提示框
            showTipBox: false,
            
        }
    },
    computed: {
        // 账号列表
        accountList: () => {
            let walletOutsideStore = useWalletOutsideStore()
            return walletOutsideStore.accountList
        },
        // 交易所列表
        exchangeList: () => {
            let walletOutsideStore = useWalletOutsideStore()
            return walletOutsideStore.exchangeList
        },
        // 目标链列表
        targetChainList: () => {
            let walletOutsideStore = useWalletOutsideStore()
            return walletOutsideStore.dexTargetChainList
        },
        // 目标链列表
        targetChainList2: () => {
            return [
                {value: 'ETH', text: 'eth-base'},
                {value: 'TRON', text: 'TRON'},
            ]
        },
    },
    mounted() {
        this.userName = UserService.getUserName()
        this.currentPanel = this.mode
        this.detailSwitchButtons = this.detailSwitchButtons.map(item => {
            if (item.id == this.mode) {
                item.active = true
            } else {
                item.active = false
            }
            return item
        })

        let walletStore = useWalletStore()
        walletStore.getUserList()

        // // TEST 
        // let that = this
        // setTimeout(() => {
        //     that.$emit('success', {id: 'createDexWalletSuccess', value: {}})
        // }, 2000)
    },
    methods: {
        // 过滤审批人员列表，排除当前创建人
        filterAccountList(accountList) {
            var userName = this.userName
            return accountList.filter(item => {
                return item.value != userName
            })
        },

        // 各种切换处理
        onAction(item) {
            let that = this
            if (item.id == 'close') {
                console.log('switch change event', item.id)
                this.$emit('update:show', false)
                let walletStore = useWalletStore()
                walletStore.resetFormData()
            } else if (item.id == 'cex') {
                this.currentPanel = item.id
            } else if (item.id == 'dex') {
                this.currentPanel = item.id
            } else if (item.id == 'prevstep') {
                if (this.currentPanel == 'cex') {
                    switch (this.step) {
                        case 1:
                            break
                        case 2:
                            this.step = 1
                            break
                        case 3:
                            this.step = 2
                            break
                    }
                } else if (this.currentPanel == 'dex') {
                    switch (this.step) {
                        case 1:
                            break
                        case 2:
                            this.step = 1
                            break
                        case 3:
                            this.step = 2
                            break
                    }
                }
            } else if (item.id == 'nextstep') {
                if (this.currentPanel == 'cex') {
                    switch (this.step) {
                        case 1:
                            if (this.form.name == '') {
                                Message.error('请输入钱包名');
                                return 
                            }
                            if (this.form.platform == '') {
                                Message.error('请选择交易所');
                                return 
                            }
                            // if (this.form.platform == 'FTX' && this.form.accountName == '') {
                            //     // 仅针对FTX交易所
                            //     Message.error('请输入账户名');
                            //     return 
                            // }
                            if (this.form.apiKey == '') {
                                Message.error('请输入API KEY');
                                return 
                            }
                            if (this.form.secretKey == '') {
                                Message.error('请输入SECRET KEY');
                                return 
                            }
                            if (this.form.platform == 'FTX' && this.form.exchangeKey == '') {
                                // 仅针对FTX交易所
                                Message.error('请输入交易所二步验证码');
                                return 
                            }
                            if (this.form.platform == 'FTX' && this.form.withdrawPassword == '') {
                                // 仅针对FTX交易所
                                Message.error('请输入提现密码');
                                return 
                            }
                            if (this.form.platform == 'OKX' && this.form.withdrawPassword == '') {
                                // 仅针对OKX交易所
                                Message.error('请输入提现密码');
                                return 
                            }

                            // 验证
                            var cexValidParams = {
                                apiKey: (() => {
                                    if (that.form.platform == 'FTX') {
                                        if (that.form.accountName) {
                                            return  [that.form.apiKey, 'FtxSub', that.form.accountName].join('_')
                                        }
                                    }
                                    return that.form.apiKey
                                })(),
                                secretKey: that.form.secretKey,
                                platform: that.form.platform
                            }
                            if (this.form.platform == 'OKX') {
                                cexValidParams.withdrawPassword = this.form.withdrawPassword
                            }
                            var loading = TrialphaLoading.loading(that);
                            WalletManagement.walletIsValidCexWallet(cexValidParams).then(validResult => {
                                if (validResult) {
                                    console.log('钱包参数验证结果 钱包参数正确')
                                    that.step = 2
                                } else {
                                    console.log('钱包参数验证结果 钱包参数错误')
                                    Message.error('钱包参数错误')
                                }
                                loading.close()
                            }, () => {
                                loading.close()
                            })
                            break
                        case 2:
                            if (this.approverUsernameList.length) {
                                let r = false
                                this.approverUsernameList.forEach(m => {
                                    if (!m.value.trim()) {
                                        r = true
                                    }
                                })
                                if (r) {
                                    Message.error('请输入审核人员');
                                    return 
                                }
                                // 审批人员是否重复
                                let d = []
                                this.approverUsernameList.forEach(m => {
                                    if (d.indexOf(m.value) > -1) {
                                        r = true
                                    } else {
                                        d.push(m.value)
                                    }
                                })
                                if (r) {
                                    Message.error('审核人员重复');
                                    return 
                                }
                            }
                            this.form.requireApprovalNumber = this.approverUsernameList.length + 1
                            this.step = 3
                            break
                        case 3:
                            break
                    }
                } else if (this.currentPanel == 'dex') {
                    switch (this.step) {
                        case 1:
                            if (this.form.name == '') {
                                Message.error('请输入姓名');
                                return 
                            }
                            if (this.form.privateKey == '') {
                                Message.error('请输入私钥');
                                return 
                            }
                            // 验证
                            var dexValidParams = {
                                walletAddress: that.form.address,
                                privateKey: that.form.privateKey,
                                dexChain: that.form.chainName
                            }
                            var loading2 = TrialphaLoading.loading(that);
                            WalletManagement.walletIsValidDexWallet(dexValidParams).then(validResult => {
                                if (validResult) {
                                    console.log('钱包参数验证结果 钱包参数正确')
                                    that.step = 2
                                } else {
                                    console.log('钱包参数验证结果 钱包参数错误')
                                    Message.error('钱包参数错误')
                                }
                                loading2.close()
                            }, () => {
                                loading2.close()
                            })
                            break
                        case 2:
                            if (this.approverUsernameList.length) {
                                let r = false
                                this.approverUsernameList.forEach(m => {
                                    if (!m.value.trim()) {
                                        r = true
                                    }
                                })
                                if (r) {
                                    Message.error('请输入审核人员');
                                    return 
                                }
                                // 审批人员是否重复
                                let d = []
                                this.approverUsernameList.forEach(m => {
                                    if (d.indexOf(m.value) > -1) {
                                        r = true
                                    } else {
                                        d.push(m.value)
                                    }
                                })
                                if (r) {
                                    Message.error('审核人员重复');
                                    return 
                                }
                            }
                            this.form.requireApprovalNumber = this.approverUsernameList.length + 1
                            this.step = 3
                            break
                        case 3:
                            break
                    }
                }
            } else if (item.id == 'addApproverUser') {
                if (this.approverUsernameList.length >= 20) {
                    Message.error('最多20人审核');
                    return
                }
                this.approverUsernameList.push({value: ''})
            } else if (item.id == 'delApproverUser') {
                this.approverUsernameList.splice(item.value, 1)
            } else if (item.id == 'append') {

                // 检查审核人员数量
                // let aCount = that.approverUsernameList.length + 1
                let rCount = that.form.requireApprovalNumber
                if (rCount < 1) {
                    Message.error('审核通过人数错误');
                    return
                }
                // else if (aCount == 2) {
                //     if (rCount != 1 && rCount != 2) {
                //         Message.error('审核通过人数错误');
                //         return
                //     }
                // }
                // else if (aCount > 2) {
                //     if (rCount < 2) {
                //         Message.error('审核通过人数错误');
                //         return
                //     }
                //     if (rCount > aCount) {
                //         Message.error('审核通过人数错误');
                //         return
                //     }
                // }

                // 新建
                if (this.currentPanel == 'cex') {
                    let params = {
                        userName: UserService.getUserName(),
                        platform: this.form.platform,
                        humanReadableName: this.form.name,
                        apiKey: (() => {
                            if (that.form.platform == 'FTX') {
                                if (that.form.accountName) {
                                    return  [that.form.apiKey, 'FtxSub', that.form.accountName].join('_')
                                }
                            }
                            return that.form.apiKey
                        })(),
                        secretKey: this.form.secretKey,
                        secondaryVerification: this.form.exchangeKey,
                        withdrawPassword: this.form.withdrawPassword,
                        approverUsernameList: this.approverUsernameList.map(m => {
                            return m.value
                        }).concat(UserService.getUserName()),
                        requireApprovalNumber: this.form.requireApprovalNumber,
                    }
                    var loading3 = TrialphaLoading.loading(that);
                    WalletManagement.createCexWallet(params).then(
                        (res) => {
                            console.log('添加成功 CEX', res)
                            Message.success('添加成功');
                            let walletStore = useWalletStore()
                            // walletStore.listWallet()
                            walletStore.listWalletCreatedByRequester().then(() => {
                                that.$emit('update:show', false)
                                that.$emit('success', {id: 'createCexWalletSuccess', value: params})
                                loading3.close()
                            }, () => {
                                loading4.close()
                            })
                        },
                        () => {
                            console.log('添加失败 CEX')
                            // Message.error(this.$i("operationFail"));
                            loading3.close()
                        }
                    );
                } else if (this.currentPanel == 'dex') {
                    let params = {
                        userName: UserService.getUserName(),
                        humanReadableName: this.form.name,
                        address: this.form.address,
                        privateKey: this.form.privateKey,
                        chainName: this.form.chainName,
                        approverUsernameList: this.approverUsernameList.map(m => {
                            return m.value
                        }).concat(UserService.getUserName()),
                        requireApprovalNumber: this.form.requireApprovalNumber,
                    }
                    var loading4 = TrialphaLoading.loading(that);
                    WalletManagement.createDexWallet(params).then(
                        (res) => {
                            console.log('添加成功 DEX', res)
                            Message.success('添加成功');
                            let walletStore = useWalletStore()
                            // walletStore.listWallet()
                            walletStore.listWalletCreatedByRequester().then(() => {
                                that.$emit('update:show', false)
                                that.$emit('success', {id: 'createDexWalletSuccess', value: params})
                                loading4.close()
                            }, () => {
                                loading4.close()
                            })
                        },
                        () => {
                            console.log('添加失败 DEX')
                            // Message.error(this.$i("operationFail"));
                            loading4.close()
                        }
                    );
                }
            }
        },

        // 提示框
        doTipBoxShow() {
            this.showTipBox = true
        },
        doTipBoxHide() {
            this.showTipBox = false
        }
        
    }
}
</script>

<style scoped>
.create-customer-dialog{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
}
.create-customer-dialog-content{
    background: #22272E;
    width: 500px;
    /* height: 360px; */
    padding: 30px;
    border: 1px solid #3E454E;
    border-radius: 8px;
    position: fixed;
    left: 50%;
    top: 30%;
    margin-left: -250px;
    margin-top: -140px;
}
.create-customer-dialog-content-inner{
    position: relative;
}
</style>