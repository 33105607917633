<script type="text/javascript">
import Moralis from "moralis";

async function initMoralis() {
  await Moralis.start({
    apiKey: "fqHjbuDVfZp47b0aedxi2FFcT4hMtTA6Q1QTSHJAWizIKJ6R0Prgonxr3VspZ4IS",
    // ...and any other configuration
  });
}

async function getAllERC20TokenBalance(chain, address) {
  const response = await Moralis.EvmApi.token.getWalletTokenBalances({
    address,
    chain,
  });
  return (response.toJSON());
}

async function getNativeTokenBalance(chain, address) {
  const response = await Moralis.EvmApi.balance.getNativeBalance({
    address,
    chain,
  });
  return Number(response.toJSON().balance) / Math.pow(10, 18);
}

export default {
  initMoralis,
  getAllERC20TokenBalance,
  getNativeTokenBalance
};
</script>