<script>

function getAmountString(amount, decimals) {
  return amount + '' + (Math.pow(10, decimals) + '').replaceAll('1', '');
}

function getRealAmount(amount, decimals) {
  return Number.prototype.accDiv(amount, (Math.pow(10, decimals)));
}

export default {
  getAmountString,
  getRealAmount
}
</script>