<template>
  <div :dto="dto">
    <el-skeleton animated :loading="rendering" :throttle="268">
      <template slot="template">
        <trialpha-sk style="margin-top: 0;width: 60%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 40%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 100%;float: left"></trialpha-sk>
      </template>
      <template>
        <el-row>
          <el-collapse class="trialpha-collapse" v-model="activeNames" style="border: 0" @change="collapseChange">
            <el-collapse-item class="trialpha-collapse" style="border: 0" :name="ins.sid" v-for="(ins, index) in instanceList" :key="ins.strategyConfig.token0Address + ins.strategyConfig.token1Address">
              <template slot="title">
                <el-row style="width: 168px;display: flex">
                  <el-card class="black-card" body-style="padding: 0"
                           style="line-height: 46px;margin-left: -10px;margin-top: 1px;border-left: 0;border-top: 0;border-bottom: 0;border-radius: 0;height: 46px;width: 168px;background-color: #444c56;text-align: center">
                      <span class="dark-mode-font" :style="';font-size: 12px;' + (ins.isActive ? 'color: #FFFFFF' : '')">{{
                          ins.strategyConfig.binanceToken0Symbol + ' / ' + ins.strategyConfig.binanceToken1Symbol
                        }} (Positions: {{ ins.strategyConfig.allPositions.length }})</span>
                  </el-card>
                </el-row>
                <div style="flex: 1">
                  <span style="margin-left: 8px;float: left;display: inline-block">Chain: {{ getChainNameById(ins.strategyConfig.user.chainId) }}</span>
                  <el-row style="float: right;line-height: 46px;margin-right: 10px;margin-top: 1px;border-left: 0;border-top: 0;border-bottom: 0;border-radius: 0;height: 46px;width: 468px;padding: 0">
                    <el-col :span="9">
                      <span style="font-size: 13px;">Deposit: {{ ins.strategyConfig.depositPayTokenAmount }} {{ ins.strategyConfig.binancePayTokenSymbol }}</span>
                    </el-col>
                    <el-col :span="10">
                      <el-row style="line-height: 23px;height: 23px;padding-top: 1px">
                        <span style="font-size: 12px;font-family: 'Microsoft Sans Serif',serif">Liquidity: {{ positionInfoList[index].token0L }} ({{ ins.strategyConfig.binanceToken0Symbol }})</span>
                      </el-row>
                      <el-row style="line-height: 23px;height: 23px;padding-bottom: 1px">
                        <span style="font-size: 12px;font-family: 'Microsoft Sans Serif',serif">Liquidity: {{ positionInfoList[index].token1L }} ({{ ins.strategyConfig.binanceToken1Symbol }} )</span>
                      </el-row>
                    </el-col>
                    <el-col :span="5">
                      <el-row style="line-height: 23px;height: 23px;padding-top: 1px">
                        <span style="font-size: 12px;font-family: 'Microsoft Sans Serif',serif">Fee: {{ positionInfoList[index].token0P }}</span>
                      </el-row>
                      <el-row style="line-height: 23px;height: 23px;padding-bottom: 1px">
                        <span style="font-size: 12px;font-family: 'Microsoft Sans Serif',serif">Fee: {{ positionInfoList[index].token1P }}</span>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
              </template>
              <el-row style="height: 90%;overflow-y: auto">
                <el-row style="width: 98%;margin-top: 8px;margin-left: auto;margin-right: auto">
                  <el-col :span="5">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">实例状态 (ID: {{ ins.sid }})</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">是否清空所有仓位并提现</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto">
                  <el-col :span="5" style=";padding-right: 8px">
                    <el-select class="select-symbol" style="width: 100%" v-model="ins.isActive" placeholder="请选择">
                      <el-option
                          v-for="item in [{value: true, label: 'Active'}, {value: false, label: 'Inactive'}]"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="8" style=";padding-right: 8px">
                    <el-select class="select-symbol" style="width: 100%" v-model="ins.strategyConfig.closeAllPosition" placeholder="请选择">
                      <el-option
                          v-for="item in [{value: true, label: '是'}, {value: false, label: '否'}]"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="3" style=";padding-right: 8px">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 3px;" @click="walletTransfer(ins)" :loading="withdrawing">Transfer</el-button>
                  </el-col>
                  <el-col :span="4" style=";padding-right: 8px">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 3px;" @click="walletDeposit(ins)" :loading="withdrawing">Deposit</el-button>
                  </el-col>
                  <el-col :span="4" style=";padding-right: 8px">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 3px;" @click="contractWithdraw(ins)" :loading="withdrawing">Withdraw</el-button>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin: 10px auto 0 auto">
                  <el-col :span="5" style="padding-right: 8px">
                    <span class="dark-mode-font" style="color: white">Chain</span>
                  </el-col>
                  <el-col :span="8" style="padding-right: 8px">
                    <span class="dark-mode-font" style="color: white">Rpc</span>
                  </el-col>
                  <el-col :span="11" style="padding-right: 8px">
                    <span class="dark-mode-font" style="color: white">Vault Contract Address</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-left: 10px;margin-top: 3px">
                  <el-col :span="5" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" :value="getChainNameById(ins.strategyConfig.user.chainId)" disabled/>
                  </el-col>
                  <el-col :span="8" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" type="password" v-model="ins.strategyConfig.user.rpc"/>
                  </el-col>
                  <el-col :span="11" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.user.vaultContractAddress" disabled/>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-left: 10px;margin-top: 8px">
                  <el-col :span="12" style="padding-right: 8px">
                    <span class="dark-mode-font" style="color: white">Pay Token Address</span>
                  </el-col>
                  <el-col :span="4" style="padding-right: 8px">
                    <span class="dark-mode-font" style="color: white">Decimals</span>
                  </el-col>
                  <el-col :span="8" style="padding-right: 8px">
                    <span class="dark-mode-font" style="color: white">Pay Token Binance Coin Name</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-left: 10px;margin-top: 3px">
                  <el-col :span="12" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.payTokenAddress" disabled/>
                  </el-col>
                  <el-col :span="4" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.payTokenDecimals" disabled/>
                  </el-col>
                  <el-col :span="8" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.binancePayTokenSymbol" disabled/>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-left: 10px;margin-top: 8px">
                  <el-col :span="12" style="padding-right: 8px">
                    <el-col :span="8">
                      <span class="dark-mode-font" style="color: white">Token0 Address</span>
                    </el-col>
                    <el-col :span="16" style="padding-right: 5px">
                      <el-checkbox v-model="ins.strategyConfig.lockToken0Fee" style="color: white;float: right;font-size: 12px;">Lock Profit: {{ins.strategyConfig.token0TotalFee.toFixed(4)}}</el-checkbox>
                    </el-col>
                  </el-col>
                  <el-col :span="4" style="padding-right: 8px">
                    <span class="dark-mode-font" style="color: white">Decimals</span>
                  </el-col>
                  <el-col :span="8" style="padding-right: 8px">
                    <span class="dark-mode-font" style="color: white">Pay Token0 Binance Coin Name</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-left: 10px;margin-top: 3px">
                  <el-col :span="12" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.token0Address" disabled/>
                  </el-col>
                  <el-col :span="4" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.token0Decimals" disabled/>
                  </el-col>
                  <el-col :span="8" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.binanceToken0Symbol" disabled/>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-left: 10px;margin-top: 8px">
                  <el-col :span="12" style="padding-right: 8px">
                    <el-col :span="8">
                      <span class="dark-mode-font" style="color: white">Token1 Address</span>
                    </el-col>
                    <el-col :span="16" style="padding-right: 5px">
                      <el-checkbox v-model="ins.strategyConfig.lockToken1Fee" style="color: white;float: right;font-size: 12px">Lock Profit: {{ins.strategyConfig.token1TotalFee.toFixed(4)}}</el-checkbox>
                    </el-col>
                  </el-col>
                  <el-col :span="4" style="padding-right: 8px">
                    <span class="dark-mode-font" style="color: white">Decimals</span>
                  </el-col>
                  <el-col :span="8" style="padding-right: 8px">
                    <span class="dark-mode-font" style="color: white">Pay Token1 Binance Coin Name</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-left: 10px;margin-top: 3px">
                  <el-col :span="12" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.token1Address" disabled/>
                  </el-col>
                  <el-col :span="4" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.token1Decimals" disabled/>
                  </el-col>
                  <el-col :span="8" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.binanceToken1Symbol" disabled/>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-left: 10px;margin-top: 8px">
                  <el-col :span="12" style="padding-right: 8px">
                    <el-col :span="12">
                      <span class="dark-mode-font" style="color: white">Last Transaction Time</span>
                    </el-col>
                    <el-col :span="12">
                      <span class="dark-mode-font" style="color: white">Next Transaction Time</span>
                    </el-col>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-left: 10px;margin-top: 3px">
                  <el-col :span="12" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" :value="new Date(ins.strategyConfig.lastExecutePositionTimeMills).toString()" disabled/>
                  </el-col>
                  <el-col :span="12" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" :value="new Date(ins.strategyConfig.lastExecutePositionTimeMills + ins.strategyConfig.executeTimeGasMills).toString()" disabled/>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-left: 10px;margin-top: 8px">
                  <el-col :span="8" style="padding-right: 8px">
                    <span class="dark-mode-font" style="color: white">Pool Fee</span>
                  </el-col>
                  <el-col :span="8" style="padding-right: 8px">
                    <span class="dark-mode-font" style="color: white">Max Slippage (%)</span>
                  </el-col>
                  <el-col :span="8" style="padding-right: 8px">
                    <span class="dark-mode-font" style="color: white">Pay Token Deposit Amount</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-left: 10px;margin-top: 3px">
                  <el-col :span="8" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.poolFee"/>
                  </el-col>
                  <el-col :span="8" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.slippage"/>
                  </el-col>
                  <el-col :span="8" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.depositPayTokenAmount"/>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-left: 10px;margin-top: 8px">
                  <el-col :span="8" style="padding-right: 8px">
                    <span class="dark-mode-font" style="color: white">Bollinger Bands Period</span>
                  </el-col>
                  <el-col :span="8" style="padding-right: 8px">
                    <span class="dark-mode-font" style="color: white">Intervals</span>
                  </el-col>
                  <el-col :span="8" style="padding-right: 8px">
                    <span class="dark-mode-font" style="color: white">Balance Shares</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-left: 10px;margin-top: 3px">
                  <el-col :span="8" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.bollingerBandsPeriod"/>
                  </el-col>
                  <el-col :span="8" style="padding-right: 8px">
                    <el-select class="select-symbol" style="width: 100%" v-model="ins.strategyConfig.executeTimeGasMills" placeholder="请选择">
                      <el-option
                          v-for="item in [{value: 300000, label: 'M5'},{value: 1500000, label: 'M15'},{value: 3600000, label: 'H1'},{value: 14400000, label: 'H4'},{value: 86400000, label: 'D1'}]"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="8" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.balanceDivide"/>
                  </el-col>
                </el-row>

                <el-divider></el-divider>

                <el-card class="trade-black-card" style="height: 128px;width: 98%;border-radius: 0;margin: auto;" shadow="none" body-style="padding: 0">
                  <general-contract-profit-panel :ref="'profit_' + ins.sid" :id="'profit_' + ins.sid" style="width: 100%;height: 126px;z-index: 1;"></general-contract-profit-panel>
                </el-card>

                <el-divider></el-divider>

                <el-row style="margin-top: 20px;padding-left: 10px;padding-right: 10px">
                  <el-card class="black-card"
                           style="border-radius: 3px 3px 0 0;border-bottom: 0;background-color: #1c2128"
                           body-style="padding: 10px" shadow="none">
                    <el-row>
                      <el-col :span="10">
                        <span class="dark-mode-font" style="color: white">当前持有仓位</span>
                      </el-col>
                      <el-col :span="14" style="text-align: right">
                        <span class="dark-mode-font" style="color: white">
                          Total: {{ getAllPositionTotalUsd(ins.strategyConfig.allPositions, ins.strategyConfig.binanceToken0Symbol, ins.strategyConfig.binanceToken1Symbol) }}
                        </span>
                      </el-col>
                    </el-row>
                  </el-card>
                  <el-card class="black-card" style="border-radius: 0;height: 28px;background-color: #1c2128"
                           shadow="none" body-style="padding: 4px 10px 3px 10px">
                    <el-row>
                      <el-col :span="3">
                        <span class="dark-mode-font"
                              style="display: inline-block;float: left;text-align: left;font-size: 12px;color:darkgray ">Id</span>
                      </el-col>
                      <el-col :span="4">
                        <span class="dark-mode-font"
                              style="display: inline-block;float: left;text-align: right;font-size: 12px;color:darkgray ">{{ ins.strategyConfig.binanceToken0Symbol }} Amount</span>
                      </el-col>
                      <el-col :span="4">
                        <span class="dark-mode-font"
                              style="display: inline-block;float: left;text-align: right;font-size: 12px;color:darkgray ">{{ ins.strategyConfig.binanceToken1Symbol }} Amount</span>
                      </el-col>
                      <el-col :span="4">
                        <span class="dark-mode-font"
                              style="display: inline-block;float: left;text-align: right;font-size: 12px;color:darkgray ">{{ ins.strategyConfig.binanceToken0Symbol }} Profit</span>
                      </el-col>
                      <el-col :span="4">
                        <span class="dark-mode-font"
                              style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">{{ ins.strategyConfig.binanceToken1Symbol }} Profit</span>
                      </el-col>
                      <el-col :span="5">
                        <span class="dark-mode-font"
                              style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">Total USD / Unclaimed</span>
                      </el-col>
                    </el-row>
                  </el-card>
                  <el-card class="black-card" style="border-radius: 0;border-top: 0;max-height: 224px;overflow-y: auto" shadow="none" body-style="padding: 0">
                    <div v-if="ins.strategyConfig.allPositions.length > 0">
                      <el-card class="black-card-hover" shadow="none" style="border: 0;border-radius: 0"
                               body-style="padding:0" v-for="(p, index) in ins.strategyConfig.allPositions"
                               :key="index">
                        <el-row style="height: 25px;border-bottom: 1px solid #2e3033;padding: 3px 10px 3px 10px">
                          <el-col :span="3">
                            <span class="dark-mode-font"
                                  style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white "><a :href="'https://app.uniswap.org/#/pools/' + p.tokenId + '?chain=' + getChainNameById(ins.strategyConfig.user.chainId).toLowerCase()" target="_blank">{{
                                p.tokenId
                              }}</a></span>
                          </el-col>
                          <el-col :span="4">
                            <span class="dark-mode-font"
                                  style="display: inline-block;float: left;text-align: right;font-size: 12px;color:white ">{{
                                p['price']
                              }}</span>
                          </el-col>
                          <el-col :span="4">
                            <span class="dark-mode-font"
                                  style="display: inline-block;float: left;text-align: right;font-size: 12px;color:white ">{{
                                p.token0Liquidity
                              }}</span>
                          </el-col>
                          <el-col :span="4">
                            <span class="dark-mode-font"
                                  style="display: inline-block;float: left;text-align: right;font-size: 12px;color:white ">{{
                                p.token1Liquidity
                              }}</span>
                          </el-col>
                          <el-col :span="4">
                            <span class="dark-mode-font"
                                  style="display: inline-block;float: left;text-align: right;font-size: 12px;color:white ">{{
                                p.token0Fee
                              }}</span>
                          </el-col>
                          <el-col :span="4">
                            <span class="dark-mode-font"
                                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{
                                p.token1Fee
                              }}</span>
                          </el-col>
                          <el-col :span="5">
                            <span class="dark-mode-font"
                                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{ getPositionTotalUsd(p, ins.strategyConfig.binanceToken0Symbol, ins.strategyConfig.binanceToken1Symbol) }}</span>
                          </el-col>
                        </el-row>
                      </el-card>
                    </div>
                    <div v-if="ins.strategyConfig.allPositions.length === 0">
                      <el-row
                          style="height: 25px;border: 0;background-color: rgba(0, 0, 0, 0);padding: 3px 10px 3px 10px;text-align: center;margin-top: 20px;margin-bottom: 25px">
                        <span class="dark-mode-font" style=";font-size: 12px;color:gray;text-align: center;"><i
                            class="el-icon-s-order"/> EMPTY ...</span>
                      </el-row>
                    </div>
                    <el-card class="black-card-hover" shadow="none" style="border: 0;border-radius: 0"
                             body-style="padding:0">
                      <el-row style="height: 25px;border-bottom: 1px solid #2e3033;padding: 3px 10px 3px 10px">
                        <el-col :span="4">
                            <span class="dark-mode-font"
                                  style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">Total Claimed</span>
                        </el-col>
                        <el-col :span="4" :offset="7">
                            <span class="dark-mode-font"
                                  style="display: inline-block;float: left;text-align: right;font-size: 12px;color:white ">{{
                                ins.strategyConfig.token0TotalFee
                              }}</span>
                        </el-col>
                        <el-col :span="4">
                            <span class="dark-mode-font"
                                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{
                                ins.strategyConfig.token1TotalFee
                              }}</span>
                        </el-col>
                      </el-row>
                    </el-card>
                  </el-card>
                </el-row>

                <el-divider></el-divider>
                <el-row style="width: 98%;margin: 10px auto">
                  <el-col :span="12">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 3px 0 0 0;border-right: 0" @click="reset(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i
                        class="el-icon-c-scale-to-original" style="font-size: 16px"/></el-button>
                  </el-col>
                  <el-col :span="12">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0 3px 0 0" @click="deleteIns(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i class="el-icon-delete"
                                                                                                                                                                                                                                    style="font-size: 16px"/>
                    </el-button>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin: -10px auto auto;">
                  <el-col :span="24">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0 0 3px 3px;border-top: 0" @click="save(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i class="el-icon-check"
                                                                                                                                                                                                                                               style="font-size: 16px"/>
                    </el-button>
                  </el-col>
                </el-row>
              </el-row>
            </el-collapse-item>
          </el-collapse>
          <el-collapse class="trialpha-collapse" v-model="createCollapse" style="border-top: 0">
            <el-collapse-item class="trialpha-collapse">
              <template slot="title">
                <i class="el-icon-menu" style="margin-left: 2px;color: #0ecb81;margin-top: 1px"/><h4 class="dark-mode-font" style="margin-left: 8px;padding-top: 6px;color: white">Create New Instance</h4>
              </template>
              <el-row style="width: 98%;margin: 20px auto 0 auto">
                <el-col :span="5" style="padding-right: 8px">
                  <span class="dark-mode-font" style="color: white">ChainId</span>
                </el-col>
                <el-col :span="7" style="padding-right: 8px">
                  <span class="dark-mode-font" style="color: white">Rpc</span>
                </el-col>
              </el-row>
              <el-row style="width: 98%;margin-left: 10px;margin-top: 3px">
                <el-col :span="5" style="padding-right: 8px">
                  <el-select class="chose-symbol select-symbol" filterable v-model="instance.user.chainId" placeholder="Chain Id" style="width: 100%;line-height: 35px">
                    <el-option
                        v-for="item in (chainIds)"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="19" style="padding-right: 8px">
                  <el-input class="trialpha-dark-input-no-pre" v-model="instance.user.rpc"/>
                </el-col>
              </el-row>
              <el-row style="width: 98%;margin-left: 10px;margin-top: 3px">
                <el-col :span="12" style="padding-right: 8px">
                  <span class="dark-mode-font" style="color: white">Pay Token Address</span>
                </el-col>
                <el-col :span="4" style="padding-right: 8px">
                  <span class="dark-mode-font" style="color: white">Decimals</span>
                </el-col>
                <el-col :span="8" style="padding-right: 8px">
                  <span class="dark-mode-font" style="color: white">Pay Token Binance Coin Name</span>
                </el-col>
              </el-row>
              <el-row style="width: 98%;margin-left: 10px;margin-top: 3px">
                <el-col :span="12" style="padding-right: 8px">
                  <el-input class="trialpha-dark-input-no-pre" v-model="instance.payTokenAddress"/>
                </el-col>
                <el-col :span="4" style="padding-right: 8px">
                  <el-input class="trialpha-dark-input-no-pre" v-model="instance.payTokenDecimals"/>
                </el-col>
                <el-col :span="8" style="padding-right: 8px">
                  <el-input class="trialpha-dark-input-no-pre" v-model="instance.binancePayTokenSymbol"/>
                </el-col>
              </el-row>
              <el-row style="width: 98%;margin-left: 10px;margin-top: 3px">
                <el-col :span="12" style="padding-right: 8px">
                  <span class="dark-mode-font" style="color: white">Token0 Address</span>
                </el-col>
                <el-col :span="4" style="padding-right: 8px">
                  <span class="dark-mode-font" style="color: white">Decimals</span>
                </el-col>
                <el-col :span="8" style="padding-right: 8px">
                  <span class="dark-mode-font" style="color: white">Pay Token0 Binance Coin Name</span>
                </el-col>
              </el-row>
              <el-row style="width: 98%;margin-left: 10px;margin-top: 3px">
                <el-col :span="12" style="padding-right: 8px">
                  <el-input class="trialpha-dark-input-no-pre" v-model="instance.token0Address"/>
                </el-col>
                <el-col :span="4" style="padding-right: 8px">
                  <el-input class="trialpha-dark-input-no-pre" v-model="instance.token0Decimals"/>
                </el-col>
                <el-col :span="8" style="padding-right: 8px">
                  <el-input class="trialpha-dark-input-no-pre" v-model="instance.binanceToken0Symbol"/>
                </el-col>
              </el-row>
              <el-row style="width: 98%;margin-left: 10px;margin-top: 3px">
                <el-col :span="12" style="padding-right: 8px">
                  <span class="dark-mode-font" style="color: white">Token1 Address</span>
                </el-col>
                <el-col :span="4" style="padding-right: 8px">
                  <span class="dark-mode-font" style="color: white">Decimals</span>
                </el-col>
                <el-col :span="8" style="padding-right: 8px">
                  <span class="dark-mode-font" style="color: white">Pay Token1 Binance Coin Name</span>
                </el-col>
              </el-row>
              <el-row style="width: 98%;margin-left: 10px;margin-top: 3px">
                <el-col :span="12" style="padding-right: 8px">
                  <el-input class="trialpha-dark-input-no-pre" v-model="instance.token1Address"/>
                </el-col>
                <el-col :span="4" style="padding-right: 8px">
                  <el-input class="trialpha-dark-input-no-pre" v-model="instance.token1Decimals"/>
                </el-col>
                <el-col :span="8" style="padding-right: 8px">
                  <el-input class="trialpha-dark-input-no-pre" v-model="instance.binanceToken1Symbol"/>
                </el-col>
              </el-row>
              <el-row style="height: 88px;overflow-y: auto">
                <el-divider></el-divider>
                <el-row style="width: 98%;margin: 10px auto">
                  <el-col :span="24">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 3px" @click="add(instance)" :disabled="rendering" :loading="processing" v-auth:dbWriteApi><i class="el-icon-check" style="font-size: 16px"/></el-button>
                  </el-col>
                </el-row>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-row>
      </template>
    </el-skeleton>
    <wallet-operation ref="walletOperation"></wallet-operation>
  </div>
</template>

<script>
import {Message} from "element-ui";
import StrategyInstanceService from "@/api/trade/StrategyInstanceService";
import ConfirmProvider from "@/components/ConfirmProvider";
import TrialphaSk from "@/components/TrialphaSk";
import SymbolInfoService from "@/utils/SymbolInfoService.vue";
import GeneralApiService from "@/api/trade/GeneralApiService.vue";
import GeneralContractProfitPanel from "@/pages/controlPanel/accountMgnt/strategies/defi/ContractProfitPanel.vue";
import UniPlusService from "@/api/trade/strategy/UniPlusService.vue";
import WalletOperation from "@/pages/controlPanel/accountMgnt/strategies/defi/WalletOperation.vue";
import UserService from "@/api/user/UserService.vue";

export default {
  name: "UniPlusBoll",
  components: {WalletOperation, GeneralContractProfitPanel, TrialphaSk},
  props: {
    dto: Object
  },
  data: function () {
    return {
      chainId: 31337,
      chainIds: [
        {
          name: 'Mainnet',
          value: 1
        },
        {
          name: 'Arbitrum',
          value: 42161
        },
        {
          name: 'Optimism',
          value: 10
        },
        {
          name: 'Base',
          value: 8453
        },
        {
          name: 'Polygon',
          value: 137
        }
      ],
      withdrawing: false,
      processing: false,
      rendering: true,
      activeNames: [],
      createCollapse: [],
      instance: {},
      instanceList: [],
      accountInstance: {},
      positionInfoList: []
    }
  },
  created() {

  },
  methods: {
    walletTransfer(ins) {
      let that = this;
      ConfirmProvider.openConfirmWithPassword(that, '身份验证', '请输入登入密码以发起转账', function (value) {
        if (value === UserService.getPassword()) {
          that.$refs['walletOperation'].renderTransfer(ins);
        } else {
          Message.error('身份验证失败');
        }
      }, '');
    },
    walletDeposit(ins) {
      this.$refs['walletOperation'].renderDeposit(ins);
    },
    contractWithdraw(ins) {
      this.$refs['walletOperation'].renderWithdraw(ins);
    },
    getInsAllPositionInfo(ins) {
      let token0L = 0;
      let token1L = 0;
      let token0P = 0;
      let token1P = 0;
      ins.strategyConfig.allPositions.forEach((p) => {
        token0L += p.token0Liquidity;
        token1L += p.token1Liquidity;
        token0P += p.token0Fee;
        token1P += p.token1Fee;
      });
      return {
        token0L: token0L.toFixed(4),
        token1L: token1L.toFixed(4),
        token0P: (token0P + ins.strategyConfig.token0TotalFee).toFixed(4),
        token1P: (token1P + ins.strategyConfig.token1TotalFee).toFixed(4)
      }
    },
    getPositionTotalUsd(positionObj, token0, token1) {
      let totalUsd = 0;
      let totalProfit = 0;
      totalUsd += (positionObj.token0Liquidity + positionObj.token0Fee) * this.getPrice(token0);
      totalUsd += (positionObj.token1Liquidity + positionObj.token1Fee) * this.getPrice(token1);
      totalProfit += (positionObj.token0Fee) * this.getPrice(token0);
      totalProfit += (positionObj.token1Fee) * this.getPrice(token1);
      return totalUsd.toFixed(2) + ' / ' + totalProfit.toFixed(4);
    },
    getAllPositionTotalUsd(allPosition, token0, token1) {
      let totalUsd = 0;
      let totalProfit = 0;
      allPosition.forEach((p) => {
        totalUsd += (p.token0Liquidity + p.token0Fee) * this.getPrice(token0);
        totalUsd += (p.token1Liquidity + p.token1Fee) * this.getPrice(token1);
        totalProfit += (p.token0Fee) * this.getPrice(token0);
        totalProfit += (p.token1Fee) * this.getPrice(token1);
      })
      return totalUsd.toFixed(2) + ' / ' + totalProfit.toFixed(4);
    },
    getPrice: function (coin) {
      if (coin === 'USDT' || coin === 'BUSD' || coin === 'USDC' || coin === 'DAI' || coin === 'TUSD') {
        return 1;
      }
      return SymbolInfoService.getSymbolPrice(GeneralApiService.PLATFORM.BINANCE, GeneralApiService.SYMBOL_TYPE.SPOT, coin + 'USDT');
    },
    render: function (accountInstance) {
      let that = this;
      that.rendering = true;
      that.accountInstance = accountInstance;
      that.instance = JSON.parse(JSON.stringify(that.dto.strategyConfig));
      that.instance.user.chainId = 1;
      StrategyInstanceService.query(that.accountInstance.instanceId, that.dto.strategyName, function (data) {
        that.instanceList = data.strategyInsList;
        that.positionInfoList = [];
        that.instanceList.forEach((ins) => {
          that.positionInfoList.push(that.getInsAllPositionInfo(ins));
        })
        that.collapseChange(that.activeNames);
        that.rendering = false;
      }, function () {
        that.rendering = false;
      });
    },
    collapseChange: function (activePanels) {
      for (let instanceListKey in this.instanceList) {
        let ins = this.instanceList[instanceListKey];
        if (activePanels.indexOf(ins.sid) > -1) {
          this.renderContractProfit(ins);
        }
      }
    },
    renderContractProfit: function (ins) {
      let that = this;
      that.$nextTick(function () {
        that.$refs['profit_' + ins.sid][0].render(ins.strategyConfig.usdBalanceTimeList, ins.strategyConfig.usdBalanceList);
        that.$forceUpdate();
      });
    },
    withdrawAllPayToken: function (ins) {
      let that = this;
      ConfirmProvider.openConfirm(this, '确认提现', '请确定把合约中当前空闲的所有 Pay Token 提至操作钱包，请确认钱包中 Gas 足够。', function () {
        that.withdrawing = true;
        UniPlusService.withdrawTokenToWallet(ins.sid + '', ins.strategyConfig.payTokenAddress, function (result) {
          that.withdrawing = false;
          if (result.toUpperCase() === 'SUCCESS') {
            Message.success('已成功提现');
            that.render(that.accountInstance);
          } else {
            Message.success('提现失败');
          }
        }, function () {
          Message.success('提现失败');
          that.withdrawing = false;
        });
      });
    },
    add: function (ins) {
      let that = this;
      if (!this.isTokenFirstIsToken0(ins.token0Address, ins.token1Address)) {
        Message.error('请调换 Token0 和 Token1 的顺序');
        return;
      }
      that.processing = true;
      StrategyInstanceService.create({
        insId: this.accountInstance.instanceId,
        strategyName: this.dto.strategyName,
        config: JSON.stringify(ins)
      }, function () {
        Message.success('已成功创建新的实例');
        that.processing = false;
        setTimeout(() => that.render(that.accountInstance), 888);
      }, function () {
        that.processing = false;
      });
    },
    reset: function (ins) {
      let that = this;
      ConfirmProvider.openConfirm(this, '确认重置', '该操作将会重置所有非配置的数据', function () {
        that.processing = true;
        StrategyInstanceService.reset(ins.sid, function () {
          that.processing = false;
          Message.success('已成功重置');
          that.render(that.accountInstance);
        }, function () {
          that.processing = false;
        });
      });
    },
    deleteIns: function (ins) {
      let that = this;
      ConfirmProvider.openConfirm(this, '确认删除', '该操作将会删除该实例，此操作无法恢复', function () {
        that.processing = true;
        StrategyInstanceService.deleteIns(ins.sid, function () {
          that.processing = false;
          Message.success('已成功删除');
          that.render(that.accountInstance);
        }, function () {
          that.processing = false;
        });
      });
    },
    save: function (ins) {
      let that = this;
      that.processing = true;
      StrategyInstanceService.update({
        sid: ins.sid,
        strategyName: ins.strategyName,
        config: (ins.strategyConfig),
        instanceEntity: {
          id: that.accountInstance.instanceId
        },
        isActive: ins.isActive
      }, function () {
        that.processing = false;
        Message.success('已成功更新');
        that.render(that.accountInstance);
      }, function () {
        that.processing = false;
      });
    },
    isTokenFirstIsToken0: function (tokenFirst, tokenSecond) {
      return tokenFirst.toLowerCase() < tokenSecond.toLowerCase();
    },
    getChainNameById: function (id) {
      for (let i = 0; i < Object.keys(this.chainIds).length; i++) {
        let chainObj = this.chainIds[i];
        if (chainObj.value === id) {
          return chainObj.name;
        }
      }
    }
  }
}
</script>

<style scoped>

.ellipsis-line {
  padding: 8px;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-collapse {
  color: #FFFFFF;
  border-top: 1px solid #444c56;
  border-bottom: 1px solid #444c56;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: white;
}

.el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-divider {
  border-top: 1px solid #444c56;
}

.el-divider.info {
  width: 80%;
  border-top: 1px solid #444c56;
}

.el-divider__text {
  color: white;
  background-color: #444c56;
}

.select-symbol .el-input__inner {
  color: white;
  height: 35px !important;
  line-height: 35px;
  width: 100%;
  padding: 0 10px 0 10px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-right: 0;
  border-radius: 0;
  font-size: 12px;
  outline: none;
}

.select-symbol.el-select .el-input.is-focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:hover .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:hover .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:visited .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:visited .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

</style>