<script>

import GeneralApiService from "@/api/trade/GeneralApiService";

function binanceSpotNameResolver(coin) {
  return coin.toUpperCase() + 'BUSD';
}

function binanceUsdNameResolver(coin) {
  return coin.toUpperCase() + 'USDT';
}

function binanceCoinNameResolver(coin) {
  return coin.toUpperCase() + 'USD_PERP';
}

function ftxUsdNameResolver(coin) {
  return coin.toUpperCase() + '-PERP';
}

function okxSpotNameResolver(coin) {
  return coin.toUpperCase() + '-USDT';
}

function okxUsdNameResolver(coin) {
  return coin.toUpperCase() + '-USDT-SWAP';
}

function okxCoinNameResolver(coin) {
  return coin.toUpperCase() + '-USD-SWAP';
}

function deribitNameResolver(coin) {
  return coin.toUpperCase() + '-PERPETUAL';
}

function bybitNameResolver(coin) {
  return coin.toUpperCase() + 'USDT';
}

function kuCoinUsdNameResolver(coin) {
  return coin.toUpperCase() + 'USDTM';
}

function kuCoinCoinNameResolver(coin) {
  return coin.toUpperCase() + 'USDM';
}

function mexcUsdNameResolver(coin) {
  return coin.toUpperCase() + '_USDT';
}

function mexcCoinNameResolver(coin) {
  return coin.toUpperCase() + '_USD';
}

function nameResolver(platform, symbolType, coin) {
  if (platform === GeneralApiService.PLATFORM.BINANCE) {
    if (symbolType === GeneralApiService.SYMBOL_TYPE.USD_CONTRACT) {
      return binanceUsdNameResolver(coin);
    } else if (symbolType === GeneralApiService.SYMBOL_TYPE.COIN_CONTRACT) {
      return binanceCoinNameResolver(coin);
    } else if (symbolType === GeneralApiService.SYMBOL_TYPE.SPOT) {
      return binanceSpotNameResolver(coin);
    }
    return binanceSpotNameResolver(coin);
  } else if (platform === GeneralApiService.PLATFORM.OKX) {
    if (symbolType === GeneralApiService.SYMBOL_TYPE.USD_CONTRACT) {
      return okxUsdNameResolver(coin);
    } else if (symbolType === GeneralApiService.SYMBOL_TYPE.COIN_CONTRACT) {
      return okxCoinNameResolver(coin);
    } else if (symbolType === GeneralApiService.SYMBOL_TYPE.SPOT) {
      return okxSpotNameResolver(coin);
    }
    return okxSpotNameResolver(coin);
  } else if (platform === GeneralApiService.PLATFORM.FTX) {
    return ftxUsdNameResolver(coin);
  } else if (platform === GeneralApiService.PLATFORM.DERIBIT) {
    return deribitNameResolver(coin);
  }  else if (platform === GeneralApiService.PLATFORM.BYBIT) {
    if (symbolType === GeneralApiService.SYMBOL_TYPE.USD_CONTRACT) {
      return bybitNameResolver(coin);
    }
    return bybitNameResolver(coin);
  } else if (platform === GeneralApiService.PLATFORM.KUCOIN) {
    if (symbolType === GeneralApiService.SYMBOL_TYPE.USD_CONTRACT) {
      return kuCoinUsdNameResolver(coin);
    } else if (symbolType === GeneralApiService.SYMBOL_TYPE.COIN_CONTRACT) {
      return kuCoinCoinNameResolver(coin);
    }
    return kuCoinUsdNameResolver(coin);
  } else if (platform === GeneralApiService.PLATFORM.MEXC) {
    if (symbolType === GeneralApiService.SYMBOL_TYPE.USD_CONTRACT) {
      return mexcUsdNameResolver(coin);
    } else if (symbolType === GeneralApiService.SYMBOL_TYPE.COIN_CONTRACT) {
      return mexcCoinNameResolver(coin);
    }
    return mexcUsdNameResolver(coin);
  }
}

export default {
  nameResolver
}
</script>