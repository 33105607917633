import { defineStore } from 'pinia'
import { piniaStore } from '@/store/index'
import moment from "moment"

import WalletManagement from "@/api/wallet/WalletManagement"
import UserService from "@/api/user/UserService";

// function TaskChain(){
//     var self = this;
//     self.index = 0;
//     self.chain = [];
//     self.reset = function(){
//         self.index = 0;
//     };
//     self.clear = function(){
//         self.chain = [];
//         self.index = 0;
//     };
//     self.method = function(fn){
//         self.chain.push(fn);
//     };
//     self.start = function(){
//         if(self.chain.length == 0){
//             return;
//         }
//         var next = function(data){
//             var fn = self.chain[++self.index];
//             if(fn){
//                 fn(next, data);
//             }
//         };
//         self.chain[0](next);
//     };
//     return self;
// }

function uuid(len, radix) {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    var uuid = [],
        i;
    radix = radix || chars.length;
    if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
        // rfc4122, version 4 form
        var r;
        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';
        // Fill in random data.  At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | Math.random() * 16;
                uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
            }
        }
    }
    return uuid.join('');
}

function CreateID(prefix){
    if(!prefix) prefix = 'ID';
    return prefix + '_' + uuid(8, 16) + '_' + Math.round( Math.random() * 100 )
}

export function AsyncQueue() {
    var self = this;
    self.queue = [];
    self.countdown = 0;
    self._finish = function() {};
    self.reset = function(){
        self.countdown = 0;
    };
    self.action = function(fn){
        self.queue.push(fn);
    };
    self.finish = function(fn){
        self._finish = fn;
    };
    self.start = function(){
        if(self.queue.length == 0){
            return;
        }
        var finish = function(){
            if (++ self.countdown == self.queue.length) {
                self._finish();
            }
        };
        for (var i in self.queue) {
            self.queue[i](finish);
        }
    };
}

export const useWalletStore = defineStore(
    'wallet',
    {
        // arrow function recommended for full type inference
        state: () => {
            return {
                // 支持的交易所列表 BINANCE, FTX, DERIBIT, OKX
                exchangeList: [
                    { text: 'BINANCE', value: 'BINANCE' },
                    { text: 'FTX', value: 'FTX' },
                    { text: 'DERIBIT', value: 'DERIBIT' },
                    { text: 'OKX', value: 'OKX' },
                ],
                // 账号列表
                accountList: [],
                // 常用钱包列表
                commonUsedWalletList: [],
                // 钱包列表
                walletList: [],
                // 目标链与代币列表对应表(DEX), 链 -> 币种
                mapDexChainToken: {},
                // 目标链与代币列表对应表(CEX), 交易所 -> 币种 -> 链
                mapCexChainToken: {},
                // // 目标链列表
                // targetChainList: [],
                // // 代币列表
                // coinTypeList: [],
                // 等待审批列表
                paddingTransfers: [],
                // 我参与的列表
                createdByRequester: [],
                // 我创建的列表
                approvedByRequester: [],
                // 转账记录列表
                finishedTransfers: [],
                finishedTransfersIndex: 0,
                finishedTransfersSize: 15,
                finishedTransfersTotal: 0,
                finishedTransfersShow: [],
                // ===== 常用钱包列表展示类型
                filterCommonUsedWalletType: '',
                // ===== 当前选择的目标链类型
                filterTargetChain: '',
                // ===== ClearFormAction
                actionClearForm: 1
            }
        },
        // 相当于 vue 中的 computed 计算属性
        getters: {
            // sourceWalletList: state => {
            //     return state.walletList.map(item => {
            //         return {
            //             value: item.walletType == 'CEX' ? item.apiKey : item.address,
            //             text: item.walletType == 'CEX' ? item.apiKey : item.address,
            //             walletType: item.walletType,
            //             origin: item
            //         }
            //     })
            // },
            // sourceWalletMap: state => {
            //     let map = {}
            //     state.walletList.forEach(item => {
            //         let key = item.walletType == 'CEX' ? item.apiKey : item.address
            //         map[key] = item
            //     })
            //     return map
            // },
            sourceWalletList: state => {
                let map = {}
                let walletList2 = []
                let walletList = state.createdByRequester.concat(state.approvedByRequester)
                // let walletList = state.createdByRequester
                walletList.forEach(item => {
                    let key = item.walletType == 'CEX' ? item.apiKey : item.address
                    if (!map[key]) {
                        map[key] = item
                        walletList2.push(item)
                    }
                })
                return walletList2.map(item => {
                    return {
                        value: item.walletType == 'CEX' ? item.apiKey : item.address,
                        text: item.walletType == 'CEX' ? item.apiKey : item.address,
                        walletType: item.walletType,
                        origin: item
                    }
                })
            },
            sourceWalletMap: state => {
                let map = {}
                let walletList = state.createdByRequester.concat(state.approvedByRequester)
                // let walletList = state.createdByRequester
                walletList.forEach(item => {
                    let key = item.walletType == 'CEX' ? item.apiKey : item.address
                    map[key] = item
                })
                return map
            },
            dexTargetChainList: state => {
                return state.mapDexChainToken
            },
            cexTargetChainList: state => {
                return state.mapCexChainToken
            },
            dexCoinTypeList: state => {
                return state.mapDexChainToken
            },
            cexCoinTypeList: state => {
                return state.mapCexChainToken
            }
        },
        // 相当于 vue 中的 methods 方法
        actions: {
            resetFormData() {
                this.$state.actionClearForm = Date.now()
            },
            setFilterCommonUsedWalletType(walletType) {
                console.log('=== 当前目钱包类型', walletType)
                this.$state.filterCommonUsedWalletType = walletType
            },
            setFilterTargetChain(targetChain) {
                console.log('=== 当前目标链类型', targetChain)
                this.$state.filterTargetChain = targetChain
            },
            getUserList() {
                WalletManagement.getUserList().then((data) => {
                    console.log('===账号列表', data)
                    this.accountList = data.elements.map(m => {
                        return {
                            value: m.username,
                            text: m.alias,
                            origin: m
                        }
                    })
                }, (err) => {
                    console.log({err})
                })
            },
            listAllChainTokenMappings() {
                WalletManagement.listAllDexChainTokenMappings().then((data) => {
                    console.log('===币种列表、目标链列表', data)
                    // 目标链与代币列表对应表(DEX), 链 -> 币种
                    this.mapDexChainToken = data
                    // 目标链与代币列表对应表(CEX), 交易所 -> 币种 -> 链
                    this.mapCexChainToken = {
                        BINANCE: {
                            USDT: ['BSC','ETH','POLYGON','TRON'],
                            USDC: ['BSC','ETH','POLYGON','TRON'],
                            BUSD: ['BSC','ETH','POLYGON'],
                            BTC: ['BTC'],
                            ETH: ['BSC','ETH','ARB','OPTIMISM'],
                            BNB: ['BSC'],
                            MATIC: ['BSC','ETH','POLYGON']
                        },
                        FTX: {},
                        DERIBIT: {},
                        OKX: {
                            USDT: ['BSC','ETH','POLYGON','TRON','ARB','OPTIMISM'],
                            USDC: ['BSC','ETH','POLYGON','TRON','ARB','OPTIMISM'],
                            BTC: ['BTC'],
                            ETH: ['ETH','ARB','OPTIMISM','ZKSYNC'],
                            BNB: ['BSC'],
                            MATIC: ['ETH','POLYGON']
                        },
                    }
                }, (err) => {
                    console.log({err})
                })
            },
            listCommonUsedWallet() {
                return new Promise((resolve) => {
                    WalletManagement.listCommonUsedWallet().then((walletList) => {
                        console.log('===常用钱包列表', walletList)
                        this.commonUsedWalletList = walletList.map(item => {
                            item.cid = item.id
                            item.id = item.walletType == 'CEX' ? item.apiKey : item.address
                            return item
                        })
                        resolve()
                    }, (err) => {
                        console.log({err})
                        resolve()
                    })
                })
            },
            listWallet() {
                WalletManagement.listWallet().then((walletList) => {
                    console.log('===钱包列表', walletList)
                    this.walletList = walletList
                }, (err) => {
                    console.log({ err })
                })
            },
            listTargetChain() {
                WalletManagement.listTargetChain().then((targetChainList) => {
                    console.log('===目标链列表', targetChainList)
                    this.targetChainList = targetChainList
                }, (err) => {
                    console.log({err})
                })
            },
            listCoinType() {
                WalletManagement.listCoinType().then((coinTypeList) => {
                    console.log('===代币列表', coinTypeList)
                    this.coinTypeList = coinTypeList
                }, (err) => {
                    console.log({err})
                })
            },
            // 转出
            createTransfer(source, targetList, notes) {
                return new Promise((resolve) => {
                    let transfers = []
                    targetList.addressList.forEach(t => {
                        transfers.push({
                            transactionHash: "",

                            fromWalletType: source.sourceWalletType.toUpperCase(),
                            fromWallet: source.sourceWallet,
                            fromDexChain: source.targetChain,
                            token: source.coinType,
    
                            toWalletType: t.walletType ? t.walletType.toUpperCase() : 'DEX',
                            toWallet: t.display,
                            toDexChain: source.targetChain,
                            tokenAmount: parseFloat(t.count),

                            notes: notes,

                            userName: UserService.getUserName()
                        })
                    })
                    console.log('转账列表', transfers)
                    let t = new AsyncQueue()
                    transfers.forEach(() => {
                        t.action(finish => {
                            let params = transfers.pop()
                            console.log('发起转账', JSON.stringify(params))
                            WalletManagement.createTransfer(params).then(() => {
                                finish()
                            }, (err) => {
                                console.log({err})
                                finish()
                            })
                        })
                    })
                    t.finish(() => {
                        console.log('发起转账完成')
                        resolve()
                    })
                    t.start()
                })
            },
            // 归集
            createTransferAssemble(sourceList, target, notes) {
                return new Promise((resolve) => {
                    let transfers = []
                    sourceList.forEach(source => {
                        transfers.push({
                            transactionHash: "",

                            fromWalletType: source.sourceWalletType.toUpperCase(),
                            fromWallet: source.sourceWallet,
                            fromDexChain: source.targetChain,
                            token: source.coinType,
    
                            toWalletType: target.walletType ? target.walletType.toUpperCase() : 'DEX',
                            toWallet: target.display,
                            toDexChain: source.targetChain,
                            tokenAmount: source.count,

                            notes: notes,

                            userName: UserService.getUserName()
                        })
                    })
                    console.log('转账列表', transfers)
                    let t = new AsyncQueue()
                    transfers.forEach(() => {
                        t.action(finish => {
                            let params = transfers.pop()
                            console.log('发起转账', JSON.stringify(params))
                            WalletManagement.createTransfer(params).then(() => {
                                finish()
                            }, (err) => {
                                console.log({err})
                                finish()
                            })
                        })
                    })
                    t.finish(() => {
                        console.log('发起转账完成')
                        resolve()
                    })
                    t.start()
                })
            },
            // 等待审批列表
            listPendingTransfers() {
                return new Promise((resolve, reject) => {
                    WalletManagement.listICanApproveTransfers().then((paddingTransfers) => {
                        console.log('===等待审批列表', paddingTransfers)
                        paddingTransfers.forEach(item => {
                            item._index = CreateID()
                            item.createTimeDate = moment(item.createTime * 1000).add(8, 'hours').format('YYYY/MM/DD HH:mm:ss')
                        })
                        this.paddingTransfers = paddingTransfers
                        resolve()
                    }, (err) => {
                        console.log({err})
                        reject()
                    })
                })
            },
            // 我创建的列表
            listWalletCreatedByRequester() {
                return new Promise((resolve, reject) => {
                    WalletManagement.listWalletCreatedByRequester().then((createdByRequester) => {
                        console.log('===我创建的列表', createdByRequester)
                        this.createdByRequester = createdByRequester
                        resolve()
                    }, (err) => {
                        console.log({err})
                        reject()
                    })
                })
            },
            // 我参与的列表
            listWalletApprovedByRequester() {
                return new Promise((resolve, reject) => {
                    WalletManagement.listWalletApprovedByRequester().then((approvedByRequester) => {
                        console.log('===我参与的列表', approvedByRequester)
                        this.approvedByRequester = approvedByRequester
                        resolve()
                    }, (err) => {
                        console.log({err})
                        reject()
                    })
                })
            },
            // 转账记录列表
            listMyFinishedTransfers() {
                return new Promise((resolve, reject) => {
                    WalletManagement.listMyFinishedTransfers(0, 99999999).then((finishedTransfers) => {
                        finishedTransfers.forEach(item => {
                            item._index = CreateID()
                            item.createTimeDate = moment(item.createTime * 1000).add(8, 'hours').format('YYYY/MM/DD HH:mm:ss')
                        })
                        console.log('===转账记录列表', finishedTransfers)
                        this.finishedTransfers = finishedTransfers
                        this.finishedTransfersTotal = finishedTransfers.length
                        this.finishedTransfersIndex = 0
                        let start = this.finishedTransfersIndex * this.finishedTransfersSize
                        let end = start + this.finishedTransfersSize
                        let list = this.finishedTransfers.slice(start, end)
                        this.finishedTransfersShow = list
                        resolve()
                    }, (err) => {
                        console.log({err})
                        reject()
                    })
                })
            },
            listMyFinishedTransfersTargetPage(index) {
                this.finishedTransfersIndex = index
                let start = this.finishedTransfersIndex * this.finishedTransfersSize
                let end = start + this.finishedTransfersSize
                let list = this.finishedTransfers.slice(start, end)
                this.finishedTransfersShow = list
            },
            // 审批通过
            approveTransfer(paddingList) {
                let that = this
                return new Promise((resolve) => {
                    // 回收审批
                    that.revokeApprovalTransfer(paddingList, false).then(() => {
                        // 提交审批
                        let transfers = []
                        paddingList.forEach(t => {
                            transfers.push({
                                transferId: t.id,
                                approver: UserService.getUserName(),
                                action: 'APPROVE'
                            })
                        })
                        console.log('审批列表', transfers)
                        let t = new AsyncQueue()
                        transfers.forEach(() => {
                            t.action(finish => {
                                let params = transfers.pop()
                                console.log('审批', JSON.stringify(params))
                                WalletManagement.approveTransfer(params).then(() => {
                                    finish()
                                }, (err) => {
                                    console.log({err})
                                    finish()
                                })
                            })
                        })
                        t.finish(() => {
                            console.log('审批完成')
                            that.listPendingTransfers().then(() => {
                                that.listMyFinishedTransfers().then(() => {
                                    resolve()
                                }, () => {
                                    resolve()
                                })
                            }, () => {
                                that.listMyFinishedTransfers().then(() => {
                                    resolve()
                                }, () => {
                                    resolve()
                                })
                            })
                        })
                        t.start()
                    })
                })
            },
            // 审批拒绝
            approveTransferDecline(paddingList) {
                let that = this
                return new Promise((resolve) => {
                    // 回收审批
                    that.revokeApprovalTransfer(paddingList, false).then(() => {
                        // 提交审批
                        let transfers = []
                        paddingList.forEach(t => {
                            transfers.push({
                                transferId: t.id,
                                approver: UserService.getUserName(),
                                action: 'DECLINE'
                            })
                        })
                        console.log('审批列表', transfers)
                        let t = new AsyncQueue()
                        transfers.forEach(() => {
                            t.action(finish => {
                                let params = transfers.pop()
                                console.log('审批', JSON.stringify(params))
                                WalletManagement.approveTransfer(params).then(() => {
                                    finish()
                                }, (err) => {
                                    console.log({err})
                                    finish()
                                })
                            })
                        })
                        t.finish(() => {
                            console.log('审批完成')
                            that.listPendingTransfers().then(() => {
                                that.listMyFinishedTransfers().then(() => {
                                    resolve()
                                }, () => {
                                    resolve()
                                })
                            }, () => {
                                that.listMyFinishedTransfers().then(() => {
                                    resolve()
                                }, () => {
                                    resolve()
                                })
                            })
                        })
                        t.start()
                    })
                })
            },
            // 收回审批
            revokeApprovalTransfer(paddingList, refresh) {
                let that = this
                return new Promise((resolve) => {
                    let transfers = []
                    paddingList.forEach(t => {
                        if (t.transferApprovalRes) {
                            transfers.push({
                                approvalId: t.transferApprovalRes.id,
                                requester: UserService.getUserName(),
                            })
                        }
                    })
                    if (transfers.length) {
                        console.log('收回审批列表', transfers)
                        let t = new AsyncQueue()
                        transfers.forEach(() => {
                            t.action(finish => {
                                let params = transfers.pop()
                                console.log('收回审批', JSON.stringify(params))
                                WalletManagement.revokeApprovalTransfer(params).then(() => {
                                    finish()
                                }, (err) => {
                                    console.log({err})
                                    finish()
                                })
                            })
                        })
                        t.finish(() => {
                            console.log('收回审批完成')
                            if (refresh) {
                                that.listPendingTransfers().then(() => {
                                    resolve()
                                }, () => {
                                    resolve()
                                })
                            } else {
                                resolve()
                            }
                        })
                        t.start()
                    } else {
                        resolve()
                    }
                })
            },
            // 转账参数转换
            convertTransferParam(sourceData, targetData, notes) {
                let transfers = []
                if (sourceData instanceof Array) {
                    // 归集
                    sourceData.forEach(source => {
                        transfers.push({
                            transactionHash: "",

                            fromWalletId: source.sourceWalletId,

                            fromWalletType: source.sourceWalletType.toUpperCase(),
                            fromWallet: source.sourceWallet,
                            fromDexChain: source.targetChain,
                            token: source.coinType,
    
                            toWalletType: targetData.walletType ? targetData.walletType.toUpperCase() : 'DEX',
                            toWallet: targetData.display,
                            toDexChain: source.targetChain,
                            tokenAmount: source.count,

                            notes: notes,

                            userName: UserService.getUserName()
                        })
                    })
                } else {
                    // 转出
                    targetData.addressList.forEach(t => {
                        transfers.push({
                            transactionHash: "",

                            fromWalletId: sourceData.sourceWalletId,

                            fromWalletType: sourceData.sourceWalletType.toUpperCase(),
                            fromWallet: sourceData.sourceWallet,
                            fromDexChain: sourceData.targetChain,
                            token: sourceData.coinType,
    
                            toWalletType: t.walletType ? t.walletType.toUpperCase() : 'DEX',
                            toWallet: t.display,
                            toDexChain: sourceData.targetChain,
                            tokenAmount: parseFloat(t.count),

                            notes: notes,

                            userName: UserService.getUserName()
                        })
                    })
                }
                return transfers
            },
            // 检查转账金额是否符合要求
            checkTransferAmount(sourceData, targetData, notes) {
                var that = this
                return new Promise((resolve) => {
                    let transfers = this.convertTransferParam(sourceData, targetData, notes).filter(t => {
                        return t.fromWalletType == 'CEX'
                    })
                    console.log('检查列表', transfers)
                    if (transfers.length == 0) {
                        console.log('检查列表为空')
                        resolve([])
                        return
                    }
                    // 根据交易所类型获取每个交易所的第一个钱包ID, 每个交易所调用一次接口
                    
                    var platformMap  = {}
                    var platformList = []

                    transfers.forEach(t => {
                        var cacheWallet = that.sourceWalletMap[t.fromWallet]
                        if (!platformMap[cacheWallet.platformName]) {
                            var item = {platform: cacheWallet.platformName, transfers: [t]}
                            platformList.push(item)
                            platformMap[cacheWallet.platformName] = item
                        } else {
                            platformMap[cacheWallet.platformName].transfers.push(t)
                        }
                    })
                    console.log('按交易所类型分类结果', platformList)

                    let errors = []
                    let t = new AsyncQueue()
                    platformList.forEach(() => {
                        t.action(finish => {
                            let platform = platformList.pop()
                            var transfer = platform.transfers[0]
                            // console.log('发起转账', JSON.stringify(transfer))
                            WalletManagement.getCexTransferConstraint(transfer.fromWalletId).then(res => {
                                console.log('检查指标', res)
                                platform.transfers.forEach(params => {
                                    var errorMessage = ''
                                    var tokenWithdraw = null
                                    res.forEach(w => {
                                        if (w.dexChain == params.fromDexChain && w.token == params.token) {
                                            tokenWithdraw = w
                                            return false
                                        }
                                    })
                                    console.log('检查目标', tokenWithdraw)
                                    // if (res.withdrawEnable) {
                                        if (params.tokenAmount < parseFloat(tokenWithdraw.withdrawMin)) {
                                            errorMessage = '提现最小金额' + tokenWithdraw.withdrawMin
                                        }
                                        else if (params.tokenAmount > parseFloat(tokenWithdraw.withdrawMax)) {
                                            errorMessage = '提现最大金额' + tokenWithdraw.withdrawMax
                                        }
                                        // 通过
                                    // } else {
                                    //     errorMessage = '不允许提现'
                                    // }
                                    if (errorMessage) {
                                        errors.push({
                                            fromWallet: params.fromWallet,
                                            toWallet: params.toWallet,
                                            errorMessage: errorMessage,
                                            dexChain: tokenWithdraw.dexChain, // 提现链名
                                            token: tokenWithdraw.token, // 提现币种
                                            withdrawMin: tokenWithdraw.withdrawMin, // 提现最小金额
                                            withdrawMax: tokenWithdraw.withdrawMax, // 提现最大金额
                                            withdrawEnable: tokenWithdraw.withdrawEnable, // 是否允许体现
                                        })
                                    }
                                })
                                finish()
                            }, (err) => {
                                console.log({err})
                                // errors.push({
                                //     fromWallet: params.fromWallet,
                                //     toWallet: params.toWallet,
                                //     errorMessage: '提现最小金额22',
                                //     // dexChain: res.dexChain, // 提现链名
                                //     // token: res.token, // 提现币种
                                //     // withdrawMin: res.withdrawMin, // 提现最小金额
                                //     // withdrawMax: res.withdrawMax, // 提现最大金额
                                //     // withdrawEnable: res.withdrawEnable, // 是否允许体现
                                // })
                                finish()
                            })
                        })
                    })
                    t.finish(() => {
                        console.log('发起转账检查完成', errors)
                        resolve(errors)
                    })
                    t.start()
                })
            },
        }
    }
)

export function useWalletOutsideStore() {
    return useWalletStore(piniaStore)
}
