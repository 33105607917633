import { render, staticRenderFns } from "./HedgeOpenOkx.vue?vue&type=template&id=bd44010a&scoped=true&"
import script from "./HedgeOpenOkx.vue?vue&type=script&lang=js&"
export * from "./HedgeOpenOkx.vue?vue&type=script&lang=js&"
import style0 from "./HedgeOpenOkx.vue?vue&type=style&index=0&id=bd44010a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd44010a",
  null
  
)

export default component.exports