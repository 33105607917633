<script>

import Web3Service from "@/web3/Web3Service";
import GmxAbi from "@/web3/abis/gmx/GmxAbi";

let glpRewardTrackerAbiContractAddress = '0x4e971a87900b931fF39d1Aad67697F49835400b6';
let glpManagerContractAddress = '0x3963FfC9dff443c2A94f21b129D429891E32ec18';
let rewardReaderContractAddress = '0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0';

function getGlpRewardTrackerContract(that) {
  let web3 = Web3Service.getWeb3(that);
  return new web3.eth.Contract(GmxAbi.glpRewardTrackerABI, glpRewardTrackerAbiContractAddress);
}

function getGlpManagerContract(that) {
  let web3 = Web3Service.getWeb3(that);
  return new web3.eth.Contract(GmxAbi.glpManagerABI, glpManagerContractAddress);
}

function getRewardReaderContract(that) {
  let web3 = Web3Service.getWeb3(that);
  return new web3.eth.Contract(GmxAbi.rewardReaderABI, rewardReaderContractAddress);
}

export default {
  glpRewardTrackerAbiContractAddress,
  glpManagerContractAddress,
  rewardReaderContractAddress,
  getGlpRewardTrackerContract,
  getGlpManagerContract,
  getRewardReaderContract
}
</script>