<script type="text/javascript">

import ServiceMapper from "@/const/ServiceMapper";
import AbmNet from "@/api/net/AbmNet";

function getKLine(platform, symbol, size, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.dataServiceKLine + '?platform=' + platform + '&symbol=' + symbol + '&size=' + size, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getAhr999(success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.dataServiceAhr999, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getFunding(success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.dataServiceFunding, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getLiquidation(success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.dataServiceLiquidation, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getOptionDelivery(symbol, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.dataServiceOptionDelivery + '?symbol=' + symbol, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getOptionStrike(symbol, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.dataServiceOptionStrike + '?symbol=' + symbol, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getFutureProfit(symbol, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.dataServiceFutureProfit + '?symbol=' + symbol, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

export default {
  getKLine,
  getAhr999,
  getFunding,
  getLiquidation,
  getOptionDelivery,
  getOptionStrike,
  getFutureProfit
}

</script>