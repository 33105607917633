<template>
  <div style="width: 100%;height: 560px">
    <el-row>
      <el-col :span="12">
        <div id="id-deliveryChart" ref="deliveryChart" style="height: 280px;z-index: 1"></div>
      </el-col>
      <el-col :span="12">
        <div id="id-strikeChart" ref="strikeChart" style="height: 280px;z-index: 1"></div>
      </el-col>
    </el-row>
    <el-row style="position: absolute;margin-top: -195px;width: 100%;z-index: 0">
      <el-col :span="24">
        <span class="dark-mode-font" style="color: #343947;font-size: 18px" v-if="!loadingBtc">Bitcoin</span>
      </el-col>
    </el-row>
    <el-row style="position: absolute;margin-top: -170px;width: 100%;z-index: 0">
      <el-col :span="24">
        <span class="dark-mode-font" style="color: #343947;font-size: 18px" v-if="!loadingBtc">Open Interest By Expiry</span>
      </el-col>
    </el-row>
    <el-row style="position: absolute;margin-top: -145px;width: 100%;z-index: 0">
      <el-col :span="24">
        <span class="dark-mode-font" style="color: #343947;font-size: 18px" v-if="!loadingBtc">Open Interest By Strike Price</span>
        <div class="trialpha-loading" v-if="loadingBtc" style="margin-left: calc(50% - 22px)">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <div id="id-deliveryChartEth" ref="deliveryChartEth" style="height: 280px;z-index: 1"></div>
      </el-col>
      <el-col :span="12">
        <div id="id-strikeChartEth" ref="strikeChartEth" style="height: 280px;z-index: 1"></div>
      </el-col>
    </el-row>
    <el-row style="position: absolute;margin-top: -195px;width: 100%;z-index: 0">
      <el-col :span="24">
        <span class="dark-mode-font" style="color: #343947;font-size: 18px" v-if="!loadingEth">Ethereum</span>
      </el-col>
    </el-row>
    <el-row style="position: absolute;margin-top: -170px;width: 100%;z-index: 0">
      <el-col :span="24">
        <span class="dark-mode-font" style="color: #343947;font-size: 18px" v-if="!loadingEth">Open Interest By Expiry</span>
      </el-col>
    </el-row>
    <el-row style="position: absolute;margin-top: -145px;width: 100%;z-index: 0">
      <el-col :span="24">
        <span class="dark-mode-font" style="color: #343947;font-size: 18px" v-if="!loadingEth">Open Interest By Strike Price</span>
        <div class="trialpha-loading" v-if="loadingEth" style="margin-left: calc(50% - 22px)">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import DataApiService from "@/api/trade/DataApiService";

export default {
  name: 'optionData',
  components: {},
  data: function () {
    return {
      color: {},
      loadingBtc: true,
      loadingEth: true,
      timeList: [],
      price: [],
      timeInterval: {}
    }
  },
  created: function () {
    this.loadingBtc = true;
    this.loadingEth = true;
    this.renderBtcDelivery();
    this.renderEthDelivery();
    this.timeInterval = setInterval(() => {
      this.renderBtcDelivery();
      this.renderEthDelivery();
    }, 28 * 1000);
  },
  destroyed: function () {
    clearInterval(this.timeInterval);
  },
  methods: {
    getDeliveryOption(xTimeList, bear, bull) {
      let series = [{
        showSymbol: false,
        name: 'PUT',
        type: 'bar',
        data: bear,
        smooth: true,
        lineStyle: {
          color: '#f6465d',
          width: 1,   //这里是坐标轴的宽度,可以去掉
        }
      },{
        showSymbol: false,
        name: 'CALL',
        type: 'bar',
        data: bull,
        smooth: true,
        lineStyle: {
          color: '#0ecb81',
          width: 1,   //这里是坐标轴的宽度,可以去掉
        }
      }];
      return {
        color: ['#f6465d', '#0ecb81'],
        legend: {
          data: ['PUT', 'CALL'],
          textStyle: {
            color: 'white',
            fontSize: 10
          }
        },
        calculable: true,
        tooltip: {
          show: true, // 是否显示
          trigger: 'axis', // 触发类型  'item'图形触发：散点图，饼图等无类目轴的图表中使用； 'axis'坐标轴触发；'none'：什么都不触发。
          axisPointer: { // 坐标轴指示器配置项。
            type: 'cross', // 'line' 直线指示器  'shadow' 阴影指示器  'none' 无指示器  'cross' 十字准星指示器。
            axis: 'auto', // 指示器的坐标轴。
            snap: true, // 坐标轴指示器是否自动吸附到点上
          }
        },
        xAxis: [{
          type: 'category',
          boundaryGap: true,
          show: true,
          data: xTimeList,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: 8, //更改坐标轴文字大小
              color: 'white'
            }
          },
        }],
        grid: {
          x: 40,
          y: 30,
          x2: 8,
          y2: 30
        },
        yAxis: [{
          type: 'value',
          position: 'left',
          show: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: 'white', //更改坐标轴文字颜色
              fontSize: 8 //更改坐标轴文字大小
            }
          },
        }],
        series: series
      };
    },
    getStrikeOption(xTimeList, bear, bull) {
      let series = [{
        showSymbol: false,
        name: 'PUT',
        type: 'bar',
        data: bear,
        smooth: true,
        lineStyle: {
          color: '#f6465d',
          width: 1,   //这里是坐标轴的宽度,可以去掉
        }
      },{
        showSymbol: false,
        name: 'CALL',
        type: 'bar',
        data: bull,
        smooth: true,
        lineStyle: {
          color: '#0ecb81',
          width: 1,   //这里是坐标轴的宽度,可以去掉
        }
      }];
      return {
        color: ['#f6465d', '#0ecb81'],
        legend: {
          data: ['PUT', 'CALL'],
          textStyle: {
            color: 'white',
            fontSize: 10
          }
        },
        calculable: true,
        tooltip: {
          show: true, // 是否显示
          trigger: 'axis', // 触发类型  'item'图形触发：散点图，饼图等无类目轴的图表中使用； 'axis'坐标轴触发；'none'：什么都不触发。
          axisPointer: { // 坐标轴指示器配置项。
            type: 'cross', // 'line' 直线指示器  'shadow' 阴影指示器  'none' 无指示器  'cross' 十字准星指示器。
            axis: 'auto', // 指示器的坐标轴。
            snap: true, // 坐标轴指示器是否自动吸附到点上
          }
        },
        xAxis: [{
          type: 'category',
          boundaryGap: true,
          show: true,
          data: xTimeList,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: 8, //更改坐标轴文字大小
              color: 'white'
            }
          },
        }],
        grid: {
          x: 8,
          y: 30,
          x2: 30,
          y2: 30
        },
        yAxis: [{
          type: 'value',
          position: 'right',
          show: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: 'white', //更改坐标轴文字颜色
              fontSize: 8 //更改坐标轴文字大小
            }
          },
        }],
        series: series
      };
    },
    renderBtcDelivery: function () {
      let that = this;
      let xTimeList = [];
      let bear = [];
      let bull = [];
      DataApiService.getOptionDelivery('BTC', function (data) {
        that.renderBtcStrike();
        xTimeList = data.data.data.keyList;
        bear = data.data.data.putOIList;
        bull = data.data.data.callOIList;
        if (!that.deliveryChart && that.$refs.deliveryChart) {
          that.deliveryChart = that.$echarts.init(that.$refs.deliveryChart);
          that.deliveryChart.clear();
          that.deliveryChart.setOption(that.getDeliveryOption(xTimeList, bear, bull));
          window.addEventListener('resize', () => (that.deliveryChart.resize()));
        }
      });
    },
    renderBtcStrike: function () {
      let that = this;
      let xTimeList = [];
      let bear = [];
      let bull = [];
      DataApiService.getOptionStrike('BTC', function (data) {
        xTimeList = data.data.data.keyList;
        bear = data.data.data.putOIList;
        bull = data.data.data.callOIList;
        if (!that.strikeChart && that.$refs.strikeChart) {
          that.strikeChart = that.$echarts.init(that.$refs.strikeChart);
          that.strikeChart.clear();
          that.strikeChart.setOption(that.getStrikeOption(xTimeList, bear, bull));
          window.addEventListener('resize', () => (that.strikeChart.resize()));
        }
        that.loadingBtc = false;
      });
    },
    renderEthDelivery: function () {
      let that = this;
      let xTimeList = [];
      let bear = [];
      let bull = [];
      DataApiService.getOptionDelivery('ETH', function (data) {
        that.renderEthStrike();
        xTimeList = data.data.data.keyList;
        bear = data.data.data.putOIList;
        bull = data.data.data.callOIList;
        if (!that.deliveryChartEth && that.$refs.deliveryChartEth) {
          that.deliveryChartEth = that.$echarts.init(that.$refs.deliveryChartEth);
          that.deliveryChartEth.clear();
          that.deliveryChartEth.setOption(that.getDeliveryOption(xTimeList, bear, bull));
          window.addEventListener('resize', () => (that.deliveryChartEth.resize()));
        }
      });
    },
    renderEthStrike: function () {
      let that = this;
      let xTimeList = [];
      let bear = [];
      let bull = [];
      DataApiService.getOptionStrike('ETH', function (data) {
        xTimeList = data.data.data.keyList;
        bear = data.data.data.putOIList;
        bull = data.data.data.callOIList;
        if (!that.strikeChartEth && that.$refs.strikeChartEth) {
          that.strikeChartEth = that.$echarts.init(that.$refs.strikeChartEth);
          that.strikeChartEth.clear();
          that.strikeChartEth.setOption(that.getStrikeOption(xTimeList, bear, bull));
          window.addEventListener('resize', () => (that.strikeChartEth.resize()));
        }
        that.loadingEth = false;
      });
    }
  }
}
</script>