<template>
  <div :dto="dto">
    <el-skeleton animated :loading="rendering" :throttle="268">
      <template slot="template">
        <trialpha-sk style="margin-top: 0;width: 60%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 40%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 100%;float: left"></trialpha-sk>
      </template>
      <template>
        <el-row>
          <el-collapse class="trialpha-collapse" v-model="activeNames" style="border: 0">
            <el-collapse-item class="trialpha-collapse" style="border: 0" :name="ins.strategyConfig.symbol1 + ins.strategyConfig.symbol2" v-for="ins in instanceList" :key="ins.strategyConfig.symbol1 + ins.strategyConfig.symbol2">
              <template slot="title">
                <el-row style="width: 100%">
                  <el-col :span="4" style="padding: 0">
                    <el-card class="black-card" body-style="padding: 0" style="line-height: 46px;margin-left: -10px;margin-top: 9px;border-left: 0;border-top: 0;border-bottom: 0;border-radius: 0;height: 46px;width: 50px;background-color: #444c56">
                      <span class="dark-mode-font" style="padding: 0;font-size: 12px;width: 100%;display: inline-block;text-align: center">{{ allPrice[ins.strategyConfig.symbol1] ? ((ins.strategyConfig.symbol1Short ? ((allPrice[ins.strategyConfig.symbol1] - allPrice[ins.strategyConfig.symbol2]) / allPrice[ins.strategyConfig.symbol2] * 100) : ((allPrice[ins.strategyConfig.symbol2] - allPrice[ins.strategyConfig.symbol1]) / allPrice[ins.strategyConfig.symbol1] * 100)).toFixed(2)) : 0 }}%</span>
                    </el-card>
                  </el-col>
                  <el-col :span="20">
                    <el-col :span="12" style="display: flex">
                      <i class="header-icon" v-bind:class="{'el-icon-caret-bottom': ins.strategyConfig.symbol1Short, ' el-icon-caret-top': !ins.strategyConfig.symbol1Short}"
                         :style="'float:left;margin-top:26px;font-weight: bolder;margin-left: 1px;' + (ins.strategyConfig.symbol1Short ? 'color: #f6465d' : 'color:#0ecb81') + ''"/>
                      <span class="dark-mode-font ellipsis-line" :style="'display: inline-block;float: left;margin-left: 0;' + (ins.isActive ? 'color: #FFFFFF' : '') + ''">{{ ins.strategyConfig.symbol1 }}</span>
                    </el-col>
                    <el-col :span="12" style="display: flex">
                      <i class="header-icon" v-bind:class="{'el-icon-caret-bottom': !ins.strategyConfig.symbol1Short, ' el-icon-caret-top': ins.strategyConfig.symbol1Short}"
                         :style="'float:left;margin-top:26px;font-weight: bolder;margin-left: 10px;' + (ins.strategyConfig.symbol1Short ? 'color: #0ecb81' : 'color:#f6465d') + ''"/>
                      <span class="dark-mode-font ellipsis-line" :style="'display: inline-block;float: left;margin-left: 0;' + (ins.isActive ? 'color: #FFFFFF' : '') + ''">{{ ins.strategyConfig.symbol2 }}</span>
                    </el-col>
                  </el-col>
                </el-row>
              </template>
              <el-row style="height: 346px;overflow-y: auto">
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">实例状态</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <el-select class="select-symbol" style="width: 100%" v-model="ins.isActive" placeholder="请选择">
                      <el-option
                          v-for="item in [{value: true, label: 'Active'}, {value: false, label: 'Inactive'}]"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="12" :offset="1">
                    <el-button class="dark-mode-btn dark-mode-font" style="width: 100%;border-radius: 3px;height: 35px;padding: 3px 0 3px 0;margin-top: 0;font-size: 12px" @click="openTradeTools(ins)">调仓工具</el-button>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">仓位检查条件</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11" style="display: flex">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.checkPositionSymbolList1"/>
                  </el-col>
                  <el-col :span="1" style="display: flex">
                    <el-button class="dark-mode-btn" disabled style="cursor: auto;width: 100%;border-radius: 0;height: 35px;padding: 0 0 3px 0;margin-top: 0;border: 0;background-color: rgba(0, 0, 0, 0);font-size: 20px">=</el-button>
                  </el-col>
                  <el-col :span="12" style="display: flex">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.checkPositionSymbolList2"/>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">实际检测余额</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11" style="display: flex">
                    <el-input class="trialpha-dark-input-no-pre" disabled v-model="ins.strategyConfig.checkPositionSymbolList1RealPosition"/>
                  </el-col>
                  <el-col :span="12" :offset="1" style="display: flex">
                    <el-input class="trialpha-dark-input-no-pre" disabled v-model="ins.strategyConfig.checkPositionSymbolList2RealPosition"/>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">Symbol 1 理论统计</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <el-input class="trialpha-dark-input trialpha-dark-input-para" placeholder="" v-model="ins.strategyConfig.symbol1EntryPrice" disabled>
                      <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">Price</span></template>
                    </el-input>
                  </el-col>
                  <el-col :span="12" :offset="1">
                    <el-input class="trialpha-dark-input trialpha-dark-input-para" placeholder="" v-model="ins.strategyConfig.symbol1DealtAmount" disabled>
                      <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">Amount</span></template>
                    </el-input>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">Symbol 2 理论统计</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <el-input class="trialpha-dark-input trialpha-dark-input-para" placeholder="" v-model="ins.strategyConfig.symbol2EntryPrice" disabled>
                      <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">Price</span></template>
                    </el-input>
                  </el-col>
                  <el-col :span="12" :offset="1">
                    <el-input class="trialpha-dark-input trialpha-dark-input-para" placeholder="" v-model="ins.strategyConfig.symbol2DealtAmount" disabled>
                      <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">Amount</span></template>
                    </el-input>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="18">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">理论收益统计</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <el-input class="trialpha-dark-input trialpha-dark-input-para" placeholder="" :value="(ins.strategyConfig.symbol1Short ? (((ins.strategyConfig.symbol1EntryPrice - ins.strategyConfig.symbol2EntryPrice) / ins.strategyConfig.symbol2EntryPrice) * 100).toFixed(2) : (((ins.strategyConfig.symbol2EntryPrice - ins.strategyConfig.symbol1EntryPrice) / ins.strategyConfig.symbol1EntryPrice) * 100).toFixed(2)) + '%'" disabled>
                      <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">Spread</span></template>
                    </el-input>
                  </el-col>
                  <el-col :span="12" :offset="1">
                    <el-input class="trialpha-dark-input trialpha-dark-input-para" placeholder="" :value="ins.strategyConfig.symbol1Short ? (ins.strategyConfig.symbol1EntryPrice * ins.strategyConfig.symbol1DealtAmount - ins.strategyConfig.symbol2EntryPrice * ins.strategyConfig.symbol2DealtAmount).toFixed(2) : (ins.strategyConfig.symbol2EntryPrice * ins.strategyConfig.symbol2DealtAmount - ins.strategyConfig.symbol1EntryPrice * ins.strategyConfig.symbol1DealtAmount).toFixed(2)" disabled>
                      <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">USD</span></template>
                    </el-input>
                  </el-col>
                </el-row>
                <div v-for="level in ins.strategyConfig.level" :key="level.index" >
                  <el-divider></el-divider>
                  <el-card class="black-card" style="width: 98%;margin-top: -10px;margin-left: auto;margin-right: auto;border: 0" shadow="none" body-style="padding: 0">
                    <el-row style="margin-top: 0">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">开仓限制</span>
                    </el-row>
                    <el-row style="margin-top: 5px">
                      <el-col :span="11">
                        <span class="dark-mode-font" style="color: white;display: inline-block;margin-top: 5px;margin-left: 10px">Level: {{ (level.index + 1) }} </span>
                      </el-col>
                      <el-col :span="12" :offset="1">
                        <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="level.gapMoreThan">
                          <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">间距</span></template>
                        </el-input>
                      </el-col>
                    </el-row>
                    <el-row style="margin-top: 5px">
                      <el-col :span="11">
                        <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="level.amount">
                          <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">仓位</span></template>
                        </el-input>
                      </el-col>
                      <el-col :span="12" :offset="1">
                        <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="level.randomPercent">
                          <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">扰动</span></template>
                        </el-input>
                      </el-col>
                    </el-row>
                    <el-row style="margin-top: 5px">
                      <el-col :span="11">
                        <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="level.maxTimes">
                          <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">次数</span></template>
                        </el-input>
                      </el-col>
                      <el-col :span="12" :offset="1">
                        <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="level.maxAmount">
                          <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">USD</span></template>
                        </el-input>
                      </el-col>
                    </el-row>
                    <el-row style="margin-top: 10px">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">执行情况</span>
                    </el-row>
                    <el-row style="margin-top: 5px">
                      <el-col :span="11">
                        <el-input class="trialpha-dark-input trialpha-dark-input-para" placeholder="" v-model="level.executeTimes" disabled>
                          <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">次数</span></template>
                        </el-input>
                      </el-col>
                      <el-col :span="12" :offset="1">
                        <el-input class="trialpha-dark-input trialpha-dark-input-para" placeholder="" v-model="level.executeAmount" disabled>
                          <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">USD</span></template>
                        </el-input>
                      </el-col>
                    </el-row>
                  </el-card>
                </div>
                <el-divider></el-divider>
                <el-row style="width: 98%;margin: 10px auto">
                  <el-col :span="6">
                    <el-button class="dark-mode-btn" style="float: left;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 3px 0 0 0;border-right: 0" @click="removeLevel(ins.strategyConfig)" :disabled="ins.strategyConfig.level.length <= 1 || rendering"><i
                        class="el-icon-minus" style="font-size: 16px" :disabled="processing" :loading="processing"/></el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0;border-right: 0" @click="addLevel(ins.strategyConfig)" :disabled="processing" :loading="processing"><i class="el-icon-plus" style="font-size: 16px"/></el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0;border-right: 0" @click="reset(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i class="el-icon-c-scale-to-original" style="font-size: 16px"/></el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0 3px 0 0" @click="deleteIns(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i class="el-icon-delete" style="font-size: 16px"/></el-button>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin: -10px auto auto;">
                  <el-col :span="24">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0 0 3px 3px;border-top: 0" @click="save(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i class="el-icon-check" style="font-size: 16px"/></el-button>
                  </el-col>
                </el-row>
              </el-row>
            </el-collapse-item>
          </el-collapse>
          <el-collapse class="trialpha-collapse" v-model="createCollapse" style="border-top: 0">
            <el-collapse-item class="trialpha-collapse">
              <template slot="title">
                <i class="el-icon-menu" style="margin-left: 2px;color: #0ecb81;margin-top: 1px"/><h4 class="dark-mode-font" style="margin-left: 8px;padding-top: 6px;color: white">Create New Instance</h4>
              </template>
              <el-row style="width: 98%;margin: 20px auto 0 auto">
                <el-col :span="11" style="padding-top: 2px">
                  <span class="dark-mode-font" style="color: white;display: inline-block;margin-top: 5px;float: right;margin-right: 5px;font-size: 12px">选择做空标的</span>
                </el-col>
                <el-col :span="12" :offset="1">
                  <el-select class="select-symbol" v-model="instance.symbol1Short" placeholder="请选择" style="width: 100%;line-height: 35px">
                    <el-option
                        v-for="item in [{value: true, label: 'Symbol 1'}, {value: false, label: 'Symbol 2'}]"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-row style="width: 98%;margin: 5px auto 0 auto">
                <el-col :span="11">
                  <el-select class="chose-symbol select-symbol" filterable v-model="instance.symbol1" placeholder="Symbol 1" style="width: 100%;line-height: 35px" @hook:mounted="closeReadOnly" @focus="closeReadOnly" @visible-change="closeReadOnly">
                    <el-option
                        v-for="item in allSymbols"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="12" :offset="1">
                  <el-select class="chose-symbol select-symbol" filterable v-model="instance.symbol2" placeholder="Symbol 2" style="width: 100%;line-height: 35px" @hook:mounted="closeReadOnly" @focus="closeReadOnly" @visible-change="closeReadOnly">
                    <el-option
                        v-for="item in allSymbols"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-row style="height: 268px;overflow-y: auto">
                <div v-for="level in instance.level" :key="level.index" >
                  <el-divider></el-divider>
                  <el-card class="black-card" style="width: 98%;margin-top: -10px;margin-left: auto;margin-right: auto;border: 0" shadow="none" body-style="padding: 0">
                    <el-row style="margin-top: 0">
                      <span class="dark-mode-font" style="color: white;font-size: 12px">开仓限制</span>
                    </el-row>
                    <el-row style="margin-top: 5px">
                      <el-col :span="11">
                        <span class="dark-mode-font" style="color: white;display: inline-block;margin-top: 5px;margin-left: 10px">Level: {{ (level.index + 1) }} </span>
                      </el-col>
                      <el-col :span="12" :offset="1">
                        <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="level.gapMoreThan">
                          <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">间距</span></template>
                        </el-input>
                      </el-col>
                    </el-row>
                    <el-row style="margin-top: 5px">
                      <el-col :span="11">
                        <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="level.amount">
                          <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">仓位</span></template>
                        </el-input>
                      </el-col>
                      <el-col :span="12" :offset="1">
                        <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="level.randomPercent">
                          <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">扰动</span></template>
                        </el-input>
                      </el-col>
                    </el-row>
                    <el-row style="margin-top: 5px">
                      <el-col :span="11">
                        <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="level.maxTimes">
                          <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">次数</span></template>
                        </el-input>
                      </el-col>
                      <el-col :span="12" :offset="1">
                        <el-input class="trialpha-dark-input trialpha-dark-input-para" v-model="level.maxAmount">
                          <template slot="prepend"><span style="font-size: 12px;font-weight: bolder">USD</span></template>
                        </el-input>
                      </el-col>
                    </el-row>
                  </el-card>
                </div>
                <el-divider></el-divider>
                <el-row style="width: 98%;margin: 10px auto">
                  <el-col :span="8">
                    <el-button class="dark-mode-btn" style="float: left;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 3px 0 0 3px;border-right: 0" @click="removeLevel(instance)" :disabled="instance.level === undefined || instance.level.length <= 1 || rendering" :loading="processing"><i
                        class="el-icon-minus" style="font-size: 16px"/></el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0;border-right: 0" @click="addLevel(instance)" :disabled="rendering" :loading="processing"><i class="el-icon-plus" style="font-size: 16px"/></el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0 3px 3px 0" @click="add(instance)" :disabled="rendering" :loading="processing" v-auth:dbWriteApi><i class="el-icon-check" style="font-size: 16px"/></el-button>
                  </el-col>
                </el-row>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-row>
      </template>
      <trade-tools ref="open-tools"></trade-tools>
    </el-skeleton>
  </div>
</template>

<script>
import {Message} from "element-ui";
import StrategyInstanceService from "@/api/trade/StrategyInstanceService";
import ConfirmProvider from "@/components/ConfirmProvider";
import TradeTools from "@/pages/controlPanel/accountMgnt/strategies/openTask/ftx/TradeTools";
import TradeApiService from "@/api/trade/TradeApiService";
import TrialphaSk from "@/components/TrialphaSk";

export default {
  name: "OpenTaskOkxUsdtHedge",
  components: {TrialphaSk, TradeTools},
  props: {
    dto: Object
  },
  data: function () {
    return {
      processing: false,
      rendering: true,
      activeNames: ['1'],
      createCollapse: ['1'],
      instance: {},
      instanceList: [],
      accountInstance: {},
      allPrice: {},
      allSymbols: []
    }
  },
  created() {
    this.closeReadOnly(true);
  },
  methods: {
    closeReadOnly(val) {
      this.$nextTick(() => {
        if (val) {
          this.$el.querySelectorAll('.chose-symbol.select-symbol .el-input__inner').forEach((item) => {
            item.removeAttribute('readonly');
          })
        }
      })
    },
    openTradeTools(ins) {
      this.$nextTick(function () {
        this.$refs['open-tools'].renderOkx(ins);
      });
    },
    handleShortLong(ins) {
      ins.symbol1Short = !ins.symbol1Short;
    },
    render: function (accountInstance) {
      let that = this;
      that.rendering = true;
      that.accountInstance = accountInstance;
      that.instance = JSON.parse(JSON.stringify(that.dto.strategyConfig));
      that.addLevel(that.instance);
      TradeApiService.getAllPrice('OKX', 'SPOT', function (spot) {
        TradeApiService.getAllPrice('OKX', 'SWAP', function (swap) {
          TradeApiService.getAllPrice('OKX', 'FUTURES', function (future) {
            let tmpMap = Object.assign(spot, swap);
            that.allPrice = Object.assign(tmpMap, future)
            let symbols = [];
            for (const allPriceKey in that.allPrice) {
              if (allPriceKey.endsWith('USDT') || allPriceKey.endsWith('USDC') || allPriceKey.endsWith('USDK') || allPriceKey.endsWith('DAI') || (allPriceKey.split('-').length === 3 && allPriceKey.indexOf('USDT') > -1)) {
                symbols.push(allPriceKey);
              }
            }
            that.allSymbols = symbols;
            StrategyInstanceService.query(that.accountInstance.instanceId, that.dto.strategyName, function (data) {
              that.instanceList = data.strategyInsList;
              that.rendering = false;
            }, function () {
              that.rendering = false;
            });
          });
        });
      });
    },
    removeLevel: function (ins) {
      ins.level.pop();
    },
    addLevel: function (ins) {
      let levelObj = JSON.parse(JSON.stringify(this.dto.strategyConfig.levelObj));
      levelObj.index = ins.level.length;
      ins.level.push(
          levelObj
      );
    },
    reset: function (ins) {
      let that = this;
      ConfirmProvider.openConfirm(this, '确认重置', '该操作将会重置所有非配置的数据', function () {
        that.processing = true;
        StrategyInstanceService.reset(ins.sid, function () {
          that.processing = false;
          Message.success('已成功重置');
          that.render(that.accountInstance);
        }, function () {
          that.processing = false;
        });
      });
    },
    deleteIns: function (ins) {
      let that = this;
      ConfirmProvider.openConfirm(this, '确认删除', '该操作将会删除该实例，此操作无法恢复', function () {
        that.processing = true;
        StrategyInstanceService.deleteIns(ins.sid, function () {
          that.processing = false;
          Message.success('已成功删除');
          that.render(that.accountInstance);
        }, function () {
          that.processing = false;
        });
      });
    },
    save: function (ins) {
      if (ins.strategyConfig.level.length > 1) {
        for (let i = 0; i < ins.strategyConfig.level.length - 1; i++) {
          if (ins.strategyConfig.level[i].gapMoreThan >= ins.strategyConfig.level[i + 1].gapMoreThan) {
            Message.error('间距需要递增');
            return;
          }
        }
      }
      let that = this;
      that.processing = true;
      StrategyInstanceService.update({
        sid: ins.sid,
        strategyName: ins.strategyName,
        config: (ins.strategyConfig),
        instanceEntity: {
          id: that.accountInstance.instanceId
        },
        isActive: ins.isActive
      }, function () {
        that.processing = false;
        Message.success('已成功更新');
        that.render(that.accountInstance);
      }, function () {
        that.processing = false;
      });
    },
    add: function (ins) {
      if (ins.level.length > 1) {
        for (let i = 0; i < ins.level.length - 1; i++) {
          if (ins.level[i].gapMoreThan >= ins.level[i + 1].gapMoreThan) {
            Message.error('间距需要递增');
            return;
          }
        }
      }
      ins.checkPositionSymbolList1 = ins.symbol1.toUpperCase().replace('/USD', '');
      ins.checkPositionSymbolList2 = ins.symbol2.toUpperCase().replace('/USD', '');
      let that = this;
      that.processing = true;
      StrategyInstanceService.create({
        insId: this.accountInstance.instanceId,
        strategyName: this.dto.strategyName,
        config: JSON.stringify(ins)
      }, function () {
        Message.success('已成功创建新的实例');
        that.processing = false;
        setTimeout(() => that.render(that.accountInstance), 888);
      }, function () {
        that.processing = false;
      });
    }
  }
}
</script>

<style scoped>

.ellipsis-line {
  padding: 8px;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-collapse {
  color: #FFFFFF;
  border-top: 1px solid #444c56;
  border-bottom: 1px solid #444c56;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: white;
}

.el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-divider {
  border-top: 1px solid #444c56;
}

.el-divider.info {
  width: 80%;
  border-top: 1px solid #444c56;
}

.el-divider__text {
  color: white;
  background-color: #444c56;
}

.select-symbol .el-input__inner {
  color: white;
  height: 35px !important;
  line-height: 35px;
  width: 100%;
  padding: 0 10px 0 10px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-right: 0;
  border-radius: 0;
  font-size: 12px;
  outline: none;
}

.select-symbol.el-select .el-input.is-focus .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:hover .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:hover .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:focus .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:visited .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:focus .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:visited .el-input__inner  {
  outline: none;
  border: 1px solid #444c56;
}

</style>