<template>
  <div>
    <el-drawer
        size="100%"
        :modal="false"
        append-to-body
        :close-on-click-modal=false
        :close-on-press-escape=false
        :wrapperClosable=false
        custom-class="black-card"
        :visible.sync="drawer"
        direction="rtl">
      <div style="text-align: center;margin-top: -3px;">
        <h4 class="dark-mode-font" style="font-size: 20px;color: white"><b>{{ $i('downloadPage.HowToDownloadTheAppForIOS') }}</b></h4>
      </div>
      <el-row style="margin-top: 25px">
        <el-col :span="20" :offset="2">
          <span class="dark-mode-font" style="display: inline-block;width: 100%;color: #FFFFFF;text-align: center">{{ $i('downloadPage.step0') }}</span>
        </el-col>
      </el-row>
      <el-row style="margin-top: 25px">
        <el-col :span="20" :offset="2">
          <span class="dark-mode-font" style="display: inline-block;float: left">1.<span>{{ $i('downloadPage.step1') }} <span style="color: #FFFFFF">https://quant.forthtech.io</span> {{ $i('downloadPage.step1_1') }}</span></span>
        </el-col>
      </el-row>
      <el-row style="margin-top: 25px">
        <el-col :span="20" :offset="2">
          <el-image style="margin-left: calc(50% - 100px);width: 200px;height: 400px" src="https://abm-app-image.s3.ap-northeast-1.amazonaws.com/download/1.png" :preview-src-list="stepList"></el-image>
        </el-col>
      </el-row>
      <el-row style="margin-top: 25px">
        <el-col :span="20" :offset="2">
          <span class="dark-mode-font" style="display: inline-block;float: left">2. {{ $i('downloadPage.step2') }}</span>
        </el-col>
      </el-row>
      <el-row style="margin-top: 25px">
        <el-col :span="20" :offset="2">
          <el-image style="margin-left: calc(50% - 100px);width: 200px;height: 400px" src="https://abm-app-image.s3.ap-northeast-1.amazonaws.com/download/2.png" :preview-src-list="stepList"></el-image>
        </el-col>
      </el-row>
      <el-row style="margin-top: 25px">
        <el-col :span="20" :offset="2">
          <span class="dark-mode-font" style="display: inline-block;float: left">3. {{ $i('downloadPage.step3') }}</span>
        </el-col>
      </el-row>
      <el-row style="margin-top: 25px">
        <el-col :span="20" :offset="2">
          <el-image style="margin-left: calc(50% - 100px);width: 200px;height: 400px" src="https://abm-app-image.s3.ap-northeast-1.amazonaws.com/download/3.png" :preview-src-list="stepList"></el-image>
        </el-col>
      </el-row>
      <el-row style="margin-top: 25px">
        <el-col :span="20" :offset="2">
          <span class="dark-mode-font" style="display: inline-block;float: left">4. {{ $i('downloadPage.step4') }}</span>
        </el-col>
      </el-row>
      <el-row style="margin-top: 25px">
        <el-col :span="20" :offset="2">
          <el-image style="margin-left: calc(50% - 100px);width: 200px;height: 400px" src="https://abm-app-image.s3.ap-northeast-1.amazonaws.com/download/4.png" :preview-src-list="stepList"></el-image>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>

<script>

export default {
  name: "IosDownload",
  data: function () {
    return {
      drawer: false,
      stepList: [
        'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/download/1.png',
        'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/download/2.png',
        'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/download/3.png',
        'https://abm-app-image.s3.ap-northeast-1.amazonaws.com/download/4.png'
      ]
    }
  },
  methods: {
    openDrawer: function () {
      this.drawer = true;
    }
  }
}
</script>

<style>

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(119, 109, 109, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(119, 109, 109, 0.4);
}

</style>