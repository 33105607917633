<script type="text/javascript">

import ServiceMapper from "@/const/ServiceMapper";
import AbmNet from "@/api/net/AbmNet";

function query(lastDay, instanceIdsReq, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.tradeVolQuery + '?lastNDay=' + lastDay + '&instanceIdsReq=' + instanceIdsReq, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

export default {
  query
};
</script>

