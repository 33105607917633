<script type="text/javascript">
import UserService from "@/api/user/UserService";
import {Message} from "element-ui";
import CacheService from "@/cache/CacheService";
import Web3 from "web3";

let logoutTimer = undefined;
let web3 = undefined;
let chainMap = {
  '1': {
    name: 'Ethereum',
    symbol: 'ETH'
  },
  '5': {
    name: 'Goerli',
    symbol: 'GoerliETH'
  },
  '56': {
    name: 'BSC',
    symbol: 'BNB'
  },
  '43114': {
    name: 'Avalanche',
    symbol: 'AVAX'
  },
  '137': {
    name: 'Polygon',
    symbol: 'MATIC'
  },
  '25': {
    name: 'Cronos',
    symbol: 'CRO'
  },
  '10': {
    name: 'Optimism',
    symbol: 'ETH'
  },
  '42161': {
    name: 'Arbitrum',
    symbol: 'ETH'
  },
  '250': {
    name: 'Fantom',
    symbol: 'FTM'
  },
  '8217': {
    name: 'Klaytn',
    symbol: 'KLAY'
  },
  '2222': {
    name: 'Kava',
    symbol: 'KAVA'
  }
}

function initWeb3(that) {
  if (!web3 && window.ethereum && window.ethereum.isMetaMask) {
    web3 = new Web3(window.ethereum);
    web3.currentProvider.on('accountsChanged', (newSelectedAccount) => {
      clearLogoutTimer();
      if (newSelectedAccount.length > 0) {
        initWalletInfo(that);
      }
      web3LoginCheck(that);
    });
    web3.currentProvider.on('networkChanged', function(){
      initWalletInfo(that);
    });
  }
}

function getWeb3(that) {
  initWeb3(that);
  return web3;
}

function web3LoginCheck (that) {
  if (UserService.getUserObj() && UserService.getUserObj().web3Login) {
    getWeb3(this).eth.getAccounts().then((address) => {
      if (address.length > 0) {
        web3LoginCheckInner(that, address[0]);
      } else {
        web3LoginCheckInner(that, 'NULL');
      }
    });
  }
}

function sign(that, address, success, error) {
  UserService.getNonce(address, function (data) {
    let hexData = getWeb3(that).utils.utf8ToHex(data);
    getWeb3(that).eth.personal.sign(hexData, address, '').then((sign) => {
      UserService.validateNonce(sign, address, function (data) {
        success(sign, data);
      }, function (err) {
        error(err);
      });
    }).catch(() => {
      error();
    });
  }, function () {
    error();
  });
}

function clearLogoutTimer() {
  if (logoutTimer) {
    clearTimeout(logoutTimer);
  }
}

function web3LoginCheckInner (that, addressNew) {
  let loginAddress = UserService.getUserObj().userInfo.publicAddress;
  if (addressNew.toUpperCase() !== loginAddress.toUpperCase()) {
    Message.warning('Please switch your account to ' + loginAddress + ', otherwise you will be logged out after 20 seconds');
    logoutTimer = setTimeout(() => {
      that.isLogin = false;
      UserService.logout(() => {
        CacheService.clear();
        location.reload();
      }, () => {
        CacheService.clear();
        location.reload();
      });
    }, 20000);
  }
}

async function requestMetaMask() {
  return window.ethereum.request({ method: 'eth_requestAccounts' });
}

function initWalletInfo(that, callback) {
  if (getWeb3(that)) {
    getWeb3(that).eth.getChainId().then((networkType) => {
      getWeb3(that).eth.getAccounts().then((address) => {
        getWeb3(that).eth.getBalance(address[0]).then((balance) => {
          if (callback) {
            callback({
              networkId: networkType,
              address: address[0],
              networkName: chainMap[networkType].name,
              networkSymbol: chainMap[networkType].symbol,
              balance: (balance / Math.pow(10, 18)).toFixed(4),
            });
          }
        })
      });
    });
  }
}

export default {
  sign,
  getWeb3,
  initWeb3,
  web3LoginCheck,
  requestMetaMask,
  initWalletInfo,
  chainMap
};
</script>

<style scoped>
</style>