<script type="text/javascript">
import AbmNet from "@/api/net/AbmNet";
import ServiceMapper from "@/const/ServiceMapper"
import UserService from "@/api/user/UserService";

function listTargetChain() {
  return new Promise((resolve) => {
    resolve([
      { value: 'ETH', text: 'ETH' }
    ])
  })
}

function listCoinType() {
  return new Promise((resolve) => {
    resolve([
      { value: 'USDT', text: 'USDT' }
    ])
  })
}

function listCommonUsedCexWallet() {
  return new Promise((resolve, reject) => {
    let params = {
      page: 0,
      size: 99999999,
      userName: UserService.getUserName()
    }
    AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.walletListCommonUsedCexWallet, params, function (data) {
      resolve(data);
    }, function (err) {
      reject(err);
    });
  })
}

function listCommonUsedDexWallet() {
  return new Promise((resolve, reject) => {
    let params = {
      page: 0,
      size: 99999999,
      userName: UserService.getUserName()
    }
    AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.walletListCommonUsedDexWallet, params, function (data) {
      resolve(data);
    }, function (err) {
      reject(err);
    });
  })
}

// 常用钱包列表，包含交易所常用钱包和去中心化钱包
function listCommonUsedWallet() {
  return new Promise((resolve, reject) => {
    // listCommonUsedCexWallet().then((cexWalletList) => {
    //   cexWalletList.forEach(w => {
    //     w.walletType = 'CEX'
    //   })
    //   listCommonUsedDexWallet().then((dexWalletList) => {
    //     dexWalletList.forEach(w => {
    //       w.walletType = 'DEX'
    //     })
    //     resolve(cexWalletList.concat(dexWalletList))
    //   }, (err) => {
    //     reject(err)
    //   })
    // }, (err) => {
    //   reject(err)
    // })
    listCommonUsedDexWallet().then((dexWalletList) => {
      dexWalletList.forEach(w => {
        w.walletType = 'DEX'
      })
      resolve(dexWalletList)
    }, (err) => {
      reject(err)
    })
  })
}

function addCommonUsedWallet(params) {
  // let params = {
  //   walletType,
  //   wallet,
  //   dexChain,
  //   userName: UserService.getUserName()
  // }
  return new Promise((resolve, reject) => {
    params.userName = UserService.getUserName()
    AbmNet.postRequest(ServiceMapper.WEB_SERVICE.walletAddCommonUsedWallet, params, function (res) {
      resolve(res);
    }, function (err) {
      reject(err);
    });
  })
}

function deleteCommonUsedWallet(params) {
  // let params = {
  //   id,
  // }
  return new Promise((resolve, reject) => {
    // params.userName = UserService.getUserName()
    AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.walletDeleteCommonUsedWallet, params, function (res) {
      resolve(res);
    }, function (err) {
      reject(err);
    });
  })
}

function createCexWallet(params) {
  // let params = {
  //   platform: "BINANCE",
  //   apiKey: "apiKey6",
  //   secretKey: "secretKey",
  //   secondaryVerification: "secondaryVerification",
  //   requireApprovalNumber: 1,
  //   userName: "louie",
  //   approverUsernameList: ["louie"]
  //   "humanReadableName" : "123"
  // }
  return new Promise((resolve, reject) => {
    params.userName = UserService.getUserName()
    AbmNet.postRequest(ServiceMapper.WEB_SERVICE.walletCreateCexWallet, params, function (res) {
      resolve(res);
    }, function (err) {
      reject(err);
    });
  })
}

function createDexWallet(params) {
  // let params = {
  //   address: "wjb",
  //   chainName: "ETH",
  //   requireApprovalNumber: 1,
  //   userName: "louie",
  //   approverUsernameList: ["louie"]
  //   "humanReadableName" : "123"
  // }
  return new Promise((resolve, reject) => {
    params.userName = UserService.getUserName()
    AbmNet.postRequest(ServiceMapper.WEB_SERVICE.walletCreateDexWallet, params, function (res) {
      resolve(res);
    }, function (err) {
      reject(err);
    });
  })
}

function deleteWallet(params) {
  // let params = {
  //   id
  // }
  return new Promise((resolve, reject) => {
    params.userName = UserService.getUserName()
    AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.walletDeleteWallet, params, function (res) {
      resolve(res);
    }, function (err) {
      reject(err);
    });
  })
}

function listCexWallet() {
  return new Promise((resolve, reject) => {
    let params = {
      page: 0,
      size: 99999999
    }
    AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.walletListCexWallet, params, function (data) {
      resolve(data);
    }, function (err) {
      reject(err);
    });
  })
}

function listDexWallet() {
  return new Promise((resolve, reject) => {
    let params = {
      page: 0,
      size: 99999999
    }
    AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.walletListDexWallet, params, function (data) {
      resolve(data);
    }, function (err) {
      reject(err);
    });
  })
}

function getCexTransferConstraint(id) {
  return new Promise((resolve, reject) => {
    let params = {
      id
    }
    AbmNet.postRequest(ServiceMapper.WEB_SERVICE.walletGetCexTransferConstraint, params, function (data) {
      resolve(data);
    }, function (err) {
      reject(err);
    });
  })
}

// 钱包列表，包含交易所常用钱包和去中心化钱包
function listWallet() {
  return new Promise((resolve, reject) => {
    listCexWallet().then((cexWalletList) => {
      cexWalletList.forEach(w => {
        w.walletType = 'CEX'
      })
      listDexWallet().then((dexWalletList) => {
        dexWalletList.forEach(w => {
          w.walletType = 'DEX'
        })
        resolve(cexWalletList.concat(dexWalletList))
      }, (err) => {
        reject(err)
      })
    }, (err) => {
      reject(err)
    })
  })
}

function createTransfer(params) {
  // let params = {
  //   transactionHash: "wjb",
  //   fromWalletType: "CEX",
  //   fromWallet: "apiKey",
  //   toWalletType: "DEX",
  //   toWallet: "wjb",
  //   toDexChain: "ETH",
  //   token: "USDT",
  //   tokenAmount: 100.5,
  //   userName: "louie"
  // }
  return new Promise((resolve, reject) => {
    params.userName = UserService.getUserName()
    AbmNet.postRequest(ServiceMapper.WEB_SERVICE.walletCreateTransfer, params, function (res) {
      resolve(res);
    }, function (err) {
      reject(err);
    });
  })
}

function approveTransfer(params) {
  // let params = {
  //   transferId: "203a6805-9739-43a8-aa71-8e0035bfb812",
  //   approver: "louie"
  // }
  return new Promise((resolve, reject) => {
    params.approver = UserService.getUserName()
    AbmNet.postRequest(ServiceMapper.WEB_SERVICE.walletApproveTransfer, params, function (res) {
      resolve(res);
    }, function (err) {
      reject(err);
    });
  })
}

function revokeApprovalTransfer(params) {
  // let params = {
  //   approvalId: "203a6805-9739-43a8-aa71-8e0035bfb812",
  //   requester: "louie"
  // }
  return new Promise((resolve, reject) => {
    params.approver = UserService.getUserName()
    AbmNet.postRequest(ServiceMapper.WEB_SERVICE.walletRevokeApprovalTransfer, params, function (res) {
      resolve(res);
    }, function (err) {
      reject(err);
    });
  })
}

function listPendingTransfers() {
  return new Promise((resolve, reject) => {
    let params = {
      page: 0,
      size: 99999999
    }
    AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.walletListPendingTransfers, params, function (data) {
      resolve(data);
    }, function (err) {
      reject(err);
    });
  })
}

function listFinishedTransfers() {
  return new Promise((resolve, reject) => {
    let params = {
      page: 0,
      size: 99999999
    }
    AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.walletListFinishedTransfers, params, function (data) {
      resolve(data);
    }, function (err) {
      reject(err);
    });
  })
}

function listMyFinishedTransfers(index, size) {
  return new Promise((resolve, reject) => {
    let params = {
      page: index,
      size: size
    }
    AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.walletListMyFinishedTransfers, params, function (data) {
      resolve(data);
    }, function (err) {
      reject(err);
    });
  })
}

function listICanApproveTransfers() {
  return new Promise((resolve, reject) => {
    let params = {
      page: 0,
      size: 99999999
    }
    AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.walletListICanApproveTransfers, params, function (data) {
      resolve(data);
    }, function (err) {
      reject(err);
    });
  })
}

function listCreatedByMeTransfers() {
  return new Promise((resolve, reject) => {
    let params = {
      page: 0,
      size: 99999999
    }
    AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.walletListCreatedByMeTransfers, params, function (data) {
      resolve(data);
    }, function (err) {
      reject(err);
    });
  })
}

function getUserList() {
  return new Promise((resolve, reject) => {
    let params = {
      page: 1,
      size: 99999999
    }
    AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.getUserList, params, function (data) {
      resolve(data);
    }, function (err) {
      reject(err);
    });
  })
}

function listAllDexChainTokenMappings() {
  return new Promise((resolve, reject) => {
    let params = {
      
    }
    AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.walletListAllDexChainTokenMappings, params, function (data) {
      resolve(data);
    }, function (err) {
      reject(err);
    });
  })
}

function listDexWalletCreatedByRequester() {
    return new Promise((resolve, reject) => {
      let params = {
        page: 0,
        size: 99999999
      }
      AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.walletListDexWalletCreatedByRequester, params, function (data) {
        resolve(data);
      }, function (err) {
        reject(err);
      });
    })
}

function listCexWalletCreatedByRequester() {
    return new Promise((resolve, reject) => {
      let params = {
        page: 0,
        size: 99999999
      }
      AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.walletListCexWalletCreatedByRequester, params, function (data) {
        resolve(data);
      }, function (err) {
        reject(err);
      });
    })
}

// 由我创建的钱包，包含交易所常用钱包和去中心化钱包
function listWalletCreatedByRequester() {
  return new Promise((resolve, reject) => {
    listCexWalletCreatedByRequester().then((cexWalletList) => {
      cexWalletList.forEach(w => {
        w.walletType = 'CEX'
      })
      listDexWalletCreatedByRequester().then((dexWalletList) => {
        dexWalletList.forEach(w => {
          w.walletType = 'DEX'
        })
        resolve(cexWalletList.concat(dexWalletList))
      }, (err) => {
        reject(err)
      })
    }, (err) => {
      reject(err)
    })
  })
}

function listDexWalletApprovedByRequester() {
    return new Promise((resolve, reject) => {
      let params = {
        page: 0,
        size: 99999999
      }
      AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.walletListDexWalletApprovedByRequester, params, function (data) {
        resolve(data);
      }, function (err) {
        reject(err);
      });
    })
}

function listCexWalletApprovedByRequester() {
    return new Promise((resolve, reject) => {
      let params = {
        page: 0,
        size: 99999999
      }
      AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.walletListCexWalletApprovedByRequester, params, function (data) {
        resolve(data);
      }, function (err) {
        reject(err);
      });
    })
}

// 我有审批权的钱包，包含交易所常用钱包和去中心化钱包
function listWalletApprovedByRequester() {
  return new Promise((resolve, reject) => {
    listCexWalletApprovedByRequester().then((cexWalletList) => {
      cexWalletList.forEach(w => {
        w.walletType = 'CEX'
      })
      listDexWalletApprovedByRequester().then((dexWalletList) => {
        dexWalletList.forEach(w => {
          w.walletType = 'DEX'
        })
        resolve(cexWalletList.concat(dexWalletList))
      }, (err) => {
        reject(err)
      })
    }, (err) => {
      reject(err)
    })
  })
}

function updateWalletApprovers(params) {
//   let params = {
//     id: "c17557df-ba07-4d8e-860e-99cb804963f7",
//     requireApprovalNumber : 3,
//     approverUsernameList : ["louie", "lining", "joe.admin", "duoduo"]
//   }
  return new Promise((resolve, reject) => {
    params.approver = UserService.getUserName()
    AbmNet.postRequest(ServiceMapper.WEB_SERVICE.walletUpdateWalletApprovers, params, function (res) {
      resolve(res);
    }, function (err) {
      reject(err);
    });
  })
}

function walletQueryCexBalanceOf(params) {
  // let params = {
  //   "apiKey" : "apiKey",
  // }
  return new Promise((resolve, reject) => {
    params.approver = UserService.getUserName()
    AbmNet.postRequest(ServiceMapper.WEB_SERVICE.walletQueryCexBalanceOf, params, function (res) {
      res.USDC = res.USD != undefined ? res.USD : res.USDC
      res.BUSD = res.USD != undefined ? res.USD : res.BUSD
      resolve(res);
    }, function (err) {
      reject(err);
    });
  })
}

function walletQueryDexBalanceOf(params) {
  // let params = {
  //   "walletAddress" : "0x743DdbF5afdC333d2f99d8CCB5Ac5312a2ab9f9F",
  //   "dexChain" : "ETH",
  //   "tokens" : ["USDC", "ETH"]
  // }
  return new Promise((resolve, reject) => {
    params.approver = UserService.getUserName()
    AbmNet.postRequest(ServiceMapper.WEB_SERVICE.walletQueryDexBalanceOf, params, function (res) {
      res.USDC = res.USD != undefined ? res.USD : res.USDC
      res.BUSD = res.USD != undefined ? res.USD : res.BUSD
      resolve(res);
    }, function (err) {
      reject(err);
    });
  })
}

function walletIsValidCexWallet(params) {
  // let params = {
  //   "apiKey" : "cZ3MPpgELQJMCSvRnsYWw-sTQPBDqlMKyw9gmHS7_FtxSub_HF",
  //   "secretKey" : "12345",
  //   "platform" : "FTX"
  // }
  return new Promise((resolve, reject) => {
    AbmNet.postRequest(ServiceMapper.WEB_SERVICE.walletIsValidCexWallet, params, function (res) {
      resolve(res);
    }, function (err) {
      reject(err);
    });
  })
}

function walletIsValidDexWallet(params) {
  // let params = {
  //   "walletAddress" : "0x743DdbF5afdC333d2f99d8CCB5Ac5312a2ab9f9F",
  //   "privateKey" : "0c9fd95987cb31d4eccd252b99e56f552a1b59a",
  //   "dexChain" : "ETH"
  // }
  return new Promise((resolve, reject) => {
    AbmNet.postRequest(ServiceMapper.WEB_SERVICE.walletIsValidDexWallet, params, function (res) {
      resolve(res);
    }, function (err) {
      reject(err);
    });
  })
}



export default {
  listTargetChain,
  listCoinType,
  listCommonUsedCexWallet,
  listCommonUsedDexWallet,
  listCommonUsedWallet,
  addCommonUsedWallet,
  deleteCommonUsedWallet,
  createCexWallet,
  createDexWallet,
  deleteWallet,
  listCexWallet,
  listDexWallet,
  getCexTransferConstraint,
  listWallet,
  createTransfer,
  approveTransfer,
  revokeApprovalTransfer,
  listPendingTransfers,
  listFinishedTransfers,
  listMyFinishedTransfers,
  listICanApproveTransfers,
  listCreatedByMeTransfers,
  getUserList,
  listAllDexChainTokenMappings,
  listDexWalletCreatedByRequester,
  listCexWalletCreatedByRequester,
  listWalletCreatedByRequester,
  listDexWalletApprovedByRequester,
  listCexWalletApprovedByRequester,
  listWalletApprovedByRequester,
  updateWalletApprovers,
  walletQueryCexBalanceOf,
  walletQueryDexBalanceOf,
  walletIsValidCexWallet,
  walletIsValidDexWallet
}
</script>
