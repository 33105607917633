<template>
  <el-card class="black-card" style="cursor: pointer;position: sticky;bottom: 0;width:100%;border-radius: 0;border-left: 0;border-right: 0;border-bottom: 0;height: 22px;z-index:999" body-style="padding: 0" @click.native="openDataPanel">
    <el-row style="margin-top: -4px">
      <ul>
        <li><i class="el-icon-s-unfold" style="cursor: pointer;color: #ffffff;float: left;margin-left: 10px;padding-top: 7px;font-size: 14px"/><span class="smaller-font" style="display: inline-block;font-size: 12px;color: white;" v-for="i in selectedSymbols" :key="priceInfo[i].s">{{ priceInfo[i].s }}: <span
            :style="(Number(priceInfo[i].c) > Number(priceInfo[i].o)) ? 'color:#0ecb81' : 'color:#f6465d'"><i :class="(Number(priceInfo[i].c) > Number(priceInfo[i].o)) ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"/>{{ priceInfo[i].c }} {{
            ((Number(priceInfo[i].c) - Number(priceInfo[i].o)) / Number(priceInfo[i].o) * 100).toFixed(2)
          }}%</span></span></li>
      </ul>
    </el-row>
    <data-panel ref="data-panel"></data-panel>
  </el-card>
</template>

<script>

import DataPanel from "@/components/DataPanel";
export default {
  name: "PriceInfo",
  components: {DataPanel},
  data: function () {
    return {
      selectedSymbols: ['BTCUSDT', 'ETHUSDT', 'LTCUSDT', 'BNBUSDT', 'SOLUSDT', 'XRPUSDT', 'APEUSDT', 'DOGEUSDT', 'ADAUSDT', 'ATOMUSDT', 'DOTUSDT', 'LINKUSDT', 'UNIUSDT', 'SUSHIUSDT', 'ETCUSDT', 'BCHUSDT', 'FILUSDT', '1INCHUSDT', 'MATICUSDT',
        'SANDUSDT', 'EOSUSDT', 'STORJUSDT', 'KNCUSDT'],
      priceInfo: {}
    }
  },
  created() {
    for (const selectedSymbolsKey in this.selectedSymbols) {
      let obj = this.selectedSymbols[selectedSymbolsKey];
      this.priceInfo[obj] = {
        s: obj,
        c: 1,
        o: 1,
        i: 1
      };
    }
    this.initWebsocket();
  },
  methods: {
    openDataPanel() {
      this.$nextTick(function () {
        this.$refs['data-panel'].render();
      });
    },
    initWebsocket() {
      let that = this;
      that.webSocketObj = new WebSocket('wss://fstream.binance.com/ws/!miniTicker@arr');
      that.webSocketObj.onmessage = that.onMessage
      that.webSocketObj.onopen = that.onOpen
      that.webSocketObj.onerror = that.onError
      that.webSocketObj.onclose = that.onClose
    },
    onOpen() {
      if (this.webSocketObj.readyState === 1) {
        this.wsHeartFlag = true;
        this.reconnectTime = 0;
      }
    },
    timingHeart() {
      if (this.wsHeartFlag) {
        this.webSocketObj.send('pong');
      }
      this.wsHeart = setTimeout(() => {
        this.timingHeart();
      }, 1000 * 60 * 10);
    },
    onMessage(evt) {
      let data = evt && JSON.parse(evt.data);
      for (const argumentsKey in data) {
        let obj = data[argumentsKey];
        if (this.selectedSymbols.indexOf(obj.s) > -1) {
          this.priceInfo[obj.s] = obj;
        }
      }
      this.$forceUpdate();
    },
    onError() {
      // 链接失败，进行重连
      clearTimeout(this.wsHeart);
      this.wsHeartFlag = false;
      this.initWebsocket();
    },
    onClose() {
      if (this.wsHeartFlag) {
        this.wsHeartFlag = false;
        this.webSocketObj && this.webSocketObj.close && this.webSocketObj.close();
      }
    },
  }
}
</script>

<style scoped>
.smaller-font {
  -webkit-transform-origin-x: 0;
  -webkit-transform: scale(0.9);
}

li {
  content: ".";
  color: #0ecb81;
}
</style>