<template>
    <div class="ft-group-button not-select"
        :class="[textMode == 'true' ? 'text-mode' : '']" >
        <div class="ft-group-button-inner" :class="[type == 'block' ? 'ft-block' : '']">
            <template v-for="item in buttons">
                <div v-bind:key="item.id" class="ft-group-button-item" 
                    :class="[mode === 'group' && item.active ? 'active' : '', textMode == 'true' ? 'text-mode' : '']" 
                    :style="{padding: (padding ? padding : (textMode == 'true' ? '0 40px 0 0' : '0 20px 0 20px'))}"
                    @click="eventTap(item)">
                    <i v-if="item.icon" :class="item.icon"></i>
                    {{item.textI18n ? $i(item.textI18n) : item.text}}
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        
    },
    props: {
        // group, single
        mode: {
            type: String,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: 'group'
        },
        // '', block
        type: {
            type: String,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: ''
        },
        buttons: {
            type: Array,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: [
                // {
                //     id: 'personal',
                //     text: '个人',
                //     textI18n: 'channelMgnt.switchPageTypePersonal',
                //     active: true
                // },
                // {
                //     id: 'channel',
                //     text: '渠道',
                //     textI18n: 'channelMgnt.switchPageTypeChannel',
                //     active: false
                // },
                // {
                //     id: 'append',
                //     icon: 'el-icon-plus',
                //     text: '新建',
                //     textI18n: 'channelMgnt.addCustomer',
                //     active: false
                // }
            ]
        },
        padding: {
            type: String,
            default: ''
        },
        bData: {
            type: Object
        },
        textMode: {
            type: String,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: 'false'
        },
    },
    data() {
        return {

        }
    },
    methods: {
        eventTap(item) {
            if (this.mode == 'group') {
                var buttons = this.buttons
                buttons.forEach((b) => {
                    if (b.id == item.id) {
                        b.active = true
                    } else {
                        b.active = false
                    }
                })
                this.$emit('update:buttons', buttons);
            }
            this.$emit('switch-change', item, this.bData);
        }
    }
}
</script>

<style scoped>
.ft-group-button {
    float: left; height: 30px; padding: 0; border: 1px solid #3E454E; border-radius: 3px;line-height: 30px;font-size: 12px;
}
.ft-group-button.text-mode {
    border: 1px solid transparent;
    background: transparent !important;
    font-size: 16px;
}
.ft-group-button .ft-group-button-inner {
    display: flex; color: white;border: 0;
}
.ft-group-button .ft-group-button-inner.ft-block{
    width: 100%;
    text-align: center;
}
.ft-group-button .ft-group-button-inner .ft-group-button-item{
    padding: 0 20px 0 20px; cursor: pointer; border-radius: 3px; line-height: 29px;
}
.ft-group-button .ft-group-button-inner.ft-block .ft-group-button-item{
    width: 100%;
    text-align: center;
}
.ft-group-button .ft-group-button-inner .ft-group-button-item.active{
  background: #3E454E;
  border: 0;
}

.ft-group-button .ft-group-button-inner .ft-group-button-item:hover{
  background: #646b74;
}

.ft-group-button .ft-group-button-inner .ft-group-button-item.text-mode{
  background: transparent;
  cursor: initial;
}

.not-select{
    -moz-user-select:none; /*火狐*/
    -webkit-user-select:none; /*webkit浏览器*/
    -ms-user-select:none; /*IE10*/
    -khtml-user-select:none; /*早期浏览器*/
    user-select:none;
}
</style>