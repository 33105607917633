<script lang="javascript">
import param from "@/paras/Paras";

const trans = {
  lang: '日本',
  copyright: 'Copyright © 2022 ' + param.getCompanyDisplayValue(),
  lang_china: '繁體中文',
  lang_english: 'English',
  lang_japan: '日本',
  lang_korea: '한국인',
  lang_thailand: 'ไทย',
  changePassword: 'パスワードを変更する',
  manageAccount: 'アカウント管理',
  pleaseLoginFirst: '最初にログインしてください',
  week: {
    Sun: 'Sun',
    Mon: 'Mon',
    Tue: 'Tue',
    Wed: 'Web',
    Thu: 'Thu',
    Fri: 'Fri',
    Sat: 'Sat'
  },
  login: {
    login: 'ログイン',
    processing: '処理...',
    subTitle: '統合認証管理プラットフォーム',
    logout: 'サインアウト',
    register: '登録',
    email: '郵便',
    username: 'ユーザー名',
    password: 'パスワード',
    forgetPwd: 'パスワードをお忘れですか',
    start: 'ログインする',
    dontHaveAccount: 'まだアカウントを持っていません',
    loginSuccess: '正常にログインしました',
    logoutSuccess: '現在のアカウントからログアウトしています',
    loginTitle: 'ログイン通知'
  },
  navBars: {
    controlPanel: 'コントロールパネル',
    accountMgnt: 'アカウント管理',
    tradeMgnt: 'アグリゲートトレーディングセンター',
    riskMgnt: '危機管理',
    testMgnt: 'テストセンター',
    documentMgnt: 'ドキュメンテーションセンター',
    channelMgnt: 'お客様リレーション',
    wallet: '財布'
  },
  permission: {
    SUPER_ADMIN: 'スーパーアドミニストレーター',
    ADMIN: '管理者'
  },
  controlPanelHome: {
    lastLoginTime: ', 最終ログイン時間： ',
    allAccountOperation: 'みんなの活動',
    queryAll: 'すべて表示',
    tradeTimes: '累積トランザクション時間',
    tradeAmounts: '累積売上高',
    tradeAmount30: '過去30日間の売上高',
    errorPosition: '間違った位置のリマインダー',
    message: 'プライベートメッセージのリマインダー',
    groupMessage: 'グループメッセージリマインダー',
    system7Vol: '取引量',
    unreadMsg: 'プライベートレター',
    unreadGroupMsg: 'グループメッセージ',
    taskConfig: 'タスク構成',
    pendingTask: '未処理のタスク'
  },
  tradeMgnt: {
    load: 'ロード',
    kPanel: {
      open: '開いた',
      close: '受け取る',
      high: '高い',
      low: '低い',
      change: '見積もりの​​変更',
      amplitude: '振幅',
      vol: '音量'
    },
    infoPanel: {
      change24: '24h浮き沈み',
      low24: '24h最低価格',
      high24: '24h最高価格',
      vol24: '24h音量',
      volU24: '24hひっくり返す'
    },
    klineType: {
      m1: '1分',
      m5: '5分',
      m15: '15分',
      m30: '30分',
      h1: '1時間',
      h2: '2時間',
      h4: '4時間',
      d1: '1日',
      week: '1週間'
    }
  }
}

export default {
  trans
}

</script>