<script type="text/javascript">


import AbmNet from "@/api/net/AbmNet";
import ServiceMapper from "@/const/ServiceMapper";


function withdrawTokenToWallet(sid, tokenAddress, readableAmount, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.uniPlusWithdrawTokenToWallet, {
    insId: sid,
    tokenAddress: tokenAddress,
    readableAmount: readableAmount
  }, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function uniPlusQueryBalance(sid, isNativeToken, isQuerySenderWallet, tokenAddress, success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.uniPlusQueryBalance + '?insId=' + sid + '&isNativeToken=' + isNativeToken + '&isQuerySenderWallet=' + isQuerySenderWallet + '&tokenAddress=' + tokenAddress, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function uniPlusTransfer(sid, to, isNativeToken, tokenAddress, readableAmount, success, error) {
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.uniPlusTransfer, {
    insId: sid,
    to: to,
    isNativeToken: isNativeToken,
    tokenAddress: tokenAddress,
    readableAmount: readableAmount
  }, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}



export default {
  withdrawTokenToWallet,
  uniPlusQueryBalance,
  uniPlusTransfer
}

</script>


