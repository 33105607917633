<template>
  <div>
    <el-card class="black-card" style="height: 398px" shadow="none">
      <input class="black-card" type="text" placeholder="Search..." v-model="keyWord" style="background-color: #1a1f25;width: 100%;outline: none;color: white;font-size: 12px;height: 30px;padding-left: 5px;border-radius: 2px" />
      <el-button-group style="margin-top: 10px;margin-left: 6px">
        <el-button class="dark-mode-btn" style="height: 25px;line-height: 25px;font-size: 12px;padding: 0 12px 0 12px;-webkit-transform: scale(0.8);margin-left: -13px;margin-top: -6px;width: 70px;border: 0;border-radius: 0" v-for="group in Object.keys(symbolMap)" :key="group" @click="changeActive(group)" :disabled="activePanel === group">{{ group }}</el-button>
      </el-button-group>
      <el-card class="black-card" style="width: 100%;height: 25px;border: 0;margin-top: 0;" shadow="none" body-style="padding: 0">
        <el-row>
          <el-col :span="12">
            <span style="margin-top: 3px;display: inline-block;float: left;font-size: 12px;color: #c0c1c0">Symbol</span>
          </el-col>
          <el-col :span="7">
            <span style="margin-top: 3px;display: inline-block;float: right;font-size: 12px;color: #c0c1c0">Price</span>
          </el-col>
          <el-col :span="5">
            <span style="margin-top: 3px;display: inline-block;float: right;font-size: 12px;color: #c0c1c0">24h %</span>
          </el-col>
        </el-row>
      </el-card>
      <el-card v-if="symbolMap && Object.keys(symbolMap).length > 0" class="black-card" style="margin-top: 0;border: 0;height: 270px;overflow-y: auto" shadow="none" body-style="padding: 0">
        <div v-if="favoriteObj[exchange][activePanel]">
          <el-card class="select-symbol-item" @click.native="selectSymbol(symbolMap[activePanel][symbol])" style="width: 100%;height: 25px;border: 0" v-for="symbol in Object.keys(favoriteObj[exchange][activePanel]).filter((s) => favoriteSymbolFilter(s))" :key="symbol" shadow="none" body-style="padding: 0">
            <el-row>
              <el-col :span="12">
                <i v-if="!favoriteObj[exchange][activePanel] || !favoriteObj[exchange][activePanel][symbol]" class="el-icon-star-off" style="float: left;color: gold;margin-top: 6px;font-size: 12px;cursor: pointer" @click.stop="addFavorite(symbolMap[activePanel][symbol])"></i>
                <i v-if="favoriteObj[exchange][activePanel] && favoriteObj[exchange][activePanel][symbol]" class="el-icon-star-on" style="float: left;color: gold;margin-top: 6px;font-size: 12px;cursor: pointer" @click.stop="addFavorite(symbolMap[activePanel][symbol])"></i>
                <span style="margin-top: 3px;display: inline-block;float: left;font-size: 12px;color: white;margin-left: 5px">{{ symbol }}</span>
              </el-col>
              <el-col :span="7">
                <span :style="'margin-top: 3px;display: inline-block;float: right;font-size: 12px;' + ((symbolMap[activePanel][symbol].change24h > 0 ? 'color: rgb(14, 203, 129);' : 'color: rgb(246, 70, 93);')) + ''">{{ symbolMap[activePanel][symbol].price }}</span>
              </el-col>
              <el-col :span="5">
                <span :style="'margin-top: 3px;display: inline-block;float: right;font-size: 12px;' + ((symbolMap[activePanel][symbol].change24h > 0 ? 'color: rgb(14, 203, 129);' : 'color: rgb(246, 70, 93);')) + ''">{{ symbolMap[activePanel][symbol].change24h.toFixed(2) }}%</span>
              </el-col>
            </el-row>
          </el-card>
        </div>
        <div v-if="symbolMap[activePanel]">
          <el-card class="select-symbol-item" @click.native="selectSymbol(symbolMap[activePanel][symbol])" style="width: 100%;height: 25px;border: 0" v-for="symbol in Object.keys(symbolMap[activePanel]).filter((s) => symbolPanelFilter(s))" :key="symbol" shadow="none" body-style="padding: 0">
            <el-row>
              <el-col :span="12">
                <i v-if="!favoriteObj[exchange][activePanel] || !favoriteObj[exchange][activePanel][symbol]" class="el-icon-star-off" style="float: left;color: gold;margin-top: 6px;font-size: 12px;cursor: pointer" @click.stop="addFavorite(symbolMap[activePanel][symbol])"></i>
                <i v-if="favoriteObj[exchange][activePanel] && favoriteObj[exchange][activePanel][symbol]" class="el-icon-star-on" style="float: left;color: gold;margin-top: 6px;font-size: 12px;cursor: pointer" @click.stop="addFavorite(symbolMap[activePanel][symbol])"></i>
                <span style="margin-top: 3px;display: inline-block;float: left;font-size: 12px;color: white;margin-left: 5px">{{ symbol }}</span>
              </el-col>
              <el-col :span="7">
                <span :style="'margin-top: 3px;display: inline-block;float: right;font-size: 12px;' + ((symbolMap[activePanel][symbol].change24h > 0 ? 'color: rgb(14, 203, 129);' : 'color: rgb(246, 70, 93);')) + ''">{{ symbolMap[activePanel][symbol].price }}</span>
              </el-col>
              <el-col :span="5">
                <span :style="'margin-top: 3px;display: inline-block;float: right;font-size: 12px;' + ((symbolMap[activePanel][symbol].change24h > 0 ? 'color: rgb(14, 203, 129);' : 'color: rgb(246, 70, 93);')) + ''">{{ symbolMap[activePanel][symbol].change24h.toFixed(2) }}%</span>
              </el-col>
            </el-row>
          </el-card>
        </div>
      </el-card>
    </el-card>
  </div>
</template>

<script>

import CacheService from "@/cache/CacheService";

export default {
  name: "SymbolSelectPanel",
  data: function () {
    return {
      keyWord: '',
      loading: true,
      exchange: '',
      symbolMap: {},
      baseCoin: 'ALL',
      activePanel: '',
      favoriteObj: {
        BINANCE:{},
        OKEX:{},
        FTX:{},
        DERIBIT:{},
        BYBIT:{}
      }
    }
  },
  methods: {
    favoriteSymbolFilter(s) {
      if (this.keyWord === '' && this.baseCoin === 'ALL' && this.symbolMap[this.activePanel][s]) {
        return true;
      } else if (s.toUpperCase().indexOf(this.keyWord.toUpperCase()) > -1 && (this.baseCoin === 'ALL' ? true : (s.toUpperCase().indexOf(this.baseCoin.toUpperCase()) > -1)) && this.symbolMap[this.activePanel][s]) {
        return true
      }
    },
    symbolPanelFilter(s) {
      if (!this.favoriteObj[this.exchange][this.activePanel] || !this.favoriteObj[this.exchange][this.activePanel][s]) {
        if (this.keyWord === '' && this.baseCoin === 'ALL') {
          return true;
        } else if (s.toUpperCase().indexOf(this.keyWord.toUpperCase()) > -1 && (this.baseCoin === 'ALL' ? true : (s.toUpperCase().indexOf(this.baseCoin.toUpperCase()) > -1))) {
          return true
        }
      }
    },
    addFavorite: function (symbol) {
      this.$nextTick(() => {
        if (!this.favoriteObj[this.exchange][this.activePanel]) {
          this.favoriteObj[this.exchange][this.activePanel] = {};
        }
        if (!this.favoriteObj[this.exchange][this.activePanel][symbol.symbol]) {
          this.favoriteObj[this.exchange][this.activePanel][symbol.symbol] = symbol;
        } else {
          delete this.favoriteObj[this.exchange][this.activePanel][symbol.symbol];
        }
        CacheService.set('favoriteSymbol', this.favoriteObj);
        this.$forceUpdate();
      });
    },
    render: function (symbolMap, exchange, baseCoin) {
      this.keyWord = '';
      this.exchange = exchange;
      this.symbolMap = symbolMap;
      this.baseCoin = baseCoin;
      this.activePanel = Object.keys(symbolMap)[0];
      let fCache = CacheService.get('favoriteSymbol');
      if (fCache) {
        this.favoriteObj = fCache;
      }
    },
    changeActive: function (name) {
      this.activePanel = name;
    },
    selectSymbol: function (symbol) {
      this.$emit('symbolChange', symbol.value);
    }
  }
}
</script>

<style scoped>

</style>