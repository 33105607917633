<template>
  <div id="app" style="height: 100vh">
    <router-view style="margin-top: 0"></router-view>
  </div>
</template>

<script>
import NProgress from 'muse-ui-progress';
import UserService from "@/api/user/UserService";

export default {
  name: 'App',
  components: {},
  data: function () {
    return {
      isLogin: UserService.isLogin(),
      username: UserService.getUserName(),
      navTop: 0
    }
  },
  beforeCreate: function () {

  },
  created: function () {
    if (document.body.clientWidth < 1000) {
      document.styleSheets[document.styleSheets.length - 1].insertRule('::-webkit-scrollbar { display: none }', 0);
    }
    // let that = this;
    // document.body.addEventListener('touchmove', function (e) {
    //   if (e) {
    //     if (!that.hasParent(e.target, "trialpha-stander-sub-page") || !that.hasParent(e.target, "trialpha-full-sub-page")) {
    //       e.preventDefault();
    //     }
    //   }
    // }, {passive: false});

    NProgress.done();
  },
  mounted: function () {

  },
  watch: {},
  methods: {
    hasParent: function (element, parentClass) {
      let ele = element;
      while (!(ele.parentElement === null || ele.parentElement.tagName === 'body')) {
        if (ele.parentElement.className.indexOf(parentClass) > -1) {
          return true;
        }
        ele = ele.parentElement;
      }
      return false;
    }
  }
}
</script>

<style lang="less">

body {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

#app {
  font-family: "DIN-Bold", "Microsoft YaHei", "黑体", "宋体", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.button-wrapper {
  text-align: center;

  .mu-button {
    margin: 8px;
  }
}

.bit-maker-logo {
  margin-top: 0;
  margin-left: 8px;
  width: 30px;
  height: 32px;
  background-image: url("https://abm-app-image.s3.ap-northeast-1.amazonaws.com/oneport/white-logo-without-title.png");
  filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
}

.bit-maker-text {
  margin-top: 5px;
  margin-left: 8px;
  width: 93px;
  height: 23px;
  background-image: url("https://abm-app-image.s3.ap-northeast-1.amazonaws.com/oneport/white-logo-title.png");
  filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 0;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(119, 109, 109, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(119, 109, 109, 0.5);
}

.mask {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(59, 53, 53, 0.5);
  -webkit-filter: blur(5px); /* Chrome, Opera */
  -moz-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.hidden {
  display: none !important;
}

#installContainer {
  position: absolute;
  bottom: 1em;
  display: flex;
  justify-content: center;
  width: 100%;
}

#installContainer button {
  background-color: inherit;
  border: 1px solid white;
  color: white;
  font-size: 1em;
  padding: 0.75em;
}

button {
  outline: none;
}

</style>
