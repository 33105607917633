<script type="text/javascript">

import axios from "axios";

function getRequestWithFullUrl(url, success, error) {
  axios.get(url, {
    headers: {
      'content-type': 'application/json',
      'accept' : '*/*'
    }
  }).then((res) => {
    success(res.data);
  }).catch((e) => {
    if (error) {
      error(e);
    }
  });
}

function chains(success, error) {
  getRequestWithFullUrl('https://api.llama.fi/chains', function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function charts(success, error) {
  getRequestWithFullUrl('https://api.llama.fi/charts', function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function pools(success, error) {
  getRequestWithFullUrl('https://yields.llama.fi/pools', function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

export default {
  chains,
  charts,
  pools
}


</script>