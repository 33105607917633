<template>
  <div>
    <div style="margin: auto">
      <el-card class="black-card" style="border-radius: 3px 3px 0 0;border-bottom: 0;background-color: #1c2128" body-style="padding: 10px" shadow="none">
        <el-row>
          <el-col :span="12">
            <span class="dark-mode-font" style="color: white;display: inline-block;float: left">ABM token holder info</span>
          </el-col>
          <el-col :span="12">
            <span class="dark-mode-font" style="color: white;display: inline-block;float: right">0x9862f8d8BA03542C14C064199A6FFb1d5895c3E7</span>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="black-card" style="border-radius: 0;height: 28px;background-color: #1c2128" shadow="none" body-style="padding: 4px 10px 3px 10px">
        <el-row>
          <el-col :span="12">
            <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">Address</span>
          </el-col>
          <el-col :span="6">
            <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">Amount</span>
          </el-col>
          <el-col :span="6">
            <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">Rate(%)</span>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="black-card-hover" shadow="none" style=";border: 0;border-radius: 0" body-style="padding:0" v-for="(p, index) in holderInfoList" :key="index">
        <el-row style="height: 25px;border-bottom: 1px solid #444c56;border-left: 1px solid #444c56;border-right: 1px solid #444c56;padding: 3px 10px 3px 10px">
          <el-col :span="12">
            <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{ p['holderAddress'] }}</span>
          </el-col>
          <el-col :span="6">
            <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{ Number.prototype.toFormatNum(Number.prototype.accDiv(Number(p['amount']), Math.pow(10, 18))) }}</span>
          </el-col>
          <el-col :span="6">
            <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{ getRate(Number(p['amount'])) }}%</span>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <div style="margin: 15px auto auto;">
      <el-row>
        <el-col :span="16">
          <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 12px;color: gray">Mint ABM token amount</span>
        </el-col>
      </el-row>
      <el-row style="margin-top: 5px">
        <el-row>
          <el-col :span="21">
            <input class="trialpha-input" v-model="mint.amount" style="border-radius: 3px 0 0 3px"/>
          </el-col>
          <el-col :span="3">
            <el-button class="dark-mode-btn" style="height: 28px;line-height: 28px;padding: 0 10px;font-size: 12px;float: right;width: 100%;border-radius: 0 3px 3px 0;border-left: 0" :loading="mint.loading" @click="doMint">Mint</el-button>
          </el-col>
        </el-row>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col :span="16">
          <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 12px;color: gray">Target address</span>
        </el-col>
        <el-col :span="5">
          <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 12px;color: gray">Transfer amount</span>
        </el-col>
      </el-row>
      <el-row style="margin-top: 5px">
        <el-row>
          <el-col :span="16">
            <input class="trialpha-input" v-model="transfer.address" style="border-radius: 3px 0 0 3px"/>
          </el-col>
          <el-col :span="5">
            <input class="trialpha-input" v-model="transfer.amount" style="border-radius: 0;border-left: 0"/>
          </el-col>
          <el-col :span="3">
            <el-button class="dark-mode-btn" style="height: 28px;line-height: 28px;padding: 0 10px;font-size: 12px;float: right;width: 100%;border-radius: 0 3px 3px 0;border-left: 0" :loading="transfer.loading" @click="doTransfer">Send</el-button>
          </el-col>
        </el-row>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col :span="16">
          <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 12px;color: gray">New whitelist address</span>
        </el-col>
      </el-row>
      <el-row style="margin-top: 5px">
        <el-row>
          <el-col :span="21">
            <input class="trialpha-input" v-model="addWL.address" style="border-radius: 3px 0 0 3px"/>
          </el-col>
          <el-col :span="3">
            <el-button class="dark-mode-btn" style="height: 28px;line-height: 28px;padding: 0 10px;font-size: 12px;float: right;width: 100%;border-radius: 0 3px 3px 0;border-left: 0" :loading="addWL.loading" @click="doAddWL">Add</el-button>
          </el-col>
        </el-row>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col :span="16">
          <span class="dark-mode-font" style="display: inline-block;float: left;font-size: 12px;color: gray">Address to be removed</span>
        </el-col>
      </el-row>
      <el-row style="margin-top: 5px">
        <el-row>
          <el-col :span="21">
            <input class="trialpha-input" v-model="removeWL.address" style="border-radius: 3px 0 0 3px"/>
          </el-col>
          <el-col :span="3">
            <el-button class="dark-mode-btn" style="height: 28px;line-height: 28px;padding: 0 10px;font-size: 12px;float: right;width: 100%;border-radius: 0 3px 3px 0;border-left: 0" :loading="removeWL.loading" @click="doRemoveWL">Remove</el-button>
          </el-col>
        </el-row>
      </el-row>
    </div>
  </div>
</template>

<script>
import AbmTokenContract from "@/web3/contract/AbmTokenContract";
import Web3Service from "@/web3/Web3Service";
import {Message} from "element-ui";

export default {
  name: "AbmTokenMgnt",
  data: function () {
    return {
      holderInfoList: [],
      mint: {
        amount: 0,
        loading: false
      },
      transfer: {
        address: '',
        amount: 0,
        loading: false
      },
      addWL: {
        address: '',
        loading: false
      },
      removeWL: {
        address: '',
        loading: false
      }
    }
  },
  created() {
    this.initHolderInfor();
  },
  methods: {
    initHolderInfor: function () {
      let that = this;
      let abmTokenContract = AbmTokenContract.getContract(this);
      abmTokenContract.methods.getAllHoldersAddressMapping().call().then((res) => {
        that.holderInfoList = res;
      });
    },
    getRate(amount) {
      let totalAmount = 0;
      this.holderInfoList.forEach((info) => {
        totalAmount = Number.prototype.accAdd(totalAmount, info.amount);
      });
      return ((amount / totalAmount) * 100).toFixed(2);
    },
    doMint() {
      let that = this;
      if (that.mint.amount <= 0) {
        Message.warning('Mint amount must more than 0');
        return;
      }
      let web3 = Web3Service.getWeb3(this);
      let abmTokenContract = AbmTokenContract.getContract(this);
      that.mint.loading = true;
      abmTokenContract.methods.mint(web3.currentProvider.selectedAddress, web3.utils.toWei(that.mint.amount + '')).send({from: web3.currentProvider.selectedAddress}).on("receipt", function(receipt) {
        Message.success('Mint success, transaction hash is ' + receipt.transactionHash);
        that.mint.loading = false;
        that.initHolderInfor();
      }).on("error", function(error) {
        Message.error(error.message);
        that.mint.loading = false;
      });
    },
    doTransfer() {
      let that = this;
      if (that.transfer.amount <= 0) {
        Message.warning('Transfer amount must more than 0');
        return;
      }
      let web3 = Web3Service.getWeb3(this);
      let abmTokenContract = AbmTokenContract.getContract(this);
      that.transfer.loading = true;
      abmTokenContract.methods.transfer(that.transfer.address,  web3.utils.toWei(that.transfer.amount + '')).send({from: web3.currentProvider.selectedAddress}).on("receipt", function(receipt) {
        Message.success('Transfer success, transaction hash is ' + receipt.transactionHash);
        that.transfer.loading = false;
        that.initHolderInfor();
      }).on("error", function(error) {
        Message.error(error.message);
        that.transfer.loading = false;
      });
    },
    doAddWL() {
      let that = this;
      if (that.addWL.address.length <= 0) {
        Message.warning('Address is invalid');
        return;
      }
      let web3 = Web3Service.getWeb3(this);
      let abmTokenContract = AbmTokenContract.getContract(this);
      that.addWL.loading = true;
      abmTokenContract.methods.addToWhitelist(that.addWL.address).send({from: web3.currentProvider.selectedAddress}).on("receipt", function(receipt) {
        Message.success('Add ' + that.addWL.address + ' to whitelist success, transaction hash is ' + receipt.transactionHash);
        that.addWL.loading = false;
        that.initHolderInfor();
      }).on("error", function(error) {
        Message.error(error.message);
        that.addWL.loading = false;
      });
    },
    doRemoveWL() {
      let that = this;
      if (that.removeWL.address.length <= 0) {
        Message.warning('Address is invalid');
        return;
      }
      let web3 = Web3Service.getWeb3(this);
      let abmTokenContract = AbmTokenContract.getContract(this);
      that.removeWL.loading = true;
      abmTokenContract.methods.removeToWhitelist(that.removeWL.address).send({from: web3.currentProvider.selectedAddress}).on("receipt", function(receipt) {
        Message.success('Remove ' + that.removeWL.address + ' from whitelist success, transaction hash is ' + receipt.transactionHash);
        that.removeWL.loading = false;
        that.initHolderInfor();
      }).on("error", function(error) {
        Message.error(error.message);
        that.removeWL.loading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>