<template>
  <div :dto="dto">
    <el-skeleton animated :loading="rendering" :throttle="268">
      <template slot="template">
        <trialpha-sk style="margin-top: 0;width: 60%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 40%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 100%;float: left"></trialpha-sk>
      </template>
      <template>
        <el-row>
          <el-collapse class="trialpha-collapse" v-model="activeNames" style="border: 0">
            <el-collapse-item class="trialpha-collapse" style="border: 0" :name="ins.strategyConfig.platform"
                              v-for="ins in instanceList" :key="ins.strategyConfig.platform">
              <template slot="title">
                <el-row style="width: 100%">
                  <el-col :span="4" style="padding: 0">
                    <el-card class="black-card" body-style="padding: 0"
                             style="line-height: 46px;margin-left: -10px;margin-top: 0;border-left: 0;border-top: 0;border-bottom: 0;border-radius: 0;height: 46px;width: 46px;background-color: #444c56">
                      <img :src="exchangeInfo[ins.strategyConfig.platform].logo" style="width: 18px;height: 18px;margin-top: 15px;margin-left: 12px;float: left;" />
                    </el-card>
                  </el-col>
                  <el-col :span="20">
                  </el-col>
                </el-row>
              </template>
              <el-row style="height: 368px;overflow-y: auto">
                <el-row style="width: 98%;margin-top: 10px;margin-left: auto;margin-right: auto;">
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">实例状态</span>
                  </el-col>
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">单币种持有美元价值</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <el-select class="select-symbol" style="width: 100%" v-model="ins.isActive" placeholder="请选择">
                      <el-option
                          v-for="item in [{value: true, label: 'Active'}, {value: false, label: 'Inactive'}]"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="12" :offset="1">
                    <el-input class="trialpha-dark-input trialpha-dark-input-para" placeholder="" v-model="ins.strategyConfig.keepUsd"></el-input>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">成交次数</span>
                  </el-col>
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">累计成交额</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <el-input class="trialpha-dark-input trialpha-dark-input-para" placeholder=""
                              v-model="ins.strategyConfig.changeTimes" disabled>
                    </el-input>
                  </el-col>
                  <el-col :span="12" :offset="1">
                    <el-input class="trialpha-dark-input trialpha-dark-input-para" placeholder=""
                              v-model="ins.strategyConfig.tradeVol" disabled>
                    </el-input>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">排除购买的币种</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="24">
                    <el-input class="trialpha-dark-input trialpha-dark-input-para" placeholder="" v-model="ins.strategyConfig.excludeSymbols">
                    </el-input>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">交易异常的币种</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="24">
                    <el-input class="trialpha-dark-input trialpha-dark-input-para" placeholder="" v-model="ins.strategyConfig.errorSymbols">
                    </el-input>
                  </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row style="width: 98%;margin: 10px auto">
                  <el-col :span="8">
                    <el-button class="dark-mode-btn"
                               style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0;border-right: 0"
                               @click="reset(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i
                        class="el-icon-c-scale-to-original" style="font-size: 16px"/></el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button class="dark-mode-btn"
                               style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0"
                               @click="deleteIns(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i
                        class="el-icon-delete" style="font-size: 16px"/></el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button class="dark-mode-btn"
                               style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0 3px 3px 0;border-left: 0"
                               @click="save(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i
                        class="el-icon-check" style="font-size: 16px"/></el-button>
                  </el-col>
                </el-row>
              </el-row>
            </el-collapse-item>
          </el-collapse>
          <el-collapse class="trialpha-collapse" v-model="createCollapse" style="border-top: 0">
            <el-collapse-item class="trialpha-collapse">
              <template slot="title">
                <i class="el-icon-menu" style="margin-left: 2px;color: #0ecb81;margin-top: 1px"/><h4
                  class="dark-mode-font" style="margin-left: 8px;padding-top: 6px;color: white">Create New Instance</h4>
              </template>
              <el-row style="width: 98%;margin-top: 15px;margin-left: auto;margin-right: auto;">
                <el-col :span="11">
                  <span class="dark-mode-font" style="color: white;font-size: 12px">选择平台</span>
                </el-col>
                <el-col :span="12" :offset="1">
                  <span class="dark-mode-font" style="color: white;font-size: 12px">单币种持有美元价值</span>
                </el-col>
              </el-row>
              <el-row style="width: 98%;margin: 5px auto 0 auto">
                <el-col :span="11" style="padding-right: 8px">
                  <el-select class="chose-symbol select-symbol" filterable v-model="instance.platform" placeholder="Platform" style="width: 100%;line-height: 35px">
                    <el-option
                        v-for="item in Object.keys(platforms)"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="12" :offset="1" style="padding-right: 0">
                  <el-input class="trialpha-dark-input-no-pre" v-model="instance.keepUsd"/>
                </el-col>
              </el-row>
              <el-row style="width: 98%;margin-top: 15px;margin-left: auto;margin-right: auto;">
                <el-col :span="11">
                  <span class="dark-mode-font" style="color: white;font-size: 12px">选择基准货币</span>
                </el-col>
                <el-col :span="12" :offset="1">
                  <span class="dark-mode-font" style="color: white;font-size: 12px">排除购买的币种</span>
                </el-col>
              </el-row>
              <el-row style="width: 98%;margin: 5px auto 0 auto">
                <el-col :span="11" style="padding-right: 8px">
                  <el-select class="chose-symbol select-symbol" filterable v-model="instance.basicCoin" placeholder="Platform" style="width: 100%;line-height: 35px">
                    <el-option
                        v-for="item in ['USDT', 'BUSD', 'USDC']"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="12" :offset="1" style="padding-right: 0">
                  <el-input class="trialpha-dark-input-no-pre" v-model="instance.excludeSymbols"/>
                </el-col>
              </el-row>
              <el-row style="height: 88px;overflow-y: auto">
                <el-divider></el-divider>
                <el-row style="width: 98%;margin: 10px auto">
                  <el-col :span="24">
                    <el-button class="dark-mode-btn"
                               style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0 3px 3px 0"
                               @click="add(instance)" :disabled="rendering" :loading="processing" v-auth:dbWriteApi><i
                        class="el-icon-check" style="font-size: 16px"/></el-button>
                  </el-col>
                </el-row>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-row>
      </template>
    </el-skeleton>
  </div>
</template>

<script>
import {Message} from "element-ui";
import StrategyInstanceService from "@/api/trade/StrategyInstanceService";
import ConfirmProvider from "@/components/ConfirmProvider";
import TrialphaSk from "@/components/TrialphaSk";
import GeneralApiService from "@/api/trade/GeneralApiService";
import SymbolInfoService from "@/utils/SymbolInfoService";
import ExchangeInformations from "@/const/ExchangeInformations";

export default {
  name: "AllSpotMarket",
  components: {TrialphaSk},
  props: {
    dto: Object
  },
  data: function () {
    return {
      exchangeInfo: {
        BINANCE: {
          logo: ExchangeInformations.exchangeInfos.BINANCE.icon,
          fontColor: '#f5bc2f'
        },
        BITMEX: {
          logo: ExchangeInformations.exchangeInfos.BITMEX.icon,
          fontColor: '#5b69b1'
        },
        DERIBIT: {
          logo: ExchangeInformations.exchangeInfos.DERIBIT.icon,
          fontColor: '#04cfbe'
        },
        OKX: {
          logo: ExchangeInformations.exchangeInfos.OKX.icon,
          fontColor: '#67ccdf'
        },
        BYBIT: {
          logo: ExchangeInformations.exchangeInfos.BYBIT.icon,
          fontColor: '#67ccdf'
        },
        DYDX: {
          logo: ExchangeInformations.exchangeInfos.DYDX.icon,
          fontColor: '#67ccdf'
        }
      },
      platforms: GeneralApiService.PLATFORM,
      symbolTypes: ['SPOT', 'USD_CONTRACT', 'COIN_CONTRACT', 'OPTIONS'],
      processing: false,
      rendering: true,
      symbolArrays: [],
      activeNames: ['1'],
      createCollapse: ['1'],
      instance: {},
      instanceList: [],
      accountInstance: {},
      allPrice: {},
      allSymbols: [],
      timeGapArray: [
        {value: 60, label: '1 min'},
        {value: 300, label: '5 mins'},
        {value: 600, label: '10 mins '},
        {value: 900, label: '15 mins'},
        {value: 1800, label: '30 mins'},
        {value: 3600, label: '1 hour'},
        {value: 7200, label: '2 hours'},
        {value: 21600, label: '6 hours'},
        {value: 43200, label: '12 hours'},
        {value: 86400, label: '1 day'},
        {value: 172800, label: '2 days'},
        {value: 259200, label: '3 days'},
        {value: 345600, label: '4 days'},
        {value: 432000, label: '5 days'},
        {value: 518400, label: '6 days'},
        {value: 604800, label: '7 days'}
      ]
    }
  },
  created() {

  },
  methods: {
    getPrice(instance) {
      return SymbolInfoService.getSymbolPrice(instance.platform, instance.symbolType, instance.symbol);
    },
    syncSymbolList(instance) {
      this.symbolArrays = SymbolInfoService.getSymbolList(instance.platform, instance.symbolType);
    },
    openTradeTools(ins) {
      this.$nextTick(function () {
        this.$refs['open-tools'].renderFtx(ins);
      });
    },
    handleShortLong(ins) {
      ins.symbol1Short = !ins.symbol1Short;
    },
    render: function (accountInstance) {
      let that = this;
      that.rendering = true;
      that.accountInstance = accountInstance;
      that.instance = JSON.parse(JSON.stringify(that.dto.strategyConfig));
      StrategyInstanceService.query(that.accountInstance.instanceId, that.dto.strategyName, function (data) {
        that.instanceList = data.strategyInsList;
        that.rendering = false;
      }, function () {
        that.rendering = false;
      });
    },
    reset: function (ins) {
      let that = this;
      ConfirmProvider.openConfirm(this, '确认重置', '该操作将会重置所有非配置的数据', function () {
        that.processing = true;
        StrategyInstanceService.reset(ins.sid, function () {
          that.processing = false;
          Message.success('已成功重置');
          that.render(that.accountInstance);
        }, function () {
          that.processing = false;
        });
      });
    },
    deleteIns: function (ins) {
      let that = this;
      ConfirmProvider.openConfirm(this, '确认删除', '该操作将会删除该实例，此操作无法恢复', function () {
        that.processing = true;
        StrategyInstanceService.deleteIns(ins.sid, function () {
          that.processing = false;
          Message.success('已成功删除');
          that.render(that.accountInstance);
        }, function () {
          that.processing = false;
        });
      });
    },
    save: function (ins) {
      let that = this;
      that.processing = true;
      StrategyInstanceService.update({
        sid: ins.sid,
        strategyName: ins.strategyName,
        config: (ins.strategyConfig),
        instanceEntity: {
          id: that.accountInstance.instanceId
        },
        isActive: ins.isActive
      }, function () {
        that.processing = false;
        Message.success('已成功更新');
        that.render(that.accountInstance);
      }, function () {
        that.processing = false;
      });
    },
    add: function (ins) {
      let that = this;
      that.processing = true;
      StrategyInstanceService.create({
        insId: this.accountInstance.instanceId,
        strategyName: this.dto.strategyName,
        config: JSON.stringify(ins)
      }, function () {
        Message.success('已成功创建新的实例');
        that.processing = false;
        setTimeout(() => that.render(that.accountInstance), 888);
      }, function () {
        that.processing = false;
      });
    }
  }
}
</script>

<style scoped>

.ellipsis-line {
  padding: 8px;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-collapse {
  color: #FFFFFF;
  border-top: 1px solid #444c56;
  border-bottom: 1px solid #444c56;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: white;
}

.el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-divider {
  border-top: 1px solid #444c56;
}

.el-divider.info {
  width: 80%;
  border-top: 1px solid #444c56;
}

.el-divider__text {
  color: white;
  background-color: #444c56;
}

.select-symbol .el-input__inner {
  color: white;
  height: 35px !important;
  line-height: 35px;
  width: 100%;
  padding: 0 10px 0 10px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-right: 0;
  border-radius: 0;
  font-size: 12px;
  outline: none;
}

.select-symbol.el-select .el-input.is-focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:hover .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:hover .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:visited .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:visited .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

</style>