<template>
  <el-row style="width: 100%">
    <el-col class="show-than-820" :span="24" style="display: flex">
      <el-card class="black-card" style="width: 386px;height: 268px;border: 0" shadow="none">
        <el-row>
          <el-col :span="14">
            <span class="dark-mode-font" style="color: #FFFFFF;display: inline-block;float: left">
              {{ $i('instanceStatics.AssetDollarRatio') }}
            </span>
          </el-col>
        </el-row>
        <el-row>
          <div id="instanceNum" ref="instanceNum" style="width: 100%;height: 240px;margin-top: -8px;"></div>
        </el-row>
      </el-card>
      <el-card class="black-card show-more-1240" style="flex: 1;width: 100%;height: 268px;margin-left: 20px;border: 0" shadow="none">
        <el-row>
          <el-col :span="14">
            <span class="dark-mode-font" style="color: #FFFFFF;display: inline-block;float: left">
              {{ $i('instanceStatics.accountStatistics') }}
            </span>
          </el-col>
          <el-col :span="10" style="padding-left: 20px">
            <span class="dark-mode-font" style="color: #FFFFFF;display: inline-block;float: left">
              {{ $i('instanceStatics.exchangeDollarAssets') }}
            </span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14">
            <el-card class="black-card" body-style="padding: 0" shadow="none"
                     style="width: 100%;border-radius: 5px 5px 0 0;border-bottom: 0;height: 25px;line-height: 25px;margin-top: 15px;background-color: #30353c">
              <el-row>
                <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">{{ $i('instanceStatics.exchange') }}</span>
                </el-col>
                <el-col :span="4" :offset="3"><span class="dark-mode-font ellipsis-line-strategy"
                                                    style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">{{ $i('instanceStatics.count') }}</span>
                </el-col>
                <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">USD</span>
                </el-col>
                <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">BTC</span>
                </el-col>
                <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">ETH</span>
                </el-col>
              </el-row>
            </el-card>
            <el-card class="black-card" body-style="padding: 0"
                     style="width: 100%;height: 138px;overflow-y: auto;border-radius: 0" shadow="none">
              <el-skeleton animated :loading="renderingInstance" :throttle="200">
                <template slot="template">
                  <trialpha-sk
                      :style="'margin-left: 15px;margin-top: 20px;width: 60%;float: left'"></trialpha-sk>
                  <trialpha-sk
                      :style="'margin-left: 15px;margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                  <trialpha-sk
                      :style="'margin-left: 15px;margin-top: 20px;width: 90%;float: left'"></trialpha-sk>
                  <trialpha-sk
                      :style="'margin-left: 15px;margin-top: 20px;width: 40%;float: left'"></trialpha-sk>
                  <trialpha-sk
                      :style="'margin-left: 15px;margin-top: 20px;width: 95%;float: left'"></trialpha-sk>
                </template>
                <template>
                  <el-card class="black-card-hover" body-style="padding: 0" v-for="(item, index) in statistics"
                           :key="index"
                           style="border-radius: 0;border-bottom: 1px solid #444c56;border-top: 0;height: 28px;line-height: 28px;border-left: 0;border-right: 0">
                    <el-row>
                      <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                              style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;">{{
                          item.PLATFORM
                        }}</span></el-col>
                      <el-col :span="4" :offset="3"><span class="dark-mode-font ellipsis-line-strategy"
                                                          style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;">{{
                          item.COUNT
                        }}</span></el-col>
                      <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy"
                                              style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px">{{
                          item.USD.toFixed(2)
                        }}</span></el-col>
                      <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                              style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;">{{
                          item.BTC.toFixed(4)
                        }}</span></el-col>
                      <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                              style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;">{{
                          item.ETH.toFixed(3)
                        }}</span></el-col>
                    </el-row>
                  </el-card>
                </template>
              </el-skeleton>
            </el-card>
            <el-card class="black-card" body-style="padding: 0"
                     style="width: 100%;border-radius: 0 0 5px 5px;border-top: 0;height: 25px;line-height: 25px;margin-top: 0">
              <el-row>
                <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 8px;color: #FFFFFF">{{ $i('instanceStatics.total') }}</span>
                </el-col>
                <el-col :span="4" :offset="3"><span class="dark-mode-font ellipsis-line-strategy"
                                                    style="font-size: 12px;text-align: left;display: inline-block;float: left;color: #FFFFFF;margin-left: 8px">{{
                    totalCount
                  }}</span></el-col>
                <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;text-align: left;display: inline-block;float: left;color: #FFFFFF;margin-left: 8px">{{
                    totalUsd.toFixed(2)
                  }}</span></el-col>
                <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;text-align: left;display: inline-block;float: left;color: #FFFFFF;margin-left: 8px">{{
                    totalBtc.toFixed(4)
                  }}</span></el-col>
                <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;text-align: left;display: inline-block;float: left;color: #FFFFFF;margin-left: 8px">{{
                    totalEth.toFixed(3)
                  }}</span></el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="10" style="padding-left: 20px">
            <div id="exchangeV" ref="exchangeV" style="width: 100%;height: 240px;margin-top: -8px;"></div>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="black-card show-less-1240" style="flex: 1;width: 100%;height: 268px;margin-left: 20px;border: 0" shadow="none">
        <el-row>
          <el-col :span="14">
            <span class="dark-mode-font" style="color: #FFFFFF;display: inline-block;float: left">
              {{ $i('instanceStatics.accountStatistics') }}
            </span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-card class="black-card" body-style="padding: 0" shadow="none"
                     style="width: 100%;border-radius: 5px 5px 0 0;border-bottom: 0;height: 25px;line-height: 25px;margin-top: 15px;background-color: #30353c">
              <el-row>
                <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">{{ $i('instanceStatics.exchange') }}</span>
                </el-col>
                <el-col :span="4" :offset="3"><span class="dark-mode-font ellipsis-line-strategy"
                                                    style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">{{ $i('instanceStatics.count') }}</span>
                </el-col>
                <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">USD</span>
                </el-col>
                <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">BTC</span>
                </el-col>
                <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">ETH</span>
                </el-col>
              </el-row>
            </el-card>
            <el-card class="black-card" body-style="padding: 0"
                     style="width: 100%;height: 138px;overflow-y: auto;border-radius: 0" shadow="none">
              <el-skeleton animated :loading="renderingInstance" :throttle="200">
                <template slot="template">
                  <trialpha-sk
                      :style="'margin-left: 15px;margin-top: 20px;width: 60%;float: left'"></trialpha-sk>
                  <trialpha-sk
                      :style="'margin-left: 15px;margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                  <trialpha-sk
                      :style="'margin-left: 15px;margin-top: 20px;width: 90%;float: left'"></trialpha-sk>
                  <trialpha-sk
                      :style="'margin-left: 15px;margin-top: 20px;width: 40%;float: left'"></trialpha-sk>
                  <trialpha-sk
                      :style="'margin-left: 15px;margin-top: 20px;width: 95%;float: left'"></trialpha-sk>
                </template>
                <template>
                  <el-card class="black-card-hover" body-style="padding: 0" v-for="(item, index) in statistics"
                           :key="index"
                           style="border-radius: 0;border-bottom: 1px solid #444c56;border-top: 0;height: 28px;line-height: 28px;border-left: 0;border-right: 0">
                    <el-row>
                      <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                              style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;">{{
                          item.PLATFORM
                        }}</span></el-col>
                      <el-col :span="4" :offset="3"><span class="dark-mode-font ellipsis-line-strategy"
                                                          style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;">{{
                          item.COUNT
                        }}</span></el-col>
                      <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy"
                                              style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px">{{
                          item.USD.toFixed(2)
                        }}</span></el-col>
                      <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                              style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;">{{
                          item.BTC.toFixed(4)
                        }}</span></el-col>
                      <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                              style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;">{{
                          item.ETH.toFixed(3)
                        }}</span></el-col>
                    </el-row>
                  </el-card>
                </template>
              </el-skeleton>
            </el-card>
            <el-card class="black-card" body-style="padding: 0"
                     style="width: 100%;border-radius: 0 0 5px 5px;border-top: 0;height: 25px;line-height: 25px;margin-top: 0">
              <el-row>
                <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 8px;color: #FFFFFF">{{ $i('instanceStatics.total') }}</span>
                </el-col>
                <el-col :span="4" :offset="3"><span class="dark-mode-font ellipsis-line-strategy"
                                                    style="font-size: 12px;text-align: left;display: inline-block;float: left;color: #FFFFFF;margin-left: 8px">{{
                    totalCount
                  }}</span></el-col>
                <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;text-align: left;display: inline-block;float: left;color: #FFFFFF;margin-left: 8px">{{
                    totalUsd.toFixed(2)
                  }}</span></el-col>
                <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;text-align: left;display: inline-block;float: left;color: #FFFFFF;margin-left: 8px">{{
                    totalBtc.toFixed(4)
                  }}</span></el-col>
                <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                        style="font-size: 12px;text-align: left;display: inline-block;float: left;color: #FFFFFF;margin-left: 8px">{{
                    totalEth.toFixed(3)
                  }}</span></el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
      </el-card>
    </el-col>
    <el-col class="show-less-820" :span="24">
      <el-row>
        <el-card class="black-card" style="width: 100%;height: 268px;border: 0" shadow="none">
          <el-row>
            <span class="dark-mode-font" style="color: #FFFFFF;display: inline-block;float: left">
              {{ $i('instanceStatics.AssetDollarRatio') }}
            </span>
          </el-row>
          <el-row>
            <div id="instanceNumM" ref="instanceNumM" style="width: 100%;height: 240px;margin-top: -5px"></div>
          </el-row>
        </el-card>
      </el-row>
      <el-row>
        <el-card class="black-card" style="flex: 1;width: 100%;height: 268px;margin-top: 20px;border: 0" shadow="none">
          <el-row>
            <el-col :span="14">
            <span class="dark-mode-font" style="color: #FFFFFF;display: inline-block;float: left">
              {{ $i('instanceStatics.accountStatistics') }}
            </span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-card class="black-card" body-style="padding: 0" shadow="none"
                       style="width: 100%;border-radius: 5px 5px 0 0;border-bottom: 0;height: 25px;line-height: 25px;margin-top: 15px;background-color: #30353c">
                <el-row>
                  <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                          style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">{{ $i('instanceStatics.exchange') }}</span>
                  </el-col>
                  <el-col :span="4" :offset="3"><span class="dark-mode-font ellipsis-line-strategy"
                                                      style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">{{ $i('instanceStatics.count') }}</span>
                  </el-col>
                  <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy"
                                          style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">USD</span>
                  </el-col>
                  <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                          style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">BTC</span>
                  </el-col>
                  <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                          style="font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;color: #FFFFFF">ETH</span>
                  </el-col>
                </el-row>
              </el-card>
              <el-card class="black-card" body-style="padding: 0"
                       style="width: 100%;height: 138px;overflow-y: auto;border-radius: 0" shadow="none">
                <el-skeleton animated :loading="renderingInstance" :throttle="200">
                  <template slot="template">
                    <trialpha-sk
                        :style="'margin-left: 15px;margin-top: 20px;width: 60%;float: left'"></trialpha-sk>
                    <trialpha-sk
                        :style="'margin-left: 15px;margin-top: 20px;width: 80%;float: left'"></trialpha-sk>
                    <trialpha-sk
                        :style="'margin-left: 15px;margin-top: 20px;width: 90%;float: left'"></trialpha-sk>
                    <trialpha-sk
                        :style="'margin-left: 15px;margin-top: 20px;width: 40%;float: left'"></trialpha-sk>
                    <trialpha-sk
                        :style="'margin-left: 15px;margin-top: 20px;width: 95%;float: left'"></trialpha-sk>
                  </template>
                  <template>
                    <el-card class="black-card-hover" body-style="padding: 0" v-for="(item, index) in statistics"
                             :key="index"
                             style="border-radius: 0;border-bottom: 1px solid #444c56;border-top: 0;height: 28px;line-height: 28px;border-left: 0;border-right: 0">
                      <el-row>
                        <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                                style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;">{{
                            item.PLATFORM
                          }}</span></el-col>
                        <el-col :span="4" :offset="3"><span class="dark-mode-font ellipsis-line-strategy"
                                                            style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;">{{
                            item.COUNT
                          }}</span></el-col>
                        <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy"
                                                style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px">{{
                            item.USD.toFixed(2)
                          }}</span></el-col>
                        <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                                style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;">{{
                            item.BTC.toFixed(4)
                          }}</span></el-col>
                        <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                                style="color: white;font-size: 12px;display: inline-block;text-align: left;float: left;margin-left: 8px;">{{
                            item.ETH.toFixed(3)
                          }}</span></el-col>
                      </el-row>
                    </el-card>
                  </template>
                </el-skeleton>
              </el-card>
              <el-card class="black-card" body-style="padding: 0"
                       style="width: 100%;border-radius: 0 0 5px 5px;border-top: 0;height: 25px;line-height: 25px;margin-top: 0">
                <el-row>
                  <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                          style="font-size: 12px;text-align: left;display: inline-block;float: left;margin-left: 8px;color: #FFFFFF">{{ $i('instanceStatics.total') }}</span>
                  </el-col>
                  <el-col :span="4" :offset="3"><span class="dark-mode-font ellipsis-line-strategy"
                                                      style="font-size: 12px;text-align: left;display: inline-block;float: left;color: #FFFFFF;margin-left: 8px">{{
                      totalCount
                    }}</span></el-col>
                  <el-col :span="5"><span class="dark-mode-font ellipsis-line-strategy"
                                          style="font-size: 12px;text-align: left;display: inline-block;float: left;color: #FFFFFF;margin-left: 8px">{{
                      totalUsd.toFixed(2)
                    }}</span></el-col>
                  <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                          style="font-size: 12px;text-align: left;display: inline-block;float: left;color: #FFFFFF;margin-left: 8px">{{
                      totalBtc.toFixed(4)
                    }}</span></el-col>
                  <el-col :span="4"><span class="dark-mode-font ellipsis-line-strategy"
                                          style="font-size: 12px;text-align: left;display: inline-block;float: left;color: #FFFFFF;margin-left: 8px">{{
                      totalEth.toFixed(3)
                    }}</span></el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-row>
    </el-col>
    <el-col class="show-less-1240" :span="24">
      <el-row>
        <el-card class="black-card" style="flex: 1;width: 100%;height: 268px;margin-top: 20px;border: 0" shadow="none">
          <el-row>
            <el-col :span="24" style="">
            <span class="dark-mode-font" style="color: #FFFFFF;display: inline-block;float: left">
              {{ $i('instanceStatics.exchangeDollarAssets') }}
            </span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" style="">
              <div id="exchangeVM" ref="exchangeVM" style="width: 100%;height: 240px;margin-top: -8px;"></div>
            </el-col>
          </el-row>
        </el-card>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>

// import InstanceService from "@/api/trade/InstanceService";

import TrialphaSk from "@/components/TrialphaSk";
export default {
  name: "InstanceStatics",
  components: {TrialphaSk},
  data: function () {
    return {
      renderingInstance: true,
      statistics: [],
      totalCount: 0,
      totalUsd: 0,
      totalBtc: 0,
      totalEth: 0,
      ethUsd: 0,
      btcUsd: 0
    }
  },
  created() {
    // this.$nextTick(() => {
    //   this.loadInstance();
    // })
  },
  methods: {
    activePanel(instanceData) {
      this.$nextTick(() => {
        this.loadInstance(instanceData);
      })
    },
    getOption() {
      return {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          top: 15,
          textStyle: {
            fontFamily: 'DIN-Bold',
            fontSize: 12,
            color: 'white',
            padding: [4, 0, 0, 0]
          }
        },
        grid: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 0
        },
        series: [
          {
            name: 'Asset Dollar Ratio',
            type: 'pie',
            radius: ['50%', '68%'],
            center: ['55%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: true,
              textStyle: {
                fontFamily: 'DIN-Bold',
                fontSize: 12
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '12',
                fontWeight: 'bold'
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            labelLine: {
              show: true
            },
            data: [
              {value: this.totalUsd.toFixed(2), name: 'USD', itemStyle: {color: 'rgba(55,164,215,0.93)'}},
              {value: this.btcUsd.toFixed(4), name: 'BTC', itemStyle: {color: 'rgba(247,147,26, 0.86)'}},
              {value: this.ethUsd.toFixed(3), name: 'ETH', itemStyle: {color: 'rgba(104, 126, 227, 0.86)'}}
            ]
          }
        ]
      }
    },
    getBarOption(x, y) {
      let max = Math.max(...y) * 1.268;
      let series = [{
        showSymbol: true,
        name: 'USD Value',
        type: 'bar',
        data: y,
        smooth: true,
        showBackground: true,
        barWidth: 13,
        itemStyle: {
          color: 'rgba(18, 177, 250, 0.5)'
        },
        backgroundStyle: {
          color: 'rgba(18, 177, 250, 0.05)'
        }
      }];
      return {
        tooltip: {
          trigger: 'axis'
        },
        calculable: true,
        xAxis: [{
          type: 'category',
          boundaryGap: true,
          show: true,
          data: x,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: 8, //更改坐标轴文字大小
              color: 'white'
            }
          },
        }],
        grid: {
          x: 0,
          y: 25,
          x2: 50,
          y2: 40
        },
        yAxis: [{
          type: 'value',
          position: 'right',
          show: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          max: max.toFixed(0),
          splitLine: {
            show: false,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: 'white', //更改坐标轴文字颜色
              fontSize: 8 //更改坐标轴文字大小
            }
          },
        }],
        series: series
      };
    },
    render(xBar, yBar) {
      let that = this;
      if (that.$refs.instanceNum) {
        that.chart = that.$echarts.init(that.$refs.instanceNum);
        that.chart.clear();
        that.chart.setOption(that.getOption());
      }
      if (that.$refs.instanceNumM) {
        that.chartM = that.$echarts.init(that.$refs.instanceNumM);
        that.chartM.clear();
        that.chartM.setOption(that.getOption());
      }
      if (that.$refs.exchangeV) {
        that.chartE = that.$echarts.init(that.$refs.exchangeV);
        that.chartE.clear();
        that.chartE.setOption(that.getBarOption(xBar, yBar));
      }
      if (that.$refs.exchangeVM) {
        that.chartEM = that.$echarts.init(that.$refs.exchangeVM);
        that.chartEM.clear();
        that.chartEM.setOption(that.getBarOption(xBar, yBar));
      }
      window.addEventListener('resize', () => {
        if (that.chart) {
          that.chart.resize();
        }
        if (that.chartM) {
          that.chartM.resize();
        }
        if (that.chartE) {
          that.chartE.resize();
        }
        if (that.chartEM) {
          that.chartEM.resize();
        }
      });
    },
    loadInstance(instanceData) {
      let platform = {
        BINANCE: {
          PLATFORM: 'BINANCE',
          COUNT: 0,
          USD: 0,
          BTC: 0,
          ETH: 0,
          USDTOTAL: 0
        },
        DYDX: {
          PLATFORM: 'DYDX',
          COUNT: 0,
          USD: 0,
          BTC: 0,
          ETH: 0,
          USDTOTAL: 0
        },
        OKX: {
          PLATFORM: 'OKX',
          COUNT: 0,
          USD: 0,
          BTC: 0,
          ETH: 0,
          USDTOTAL: 0
        },
        DERIBIT: {
          PLATFORM: 'DERIBIT',
          COUNT: 0,
          USD: 0,
          BTC: 0,
          ETH: 0,
          USDTOTAL: 0
        },
        BYBIT: {
          PLATFORM: 'BYBIT',
          COUNT: 0,
          USD: 0,
          BTC: 0,
          ETH: 0,
          USDTOTAL: 0
        },
        MEXC: {
          PLATFORM: 'MEXC',
          COUNT: 0,
          USD: 0,
          BTC: 0,
          ETH: 0,
          USDTOTAL: 0
        }
      };
      let that = this;
      that.totalCount = 0;
      that.totalUsd = 0;
      that.totalBtc = 0;
      that.totalEth = 0;
      that.ethUsd = 0;
      that.btcUsd = 0;
      instanceData.forEach((item) => {
        item.tradeAccountList.forEach((account) => {
          try {
            that.totalCount += 1;
            platform[account.platform].COUNT = platform[account.platform].COUNT + 1;
            if (account.baseCoin.indexOf('USD') > -1 || account.baseCoin.indexOf('_U') > -1) {
              that.totalUsd += account.currentBalance;
              platform[account.platform].USD = platform[account.platform].USD + account.currentBalance;
            } else if (account.baseCoin === 'ETH') {
              that.totalEth += account.currentBalance;
              that.ethUsd += account.currentBalance * account.currentPrice;
              platform[account.platform].ETH = platform[account.platform].ETH + account.currentBalance;
            } else if (account.baseCoin === 'BTC') {
              that.totalBtc += account.currentBalance;
              that.btcUsd += account.currentBalance * account.currentPrice;
              platform[account.platform].BTC = platform[account.platform].BTC + account.currentBalance;
            }
            platform[account.platform].USDTOTAL = platform[account.platform].USDTOTAL + account.currentBalance * account.currentPrice;
          } catch (e) {
            console.error(account.platform + ': Exception');
          }
        });
      });
      let tmp = [];
      let platformKeyArray = [];
      let platformUsd = [];
      for (const platformKey in platform) {
        tmp.push(platform[platformKey]);
        platformKeyArray.push(platform[platformKey].PLATFORM);
        platformUsd.push(platform[platformKey].USDTOTAL);
      }
      that.statistics = tmp;
      that.renderingInstance = false;
      that.render(platformKeyArray, platformUsd);
    }
  }
}
</script>

<style scoped>

@media only screen and (min-width: 820px) {
  .show-less-820 {
    display: none !important
  }
}

@media only screen and (max-width: 820px) {
  .show-than-820 {
    display: none !important
  }
}

@media only screen and (max-width: 1240px) {
  .show-more-1240 {
    display: none !important
  }
}

@media only screen and (min-width: 1240px) {
  .show-less-1240 {
    display: none !important
  }
}

</style>