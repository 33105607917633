<template>
  <div class="trialpha-stander-sub-page">
    <div style="margin: 60px auto auto auto;max-width: 2068px;padding: 0 6.18% 100px 6.18%">
      <el-row>
        <el-button-group style="float: left">
          <el-button class="dark-mode-btn" size="small" style="width: 128px" :disabled="activePage === 'DATA'" @click="changePage('DATA')">Data</el-button>
          <el-button class="dark-mode-btn" size="small" style="width: 128px" :disabled="activePage === 'SWAP'" @click="changePage('SWAP')">Swap</el-button>
          <el-button class="dark-mode-btn" size="small" style="width: 128px" :disabled="activePage === 'BRIDGE'" @click="changePage('BRIDGE')">Bridge</el-button>
          <el-button class="dark-mode-btn" size="small" style="width: 128px" :disabled="activePage === 'EARN'" @click="changePage('EARN')">Earn</el-button>
          <el-button class="dark-mode-btn" size="small" style="width: 128px" :disabled="activePage === 'MW'" @click="changePage('MW')">My Wallet</el-button>
          <el-button class="dark-mode-btn" size="small" style="width: 128px" :disabled="activePage === 'MSW'" @click="changePage('MSW')">Multi-Sign</el-button>
        </el-button-group>
        <el-button class="dark-mode-btn" size="small" style="width: 128px;float: right" @click="changePage(activePage)">Refresh</el-button>
      </el-row>
      <el-row style="margin-top: 20px" v-if="activePage === 'DATA'">
        <defi-data ref="defiData" />
      </el-row>
      <el-row style="margin-top: 20px" v-if="activePage === 'SWAP'">
        <ft-swap ref="ftSwap" />
      </el-row>
      <el-row style="margin-top: 20px" v-if="activePage === 'BRIDGE'">
        <ft-bridge ref="ftBridge" />
      </el-row>
      <el-row style="margin-top: 20px" v-if="activePage === 'EARN'">
        <ft-earn ref="ftEarn" />
      </el-row>
      <el-row style="margin-top: 20px" v-if="activePage === 'MW'">
        <my-web3-wallet ref="MW" />
      </el-row>
      <el-row style="margin-top: 20px" v-if="activePage === 'MSW'">
        <abm-wallet-mgnt ref="awm" />
      </el-row>
    </div>
  </div>
</template>

<script>
import AbmWalletMgnt from "@/web3/abmWallet/AbmWalletMgnt";
import DefiData from "@/pages/controlPanel/web3/DefiData";
import FtBridge from "@/pages/controlPanel/web3/FtBridge";
import FtSwap from "@/pages/controlPanel/web3/FtSwap";
import FtEarn from "@/pages/controlPanel/web3/FtEarn";
import MyWeb3Wallet from "@/pages/controlPanel/web3/MyWallet";

export default {
  name: "Web3Mgnt",
  components: {MyWeb3Wallet, FtEarn, FtSwap, FtBridge, DefiData, AbmWalletMgnt},
  data: function () {
    return {
      activePage: 'DATA'
    }
  },
  created() {

  },
  methods: {
    changePage(page) {
      this.activePage = page;
      if (page === 'DATA') {
        this.$nextTick(() => {
          this.$refs['defiData'].initData();
        });
      } else if (page === 'SWAP') {
        this.$nextTick(() => {
          this.$refs['ftSwap'].initData();
        });
      } else if (page === 'BRIDGE') {
        this.$nextTick(() => {
          this.$refs['ftBridge'].initData();
        });
      } else if (page === 'EARN') {
        this.$nextTick(() => {
          this.$refs['ftEarn'].initData();
        });
      } else if (page === 'MW') {
        this.$nextTick(() => {
          this.$refs['MW'].initData();
        });
      } else if (page === 'MSW') {
        this.$nextTick(() => {
          this.$refs['awm'].initBalanceInfo();
        });
      }
    }
  }
}
</script>

<style scoped>

</style>