<script type="text/javascript">
import AbmNet from "@/api/net/AbmNet";
import ServiceMapper from "@/const/ServiceMapper"
import UserService from "@/api/user/UserService";

// 字符长度计算, 汉字算两个字符
function countStringLength(str) {
    if (!str) {
        return 0
    }
    var count = 0
    var re = /[\u4e00-\u9fa5]/ // 正则表达式，判断是否为汉字
    for (var i = 0; i < str.length; i++) {
        if(re.test(str.charAt(i))){
            count ++
        }
    }
    count += str.length
    return count
}

// 字符长度计算, 汉字算两个字符
function formatMoneyK(num) {
    if (num < 0) {
        return 0
    }
    if (num < 1000) {
        return num
    }
    return Math.round(num / 1000).toLocaleString() + 'k'
}

function getCustomerSizeByProgress(progress, success, error) {
  let params = {
    progress
  }
  AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.crmGetCustomerSizeByProgress, params, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function queryCustomer(pageNumber, pageSize, progress, keyword, success, error) {
  let params = {
    pageNumber,
    pageSize,
    userId: UserService.getUserId()
  }
  if (progress) {
    params.progress = progress
  }
  AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.crmQueryCustomer, params, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function likeQueryCustomer(keyword, success, error) {
  let params = {
    customerName: keyword,
    userId: UserService.getUserId()
  }
  AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.crmLikeQueryCustomers, params, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function registerCustomer(params, success, error) {
  // let params = {
  //   name,
  //   channelId,
  //   accountTag: '',
  //   coinName: "USDT",
  //   followUser: '',
  //   maxDrawdown: 20,
  //   platform: "0",
  //   progress: "接触中",
  //   settlementTime: Date.now(),
  //   startFundsAmount: 0.0,
  //   strategyRemark: "",
  //   fundUtilizationRate: 0.0,
  //   user: UserService.getUserId(),
  // }
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.crmRegisterCustomer, params, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function registerChildrenCustomer(params, success, error) {
  // let params = {
  //   customerId,
  // }
  AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.crmAddNewAccount, params, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function queryCustomerSettleInfo(customerId, success, error) {
  let params = {
    customerId
  }
  AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.crmQueryCustomerSettleInfo, params, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function queryCustomerSettle(customerId, success, error) {
  let params = {
    customerId
  }
  AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.crmQueryCustomerSettle, params, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}


function queryCustomerRemark(customerId, success, error) {
  let params = {
    customerId
  }
  AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.crmQueryCustomerRemark, params, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function updateCustomerInfo(customer, success, error) {
  let params = {
    accountNumber: customer.accountNumber,
    accountStatus: customer.accountStatus,
    accountTag: customer.accountTag,
    channelId: customer.channelId,
    coinName: customer.coinName,
    customerId: customer.customerId,
    followUser: customer.followUser,
    fundUtilizationRate: customer.fundUtilizationRate,
    maxDrawdown: customer.maxDrawdown,
    name: customer.name,
    platform: customer.platform,
    progress: customer.progress,
    settlementTime: new Date(customer.settlementTime).getTime(),
    startFundsAmount: customer.startFundsAmount,
    strategyRemark: customer.strategyRemark,
    user: customer.user,
  }
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.crmUpdateCustomerInfo, params, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function addCustomerSettlementInfo(info, success, error) {
  let params = {
    customerId: info.customerId,
    amount: info.amount,
    remark: info.remark
  }
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.crmAddCustomerSettlementInfo, params, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function addCustomerSettlement(info, success, error) {
  let params = {
    customerId: info.customerId,
    startAmount: info.startAmount,
    endAmount: info.endAmount,
    incomeAmount: info.incomeAmount,
    nextIssueAmount: info.nextIssueAmount,
  }
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.crmAddCustomerSettlement, params, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function addCustomerRemark(info, success, error) {
  let params = {
    customerId: info.customerId,
    remark: info.remark
  }
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.crmAddCustomerRemark, params, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function deleteCustomer(customerId, success, error) {
  let params = {
    customerId
  }
  AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.crmDeleteCustomer, params, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function deleteCustomerRemark(remarkId, success, error) {
  let params = {
    remarkId
  }
  AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.crmDeleteCustomerRemark, params, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function deleteSettleInfo(settleInfoId, success, error) {
  let params = {
    settleInfoId
  }
  AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.crmDeleteSettleInfo, params, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function createCasualID(customerId, success, error) {
  let params = {
    customerId
  }
  AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.crmCreateCasualID, params, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function checkCasualId(randomID, success, error) {
  let params = {
    randomID
  }
  AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.crmCheckCasualId, params, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function setApiInfo(apiInfo, randomID, success, error) {
  let params = {
    apiInfo: JSON.stringify(apiInfo),
    randomID: randomID
  }
  AbmNet.postRequestWithoutPermissionCheck(ServiceMapper.WEB_SERVICE.crmSetApiInfo, params, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function queryChannel(pageNumber, pageSize, progress, keyword, success, error) {
  let params = {
    pageNumber,
    pageSize,
    userId: UserService.getUserId()
  }
  if (progress) {
    params.progress = progress
  }
  AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.crmQueryChannel, params, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function likeQueryChannel(keyword, success, error) {
  let params = {
    channelName: keyword,
    userId: UserService.getUserId()
  }
  AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.crmLikeQueryChannels, params, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function addNewChannel(name, relation, success, error) {
  let params = {
    name,
    belong: UserService.getUserName(),
    contact: '',
    customerNumber: 0,
    desire: "中",
    fundsAmount: 0,
    info: '',
    contactCustomerList: (() => {
      return relation.split(',').filter((item) => {
        return item
      })
    })()
  }
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.crmAddNewChannel, params, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function updateChannel(info, success, error) {
  let params = {
    id: info.channelId,
    name: info.name,
    belong: info.belong,
    contact: info.contact,
    customerNumber: info.customerNumber,
    desire: info.desire,
    fundsAmount: info.fundsAmount,
    info: info.info
  }
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.crmUpdateChannel, params, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function deleteChannel(channelId, success, error) {
  let params = {
    channelId
  }
  AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.crmDeleteChannel, params, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function qureyChannelRemark(channelId, success, error) {
  let params = {
    channelId
  }
  AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.crmQureyChannelRemark, params, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function addChannelRemark(info, success, error) {
  let params = {
    channelId: info.channelId,
    remark: info.remark
  }
  AbmNet.postRequest(ServiceMapper.WEB_SERVICE.crmAddChannelRemark, params, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function deleteChannelRemark(remarkId, success, error) {
  let params = {
    remarkId
  }
  AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.crmDeleteChannelRemark, params, function (res) {
    success(res);
  }, function (err) {
    error(err);
  });
}

function getChannelFundsAmount(channelId, success, error) {
  let params = {
    channelId
  }
  AbmNet.getRequestWithParams(ServiceMapper.WEB_SERVICE.crmGetChannelFundsAmount, params, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

export default {
  countStringLength,
  formatMoneyK,
  getCustomerSizeByProgress,
  queryCustomer,
  likeQueryCustomer,
  registerCustomer,
  registerChildrenCustomer,
  updateCustomerInfo,
  queryCustomerSettleInfo,
  queryCustomerSettle,
  queryCustomerRemark,
  addCustomerSettlementInfo,
  addCustomerSettlement,
  addCustomerRemark,
  deleteCustomer,
  deleteCustomerRemark,
  deleteSettleInfo,
  createCasualID,
  checkCasualId,
  setApiInfo,
  queryChannel,
  likeQueryChannel,
  addNewChannel,
  updateChannel,
  deleteChannel,
  qureyChannelRemark,
  addChannelRemark,
  deleteChannelRemark,
  getChannelFundsAmount
}
</script>