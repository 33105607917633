<script>
import AbmNet from "@/api/net/AbmNet";
import ServiceMapper from "@/const/ServiceMapper";

function query(success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.platformInfoQuery, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function queryNews(success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.platformInfoQueryNews, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

function queryBinanceRate(success, error) {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.platformInfoQueryBinanceProduct, function (data) {
    success(data);
  }, function (err) {
    error(err);
  });
}

export default {
  query,
  queryNews,
  queryBinanceRate
}
</script>
