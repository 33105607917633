<template>
  <div>
    <vue-metamask ref="metamaskInit" userMessage="msg" @onComplete="onComplete"
                  :init-connect="initConnect"></vue-metamask>
  </div>
</template>

<script>
import VueMetamask from 'vue-metamask';
import Web3Service from "@/web3/Web3Service";

export default {
  name: "MetaMaskUtils",
  components: {
    VueMetamask,
  },
  data() {
    return {
      msg: "This is demo net work",
      initConnect: false,
      initAccountChange: false
    }
  },
  created() {
    let web3 = Web3Service.getWeb3(this);
    if (web3) {
      web3.eth.getAccounts().then((r) => {
        if (r.length > 0) {
          this.$emit('connected', r[0]);
        } else {
          this.$emit('connected', 'NULL');
        }
        this.initAccountChangeMonitor();
      });
    }
  },
  methods: {
    render: function () {
      this.$refs['metamaskInit'].init();
    },
    onComplete(data) {
      if (data.type === 'MAINNET') {
        this.$emit('connected', data.metaMaskAddress);
      } else if (data.type === 'NO_LOGIN') {
        this.$emit('connected', 'NULL');
      }
      this.initAccountChangeMonitor();
    },
    initAccountChangeMonitor() {
      if (!this.initAccountChange) {
        this.initAccountChange = true;
        let that = this;
        Web3Service.getWeb3(this).currentProvider.on('accountsChanged', (newSelectedAccount) => {
          if (newSelectedAccount.length > 0) {
            this.$emit('connected', newSelectedAccount[0]);
            Web3Service.initWalletInfo(this, (result) => {
              this.$emit('walletChange', {
                networkName: result.networkName,
                networkSymbol: result.networkSymbol,
                balance: result.balance
              });
            });
          } else {
            this.$emit('connected', 'NULL');
          }
        });
        Web3Service.getWeb3(that).currentProvider.on('networkChanged', function(){
          Web3Service.initWalletInfo(that, (result) => {
            that.$emit('walletChange', {
              networkName: result.networkName,
              networkSymbol: result.networkSymbol,
              balance: result.balance
            });
          });
        });
      }
    }
  }
}
</script>

<style scoped>

</style>