<template>
    <div class="create-customer-dialog">

        <div class="create-customer-dialog-content">

            <div class="create-customer-dialog-content-inner">
                <div @click="eventSwitchChange({id: 'close'})" style="position: absolute; top: -56px; right: -46px; width: 32px; height: 32px; background: #3E454E; color: white; cursor: pointer; border-radius: 16px; font-size: 19px; padding: 3px;">
                    <i class="el-icon-close"></i>
                </div>
            
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; margin-bottom: 20px;">
                    <el-col :span="24" style="text-align: left;">
                        <!-- <FtGroupButtonWidget :buttons.sync="detailSwitchButtons" v-on:switch-change="eventSwitchChange" /> -->
                        <FtGroupButtonWidget :buttons="[{
                            id: 'append',
                            icon: '',
                            text: '新建客户',
                            textI18n: '',
                            active: true
                        }]"/>
                    </el-col>
                </el-row>
                        
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="6" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                            客户昵称
                        </label>
                    </el-col>
                    <el-col :span="18" style="text-align: left;">
                        <input v-model="form.name" type="text" value="" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                    </el-col>
                </el-row>
                        
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="6" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                            最大回撤
                        </label>
                    </el-col>
                    <el-col :span="18" style="text-align: left;">
                        <input v-model="form.maxDrawdown" type="text" value="" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                    </el-col>
                </el-row>
                        
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="6" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                            资金量
                        </label>
                    </el-col>
                    <el-col :span="12" style="text-align: left;">
                        <input v-model="form.startFundsAmount" type="text" value="" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                    </el-col>
                    <el-col :span="6" style="text-align: left;">
                        <select v-model="form.coinName" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px;">
                            <option v-for="(item, index) in coinList" :key="index" :value="item.code">{{item.desc}}</option>
                        </select>
                    </el-col>
                </el-row>
                        
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="6" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">
                            客户进度
                        </label>
                    </el-col>
                    <el-col :span="18" style="text-align: left;">
                        <select v-model="form.progress" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;">
                            <option v-for="(item, index) in progressList" :key="index" :value="item.code">{{item.desc}}</option>
                        </select>
                    </el-col>
                </el-row>
                        
                <!-- <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="6" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">渠道归属</label>
                    </el-col>
                    <el-col :span="18" style="text-align: left;">
                        <select v-model="form.channel" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;">
                            <option v-for="(channel, cindex) in channels" v-bind:key="cindex" :value="channel.id">
                                {{channel.contact}}
                            </option>
                        </select>
                    </el-col>
                </el-row> -->
                        
                <!-- <el-row v-if="currentPanel == 'channel'" class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static;">
                    <el-col :span="6" style="text-align: right;">
                        <label style="color: #999999; font-size: 14px; line-height: 30px; padding-right: 30px;">关联客户</label>
                    </el-col>
                    <el-col :span="18" style="text-align: left;">
                        <input v-model="form.relation" type="text" value="" style="width: 100%; background: transparent; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;"/>
                    </el-col>
                </el-row> -->
                
                <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static; margin-top: 20px;">
                    <el-col :span="24">
                        <!-- 个人、渠道 新建 -->
                        <FtGroupButtonWidget :buttons="[{
                            id: 'append',
                            icon: 'el-icon-plus',
                            text: '新建',
                            textI18n: '',
                        }]" mode="single" v-on:switch-change="eventSwitchChange" style="float: none; display: inline-block; margin: auto; background: #3E454E;" />
                    </el-col>
                </el-row>
            </div>

        </div>

    </div>
</template>

<script>
import {Message} from "element-ui";
import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";

import CrmChannelManagement from "@/api/crm/CrmChannelManagement";
import UserService from "@/api/user/UserService";

export default {
    components: {
        FtGroupButtonWidget,
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        channelId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            // 新建面板
            detailSwitchButtons: [
                {
                    id: 'personal',
                    text: '新建客户',
                    textI18n: '',
                    active: true
                }
            ],
            currentPanel: 'personal',

            // 币种列表
            coinList: [
                { code: "USDT", desc: "USDT"},
                { code: "USD", desc: "USD"},
                { code: "BTC", desc: "BTC"},
                { code: "ETH", desc: "ETH"},
            ],

            // 账号状态列表
            progressList: [
                { code: '', desc: "请选择"},
                { code: '接触中', desc: "接触中"},
                { code: '沟通中', desc: "沟通中"},
                { code: '达成合作', desc: "达成合作"},
                { code: '沟通失败', desc: "沟通失败"},
            ],

            channels: [],

            form: {
                name: '',
                maxDrawdown: 0,
                startFundsAmount: 0.0,
                coinName: 'USDT',
                progress: ''
            }
            
        }
    },
    computed: {
        
    },
    mounted() {
        
    },
    methods: {

        // 各种切换处理
        eventSwitchChange(item) {
            if (item.id == 'close') {
            console.log('switch change event', item.id)
                this.$emit('update:show', false)
            } else if (item.id == 'append') {
                if (this.form.name == '') {
                    Message.error('请输入客户昵称');
                    return 
                }
                if (CrmChannelManagement.countStringLength(this.form.name) > 36) {
                    Message.warning('客户昵称超长，最多36字符，汉字算两个字符');
                    return
                }
                if (this.form.maxDrawdown == '') {
                    Message.error('请输入最大回撤');
                    return 
                }
                if (this.form.maxDrawdown > 100 || this.form.maxDrawdown < 0) {
                    Message.error('最大回撤值 0~100');
                    return 
                }
                if (this.form.startFundsAmount == '' || this.form.startFundsAmount < 0) {
                    Message.error('请输入资金量');
                    return 
                }
                if (this.form.progress == '') {
                    Message.error('请选择客户进度');
                    return 
                }
                // 个人
                let params = {
                    name: this.form.name,
                    channelId: this.channelId,
                    accountTag: '',
                    coinName: this.form.coinName,
                    followUser: UserService.getUserName(),
                    maxDrawdown: this.form.maxDrawdown,
                    platform: '0',
                    progress: this.form.progress,
                    settlementTime: Date.now(),
                    startFundsAmount: this.form.startFundsAmount,
                    strategyRemark: '',
                    fundUtilizationRate: 0.0,
                    user: UserService.getUserId(),
                }
                CrmChannelManagement.registerCustomer(params,
                    (res) => {
                        console.log(res)
                        this.$emit('update:show', false)
                        this.$emit('success', 'customer')
                    },
                    () => {
                        // Message.error(this.$i("operationFail"));
                    }
                );
            }
        },
        
    }
}
</script>

<style scoped>
.create-customer-dialog{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
}
.create-customer-dialog-content{
    background: #22272E;
    width: 440px;
    height: 386px;
    padding: 30px;
    border: 1px solid #3E454E;
    border-radius: 8px;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -220px;
    margin-top: -193px;
}
.create-customer-dialog-content-inner{
    position: relative;
}
</style>