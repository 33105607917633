import DatafeedApiService from '@/api/trade/DatafeedApiService'

const history = {}

export default {
	history: history,

	// symbolInfo, resolution, from, to, first, limit
    getBars: function(symbolInfo, resolution, from, to, first, limit, last) {
        // console.log(symbolInfo, resolution, limit)
		// var split_symbol = symbolInfo.name.split(/[:/]/)
		// const url = resolution === 'D' ? '/data/histoday' : resolution >= 60 ? '/data/histohour' : '/data/histominute'
		// const qs = {
		// 	e: split_symbol[0],
		// 	fsym: split_symbol[1],
		// 	tsym: split_symbol[2],
		// 	toTs:  to ? to : '',
		// 	limit: limit ? limit : 2000, 
		// 	// aggregate: 1//resolution 
		// }
		// console.log('请求商品数据', symbolInfo)
		return new Promise((resolve, reject) => {
			let symbol = symbolInfo.name
			let kind = ''
			if (symbolInfo.exchange == 'binance') {
				kind = symbolInfo.exchange_type
				if (symbolInfo.exchange_type == 'spot') {
					symbol = symbol.substring(0, symbol.length - '_SPOT'.length)
				} else {
					symbol = symbol.substring(0, symbol.length - '_FUTURE'.length)
				}
			}
			else if (symbolInfo.exchange == 'bybit') {
				kind = symbolInfo.exchange_type
				if (symbolInfo.exchange_type == 'delivery') {
					symbol = symbol.substring(0, symbol.length - '_BYBIT_DELIVERY'.length)
				} else if (symbolInfo.exchange_type == 'spot') {
					symbol = symbol.substring(0, symbol.length - '_BYBIT_SPOT'.length)
				} else {
					symbol = symbol.substring(0, symbol.length - '_BYBIT_FUTURE'.length)
				}
			}
			DatafeedApiService.klines(symbolInfo.exchange, kind, symbol, resolution, from, to, limit, last, (response) => {
				let data = response.data
				// if (data.Response && data.Response === 'Error') {
				// 	console.log('CryptoCompare API error:',data.Message)
				// 	return []
				// }
				if (data.length) {
					// console.log(`Actually returned: ${new Date(data.TimeFrom * 1000).toISOString()} - ${new Date(data.TimeTo * 1000).toISOString()}`)
					var bars = data.map(el => {
						let timeString = ''
						let time = 0
						if (isNaN(el.time)) {
							timeString = el.time
							time = new Date(el.time).getTime()
						} else {
							timeString = el.time
							time = parseInt(el.time)
						}
						return {
							timeString: timeString,
							time: time, //TradingView requires bar time in ms
							low: el.low,
							high: el.high,
							open: el.open,
							close: el.close,
							volume: el.vol 
						}
					})
					bars.sort((a, b) => {
						return a.time - b.time
					})
					if (first) {
						var lastBar = bars[bars.length - 1]
						history[symbolInfo.name] = lastBar
					}
					resolve(bars)
				} else {
					resolve([])
				}
			}, () => {
				reject()
			})
		})
}
}