<script type="text/javascript">

import GmxContract from "@/web3/contract/gmx/GmxContract";
import SymbolInfoService from "@/utils/SymbolInfoService";
import GeneralApiService from "@/api/trade/GeneralApiService";
import GmxContractAvax from "@/web3/contract/gmx/GmxContractAvax";

function getGlpPrice(isArb, success, error) {
  let contract = isArb ? GmxContract.getGlpManagerContract(this) : GmxContractAvax.getGlpManagerContract(this);
  contract.methods.getPrice(false).call().then((res) => {
    success(res / Math.pow(10, 30));
  }, (err) => {
    error(err);
  });
}

function getStakedGlpAmount(isArb, address, success, error) {
  let contract = isArb ? GmxContract.getGlpRewardTrackerContract(this) : GmxContractAvax.getGlpRewardTrackerContract(this);
  contract.methods.stakedAmounts(address).call().then((res) => {
    success((res / Math.pow(10, 18)));
  }, (err) => {
    error(err);
  });
}

function getEthReward(isArb, address, success, error) {
  let contract = isArb ? GmxContract.getGlpRewardTrackerContract(this) : GmxContractAvax.getGlpRewardTrackerContract(this);
  contract.methods.claimable(address).call().then((res) => {
    success((res / Math.pow(10, 18)));
  }, (err) => {
    error(err);
  });
}

function getStakeInfoReward(isArb, address, success, error) {
  let contract = isArb ? GmxContract.getRewardReaderContract(this) : GmxContractAvax.getRewardReaderContract(this);
  getStakedGlpAmount(isArb, address, function (amount) {
    getGlpPrice(isArb, function (glpPrice) {
      let ethPrice = SymbolInfoService.getSymbolPrice(GeneralApiService.PLATFORM.BINANCE, GeneralApiService.SYMBOL_TYPE.SPOT, isArb ? 'ETHUSDT' : 'AVAXUSDT');
      let gmxPrice = SymbolInfoService.getSymbolPrice(GeneralApiService.PLATFORM.BINANCE, GeneralApiService.SYMBOL_TYPE.SPOT, 'GMXUSDT');
      contract.methods.getStakingInfo(address, (isArb ? ["0x4e971a87900b931fF39d1Aad67697F49835400b6","0x1addd80e6039594ee970e5872d247bf0414c8903"] : ['0xd2d1162512f927a7e282ef43a362659e4f2a728f', '0x9e295b5b976a184b14ad8cd72413ad846c299660'])).call().then((res) => {
        success({
          gmxPrice: gmxPrice,
          glpPrice: glpPrice,
          glpStake: amount,
          ethReward: res[0] / Math.pow(10, 18),
          ethApr: (((res[1] / Math.pow(10, 18)) * 86400 * 365 * ethPrice) / ((res[4] / Math.pow(10, 18)) * glpPrice)) * 100,
          esGmxReward: Number(res[5] / Math.pow(10, 18))
        });
      }, (err) => {
        error(err);
      });
    });
  });
}

export default {
  getStakeInfoReward,
  getStakedGlpAmount,
  getGlpPrice,
  getEthReward
}


</script>