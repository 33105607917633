<template>
  <div class="panel-scoped">
  
    <el-row>
      <el-col :span="24">
        <FtGroupButtonWidget :buttons="[{
            id: 'selectTargetAddress',
            text: '选择目标地址',
            textI18n: '',
        }]" mode="single" textMode="true" v-on:switch-change="onAction" 
        style="background: #3E454E;" />
      </el-col>
    </el-row>

    <div style="margin-top: 20px;">

      <div :class="[(scrollHeight > 0 ? 'scroll-mode' : '')]" :style="(scrollHeight > 0 ? {height: scrollHeight + 'px'} : {})">

        <div style="display: flex; margin-bottom: 15px;" v-for="(item, index) in addressList" :key="index"
          v-show="item.bind == null || (item.walletType == 'dex' && filterTargetChain ? (item.bind.dexChain == filterTargetChain) : true)">
          <div style="flex: 1; position: relative">
            <FtInputBoxWidget v-model="item.display" 
              :displayText="item.displayText" 
              placeholder="请输入钱包地址" style="margin-left: 0px;" />
            <div @click="onAction({id: 'delAddress', value: index})"
              style="position: absolute; top: -10px; right: -10px; width: 20px; height: 20px; background: #3E454E; color: white; cursor: pointer; border-radius: 16px; font-size: 11px; padding: 1px;">
              <i class="el-icon-close" style="margin-top: 1px"></i>
            </div>
          </div>
          <div>
            <FtInputBoxWidget v-model="item.count" v-on:switch-blur="onAction({id: 'transferAmountCheck', value: item})" placeholder="请输入数量" style="margin-left: 20px; width: 120px;" />
            <div style="font-size: 10px; color: #eb3c3c;" v-if="transferAmountErrorMessages">{{transferAmountErrorMessages[item.display]}}</div>
          </div>
        </div>

      </div>

      <div style="display: flex; margin-bottom: 15px;">
        <div style="flex: 1;">
          <FtGroupButtonWidget :buttons="[{
              id: 'addAddress',
              text: '添加',
              textI18n: '',
              icon: 'el-icon-plus',
          }]" mode="single" type="block" v-on:switch-change="onAction" 
          style="background: #3E454E; width: 100%;" />
        </div>
        <div style="width: 140px;">
          
        </div>
      </div>

      <div style="display: flex; margin-top: 15px;">
        <div style="flex: 1;">

          <FtInputBoxWidget v-model="form.remark" placeholder="请输入备注信息" style="margin-left: 0px;" />

        </div>
        <div style="margin-left: 50px;">
          <FtGroupButtonWidget :buttons="[{
              id: 'confirm',
              text: '确认转出',
              textI18n: '',
          }]" mode="single" v-on:switch-change="onAction" 
          style="background: #3E454E; width: 100%;" />
        </div>
      </div>

    </div>

  </div>
</template>

<script>
// import {Message} from "element-ui";
// import ConfirmProvider from '@/components/ConfirmProvider'

// import CrmChannelManagement from "@/api/crm/CrmChannelManagement";
import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";
import FtInputBoxWidget from "@/components/widgets/FtInputBoxWidget";

import { useWalletOutsideStore } from '@/store/wallet'

export default {
  components: {
    FtGroupButtonWidget,
    FtInputBoxWidget
  },
  props: {},
  data() {
    return {
      // 交易所列表
      addressList: [
        { display: '', count: 0, walletType: 'dex', bind: null},
      ],

      form: {
        remark: ''
      },

      // 滚动面板高度
      scrollHeight: 0,

      // 转账金额限制信息
      transferAmountErrorMessages: null

    }
  },
  computed: {
      // 目标链类型
      filterTargetChain: () => {
        let walletOutsideStore = useWalletOutsideStore()
        return walletOutsideStore.filterTargetChain
      }
  },
  mounted() {

    var windowHeight = window.innerHeight
    this.scrollHeight = windowHeight - 240 - 280

  },
  methods: {

    // 各种切换处理
    onAction(item) {
      if (item.id == 'addAddress') {
          this.addressList.push({ display: '', displayText: '', count: 0, walletType: 'dex', bind: null})
      } else if (item.id == 'delAddress') {
          let ws = this.addressList.splice(item.value, 1)
          if (ws.length) {
            this.$emit('switch-change', {id: 'removeTargetWallet', value: ws[0].bind})
          }
      }

      else if (item.id == 'confirm') {
        this.$emit('switch-change', item)
      }

      else if (item.id == 'transferAmountCheck') {
        this.$emit('switch-change', item)
      }
    },
    getFormData() {
      let targetChain = this.filterTargetChain
      return {
        addressList: this.addressList.filter(item => {
          if (item.bind == null) {
            return true
          }
          let wallet = item.bind
          return item.walletType == 'cex' || wallet.dexChain == targetChain
        }),
        remark: this.form.remark
      }
    },
    resetFormData() {
      // 交易所列表
      this.addressList = []
    },
    pushCommonUsedWallet(commonUsedWallet) {
      if (commonUsedWallet.walletType == "CEX") {
        let pushWallet = { 
          display: commonUsedWallet.apiKey, 
          displayText: (commonUsedWallet.humanReadableName ? commonUsedWallet.humanReadableName + ' : ' + commonUsedWallet.apiKey : commonUsedWallet.apiKey), 
          count: 0, 
          walletType: 'cex', 
          bind: commonUsedWallet
        }
        // 判断当前加入的钱包是否为输入的地址，如果是，则替换
        let ext = -1
        this.addressList.forEach((w, i) => {
          if (w.display == commonUsedWallet.apiKey) {
            ext = i
          }
        })
        if (ext > -1) {
          this.addressList.splice(ext, 1, pushWallet)
        } else {
          if (this.addressList.length == 1 && this.addressList[0].bind == null && this.addressList[0].display == '') {
            this.addressList.splice(0, 1, pushWallet)
          } else {
            this.addressList.push(pushWallet)
          }
        }
      } else if (commonUsedWallet.walletType == "DEX") {
        let pushWallet = {  
          display: commonUsedWallet.address, 
          displayText: (commonUsedWallet.humanReadableName ? commonUsedWallet.humanReadableName + ' : ' + commonUsedWallet.address : commonUsedWallet.address) + ' : ' + commonUsedWallet.dexChain, 
          count: 0, 
          walletType: 'dex', 
          bind: commonUsedWallet
        }
        // 判断当前加入的钱包是否为输入的地址，如果是，则替换
        let ext = -1
        this.addressList.forEach((w, i) => {
          if (w.display == commonUsedWallet.address) {
            ext = i
          }
        })
        if (ext > -1) {
          this.addressList.splice(ext, 1, pushWallet)
        } else {
          if (this.addressList.length == 1 && this.addressList[0].bind == null && this.addressList[0].display == '') {
            this.addressList.splice(0, 1, pushWallet)
          } else {
            this.addressList.push(pushWallet)
          }
        }
      }
    },
    popCommonUsedWallet(commonUsedWallet) {
      let index = -1
      this.addressList.forEach((w, i) => {
        if (w.bind && w.bind.id == commonUsedWallet.id) {
          index = i
        }
      })
      if (index > -1) {
        this.addressList.splice(index, 1)
      }
      if (this.addressList.length == 0) {
        this.addressList.push({ display: '', count: 0, walletType: 'dex', bind: null})
      }
    },
    // 设置转账金额限制信息
    setTransferAmountCheckResult(errors) {
      // let errors = [
      //     {
      //         fromWallet: '',
      //         toWallet: '',
      //         errorMessage: '',
      //         dexChain: "ETH", //提现链名
      //         token: "AGLD", //提现币种
      //         withdrawMin: "22", //提现最小金额
      //         withdrawMax: "9999999", //提现最大金额
      //         withdrawEnable: true //是否允许体现
      //     }
      // ]
      var that = this
      that.transferAmountErrorMessages = null
      if (errors.length) {
        var errorMessages = {}
        errors.forEach(err => {
          errorMessages[err.toWallet] = err.errorMessage
        })
        that.transferAmountErrorMessages = errorMessages
      }
    }

  }
}
</script>

<style scoped>
.panel-scoped{
  background: #22272E;
  padding: 20px;
  border: 1px solid #3E454E;
  border-radius: 8px;
}

.scroll-mode{
  overflow-y: auto;
  padding-top: 10px;
}
</style>