<template>
  <div>
    <el-drawer
        style="min-width: 1000px"
        size="600px"
        :close-on-click-modal=false
        :close-on-press-escape=false
        :wrapperClosable=false
        custom-class="black-card"
        :visible.sync="drawer"
        direction="ttb">
      <h4 class="dark-mode-font" style="font-size: 20px;"><b>{{ $i('accountMgnt.createInstance') }}</b></h4>
      <el-row>
        <div class="dark-mode-font" style="width: 668px;margin: auto">
          <el-row style="margin-top: 15px;margin-bottom: 10px">
            <el-col :span="24">
              <el-row>
                <el-col :span="4" :offset="3">
                  <span style="display: inline-block;margin-top: 8px;color: #FFFFFF"><b>{{ $i('accountMgnt.nickName') }}</b></span>
                </el-col>
                <el-col :span="10">
                  <el-input class="createAccount" style="float:left" size="medium" v-model="createObj.instanceName"></el-input>
                </el-col>
                <el-col :span="4" :offset="1">
                  <el-button class="dark-mode-btn" style="float: right;height: 35px;padding: 5px 38px 5px 38px;font-size: 12px;border-radius: 3px;width: 110px" @click="create" :loading="adding">{{ $i('accountMgnt.add') }}</el-button>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <div class="dark-mode-font" style="width: 668px;height: 400px;margin: auto;overflow-y: auto">
          <el-card class="black-card" v-for="i in createObj.tradeAccounts" :key="i.id" :style="'' + (i.id > 0 ? 'border-radius: 0' : 'border-radius: 3px 3px 0 0' ) + ''">
            <el-row>
              <el-col :span="6">
                <span style="display: inline-block;float: left;margin-top: 8px;color: white"><b>{{ $i('accountMgnt.exchange') }}</b></span>
              </el-col>
              <el-col :span="12">
                <el-select class="createAccount" v-model="i.platform" placeholder="Select an exchange"
                           style="width: 100%;" value="">
                  <el-option
                      v-for="item in exchanges"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="6">
                <el-select class="createAccount" v-model="i.baseCoin" placeholder="Select a currency"
                           style="width: 100%;" value="">
                  <el-option
                      v-for="item in basicCoinList"
                      :key="item"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row style="margin-top: 5px">
              <el-col :span="6">
                <span style="display: inline-block;float: left;margin-top: 8px;color: white"><b>API KEY</b></span>
              </el-col>
              <el-col :span="18" v-if="i.platform === 'FTX'">
                <el-row>
                  <el-col :span="18">
                    <el-input class="createAccount" style="float:left" size="medium" v-model="i.apiKey"></el-input>
                  </el-col>
                  <el-col :span="6">
                    <el-input class="createAccount" style="float:left" size="medium" v-model="i.subName" placeholder="SubName"></el-input>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="18" v-if="['BINANCE', 'DERIBIT', 'BYBIT', 'MEXC', 'EVM_BASED_WALLET'].indexOf(i.platform) > -1">
                <el-row>
                  <el-col :span="24">
                    <el-input class="createAccount" style="float:left" size="medium" v-model="i.apiKey"></el-input>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="18" v-if="['OKX', 'DYDX'].indexOf(i.platform) > -1">
                <el-row>
                  <el-col :span="18">
                    <el-input class="createAccount" style="float:left" size="medium" v-model="i.apiKey"></el-input>
                  </el-col>
                  <el-col :span="6">
                    <el-input class="createAccount" style="float:left" size="medium" v-model="i.passphrase" placeholder="Passphrase"></el-input>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row style="margin-top: 5px">
              <el-col :span="6">
                <span style="display: inline-block;float: left;margin-top: 8px;color: white"><b>API SECRET</b></span>
              </el-col>
              <el-col :span="18">
                <el-input class="createAccount" style="float:left" size="medium" v-model="i.apiSecret" type="password"></el-input>
              </el-col>
            </el-row>
            <el-row style="margin-top: 5px" v-if="i.platform === 'DYDX'">
              <el-col :span="6">
                <span style="display: inline-block;float: left;margin-top: 8px;color: white"><b>Stark Key</b></span>
              </el-col>
              <el-col :span="18">
                <el-input class="createAccount" style="float:left" size="medium" v-model="i.starkKey"></el-input>
              </el-col>
            </el-row>
            <el-row style="margin-top: 5px">
              <el-col :span="18" :offset="6" v-if="['EVM_BASED_WALLET'].indexOf(i.platform) > -1">
                <el-button class="dark-mode-btn" style="float: left;width: 100%;height: 35px;padding: 0 38px 2px 38px;font-size: 12px;border-radius: 3px 0 0 3px" @click="createNewWallet(i)">Generate new wallet</el-button>
              </el-col>
            </el-row>
          </el-card>
          <el-row>
            <el-col :span="12">
              <el-button class="dark-mode-btn" style="float: left;width: 100%;height: 35px;padding: 0 38px 2px 38px;font-size: 12px;border-radius: 3px 0 0 3px" @click="removeTradeAccount" :disabled="createObj.tradeAccounts.length <= 1 || adding"><i
                  class="el-icon-remove" style="font-size: 18px"/></el-button>
            </el-col>
            <el-col :span="12">
              <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 38px 2px 38px;font-size: 12px;border-radius: 0 3px 3px 0" @click="addTradeAccount" :disabled="adding"><i class="el-icon-circle-plus" style="font-size: 18px"/></el-button>
            </el-col>
          </el-row>
        </div>
      </el-row>
    </el-drawer>
  </div>
</template>

<script>
import InstanceService from "@/api/trade/InstanceService";
import {Message} from "element-ui";
import Web3Service from "@/web3/Web3Service.vue";

export default {
  name: "CreateAccount",
  data: function () {
    return {
      adding: false,
      drawer: false,
      basicCoinList: [
        'USDT',
        'USD',
        'BTC',
        'ETH',
        'LTC',
        'FIL',
        'DOT',
        'ETH_U',
        'BTC_U',
        'SOL_U',
      ],
      exchanges: [
        {
          name: 'BINANCE',
          value: 'BINANCE'
        }, {
          name: 'OKX',
          value: 'OKX'
        }, {
          name: 'DERIBIT',
          value: 'DERIBIT'
        }, {
          name: 'EVM BASED WALLET',
          value: 'EVM_BASED_WALLET'
        }, {
          name: 'BYBIT',
          value: 'BYBIT'
        }, {
          name: 'MEXC',
          value: 'MEXC'
        }, {
          name: 'DYDX',
          value: 'DYDX'
        }
      ],
      createObj: {
        instanceName: '',
        tradeAccounts: [
          {
            id: 0,
            active: true,
            alias: '',
            subName: '',
            apiKey: '',
            apiSecret: '',
            passphrase: '',
            starkKey: '',
            baseCoin: 'USD',
            currentBalance: 0,
            email: '',
            info: '',
            originBalance: 0,
            platform: 'BINANCE'
          }
        ],
        walletObj: {
          walletAddress: '',
          walletPrivateKey: ''
        }
      }
    }
  },
  methods: {
    openDrawer: function () {
      this.drawer = true;
    },
    addTradeAccount() {
      this.createObj.tradeAccounts.push(
          {
            id: this.createObj.tradeAccounts.length,
            active: true,
            alias: '',
            subName: '',
            apiKey: '',
            apiSecret: '',
            passphrase: '',
            starkKey: '',
            baseCoin: 'USD',
            currentBalance: 0,
            email: '',
            info: '',
            originBalance: 0,
            platform: 'BINANCE'
          }
      );
    },
    removeTradeAccount() {
      this.createObj.tradeAccounts.pop();
    },
    create() {
      let that = this;
      for (const tradeAccountsKey in this.createObj.tradeAccounts) {
        if (this.createObj.tradeAccounts[tradeAccountsKey].platform === 'DYDX' && this.createObj.tradeAccounts[tradeAccountsKey].starkKey) {
          this.createObj.tradeAccounts[tradeAccountsKey].apiSecret = this.createObj.tradeAccounts[tradeAccountsKey].apiSecret + '_StarkKey_' + this.createObj.tradeAccounts[tradeAccountsKey].starkKey.replaceAll('0x', '').replaceAll('0X', '');
        }
        this.createObj.tradeAccounts[tradeAccountsKey].alias = this.createObj.instanceName + '_' + this.createObj.tradeAccounts[tradeAccountsKey].platform;
        this.createObj.tradeAccounts[tradeAccountsKey].email = this.createObj.instanceName + '_' + this.createObj.tradeAccounts[tradeAccountsKey].platform + '@trialpha.com';
      }
      that.adding = true;
      if(/^[a-zA-Z][a-zA-Z0-9\u4e00-\u9fa5_.]{4,25}$/.test(this.createObj.instanceName)) {
        InstanceService.create(this.createObj, function () {
          Message.success(that.createObj.instanceName + ' 添加成功');
          setTimeout(() => {
            that.$emit('renderEvent',true);
            that.adding = false;
            that.drawer = false;
          }, 888);
        }, function () {
          that.adding = false;
        });
      } else {
        that.adding = false;
        Message.warning('名称不符合要求')
      }
    },
    createNewWallet(i) {
      let that = this;
      let newWallet = Web3Service.getWeb3(that).eth.accounts.create();
      i.apiKey = newWallet.address;
      i.apiSecret = newWallet.privateKey;
    }
  }
}
</script>

<style>

.createAccount .el-input__inner {
  color: white;
  height: 36px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-radius: 3px;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 2px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(119, 109, 109, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(119, 109, 109, 0.4);
}

</style>