<template>
  <div class="trialpha-full-sub-page" style=";background-color: #1c2128">
    <el-row style="width: 100%;position: absolute;z-index: 568">
      <el-col :span="2" :offset="22">
        <b-navbar
            style=";background-color:rgba(0,0,0,0);width: 198px;top:0;margin-top: 5px;border:0;padding-top:0;padding-bottom:0;float: right">
          <b-collapse is-nav>
            <b-nav-item-dropdown class="border-0" text="App" toggle-class="text-light"
                                 style="margin-right: 15px;margin-top: 2%;float: right;border: 0;outline: 0" no-caret>
              <b-dropdown-item href="#" v-on:click="iosPage">IOS</b-dropdown-item>
              <b-dropdown-item href="https://abm-app-image.s3.ap-northeast-1.amazonaws.com/download/ForthTech.apk">
                Android
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown :text="$i('lang')" toggle-class="text-light" no-caret
                                 style="margin-right: 15px;margin-top: 2%;float: right;border: 0;outline: 0">
              <b-dropdown-item href="#" v-on:click="setLanguage('China')">{{ $i('lang_china') }}</b-dropdown-item>
              <b-dropdown-item href="#" v-on:click="setLanguage('English')">{{ $i('lang_english') }}</b-dropdown-item>
              <b-dropdown-item href="#" v-on:click="setLanguage('Japan')">{{ $i('lang_japan') }}</b-dropdown-item>
              <b-dropdown-item href="#" v-on:click="setLanguage('Korea')">{{ $i('lang_korea') }}</b-dropdown-item>
              <b-dropdown-item href="#" v-on:click="setLanguage('Thailand')">{{ $i('lang_thailand') }}</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-collapse>
        </b-navbar>
      </el-col>
    </el-row>
    <el-card class="login-card" shadow="none">
      <el-row>
        <el-col :span="12">
          <div class="login-logo-ft-left" style="float: right;margin-top: -6px;margin-right: 72px"></div>
        </el-col>
        <el-col :span="12">
          <div class="login-logo-ft" style="float: left;margin-top: 5px;margin-left: -62px"></div>
        </el-col>
      </el-row>
      <el-row style="margin-top: 8%">
        <span style="font-size: 12px;color: #FFFFFF"><b>{{ $i('customerPanel.subtitle') }}</b></span>
      </el-row>
      <el-row style="margin-top: 10px">
        <div class="dark-mode-font" style="width: 100%;height: 60vh;margin: 10px auto auto auto;overflow-y: auto;">
          <el-card class="black-card" v-for="i in createObj.tradeAccounts" :key="i.id"
                   :style="'' + (i.id > 0 ? 'border-radius: 0' : 'border-radius: 3px 3px 0 0' ) + ';border-bottom: 0'">
            <el-row>
              <el-col :span="6">
                <span class="ellipsis-line" style="display: inline-block;float: left;margin-top: 8px;color: white"><b>{{
                    $i('accountMgnt.exchange')
                  }}</b></span>
              </el-col>
              <el-col :span="18" style="padding-left: 5px">
                <el-select class="createAccount" v-model="i.platform" placeholder="Select an exchange"
                           style="width: 100%;" value="">
                  <el-option
                      v-for="item in exchanges"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row style="margin-top: 5px">
              <el-col :span="6">
                <span class="ellipsis-line" style="display: inline-block;float: left;margin-top: 8px;color: white"><b>API KEY</b></span>
              </el-col>
              <el-col :span="18" style="padding-left: 5px">
                <el-input class="createAccount" style="float:left" size="medium" v-model="i.apiKey"></el-input>
              </el-col>
            </el-row>
            <el-row v-if="i.platform === 'OKX'" style="margin-top: 5px">
              <el-col :span="6">
                <span class="ellipsis-line" style="display: inline-block;float: left;margin-top: 8px;color: white"><b>Passphrase</b></span>
              </el-col>
              <el-col :span="18" style="padding-left: 5px">
                <el-input class="createAccount" style="float:left" size="medium" v-model="i.passphrase"></el-input>
              </el-col>
            </el-row>
            <el-row style="margin-top: 5px" v-if="i.platform === 'FTX'">
              <el-col :span="6">
                <span class="ellipsis-line" style="display: inline-block;float: left;margin-top: 8px;color: white"><b>SubName</b></span>
              </el-col>
              <el-col :span="18" style="padding-left: 5px">
                <el-input class="createAccount" style="float:left" size="medium" v-model="i.subName"></el-input>
              </el-col>
            </el-row>
            <el-row style="margin-top: 5px">
              <el-col :span="6">
                <span class="ellipsis-line" style="display: inline-block;float: left;margin-top: 8px;color: white"><b>API SECRET</b></span>
              </el-col>
              <el-col :span="18" style="padding-left: 5px">
                <el-input class="createAccount" style="float:left" size="medium" v-model="i.apiSecret"></el-input>
              </el-col>
            </el-row>
          </el-card>
          <el-row>
            <el-col :span="12">
              <el-button class="dark-mode-btn"
                         style="float: left;width: 100%;height: 35px;padding: 0;font-size: 12px;border-radius: 0;border-right: 0;border-bottom: 0"
                         @click="removeTradeAccount" :disabled="createObj.tradeAccounts.length <= 1 || adding">
                {{ $i('customerPanel.delA1') }}{{ createObj.tradeAccounts.length === 1 ? ('1' + $i('customerPanel.st')) : (createObj.tradeAccounts.length === 2 ? ('2' + $i('customerPanel.nd')) : (createObj.tradeAccounts.length === 3 ? ('3' + $i('customerPanel.rd')) : (createObj.tradeAccounts.length + $i('customerPanel.th')))) }}{{ $i('customerPanel.delA2') }}
              </el-button>
            </el-col>
            <el-col :span="12">
              <el-button class="dark-mode-btn"
                         style="float: right;width: 100%;height: 35px;padding: 0;font-size: 12px;border-radius: 0;text-align: center;border-bottom: 0"
                         @click="addTradeAccount" :disabled="adding">
                {{ $i('customerPanel.addA1') }}{{ (createObj.tradeAccounts.length === 1 ? ('2' + $i('customerPanel.nd')) : (createObj.tradeAccounts.length === 2 ? ('3' + $i('customerPanel.rd')) : ((createObj.tradeAccounts.length + 1) + $i('customerPanel.th')))) }}{{ $i('customerPanel.addA2') }}
              </el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-button class="dark-mode-btn"
                         style="float: right;width: 100%;height: 35px;padding: 0;font-size: 12px;border-radius: 0 0 3px 3px;text-align: center"
                         @click="create" :disabled="adding">
                {{ $i('customerPanel.submit') }}
              </el-button>
            </el-col>
          </el-row>
        </div>
      </el-row>
    </el-card>
    <ios-download ref="iosPage"></ios-download>
  </div>
</template>

<script>

import RouterPath from "@/router/RouterPath";
import IosDownload from "@/components/IosDownloadPage";
import CrmChannelManagement from "@/api/crm/CrmChannelManagement";
import {Message} from "element-ui";

export default {
  name: "CustomerKey",
  components: {IosDownload},
  data: function () {
    return {
      adding: false,
      randomID: '',
      basicCoinList: [
        'USDT',
        'USD',
        'BTC',
        'ETH',
        'LTC',
        'FIL',
        'DOT',
        'ETH_U',
        'BTC_U',
        'SOL_U',
      ],
      exchanges: [
        {
          name: 'BINANCE',
          value: 'BINANCE'
        }, {
          name: 'OKX',
          value: 'OKX'
        }, {
          name: 'DERIBIT',
          value: 'DERIBIT'
        }, {
          name: 'FTX',
          value: 'FTX'
        }
      ],
      createObj: {
        instanceName: '',
        tradeAccounts: [
          {
            id: 0,
            active: true,
            alias: '',
            subName: '',
            apiKey: '',
            apiSecret: '',
            passphrase: '',
            baseCoin: 'USD',
            currentBalance: 0,
            email: '',
            info: '',
            originBalance: 0,
            platform: 'BINANCE'
          }
        ]
      }
    };
  },
  created() {
    this.randomID = this.$route.query.randomID;
    let that = this;
    CrmChannelManagement.checkCasualId(this.randomID, function () {
      Message.info(that.$i('customerPanel.initInfo'));
    }, function () {
      that.$router.push({
        path: RouterPath.router.controlPanel
      });
    });
  },
  methods: {
    iosPage: function () {
      this.$refs['iosPage'].openDrawer();
    },
    addTradeAccount() {
      this.createObj.tradeAccounts.push(
          {
            id: this.createObj.tradeAccounts.length,
            active: true,
            alias: '',
            subName: '',
            apiKey: '',
            apiSecret: '',
            passphrase: '',
            baseCoin: 'USD',
            currentBalance: 0,
            email: '',
            info: '',
            originBalance: 0,
            platform: 'BINANCE'
          }
      );
    },
    removeTradeAccount() {
      this.createObj.tradeAccounts.pop();
    },
    create() {
      let that = this;
      for (const tradeAccountsKey in this.createObj.tradeAccounts) {
        if (this.createObj.tradeAccounts[tradeAccountsKey].apiKey === '') {
          Message.error('API key is empty');
          return;
        }
        if (this.createObj.tradeAccounts[tradeAccountsKey].apiSecret === '') {
          Message.error('API secret is empty');
          return;
        }
        if (this.createObj.tradeAccounts[tradeAccountsKey].platform === 'OKX' && this.createObj.tradeAccounts[tradeAccountsKey].passphrase === '') {
          Message.error('Passphrase is empty');
          return;
        }
      }
      that.adding = true;
      CrmChannelManagement.setApiInfo(this.createObj, this.randomID, function () {
        Message.success('Success');
        setTimeout(() => {
          that.$router.push({
            path: RouterPath.router.login
          });
          that.adding = false;
        }, 268);
      }, function (err) {
        Message.error(err);
        that.adding = false;
      });
    }
  }
};
</script>

<style lang="css" scoped>

.ellipsis-line {
  padding: 0;
  font-size: 13px;
  text-align: left;
  width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.login-logo-ft {
  text-align: center;
  margin: auto;
  height: 23px;
  width: 175px;
  background-image: url("https://abm-app-image.s3.ap-northeast-1.amazonaws.com/oneport/FT_NEW_TEXT_W.png");
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
}

.login-logo-ft-left {
  text-align: center;
  margin: auto;
  height: 45px;
  width: 45px;
  background-image: url("https://abm-app-image.s3.ap-northeast-1.amazonaws.com/oneport/FT_NEW_LOGO_W.png");
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
}

.login-card {
  width: 90%;
  max-width: 868px;
  height: 80vh;
  text-align: center;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.95;
  position: absolute;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}

li {
  list-style: none;
}

.createAccount .el-input__inner {
  color: white;
  height: 36px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-radius: 3px;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 2px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(119, 109, 109, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(119, 109, 109, 0.4);
}


</style>