<template>
  <div>

    <el-row class="hidden-sm-and-down" style="margin-top: 32px; width: 100%;position: static;">
      <el-col :span="24">
        <FtWalletExamineListPanel ref="elp" v-on:switch-change="onAction" />
      </el-col>
    </el-row>

    <el-row class="hidden-sm-and-down" style="margin-top: 32px; width: 100%;position: static;">
      <el-col :span="10">
        <FtWalletExamineMyParticipatedListPanel v-on:switch-change="onAction" />
        <FtWalletExamineMyCreatedListPanel v-on:switch-change="onAction" style="margin-top: 32px;" />
      </el-col>
      <el-col :span="14">
        <FtWalletExamineTransferRecordListPanel v-on:switch-change="onAction" style="margin-left: 32px;" />
      </el-col>
    </el-row>

  </div>
</template>

<script>
import UserPermissionService from "@/api/user/UserPermissionService";
import FtWalletExamineListPanel from "@/components/FtWalletExamineListPanel";
import FtWalletExamineMyParticipatedListPanel from "@/components/FtWalletExamineMyParticipatedListPanel";
import FtWalletExamineMyCreatedListPanel from "@/components/FtWalletExamineMyCreatedListPanel";
import FtWalletExamineTransferRecordListPanel from "@/components/FtWalletExamineTransferRecordListPanel";
import TrialphaLoading from "@/components/TrialphaLoading";

import { useWalletStore } from '@/store/wallet'

export default {
  components: {
    FtWalletExamineMyParticipatedListPanel,
    FtWalletExamineMyCreatedListPanel,
    FtWalletExamineListPanel,
    FtWalletExamineTransferRecordListPanel
  },
  data: function () {
    return {

      refreshStatus: true

    }
  },
  mounted() {

    this.refresh()

  },
  methods: {
    hasPermission(featureName) {
      return UserPermissionService.isAllowed(featureName);
    },

    refresh() {
      let that = this
      console.log('刷新页面=====')
      let loading = TrialphaLoading.loading(that)
      const walletStore = useWalletStore()
      walletStore.listPendingTransfers().then(() => {
        walletStore.listMyFinishedTransfers()
        walletStore.listWalletCreatedByRequester()
        walletStore.listWalletApprovedByRequester()

        that.$refs.elp.onFilterStatus()
        // that.refreshStatus = false
        // setTimeout(() => {
        //   that.refreshStatus = true
        // }, 10)
        setTimeout(() => {
          loading.close()
        }, 1000)
      }, () => {
        setTimeout(() => {
          loading.close()
        }, 1000)
      })
    },

    // 各种切换处理
    onAction(item) {
      this.$emit('switch-change', item)
    }

  }
}
</script>

<style>


</style>