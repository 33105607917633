var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel-scoped"},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('FtGroupButtonWidget',{staticStyle:{"background":"#3E454E"},attrs:{"buttons":[{
          id: 'append',
          text: '转账记录',
          textI18n: '',
      }],"mode":"single","textMode":"true"}})],1)],1),_c('div',{class:[(_vm.scrollHeight > 0 ? 'scroll-mode' : '')],style:((_vm.scrollHeight > 0 ? {height: _vm.scrollHeight + 'px'} : {}))},_vm._l((_vm.finishedTransfers),function(item){return _c('div',{key:item._index,staticStyle:{"display":"flex","margin-top":"20px"}},[_c('div',{staticClass:"ellipsis-line-tr",staticStyle:{"flex":"1","border":"1px solid #3E454E","border-radius":"8px","height":"30px","line-height":"26px","color":"#fff","font-size":"12px","text-align":"left","padding-left":"10px"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"padding-right":"15px"}},[_vm._v(" "+_vm._s(item.createTimeDate)+" ")]),_c('div',{staticStyle:{"flex":"1","padding-right":"15px"}},[_vm._v(" "+_vm._s(item.fromWalletHumanReadableName ? item.fromWalletHumanReadableName : item.fromWalletAddress)+" 向 "+_vm._s(item.toWalletHumanReadableName ? item.toWalletHumanReadableName : item.toWalletAddress)+" 转账 "+_vm._s(item.tokenAmount)+" "+_vm._s(item.tokenType)+" "),_c('span',{staticStyle:{"display":"inline-block","float":"right"}},[_vm._v(" "+_vm._s(item.statusHumanReadableName.indexOf('成功') > -1 ? '转账成功' : '转账失败')+" ")])])])]),_c('div',{staticStyle:{"padding-left":"20px"}},[_c('FtGroupButtonWidget',{staticStyle:{"background":"#3E454E"},attrs:{"buttons":[{
            id: 'transferDetail',
            text: '查看详情',
            textI18n: '',
        }],"mode":"single"},on:{"switch-change":function($event){return _vm.onAction({id: 'transferDetail', value: item})}}})],1)])}),0),_c('FtPagingWidget',{attrs:{"pageIndex":_vm.pageIndex,"pageSize":_vm.pageSize,"pageTotal":_vm.pageTotal},on:{"index-change":_vm.onPagingIndexChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }