<script>
import UserService from "@/api/user/UserService";
import Optional from "@/optional/Optional";

const tracupInfo = {
  'Tiny001': {
    userkey: 'b38ba4202a8f5431ab75c0a95c6ba24b',
    apikey: '7fa0976e42c18574e48ce01621b105df'
  },
  'jiajie': {
    userkey: '2c16c2f005273690f3fedc1e2fb4aaa1',
    apikey: '07c208264a8d6ac3db93b37cd7fa4cf5'
  },
  'Shawn': {
    userkey: '0ae9c8b579715549e520ee42ef630f64',
    apikey: 'f3af9f53e4ec5332ea7c12cbd4e101a5'
  },
  'Petrus': {
    userkey: '3467500b5bf45e61ad17a3ae5bdbe912',
    apikey: 'c2dd1e226f433b63433263d01f256473'
  },
  'Ming001': {
    userkey: '5e917d57eecf60ac13c47db9e71f1e51',
    apikey: '835a4dc0b52c7d171ec996e3b0950bd9'
  },
  'zhangxin': {
    userkey: '1d380e8c8d90c8ee237647216993125b',
    apikey: 'a81cc0e9328a9bad1fa504816de247ec'
  },
  'Trialpha.Admin': {
    userkey: 'a29ab69b8f7b70ad9f604274ccc482dc',
    apikey: 'ee1df80c9ed779a438f0a3f4f31d4290'
  },
  'zpl.joe': {
    userkey: 'cc5d96914f5192c6abbcd5bff616c7ac',
    apikey: '7b36f46d73d3111aaed8bc681a36c584'
  },
  'zsf001': {
    userkey: '1d380e8c8d90c8ee237647216993125b',
    apikey: 'a81cc0e9328a9bad1fa504816de247ec'
  },
  'dqlancer': {
    userkey: '1d380e8c8d90c8ee237647216993125b',
    apikey: 'a81cc0e9328a9bad1fa504816de247ec'
  },
  'defaultUser': {
    userkey: '627abb2df54c2688d8398882ddb20c5e',
    apikey: 'f1e6248ac5bbee9ba51179a57fd2004b'
  }
}

function getTracupUser() {
  let userName = UserService.getUserName();
  return Optional.orElse(() => {
    return tracupInfo[userName];
  }, tracupInfo['defaultUser']);
}

export default {
  getTracupUser
}
</script>