<template>
  <el-card class="black-card" style="width: 100%;height: 100vh;overflow-y: auto;border: 0;background-color: #1c2128" shadow="none" body-style="padding: 58px 15px 78px 15px">
    <el-collapse class="trialpha-collapse" v-model="createCollapse" style="border: 0;margin-top: 0" @change="change">
      <el-collapse-item class="trialpha-collapse">
        <template slot="title">
          <i class="el-icon-user-solid" style="margin-left: 2px;color: white;margin-top: 1px;border: 0"/><h4 class="dark-mode-font" style="margin-left: 10px;padding-top: 6px;color: white">{{ $i('userInfo.userInfoSetting') }}</h4>
        </template>
        <div>
          <el-row style="margin-top: 25px">
            <el-col :span="8" style="padding-left: 0">
              <span class="dark-mode-font" style="color: white;float: left;font-size: 12px;margin-top: 4px">{{ $i('userInfo.alias') }}</span>
            </el-col>
            <el-col :span="16" style="padding-right: 0">
              <input class="black-card" style="width: 100%;outline: none;color: white;padding: 8px 10px 8px 10px;font-size: 12px;height: 28px;line-height: 28px" v-model="userObj.userInfo.alias" />
            </el-col>
          </el-row>
          <el-row style="margin-top: 15px">
            <el-col :span="8" style="padding-left: 0">
              <span class="dark-mode-font" style="color: white;float: left;font-size: 12px;margin-top: 4px">{{ $i('userInfo.email') }}</span>
            </el-col>
            <el-col :span="16" style="padding-right: 0">
              <input class="black-card" style="width: 100%;outline: none;color: white;padding: 8px 10px 8px 10px;font-size: 12px;height: 28px;line-height: 28px" v-model="userObj.userInfo.email" />
            </el-col>
          </el-row>
          <el-row style="margin-top: 15px">
            <el-col :span="8" style="padding-left: 0">
              <span class="dark-mode-font" style="color: white;float: left;font-size: 12px;margin-top: 4px">{{ $i('userInfo.username') }}</span>
            </el-col>
            <el-col :span="16" style="padding-right: 0">
              <input class="black-card" style="width: 100%;outline: none;color: white;padding: 8px 10px 8px 10px;font-size: 12px;height: 28px;line-height: 28px" v-model="userObj.userInfo.username" />
            </el-col>
          </el-row>
          <el-row style="margin-top: 15px">
            <el-col :span="8" style="padding-left: 0">
              <span class="dark-mode-font" style="color: white;float: left;font-size: 12px;margin-top: 4px">{{ $i('userInfo.password') }}</span>
            </el-col>
            <el-col :span="16" style="padding-right: 0">
              <input class="black-card" style="width: 100%;outline: none;color: white;padding: 8px 10px 8px 10px;font-size: 12px;height: 28px;line-height: 28px" type="password" v-model="oldPassword" />
            </el-col>
          </el-row>
          <el-row style="margin-top: 15px">
            <el-col :span="8" style="padding-left: 0">
              <span class="dark-mode-font" style="color: white;float: left;font-size: 12px;margin-top: 4px">{{ $i('userInfo.newPassword') }}</span>
            </el-col>
            <el-col :span="16" style="padding-right: 0">
              <input class="black-card" style="width: 100%;outline: none;color: white;padding: 8px 10px 8px 10px;font-size: 12px;height: 28px;line-height: 28px" type="password" v-model="newPassword" />
            </el-col>
          </el-row>
          <el-row style="margin-top: 15px">
            <el-col :span="8" style="padding-left: 0">
              <span class="dark-mode-font" style="color: white;float: left;font-size: 12px;margin-top: 4px">{{ $i('userInfo.verification') }}</span>
            </el-col>
            <el-col :span="10" style="">
              <input class="black-card" style="width: 100%;outline: none;color: white;padding: 8px 10px 8px 10px;font-size: 12px;height: 28px;line-height: 28px;border-radius: 3px 0 0 3px;border-right: 0" v-model="code" />
            </el-col>
            <el-col :span="6" style="padding-right: 0">
              <el-button class="dark-mode-btn" style="height: 28px;line-height: 28px;padding: 0 10px 0 10px;width: 100%;border-radius: 0 3px 3px 0" @click="sendVerification" :disabled="sending">{{ sending ? resend : $i('userInfo.send') }}</el-button>
            </el-col>
          </el-row>
          <el-row style="margin-top: 15px">
            <el-col :span="24" style="padding-left: 0;padding-right: 0">
              <el-button class="dark-mode-btn" style="height: 32px;line-height: 32px;padding: 0 10px 0 10px;width: 100%;border-radius: 3px;font-size: 13px" @click="update" :loading="processing">{{ $i('userInfo.update') }}</el-button>
            </el-col>
          </el-row>
        </div>
      </el-collapse-item>
    </el-collapse>
    <el-select class="mobile-user-mgnt-select" v-model="selectLang" placeholder="请选择" size="medium" style="width: 100%;float: right;margin-right: 0;margin-top: 10px" @change="updateLang">
      <el-option
          v-for="item in langList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>
    <el-button class="dark-mode-btn" @click="logout" style="margin-top: 10px;width: 100%;margin-bottom: 10px">{{ $i('login.logout') }}</el-button>
  </el-card>
</template>

<script>

import I18NService from "@/i18n/I18NService";
import UserService from "@/api/user/UserService";
import CacheService from "@/cache/CacheService";
import RouterPath from "@/router/RouterPath";
import {Message} from "element-ui";
import AdminUserManagement from "@/api/user/AdminUserManagement";

export default {
  name: "MobileUserManagement",
  data: function () {
    return {
      createCollapse: ['1'],
      selectLang: I18NService.lang,
      langList: [
        {
          value: 'English',
          label: this.$i('lang_english')
        },{
          value: 'China',
          label: this.$i('lang_china')
        },{
          value: 'Japan',
          label: this.$i('lang_japan')
        },{
          value: 'Korea',
          label: this.$i('lang_korea')
        },{
          value: 'Thailand',
          label: this.$i('lang_thailand')
        }
      ],
      userObj: UserService.getUserObj(),
      oldPassword: '',
      newPassword: '',
      sending: false,
      resend: 60,
      processing: false,
      code: ''
    }
  },
  methods: {
    change: function (string) {
      if (string[0] === '1') {
        this.active = !this.active;
      }
    },
    updateLang: function (lang) {
      this.setLanguage(lang);
    },
    sendVerification: function () {
      if (this.sending) {
        return;
      }
      if (this.oldPassword !== this.userObj.password){
        Message.error('Your current password verification failed')
        return;
      }
      this.sending = true;
      let sendTimer = setInterval(() => {
        this.resend--;
        if (this.resend <= 0) {
          this.resend = 60;
          this.sending = false;
          clearInterval(sendTimer);
        }
      }, 1000);
      let that = this;
      AdminUserManagement.getVerificationCode(function () {
        Message.success('Verification code already send to you email: ' + that.userObj.userInfo.email);
      });
    },
    update: function () {
      let req = {
        id: this.userObj.userInfo.id,
        email: this.userObj.userInfo.email,
        alias: this.userObj.userInfo.alias,
        code: this.code,
        username: this.userObj.userInfo.username,
        password: this.newPassword
      }
      if (!req.email || !req.alias || !req.code || !req.username) {
        Message.warning('Required field not filled');
        return;
      }
      if (this.oldPassword !== this.userObj.password){
        Message.error('Your current password verification failed')
        return;
      }
      let that = this;
      that.processing = true;
      AdminUserManagement.updateUserInfo(req, function () {
        Message.success('Update success, please re-login');
        setTimeout(() => {
          that.isLogin = false;
          UserService.logout(() => {
            CacheService.clear();
            that.$router.push({path: RouterPath.router.login});
          }, () => {
            CacheService.clear();
            that.$router.push({path: RouterPath.router.login});
          });
        }, 568);
      }, function () {
        that.processing = false;
      });
    },
    logout: function () {
      this.isLogin = false;
      this.$message(this.$i('login.logoutSuccess'));
      let that = this;
      UserService.logout(() => {
        CacheService.clear();
        that.$router.push({path: RouterPath.router.login});
      }, () => {
        CacheService.clear();
        that.$router.push({path: RouterPath.router.login});
      });
    }
  }
}
</script>

<style scoped>

.mobile-user-mgnt-select .el-input__inner {
  color: white;
  height: 35px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-radius: 3px;
  font-size: 12px;
}

.el-collapse {
  color: #FFFFFF;
  background-color: #22272e;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: white;
}

.el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #373e47 !important;
  border: #444c56 1px solid;
  border-radius: 3px;
  height: 40px;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold',serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

</style>