<template>
  <div :dto="dto">
    <el-skeleton animated :loading="rendering" :throttle="268">
      <template slot="template">
        <trialpha-sk style="margin-top: 0;width: 60%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 40%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 100%;float: left"></trialpha-sk>
      </template>
      <template>
        <el-row>
          <el-collapse class="trialpha-collapse" v-model="activeNames" style="border: 0">
            <el-collapse-item class="trialpha-collapse" style="border: 0"
                              :name="ins.strategyConfig.symbol"
                              v-for="ins in instanceList"
                              :key="ins.strategyConfig.symbol">
              <template slot="title">
                <el-row style="width: 100%;display: flex">
                  <el-card class="black-card" body-style="padding: 0"
                           style="line-height: 46px;margin-left: -10px;margin-top: 9px;border-left: 0;border-top: 0;border-bottom: 0;border-radius: 0;height: 46px;width: 68px;background-color: #444c56;text-align: center">
                      <span class="dark-mode-font" style=";font-size: 12px;width: 50px">{{
                          getPrice(ins.strategyConfig)
                        }}</span>
                  </el-card>
                  <div style="flex: 1">
                    <el-row>
                      <el-col :span="6" style="display: flex">
                        <img :src="exchangeInfo[ins.strategyConfig.symbolPlatform].logo" style="width: 18px;height: 18px;margin-top: 23px;margin-left: 12px;float: left;"/>
                        <div style="flex: 1">
                          <el-row>
                            <el-col :span="4">
                              <i class="header-icon" v-bind:class="{'el-icon-caret-bottom': (!ins.strategyConfig.isLong), ' el-icon-caret-top': ins.strategyConfig.isLong}"
                                 :style="'float:right;margin-top:26px;font-weight: bolder;margin-right: 5px;' + (!ins.strategyConfig.isLong ? 'color: #f6465d' : 'color:#0ecb81') + ''"/>
                            </el-col>
                            <el-col :span="20">
                              <span class="dark-mode-font ellipsis-line" :style="'display: inline-block;float: left;margin-left: 0;' + (ins.isActive ? 'color: #FFFFFF' : '') + ''">{{ ins.strategyConfig.symbol }}</span>
                            </el-col>
                          </el-row>
                        </div>
                      </el-col>
                      <el-col :span="7" style="margin-top: 16px">
                        <el-row style="padding: 0;line-height: 15px;height: 15px">
                          <el-col :span="10" style="max-width: 110px">
                            <span class="dark-mode-font ellipsis-line" style="font-family: 'Microsoft YaHei',serif;padding: 0;line-height: 15px;height: 15px;display: inline-block;float: left;margin-right: 0;text-align: left;font-size: 12px;color: #FFFFFF">
                              Position
                            </span>
                          </el-col>
                          <el-col :span="12">
                            <span class="dark-mode-font ellipsis-line" style="font-family: 'Microsoft YaHei',serif;padding: 0;line-height: 15px;height: 15px;display: inline-block;float: left;margin-right: 0;text-align: left;font-size: 12px;color: #FFFFFF">
                              {{ins.strategyConfig.baseCoinHoldAmount }} ({{ ins.strategyConfig.currentLevel }})
                            </span>
                          </el-col>
                        </el-row>
                        <el-row style="padding: 0;line-height: 15px;height: 15px;margin-top: 2px">
                          <el-col :span="10" style="max-width: 110px">
                            <span class="dark-mode-font ellipsis-line" style="font-family: 'Microsoft YaHei',serif;padding: 0;line-height: 15px;height: 15px;display: inline-block;float: left;margin-right: 0;text-align: left;font-size: 12px;color: #FFFFFF">
                              Entry Price
                            </span>
                          </el-col>
                          <el-col :span="12">
                            <span class="dark-mode-font ellipsis-line" style="font-family: 'Microsoft YaHei',serif;padding: 0;line-height: 15px;height: 15px;display: inline-block;float: left;margin-right: 0;text-align: left;font-size: 12px;color: #FFFFFF">
                              {{ (ins.strategyConfig.baseCoinTotalCost / ins.strategyConfig.baseCoinHoldAmount).toFixed(4) }}
                            </span>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="7" style="margin-top: 16px;padding-left: 5px">
                        <el-row>
                          <el-col :span="10" style="max-width: 110px">
                            <span class="dark-mode-font ellipsis-line" style="font-family: 'Microsoft YaHei',serif;padding: 0;line-height: 15px;height: 15px;display: inline-block;float: left;margin-right: 0;text-align: left;font-size: 12px;color: #FFFFFF">
                              Trade Times
                            </span>
                          </el-col>
                          <el-col :span="12">
                            <span class="dark-mode-font ellipsis-line" style="font-family: 'Microsoft YaHei',serif;padding: 0;line-height: 15px;height: 15px;display: inline-block;float: left;margin-right: 0;text-align: left;font-size: 12px;color: #FFFFFF">
                              {{ (ins.strategyConfig.tradeTimes) }}
                            </span>
                          </el-col>
                        </el-row>
                        <el-row style="padding: 0;line-height: 15px;height: 15px;margin-top: 2px">
                          <el-col :span="10" style="max-width: 110px">
                            <span class="dark-mode-font ellipsis-line" style="font-family: 'Microsoft YaHei',serif;padding: 0;line-height: 15px;height: 15px;display: inline-block;float: left;margin-right: 0;text-align: left;font-size: 12px;color: #FFFFFF">
                              Profit Times
                            </span>
                          </el-col>
                          <el-col :span="12">
                            <span class="dark-mode-font ellipsis-line" style="font-family: 'Microsoft YaHei',serif;padding: 0;line-height: 15px;height: 15px;display: inline-block;float: left;margin-right: 0;text-align: left;font-size: 12px;color: #ffffff">
                              {{ ins.strategyConfig.closeTimes}} ({{((ins.strategyConfig.closeTimes / ins.strategyConfig.openTimes) * 100).toFixed(2) }}%)
                            </span>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="4" style="margin-top: 16px;padding-right: 2px">
                        <el-row>
                            <span class="dark-mode-font ellipsis-line" style=";padding: 0;line-height: 15px;height: 15px;display: inline-block;float: right;margin-right: 0;text-align: right;font-size: 13px;color: #FFFFFF">
                              ${{ ins.strategyConfig.quoteMax }}
                            </span>
                        </el-row>
                        <el-row style="padding: 0;line-height: 15px;height: 15px;margin-top: 2px">
                          <span class="dark-mode-font ellipsis-line" style="font-family: 'Microsoft YaHei',serif;padding: 0;line-height: 15px;height: 15px;display: inline-block;float: right;margin-right: 0;text-align: right;color: #0ecb81;font-size: 12px">
                            ${{ ins.strategyConfig.totalProfit.toFixed(2) }} ({{ ((ins.strategyConfig.totalProfit / ins.strategyConfig.quoteMax) * 100).toFixed(2) }}%)
                          </span>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </el-row>
              </template>
              <el-row style="height: 568px;overflow-y: auto">
                <el-row style="width: 98%;margin-top: 8px;margin-left: auto;margin-right: auto;">
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">实例状态 (ID: {{ ins.sid }})</span>
                  </el-col>
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">多空方向</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <el-select class="select-symbol" style="width: 100%" v-model="ins.isActive" placeholder="请选择">
                      <el-option
                          v-for="item in [{value: true, label: 'Active'}, {value: false, label: 'Inactive'}]"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="12" :offset="1">
                    <el-select class="select-symbol" style="width: 100%" v-model="ins.strategyConfig.isLong" placeholder="请选择">
                      <el-option
                          v-for="item in [{value: true, label: 'Long'}, {value: false, label: 'Short'}]"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 8px;margin-left: auto;margin-right: auto;">
                  <el-col :span="6">
                    <span class="dark-mode-font"
                          style="color: white;font-size: 12px">{{ ins.strategyConfig.quoteAsset }}最大投入</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">获利后是否停止</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">总成交次数</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">总成交额预估(USD)</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="6" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.quoteMax"/>
                  </el-col>
                  <el-col :span="5">
                    <el-select class="select-symbol" style="width: 100%" v-model="ins.strategyConfig.stopAfterSell"
                               placeholder="请选择">
                      <el-option
                          v-for="item in [{value: true, label: '获利后停止'}, {value: false, label: '获利后继续'}]"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="6" :offset="1" style=";padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" disabled :value="(ins.strategyConfig.tradeTimes)"/>
                  </el-col>
                  <el-col :span="6">
                    <el-input class="trialpha-dark-input-no-pre" disabled
                              :value="(ins.strategyConfig.totalVol).toFixed(4)"/>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="4">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">初始美元</span>
                  </el-col>
                  <el-col :span="4">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">最大美元</span>
                  </el-col>
                  <el-col :span="3">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">增长率(%)</span>
                  </el-col>
                  <el-col :span="4" :offset="1">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">初始间距(%)</span>
                  </el-col>
                  <el-col :span="4">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">最大间距(%)</span>
                  </el-col>
                  <el-col :span="4">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">间距增长率(%)</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="4" style="display: flex;padding-right: 8px">
                    <el-tooltip class="item" effect="dark" content="初次加仓的时候使用的美元额度" placement="top-start" :open-delay="666">
                      <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.openUsd"/>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="4" style="display: flex;padding-right: 8px">
                    <el-tooltip class="item" effect="dark" content="单次加仓的时候最大允许使用的美元额度" placement="top-start" :open-delay="666">
                      <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.maxUsd"/>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="3" style="display: flex">
                    <el-tooltip class="item" effect="dark" content="每一层美元额度的增长率（当增长额大于最大美元额度时，以最大美元额度为准）" placement="top-start" :open-delay="666">
                      <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.positionIncreaseRate"/>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="4" :offset="1" style="display: flex;padding-right: 8px">
                    <el-tooltip class="item" effect="dark" content="初次挂单的间距" placement="top-start" :open-delay="666">
                      <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.gridGap"/>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="4" style="display: flex;padding-right: 8px">
                    <el-tooltip class="item" effect="dark" content="最大的挂单间距" placement="top-start" :open-delay="666">
                      <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.maxGridGap"/>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="4" style="display: flex">
                    <el-tooltip class="item" effect="dark" content="挂单的间距增长率" placement="top-start" :open-delay="666">
                      <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.gridGapIncreaseRate"/>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">当前轮次加仓次数</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">加减仓统计</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font"
                          style="color: white;font-size: 12px">理论持仓</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font"
                          style="color: white;font-size: 12px">持仓成本</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="6" style="display: flex;padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" :value="ins.strategyConfig.currentLevel" disabled/>
                  </el-col>
                  <el-col :span="5" style="display: flex">
                    <el-input class="trialpha-dark-input-no-pre"
                              :value="ins.strategyConfig.openTimes + ' / ' + ins.strategyConfig.closeTimes + ' / ' + ((ins.strategyConfig.closeTimes / ins.strategyConfig.openTimes) * 100).toFixed(2) + '%'"
                              disabled/>
                  </el-col>
                  <el-col :span="6" :offset="1" style="display: flex;padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.baseCoinHoldAmount"
                              disabled/>
                  </el-col>
                  <el-col :span="6" style="display: flex;">
                    <el-input class="trialpha-dark-input-no-pre"
                              :value="ins.strategyConfig.baseCoinTotalCost / ins.strategyConfig.baseCoinHoldAmount"
                              disabled/>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 8px;margin-left: auto;margin-right: auto;">
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">预计最终买入价</span>
                  </el-col>
                  <el-col :span="5">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">预计剩余幅度</span>
                  </el-col>
                  <el-col :span="6" :offset="1">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">已获取利润(USD)</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="6" style=";padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" disabled
                              :value="(ins.strategyConfig.simulateLowestPrice).toFixed(6)"/>
                  </el-col>
                  <el-col :span="5">
                    <el-input class="trialpha-dark-input-no-pre" disabled
                              :value="(ins.strategyConfig.simulateLowestPricePercent).toFixed(2) + '%'"/>
                  </el-col>
                  <el-col :span="12" :offset="1">
                    <el-input class="trialpha-dark-input-no-pre" disabled
                              :value="(ins.strategyConfig.totalProfit).toFixed(2)"/>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 8px;margin-left: auto;margin-right: auto;">
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">下次加仓位置</span>
                  </el-col>
                  <el-col :span="5">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">剩余幅度</span>
                  </el-col>
                  <el-col :span="6" :offset="1">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">清仓位置</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">剩余幅度</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="6" style=";padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" disabled
                              :value="(ins.strategyConfig.openOrderInfo) ? ins.strategyConfig.openOrderInfo.realCostPrice : '0.0'"/>
                  </el-col>
                  <el-col :span="5">
                    <el-input class="trialpha-dark-input-no-pre" disabled
                              :value="((ins.strategyConfig.openOrderInfo) ? ((Math.abs(getPrice(ins.strategyConfig) - ins.strategyConfig.openOrderInfo.realCostPrice) / ins.strategyConfig.openOrderInfo.realCostPrice) * 100).toFixed(2) : '0.0') + '%'"/>
                  </el-col>
                  <el-col :span="6" :offset="1" style="padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" disabled
                              :value="(ins.strategyConfig.closeOrderInfo) ? ins.strategyConfig.closeOrderInfo.realCostPrice : '0.0'"/>
                  </el-col>
                  <el-col :span="6">
                    <el-input class="trialpha-dark-input-no-pre" disabled
                              :value="((ins.strategyConfig.closeOrderInfo) ? ((Math.abs(ins.strategyConfig.closeOrderInfo.realCostPrice - getPrice(ins.strategyConfig)) / getPrice(ins.strategyConfig)) * 100).toFixed(2) : '0.0') + '%'"/>
                  </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row style="margin-top: 20px;padding-left: 10px;padding-right: 10px">
                  <el-card class="black-card"
                           style="border-radius: 3px 3px 0 0;border-bottom: 0;background-color: #1c2128"
                           body-style="padding: 10px" shadow="none">
                    <el-row>
                      <el-col :span="8">
                        <span class="dark-mode-font" style="color: white">当前投入记录</span>
                      </el-col>
                    </el-row>
                  </el-card>
                  <el-card class="black-card" style="border-radius: 0;height: 28px;background-color: #1c2128"
                           shadow="none" body-style="padding: 4px 10px 3px 10px">
                    <el-row>
                      <el-col :span="3">
                        <span class="dark-mode-font"
                              style="display: inline-block;float: left;text-align: left;font-size: 12px;color:darkgray ">加仓次数</span>
                      </el-col>
                      <el-col :span="4">
                        <span class="dark-mode-font"
                              style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">成交均价</span>
                      </el-col>
                      <el-col :span="5">
                        <span class="dark-mode-font"
                              style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">成交数量</span>
                      </el-col>
                      <el-col :span="12">
                        <span class="dark-mode-font"
                              style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">成交时间</span>
                      </el-col>
                    </el-row>
                  </el-card>
                  <el-card class="black-card" style="border-radius: 0;border-top: 0;max-height: 224px;overflow-y: auto"
                           shadow="none" body-style="padding: 0">
                    <div v-if="ins.strategyConfig.orderRecordList.length > 0">
                      <el-card class="black-card-hover" shadow="none" style="border: 0;border-radius: 0"
                               body-style="padding:0" v-for="(p, index) in ins.strategyConfig.orderRecordList"
                               :key="index">
                        <el-row style="height: 25px;border-bottom: 1px solid #2e3033;padding: 3px 10px 3px 10px">
                          <el-col :span="3">
                            <span class="dark-mode-font"
                                  style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{
                                p['level']
                              }}</span>
                          </el-col>
                          <el-col :span="4">
                            <span class="dark-mode-font"
                                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{
                                p['price']
                              }}</span>
                          </el-col>
                          <el-col :span="5">
                            <span class="dark-mode-font"
                                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{
                                p['amount']
                              }}</span>
                          </el-col>
                          <el-col :span="12">
                            <span class="dark-mode-font"
                                  style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{
                                new Date(p['time'])
                              }}</span>
                          </el-col>
                        </el-row>
                      </el-card>
                    </div>
                    <div v-if="ins.strategyConfig.orderRecordList.length === 0">
                      <el-row
                          style="height: 25px;border: 0;background-color: rgba(0, 0, 0, 0);padding: 3px 10px 3px 10px;text-align: center;margin-top: 20px;margin-bottom: 25px">
                        <span class="dark-mode-font" style=";font-size: 12px;color:gray;text-align: center;"><i
                            class="el-icon-s-order"/> EMPTY ...</span>
                      </el-row>
                    </div>
                  </el-card>
                </el-row>
                <el-divider></el-divider>
                <el-row style="width: 98%;margin: 10px auto">
                  <el-col :span="12">
                    <el-button class="dark-mode-btn"
                               style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 3px 0 0 0;border-right: 0"
                               @click="reset(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i
                        class="el-icon-c-scale-to-original" style="font-size: 16px"/></el-button>
                  </el-col>
                  <el-col :span="12">
                    <el-button class="dark-mode-btn"
                               style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0 3px 0 0"
                               @click="deleteIns(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i
                        class="el-icon-delete"
                        style="font-size: 16px"/>
                    </el-button>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin: -10px auto auto;">
                  <el-col :span="24">
                    <el-button class="dark-mode-btn"
                               style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0 0 3px 3px;border-top: 0"
                               @click="save(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i
                        class="el-icon-check"
                        style="font-size: 16px"/>
                    </el-button>
                  </el-col>
                </el-row>
              </el-row>
            </el-collapse-item>
          </el-collapse>
          <el-collapse class="trialpha-collapse" v-model="createCollapse" style="border-top: 0">
            <el-collapse-item class="trialpha-collapse">
              <template slot="title">
                <i class="el-icon-menu" style="margin-left: 2px;color: #0ecb81;margin-top: 1px"/><h4
                  class="dark-mode-font" style="margin-left: 8px;padding-top: 6px;color: white">Create New Instance</h4>
              </template>
              <el-row style="width: 98%;margin: 20px auto 0 auto">
                <el-col :span="8" style="padding-right: 8px">
                  <span class="dark-mode-font" style="color: white">Platform</span>
                </el-col>
                <el-col :span="8" style="padding-right: 8px">
                  <span class="dark-mode-font" style="color: white">Symbol</span>
                </el-col>
              </el-row>
              <el-row style="width: 98%;margin-left: 10px;margin-top: 3px">
                <el-col :span="8" style="padding-right: 8px">
                  <el-select class="chose-symbol select-symbol" filterable v-model="instance.symbolPlatform"
                             placeholder="Platform 1" style="width: 100%;line-height: 35px"
                             @hook:mounted="closeReadOnly" @focus="closeReadOnly" @visible-change="closeReadOnly"
                             @change="syncSymbolList(instance)">
                    <el-option
                        v-for="item in (platforms)"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="16">
                  <el-select class="chose-symbol select-symbol" filterable v-model="instance.symbol"
                             placeholder="Symbol" style="width: 100%;line-height: 35px">
                    <el-option
                        v-for="item in (symbolArrays)"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-row style="height: 88px;overflow-y: auto">
                <el-divider></el-divider>
                <el-row style="width: 98%;margin: 10px auto">
                  <el-col :span="24">
                    <el-button class="dark-mode-btn"
                               style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 3px"
                               @click="add(instance)" :disabled="rendering" :loading="processing" v-auth:dbWriteApi><i
                        class="el-icon-check"
                        style="font-size: 16px"/>
                    </el-button>
                  </el-col>
                </el-row>
              </el-row>
            </el-collapse-item>
          </el-collapse>
          <el-collapse class="trialpha-collapse" v-model="calculateCollapse" style="border-top: 0">
            <el-collapse-item class="trialpha-collapse">
              <template slot="title">
                <i class="el-icon-s-platform" style="margin-left: 2px;color: #0ecb81;margin-top: 1px"/><h4
                  class="dark-mode-font" style="margin-left: 8px;padding-top: 6px;color: white">Calculator</h4>
              </template>
              <el-row style="margin-top: 10px">
                <el-col :span="8">
                  <span class="dark-mode-font" style="font-size: 13px">总投入本金(USD)</span>
                </el-col>
                <el-col :span="8">
                  <span class="dark-mode-font" style="font-size: 13px">当前币价</span>
                </el-col>
                <el-col :span="6">
                  <span class="dark-mode-font" style="font-size: 13px">多空选择</span>
                </el-col>
              </el-row>
              <el-row style="margin-top: 5px">
                <el-col :span="8" style="padding-right: 8px">
                  <el-input class="trialpha-dark-input-no-pre" v-model="calculate.quoteCoinUsdBalance"/>
                </el-col>
                <el-col :span="8" style="padding-right: 8px">
                  <el-input class="trialpha-dark-input-no-pre" v-model="calculate.currentPrice"/>
                </el-col>
                <el-col :span="8">
                  <el-select class="select-symbol" style="width: 100%" v-model="calculate.isLong" placeholder="请选择">
                    <el-option
                        v-for="item in [{value: true, label: 'Long'}, {value: false, label: 'Short'}]"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-row style="margin-top: 10px">
                <el-col :span="8">
                  <span class="dark-mode-font" style="font-size: 13px">初次投入(USD)</span>
                </el-col>
                <el-col :span="8">
                  <span class="dark-mode-font" style="font-size: 13px">单次最大投入(USD)</span>
                </el-col>
                <el-col :span="8">
                  <span class="dark-mode-font" style="font-size: 13px">投入增长率(%)</span>
                </el-col>
              </el-row>
              <el-row style="margin-top: 5px">
                <el-col :span="8" style="padding-right: 8px">
                  <el-input class="trialpha-dark-input-no-pre" v-model="calculate.openUsd"/>
                </el-col>
                <el-col :span="8" style="padding-right: 8px">
                  <el-input class="trialpha-dark-input-no-pre" v-model="calculate.maxUsd"/>
                </el-col>
                <el-col :span="8" style="padding-right: 0">
                  <el-input class="trialpha-dark-input-no-pre" v-model="calculate.positionIncreaseRate"/>
                </el-col>
              </el-row>
              <el-row style="margin-top: 10px">
                <el-col :span="8">
                  <span class="dark-mode-font" style="font-size: 13px">初始间距(%)</span>
                </el-col>
                <el-col :span="8">
                  <span class="dark-mode-font" style="font-size: 13px">最大间距(%)</span>
                </el-col>
                <el-col :span="8">
                  <span class="dark-mode-font" style="font-size: 13px">间距增长率(%)</span>
                </el-col>
              </el-row>
              <el-row style="margin-top: 5px">
                <el-col :span="8" style="padding-right: 8px">
                  <el-input class="trialpha-dark-input-no-pre" v-model="calculate.baseGrid"/>
                </el-col>
                <el-col :span="8" style="padding-right: 8px">
                  <el-input class="trialpha-dark-input-no-pre" v-model="calculate.maxGrid"/>
                </el-col>
                <el-col :span="8">
                  <el-input class="trialpha-dark-input-no-pre" v-model="calculate.gridIncreaseRate"/>
                </el-col>
              </el-row>
              <el-row style="margin-top: 20px">
                <el-button class="dark-mode-btn" size="small" style="width: 100%" @click="calculateReport">Report
                </el-button>
              </el-row>
              <el-row style="margin-top: 20px">
                <el-card class="black-card"
                         style="border-radius: 3px 3px 0 0;border-bottom: 0;background-color: #1c2128"
                         body-style="padding: 10px" shadow="none">
                  <el-row>
                    <el-col :span="8">
                      <span class="dark-mode-font" style="color: white">Report</span>
                    </el-col>
                  </el-row>
                </el-card>
                <el-card class="black-card" style="border-radius: 0;height: 28px;background-color: #1c2128"
                         shadow="none" body-style="padding: 4px 10px 3px 10px">
                  <el-row>
                    <el-col :span="3">
                      <span class="dark-mode-font"
                            style="display: inline-block;float: left;text-align: left;font-size: 12px;color:darkgray ">加仓次数</span>
                    </el-col>
                    <el-col :span="3">
                      <span class="dark-mode-font"
                            style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">总投入</span>
                    </el-col>
                    <el-col :span="4">
                      <span class="dark-mode-font"
                            style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">本次投入</span>
                    </el-col>
                    <el-col :span="4">
                      <span class="dark-mode-font"
                            style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">本次间距</span>
                    </el-col>
                    <el-col :span="4">
                      <span class="dark-mode-font"
                            style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">投入价格</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="dark-mode-font"
                            style="display: inline-block;float: right;text-align: right;font-size: 12px;color:darkgray ">预测成本(实际优于预测)</span>
                    </el-col>
                  </el-row>
                </el-card>
                <el-card class="black-card" style="border-radius: 0;border-top: 0;max-height: 224px;overflow-y: auto"
                         shadow="none" body-style="padding: 0">
                  <div v-if="report.length > 0">
                    <el-card class="black-card-hover" shadow="none" style="border: 0;border-radius: 0"
                             body-style="padding:0" v-for="(p, index) in report" :key="index">
                      <el-row style="height: 25px;border-bottom: 1px solid #2e3033;padding: 3px 10px 3px 10px">
                        <el-col :span="3">
                          <span class="dark-mode-font"
                                style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{
                              p['level']
                            }}</span>
                        </el-col>
                        <el-col :span="3">
                          <span class="dark-mode-font"
                                style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{
                              p['totalCost']
                            }}</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font"
                                style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{
                              p['levelAddUsd']
                            }}</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font"
                                style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{
                              p['levelGridGap']
                            }}</span>
                        </el-col>
                        <el-col :span="4">
                          <span class="dark-mode-font"
                                style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{
                              p['addPrice']
                            }}</span>
                        </el-col>
                        <el-col :span="6">
                          <span class="dark-mode-font"
                                style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{
                              p['entryPrice']
                            }}</span>
                        </el-col>
                      </el-row>
                    </el-card>
                  </div>
                  <div v-if="report.length === 0">
                    <el-row
                        style="height: 25px;border: 0;background-color: rgba(0, 0, 0, 0);padding: 3px 10px 3px 10px;text-align: center;margin-top: 20px;margin-bottom: 25px">
                      <span class="dark-mode-font" style=";font-size: 12px;color:gray;text-align: center;"><i
                          class="el-icon-s-order"/> EMPTY ...</span>
                    </el-row>
                  </div>
                </el-card>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-row>
      </template>
      <trade-tools ref="open-tools"></trade-tools>
    </el-skeleton>
  </div>
</template>

<script>
import {Message} from "element-ui";
import StrategyInstanceService from "@/api/trade/StrategyInstanceService";
import ConfirmProvider from "@/components/ConfirmProvider";
import TradeTools from "@/pages/controlPanel/accountMgnt/strategies/openTask/ftx/TradeTools";
import TrialphaSk from "@/components/TrialphaSk";
import GeneralApiService from "@/api/trade/GeneralApiService";
import SymbolInfoService from "@/utils/SymbolInfoService";
import ExchangeInformations from "@/const/ExchangeInformations";

export default {
  name: "CoinContractVolatility",
  components: {TrialphaSk, TradeTools},
  props: {
    dto: Object
  },
  data: function () {
    return {
      exchangeInfo: {
        BINANCE: {
          logo: ExchangeInformations.exchangeInfos.BINANCE.icon,
          fontColor: '#f5bc2f'
        },
        BITMEX: {
          logo: ExchangeInformations.exchangeInfos.BITMEX.icon,
          fontColor: '#5b69b1'
        },
        DERIBIT: {
          logo: ExchangeInformations.exchangeInfos.DERIBIT.icon,
          fontColor: '#04cfbe'
        },
        FTX: {
          logo: ExchangeInformations.exchangeInfos.FTX.icon,
          fontColor: '#67ccdf'
        },
        OKX: {
          logo: ExchangeInformations.exchangeInfos.OKX.icon,
          fontColor: '#67ccdf'
        },
        BYBIT: {
          logo: ExchangeInformations.exchangeInfos.BYBIT.icon,
          fontColor: '#67ccdf'
        }
      },
      platforms: [GeneralApiService.PLATFORM.BINANCE, GeneralApiService.PLATFORM.OKX, GeneralApiService.PLATFORM.BYBIT, GeneralApiService.PLATFORM.DERIBIT],
      symbolTypes: ['SPOT', 'USD_CONTRACT', 'COIN_CONTRACT', 'OPTIONS'],
      orderTypes: GeneralApiService.ORDER_TYPE,
      symbolArrays: [],
      processing: false,
      rendering: true,
      activeNames: ['1'],
      createCollapse: ['1'],
      calculateCollapse: ['1'],
      instance: {},
      instanceList: [],
      accountInstance: {},
      allPrice: {},
      allSymbols: [],
      calculate: {
        currentPrice: 1600,
        quoteCoinUsdBalance: 10000,
        openUsd: 25,
        maxUsd: 250,
        positionIncreaseRate: 16.8,
        baseGrid: 0.268,
        maxGrid: 0.568,
        gridIncreaseRate: 16.8,
        isLong: true
      },
      report: []
    }
  },
  created() {
    this.closeReadOnly(true);
  },
  methods: {
    getProfit(config) {
      let profit = (config.openUsd * (config.gridGap / 100));
      return ((config.sellTimes >= config.buyTimes) ? config.buyTimes * profit : config.sellTimes * profit).toFixed(6);
    },
    syncSymbolList(instance) {
      let arrays = [];
      let that = this;
      SymbolInfoService.getSymbolList(instance.symbolPlatform, GeneralApiService.SYMBOL_TYPE.COIN_CONTRACT).forEach((info) => {
        if (that.instance.symbolPlatform === GeneralApiService.PLATFORM.OKX) {
          if (info.indexOf('-USD-') > -1 && !info.endsWith('-C') && !info.endsWith('-P')) {
            arrays.push(info);
          }
        } else {
          arrays.push(info);
        }
      });
      this.symbolArrays = arrays;
    },
    closeReadOnly(val) {
      this.$nextTick(() => {
        if (val) {
          this.$el.querySelectorAll('.chose-symbol.select-symbol .el-input__inner').forEach((item) => {
            item.removeAttribute('readonly');
          })
        }
      })
    },
    openTradeTools(ins) {
      this.$nextTick(function () {
        this.$refs['open-tools'].renderFtx(ins);
      });
    },
    handleShortLong(ins) {
      ins.symbol1Short = !ins.symbol1Short;
    },
    render: function (accountInstance) {
      let that = this;
      that.rendering = true;
      that.accountInstance = accountInstance;
      that.instance = JSON.parse(JSON.stringify(that.dto.strategyConfig));
      StrategyInstanceService.query(that.accountInstance.instanceId, that.dto.strategyName, function (data) {
        that.instanceList = data.strategyInsList;
        that.rendering = false;
      }, function () {
        that.rendering = false;
      });
    },
    add: function (ins) {
      ins.symbol = ins.symbol.toUpperCase();
      let that = this;
      that.processing = true;
      StrategyInstanceService.create({
        insId: this.accountInstance.instanceId,
        strategyName: this.dto.strategyName,
        config: JSON.stringify(ins)
      }, function () {
        Message.success('已成功创建新的实例');
        that.processing = false;
        setTimeout(() => that.render(that.accountInstance), 888);
      }, function () {
        that.processing = false;
      });
    },
    getPrice: function (ins) {
      return SymbolInfoService.getSymbolPrice(ins.symbolPlatform, GeneralApiService.SYMBOL_TYPE.COIN_CONTRACT, ins.symbol);
    },
    reset: function (ins) {
      let that = this;
      ConfirmProvider.openConfirm(this, '确认重置', '该操作将会重置所有非配置的数据', function () {
        that.processing = true;
        StrategyInstanceService.reset(ins.sid, function () {
          that.processing = false;
          Message.success('已成功重置');
          that.render(that.accountInstance);
        }, function () {
          that.processing = false;
        });
      });
    },
    deleteIns: function (ins) {
      let that = this;
      ConfirmProvider.openConfirm(this, '确认删除', '该操作将会删除该实例，此操作无法恢复', function () {
        that.processing = true;
        StrategyInstanceService.deleteIns(ins.sid, function () {
          that.processing = false;
          Message.success('已成功删除');
          that.render(that.accountInstance);
        }, function () {
          that.processing = false;
        });
      });
    },
    save: function (ins) {
      let that = this;
      that.processing = true;
      StrategyInstanceService.update({
        sid: ins.sid,
        strategyName: ins.strategyName,
        config: (ins.strategyConfig),
        instanceEntity: {
          id: that.accountInstance.instanceId
        },
        isActive: ins.isActive
      }, function () {
        that.processing = false;
        Message.success('已成功更新');
        that.render(that.accountInstance);
      }, function () {
        that.processing = false;
      });
    },
    calculateReport: function () {
      let simulateUsdCost = 0;
      let simulateLowestPrice = 0;
      let totalCoinAmount = 0;
      let result = [];
      for (let i = 0; i < 100000 && simulateUsdCost < this.calculate.quoteCoinUsdBalance; i++) {
        let openUsdSimulate = Math.min(this.calculate.maxUsd, this.calculate.openUsd * Math.pow(1 + (this.calculate.positionIncreaseRate / 100), i));
        let nextGapSimulate = Math.min(this.calculate.maxGrid, this.calculate.baseGrid * Math.pow(1 + (this.calculate.gridIncreaseRate / 100), i));
        if (simulateUsdCost + openUsdSimulate >= this.calculate.quoteCoinUsdBalance) {
          break;
        }
        simulateUsdCost += openUsdSimulate;
        simulateLowestPrice = (i === 0 ? this.calculate.currentPrice : simulateLowestPrice) * (1 + ((this.calculate.isLong ? -1 : 1) * (nextGapSimulate / 100)));
        totalCoinAmount += openUsdSimulate / ((i === 0 ? this.calculate.currentPrice : simulateLowestPrice) * (1 + ((this.calculate.isLong ? -1 : 1) * (nextGapSimulate / 100))));
        result.push({
          level: i + 1,
          totalCost: simulateUsdCost.toFixed(2),
          levelAddUsd: openUsdSimulate.toFixed(2),
          levelGridGap: nextGapSimulate.toFixed(2),
          addPrice: simulateLowestPrice.toFixed(2),
          entryPrice: (simulateUsdCost / totalCoinAmount).toFixed(6)
        });
      }
      console.log(result);
      this.report = result;
    }
  }
}
</script>

<style scoped>

.ellipsis-line {
  padding: 8px;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-collapse {
  color: #FFFFFF;
  border-top: 1px solid #444c56;
  border-bottom: 1px solid #444c56;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: white;
}

.el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-divider {
  border-top: 1px solid #444c56;
}

.el-divider.info {
  width: 80%;
  border-top: 1px solid #444c56;
}

.el-divider__text {
  color: white;
  background-color: #444c56;
}

.select-symbol .el-input__inner {
  color: white;
  height: 35px !important;
  line-height: 35px;
  width: 100%;
  padding: 0 10px 0 10px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-right: 0;
  border-radius: 0;
  font-size: 12px;
  outline: none;
}

.select-symbol.el-select .el-input.is-focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:hover .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:hover .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:visited .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:visited .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

</style>