<script>

import Web3Service from "@/web3/Web3Service";
import AbmWalletAbi from "@/web3/abis/AbmWalletAbi";

let contractAddress = '0xC5fC28B8130f34378d7EfdEfc97F26032072F5C1';

function getContract(that) {
  let web3 = Web3Service.getWeb3(that);
  return new web3.eth.Contract(AbmWalletAbi.ABI, contractAddress);
}

export default {
  getContract,
  contractAddress
}
</script>