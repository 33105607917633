<script>

function getSimpleTimeInfo() {
  let vWeek, vWeek_s;
  vWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (month < 9) {
    month = '0' + (month + 1);
  }
  if (day < 9) {
    day = '0' + (day);
  }
  vWeek_s = date.getDay();
  return vWeek[vWeek_s] + ' ' + year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
}

function timestampToDate(timestamp) {
  let date = new Date(Number(timestamp));
  let Y = date.getFullYear() + '-';
  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  let D = date.getDate() + ' ';
  let h = date.getHours() + ':';
  let m = date.getMinutes() + ':';
  let s = date.getSeconds();
  return Y + M + D + h + m + s;
}

function timestampToDateWithoutTime(timestamp) {
  let date = new Date(Number(timestamp));
  let Y = date.getFullYear() + '-';
  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  let D = date.getDate();
  return Y + M + D;
}

function getTimeDiffString(timestamp) {
  let dateBegin = new Date(timestamp);                        //如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
  let dateEnd = new Date();                                   //获取当前时间
  let dateDiff = dateEnd.getTime() - (1000 * 60 * 60 * 8) - dateBegin.getTime();     //时间差的毫秒数
  if (dateDiff < 1000 * 60) {
    return 'less than 1min'
  }
  if (dateDiff < 1000 * 60 * 60) {
    return (dateDiff / (1000 * 60)).toFixed(0) + ' minutes ago'
  }
  if (dateDiff < 1000 * 60 * 60 * 24) {
    return (dateDiff / (1000 * 60 * 60)).toFixed(0) + ' hours ago'
  } else {
    return (dateDiff / (1000 * 60 * 60 * 24)).toFixed(0) + ' days ago'
  }
}

function getTimeStamp() {
  return new Date().getTime();
}

export default {
  getTimeStamp,
  getSimpleTimeInfo,
  timestampToDate,
  getTimeDiffString,
  timestampToDateWithoutTime
}
</script>
