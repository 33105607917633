<script type="text/javascript">
import CryptoJS from 'crypto-js/crypto-js';

function encrypt(str, keyStr, ivStr) {
  keyStr = CryptoJS.enc.Utf8.parse(keyStr);
  ivStr = CryptoJS.enc.Utf8.parse(ivStr);
  let srcs = CryptoJS.enc.Utf8.parse(str);
  let encrypted = CryptoJS.AES.encrypt(srcs, keyStr, { iv: ivStr, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  return encrypted.ciphertext.toString().toUpperCase();
}

function decrypt(str, keyStr, ivStr) {
  keyStr = CryptoJS.enc.Utf8.parse(keyStr);
  ivStr = CryptoJS.enc.Utf8.parse(ivStr);
  let encryptedHexStr = CryptoJS.enc.Hex.parse(str);
  let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  let decrypt = CryptoJS.AES.decrypt(srcs, keyStr, { iv: ivStr, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}

export default {
  encrypt,
  decrypt
};
</script>

<style scoped>
</style>