<template>
  <div>
    <div :id=id ref="tvlChart" :color="color" :width="width" style="height: 100%;width: 100%"></div>
  </div>
</template>

<script>

export default {
  name: 'TvlChart',
  components: {},
  props: {
    id: String,
    color: String,
    width: String
  },
  data: function () {
    return {
      init: false,
      timeList: [],
      price: [],
    }
  },
  created: function () {
  },
  methods: {
    getProfitOptionOnlyProfit(xTimeList, profitList) {
      let series = [{
        showSymbol: true,
        name: this.$i('controlPanelHome.system7Vol'),
        type: 'line',
        data: profitList,
        smooth: true,
        itemStyle: {
          normal: {
            areaStyle: {
              type: 'default'
            },
            color: new this.$echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [{
                  offset: 0,
                  color: 'rgb(30,172,238,0.3)'
                },
                  {
                    offset: 0.5,
                    color: 'rgba(30,172,238, 0.1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(30,172,238, 0.05)'
                  }
                ]
            ),
            lineStyle: {        // 系列级个性化折线样式
              width: 2,
              type: 'solid',
              color: "rgb(18,177,250)"
            },
            opacity: 0
          }
        },
      }];
      return {
        tooltip: {
          trigger: 'axis'
        },
        color:[this.color],
        calculable: true,
        xAxis: [{
          type: 'category',
          boundaryGap: true,
          show: true,
          data: xTimeList,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: 8, //更改坐标轴文字大小
              color: 'white'
            }
          },
        }],
        grid: {
          x: 25,
          y: 15,
          x2: 38,
          y2: 15
        },
        yAxis: [{
          type: 'value',
          show: true,
          position: 'right',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#444c56',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(33,39,49,0.5)',
              width: 1,   //这里是坐标轴的宽度,可以去掉
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: 'white', //更改坐标轴文字颜色
              fontSize: 8 //更改坐标轴文字大小
            },
            formatter: (value) => {
              return (value / 1000000000).toFixed(1);
            }
          },
        }],
        series: series
      };
    },
    timestampToDate(timestamp) {
      let date = new Date(Number(timestamp) * 1000);
      let Y = date.getFullYear() + '-';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let D = date.getDate();
      return Y + M + D;
    },
    render: function (data) {
      let that = this;
      let xTimeList = [];
      let volList = [];
      let skipIndex = 0;
      let skipDay = 418;
      data.forEach((obj) => {
        if (skipIndex > skipDay) {
          xTimeList.push(that.timestampToDate(obj.date));
          volList.push(obj.totalLiquidityUSD.toFixed(2));
        }
        skipIndex++;
      });
      if (that.$refs.tvlChart) {
        that.tvlChart = that.$echarts.init(that.$refs.tvlChart);
        that.tvlChart.clear();
        that.tvlChart.setOption(that.getProfitOptionOnlyProfit(xTimeList, volList));
        window.addEventListener('resize', () => (that.tvlChart.resize()));
      }
    }
  }
}
</script>