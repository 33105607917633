<template>
  <div>
    <el-row style="display: flex">
      <gmx ref="gmx" style="width: 49%;float: left"/>
      <gmx-avax ref="gmxAvax" style="width: 49%;float: left"/>
    </el-row>
    <el-row>
      <el-col :span="8" style="padding-right: 10px">
        <a-p-e ref="ape" :type="'APE'"></a-p-e>
      </el-col>
      <el-col :span="8" style="padding-right: 10px">
        <a-p-e ref="mayc" :type="'MAYC'"></a-p-e>
      </el-col>
      <el-col :span="8">
        <a-p-e ref="bayc" :type="'BAYC'"></a-p-e>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import Gmx from "@/pages/controlPanel/web3/arb/GMX";
import GmxAvax from "@/pages/controlPanel/web3/arb/GMX_Avax";
import APE from "@/pages/controlPanel/web3/erc/APE";
// import AcLevel from "@/pages/controlPanel/web3/bsc/Level.vue";

export default {
  name: "FtEarn",
  components: {APE, GmxAvax, Gmx},
  data: function () {
    return {
    }
  },
  created() {

  },
  methods: {
    initData() {
      this.$nextTick(() => {
        this.$refs['gmx'].init();
        this.$refs['gmxAvax'].init();
        this.$refs['ape'].init();
        this.$refs['mayc'].init();
        this.$refs['bayc'].init();
        this.$refs['pid0'].init();
        this.$refs['pid1'].init();
        this.$refs['pid2'].init();
      });
    }
  }
}
</script>

<style scoped>

.el-divider.data-divider {
  border-top: 1px solid #444c56;
}

.data-divider > .el-divider__text {
  background-color: #22272e;
}

</style>