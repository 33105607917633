<template>
  <div class="panel-scoped">

    <div style="display: flex; border: 1px solid #3E454E; padding: 20px 20px 12px 20px; border-radius: 8px;">
      <div style="flex: 1;">
        <div style="display: flex;">
          <div>
            <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">选择目标链</label>
          </div>
          <div style="padding-right: 20px; width: 130px;">
            <select v-model="form.targetChain" @change="onAction({id: 'changeTargetChain'})" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px">
                <option v-for="(item, index) in targetChainList" :key="index" :value="item.value">{{item.text}}</option>
            </select>
          </div>
        </div>
      </div>
      <div style="display: none;">
        <FtGroupButtonWidget :buttons="[{
            id: 'createWallet',
            text: '添加转出地址',
            textI18n: '',
        }]" mode="single" v-on:switch-change="onAction" 
        style="margin-left: 16px; background: #3E454E;" />
      </div>
    </div>
  
    <el-row style="margin-top: 20px;">
      <el-col :span="24">
        <FtGroupButtonWidget :buttons="[{
            id: 'append',
            text: '选择转出地址',
            textI18n: '',
        }]" mode="single" textMode="true" v-on:switch-change="onAction" 
        style="background: #3E454E;" />
      </el-col>
    </el-row>

    <div style="margin-top: 20px;">

      <div :class="[(scrollHeight > 0 ? 'scroll-mode' : '')]" :style="(scrollHeight > 0 ? {height: scrollHeight + 'px'} : {})">

        <div style="display: flex; margin-bottom: 15px; border: 1px solid #3E454E; border-radius: 8px; padding: 18px 20px 8px 20px;" 
          v-for="(address, index) in addressList" :key="index">
          <div>
            <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">选择转出地址</label>
          </div>
          <div style="flex: 1; padding-right: 20px;">
            <select v-model="address.wallet" v-if="updateWallet" @change="changeWalletAction(address)" style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px">
                <option v-for="(item, index) in walletList" :key="index" :value="item.value">{{item.origin && item.origin.humanReadableName ? item.origin.humanReadableName + ' : ' : ''}}{{item.text}}</option>
            </select>
          </div>
          <div>
            <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">选择转出代币</label>
          </div>
          <div style="flex: 1; padding-right: 20px;">
            <select v-model="address.coinType" v-if="updateCoinType"
                style="width: 100%; background: #22272E; color: white; border: 0px; border-bottom: 1px solid #3E454E; line-height: 30px; height: 30px;font-size: 13px">
                <option v-for="item in coinTypeList" :key="item.text" :value="item.value">
                  {{item.text}}
                </option>
            </select>
          </div>
          <div>
            <label style="color: #999999; font-size: 13px; line-height: 30px; padding-right: 10px;">转出数量</label>
          </div>
          <div style="flex: 1; padding-right: 50px; position: relative;">
            <FtInputBoxWidget v-model="address.count" v-on:switch-blur="onAction({id: 'transferAmountCheck', value: address})" style="margin-left: 0px; border: 1px solid transparent;" />
            <span @click="setMoneyMax(address.wallet)" 
              style="color: #FFF; background: #3E454E; padding: 4px 12px; border-radius: 8px; font-size: 12px; cursor: pointer; position: absolute; top: 0px; right: 0px;">
              MAX
            </span>
            <div style="position: absolute; bottom: 8px; right: 0px; left: 0px; border-bottom: 1px solid #3E454E;"></div>
            <div style="position: absolute; bottom: -8px; font-size: 10px; color: #eb3c3c;" v-if="transferAmountErrorMessages">{{transferAmountErrorMessages[address.wallet]}}</div>
          </div>
          <div style="width: 100px; position: relative; text-align: right;">
            <span v-if="address.wallet" style="color: #FFB324; background: rgba(255,179,36,0.1); padding: 6px 12px; border-radius: 8px; font-size: 12px;">
              审核 ({{sourceWalletMap[address.wallet].requireApprovalNumber}}
              {{sourceWalletMap[address.wallet].approvers ? '/ ' + sourceWalletMap[address.wallet].approvers.length : '/ 0'}})
            </span>
            <div @click="onAction({id: 'delAddress', value: index})"
              style="position: absolute; top: -28px; right: -30px; width: 20px; height: 20px; background: #3E454E; color: white; cursor: pointer; border-radius: 16px; font-size: 11px; text-align: center;">
              <i class="el-icon-close"></i>
            </div>
          </div>
        </div>

      </div>

      <div style="display: flex; margin-bottom: 15px;">
        <div style="flex: 1;">
          <FtGroupButtonWidget :buttons="[{
              id: 'addAddress',
              text: '添加',
              textI18n: '',
              icon: 'el-icon-plus',
          }]" mode="single" type="block" v-on:switch-change="onAction" 
          style="background: #3E454E; width: 100%;" />
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {Message} from "element-ui";
// import ConfirmProvider from '@/components/ConfirmProvider'

// import CrmChannelManagement from "@/api/crm/CrmChannelManagement";
import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";
import FtInputBoxWidget from "@/components/widgets/FtInputBoxWidget";

import { useWalletStore, useWalletOutsideStore, AsyncQueue } from '@/store/wallet'
import WalletManagement from "@/api/wallet/WalletManagement";
import TrialphaLoading from "@/components/TrialphaLoading";

export default {
  components: {
    FtGroupButtonWidget,
    FtInputBoxWidget
  },
  props: {},
  data() {
    return {

      // 表单数据
      form: {
        sourceWallet: '',
        sourceWalletType: '',
        targetChain: '',
        coinType: '',
        remark: ''
      },

      addressList: [
        {wallet: '', coinType: '', count: 0}
      ],

      updateWallet: true,
      updateCoinType: true,

      // 钱包列表
      walletList: [],

      // 代币列表
      coinTypeList: [],

      // 余额信息
      walletMoneyInfo: {},

      // 滚动面板高度
      scrollHeight: 0,

      // 转账金额限制信息
      transferAmountErrorMessages: null

    }
  },
  computed: {
      // 转出钱包地址
      sourceWalletList: () => {
        let walletOutsideStore = useWalletOutsideStore()
        return [{text: '请选择', value: ''}].concat(walletOutsideStore.sourceWalletList)
      },
      sourceWalletMap: () => {
        let walletOutsideStore = useWalletOutsideStore()
        return walletOutsideStore.sourceWalletMap
      },
      // 目标链列表
      targetChainList: () => {
        let walletOutsideStore = useWalletOutsideStore()
        var map = {}
        var list = [{text: '请选择', value: ''}]
        for (var n in walletOutsideStore.dexTargetChainList) {
          if (!map[n]) {
            map[n] = true
            list.push({text: n, value: n})
          }
        }
        for (var k in walletOutsideStore.cexTargetChainList) {
          for (var m in walletOutsideStore.cexTargetChainList[k]) {
            for (var h in walletOutsideStore.cexTargetChainList[k][m]) {
              var v = walletOutsideStore.cexTargetChainList[k][m][h]
              if (!map[v]) {
                map[v] = true
                list.push({text: v, value: v})
              }
            }
          }
        }
        return list
      },
      // 目标链列表
      dexTargetChainList: () => {
        let walletOutsideStore = useWalletOutsideStore()
        return [{text: '请选择', value: ''}].concat(walletOutsideStore.dexTargetChainList)
      },
      cexTargetChainList: () => {
        let walletOutsideStore = useWalletOutsideStore()
        return [{text: '请选择', value: ''}].concat(walletOutsideStore.cexTargetChainList)
      },
      // 代币列表(tokens)
      dexCoinTypeTokens: () => {
        let walletOutsideStore = useWalletOutsideStore()
        return walletOutsideStore.dexCoinTypeList
      },
      // 目标链类型
      filterTargetChain: () => {
        let walletOutsideStore = useWalletOutsideStore()
        return walletOutsideStore.filterTargetChain
      }
  },
  mounted() {

    var windowHeight = window.innerHeight
    this.scrollHeight = windowHeight - 240 - 350

  },
  methods: {
    changeChainAction() {
      var that = this
      var list = that.sourceWalletList
      var chain = that.form.targetChain

      var walletOutsideStore = useWalletOutsideStore()
      var dexMap = {}
      var cexMap = {}
      for (var n in walletOutsideStore.dexTargetChainList) {
        if (!dexMap[n]) {
          dexMap[n] = true
        }
      }
      for (var k in walletOutsideStore.cexTargetChainList) {
        for (var m in walletOutsideStore.cexTargetChainList[k]) {
          for (var h in walletOutsideStore.cexTargetChainList[k][m]) {
            var v = walletOutsideStore.cexTargetChainList[k][m][h]
            if (!cexMap[v]) {
              cexMap[v] = true
            }
          }
        }
      }
      var status = 0
      if (dexMap[chain] && cexMap[chain]) {
        status = 1
      } else if (dexMap[chain]) {
        status = 2
      } else if (cexMap[chain]) {
        status = 3
      }
      list = list.filter(s => {
        if (s.value == '') {
          return true
        }
        if (status == 1) {
          return true
        }
        if (status == 2) {
          return s.walletType == 'DEX'
        }
        if (status == 3) {
          return s.walletType == 'CEX'
        }
        return true
      })
      if (chain == 'BSC') {
        console.log('钱包列表刷新BSC', list)
        that.clearOKX()
        that.walletList = list.filter(s => {
          if (s.value == '') {
            return true
          }
          var wallet = that.sourceWalletMap[s.value]
          return wallet.platformName != 'OKX'
        })
      } else {
        console.log('钱包列表刷新', list)
        that.walletList = list
      }
    },
    clearOKX() {
      // addressList
      var that = this
      that.addressList.forEach(address => {
        var wallet = that.sourceWalletMap[address.wallet]
        if (wallet && wallet.platformName == 'OKX') {
          address.wallet = ''
          address.coinType = ''
          address.count = 0
        }
      })
    },
    changeWalletAction(address) {
      var that = this
      var targetChain = that.form.targetChain
      var options = []
      if (address.wallet) {
        options.push({value: '', text: '请选择'})
      }
      if (targetChain && address.wallet) {
        var walletOutsideStore = useWalletOutsideStore()
        var wallet = that.sourceWalletMap[address.wallet]
        if (wallet.walletType == 'CEX') {
          console.log('当前钱包', wallet)
          var coinTypes = walletOutsideStore.cexCoinTypeList[wallet.platformName]
          for (var coinType in coinTypes) {
            var chains = coinTypes[coinType]
            if (chains.indexOf(targetChain) > -1) {
              var count = that.walletMoneyInfo[address.wallet] ? that.walletMoneyInfo[address.wallet][coinType] : 0
              options.push({
                value: coinType,
                text: coinType + ' ' + (count ? count : 0)
              })
            }
          }
          // 
        } else { // DEX
          walletOutsideStore.dexCoinTypeList[targetChain].forEach(item => {
            var count = that.walletMoneyInfo[address.wallet] ? that.walletMoneyInfo[address.wallet][item] : 0
            options.push({
              value: item,
              text: item + ' ' + (count ? count : 0)
            })
          })
        }
        console.log('计算代币列表======', options)
      }
      that.coinTypeList = options
      address.coinType = ''
      address.count = 0
      that.getWalletMoney(address.wallet, true)
    },
    filterSourceList(list, chain) {
      var walletOutsideStore = useWalletOutsideStore()
      var dexMap = {}
      var cexMap = {}
      for (var n in walletOutsideStore.dexTargetChainList) {
        if (!dexMap[n]) {
          dexMap[n] = true
        }
      }
      for (var k in walletOutsideStore.cexTargetChainList) {
        for (var m in walletOutsideStore.cexTargetChainList[k]) {
          for (var h in walletOutsideStore.cexTargetChainList[k][m]) {
            var v = walletOutsideStore.cexTargetChainList[k][m][h]
            if (!cexMap[v]) {
              cexMap[v] = true
            }
          }
        }
      }
      var status = 0
      if (dexMap[chain] && cexMap[chain]) {
        status = 1
      } else if (dexMap[chain]) {
        status = 2
      } else if (cexMap[chain]) {
        status = 3
      }
      list = list.filter(s => {
        if (s.value == '') {
          return true
        }
        if (status == 1) {
          return true
        }
        if (status == 2) {
          return s.walletType == 'DEX'
        }
        if (status == 3) {
          return s.walletType == 'CEX'
        }
        return true
      })
      if (chain == 'BSC') {
        console.log('钱包列表刷新', list)
        return list.filter(s => {
          if (s.value == '') {
            return true
          }
          return s.origin.platformName != 'OKX'
        })
      }
      console.log('钱包列表刷新', list)
      return list
    },
    drawCoinTypeList(targetChain, address) {
      let that = this
      let options = []
      if (address.wallet) {
        options.push({value: '', text: '请选择'})
      }
      if (targetChain && address.wallet) {
        let walletOutsideStore = useWalletOutsideStore()
        let wallet = that.sourceWalletMap[address.wallet]
        if (wallet.walletType == 'CEX') {
          console.log('当前钱包', wallet)
          var coinTypes = walletOutsideStore.cexCoinTypeList[wallet.platformName]
          for (var coinType in coinTypes) {
            var chains = coinTypes[coinType]
            if (chains.indexOf(targetChain) > -1) {
              let count = that.walletMoneyInfo[address.wallet] ? that.walletMoneyInfo[address.wallet][coinType] : 0
              options.push({
                value: coinType,
                text: coinType + ' ' + (count ? count : 0)
              })
            }
          }
          // 
        } else { // DEX
          walletOutsideStore.dexCoinTypeList[targetChain].map(item => {
            let count = that.walletMoneyInfo[address.wallet] ? that.walletMoneyInfo[address.wallet][item] : 0
            return {
              value: item,
              text: item + ' ' + (count ? count : 0)
            }
          })
        }
        console.log('计算代币列表======', options)
      }
      return options
    },
    onAction(item) {
      var that = this
      if (item.id == 'addAddress') {
        if (that.filterTargetChain == '') {
          Message.error('请先选择目标链')
          return
        }
        that.addressList.push({wallet: '', coinType: '', count: 0})
      } else if (item.id == 'delAddress') {
          let ws = that.addressList.splice(item.value, 1)
          if (ws.length) {
            that.$emit('switch-change', {id: 'removeTargetWallet', value: ws[0].bind})
          }
      }
      else if (item.id == 'createWallet') {
        that.$emit('switch-change', item)
      }
      else if (item.id == 'changeTargetChain') {
        that.changeChainAction()
        let walletStore = useWalletStore()
        walletStore.setFilterTargetChain(that.form.targetChain)
        that.updateWallet = false
        setTimeout(() => {
          that.updateWallet = true
          that.eachWalletMoney(true)
        }, 100)
      }

      else if (item.id == 'transferAmountCheck') {
        that.$emit('switch-change', item)
      }
    },
    getFormData() {
      let that = this
      let walletList = []
      that.addressList.map(address => {
        var walletId = ''
        if (that.sourceWalletMap[address.wallet]) {
          walletId = that.sourceWalletMap[address.wallet].id
        }
        walletList.push({
          sourceWalletId: walletId,
          sourceWallet: address.wallet,
          sourceWalletType: that.sourceWalletMap[address.wallet].walletType,
          targetChain: that.form.targetChain,
          coinType: address.coinType,
          count: address.count,
          countMax: that.walletMoneyInfo[address.wallet] ? that.walletMoneyInfo[address.wallet][address.coinType] : 0,
          remark: ''
        })
      })
      return walletList
    },
    resetFormData() {
      // 表单数据
      this.form = {
        sourceWallet: '',
        sourceWalletType: '',
        targetChain: '',
        coinType: '',
        remark: ''
      }
      // 交易所列表
      this.addressList = []
      // 余额信息
      this.walletMoneyInfo = {}
      let walletStore = useWalletStore()
      walletStore.setFilterTargetChain('')
    },
    // 修改链后，钱包余额查询
    eachWalletMoney(showLoading) {
      let that = this
      return new Promise(resolve => {
        if (that.form.targetChain == '') {
          resolve()
          return 
        }
        let loading = showLoading ? TrialphaLoading.loading(that) : {close: () => {}}
        let index = 0
        that.updateCoinType = false
        let m = new AsyncQueue()
        that.addressList.forEach(() => {
          m.action(finish => {
            let address = that.addressList[index ++]
            that.getWalletMoney(address.wallet, false).then(() => {
              finish()
            }, () => {
              finish()
            })
          })
        })
        m.finish(() => {
          loading.close()
          that.updateCoinType = true
          resolve()
        })
        m.start()
      })
    },
    // 选择钱包后，获取该钱包各币种余额
    getWalletMoney(targetWallet, showLoading) {
      let that = this
      return new Promise((resolve, reject) => {
        let wallet = that.sourceWalletMap[targetWallet]
        console.log('钱包余额查询', wallet)
        if (wallet.walletType == 'DEX') {
          let tokens = that.dexCoinTypeTokens[that.form.targetChain]
          let paramsCex = {
              walletAddress : targetWallet,
              dexChain : that.form.targetChain,
              tokens : tokens
          }
          if (paramsCex.walletAddress && paramsCex.dexChain) {
            let loading = showLoading ? TrialphaLoading.loading(that) : {close: () => {}}
            showLoading ? that.updateCoinType = false : false
            WalletManagement.walletQueryDexBalanceOf(paramsCex).then((res) => {
              console.log('钱包余额', res)
              that.walletMoneyInfo[targetWallet] = res
              loading.close()
              showLoading ? that.updateCoinType = true : false
              resolve()
            }, () => {
              that.walletMoneyInfo[targetWallet] = {}
              loading.close()
              showLoading ? that.updateCoinType = true : false
              resolve()
            })
          }
        } else if (wallet.walletType == 'CEX') {
          let paramsDex = {
              apiKey : targetWallet
          }
          if (paramsDex.apiKey && that.form.targetChain) {
            let loading = showLoading ? TrialphaLoading.loading(that) : {close: () => {}}
            showLoading ? that.updateCoinType = false : false
            WalletManagement.walletQueryCexBalanceOf(paramsDex).then((res) => {
              console.log('钱包余额', res)
              that.walletMoneyInfo[targetWallet] = res
              loading.close()
              showLoading ? that.updateCoinType = true : false
              resolve()
            }, () => {
              that.walletMoneyInfo[targetWallet] = {}
              loading.close()
              showLoading ? that.updateCoinType = true : false
              resolve()
            })
          }
        } else {
          reject()
        }
      })
    },
    setMoneyMax(targetWallet) {
      let that = this
      let wallet = this.sourceWalletMap[targetWallet]
      console.log('钱包余额查询', wallet)
      this.addressList.forEach(item => {
        if (item.wallet == targetWallet) {
          if (that.walletMoneyInfo[targetWallet]) {
            if (item.coinType) {
              console.log('MAX余额', that.walletMoneyInfo[targetWallet][item.coinType])
              item.count = that.walletMoneyInfo[targetWallet][item.coinType]
            }
          }
        }
      })

    },
    // 设置转账金额限制信息
    setTransferAmountCheckResult(errors) {
      // let errors = [
      //     {
      //         fromWallet: '',
      //         toWallet: '',
      //         errorMessage: '',
      //         dexChain: "ETH", //提现链名
      //         token: "AGLD", //提现币种
      //         withdrawMin: "22", //提现最小金额
      //         withdrawMax: "9999999", //提现最大金额
      //         withdrawEnable: true //是否允许体现
      //     }
      // ]
      var that = this
      that.transferAmountErrorMessages = null
      if (errors.length) {
        var errorMessages = {}
        errors.forEach(err => {
          errorMessages[err.fromWallet] = err.errorMessage
        })
        that.transferAmountErrorMessages = errorMessages
      }
    }
  }
}
</script>

<style scoped>
.panel-scoped{
  background: #22272E;
  padding: 20px;
  border: 1px solid #3E454E;
  border-radius: 8px;
}

.scroll-mode{
  overflow-y: auto;
  padding-top: 10px;
  padding-right: 10px;
}
</style>