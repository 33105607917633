<template>
  <div style="height: 988px">
    <el-input class="dark-mode-input-search" placeholder="Filter" v-model="search" style="float: right;width: 98px"></el-input>
    <el-checkbox-group v-model="time" @change="report">
      <el-checkbox label="REAL"></el-checkbox>
      <el-checkbox label="7DAY"></el-checkbox>
      <el-checkbox label="30DAY"></el-checkbox>
      <el-checkbox label="1YEAR"></el-checkbox>
    </el-checkbox-group>
    <el-checkbox-group v-model="compareExchange" @change="report">
      <el-checkbox label="Binance"></el-checkbox>
      <el-checkbox label="OKX"></el-checkbox>
      <el-checkbox label="Bybit"></el-checkbox>
      <el-checkbox label="Gate"></el-checkbox>
      <el-checkbox label="dYdX"></el-checkbox>
    </el-checkbox-group>
    <el-row style="margin-top: 0">
      <el-col :span="3">
        <span style="display: inline-block;float: left;color: black;font-size: 12px;margin-top: 5px">Binance Risk Exposure</span>
      </el-col>
      <el-col :span="3">
        <span style="display: inline-block;float: left;color: black;font-size: 12px;margin-top: 5px">OKX Risk Exposure</span>
      </el-col>
      <el-col :span="3">
        <span style="display: inline-block;float: left;color: black;font-size: 12px;margin-top: 5px">Bybit Risk Exposure</span>
      </el-col>
      <el-col :span="3">
        <span style="display: inline-block;float: left;color: black;font-size: 12px;margin-top: 5px">Gate Risk Exposure</span>
      </el-col>
      <el-col :span="3">
        <span style="display: inline-block;float: left;color: black;font-size: 12px;margin-top: 5px">dYdX Risk Exposure</span>
      </el-col>
    </el-row>
    <el-row style="margin-top: 3px">
      <el-col :span="3">
        <el-input class="dark-mode-input-search" placeholder="Position" :value="position.binance + ' / ' + (((position.binance * 100) / position.binanceTP)).toFixed(2) + '%'"
                  style="float: left;width: 138px;margin-left: 0" size="small" disabled></el-input>
      </el-col>
      <el-col :span="3">
        <el-input class="dark-mode-input-search" placeholder="Position" :value="position.okx + ' / ' + (((position.okx * 100) / position.okxTP)).toFixed(2) + '%'"
                  style="float: left;width: 138px;margin-left: 0" size="small" disabled></el-input>
      </el-col>
      <el-col :span="3">
        <el-input class="dark-mode-input-search" placeholder="Position" :value="position.bybit + ' / ' + (((position.bybit * 100) / position.bybitTP)).toFixed(2) + '%'"
                  style="float: left;width: 138px;margin-left: 0" size="small" disabled></el-input>
      </el-col>
      <el-col :span="3">
        <el-input class="dark-mode-input-search" placeholder="Position" :value="position.gate + ' / ' + (((position.gate * 100) / position.gateTP)).toFixed(2) + '%'"
                  style="float: left;width: 138px;margin-left: 0" size="small" disabled></el-input>
      </el-col>
      <el-col :span="3">
        <el-input class="dark-mode-input-search" placeholder="Position" :value="position.dydx + ' / ' + (((position.dydx * 100) / position.dydxTP)).toFixed(2) + '%'"
                  style="float: left;width: 138px;margin-left: 0" size="small" disabled></el-input>
      </el-col>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-col :span="3">
        <span style="display: inline-block;float: left;color: black;font-size: 12px;margin-top: 5px">Binance Position</span>
      </el-col>
      <el-col :span="3">
        <span style="display: inline-block;float: left;color: black;font-size: 12px;margin-top: 5px">OKX Position</span>
      </el-col>
      <el-col :span="3">
        <span style="display: inline-block;float: left;color: black;font-size: 12px;margin-top: 5px">Bybit Position</span>
      </el-col>
      <el-col :span="3">
        <span style="display: inline-block;float: left;color: black;font-size: 12px;margin-top: 5px">Gate Position</span>
      </el-col>
      <el-col :span="3">
        <span style="display: inline-block;float: left;color: black;font-size: 12px;margin-top: 5px">dYdX Position</span>
      </el-col>
      <el-col :span="3" :offset="6">
        <span style="display: inline-block;float: right;color: black;font-size: 12px;margin-top: 5px">Total Position</span>
      </el-col>
    </el-row>
    <el-row style="margin-top: 3px">
      <el-col :span="3">
        <el-input class="dark-mode-input-search" placeholder="Position" v-model="position.binanceTP"
                  style="float: left;width: 138px;margin-left: 0" size="small" disabled></el-input>
      </el-col>
      <el-col :span="3">
        <el-input class="dark-mode-input-search" placeholder="Position" v-model="position.okxTP"
                  style="float: left;width: 138px;margin-left: 0" size="small" disabled></el-input>
      </el-col>
      <el-col :span="3">
        <el-input class="dark-mode-input-search" placeholder="Position" v-model="position.bybitTP"
                  style="float: left;width: 138px;margin-left: 0" size="small" disabled></el-input>
      </el-col>
      <el-col :span="3">
        <el-input class="dark-mode-input-search" placeholder="Position" v-model="position.gateTP"
                  style="float: left;width: 138px;margin-left: 0" size="small" disabled></el-input>
      </el-col>
      <el-col :span="3">
        <el-input class="dark-mode-input-search" placeholder="Position" v-model="position.dydxTP"
                  style="float: left;width: 138px;margin-left: 0" size="small" disabled></el-input>
      </el-col>
      <el-col :span="3" :offset="6">
        <el-input class="dark-mode-input-search" placeholder="Position" :value="position.total"
                  style="float: right;width: 138px;margin-left: 0" size="small" disabled></el-input>
      </el-col>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-col :span="3">
        <span style="display: inline-block;float: left;color: black;font-size: 12px;margin-top: 5px">8h Profit</span>
      </el-col>
      <el-col :span="3">
        <span
            style="display: inline-block;float: left;color: black;font-size: 12px;margin-top: 5px">7 Days Profit</span>
      </el-col>
      <el-col :span="3">
        <span
            style="display: inline-block;float: left;color: black;font-size: 12px;margin-top: 5px">30 Days Profit</span>
      </el-col>
      <el-col :span="3">
        <span
            style="display: inline-block;float: left;color: black;font-size: 12px;margin-top: 5px">1 Year Profit</span>
      </el-col>
      <el-col :span="3">
        <span
            style="display: inline-block;float: left;color: black;font-size: 12px;margin-top: 5px">Choose Symbol Count</span>
      </el-col>
      <el-col :span="3">
        <span
            style="display: inline-block;float: left;color: black;font-size: 12px;margin-top: 5px">Trade Instance Count</span>
      </el-col>
    </el-row>
    <el-row style="margin-top: 2px">
      <el-col :span="3">
        <el-input class="dark-mode-input-search" placeholder="Position"
                  :value="((profit.realFee * 100) / position.total).toFixed(2) + '% / ' + ((profit.realFee * 100 * 1095) / position.total).toFixed(2) + '%'"
                  style="float: left;width: 138px;margin-left: 0" size="small" disabled></el-input>
      </el-col>
      <el-col :span="3">
        <el-input class="dark-mode-input-search" placeholder="Position"
                  :value="((profit.day7Fee * 100) / position.total).toFixed(2) + '% / ' + ((profit.day7Fee * 100 * 52) / position.total).toFixed(2) + '%'"
                  style="float: left;width: 138px;margin-left: 0" size="small" disabled></el-input>
      </el-col>
      <el-col :span="3">
        <el-input class="dark-mode-input-search" placeholder="Position"
                  :value="((profit.day30Fee * 100) / position.total).toFixed(2) + '% / ' + ((profit.day30Fee * 100 * 12) / position.total).toFixed(2) + '%'"
                  style="float: left;width: 138px;margin-left: 0" size="small" disabled></el-input>
      </el-col>
      <el-col :span="3">
        <el-input class="dark-mode-input-search" placeholder="Position"
                  :value="((profit.year1Fee * 100) / position.total).toFixed(2) + '% / ' + ((profit.year1Fee * 100) / position.total).toFixed(2) + '%'"
                  style="float: left;width: 138px;margin-left: 0" size="small" disabled></el-input>
      </el-col>
      <el-col :span="3">
        <el-input class="dark-mode-input-search" placeholder="Position" v-model="symbolCounts"
                  style="float: left;width: 138px;margin-left: 0" size="small" disabled></el-input>
      </el-col>
      <el-col :span="3">
        <el-input class="dark-mode-input-search" placeholder="Position" v-model="tradeInstanceCount"
                  style="float: left;width: 138px;margin-left: 0" size="small" disabled></el-input>
      </el-col>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-col :span="3">
        <span style="display: inline-block;float: left;color: black;font-size: 12px;margin-top: 5px">7 Days Profit More Than</span>
      </el-col>
      <el-col :span="3">
        <span
            style="display: inline-block;float: left;color: black;font-size: 12px;margin-top: 5px">30 Days Profit More Than</span>
      </el-col>
      <el-col :span="3">
        <span
            style="display: inline-block;float: left;color: black;font-size: 12px;margin-top: 5px">1 Year Profit More Than</span>
      </el-col>
    </el-row>
    <el-row style="margin-top: 2px">
      <el-col :span="3">
        <el-input class="dark-mode-input-search" placeholder="Position" v-model="profit.day7ProfitMoreThan" @change="report"
                  style="float: left;width: 98px;margin-left: 0" size="small"></el-input>
      </el-col>
      <el-col :span="3">
        <el-input class="dark-mode-input-search" placeholder="Position" v-model="profit.day30ProfitMoreThan" @change="report"
                  style="float: left;width: 98px;margin-left: 0" size="small"></el-input>
      </el-col>
      <el-col :span="3">
        <el-input class="dark-mode-input-search" placeholder="Position" v-model="profit.year1ProfitMoreThan" @change="report"
                  style="float: left;width: 98px;margin-left: 0" size="small"></el-input>
      </el-col>
      <el-col :span="3" :offset="12">
        <export-excel
            :name="'FundingFeeData-' + (new Date().getTime()) + '.xls'"
            :data = "fundingFeeResult">
          <i class="el-icon-download" style="cursor: pointer;float: right;font-size: 20px"></i>
        </export-excel>
      </el-col>
    </el-row>
    <el-table
        :data="fundingFeeResult.filter((obj) => {if ((search === '' || obj.symbol.indexOf(search.toUpperCase()) > -1) && obj.position > 0) return true;})"
        height="706"
        style="width: 100%">
      <el-table-column
          sortable
          prop="shortE"
          label="Short Exchange">
      </el-table-column>
      <el-table-column
          sortable
          prop="longE"
          label="Long Exchange">
      </el-table-column>
      <el-table-column
          sortable
          prop="symbol"
          label="Symbol">
      </el-table-column>
      <el-table-column
          sortable
          prop="realFee"
          label="Real Fee">
      </el-table-column>
      <el-table-column
          sortable
          prop="day7Fee"
          label="7 Days Fee">
      </el-table-column>
      <el-table-column
          sortable
          prop="day30Fee"
          label="30 Days Fee">
      </el-table-column>
      <el-table-column
          sortable
          prop="year1Fee"
          label="1 Year Fee">
      </el-table-column>
      <el-table-column
          label="Position(USD)">
        <template v-slot="scope">
          <el-input class="dark-mode-input-search" placeholder="Filter" v-model="scope.row.position" style=";width: 88px" @change="changePosition"></el-input>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>

export default {
  name: "FundingFeeReport",
  data: function () {
    return {
      fundingFee: [],
      fundingFeeResult: [],
      time: ['REAL'],
      search: '',
      compareExchange: ['Binance', 'OKX'],
      positionString: '',
      position: {
        binance: 0,
        ftx: 0,
        okx: 0,
        bybit: 0,
        gate: 0,
        dydx: 0,
        binanceTP: 0,
        ftxTP: 0,
        okxTP: 0,
        bybitTP: 0,
        gateTP: 0,
        dydxTP: 0,
        total: 0
      },
      profit: {
        realFee: 0,
        day7Fee: 0,
        day30Fee: 0,
        year1Fee: 0,
        total: 0,
        day7ProfitMoreThan: 0,
        day30ProfitMoreThan: 0,
        year1ProfitMoreThan: 0
      },
      symbolCounts: 0,
      tradeInstanceCount: 0
    }
  },
  methods: {
    changePosition() {
      this.position = {
        binance: 0,
        ftx: 0,
        okx: 0,
        bybit: 0,
        gate: 0,
        dydx: 0,
        binanceTP: 0,
        ftxTP: 0,
        okxTP: 0,
        bybitTP: 0,
        gateTP: 0,
        dydxTP: 0,
        total: 0
      };
      this.profit.realFee = 0;
      this.profit.day7Fee = 0;
      this.profit.day30Fee = 0;
      this.profit.year1Fee = 0;
      this.profit.total = 0;
      let hasPositionCount = 0;
      let symbolArray = [];
      this.fundingFeeResult.forEach((obj) => {
        if ((this.search === '' || obj.symbol.indexOf(this.search) > -1) && Number(obj.position) > 0) {
          this.position[obj.shortE.toLowerCase()] -= Number(obj.position);
          this.position[obj.longE.toLowerCase()] += Number(obj.position);
          this.position[obj.shortE.toLowerCase() + 'TP'] += Number(obj.position);
          this.position[obj.longE.toLowerCase() + 'TP'] += Number(obj.position);
          this.position.total += Number(obj.position) * 2;
          this.profit.realFee = Number((obj.position * ((obj.realFee / 100)) + this.profit.realFee).toFixed(2));
          this.profit.day7Fee = Number((obj.position * ((obj.day7Fee / 100)) + this.profit.day7Fee).toFixed(2));
          this.profit.day30Fee = Number((obj.position * ((obj.day30Fee / 100)) + this.profit.day30Fee).toFixed(2));
          this.profit.year1Fee = Number((obj.position * ((obj.year1Fee / 100)) + this.profit.year1Fee).toFixed(2));
          hasPositionCount++;
          if (symbolArray.indexOf(obj.symbol) === -1) {
            symbolArray.push(obj.symbol);
          }
        }
      });
      this.symbolCounts = symbolArray.length;
      this.tradeInstanceCount = hasPositionCount;
    },
    render: function (fundingFee) {
      this.fundingFee = fundingFee;
      this.report();
    },
    report: function () {
      let tmpResult = {};
      let mergeResult = [];
      for (let i = 0; i < this.time.length; i++) {
        this.fundingCalc(this.time[i]).forEach((r) => {
          if (!tmpResult[this.time[i]]) {
            tmpResult[this.time[i]] = {};
          }
          tmpResult[this.time[i]][r.shortE + '-' + r.longE + '-' + r.symbol] = r;
        });
      }
      if (this.time.length > 1) {
        Object.keys(tmpResult[this.time[0]]).forEach((s) => {
          let hasSymbol = true;
          for (let i = 1; i < this.time.length; i++) {
            if (!tmpResult[this.time[i]][s]) {
              hasSymbol = false;
            }
          }
          if (hasSymbol) {
            let obj = {
              shortE: tmpResult[this.time[0]][s].shortE,
              longE: tmpResult[this.time[0]][s].longE,
              symbol: tmpResult[this.time[0]][s].symbol,
              realFee: tmpResult['REAL'] ? tmpResult['REAL'][s].fee : 0,
              day7Fee: tmpResult['7DAY'] ? tmpResult['7DAY'][s].fee : 0,
              day30Fee: tmpResult['30DAY'] ? tmpResult['30DAY'][s].fee : 0,
              year1Fee: tmpResult['1YEAR'] ? tmpResult['1YEAR'][s].fee : 0,
              position: 100
            };
            if (this.profit.day7ProfitMoreThan <= obj.day7Fee && this.profit.day30ProfitMoreThan <= obj.day30Fee && this.profit.year1ProfitMoreThan <= obj.year1Fee) {
              mergeResult.push(obj);
            }
          }
        });
      } else {
        Object.keys(tmpResult[this.time[0]]).forEach((s) => {
          let obj = {
            shortE: tmpResult[this.time[0]][s].shortE,
            longE: tmpResult[this.time[0]][s].longE,
            symbol: tmpResult[this.time[0]][s].symbol,
            realFee: tmpResult['REAL'] ? tmpResult['REAL'][s].fee : 0,
            day7Fee: tmpResult['7DAY'] ? tmpResult['7DAY'][s].fee : 0,
            day30Fee: tmpResult['30DAY'] ? tmpResult['30DAY'][s].fee : 0,
            year1Fee: tmpResult['1YEAR'] ? tmpResult['1YEAR'][s].fee : 0,
            position: 100
          };
          if (this.profit.day7ProfitMoreThan <= obj.day7Fee && this.profit.day30ProfitMoreThan <= obj.day30Fee && this.profit.year1ProfitMoreThan <= obj.year1Fee) {
            mergeResult.push(obj);
          }
        });
      }
      this.fundingFeeResult = mergeResult;
      this.changePosition();
    },
    fundingCalc(time) {
      let timeType = {
        'REAL': {},
        '7DAY': {},
        '30DAY': {},
        '1YEAR': {}
      };
      let result = [];
      Object.keys(this.fundingFee).forEach((e) => {
        if (e.indexOf(time) > -1) {
          let ex = e.replaceAll(time, '');
          if (this.compareExchange.indexOf(ex) > -1) {
            Object.keys(this.fundingFee[e]).forEach((s) => {
              if (!timeType[time][e]) {
                timeType[time][e] = {};
              }
              timeType[time][e][s] = this.fundingFee[e][s];
            });
          }
        }
      });
      for (let i = 0; i < this.compareExchange.length - 1; i++) {
        for (let j = i + 1; j < this.compareExchange.length; j++) {
          let key1 = this.compareExchange[i] + time;
          let key2 = this.compareExchange[j] + time;
          Object.keys(timeType[time][key1]).forEach((c1) => {
            if (timeType[time][key2][c1]) {
              let originFee = Number((timeType[time][key1][c1] - timeType[time][key2][c1]).toFixed(3));
              if (originFee > 0) {
                result.push({
                  shortE: this.compareExchange[i],
                  longE: this.compareExchange[j],
                  symbol: c1,
                  fee: originFee,
                  time: time
                });
              } else if (originFee < 0) {
                result.push({
                  shortE: this.compareExchange[j],
                  longE: this.compareExchange[i],
                  symbol: c1,
                  fee: -originFee,
                  time: time
                });
              }
            }
          });
        }
      }
      result.sort(function (a, b) {
        return a.fee - b.fee;
      });
      result.reverse();
      return result;
    }
  }
}
</script>

<style scoped>

</style>