<script>
import AbmNet from "@/api/net/AbmNet";
import ServiceMapper from "@/const/ServiceMapper";
import SymbolFundingFeeNameResolver from "@/utils/SymbolFundingFeeNameResolver";

let FUNDING_FEE_MODEL = {};
let FUNDING_DAYS = {
  DAY: 1,
  THREE_DAY: 3,
  WEEK: 7,
  TWO_WEEK: 15,
  MONTH: 30,
  TWO_MONTH: 60,
  THREE_MONTH: 90,
  SIX_MONTH: 180,
  YEAR: 365,
}

function loadData() {
  loadSymbolFundingFee();
}

function loadSymbolFundingFee() {
  AbmNet.getRequest(ServiceMapper.WEB_SERVICE.dataServiceAllFundingFee, function (data) {
    FUNDING_FEE_MODEL = data;
  });
}

function getFundingFee(platform, symbolType, symbolName, days) {
  symbolName = SymbolFundingFeeNameResolver.nameResolver(platform, symbolType, symbolName);
  try {
    return FUNDING_FEE_MODEL[platform][symbolName][days];
  } catch (e) {
    return 0;
  }
}

function getFundingFeeModel() {
  return FUNDING_FEE_MODEL;
}

function init() {
  loadData();
  setInterval(loadData, 1800000);
}

export default {
  init,
  getFundingFee,
  getFundingFeeModel,
  FUNDING_DAYS
}
</script>
