<script>

import Web3Service from "@/web3/Web3Service";
import GmxAbi from "@/web3/abis/gmx/GmxAbi";

let glpRewardTrackerAbiContractAddress = '0xd2d1162512f927a7e282ef43a362659e4f2a728f';
let glpManagerContractAddress = '0xD152c7F25db7F4B95b7658323c5F33d176818EE4';
let rewardReaderContractAddress = '0x04Fc11Bd28763872d143637a7c768bD96E44c1b6';

function getGlpRewardTrackerContract(that) {
  let web3 = Web3Service.getWeb3(that);
  return new web3.eth.Contract(GmxAbi.glpRewardTrackerABI, glpRewardTrackerAbiContractAddress);
}

function getGlpManagerContract(that) {
  let web3 = Web3Service.getWeb3(that);
  return new web3.eth.Contract(GmxAbi.glpManagerABI, glpManagerContractAddress);
}

function getRewardReaderContract(that) {
  let web3 = Web3Service.getWeb3(that);
  return new web3.eth.Contract(GmxAbi.rewardReaderABI, rewardReaderContractAddress);
}

export default {
  glpRewardTrackerAbiContractAddress,
  glpManagerContractAddress,
  rewardReaderContractAddress,
  getGlpRewardTrackerContract,
  getGlpManagerContract,
  getRewardReaderContract
}
</script>