<script>

function openAlert(that, title, content, then) {
  that.$alert(content, title, {
    confirmButtonText: '确定',
    confirmButtonClass: 'dark-mode-btn',
    center: true,
    closeOnClickModal: false,
    closeOnPressEscape: false,
    customClass: 'black-card'
  }).then(() => {
    then();
  }).catch(() => {
  });
}

function openConfirm(that, title, content, then) {
  that.$confirm(content, title, {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    cancelButtonClass: 'dark-mode-btn',
    confirmButtonClass: 'dark-mode-btn',
    type: 'info',
    center: true,
    closeOnClickModal: false,
    closeOnPressEscape: false,
    customClass: 'black-card'
  }).then(() => {
    then();
  }).catch(() => {
  });
}

function openConfirmWithInput(that, title, content, then, defaultV) {
  that.$prompt(content, title, {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    cancelButtonClass: 'dark-mode-btn',
    confirmButtonClass: 'dark-mode-btn',
    inputErrorMessage: '备注名格式不正确',
    center: true,
    closeOnClickModal: false,
    closeOnPressEscape: false,
    customClass: 'black-card',
    inputValue: defaultV,
    inputPattern: /^[a-zA-Z][a-zA-Z0-9\u4e00-\u9fa5_.]{4,25}$/
  }).then(({value}) => {
    then(value)
  }).catch(() => {
  });
}

function openConfirmWithPassword(that, title, content, then, defaultV) {
  that.$prompt(content, title, {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    cancelButtonClass: 'dark-mode-btn',
    confirmButtonClass: 'dark-mode-btn',
    center: true,
    closeOnClickModal: false,
    closeOnPressEscape: false,
    customClass: 'black-card',
    inputValue: defaultV,
    inputType: 'password'
  }).then(({value}) => {
    then(value)
  }).catch(() => {
  });
}

function openConfirmWithInputWithoutRegex(that, title, content, then, defaultV) {
  that.$prompt(content, title, {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    cancelButtonClass: 'dark-mode-btn',
    confirmButtonClass: 'dark-mode-btn',
    center: true,
    closeOnClickModal: false,
    closeOnPressEscape: false,
    customClass: 'black-card',
    inputValue: defaultV
  }).then(({value}) => {
    then(value)
  }).catch(() => {
  });
}


function openConfirmWithRegex(that, title, content, then, defaultV, regex) {
  that.$prompt(content, title, {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    cancelButtonClass: 'dark-mode-btn',
    confirmButtonClass: 'dark-mode-btn',
    inputErrorMessage: '客户名格式不正确',
    center: true,
    inputPlaceholder: "",
    closeOnClickModal: false,
    closeOnPressEscape: false,
    customClass: 'black-card',
    inputValue: defaultV,
    inputPattern: regex
  }).then(({value}) => {
    then(value)
  }).catch(() => {
  });
}

export default {
  openAlert,
  openConfirm,
  openConfirmWithInput,
  openConfirmWithRegex,
  openConfirmWithPassword,
  openConfirmWithInputWithoutRegex
}
</script>

<style>
.el-message-box__message p {
  color: white;
}

.el-message-box__title span {
  color: white;
}

.black-card .el-input__inner {
  color: white;
  height: 35px;
  background-color: #373e47;
  border: #444c56 1px solid;
}

.el-progress-circle__track {
  stroke: #373e47;
}
</style>