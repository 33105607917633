<template>
  <div class="trialpha-stander-sub-page">
    <div class="trialpha-stander-sub-page" style="padding: 0 0 100px 0">
      <div style="margin-top: 68px;">

        <el-row class="hidden-sm-and-down" style="margin-top: 10px;width: 100%;position: static">
          <el-col :span="20" style="display: flex">
            <!-- 转出、归集、审核 切换 -->
            <FtGroupButtonWidget :buttons.sync="panelSwitchButtons" v-on:switch-change="onAction" />
            <!-- 刷新 切换 -->
            <FtGroupButtonWidget :buttons="[{
                id: 'refresh',
                text: '刷新',
                textI18n: '',
            }]" mode="single" v-on:switch-change="onAction" 
            style="background: #3E454E; margin-left: 30px;" />
            <!-- 添加转出地址 切换 -->
            <FtGroupButtonWidget :buttons="[{
                id: 'createWallet',
                text: '添加转出地址',
                textI18n: '',
            }]" mode="single" v-on:switch-change="onAction" 
            style="background: #3E454E; margin-left: 30px;" />
            <!-- 删除转出地址 切换 -->
            <FtGroupButtonWidget :buttons="[{
                id: 'deleteWallet',
                text: '删除转出地址',
                textI18n: '',
            }]" mode="single" v-on:switch-change="onAction" 
            style="background: #3E454E; margin-left: 30px;" />
          </el-col>
        </el-row>

        <TransferOut v-if="currentPanel == 'TransferOut'" ref="top" v-on:switch-change="onAction" />
        <Assemble v-if="currentPanel == 'Assemble'" ref="aep" v-on:switch-change="onAction" />
        <Examine v-if="currentPanel == 'Examine'" ref="eep" v-on:switch-change="onAction" />
        
      </div>
    </div>

    <FtWalletCreateCommonUsedWalletDialog v-if="dialogCreateCommonUsedWalletShow" 
      :show.sync="dialogCreateCommonUsedWalletShow" 
      :mode="dialogCreateCommonUsedWalletMode" 
      v-on:success="onAction" />
    
    <FtWalletCreateWalletDialog v-if="dialogCreateWalletShow" 
      :show.sync="dialogCreateWalletShow" 
      :mode="dialogCreateWalletMode" 
      v-on:success="onAction" />
    
    <FtWalletDeleteWalletDialog v-if="dialogDeleteWalletShow" 
      :show.sync="dialogDeleteWalletShow" 
      :mode="dialogDeleteWalletMode" 
      v-on:success="onAction" />
    
    <FtWalletUpdateWalletDialog v-show="dialogUpdateWalletShow" ref="updateWalletDialog"
      :show.sync="dialogUpdateWalletShow" 
      :mode="dialogUpdateWalletMode" 
      v-on:success="onAction" />
    
    <FtWalletApproverDetailWalletDialog v-show="dialogApproverDetailWalletShow" ref="approverDetailWalletDialog"
      :show.sync="dialogApproverDetailWalletShow" 
      :mode="dialogApproverDetailWalletMode" 
      v-on:success="onAction" />

    <FtWalletTransferDetailDialog v-show="dialogTransferDetailShow" ref="transferDetailDialog"
      :show.sync="dialogTransferDetailShow" 
      :mode="dialogTransferDetailMode" 
      v-on:success="onAction" />

    <FtWalletTransferApprovalDialog v-show="dialogTransferApprovalShow" ref="transferApprovalDialog"
      :show.sync="dialogTransferApprovalShow" 
      :mode="dialogTransferApprovalMode" 
      v-on:success="onAction" />
      
  </div>
</template>

<script>
import UserPermissionService from "@/api/user/UserPermissionService";
import FtGroupButtonWidget from "@/components/widgets/FtGroupButtonWidget";
import TransferOut from "@/pages/controlPanel/wallet/TransferOut";
import Assemble from "@/pages/controlPanel/wallet/Assemble";
import Examine from "@/pages/controlPanel/wallet/Examine";

import FtWalletCreateCommonUsedWalletDialog from "@/components/FtWalletCreateCommonUsedWalletDialog";
import FtWalletCreateWalletDialog from "@/components/FtWalletCreateWalletDialog";
import FtWalletDeleteWalletDialog from "@/components/FtWalletDeleteWalletDialog";
import FtWalletUpdateWalletDialog from "@/components/FtWalletUpdateWalletDialog";
import FtWalletApproverDetailWalletDialog from "@/components/FtWalletApproverDetailWalletDialog";
import FtWalletTransferDetailDialog from "@/components/FtWalletTransferDetailDialog";
import FtWalletTransferApprovalDialog from "@/components/FtWalletTransferApprovalDialog";

import { useWalletStore } from '@/store/wallet'

export default {
  name: "Wallet",
  components: {
    FtGroupButtonWidget,
    TransferOut,
    Assemble,
    Examine,
    FtWalletCreateCommonUsedWalletDialog,
    FtWalletCreateWalletDialog,
    FtWalletDeleteWalletDialog,
    FtWalletUpdateWalletDialog,
    FtWalletApproverDetailWalletDialog,
    FtWalletTransferDetailDialog,
    FtWalletTransferApprovalDialog
  },
  data: function () {
    return {
      // 面板切换
      currentPanel: '',
      panelSwitchButtons: [
          {
              id: 'TransferOut',
              text: '转出',
              textI18n: '',
              active: false
          },
          {
              id: 'Assemble',
              text: '归集',
              textI18n: '',
              active: false
          },
          {
              id: 'Examine',
              text: '审核',
              textI18n: '',
              active: false
          }
      ],

      // 新建常用钱包
      dialogCreateCommonUsedWalletShow: false,
      dialogCreateCommonUsedWalletMode: 'cex',

      // 新建钱包
      dialogCreateWalletShow: false,
      dialogCreateWalletMode: 'cex',

      // 删除钱包
      dialogDeleteWalletShow: false,
      dialogDeleteWalletMode: 'cex',

      // 修改钱包
      dialogUpdateWalletShow: false,
      dialogUpdateWalletMode: 'cex',

      // 修改钱包
      dialogApproverDetailWalletShow: false,
      dialogApproverDetailWalletMode: 'cex',

      // 转账详情
      dialogTransferDetailShow: false,
      dialogTransferDetailMode: 'cex',

      // 审批详情
      dialogTransferApprovalShow: false,
      dialogTransferApprovalMode: 'cex',


    }
  },
  mounted() {
    let query = this.$route.query
    console.log('当前页面路由', this.$route)
    if (!query.tab) {
      this.onAction({id: 'TransferOut'})
      this.panelSwitchButtons.forEach(b => {
        if (b.id == 'TransferOut') {
          b.active = true
        } else {
          b.active = false
        }
      })
    } else {
      if (query.tab == '1') {
        this.onAction({id: 'TransferOut'})
        this.panelSwitchButtons.forEach(b => {
          if (b.id == 'TransferOut') {
            b.active = true
          } else {
            b.active = false
          }
        })
      }
      if (query.tab == '2') {
        this.onAction({id: 'Assemble'})
        this.panelSwitchButtons.forEach(b => {
          if (b.id == 'Assemble') {
            b.active = true
          } else {
            b.active = false
          }
        })
      }
      if (query.tab == '3') {
        this.onAction({id: 'Examine'})
        this.panelSwitchButtons.forEach(b => {
          if (b.id == 'Examine') {
            b.active = true
          } else {
            b.active = false
          }
        })
      }
    }

  },
  methods: {
    hasPermission(featureName) {
      return UserPermissionService.isAllowed(featureName);
    },
    refreshWidth: function () {
      window.fullWidth = document.documentElement.clientWidth;
      this.windowWidth = window.fullWidth;
      if (this.windowWidth > 1000) {
        this.configWidth = '60%';
        this.positionWidth = '40%'
      } else {
        this.configWidth = '100%';
        this.positionWidth = '100%'
      }
    },

    // 各种切换处理
    onAction(item) {
      var that = this
      console.log('switch change event', item.id)
      // 面板切换
      if (item.id == 'TransferOut') {
        let walletStore = useWalletStore()
        walletStore.setFilterTargetChain('')
        that.currentPanel = item.id
      } else if (item.id == 'Assemble') {
        let walletStore = useWalletStore()
        walletStore.setFilterTargetChain('')
        that.currentPanel = item.id
      } else if (item.id == 'Examine') {
        let walletStore = useWalletStore()
        walletStore.setFilterTargetChain('')
        that.currentPanel = item.id
        that.panelSwitchButtons.forEach(b => {
          if (b.id == 'Examine') {
            b.active = true
          } else {
            b.active = false
          }
        })
        this.$refs.eep.refresh()
      }
      // 添加常用钱包
      else if (item.id == 'createCommonUsedWallet') {
        this.dialogCreateCommonUsedWalletMode = 'dex'
        this.dialogCreateCommonUsedWalletShow = true
      }
      // 添加钱包
      else if (item.id == 'createWallet') {
        this.dialogCreateWalletMode = 'cex'
        this.dialogCreateWalletShow = true
      }
      // 删除钱包
      else if (item.id == 'deleteWallet') {
        this.dialogDeleteWalletMode = 'cex'
        this.dialogDeleteWalletShow = true
      }
      else if (item.id == 'createCexWalletSuccess' || item.id == 'createDexWalletSuccess') {
        let wallet = item.value
        if (this.currentPanel == 'TransferOut') {
          this.$refs.top.setSourceWallet(wallet)
        }
        // if (this.currentPanel == 'Assemble') {
        //   this.$refs.aep.setSourceWallet(wallet)
        // }
      }
      // 修改钱包
      else if (item.id == 'updateWallet') {
        this.dialogUpdateWalletMode = 'cex'
        this.dialogUpdateWalletShow = true
        this.$refs.updateWalletDialog.setData(item.value)
      }
      // 钱包审批成员
      else if (item.id == 'approverDetailWallet') {
        this.dialogApproverDetailWalletMode = 'cex'
        this.dialogApproverDetailWalletShow = true
        this.$refs.approverDetailWalletDialog.setData(item.value)
      }
      // 转账详情
      else if (item.id == 'transferDetail') {
        this.dialogTransferDetailMode = 'cex'
        this.dialogTransferDetailShow = true
        this.$refs.transferDetailDialog.setData(item.value)
      }
      // 转账审批详情
      else if (item.id == 'approvalDetail') {
        this.dialogTransferApprovalMode = 'cex'
        this.dialogTransferApprovalShow = true
        this.$refs.transferApprovalDialog.setData(item.value)
      }
      // 刷新
      else if (item.id == 'refresh' || item.id == 'deleteWalletSuccess') {
        if (that.currentPanel == 'TransferOut') {
          this.$refs.top.refresh()
        }
        if (that.currentPanel == 'Assemble') {
          this.$refs.aep.refresh()
        }
        if (that.currentPanel == 'Examine') {
          this.$refs.eep.refresh()
        }
      }
    }
  }
}
</script>

<style>

input,select,textarea{
  outline: none;
}

</style>
