<template>
  <div>
    <el-row class="hidden-sm-and-down" style="margin-top: 32px; width: 100%;position: static;">
      <el-col :span="14">
        <!-- 选择源地址 -->
        <FtWalletTransferOutSourceAddressPanel ref="tosap" v-on:switch-change="onAction" />
        <!-- 选择目标地址 -->
        <FtWalletTransferOutTargetAddressPanel ref="totap" v-on:switch-change="onAction" style="margin-top: 20px;"/>
      </el-col>
      <el-col :span="10">
        <div style="border-left: 1px solid #3E454E; margin-left: 30px; padding-left: 30px;">
          <FtWalletAddressOftenPanel ref="aop" v-on:switch-change="onAction" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {Message} from "element-ui";
import ConfirmProvider from '@/components/ConfirmProvider'
import UserPermissionService from "@/api/user/UserPermissionService";
import FtWalletTransferOutSourceAddressPanel from "@/components/FtWalletTransferOutSourceAddressPanel";
import FtWalletTransferOutTargetAddressPanel from "@/components/FtWalletTransferOutTargetAddressPanel";
import FtWalletAddressOftenPanel from "@/components/FtWalletAddressOftenPanel";
import TrialphaLoading from "@/components/TrialphaLoading";

import { useWalletStore } from '@/store/wallet'

export default {
  components: {
    FtWalletTransferOutSourceAddressPanel,
    FtWalletTransferOutTargetAddressPanel,
    FtWalletAddressOftenPanel
  },
  data: function () {
    return {


    }
  },
  mounted() {

    this.refresh()

  },
  created() {
    
  },
  destroyed: function () {
    
  },
  methods: {
    hasPermission(featureName) {
      return UserPermissionService.isAllowed(featureName);
    },

    refresh() {
      let that = this
      console.log('刷新页面=====')
      let loading = TrialphaLoading.loading(that)
      const walletStore = useWalletStore()
      walletStore.listCommonUsedWallet()
      walletStore.listWalletCreatedByRequester()
      walletStore.listWalletApprovedByRequester()
      walletStore.listAllChainTokenMappings()
      
      this.$refs.tosap.getWalletMoney(false).then(() => {
        setTimeout(() => {
          loading.close()
        }, 1000)
      })
    },

    resetFormData() {
      console.log('清理表单=====')
        this.$refs.tosap.resetFormData()
        this.$refs.totap.resetFormData()
        this.$refs.aop.resetFormData()
    },

    setSourceWallet(wallet) {
      this.$refs.tosap.setSourceWallet(wallet)
    },

    // 各种切换处理
    onAction(item) {
      let that = this
      if (item.id == 'confirm') {
        // 
        let sourceData = this.$refs.tosap.getFormData()
        let targetData = this.$refs.totap.getFormData()
        console.log('转出', sourceData)
        console.log('转入', targetData)
        if (sourceData.sourceWallet == '') {
          Message.error('请选择转出钱包')
          return
        }
        if (sourceData.targetChain == '') {
          Message.error('请选择目标链')
          return
        }
        if (sourceData.coinType == '') {
          Message.error('请选择转出代币')
          return
        }
        // if (sourceData.remark == '') {
        //   Message.error('请输入备注信息')
        //   return
        // }
        // 必须输入地址
        if (targetData.addressList.length == 0) {
          Message.error('请添加目标钱包地址')
          return
        }
        let addressEmpty = false
        targetData.addressList.forEach(t => {
            if (t.bind == null && t.display == '') {
              addressEmpty = true
            }
        })
        if (addressEmpty) {
          Message.error('请输入目标钱包地址')
          return
        }
        // 转出数量检查
        let zero = false
        targetData.addressList.forEach(t => {
          if (isNaN(t.count)) {
            zero = true
          }
        })
        if (zero) {
          Message.error('转出数量无效')
          return
        }
        // 必须输入金额
        let moneyEmpty = false
        targetData.addressList.forEach(t => {
            if (parseFloat(t.count) <= 0) {
              moneyEmpty = true
            }
        })
        if (moneyEmpty) {
          Message.error('请输入转出数量')
          return
        }
        // // 检查 转出金额合计，要小于等于余额
        // let targetTotalMoney = 0
        // targetData.addressList.forEach(t => {
        //     targetTotalMoney += parseFloat(t.count)
        // })
        // if (targetTotalMoney > sourceData.money) {
        //   Message.error('转出金额合计，要小于等于余额')
        //   return
        // }
        
        // 备注检查
        if (targetData.remark == '') {
          Message.error('请输入备注信息')
          return
        }

        let walletStore = useWalletStore()
        // 检查是否满足当前币种的转账数量
        walletStore.checkTransferAmount(sourceData, targetData, targetData.remark).then(errors => {
          // 提示信息
          that.$refs.totap.setTransferAmountCheckResult(errors)
          if (errors.length > 0) {
            return
          }
          ConfirmProvider.openConfirm(this, '确认操作', '请确认转账', () => {
            walletStore.createTransfer(sourceData, targetData, targetData.remark).then(() => {
              that.resetFormData()
              Message.success('发起转账成功')
              that.$emit('switch-change', {id: 'Examine'})
            }, () => {
              Message.error('发起转账失败')
            })
          });
        })
      } else if (item.id == 'transferAmountCheck') {
        // 
        let sourceData = this.$refs.tosap.getFormData()
        let targetData = this.$refs.totap.getFormData()
        console.log('转出', sourceData)
        console.log('转入', targetData)
        if (sourceData.sourceWallet == '') {
          // Message.error('请选择转出钱包')
          return
        }
        if (sourceData.targetChain == '') {
          // Message.error('请选择目标链')
          return
        }
        if (sourceData.coinType == '') {
          // Message.error('请选择转出代币')
          return
        }
        // 转出数量检查
        let zero = false
        targetData.addressList.forEach(t => {
          if (isNaN(t.count)) {
            zero = true
          }
        })
        if (zero) {
          Message.error('转出数量无效')
          return
        }
        // 必须输入金额
        let moneyEmpty = false
        targetData.addressList.forEach(t => {
            if (parseFloat(t.count) <= 0) {
              moneyEmpty = true
            }
        })
        if (moneyEmpty) {
          // Message.error('请输入转出数量')
          return
        }

        let walletStore = useWalletStore()
        // 检查是否满足当前币种的转账数量
        walletStore.checkTransferAmount(sourceData, targetData, targetData.remark).then(errors => {
          that.$refs.totap.setTransferAmountCheckResult(errors)
        })
      } else if (item.id == 'commonUsedWalletChecked') {
        // 添加目标地址
        this.$refs.totap.pushCommonUsedWallet(item.value)
      } else if (item.id == 'commonUsedWalletUnchecked') {
        // 取消目标地址
        this.$refs.totap.popCommonUsedWallet(item.value)
      } else if (item.id == 'removeTargetWallet') {
        // 取消常用钱包
        console.log('===取消常用钱包', item.value)
        if (item.value) {
          this.$refs.aop.uncheckedCommonUsedWallet(item.value)
        }
      } else {
        this.$emit('switch-change', item)
      }
    }
  }
}
</script>

<style>


</style>