<script>

import GeneralApiService from "@/api/trade/GeneralApiService";

function binanceUsdNameResolver(symbol) {
  if (symbol.endsWith("USDT")) {
    return symbolNameGeneralResolver(symbol).split("USDT")[0] + "-U-USDT";
  } else if (symbol.endsWith("BUSD")) {
    return  symbolNameGeneralResolver(symbol).split("BUSD")[0] + "-U-USD";
  }
  return symbol;
}

function binanceCoinNameResolver(symbol) {
  if (symbol.endsWith("USD_PERP")) {
    return symbolNameGeneralResolver(symbol).replaceAll("USD_PERP", "") + "-C";
  }
  return symbol;
}

function ftxUsdNameResolver(symbol) {
  if (symbol.endsWith("-PERP")) {
    return symbolNameGeneralResolver(symbol).replaceAll("-PERP", "") + "-U-USD";
  }
  return symbol;
}

function okxNameResolver(symbol) {
  if (symbol.endsWith("-USD-SWAP")) {
    return symbolNameGeneralResolver(symbol).replaceAll("-USD-SWAP", "") + "-C";
  }
  if (symbol.endsWith("-USDT-SWAP")) {
    return symbolNameGeneralResolver(symbol).replaceAll("-USDT-SWAP", "") + "-U-USDT";
  }
  return symbol;
}

function deribitNameResolver(symbol) {
  if (symbol.endsWith("-PERPETUAL")) {
    return symbolNameGeneralResolver(symbol).replaceAll("-PERPETUAL", "") + "-U-USD";
  }
  return symbol;
}

function bybitNameResolver(symbol) {
  if (symbol.endsWith("USD")) {
    return symbolNameGeneralResolver(symbol).replaceAll("USD", "") + "-C";
  }
  if (symbol.endsWith("USDT")) {
    return symbolNameGeneralResolver(symbol).replaceAll("USDT", "") + "-U-USDT";
  }
  if (symbol.endsWith("USDC")) {
    return symbolNameGeneralResolver(symbol).replaceAll("USDC", "") + "-U-USD";
  }
  if (symbol.endsWith("PERP")) {
    return symbolNameGeneralResolver(symbol).replaceAll("PERP", "") + "-U-USD";
  }
  return symbol;
}

function kuCoinNameResolver(symbol) {
  symbol = symbol.replaceAll("XBT", "BTC");
  if (symbol.endsWith("USDM")) {
    return symbolNameGeneralResolver(symbol).replaceAll("USDM", "") + "-C";
  }
  if (symbol.endsWith("USDTM")) {
    return symbolNameGeneralResolver(symbol).replaceAll("USDTM", "") + "-U-USDT";
  }
  return symbol;
}

function mexcNameResolver(symbol) {
  if (symbol.endsWith("_USDT")) {
    return symbolNameGeneralResolver(symbol).replaceAll("_USDT", "") + "-U-USDT";
  }
  if (symbol.endsWith("_USD")) {
    return symbolNameGeneralResolver(symbol).replaceAll("_USD", "") + "-C";
  }
  return symbol;
}

function dydxNameResolver(symbol) {
  if (symbol.endsWith("-USD")) {
    return symbolNameGeneralResolver(symbol).replaceAll("-USD", "") + "-U-USD";
  }
  return symbol;
}

function symbolNameGeneralResolver(symbol) {
  return symbol.replaceAll("10000", "").replaceAll("1000", "");
}

function nameResolver(platform, symbolType, symbol) {
  if (platform === GeneralApiService.PLATFORM.BINANCE) {
    if (symbolType === GeneralApiService.SYMBOL_TYPE.USD_CONTRACT) {
      return binanceUsdNameResolver(symbol);
    } else if (symbolType === GeneralApiService.SYMBOL_TYPE.COIN_CONTRACT) {
      return binanceCoinNameResolver(symbol);
    }
  } else if (platform === GeneralApiService.PLATFORM.OKX) {
    return okxNameResolver(symbol);
  } else if (platform === GeneralApiService.PLATFORM.FTX) {
    return ftxUsdNameResolver(symbol);
  } else if (platform === GeneralApiService.PLATFORM.DERIBIT) {
    return deribitNameResolver(symbol);
  }  else if (platform === GeneralApiService.PLATFORM.BYBIT) {
    if (symbolType === GeneralApiService.SYMBOL_TYPE.USD_CONTRACT) {
      return bybitNameResolver(symbol);
    }
  } else if (platform === GeneralApiService.PLATFORM.KUCOIN) {
    if (symbolType === GeneralApiService.SYMBOL_TYPE.USD_CONTRACT) {
      return kuCoinNameResolver(symbol);
    }
  } else if (platform === GeneralApiService.PLATFORM.MEXC) {
    if (symbolType === GeneralApiService.SYMBOL_TYPE.USD_CONTRACT) {
      return mexcNameResolver(symbol);
    }
  } else if (platform === GeneralApiService.PLATFORM.DYDX) {
    return dydxNameResolver(symbol);
  }
}

export default {
  nameResolver
}
</script>