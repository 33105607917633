<script>

import Web3Service from "@/web3/Web3Service";
import MintARB from "@/web3/abis/MintARB";

let ca = '0x67a24CE4321aB3aF51c2D0a4801c3E111D88C9d9';

function getContract(that) {
  let web3 = Web3Service.getWeb3(that);
  return new web3.eth.Contract(MintARB.ABI, ca);
}

export default {
  ca,
  getContract
}
</script>