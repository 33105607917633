<template>
  <div style="margin-top: 0;height: 100vh;overflow-y: auto">
    <el-row v-if="moreThan1200" style="height: 700px;display: flex">
      <el-col style="flex: 1;height: 100%">
        <el-row style="height: 80px">
          <el-card class="trade-black-card"
                   style="height: 80px;width: 100%;display: flex;float: left;border-radius: 0;border-right: 0;border-top: 0;border-left: 0;"
                   shadow="none" body-style="padding: 0">
            <el-card class="symbol-select-class" body-style="padding: 10px 10px 10px 5px"
                     style="border: 0;background-color: rgba(0, 0, 0, 0);height: 80px;float: left" shadow="none">
              <el-popover
                  @show="renderSymbolSelect"
                  :visible-arrow=false
                  transition="none"
                  popper-class="black-card-symbol-select-popover"
                  placement="bottom"
                  width="368"
                  trigger="hover">
                <symbol-select-panel ref="m1200-symbol-select-panel" style="margin-top: -15px"
                                     v-on:symbolChange="symbolChanged"></symbol-select-panel>
                <div slot="reference">
                  <el-row style="float: left">
                    <span v-if="selectAccount.length > 0" style="display: inline-block;height: 30px;text-align: left;margin-left: 5px;margin-top: 8px;z-index: 0;color: #979696;font-size: 12px">
                      <img :src="exchanges[accountIdMap[selectAccount[1]].platform.toUpperCase()].icon"
                           style="width: 18px;height: 18px;margin-top: -4px;margin-left: 3px"
                           :alt=accountIdMap[selectAccount[1]].platform.toUpperCase()> {{
                        accountIdMap[selectAccount[1]].platform.toUpperCase()
                      }}
                    </span>
                    <span v-if="selectAccount.length === 0"
                          style="display: inline-block;height: 30px;text-align: left;margin-left: 5px;margin-top: 8px;z-index: 0;color: #979696;font-size: 12px">
                <img :src="exchanges['BINANCE'].icon" style="width: 18px;height: 18px;margin-top: -4px;margin-left: 3px"
                     alt='BINANCE'> BINANCE</span>
                  </el-row>
                  <el-row>
                    <el-button class="dark-mode-btn"
                               style="margin-top: -5px;z-index: 1;text-align: left;padding-top: 0;height: 20px;border-radius: 0;float: left;background-color: rgba(0, 0, 0, 0);border: 0;font-size: 16px;padding-left: 8px">
                      {{ selectSymbol }} <i class="el-icon-caret-bottom"></i></el-button>
                  </el-row>
                </div>
              </el-popover>
            </el-card>
            <el-card class="trade-black-card" style="border: 0;flex: 1;height: 80px;float: right"
                     shadow="none" body-style="padding: 0">
              <el-card class="trade-black-card" style="border: 0;width: 88px;height: 80px;float: left;margin-left: 20px"
                       shadow="none" body-style="padding: 20px 5px 20px 5px">
                <el-row>
                  <span style="color: #979696;font-size: 12px;display: inline-block;float: left">Last Price</span>
                </el-row>
                <el-row style="margin-top: 5px">
                <span
                    :style="'' + (symbolInfo.lastPrice > lastLastPrice ? 'color: rgb(14, 203, 129);' : 'color: rgb(246, 70, 93);' ) + ';font-size: 13px;display: inline-block;float: left'">{{
                    symbolInfo.lastPrice
                  }}</span>
                </el-row>
              </el-card>
              <el-card class="trade-black-card" style="border: 0;width: 88px;height: 80px;float: left" shadow="none"
                       body-style="padding: 20px 5px 20px 5px">
                <el-row>
                  <span style="color: #979696;font-size: 12px;display: inline-block;float: left">24h Change</span>
                </el-row>
                <el-row style="margin-top: 5px">
                  <span
                      :style="'' + (symbolInfo.change24h > 0 ? 'color: rgb(14, 203, 129);' : 'color: rgb(246, 70, 93);' ) + 'font-size: 13px;display: inline-block;float: left'">{{
                      (symbolInfo.change24h).toFixed(3)
                    }}%</span>
                </el-row>
              </el-card>
              <el-card class="trade-black-card" style="border: 0;width: 88px;height: 80px;float: left" shadow="none"
                       body-style="padding: 20px 5px 20px 5px">
                <el-row>
                  <span style="color: #979696;font-size: 12px;display: inline-block;float: left">Funding Rate</span>
                </el-row>
                <el-row style="margin-top: 5px">
                  <span
                      :style="'' + (symbolInfo.fundingRate > 0 ? 'color: rgb(246, 70, 93);' : 'color: rgb(14, 203, 129);') + ';font-size: 13px;display: inline-block;float: left'">{{
                      (symbolInfo.fundingRate * 100).toFixed(4)
                    }}%</span>
                </el-row>
              </el-card>
              <el-card class="trade-black-card" style="border: 0;width: 125px;height: 80px;float: left" shadow="none"
                       body-style="padding: 20px 5px 20px 5px">
                <el-row>
                  <span style="color: #979696;font-size: 12px;display: inline-block;float: left">24h Volume {{ quotaCurrency }}</span>
                </el-row>
                <el-row style="margin-top: 5px">
                  <span style="color: white;font-size: 13px;display: inline-block;float: left">{{
                      Number.prototype.toFormatNum(symbolInfo.volumeUsd24h.toFixed(0))
                    }}</span>
                </el-row>
              </el-card>
            </el-card>
          </el-card>
        </el-row>
        <el-row style="height: 620px">
          <TVChartContainer ref="m1200-tv-main-panel" :symbol="selectSymbol" :hide-header-search="true" v-on:chart-subscribe="onChartSubscribe"
                            style="width: 100%;float: left"/>
        </el-row>
      </el-col>
      <div class="operation-area">
        <el-col style="width: 46.5%;height: 100%">
          <draggable animation="888">
            <div>
              <el-card class="trade-black-card"
                       style="height: 80px;width: 100%;float: left;border-radius: 0;border-right: 0;border-top: 0;border-bottom: 0"
                       shadow="none" body-style="padding: 10px 15px 10px 15px">
                <el-row>
                  <span style="color: white;font-size: 13px;display: inline-block;float: left">Orderbook</span>
                </el-row>
                <el-row style="margin-top: 22px">
                  <el-col :span=8>
                    <span style="color: darkgray;font-size: 12px;display: inline-block;float: left">Price</span>
                  </el-col>
                  <el-col :span="8">
                    <span style="color: darkgray;font-size: 12px;display: inline-block;float: right">Amount</span>
                  </el-col>
                  <el-col :span="8">
                    <span style="color: darkgray;font-size: 12px;display: inline-block;float: right">Total</span>
                  </el-col>
                </el-row>
              </el-card>
              <order-book-panel ref="m1200-order-book-panel" style="height: 450px"></order-book-panel>
            </div>
            <el-card class="trade-black-card"
                     style="height: 170px;width: 100%;border-radius: 0;border-right: 0;border-bottom: 0" shadow="none"
                     body-style="padding: 0">
              <el-row style="padding: 15px">
                <span style="color: white;font-size: 13px;display: inline-block;float: left">Trades</span>
              </el-row>
              <el-row style="padding-left: 15px;padding-right: 15px">
                <el-col :span=8>
                  <span style="color: darkgray;font-size: 12px;display: inline-block;float: left">Price</span>
                </el-col>
                <el-col :span="8">
                  <span style="color: darkgray;font-size: 12px;display: inline-block;float: right">Amount</span>
                </el-col>
                <el-col :span="8">
                  <span style="color: darkgray;font-size: 12px;display: inline-block;float: right">Time</span>
                </el-col>
              </el-row>
              <trades-panel ref="m1200-trades-panel" style="height: 250px"></trades-panel>
            </el-card>
          </draggable>
        </el-col>
        <el-col style="width: 53.5%;height: 100%">
          <draggable animation="888">
            <el-card class="trade-black-card"
                     style="height: 80px;border-radius: 0;border-top: 0;border-right: 0;border-bottom: 0"
                     body-style="padding: 0">
              <el-cascader
                  class="account-select"
                  style="width: 100%;margin-top: -3px;"
                  v-model="selectAccount"
                  :options="instanceList"
                  :props="{ expandTrigger: 'hover' }"
                  @change="handleChange"></el-cascader>
              <el-row style="padding-top: 5px;padding-left: 10px;border-top: 1px solid #444c56"
                      v-if="selectAccount.length > 0">
                <span
                    style="font-size: 12px;color: white;display: inline-block;float: left;padding-left: 5px;padding-top: 5px">Balance: <span
                    style="display: inline-block;padding-left: 10px;">{{
                    accountIdMap[selectAccount[1]].currentBalance.toFixed(4)
                  }} {{ accountIdMap[selectAccount[1]].baseCoin }}</span></span>
                <img :src="exchanges[accountIdMap[selectAccount[1]].platform.toUpperCase()].icon"
                     :style="'' + exchanges[accountIdMap[selectAccount[1]].platform.toUpperCase()].iconStyle + ''"
                     :alt=accountIdMap[selectAccount[1]].platform.toUpperCase()>
              </el-row>
              <el-row style="padding-top: 5px;padding-left: 10px;border-top: 1px solid #444c56"
                      v-if="selectAccount.length <= 0">
                <span
                    style="font-size: 12px;color: white;display: inline-block;float: left;padding-left: 5px;padding-top: 5px"><span
                    style="display: inline-block;padding-left: 10px;">PLEASE ADD AN ACCOUNT FIRST</span></span>
              </el-row>
            </el-card>
            <el-card class="trade-black-card" style="height: 128px;width: 100%;border-radius: 0;border-right: 0"
                     shadow="none" body-style="padding: 0">
              <general-account-profit-panel ref="m1200-profit-panel" :id="'account_' + selectAccount[0]"
                                            style="width: 100%;height: 126px;margin-top: -2px;z-index: 1;"></general-account-profit-panel>
            </el-card>
            <el-card class="trade-black-card"
                     style="height: 492px;width: 100%;float: left;border-radius: 0;border-top: 0;border-right: 0;border-bottom: 0"
                     shadow="none" body-style="padding: 0">
              <el-row>
                <el-col :span="12">
                  <el-button :class="{'dark-trade-btn-long-active': isLong, 'dark-trade-btn-long': !isLong}"
                             style="width: 100%;height: 40px" @click="isLong = true">BUY
                  </el-button>
                </el-col>
                <el-col :span="12">
                  <el-button :class="{'dark-trade-btn-short-active': !isLong, 'dark-trade-btn-short': isLong}"
                             style="width: 100%;height: 40px" @click="isLong = false">SELL
                  </el-button>
                </el-col>
              </el-row>
              <el-row style="margin-top: 5px">
                <el-col :span="6">
                  <el-button :class="{'order-type-btn-active': activeLimit, 'order-type-btn': !activeLimit}" style=""
                             @click="changeOrderType('Limit')">Limit
                  </el-button>
                </el-col>
                <el-col :span="6">
                  <el-button :class="{'order-type-btn-active': activeMarket, 'order-type-btn': !activeMarket}" style=""
                             @click="changeOrderType('Market')">Market
                  </el-button>
                </el-col>
                <el-col :span="6">
                  <el-button disabled :class="{'order-type-btn-active': activeTWAP, 'order-type-btn': !activeTWAP}"
                             style=""
                             @click="changeOrderType('TWAP')">TWAP
                  </el-button>
                </el-col>
                <el-col :span="6">
                  <el-button disabled :class="{'order-type-btn-active': activeVWAP, 'order-type-btn': !activeVWAP}"
                             style=""
                             @click="changeOrderType('VWAP')">VWAP
                  </el-button>
                </el-col>
              </el-row>
              <el-row style="padding: 15px">
                <el-row>
                  <span style="font-size: 12px;color: gray;display: inline-block;float: left">Coin amount</span>
                </el-row>
                <el-row style="margin-top: 5px">
                  <input class="trade-black-card" type="text" v-auth:tradePanelWrite
                         style="background-color: #1a1f25;width: 100%;outline: none;color: white;font-size: 12px;height: 30px;padding-left: 5px;border-radius: 2px">
                </el-row>
                <div class="limit-comp" v-if="activeLimit">
                  <el-row style="margin-top: 10px">
                    <span style="font-size: 12px;color: gray;display: inline-block;float: left">Price</span>
                  </el-row>
                  <el-row style="margin-top: 5px">
                    <input class="trade-black-card" type="text" v-auth:tradePanelWrite
                           style="background-color: #1a1f25;width: 100%;outline: none;color: white;font-size: 12px;height: 30px;padding-left: 5px;border-radius: 2px">
                  </el-row>
                </div>
                <div class="WAP-comp" v-if="activeVWAP || activeTWAP">
                  <el-row style="margin-top: 10px">
                    <span style="font-size: 12px;color: gray;display: inline-block;float: left">Running time</span>
                  </el-row>
                  <el-row style="margin-top: 5px">
                    <input class="trade-black-card" type="text" v-auth:tradePanelWrite
                           style="background-color: #1a1f25;width: 100%;outline: none;color: white;font-size: 12px;height: 30px;padding-left: 5px;border-radius: 2px">
                  </el-row>
                  <el-row style="margin-top: 10px">
                    <span style="font-size: 12px;color: gray;display: inline-block;float: left">Maximum Market Participation Rate</span>
                  </el-row>
                  <el-row style="margin-top: 5px">
                    <input class="trade-black-card" type="text" v-auth:tradePanelWrite
                           style="background-color: #1a1f25;width: 100%;outline: none;color: white;font-size: 12px;height: 30px;padding-left: 5px;border-radius: 2px">
                  </el-row>
                </div>
                <el-row style="margin-top: 25px">
                  <el-button :class="{'trade-btn-long': isLong, 'trade-btn-short': !isLong}" v-auth:tradePanelWrite
                             style="width: 100%;height: 35px;line-height: 35px;padding: 0">{{ isLong ? 'BUY' : 'SELL' }}
                  </el-button>
                </el-row>
              </el-row>
            </el-card>
          </draggable>
        </el-col>
      </div>
    </el-row>
    <el-row v-if="moreThan650">
      <el-row class="hide-less-561" style="height: 80px">
        <el-card class="trade-black-card"
                 style="height: 80px;width: 100%;display: flex;float: left;border-radius: 0;border-right: 0;border-top: 0;border-left: 0;"
                 shadow="none" body-style="padding: 0">
          <el-card body-style="padding: 10px 10px 10px 5px"
                   style="border: 0;background-color: rgba(0, 0, 0, 0);height: 80px;float: left"
                   shadow="none">
            <el-popover
                :show="renderSymbolSelect"
                :visible-arrow=false
                transition="none"
                popper-class="black-card-symbol-select-popover"
                placement="bottom"
                width="368"
                trigger="hover">
              <symbol-select-panel ref="m650-symbol-select-panel" style="margin-top: -15px"
                                   v-on:symbolChange="symbolChanged"></symbol-select-panel>
              <div slot="reference">
                <el-row style="float: left">
                    <span v-if="selectAccount.length > 0" style="display: inline-block;height: 30px;text-align: left;margin-left: 5px;margin-top: 8px;z-index: 0;color: #979696;font-size: 12px">
                      <img :src="exchanges[accountIdMap[selectAccount[1]].platform.toUpperCase()].icon"
                           style="width: 18px;height: 18px;margin-top: -4px;margin-left: 3px"
                           :alt=accountIdMap[selectAccount[1]].platform.toUpperCase()> {{
                        accountIdMap[selectAccount[1]].platform.toUpperCase()
                      }}
                    </span>
                  <span v-if="selectAccount.length === 0"
                        style="display: inline-block;height: 30px;text-align: left;margin-left: 5px;margin-top: 8px;z-index: 0;color: #979696;font-size: 12px">
                <img :src="exchanges['BINANCE'].icon" style="width: 18px;height: 18px;margin-top: -4px;margin-left: 3px"
                     alt='BINANCE'> BINANCE</span>
                </el-row>
                <el-row>
                  <el-button class="dark-mode-btn"
                             style="margin-top: -5px;z-index: 1;text-align: left;padding-top: 0;height: 20px;border-radius: 0;float: left;background-color: rgba(0, 0, 0, 0);border: 0;font-size: 16px;padding-left: 8px">
                    {{ selectSymbol }} <i class="el-icon-caret-bottom"></i></el-button>
                </el-row>
              </div>
            </el-popover>
          </el-card>
          <el-card class="trade-black-card" style="border: 0;height: 80px" shadow="none"
                   body-style="padding: 0">
            <el-card class="trade-black-card" style="border: 0;width: 88px;height: 80px;float: left;margin-left: 20px"
                     shadow="none" body-style="padding: 20px 5px 20px 5px">
              <el-row>
                <span style="color: #979696;font-size: 12px;display: inline-block;float: left">Last Price</span>
              </el-row>
              <el-row style="margin-top: 5px">
                <span
                    :style="'' + (symbolInfo.lastPrice > lastLastPrice ? 'color: rgb(14, 203, 129);' : 'color: rgb(246, 70, 93);' ) + ';font-size: 13px;display: inline-block;float: left'">{{
                    symbolInfo.lastPrice
                  }}</span>
              </el-row>
            </el-card>
            <el-card class="trade-black-card" style="border: 0;width: 88px;height: 80px;float: left" shadow="none"
                     body-style="padding: 20px 5px 20px 5px">
              <el-row>
                <span style="color: #979696;font-size: 12px;display: inline-block;float: left">24h Change</span>
              </el-row>
              <el-row style="margin-top: 5px">
                <span
                    :style="'' + (symbolInfo.change24h > 0 ? 'color: rgb(14, 203, 129);' : 'color: rgb(246, 70, 93);' ) + 'font-size: 13px;display: inline-block;float: left'">{{
                    (symbolInfo.change24h).toFixed(3)
                  }}%</span>
              </el-row>
            </el-card>
            <el-card class="trade-black-card" style="border: 0;width: 88px;height: 80px;float: left" shadow="none"
                     body-style="padding: 20px 5px 20px 5px">
              <el-row>
                <span style="color: #979696;font-size: 12px;display: inline-block;float: left">Funding Rate</span>
              </el-row>
              <el-row style="margin-top: 5px">
                <span
                    :style="'' + (symbolInfo.fundingRate > 0 ? 'color: rgb(246, 70, 93);' : 'color: rgb(14, 203, 129);') + ';font-size: 13px;display: inline-block;float: left'">{{
                    (symbolInfo.fundingRate * 100).toFixed(4)
                  }}%</span>
              </el-row>
            </el-card>
            <el-card class="trade-black-card" style="border: 0;width: 125px;height: 80px;float: left" shadow="none"
                     body-style="padding: 20px 5px 20px 5px">
              <el-row>
                <span style="color: #979696;font-size: 12px;display: inline-block;float: left">24h Volume {{ quotaCurrency }}</span>
              </el-row>
              <el-row style="margin-top: 5px">
                <span style="color: white;font-size: 13px;display: inline-block;float: left">{{
                    Number.prototype.toFormatNum(symbolInfo.volumeUsd24h.toFixed(0))
                  }}</span>
              </el-row>
            </el-card>
          </el-card>
        </el-card>
      </el-row>
      <el-row class="hide-more-561" style="height: 150px">
        <el-card class="trade-black-card"
                 style="height: 150px;width: 100%;float: left;border-radius: 0;border-right: 0;border-top: 0;border-left: 0;"
                 shadow="none" body-style="padding: 0">
          <el-row>
            <el-card body-style="padding: 8px 10px 10px 5px"
                     style="border: 0;background-color: rgba(0, 0, 0, 0);width: 238px;height: 80px;float: left"
                     shadow="none">
              <el-popover
                  :show="renderSymbolSelect"
                  :visible-arrow=false
                  transition="none"
                  popper-class="black-card-symbol-select-popover"
                  placement="bottom"
                  width="368"
                  trigger="hover">
                <symbol-select-panel ref="l650-symbol-select-panel" style="margin-top: -5px"
                                     v-on:symbolChange="symbolChanged"></symbol-select-panel>
                <div slot="reference">
                <span v-if="selectAccount.length > 0"
                      style="display: inline-block;width: 238px;height: 30px;text-align: left;margin-left: 5px;margin-top: 8px;z-index: 0;color: #979696;font-size: 12px">
                <img :src="exchanges[accountIdMap[selectAccount[1]].platform.toUpperCase()].icon"
                     style="width: 18px;height: 18px;margin-top: -4px;margin-left: 3px"
                     :alt=accountIdMap[selectAccount[1]].platform.toUpperCase()>
                {{ accountIdMap[selectAccount[1]].platform.toUpperCase() }}
              </span>
                  <span v-if="selectAccount.length === 0"
                        style="display: inline-block;width: 188px;height: 30px;text-align: left;margin-left: 5px;margin-top: 8px;z-index: 0;color: #979696;font-size: 12px">
                <img :src="exchanges['BINANCE'].icon" style="width: 18px;height: 18px;margin-top: -4px;margin-left: 3px"
                     alt='BINANCE'> BINANCE</span>
                  <el-button class="dark-mode-btn"
                             style="margin-top: -5px;z-index: 1;text-align: left;width: 188px;padding-top: 0;height: 20px;border-radius: 0;float: left;background-color: rgba(0, 0, 0, 0);border: 0;font-size: 16px;padding-left: 8px">
                    {{ selectSymbol }} <i class="el-icon-caret-bottom"></i></el-button>
                </div>
              </el-popover>
            </el-card>
            <el-card class="trade-black-card" style="border: 0;width: 128px;height: 80px;float: right" shadow="none"
                     body-style="padding: 15px">
              <el-row>
                <span style="color: #979696;font-size: 12px;display: inline-block;float: right">Funding Rate</span>
              </el-row>
              <el-row style="margin-top: 5px">
                <span
                    :style="'' + (symbolInfo.fundingRate > 0 ? 'color: rgb(246, 70, 93);' : 'color: rgb(14, 203, 129);') + ';font-size: 13px;display: inline-block;float: right'">{{
                    (symbolInfo.fundingRate * 100).toFixed(4)
                  }}%</span>
              </el-row>
            </el-card>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-row style="margin-top: 0">
                <span
                    :style="'' + (symbolInfo.lastPrice > lastLastPrice ? 'color: rgb(14, 203, 129);' : 'color: rgb(246, 70, 93);' ) + ';font-size: 18px;height: 30px;line-height: 30px;display: inline-block;float: left;margin-left: 13px'">{{
                    symbolInfo.lastPrice
                  }}</span>
              </el-row>
              <el-row style="margin-top: 0">
                <span
                    :style="'' + (symbolInfo.change24h > 0 ? 'color: rgb(14, 203, 129);' : 'color: rgb(246, 70, 93);' ) + 'font-size: 13px;display: inline-block;float: left;margin-left: 13px'">{{
                    (symbolInfo.change24h).toFixed(3)
                  }}%</span>
              </el-row>
            </el-col>
            <el-col :span="16" style="padding-right: 10px">
              <el-card class="trade-black-card" style="border: 0;width: 125px;height: 80px;float: right" shadow="none"
                       body-style="padding: 5px">
                <el-row>
                  <span style="color: #979696;font-size: 12px;display: inline-block;float: right">24h Volume {{ quotaCurrency }}</span>
                </el-row>
                <el-row style="margin-top: 5px">
                  <span style="color: white;font-size: 13px;display: inline-block;float: right">{{
                      Number.prototype.toFormatNum(symbolInfo.volumeUsd24h.toFixed(0))
                    }}</span>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-row>
      <div class="main-comp">
        <el-col style="flex: 1;height: 100%">
          <el-row class="tv-style">
            <TVChartContainer ref="m650-tv-main-panel" :symbol="selectSymbol" v-on:chart-subscribe="onChartSubscribe" :hide-header-search="true"
                              style="width: 100%;float: left"/>
          </el-row>
          <el-row>
            <div class="orderbook-style" style="float: left">
              <el-card class="trade-black-card hide-less-651"
                       style="height: 47px;width: 100%;float: left;border-radius: 0;border-right: 0;border-bottom: 0;border-left: 0"
                       shadow="none" body-style="padding: 10px 15px 10px 15px">
                <el-row style="margin-top: 1px">
                  <el-col :span=6>
                    <span style="color: darkgray;font-size: 12px;display: inline-block;float: left">Bid Size</span>
                  </el-col>
                  <el-col :span="6">
                    <span
                        style="color: darkgray;font-size: 12px;display: inline-block;float: right;padding-right: 15px">Bid Price</span>
                  </el-col>
                  <el-col :span="6">
                    <span style="color: darkgray;font-size: 12px;display: inline-block;float: left;padding-left: 15px">Ask Price</span>
                  </el-col>
                  <el-col :span="6">
                    <span style="color: darkgray;font-size: 12px;display: inline-block;float: right">Ask Size</span>
                  </el-col>
                </el-row>
              </el-card>
              <el-card class="trade-black-card hide-more-651"
                       style="height: 47px;width: 100%;float: left;border-radius: 0;border-right: 0;border-bottom: 0;border-left: 0"
                       shadow="none" body-style="padding: 10px 5px 10px 5px">
                <el-row style="margin-top: 1px">
                  <el-col :span=12>
                    <span style="color: darkgray;font-size: 12px;display: inline-block;float: left">Price</span>
                  </el-col>
                  <el-col :span="12">
                    <span style="color: darkgray;font-size: 12px;display: inline-block;float: right">Total</span>
                  </el-col>
                </el-row>
              </el-card>
              <order-book-panel ref="m650-order-book-panel"></order-book-panel>
            </div>
            <div class="hide-more-651" style="width: 60%;float: right">
              <el-card class="trade-black-card"
                       style="height: 287px;width: 100%;float: left;border-radius: 0;border-right: 0;border-bottom: 0"
                       shadow="none" body-style="padding: 0">
                <el-row>
                  <el-col :span="12">
                    <el-button :class="{'dark-trade-btn-long-active': isLong, 'dark-trade-btn-long': !isLong}"
                               style="width: 100%;height: 30px;padding: 0 10px 0 10px" @click="isLong = true">BUY
                    </el-button>
                  </el-col>
                  <el-col :span="12">
                    <el-button :class="{'dark-trade-btn-short-active': !isLong, 'dark-trade-btn-short': isLong}"
                               style="width: 100%;height: 30px;padding: 0 10px 0 10px" @click="isLong = false">SELL
                    </el-button>
                  </el-col>
                </el-row>
                <el-row style="margin-top: 2px">
                  <el-col :span="6">
                    <el-button :class="{'order-type-btn-active': activeLimit, 'order-type-btn': !activeLimit}"
                               style="padding: 0;height: 25px" @click="changeOrderType('Limit')">Limit
                    </el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button :class="{'order-type-btn-active': activeMarket, 'order-type-btn': !activeMarket}"
                               style="padding: 0;height: 25px" @click="changeOrderType('Market')">Market
                    </el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button :class="{'order-type-btn-active': activeTWAP, 'order-type-btn': !activeTWAP}" disabled
                               style="padding: 0;height: 25px" @click="changeOrderType('TWAP')">TWAP
                    </el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button :class="{'order-type-btn-active': activeVWAP, 'order-type-btn': !activeVWAP}" disabled
                               style="padding: 0;height: 25px" @click="changeOrderType('VWAP')">VWAP
                    </el-button>
                  </el-col>
                </el-row>
                <el-row style="padding: 8px 15px">
                  <el-row>
                    <span style="font-size: 12px;color: gray;display: inline-block;float: left">Coin amount</span>
                  </el-row>
                  <el-row style="margin-top: 3px">
                    <input class="trade-black-card" type="text" v-auth:tradePanelWrite
                           style="background-color: #1a1f25;width: 100%;outline: none;color: white;font-size: 12px;height: 25px;padding-left: 5px;border-radius: 2px">
                  </el-row>
                  <div class="limit-comp" v-if="activeLimit">
                    <el-row style="margin-top: 5px">
                      <span style="font-size: 12px;color: gray;display: inline-block;float: left">Price</span>
                    </el-row>
                    <el-row style="margin-top: 3px">
                      <input class="trade-black-card" type="text" v-auth:tradePanelWrite
                             style="background-color: #1a1f25;width: 100%;outline: none;color: white;font-size: 12px;height: 25px;padding-left: 5px;border-radius: 2px">
                    </el-row>
                  </div>
                  <div class="WAP-comp" v-if="activeVWAP || activeTWAP">
                    <el-row style="margin-top: 5px">
                      <span style="font-size: 12px;color: gray;display: inline-block;float: left">Running time</span>
                    </el-row>
                    <el-row style="margin-top: 5px">
                      <input class="trade-black-card" type="text" v-auth:tradePanelWrite
                             style="background-color: #1a1f25;width: 100%;outline: none;color: white;font-size: 12px;height: 25px;padding-left: 5px;border-radius: 2px">
                    </el-row>
                    <el-row style="margin-top: 5px">
                      <span class="ellipsis-line" style="font-size: 12px;color: gray;display: inline-block;float: left">Maximum Market Participation Rate</span>
                    </el-row>
                    <el-row style="margin-top: 5px">
                      <input class="trade-black-card" type="text" v-auth:tradePanelWrite
                             style="background-color: #1a1f25;width: 100%;outline: none;color: white;font-size: 12px;height: 25px;padding-left: 5px;border-radius: 2px">
                    </el-row>
                  </div>
                  <el-row style="margin-top: 15px">
                    <el-button :class="{'trade-btn-long': isLong, 'trade-btn-short': !isLong}" v-auth:tradePanelWrite
                               style="width: 100%;height: 28px;line-height: 28px;padding: 0;font-size: 13px">
                      {{ isLong ? 'BUY' : 'SELL' }}
                    </el-button>
                  </el-row>
                </el-row>
              </el-card>
              <el-card class="trade-black-card" style="height: 80px;border-radius: 0;border-right: 0;border-bottom: 0"
                       body-style="padding: 0">
                <el-cascader
                    class="account-select"
                    style="width: 100%;margin-top: -3px;"
                    v-model="selectAccount"
                    :options="instanceList"
                    :props="{ expandTrigger: 'hover' }"
                    @change="handleChange"></el-cascader>
                <el-row style="padding-top: 5px;padding-left: 10px;border-top: 1px solid #444c56"
                        v-if="selectAccount.length > 0">
                <span
                    style="font-size: 12px;color: white;display: inline-block;float: left;padding-left: 5px;padding-top: 5px">Balance: <span
                    style="display: inline-block;padding-left: 10px;">{{
                    accountIdMap[selectAccount[1]].currentBalance.toFixed(4)
                  }} {{ accountIdMap[selectAccount[1]].baseCoin }}</span></span>
                  <img :src="exchanges[accountIdMap[selectAccount[1]].platform.toUpperCase()].icon"
                       :style="'' + exchanges[accountIdMap[selectAccount[1]].platform.toUpperCase()].iconStyle + ''"
                       :alt=accountIdMap[selectAccount[1]].platform.toUpperCase()>
                </el-row>
              </el-card>
              <el-card class="trade-black-card"
                       style="height: 128px;width: 100%;border-radius: 0;border-right: 0;border-bottom: 0" shadow="none"
                       body-style="padding: 0">
                <general-account-profit-panel ref="l650-profit-panel" :id="'account_' + selectAccount[0]"
                                              style="width: 100%;height: 126px;margin-top: -2px;z-index: 1;"></general-account-profit-panel>
              </el-card>
            </div>
          </el-row>
        </el-col>
        <div class="operation-area">
          <el-card class="trade-black-card"
                   style="height: 533px;width: 100%;float: left;border-radius: 0;border-top: 0;border-right: 0;border-bottom: 0"
                   shadow="none" body-style="padding: 0">
            <el-row>
              <el-col :span="12">
                <el-button :class="{'dark-trade-btn-long-active': isLong, 'dark-trade-btn-long': !isLong}"
                           style="width: 100%;height: 40px" @click="isLong = true">BUY
                </el-button>
              </el-col>
              <el-col :span="12">
                <el-button :class="{'dark-trade-btn-short-active': !isLong, 'dark-trade-btn-short': isLong}"
                           style="width: 100%;height: 40px" @click="isLong = false">SELL
                </el-button>
              </el-col>
            </el-row>
            <el-row style="margin-top: 5px">
              <el-col :span="6">
                <el-button :class="{'order-type-btn-active': activeLimit, 'order-type-btn': !activeLimit}"
                           style="padding: 0" @click="changeOrderType('Limit')">Limit
                </el-button>
              </el-col>
              <el-col :span="6">
                <el-button :class="{'order-type-btn-active': activeMarket, 'order-type-btn': !activeMarket}"
                           style="padding: 0" @click="changeOrderType('Market')">Market
                </el-button>
              </el-col>
              <el-col :span="6">
                <el-button :class="{'order-type-btn-active': activeTWAP, 'order-type-btn': !activeTWAP}" disabled
                           style="padding: 0" @click="changeOrderType('TWAP')">TWAP
                </el-button>
              </el-col>
              <el-col :span="6">
                <el-button :class="{'order-type-btn-active': activeVWAP, 'order-type-btn': !activeVWAP}" disabled
                           style="padding: 0" @click="changeOrderType('VWAP')">VWAP
                </el-button>
              </el-col>
            </el-row>
            <el-row style="padding: 15px">
              <el-row>
                <span style="font-size: 12px;color: gray;display: inline-block;float: left">Coin amount</span>
              </el-row>
              <el-row style="margin-top: 5px">
                <input class="trade-black-card" type="text" v-auth:tradePanelWrite
                       style="background-color: #1a1f25;width: 100%;outline: none;color: white;font-size: 12px;height: 30px;padding-left: 5px;border-radius: 2px">
              </el-row>
              <div class="limit-comp" v-if="activeLimit">
                <el-row style="margin-top: 10px">
                  <span style="font-size: 12px;color: gray;display: inline-block;float: left">Price</span>
                </el-row>
                <el-row style="margin-top: 5px">
                  <input class="trade-black-card" type="text" v-auth:tradePanelWrite
                         style="background-color: #1a1f25;width: 100%;outline: none;color: white;font-size: 12px;height: 30px;padding-left: 5px;border-radius: 2px">
                </el-row>
              </div>
              <div class="WAP-comp" v-if="activeVWAP || activeTWAP">
                <el-row style="margin-top: 10px">
                  <span style="font-size: 12px;color: gray;display: inline-block;float: left">Running time</span>
                </el-row>
                <el-row style="margin-top: 5px">
                  <input class="trade-black-card" type="text" v-auth:tradePanelWrite
                         style="background-color: #1a1f25;width: 100%;outline: none;color: white;font-size: 12px;height: 30px;padding-left: 5px;border-radius: 2px">
                </el-row>
                <el-row style="margin-top: 10px">
                  <span style="font-size: 12px;color: gray;display: inline-block;float: left">Maximum Market Participation Rate</span>
                </el-row>
                <el-row style="margin-top: 5px">
                  <input class="trade-black-card" type="text" v-auth:tradePanelWrite
                         style="background-color: #1a1f25;width: 100%;outline: none;color: white;font-size: 12px;height: 30px;padding-left: 5px;border-radius: 2px">
                </el-row>
              </div>
              <el-row style="margin-top: 25px">
                <el-button :class="{'trade-btn-long': isLong, 'trade-btn-short': !isLong}" v-auth:tradePanelWrite
                           style="width: 100%;height: 35px;line-height: 35px;padding: 0">{{ isLong ? 'BUY' : 'SELL' }}
                </el-button>
              </el-row>
            </el-row>
          </el-card>
          <el-card class="trade-black-card" style="height: 80px;border-radius: 0;border-right: 0;border-bottom: 0"
                   body-style="padding: 0">
            <el-cascader
                class="account-select"
                style="width: 100%;margin-top: -3px;"
                v-model="selectAccount"
                :options="instanceList"
                :props="{ expandTrigger: 'hover' }"
                @change="handleChange"></el-cascader>
            <el-row style="padding-top: 5px;padding-left: 10px;border-top: 1px solid #444c56"
                    v-if="selectAccount.length > 0">
                <span
                    style="font-size: 12px;color: white;display: inline-block;float: left;padding-left: 5px;padding-top: 5px">Balance: <span
                    style="display: inline-block;padding-left: 10px;">{{
                    accountIdMap[selectAccount[1]].currentBalance.toFixed(4)
                  }} {{ accountIdMap[selectAccount[1]].baseCoin }}</span></span>
              <img :src="exchanges[accountIdMap[selectAccount[1]].platform.toUpperCase()].icon"
                   :style="'' + exchanges[accountIdMap[selectAccount[1]].platform.toUpperCase()].iconStyle + ''"
                   :alt=accountIdMap[selectAccount[1]].platform.toUpperCase()>
            </el-row>
          </el-card>
          <el-card class="trade-black-card"
                   style="height: 128px;width: 100%;border-radius: 0;border-right: 0;border-bottom: 0" shadow="none"
                   body-style="padding: 0">
            <general-account-profit-panel ref="m650-profit-panel" :id="'account_' + selectAccount[0]"
                                          style="width: 100%;height: 126px;margin-top: -2px;z-index: 1;"></general-account-profit-panel>
          </el-card>
        </div>
      </div>
    </el-row>
    <el-row style="height: 80vh;z-index: 2">
      <el-card class="trade-black-card"
               style="border-radius: 0;border-right: 0;border-left: 0;height: calc(80vh - 111px)"
               body-style="padding: 0" shadow="none">
        <el-row class="hide-less-971" style="padding: 5px">
          <el-button :class="{'dark-operation-btn-active': activePosition, 'dark-operation-btn': !activePosition}"
                     style="" @click="changeActiveOperation('positions')">Positions
          </el-button>
          <el-button :class="{'dark-operation-btn-active': activeOrder, 'dark-operation-btn': !activeOrder}"
                     style="margin-left: 0" @click="changeActiveOperation('orders')" disabled>Orders
          </el-button>
          <el-button :class="{'dark-operation-btn-active': activeHistory, 'dark-operation-btn': !activeHistory}"
                     style="margin-left: 0" @click="changeActiveOperation('history')" disabled>History
          </el-button>
        </el-row>
        <el-row class="hide-more-971" style="padding: 5px">
          <el-button :class="{'dark-operation-btn-active': activePosition, 'dark-operation-btn': !activePosition}"
                     style="font-size: 12px;width: 108px" @click="changeActiveOperation('positions')">Positions
          </el-button>
          <el-button :class="{'dark-operation-btn-active': activeOrder, 'dark-operation-btn': !activeOrder}"
                     style="margin-left: 0;font-size: 12px;width: 108px" @click="changeActiveOperation('orders')" disabled>Orders
          </el-button>
          <el-button :class="{'dark-operation-btn-active': activeHistory, 'dark-operation-btn': !activeHistory}"
                     style="margin-left: 0;font-size: 12px;width: 108px" @click="changeActiveOperation('history')" disabled>
            History
          </el-button>
        </el-row>
        <div style="height: calc(80vh - 160px);overflow-y: auto">
          <binance-position-panel v-if="activePosition && exchange === 'BINANCE'" ref="binance-position-panel"
                                  style="height: 100%"></binance-position-panel>
          <ftx-position-panel v-if="activePosition && exchange === 'FTX'" ref="ftx-position-panel"
                              style="height: 100%"></ftx-position-panel>
          <okx-position-panel v-if="activePosition && exchange === 'OKX'" ref="okx-position-panel"
                              style="height: 100%"></okx-position-panel>
          <deribit-position-panel v-if="activePosition && exchange === 'DERIBIT'" ref="deribit-position-panel"
                                  style="height: 100%"></deribit-position-panel>


          <ftx-active-order v-if="activeOrder && exchange === 'FTX'" ref="ftx-active-order"
                            style="height: 100%"></ftx-active-order>
          <binance-active-order v-if="activeOrder && exchange === 'BINANCE'" ref="binance-active-order"
                                style="height: 100%"></binance-active-order>
        </div>
      </el-card>
    </el-row>
  </div>
</template>

<script>
import TVChartContainer from "@/components/TVChartContainer";
import InstanceService from "@/api/trade/InstanceService";
import GeneralAccountProfitPanel from "@/pages/controlPanel/tradeMgnt/GeneralProfitPanel";
import DatafeedApiService from "@/api/trade/DatafeedApiService";
import SymbolSelectPanel from "@/pages/controlPanel/tradeMgnt/SymbolSelectPanel";
import OrderBookPanel from "@/pages/controlPanel/tradeMgnt/OrderBookPanel";
import TradesPanel from "@/pages/controlPanel/tradeMgnt/TradesPanel";
import BinancePositionPanel from "@/pages/controlPanel/tradeMgnt/positionPanel/BinancePosition";
import FtxPositionPanel from "@/pages/controlPanel/tradeMgnt/positionPanel/FtxPosition";
import OkxPositionPanel from "@/pages/controlPanel/tradeMgnt/positionPanel/OkxPosition";
import draggable from 'vuedraggable';
import DeribitPositionPanel from "@/pages/controlPanel/tradeMgnt/positionPanel/DeribitPosition";
import FtxActiveOrder from "@/pages/controlPanel/tradeMgnt/activeOrderPanel/FtxActiveOrder";
import BinanceActiveOrder from "@/pages/controlPanel/tradeMgnt/activeOrderPanel/BinanceActiveOrder";
import ExchangeInformations from "@/const/ExchangeInformations";

export default {
  name: "TradeMgnt",
  components: {
    BinanceActiveOrder,
    FtxActiveOrder,
    DeribitPositionPanel,
    draggable,
    OkxPositionPanel,
    FtxPositionPanel,
    BinancePositionPanel,
    TradesPanel, OrderBookPanel, SymbolSelectPanel, GeneralAccountProfitPanel, TVChartContainer
  },
  data: function () {
    return {
      isLong: true,
      tvReady: false,
      activePosition: true,
      activeOrder: false,
      activeHistory: false,
      activeLimit: true,
      activeMarket: false,
      activeTWAP: false,
      activeVWAP: false,
      selectSymbol: 'BTCUSDT',
      exchange: 'BINANCE',
      instanceList: [],
      selectAccount: [],
      exchanges: {
        BINANCE: {
          url: ExchangeInformations.exchangeInfos.BINANCE.url,
          icon: ExchangeInformations.exchangeInfos.BINANCE.icon,
          iconStyle: 'width: 18px;height: 18px;margin-top: 5px;float:right;margin-right: 12px',
          font: 'font-size: 13px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 5px;margin-left: 10px;color: white;'
        },
        OKX: {
          url: ExchangeInformations.exchangeInfos.OKX.url,
          icon: ExchangeInformations.exchangeInfos.OKX.icon,
          iconStyle: 'width: 18px;height: 18px;margin-top: 5px;float:right;margin-right: 12px',
          font: 'font-size: 13px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 5px;margin-left: 10px;color: white'
        },
        DERIBIT: {
          url: ExchangeInformations.exchangeInfos.DERIBIT.url,
          icon: ExchangeInformations.exchangeInfos.DERIBIT.icon,
          iconStyle: 'width: 18px;height: 18px;margin-top: 5px;float:right;margin-right: 12px',
          font: 'font-size: 13px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 5px;margin-left: 10px;color: white'
        },
        BYBIT: {
          url: ExchangeInformations.exchangeInfos.BYBIT.url,
          icon: ExchangeInformations.exchangeInfos.BYBIT.icon,
          iconStyle: 'width: 18px;height: 18px;margin-top: 5px;float:right;margin-right: 12px',
          font: 'font-size: 13px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 5px;margin-left: 10px;color: white'
        },
        KUCOIN: {
          url: ExchangeInformations.exchangeInfos.KUCOIN.url,
          icon: ExchangeInformations.exchangeInfos.KUCOIN.icon,
          iconStyle: 'width: 18px;height: 18px;margin-top: 5px;float:right;margin-right: 12px',
          font: 'font-size: 13px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 5px;margin-left: 10px;color: white'
        },
        BITMEX: {
          url: ExchangeInformations.exchangeInfos.BITMEX.url,
          icon: ExchangeInformations.exchangeInfos.BITMEX.icon,
          iconStyle: 'width: 18px;height: 18px;margin-top: 5px;float:right;margin-right: 12px',
          font: 'font-size: 13px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 5px;margin-left: 10px;color: white'
        },
        GATE: {
          url: ExchangeInformations.exchangeInfos.GATE.url,
          icon: ExchangeInformations.exchangeInfos.GATE.icon,
          iconStyle: 'width: 18px;height: 18px;margin-top: 5px;float:right;margin-right: 12px',
          font: 'font-size: 13px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 5px;margin-left: 10px;color: white'
        },
        MEXC: {
          url: ExchangeInformations.exchangeInfos.MEXC.url,
          icon: ExchangeInformations.exchangeInfos.MEXC.icon,
          iconStyle: 'width: 18px;height: 18px;margin-top: 5px;float:right;margin-right: 12px',
          font: 'font-size: 13px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 5px;margin-left: 10px;color: white'
        },
        HUOBI: {
          url: ExchangeInformations.exchangeInfos.HUOBI.url,
          icon: ExchangeInformations.exchangeInfos.HUOBI.icon,
          iconStyle: 'width: 18px;height: 18px;margin-top: 5px;float:right;margin-right: 12px',
          font: 'font-size: 13px;font-family: Microsoft YaHei,serif;font-weight: bold;margin-top: 5px;margin-left: 10px;color: white'
        }
      },
      accountIdMap: {},
      instanceMap: {},
      exchangeSymbolMap: {},
      exchangeDefaultSymbol: {
        FTX: 'BTC-PERP',
        OKEX: 'BTC-USDT-SWAP',
        DERIBIT: 'BTC-PERPETUAL',
        BINANCE: 'BTCUSDT_FUTURE',
        BYBIT: 'BTCUSDT'
      },
      positionPanelMap: {
        BINANCE: 'binance-position-panel',
        BYBIT: 'bybit-position-panel',
        FTX: 'ftx-position-panel',
        OKX: 'okx-position-panel',
        DERIBIT: 'deribit-position-panel'
      },
      activeOrderMap: {
        BINANCE: 'binance-active-order',
        BYBIT: 'bybit-active-order',
        FTX: 'ftx-active-order',
        OKX: 'okx-active-order',
        DERIBIT: 'deribit-active-order'
      },
      screenWidth: document.body.clientWidth,
      moreThan1200: true,
      moreThan650: true,
      lessThan650: true,
      lastLastPrice: 0,
      symbolInfo: {
        fundingRate: 0,
        change24h: 0,
        lastPrice: 0,
        volumeUsd24h: 0
      },
      symbolInfoTimer: {},
      symbol24InfoTimer: {},
      symbol24InfoMap: {
        BINANCE:{
          FUTURE:{},
          USD:{},
          USDT:{},
          BTC:{},
          ETH:{},
          BNB:{},
          Coins:{}
        },
        BYBIT:{
          FUTURE:{},
          PERP:{},
          USDT:{},
          BTC:{},
          ETH:{},
          Coins:{}
        },
        OKEX:{
          FUTURE:{},
          USD:{},
          USDT:{},
          BTC:{},
          ETH:{},
          Coins:{}
        },
        DERIBIT:{
          FUTURE:{}
        },
        FTX:{
          FUTURE:{},
          MOVE:{},
          USD:{},
          USDT:{},
          BTC:{},
          Coins: {}
        }
      },
      quotaCurrency: 'USD',
      accountBasicCoin: 'ALL'
    }
  },
  mounted() {
    const that = this;
    that.processWidthChange(false);
    window.addEventListener('resize', () => {
      that.processWidthChange(true);
    });
  },
  created() {
    let that = this;
    // window.$crisp.push(["do", "chat:hide"]);
    let tmp = {};
    that.load24hInfo();
    this.symbol24InfoTimer = setInterval(() => {
      that.load24hInfo();
    }, 15000);
    DatafeedApiService.config((response) => {
      response.data.forEach((e) => {
        let key = e.exchange.toUpperCase();
        if (!tmp[key]) {
          tmp[key] = [];
        }
        e.symbols.forEach((s) => {
          if (key === 'BINANCE') {
            tmp[key].push({
              type: s.type ? s.type.toUpperCase() : e.type.toUpperCase(),
              value: s.symbol + '_' + e.type.toUpperCase(),
              label: s.symbol,
            });
          } else {
            tmp[key].push({
              type: s.type ? s.type.toUpperCase() : e.type.toUpperCase(),
              value: s.symbol,
              label: s.symbol
            });
          }
        });
      });
      that.exchangeSymbolMap = tmp;
    });
    this.queryAllInstance();
  },
  destroyed() {
    // window.$crisp.push(["do", "chat:show"]);
    let subscribeIntervalId = localStorage.getItem('subscribeIntervalId')
    if (subscribeIntervalId) {
      clearInterval(subscribeIntervalId)
    }
    if (this.symbol24InfoTimer) {
      clearInterval(this.symbol24InfoTimer);
    }
    if (this.symbolInfoTimer) {
      clearInterval(this.symbolInfoTimer);
    }
  },
  methods: {
    processWidthChange(initPanel) {
      let that = this;
      that.screenWidth = document.body.clientWidth;
      if (that.screenWidth <= 1201) {
        if (that.moreThan1200 || !that.moreThan650) {
          that.moreThan1200 = false;
          that.moreThan650 = true;
          if (initPanel) {
            that.tvReady = false;
            that.handleChange();
          }
        }
      } else if (that.screenWidth > 1201) {
        if (!that.moreThan1200 || that.moreThan650) {
          that.moreThan1200 = true;
          that.moreThan650 = false;
          if (initPanel) {
            that.tvReady = false;
            that.handleChange();
          }
        }
      }
      if (that.screenWidth <= 651) {
        if (!that.lessThan650) {
          that.renderProfit();
          that.renderSymbolSelect();
          that.lessThan650 = true;
        }
      } else {
        if (that.lessThan650) {
          that.renderProfit();
          that.renderSymbolSelect();
          that.lessThan650 = false;
        }
      }
    },
    symbolChanged: function (symbol) {
      let that = this;
      this.selectSymbol = symbol;
      that.$nextTick(function () {
        let pre = that.getPre();
        if (document.body.clientWidth <= 651) {
          pre = 'l650';
        }
        that.refInvoke(pre + '-symbol-select-panel', (name) => {
          that.$refs[name].$parent.doClose();
        });
      });
      let pre = this.getPre();
      this.changeSymbol(pre);
    },
    changeSymbol: function (pre) {
      let that = this;
      let ta = that.accountIdMap[that.selectAccount[1]];
      let exchange = ta ? (ta.platform === 'OKX' ? 'OKEX' : ta.platform) : 'BINANCE';
      that.refInvoke(pre + '-tv-main-panel', (name) => {
        that.$refs[name].setSymbol(that.selectSymbol, '15', () => {
        });
      });
      let platform = exchange.toLowerCase();
      let kind = '';
      let symbol = that.selectSymbol;
      if (exchange === 'BINANCE') {
        kind = that.selectSymbol.split('_')[1].toLowerCase();
        symbol = that.selectSymbol.split('_')[0];
      } else {
        kind = 'future';
      }
      that.$nextTick(function () {
        that.refInvoke(pre + '-order-book-panel', (name) => {
          that.$refs[name].render(platform, kind, symbol);
        });
        that.refInvoke(pre + '-trades-panel', (name) => {
          that.$refs[name].render(platform, kind, symbol);
        });
      });
      that.loadSymbolInfo(platform, kind, symbol);
    },
    onChartSubscribe: function (type) {
      if (['chart_ready', 'undo_redo_state_changed', 'onAutoSaveNeeded', 'series_properties_changed', 'get_bars', 'study_event', 'onTick'].indexOf(type) > -1) {
        this.tvReady = true;
      }
    },
    changeOrderType: function (operation) {
      if (operation === 'Limit') {
        this.activeLimit = true;
        this.activeMarket = false;
        this.activeTWAP = false;
        this.activeVWAP = false;
      } else if (operation === 'Market') {
        this.activeLimit = false;
        this.activeMarket = true;
        this.activeTWAP = false;
        this.activeVWAP = false;
      } else if (operation === 'TWAP') {
        this.activeLimit = false;
        this.activeMarket = false;
        this.activeTWAP = true;
        this.activeVWAP = false;
      } else if (operation === 'VWAP') {
        this.activeLimit = false;
        this.activeMarket = false;
        this.activeTWAP = false;
        this.activeVWAP = true;
      }
    },
    changeActiveOperation: function (operation) {
      let that = this;
      let ta = that.accountIdMap[that.selectAccount[1]];
      if (operation === 'positions') {
        this.activePosition = true;
        this.activeOrder = false;
        this.activeHistory = false;
        that.$nextTick(function () {
          that.refInvoke(that.positionPanelMap[that.exchange], (name) => {
            that.$refs[name].render(ta);
          });
        });
      } else if (operation === 'orders') {
        this.activePosition = false;
        this.activeOrder = true;
        this.activeHistory = false;
        that.$nextTick(function () {
          that.refInvoke(that.activeOrderMap[that.exchange], (name) => {
            that.$refs[name].render(ta);
          });
        });
      } else if (operation === 'history') {
        this.activePosition = false;
        this.activeOrder = false;
        this.activeHistory = true;
      }
    },
    queryAllInstance: function () {
      let that = this;
      InstanceService.query(function (data) {
        let tmp = [];
        let accountTmp = {};
        let defaultSelect = [];
        that.instanceMap = {};
        data.forEach((ins) => {
          let children = [];
          ins.tradeAccountList.forEach((t) => {
            let id = t.id;
            let apiKey = t.apiKey;
            let exchange = t.platform;
            accountTmp[id] = t;
            children.push({
              value: id,
              label: exchange + ': ' + apiKey
            });
            if (defaultSelect.length === 0) {
              defaultSelect.push(ins.instanceId);
              defaultSelect.push(id);
            }
          });
          tmp.push({
            value: ins.instanceId,
            label: ins.name,
            children: children
          });
          that.instanceMap[ins.instanceId] = ins;
        });
        that.instanceList = tmp;
        that.accountIdMap = accountTmp;
        that.selectAccount = defaultSelect;
        that.handleChange();
      });
    },
    renderProfit() {
      let that = this;
      let pre = this.getPre();
      if (Object.keys(that.instanceMap).length > 0) {
        that.$nextTick(function () {
          if (document.body.clientWidth <= 651) {
            pre = 'l650';
          }
          that.refInvoke(pre + '-profit-panel', (name) => {
            that.$refs[name].render(that.instanceMap[this.selectAccount[0]]);
          });
        });
      } else {
        that.refInvoke(pre + '-profit-panel', (name) => {
          that.$refs[name].renderDefault();
        });
      }
    },
    renderSymbolSelect() {
      let that = this;
      let pre = this.getPre();
      let ta = that.accountIdMap[that.selectAccount[1]];
      let exchange = ta ? (ta.platform === 'OKX' ? 'OKEX' : ta.platform) : 'BINANCE';
      that.exchange = ta ? ta.platform : 'BINANCE';
      that.$nextTick(function () {
        if (document.body.clientWidth <= 651) {
          pre = 'l650';
        }
        that.refInvoke(pre + '-symbol-select-panel', (name) => {
          that.$refs[name].render(that.symbol24InfoMap[exchange], exchange, that.accountBasicCoin);
        });
      });
    },
    handleChange() {
      let that = this;
      let pre = that.getPre();
      that.renderProfit();
      let ta = that.accountIdMap[that.selectAccount[1]];
      let exchange = ta ? (ta.platform === 'OKX' ? 'OKEX' : ta.platform) : 'BINANCE';
      that.exchange = ta ? ta.platform : 'BINANCE';
      if (that.exchange === 'DERIBIT') {
        that.accountBasicCoin = ta.baseCoin.replaceAll('_U', '');
        that.selectSymbol = that.accountBasicCoin + '-PERPETUAL';
      } else {
        that.accountBasicCoin = 'ALL';
        that.selectSymbol = that.exchangeDefaultSymbol[exchange];
      }
      let tmpCheckTv = setInterval(() => {
        try {
          that.changeSymbol(pre);
          that.$nextTick(function () {
            that.renderSymbolSelect();
            that.changeActiveOperation(that.activePosition ? 'positions' : (that.activeOrder ? 'orders' : 'history'))
          });
          that.tvReady = true;
          clearInterval(tmpCheckTv);
        } catch (e) {
          that.tvReady = e.message.indexOf('undefined') === -1;
        }
      }, 168);
    },
    refInvoke(name, callback) {
      if (this.$refs[name]) {
        callback(name);
      }
    },
    getPre() {
      return this.moreThan1200 ? 'm1200' : (this.moreThan650 ? 'm650' : 'l650');
    },
    loadSymbolInfo(platform, kind, symbol) {
      if (this.symbolInfoTimer) {
        clearInterval(this.symbolInfoTimer);
      }
      let that = this;
      DatafeedApiService.markPrice(platform, kind, symbol, function (data) {
        if (data.data.symbolMarkPrice[symbol]['fundingRate']) {
          that.symbolInfo['fundingRate'] = data.data.symbolMarkPrice[symbol]['fundingRate'];
        } else {
          that.symbolInfo['fundingRate'] = 0;
        }
      });
      DatafeedApiService.lastPriceIn24hr(platform, kind, symbol, function (data) {
        that.symbolInfo['change24h'] = data.data.symolPrices[symbol]['change24h'];
        that.symbolInfo['lastPrice'] = data.data.symolPrices[symbol]['lastPrice'];
        that.symbolInfo['volumeUsd24h'] = data.data.symolPrices[symbol]['volumeUsd24h'];
        that.quotaCurrency = data.data.symolPrices[symbol]['quotaCurrency'];
      });
      this.symbolInfoTimer = setInterval(() => {
        DatafeedApiService.markPrice(platform, kind, symbol, function (data) {
          if (data.data.symbolMarkPrice[symbol]['fundingRate']) {
            that.symbolInfo['fundingRate'] = data.data.symbolMarkPrice[symbol]['fundingRate'];
          } else {
            that.symbolInfo['fundingRate'] = 0;
          }
        });
        DatafeedApiService.lastPriceIn24hr(platform, kind, symbol, function (data) {
          that.symbolInfo['change24h'] = data.data.symolPrices[symbol]['change24h'];
          that.lastLastPrice = that.symbolInfo['lastPrice'];
          that.symbolInfo['lastPrice'] = data.data.symolPrices[symbol]['lastPrice'];
          that.symbolInfo['volumeUsd24h'] = data.data.symolPrices[symbol]['volumeUsd24h'];
          that.quotaCurrency = data.data.symolPrices[symbol]['quotaCurrency'];
        });
      }, 4568);
    },
    load24hInfo() {
      let that = this;
      DatafeedApiService.lastPriceIn24hr('binance', 'spot', '', function (data) {
        if (data && data.data && data.data.symolPrices) {
          let infoMap = data.data.symolPrices;
          let BTC = {};
          let ETH = {};
          let BNB = {};
          let USD = {};
          let USDT = {};
          let Coins = {};
          Object.keys(data.data.symolPrices).forEach((symbol) => {
            if (symbol.endsWith('USDC') || symbol.endsWith('BUSD')) {
              USD[symbol] = {
                symbol: symbol.toUpperCase(),
                value: symbol.toUpperCase() + '_SPOT',
                change24h: infoMap[symbol].change24h,
                price: infoMap[symbol].lastPrice
              };
            } else if (symbol.endsWith('USDT')) {
              USDT[symbol] = {
                symbol: symbol.toUpperCase(),
                value: symbol.toUpperCase() + '_SPOT',
                change24h: infoMap[symbol].change24h,
                price: infoMap[symbol].lastPrice
              };
            } else if (symbol.endsWith('BTC')) {
              BTC[symbol] = {
                symbol: symbol.toUpperCase(),
                value: symbol.toUpperCase() + '_SPOT',
                change24h: infoMap[symbol].change24h,
                price: infoMap[symbol].lastPrice
              };
            } else if (symbol.endsWith('ETH')) {
              ETH[symbol] = {
                symbol: symbol.toUpperCase(),
                value: symbol.toUpperCase() + '_SPOT',
                change24h: infoMap[symbol].change24h,
                price: infoMap[symbol].lastPrice
              };
            } else if (symbol.endsWith('BNB')) {
              BNB[symbol] = {
                symbol: symbol.toUpperCase(),
                value: symbol.toUpperCase() + '_SPOT',
                change24h: infoMap[symbol].change24h,
                price: infoMap[symbol].lastPrice
              };
            } else {
              Coins[symbol] = {
                symbol: symbol.toUpperCase(),
                value: symbol.toUpperCase() + '_SPOT',
                change24h: infoMap[symbol].change24h,
                price: infoMap[symbol].lastPrice
              };
            }
          });
          that.symbol24InfoMap.BINANCE.Coins = Coins;
          that.symbol24InfoMap.BINANCE.USDT = USDT;
          that.symbol24InfoMap.BINANCE.USD = USD;
          that.symbol24InfoMap.BINANCE.BTC = BTC;
          that.symbol24InfoMap.BINANCE.ETH = ETH;
          that.symbol24InfoMap.BINANCE.BNB = BNB;
        }
      });
      DatafeedApiService.lastPriceIn24hr('binance', 'future', '', function (data) {
        if (data && data.data && data.data.symolPrices) {
          let infoMap = data.data.symolPrices;
          let FUTURE = {};
          Object.keys(data.data.symolPrices).forEach((symbol) => {
            FUTURE[symbol] = {
              symbol: symbol.toUpperCase(),
              value: symbol.toUpperCase() + '_FUTURE',
              change24h: infoMap[symbol].change24h,
              price: infoMap[symbol].lastPrice
            };
          });
          that.symbol24InfoMap.BINANCE.FUTURE = FUTURE;
        }
      });
      DatafeedApiService.lastPriceIn24hr('bybit', 'future', '', function (data) {
        if (data && data.data && data.data.symolPrices) {
          let infoMap = data.data.symolPrices;
          let FUTURE = {};
          Object.keys(data.data.symolPrices).forEach((symbol) => {
            FUTURE[symbol] = {
              symbol: symbol.toUpperCase(),
              value: symbol.toUpperCase(),
              change24h: infoMap[symbol].change24h,
              price: infoMap[symbol].lastPrice
            };
          });
          that.symbol24InfoMap.BYBIT.FUTURE = FUTURE;
        }
      });
      DatafeedApiService.lastPriceIn24hr('okex', 'future', '', function (data) {
        if (data && data.data && data.data.symolPrices) {
          let infoMap = data.data.symolPrices;
          let BTC = {};
          let ETH = {};
          let USD = {};
          let USDT = {};
          let Coins = {};
          let future = {};
          Object.keys(data.data.symolPrices).forEach((symbol) => {
            if (symbol.split('-').length >= 3) {
              future[symbol] = {
                symbol: symbol.toUpperCase(),
                value: symbol.toUpperCase(),
                change24h: infoMap[symbol].change24h,
                price: infoMap[symbol].lastPrice
              };
            } else {
              if (symbol.endsWith('BTC')) {
                BTC[symbol] = {
                  symbol: symbol.toUpperCase(),
                  value: symbol.toUpperCase(),
                  change24h: infoMap[symbol].change24h,
                  price: infoMap[symbol].lastPrice
                };
              } else if (symbol.endsWith('ETH')) {
                ETH[symbol] = {
                  symbol: symbol.toUpperCase(),
                  value: symbol.toUpperCase(),
                  change24h: infoMap[symbol].change24h,
                  price: infoMap[symbol].lastPrice
                };
              } else if (symbol.endsWith('USDC')) {
                USD[symbol] = {
                  symbol: symbol.toUpperCase(),
                  value: symbol.toUpperCase(),
                  change24h: infoMap[symbol].change24h,
                  price: infoMap[symbol].lastPrice
                };
              } else if (symbol.endsWith('USDT')) {
                USDT[symbol] = {
                  symbol: symbol.toUpperCase(),
                  value: symbol.toUpperCase(),
                  change24h: infoMap[symbol].change24h,
                  price: infoMap[symbol].lastPrice
                };
              } else {
                Coins[symbol] = {
                  symbol: symbol.toUpperCase(),
                  value: symbol.toUpperCase(),
                  change24h: infoMap[symbol].change24h,
                  price: infoMap[symbol].lastPrice
                };
              }
            }
          });
          that.symbol24InfoMap.OKEX.FUTURE = future;
          that.symbol24InfoMap.OKEX.USD = USD;
          that.symbol24InfoMap.OKEX.USDT = USDT;
          that.symbol24InfoMap.OKEX.BTC = BTC;
          that.symbol24InfoMap.OKEX.ETH = ETH;
          that.symbol24InfoMap.OKEX.Coins = Coins;
        }
      });
      DatafeedApiService.lastPriceIn24hr('deribit', 'future', '', function (data) {
        if (data && data.data && data.data.symolPrices) {
          let infoMap = data.data.symolPrices;
          let optionMap = {
            FUTURE: {}
          };
          Object.keys(data.data.symolPrices).forEach((symbol) => {
            if (symbol.indexOf('USDC') === -1) {
              if (symbol.split('-').length >= 3) {
                let date = symbol.split('-')[1];
                if (!optionMap[date]) {
                  optionMap[date] = {};
                }
                optionMap[date][symbol] = {
                  symbol: symbol.toUpperCase(),
                  value: symbol.toUpperCase(),
                  change24h: infoMap[symbol].change24h,
                  price: infoMap[symbol].lastPrice
                };
              } else {
                optionMap.FUTURE[symbol] = {
                  symbol: symbol.toUpperCase(),
                  value: symbol.toUpperCase(),
                  change24h: infoMap[symbol].change24h,
                  price: infoMap[symbol].lastPrice
                };
              }
            }
          });
          that.symbol24InfoMap.DERIBIT = optionMap;
        }
      });
    }
  }
}
</script>

<style>

.ellipsis-line {
  padding: 0;
  text-align: left;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-popover.el-popper.black-card-symbol-select-popover {
  top: 80px;
  left: 25px;
}

.account-select .el-input__inner {
  border-radius: 0;
  border-left: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  font-size: 12px;
  height: 43px;
  color: white;
  background-color: rgba(0, 0, 0, 0);
}

.el-popper.el-cascader__dropdown {
  float: left;
  padding-left: 0;
  color: white;
  width: 358px;
}

.el-cascader-node {
  float: left;
  padding-left: 0;
  width: 178px;
}

.el-cascader-menu__wrap.el-scrollbar__wrap.el-scrollbar__wrap--hidden-default {
  width: 200px;
}

.dark-mode-input >>> .el-input__inner {
  font-size: 14px !important;
  color: white !important;
  font-weight: bold !important;
  background-color: #22272e;
  border: #444c56 1px solid;
}

</style>