<template>
  <div :dto="dto">
    <el-skeleton animated :loading="rendering" :throttle="268">
      <template slot="template">
        <trialpha-sk style="margin-top: 0;width: 60%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 80%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 40%;float: left"></trialpha-sk>
        <trialpha-sk style="margin-top: 20px;width: 100%;float: left"></trialpha-sk>
      </template>
      <template>
        <el-row>
          <el-collapse class="trialpha-collapse" v-model="activeNames" style="border: 0">
            <el-collapse-item class="trialpha-collapse" style="border: 0" :name="ins.strategyConfig.baseAsset + ins.strategyConfig.quoteAsset" v-for="ins in instanceList" :key="ins.strategyConfig.baseAsset + ins.strategyConfig.quoteAsset">
              <template slot="title">
                <el-row style="width: 100%;display: flex">
                  <el-card class="black-card" body-style="padding: 0"
                           style="line-height: 46px;margin-left: -10px;margin-top: 9px;border-left: 0;border-top: 0;border-bottom: 0;border-radius: 0;height: 46px;width: 68px;background-color: #444c56;text-align: center">
                      <span class="dark-mode-font" style=";font-size: 12px;width: 50px">{{
                          getPrice(ins.strategyConfig)
                        }}</span>
                  </el-card>
                  <div style="flex: 1">
                    <el-row>
                      <el-col :span="6" style="display: flex">
                        <img :src="exchangeInfo[ins.strategyConfig.symbolPlatform].logo" style="width: 18px;height: 18px;margin-top: 23px;margin-left: 12px;float: left;"/>
                        <div style="flex: 1">
                          <el-row>
                            <el-col :span="24">
                              <span class="dark-mode-font ellipsis-line" :style="'display: inline-block;float: left;margin-left: 0;' + (ins.isActive ? 'color: #FFFFFF' : '') + ''">{{ ins.strategyConfig.baseAsset + ins.strategyConfig.quoteAsset }}</span>
                            </el-col>
                          </el-row>
                        </div>
                      </el-col>
                      <el-col :span="7" style="margin-top: 16px">
                        <el-row style="padding: 0;line-height: 15px;height: 15px">
                          <el-col :span="10" style="max-width: 110px">
                            <span class="dark-mode-font ellipsis-line" style="font-family: 'Microsoft YaHei',serif;padding: 0;line-height: 15px;height: 15px;display: inline-block;float: left;margin-right: 0;text-align: left;font-size: 12px;color: #FFFFFF">
                              {{ins.strategyConfig.baseAsset }} Balance
                            </span>
                          </el-col>
                          <el-col :span="12">
                            <span class="dark-mode-font ellipsis-line" style="font-family: 'Microsoft YaHei',serif;padding: 0;line-height: 15px;height: 15px;display: inline-block;float: left;margin-right: 0;text-align: left;font-size: 12px;color: #FFFFFF">
                              {{ins.strategyConfig.baseCoinBalance.toFixed(5) }}
                            </span>
                          </el-col>
                        </el-row>
                        <el-row style="padding: 0;line-height: 15px;height: 15px;margin-top: 2px">
                          <el-col :span="10" style="max-width: 110px">
                            <span class="dark-mode-font ellipsis-line" style="font-family: 'Microsoft YaHei',serif;padding: 0;line-height: 15px;height: 15px;display: inline-block;float: left;margin-right: 0;text-align: left;font-size: 12px;color: #FFFFFF">
                              {{ins.strategyConfig.quoteAsset }} Balance
                            </span>
                          </el-col>
                          <el-col :span="12">
                            <span class="dark-mode-font ellipsis-line" style="font-family: 'Microsoft YaHei',serif;padding: 0;line-height: 15px;height: 15px;display: inline-block;float: left;margin-right: 0;text-align: left;font-size: 12px;color: #FFFFFF">
                              {{ ins.strategyConfig.quoteCoinBalance.toFixed(5) }}
                            </span>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="7" style="margin-top: 16px;padding-left: 5px">
                        <el-row>
                          <el-col :span="10" style="max-width: 110px">
                            <span class="dark-mode-font ellipsis-line" style="font-family: 'Microsoft YaHei',serif;padding: 0;line-height: 15px;height: 15px;display: inline-block;float: left;margin-right: 0;text-align: left;font-size: 12px;color: #FFFFFF">
                              Trade Times
                            </span>
                          </el-col>
                          <el-col :span="12">
                            <span class="dark-mode-font ellipsis-line" style="font-family: 'Microsoft YaHei',serif;padding: 0;line-height: 15px;height: 15px;display: inline-block;float: left;margin-right: 0;text-align: left;font-size: 12px;color: #FFFFFF">
                              {{ (ins.strategyConfig.sellTimes) + (ins.strategyConfig.buyTimes) }}
                            </span>
                          </el-col>
                        </el-row>
                        <el-row style="padding: 0;line-height: 15px;height: 15px;margin-top: 2px">
                          <el-col :span="10" style="max-width: 110px">
                            <span class="dark-mode-font ellipsis-line" style="font-family: 'Microsoft YaHei',serif;padding: 0;line-height: 15px;height: 15px;display: inline-block;float: left;margin-right: 0;text-align: left;font-size: 12px;color: #FFFFFF">
                              Profit Times
                            </span>
                          </el-col>
                          <el-col :span="12">
                            <span class="dark-mode-font ellipsis-line" style="font-family: 'Microsoft YaHei',serif;padding: 0;line-height: 15px;height: 15px;display: inline-block;float: left;margin-right: 0;text-align: left;font-size: 12px;color: #ffffff">
                              {{ Math.min((ins.strategyConfig.sellTimes), (ins.strategyConfig.buyTimes)) }} ({{((Math.min((ins.strategyConfig.sellTimes), (ins.strategyConfig.buyTimes)) / (ins.strategyConfig.buyTimes + ins.strategyConfig.sellTimes) * 100)).toFixed(2) }}%)
                            </span>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="4">
                          <span class="dark-mode-font ellipsis-line" style="padding: 0;display: inline-block;float: right;margin-right: 5px;text-align: right;color: #0ecb81;margin-top: 6px">
                            ${{ getProfit(ins.strategyConfig) }}
                          </span>
                      </el-col>
                    </el-row>
                  </div>
                </el-row>
              </template>
              <el-row style="height: 486px;overflow-y: auto">
                <el-row style="width: 98%;margin-top: 8px;margin-left: auto;margin-right: auto;">
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">实例状态</span>
                  </el-col>
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">总成交额预估(USD)</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11">
                    <el-select class="select-symbol" style="width: 100%" v-model="ins.isActive" placeholder="请选择">
                      <el-option
                          v-for="item in [{value: true, label: 'Active'}, {value: false, label: 'Inactive'}]"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="12" :offset="1">
                    <el-input class="trialpha-dark-input-no-pre" disabled :value="((ins.strategyConfig.buyTimes + ins.strategyConfig.sellTimes) * ins.strategyConfig.openUsd).toFixed(4)"/>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">开仓美元数</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">单格间距(%)</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">单格利润(USD)</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11" style="display: flex">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.openUsd"/>
                  </el-col>
                  <el-col :span="6" :offset="1" style="display: flex;padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.gridGap"/>
                  </el-col>
                  <el-col :span="6" style="display: flex">
                    <el-input class="trialpha-dark-input-no-pre" :value="(ins.strategyConfig.openUsd * (ins.strategyConfig.gridGap / 100)).toFixed(6)"/>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">{{ins.strategyConfig.baseAsset}}余额不低于</span>
                  </el-col>
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">{{ins.strategyConfig.quoteAsset}}余额不低于</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11" style="display: flex">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.baseAssetMinBalanceRequired"/>
                  </el-col>
                  <el-col :span="12" :offset="1" style="display: flex;">
                    <el-input class="trialpha-dark-input-no-pre" v-model="ins.strategyConfig.quoteAssetMinBalanceRequired"/>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">卖出总量({{ins.strategyConfig.baseAsset}})</span>
                  </el-col>
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">平均成本({{ins.strategyConfig.quoteAsset}})</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11" style="display: flex">
                    <el-input class="trialpha-dark-input-no-pre" disabled v-model="ins.strategyConfig.sellAmount"/>
                  </el-col>
                  <el-col :span="12" :offset="1" style="display: flex">
                    <el-input class="trialpha-dark-input-no-pre" disabled v-model="ins.strategyConfig.sellEntryPrice"/>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">买入总量({{ins.strategyConfig.baseAsset}})</span>
                  </el-col>
                  <el-col :span="12">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">平均成本({{ins.strategyConfig.quoteAsset}})</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="11" style="display: flex">
                    <el-input class="trialpha-dark-input-no-pre" disabled v-model="ins.strategyConfig.buyAmount"/>
                  </el-col>
                  <el-col :span="12" :offset="1" style="display: flex">
                    <el-input class="trialpha-dark-input-no-pre" disabled v-model="ins.strategyConfig.buyEntryPrice"/>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">{{ ins.strategyConfig.baseAsset }}余额</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">{{ ins.strategyConfig.baseAsset }}预期清零价</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">{{ ins.strategyConfig.quoteAsset }}余额</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">{{ ins.strategyConfig.quoteAsset }}预期清零价</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="6" style="display: flex;padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" disabled v-model="ins.strategyConfig.baseCoinBalance"/>
                  </el-col>
                  <el-col :span="5" style="display: flex;">
                    <el-input class="trialpha-dark-input-no-pre" disabled v-model="ins.strategyConfig.baseAssetClearPrice"/>
                  </el-col>
                  <el-col :span="6" :offset="1" style="display: flex;padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" disabled v-model="ins.strategyConfig.quoteCoinBalance"/>
                  </el-col>
                  <el-col :span="6" style="display: flex">
                    <el-input class="trialpha-dark-input-no-pre" disabled v-model="ins.strategyConfig.quoteAssetClearPrice"/>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">卖出次数</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">买入次数</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">成功套利次数</span>
                  </el-col>
                  <el-col :span="6">
                    <span class="dark-mode-font" style="color: white;font-size: 12px">已实现利润估算(USD)</span>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin-top: 5px;margin-left: auto;margin-right: auto;">
                  <el-col :span="6" style="display: flex;padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" disabled v-model="ins.strategyConfig.sellTimes"/>
                  </el-col>
                  <el-col :span="5" style="display: flex;">
                    <el-input class="trialpha-dark-input-no-pre" disabled v-model="ins.strategyConfig.buyTimes"/>
                  </el-col>
                  <el-col :span="6" :offset="1" style="display: flex;padding-right: 8px">
                    <el-input class="trialpha-dark-input-no-pre" disabled :value="Math.min(ins.strategyConfig.buyTimes, ins.strategyConfig.sellTimes) + ' / ' + ((Math.min(ins.strategyConfig.buyTimes, ins.strategyConfig.sellTimes) / Math.max(ins.strategyConfig.buyTimes, ins.strategyConfig.sellTimes)) * 100).toFixed(2) + '%'"/>
                  </el-col>
                  <el-col :span="6" style="display: flex">
                    <el-input class="trialpha-dark-input-no-pre" :value="getProfit(ins.strategyConfig)" disabled/>
                  </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row style="width: 98%;margin: 10px auto">
                  <el-col :span="12">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 3px 0 0 0;border-right: 0" @click="reset(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i
                        class="el-icon-c-scale-to-original" style="font-size: 16px"/></el-button>
                  </el-col>
                  <el-col :span="12">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0 3px 0 0" @click="deleteIns(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i class="el-icon-delete"
                                                                                                                                                                                                                                    style="font-size: 16px"/>
                    </el-button>
                  </el-col>
                </el-row>
                <el-row style="width: 98%;margin: -10px auto auto;">
                  <el-col :span="24">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 0 0 3px 3px;border-top: 0" @click="save(ins)" :disabled="processing" :loading="processing" v-auth:dbWriteApi><i class="el-icon-check"
                                                                                                                                                                                                                                               style="font-size: 16px"/>
                    </el-button>
                  </el-col>
                </el-row>
              </el-row>
            </el-collapse-item>
          </el-collapse>
          <el-collapse class="trialpha-collapse" v-model="createCollapse" style="border-top: 0">
            <el-collapse-item class="trialpha-collapse">
              <template slot="title">
                <i class="el-icon-menu" style="margin-left: 2px;color: #0ecb81;margin-top: 1px"/><h4 class="dark-mode-font" style="margin-left: 8px;padding-top: 6px;color: white">Create New Instance</h4>
              </template>
              <el-row style="width: 98%;margin: 20px auto 0 auto">
                <el-col :span="5" style="padding-right: 8px">
                  <span class="dark-mode-font" style="color: white">Platform</span>
                </el-col>
                <el-col :span="5" style="padding-right: 8px">
                  <span class="dark-mode-font" style="color: white">Base asset</span>
                </el-col>
                <el-col :span="5" style="padding-right: 8px">
                  <span class="dark-mode-font" style="color: white">Quote asset</span>
                </el-col>
                <el-col :span="5" style="padding-right: 8px">
                  <span class="dark-mode-font" style="color: white">Open usd</span>
                </el-col>
                <el-col :span="4" style="padding-right: 0">
                  <span class="dark-mode-font" style="color: white">Grid gap</span>
                </el-col>
              </el-row>
              <el-row style="width: 98%;margin-left: 10px;margin-top: 3px">
                <el-col :span="5" style="padding-right: 8px">
                  <el-select class="chose-symbol select-symbol" filterable v-model="instance.symbolPlatform" placeholder="Platform 1" style="width: 100%;line-height: 35px" @hook:mounted="closeReadOnly" @focus="closeReadOnly" @visible-change="closeReadOnly" @change="syncSymbol1List(instance)">
                    <el-option
                        v-for="item in Object.keys(platforms)"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="5" style="padding-right: 8px">
                  <el-input class="trialpha-dark-input-no-pre" v-model="instance.baseAsset"/>
                </el-col>
                <el-col :span="5" style="padding-right: 8px">
                  <el-input class="trialpha-dark-input-no-pre" v-model="instance.quoteAsset"/>
                </el-col>
                <el-col :span="5" style="padding-right: 8px">
                  <el-input class="trialpha-dark-input-no-pre" v-model="instance.openUsd"/>
                </el-col>
                <el-col :span="4" style="padding-right: 0">
                  <el-input class="trialpha-dark-input-no-pre" v-model="instance.gridGap"/>
                </el-col>
              </el-row>
              <el-row style="height: 88px;overflow-y: auto">
                <el-divider></el-divider>
                <el-row style="width: 98%;margin: 10px auto">
                  <el-col :span="24">
                    <el-button class="dark-mode-btn" style="float: right;width: 100%;height: 35px;padding: 0 10px;font-size: 12px;border-radius: 3px" @click="add(instance)" :disabled="rendering" :loading="processing" v-auth:dbWriteApi><i class="el-icon-check"
                                                                                                                                                                                                                                    style="font-size: 16px"/>
                    </el-button>
                  </el-col>
                </el-row>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-row>
      </template>
      <trade-tools ref="open-tools"></trade-tools>
    </el-skeleton>
  </div>
</template>

<script>
import {Message} from "element-ui";
import StrategyInstanceService from "@/api/trade/StrategyInstanceService";
import ConfirmProvider from "@/components/ConfirmProvider";
import TradeTools from "@/pages/controlPanel/accountMgnt/strategies/openTask/ftx/TradeTools";
import TrialphaSk from "@/components/TrialphaSk";
import GeneralApiService from "@/api/trade/GeneralApiService";
import SymbolInfoService from "@/utils/SymbolInfoService";
import ExchangeInformations from "@/const/ExchangeInformations";

export default {
  name: "SimpleSpotGrid",
  components: {TrialphaSk, TradeTools},
  props: {
    dto: Object
  },
  data: function () {
    return {
      exchangeInfo: {
        BINANCE: {
          logo: ExchangeInformations.exchangeInfos.BINANCE.icon,
          fontColor: '#f5bc2f'
        },
        BITMEX: {
          logo: ExchangeInformations.exchangeInfos.BITMEX.icon,
          fontColor: '#5b69b1'
        },
        DERIBIT: {
          logo: ExchangeInformations.exchangeInfos.DERIBIT.icon,
          fontColor: '#04cfbe'
        },
        FTX: {
          logo: ExchangeInformations.exchangeInfos.FTX.icon,
          fontColor: '#67ccdf'
        },
        OKX: {
          logo: ExchangeInformations.exchangeInfos.OKX.icon,
          fontColor: '#67ccdf'
        },
        BYBIT: {
          logo: ExchangeInformations.exchangeInfos.BYBIT.icon,
          fontColor: '#67ccdf'
        }
      },
      platforms: GeneralApiService.PLATFORM,
      symbolTypes: ['SPOT', 'USD_CONTRACT', 'COIN_CONTRACT', 'OPTIONS'],
      orderTypes: GeneralApiService.ORDER_TYPE,
      symbol1Arrays: [],
      symbol2Arrays: [],
      processing: false,
      rendering: true,
      activeNames: ['1'],
      createCollapse: ['1'],
      instance: {},
      instanceList: [],
      accountInstance: {},
      allPrice: {},
      allSymbols: []
    }
  },
  created() {
    this.closeReadOnly(true);
  },
  methods: {
    getProfit(config) {
      let profit = (config.openUsd * (config.gridGap / 100));
      return ((config.sellTimes >= config.buyTimes) ? config.buyTimes * profit : config.sellTimes * profit).toFixed(5);
    },
    syncSymbol1List(instance) {
      this.symbol1Arrays = SymbolInfoService.getSymbolList(instance.platform1, instance.symbolType1);
    },
    syncSymbol2List(instance) {
      this.symbol2Arrays = SymbolInfoService.getSymbolList(instance.platform2, instance.symbolType2);
    },
    closeReadOnly(val) {
      this.$nextTick(() => {
        if (val) {
          this.$el.querySelectorAll('.chose-symbol.select-symbol .el-input__inner').forEach((item) => {
            item.removeAttribute('readonly');
          })
        }
      })
    },
    openTradeTools(ins) {
      this.$nextTick(function () {
        this.$refs['open-tools'].renderFtx(ins);
      });
    },
    handleShortLong(ins) {
      ins.symbol1Short = !ins.symbol1Short;
    },
    render: function (accountInstance) {
      let that = this;
      that.rendering = true;
      that.accountInstance = accountInstance;
      that.instance = JSON.parse(JSON.stringify(that.dto.strategyConfig));
      StrategyInstanceService.query(that.accountInstance.instanceId, that.dto.strategyName, function (data) {
        that.instanceList = data.strategyInsList;
        that.rendering = false;
      }, function () {
        that.rendering = false;
      });
    },
    add: function (ins) {
      ins.baseAsset = ins.baseAsset.toUpperCase();
      ins.quoteAsset = ins.quoteAsset.toUpperCase();
      let that = this;
      that.processing = true;
      StrategyInstanceService.create({
        insId: this.accountInstance.instanceId,
        strategyName: this.dto.strategyName,
        config: JSON.stringify(ins)
      }, function () {
        Message.success('已成功创建新的实例');
        that.processing = false;
        setTimeout(() => that.render(that.accountInstance), 888);
      }, function () {
        that.processing = false;
      });
    },
    getPrice: function (ins) {
      return SymbolInfoService.getSymbolPrice(ins.symbolPlatform, GeneralApiService.SYMBOL_TYPE.SPOT, ins.baseAsset + ins.quoteAsset);
    },
    reset: function (ins) {
      let that = this;
      ConfirmProvider.openConfirm(this, '确认重置', '该操作将会重置所有非配置的数据', function () {
        that.processing = true;
        StrategyInstanceService.reset(ins.sid, function () {
          that.processing = false;
          Message.success('已成功重置');
          that.render(that.accountInstance);
        }, function () {
          that.processing = false;
        });
      });
    },
    deleteIns: function (ins) {
      let that = this;
      ConfirmProvider.openConfirm(this, '确认删除', '该操作将会删除该实例，此操作无法恢复', function () {
        that.processing = true;
        StrategyInstanceService.deleteIns(ins.sid, function () {
          that.processing = false;
          Message.success('已成功删除');
          that.render(that.accountInstance);
        }, function () {
          that.processing = false;
        });
      });
    },
    save: function (ins) {
      let that = this;
      that.processing = true;
      StrategyInstanceService.update({
        sid: ins.sid,
        strategyName: ins.strategyName,
        config: (ins.strategyConfig),
        instanceEntity: {
          id: that.accountInstance.instanceId
        },
        isActive: ins.isActive
      }, function () {
        that.processing = false;
        Message.success('已成功更新');
        that.render(that.accountInstance);
      }, function () {
        that.processing = false;
      });
    }
  }
}
</script>

<style scoped>

.ellipsis-line {
  padding: 8px;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-collapse {
  color: #FFFFFF;
  border-top: 1px solid #444c56;
  border-bottom: 1px solid #444c56;
}

.el-collapse-item >>> .el-collapse-item__content {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: white;
}

.el-collapse-item >>> .el-collapse-item__header {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-collapse-item >>> .el-collapse-item__wrap {
  font-family: 'DIN-Bold', serif; /* 重命名字体名 */
  color: #FFFFFF;
  padding: 0 10px 0 10px;
  background-color: #22272e !important;
  border: #444c56 1px solid;
}

.el-divider {
  border-top: 1px solid #444c56;
}

.el-divider.info {
  width: 80%;
  border-top: 1px solid #444c56;
}

.el-divider__text {
  color: white;
  background-color: #444c56;
}

.select-symbol .el-input__inner {
  color: white;
  height: 35px !important;
  line-height: 35px;
  width: 100%;
  padding: 0 10px 0 10px;
  background-color: #373e47;
  border: #444c56 1px solid;
  border-right: 0;
  border-radius: 0;
  font-size: 12px;
  outline: none;
}

.select-symbol.el-select .el-input.is-focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:hover .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:hover .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-select:visited .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:focus .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

.select-symbol.el-select .el-input--suffix:visited .el-input__inner {
  outline: none;
  border: 1px solid #444c56;
}

</style>