<script>

import Web3Service from "@/web3/Web3Service";
import ApeAbi from "@/web3/abis/ape/ApeAbi";

let ApeContractAddress = '0x5954aB967Bc958940b7EB73ee84797Dc8a2AFbb9';
let ApeCoinContractAddress = '0x4d224452801ACEd8B2F0aebE155379bb5D594381';

function getApeContractAddress(that) {
  let web3 = Web3Service.getWeb3(that);
  return new web3.eth.Contract(ApeAbi.apeABI, ApeContractAddress);
}

function getApeCoinContractAddress(that) {
  let web3 = Web3Service.getWeb3(that);
  return new web3.eth.Contract(ApeAbi.apeCoinABI, ApeCoinContractAddress);
}

export default {
  ApeContractAddress,
  ApeCoinContractAddress,
  getApeContractAddress,
  getApeCoinContractAddress
}
</script>