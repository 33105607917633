<template>
  <div>
    <el-row style="margin-top: 0">
    <div style="margin: auto">
      <el-card class="black-card" style="border-radius: 3px 3px 0 0;border-bottom: 0;background-color: #1c2128" body-style="padding: 10px" shadow="none">
        <el-row>
          <el-col :span="8">
            <span class="dark-mode-font" style="color: white;display: inline-block;float: left">Oneport Multi-Sign Wallet</span>
          </el-col>
          <el-col :span="16">
            <span class="dark-mode-font" style="color: white;display: inline-block;float: right;font-size: 12px;margin-top: 3px;color: gray">{{ walletAddr }}</span>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="black-card" style="border-radius: 0;height: 28px;background-color: #1c2128" shadow="none" body-style="padding: 4px 10px 3px 10px">
        <el-row>
          <el-col :span="8">
            <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">Asset</span>
          </el-col>
          <el-col :span="8">
            <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">Amount</span>
          </el-col>
          <el-col :span="8">
            <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">Operation</span>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="black-card-hover" shadow="none" style=";border: 0;border-radius: 0" body-style="padding:0" v-for="(p, index) in tokenInfoList" :key="index">
        <el-row style="height: 25px;border-bottom: 1px solid #444c56;border-left: 1px solid #444c56;border-right: 1px solid #444c56;padding: 3px 10px 3px 10px">
          <el-col :span="8">
            <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{ p.name }}</span>
          </el-col>
          <el-col :span="8">
            <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{ getFormatAmount(p.balance) }}</span>
          </el-col>
          <el-col :span="8">
            <el-button class="dark-mode-btn" style="border-radius: 0;border-top: 0;border-bottom: 0;border-right: 0;height: 26px;line-height: 26px;float: right;margin-top: -5px;font-size: 12px;padding: 0;width: 68px;margin-right: -10px" @click="send(p.name, p.address)">SEND</el-button>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <div style="margin: auto">
      <el-card class="black-card" style="border-radius: 0;border-top: 0;background-color: #1c2128" body-style="padding: 10px" shadow="none">
        <el-row>
          <el-col :span="8">
            <span class="dark-mode-font" style="color: white;display: inline-block;float: left">Pending transactions</span>
          </el-col>
        </el-row>
      </el-card>
      <div v-if="pendingList.length > 0">
        <el-card class="black-card" shadow="none" style=";border: 0;border-radius: 0" body-style="padding:0" v-for="(p, index) in pendingList" :key="index">
          <el-row style=";border-bottom: 1px solid #444c56;border-left: 1px solid #444c56;border-right: 1px solid #444c56;padding: 3px 10px 10px 10px">
            <el-row style="margin-top: 5px">
              <el-col :span="6">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:gray;">Transaction Number</span>
              </el-col>
              <el-col :span="6">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:gray;">Token Name</span>
              </el-col>
              <el-col :span="12">
                <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:gray ">Token Address</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{ p.transactionId }}</span>
              </el-col>
              <el-col :span="6">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{ tokenAddressNameMap[p.tokenAddress] }}</span>
              </el-col>
              <el-col :span="12">
                <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{ p.tokenAddress }}</span>
              </el-col>
            </el-row>
            <el-row style="margin-top: 5px">
              <el-col :span="6">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:gray;">Amount</span>
              </el-col>
              <el-col :span="9">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:gray;">Remark</span>
              </el-col>
              <el-col :span="9">
                <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:gray ">Target Address</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{ getFormatAmount(p.amount) }}</span>
              </el-col>
              <el-col :span="9">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{ p.remark }}</span>
              </el-col>
              <el-col :span="9">
                <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{ p.to }}</span>
              </el-col>
            </el-row>
            <el-row style="margin-top: 5px">
              <el-col :span="6">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:gray;">Approved</span>
              </el-col>
              <el-col :span="8">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:gray;">Rejected</span>
              </el-col>
              <el-col :span="10">
                <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:gray ">Operation</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{ getApprovedCounts(p) + '/' + approvedRequiredCount }}</span>
              </el-col>
              <el-col :span="8">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{ getRejectedCounts(p) + '/' + approvedRequiredCount }}</span>
              </el-col>
              <el-col :span="10">
                <el-button class="dark-mode-btn" style="border-radius: 0 3px 3px 0;height: 22px;line-height: 20px;float: right;margin-top: 0;font-size: 12px;padding: 0;width: 68px;margin-right: 0" @click="approvalRequest(p)" :disabled="isAlreadyOperated(p)" :loading="confirmLoading">Approval</el-button>
                <el-button class="dark-mode-btn" style="border-radius: 3px 0 0 3px;border-right: 0;height: 22px;line-height: 20px;float: right;margin-top: 0;font-size: 12px;padding: 0;width: 68px;margin-right: 0" @click="rejectRequest(p)" :disabled="isAlreadyOperated(p)" :loading="confirmLoading">Reject</el-button>
              </el-col>
            </el-row>
          </el-row>
        </el-card>
      </div>
      <div v-if="pendingList.length === 0">
        <el-row style="height: 65px;border-top: 0;border-bottom: #444c56 1px solid;border-left: #444c56 1px solid;border-right: #444c56 1px solid;background-color: rgba(0, 0, 0, 0);padding: 3px 10px 3px 10px;text-align: center;margin-top: 0;margin-bottom: 0">
          <span class="dark-mode-font" style="margin-top: 20px;display: inline-block;font-size: 12px;color:gray;text-align: center;"><i class="el-icon-s-order" /> EMPTY...</span>
        </el-row>
      </div>
    </div>
    <div style="margin: auto">
      <el-card class="black-card" style="border-radius: 0;border-top: 0;background-color: #1c2128" body-style="padding: 10px" shadow="none">
        <el-row>
          <el-col :span="8">
            <span class="dark-mode-font" style="color: white;display: inline-block;float: left">History transactions</span>
          </el-col>
        </el-row>
      </el-card>
      <div v-if="historyList.length > 0">
        <el-card class="black-card" shadow="none" style=";border: 0;border-radius: 0" body-style="padding:0" v-for="(p, index) in historyList" :key="index">
          <el-row style=";border-bottom: 1px solid #444c56;border-left: 1px solid #444c56;border-right: 1px solid #444c56;padding: 3px 10px 10px 10px">
            <el-row style="margin-top: 5px">
              <el-col :span="6">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:gray;">Transaction Number</span>
              </el-col>
              <el-col :span="6">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:gray;">Token Name</span>
              </el-col>
              <el-col :span="12">
                <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:gray ">Token Address</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{ p.transactionId }}</span>
              </el-col>
              <el-col :span="6">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{ tokenAddressNameMap[p.tokenAddress] }}</span>
              </el-col>
              <el-col :span="12">
                <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{ p.tokenAddress }}</span>
              </el-col>
            </el-row>
            <el-row style="margin-top: 5px">
              <el-col :span="6">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:gray;">Amount</span>
              </el-col>
              <el-col :span="9">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:gray;">Remark</span>
              </el-col>
              <el-col :span="9">
                <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:gray ">Target Address</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{ getFormatAmount(p.amount) }}</span>
              </el-col>
              <el-col :span="9">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{ p.remark }}</span>
              </el-col>
              <el-col :span="9">
                <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{ p.to }}</span>
              </el-col>
            </el-row>
            <el-row style="margin-top: 5px">
              <el-col :span="6">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:gray;">Approved</span>
              </el-col>
              <el-col :span="8">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:gray;">Rejected</span>
              </el-col>
              <el-col :span="10">
                <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:gray ">Status</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{ getApprovedCounts(p) + '/' + approvedRequiredCount }}</span>
              </el-col>
              <el-col :span="8">
                <span class="dark-mode-font" style="display: inline-block;float: left;text-align: left;font-size: 12px;color:white ">{{ getRejectedCounts(p) + '/' + approvedRequiredCount }}</span>
              </el-col>
              <el-col :span="10">
                <span class="dark-mode-font" style="display: inline-block;float: right;text-align: right;font-size: 12px;color:white ">{{ p.status === '1' ? 'APPROVED' : 'REJECTED' }}</span>
              </el-col>
            </el-row>
          </el-row>
        </el-card>
      </div>
      <div v-if="historyList.length === 0">
        <el-row style="height: 65px;border-top: 0;border-bottom: #444c56 1px solid;border-left: #444c56 1px solid;border-right: #444c56 1px solid;background-color: rgba(0, 0, 0, 0);padding: 3px 10px 3px 10px;text-align: center;margin-top: 0;margin-bottom: 0">
          <span class="dark-mode-font" style="margin-top: 20px;display: inline-block;font-size: 12px;color:gray;text-align: center;"><i class="el-icon-s-order" /> EMPTY...</span>
        </el-row>
      </div>
    </div>
    </el-row>
    <request-withdraw ref="requestWithdraw" @updateAssetInfo="initBalanceInfo"/>
  </div>
</template>

<script>
import AbmWalletContract from "@/web3/contract/AbmWalletContract";
import Web3Utils from "@/web3/utils/Web3Utils";
import RequestWithdraw from "@/web3/abmWallet/RequestWithdraw";
import Web3Service from "@/web3/Web3Service";
import {Message} from "element-ui";
import ChangeToChain from "@/web3/abmWallet/ChangeToChain";

export default {
  name: "AbmWalletMgnt",
  components: {RequestWithdraw},
  data: function () {
    return {
      walletAddr: AbmWalletContract.contractAddress,
      confirmLoading: false,
      approvedRequiredCount: 0,
      tokenInfoList: [
        {
          name: 'ETH',
          address: '0xC5fC28B8130f34378d7EfdEfc97F26032072F5C1',
          balance: 0
        },
        {
          name: 'ABM Token',
          address: '0xca72f94bFc804884b7c40b887Ac44487A1532FDA',
          balance: 0
        }
      ],
      tokenAddressNameMap: {
        '0xC5fC28B8130f34378d7EfdEfc97F26032072F5C1': 'ETH',
        '0xca72f94bFc804884b7c40b887Ac44487A1532FDA': 'ABM Token'
      },
      pendingList: [],
      historyList: []
    }
  },
  methods: {
    initNetwork: function () {
      let that = this;
      ChangeToChain.changeNetwork(5).then(() => {
        that.getAllTransactions();
        let abmWalletContract = AbmWalletContract.getContract(that);
        abmWalletContract.methods.getApprovalAddressRequiredCount().call().then((res) => {
          that.approvedRequiredCount = res;
        });
        that.tokenInfoList.forEach((info) => {
          if (info.address === AbmWalletContract.contractAddress) {
            abmWalletContract.methods.balanceOf().call().then((res) => {
              info.balance = res;
            });
          } else {
            abmWalletContract.methods.balanceOfToken(info.address).call().then((res) => {
              info.balance = res;
            });
          }
        });
      });
    },
    initBalanceInfo: function () {
      this.initNetwork();
    },
    getAllTransactions: function () {
      let that = this;
      let abmWalletContract = AbmWalletContract.getContract(this);
      abmWalletContract.methods.getAllTransactions().call().then((res) => {
        let historyTmp = [];
        let pendingTmp = [];
        res.forEach((info) => {
          if (info.closed) {
            historyTmp.push(info);
          } else {
            pendingTmp.push(info);
          }
        });
        that.pendingList = pendingTmp;
        that.historyList = historyTmp;
      });
    },
    getFormatAmount(amount) {
      return Web3Utils.getRealAmount(amount, 18);
    },
    getResult(info) {
      if (info.status === '1') {
        let approvedCount = 0;
        info.approvaledAddress.forEach((addr) => {
          if (addr !== '0x0000000000000000000000000000000000000000') {
            approvedCount++;
          }
        });
        return 'APPROVED: ' + approvedCount + '/';
      } else if (info.status === '2') {
        let rejectedCount = 0;
        info.rejectedAddress.forEach((addr) => {
          if (addr !== '0x0000000000000000000000000000000000000000') {
            rejectedCount++;
          }
        });
        return 'REJECTED: ' + rejectedCount + '/';
      }
    },
    getApprovedCounts(info) {
      let approvedCount = 0;
      info.approvaledAddress.forEach((addr) => {
        if (addr !== '0x0000000000000000000000000000000000000000') {
          approvedCount++;
        }
      });
      return approvedCount;
    },
    getRejectedCounts(info) {
      let rejectedCount = 0;
      info.rejectedAddress.forEach((addr) => {
        if (addr !== '0x0000000000000000000000000000000000000000') {
          rejectedCount++;
        }
      });
      return rejectedCount;
    },
    send(name, address) {
      this.$nextTick(function () {
        this.$refs['requestWithdraw'].render(name, address);
      });
    },
    isAlreadyOperated(info) {
      let web3 = Web3Service.getWeb3(this);
      let currentAddr = web3.currentProvider.selectedAddress;
      let hasMe = false;
      info.approvaledAddress.forEach((addr) => {
        if (addr.toLowerCase() === currentAddr.toLowerCase()) {
          hasMe = true;
        }
      });
      info.rejectedAddress.forEach((addr) => {
        if (addr.toLowerCase() === currentAddr.toLowerCase()) {
          hasMe = true;
        }
      });
      return hasMe;
    },
    approvalRequest(info) {
      let that = this;
      let web3 = Web3Service.getWeb3(this);
      let abmWalletContract = AbmWalletContract.getContract(this);
      that.confirmLoading = true;
      abmWalletContract.methods.confirmTransfer(info.transactionId).send({from: web3.currentProvider.selectedAddress}).on("receipt", function(receipt) {
        Message.success('Transaction confirmed success, transaction hash is ' + receipt.transactionHash);
        that.confirmLoading = false;
        that.initBalanceInfo();
      }).on("error", function(error) {
        Message.error(error.message);
        that.confirmLoading = false;
      });
    },
    rejectRequest(info) {
      let that = this;
      let web3 = Web3Service.getWeb3(this);
      let abmWalletContract = AbmWalletContract.getContract(this);
      that.confirmLoading = true;
      abmWalletContract.methods.rejectTransfer(info.transactionId).send({from: web3.currentProvider.selectedAddress}).on("receipt", function(receipt) {
        Message.success('Transaction rejected success, transaction hash is ' + receipt.transactionHash);
        that.confirmLoading = false;
        that.initBalanceInfo();
      }).on("error", function(error) {
        Message.error(error.message);
        that.confirmLoading = false;
      });
    }
  }
}
</script>

<style scoped>
.ellipsis-line-strategy {
  padding: 0;
  text-align: left;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>